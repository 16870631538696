import produce from "immer";

import { TEXT_CONSTANT } from "../../../config/constants";

export const _handle_open_bulk_update_confimation_pop_up_utils = (appStateObj: any, slotsGeneratorForms: any, configTabIndex: any, generatorFormIndex: any, currentDay: any, allDays: any) => {
  if (appStateObj !== undefined) {
    let generated_form = { ...slotsGeneratorForms[generatorFormIndex] };
    if (generated_form !== undefined && generated_form.generatedSlots !== undefined && Array.isArray(generated_form.generatedSlots) && generated_form.generatedSlots.length > 0) {
      let generatedSlots = [] as any;
      generated_form.generatedSlots.forEach((slot: any) => {
        if (slot !== undefined && slot.slotAction !== undefined && slot.slotAction !== "" && slot.slotAction !== "DELETE") {
          generatedSlots.push(slot);
        }
      });
      generated_form.generatedSlots = [];
      generated_form.generatedSlots = [...generatedSlots];
    }

    let newAppStateObj: any = produce(appStateObj, (draft: any) => {
      draft.loading = true;
      draft.openBulkUpdateConfirmationPopUp = true;
      draft.configTabIndex = configTabIndex;
      draft.generatorFormIndex = generatorFormIndex;
      draft.configuredDays = allDays;
      draft.currentBulkUpdateConfigurationSummary = { ...generated_form };
      draft.selctedDays = [];
      draft.currentDay = currentDay;
      draft.configurationBulkUpdatedObj = {
        isConfigurationBulkUpdated: false,
        bulkUpdatedDays: [],
      };
    });
    return newAppStateObj;
  }
  return {};
};

export const _handle_close_bulk_update_confimation_pop_up_utils = (appStateObj: any) => {
  if (appStateObj !== undefined) {
    let newAppStateObj: any = produce(appStateObj, (draft: any) => {
      draft.loading = false;
      draft.openBulkUpdateConfirmationPopUp = false;
      draft.configTabIndex = null;
      draft.generatorFormIndex = null;
      draft.configuredDays = [];
      draft.currentBulkUpdateConfigurationSummary = {};
      draft.selctedDays = [];
      draft.currentDay = "";
      draft.configurationBulkUpdatedObj = {
        isConfigurationBulkUpdated: false,
        bulkUpdatedDays: [],
      };
    });
    return newAppStateObj;
  }
  return {};
};

export const _handle_day_delected_for_bulk_update_utils = (appStateObj: any, selctedDays: any, configuredDays: any, dayValue: any) => {
  if (appStateObj !== undefined) {
    let selectedDaysCopy = [...selctedDays];
    if (selectedDaysCopy !== undefined && Array.isArray(selectedDaysCopy) && selectedDaysCopy.length >= 0) {
      if (dayValue === TEXT_CONSTANT.ALL) {
        if (selectedDaysCopy.length === configuredDays.length) {
          selectedDaysCopy = [];
        } else {
          selectedDaysCopy = [...configuredDays];
        }
      } else {
        const isElementPresent = selectedDaysCopy.indexOf(dayValue);
        if (isElementPresent > -1) {
          selectedDaysCopy.splice(isElementPresent, 1);
        } else {
          selectedDaysCopy.push(dayValue);
        }
      }
    } else {
      selectedDaysCopy = [];
    }
    let newAppStateObj: any = produce(appStateObj, (draft: any) => {
      draft.selctedDays = [...selectedDaysCopy];
    });
    return newAppStateObj;
  }
  return {};
};

export const _handle_clear_bulk_update_confimation_pop_up_utils = (appStateObj: any, days: any) => {
  if (appStateObj !== undefined) {
    let newAppStateObj: any = produce(appStateObj, (draft: any) => {
      draft.loading = false;
      draft.openBulkUpdateConfirmationPopUp = false;
      draft.configTabIndex = null;
      draft.generatorFormIndex = null;
      draft.configuredDays = [];
      draft.currentBulkUpdateConfigurationSummary = {};
      draft.selctedDays = [];
      draft.currentDay = "";
      draft.configurationBulkUpdatedObj = {
        isConfigurationBulkUpdated: false,
        bulkUpdatedDays: [...days],
      };
    });
    return newAppStateObj;
  }
  return {};
};
