import React from "react";

import { userStyles } from "./Styles/PosZoneMappingDialogStyles";

import { Card, CardContent, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, Table, Typography } from "@material-ui/core";
import { capitalizeFirstLetter } from "../../../utils/DataUtils";

import ModalContainer from "../../../base/ModalContainer";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import TextBox from "../../common/TextBox";

import searchIcon from "../../assets/svg/SearchIcon.svg";
import ZoomOut from "../../assets/svg/ZoomOut.svg";

import PosZoneMappingDialogHeader from "./Common/PosZoneMappingDialogHeader";
import PosZoneMappingDialogBody from "./Common/PosZoneMappingDialogBody";
import Loader from "../../common/Loader/Loader";

interface PosZoneMappingDialogInterface {
  open: any;
  handleClose: any;
  stateObj: any;
  fetchRecordsByPosCode: any;
  handleKeyUp: any;
  handlePosZoneSearchChange: any;
  updatePosNumberOpenIndex: any;
  handleChangeIsNonServiceable: any;
  selectPosZoneForMapping: any;
  handlePOSZoneMappingSubmit: any;
  currentCountry: any;
  isLoading: any;
}

const PosZoneMappingDialog = (props: PosZoneMappingDialogInterface) => {
  const {
    open,
    handleClose,
    stateObj,
    fetchRecordsByPosCode,
    handleKeyUp,
    handlePosZoneSearchChange,
    updatePosNumberOpenIndex,
    handleChangeIsNonServiceable,
    selectPosZoneForMapping,
    handlePOSZoneMappingSubmit,
    currentCountry,
    isLoading,
  } = props;
  const classes = userStyles();

  return (
    <ModalContainer open={open} onClose={handleClose}>
      <Card className={classes.cardContainer}>
        <CardContent className={classes.cardContentStyle}>
          <Grid container spacing={0} className={classes.gridContainerMain}>
            <Grid item xs={6} className={classes.gridItemTitleDivStyle}>
              <Typography className={classes.headerSpanStyle}>{`POS Zone List ${
                stateObj.serviceTypeName !== undefined && stateObj.serviceTypeName !== "" ? `For - ${stateObj.serviceTypeName}` : ""
              }`}</Typography>
            </Grid>
            <Grid item xs={6} className={classes.gridItemServiceableDivStyle}>
              <FormControlLabel
                control={<Checkbox checked={stateObj.isNonServiceable} value={stateObj.isNonServiceable} onChange={handleChangeIsNonServiceable} />}
                label={<Typography className={classes.checkboxLabel}>{"Mark as non serviceable"}</Typography>}
              />
            </Grid>
          </Grid>

          {isLoading ? (
            <Card className={classes.nonServiceableCard}>
              <CardContent className={classes.nonServiceableCardContent}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <Loader />
                </Grid>
              </CardContent>
            </Card>
          ) : stateObj.isNonServiceable ? (
            <Card className={classes.nonServiceableCard}>
              <CardContent className={classes.nonServiceableCardContent}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <Typography className={classes.polygonNonServiceableSpanStyle}>{`Please Note!`}</Typography>
                  <Typography className={classes.polygonNonServiceableSpanStyle}>{`Non Serviceable value selected`}</Typography>
                </Grid>
              </CardContent>
            </Card>
          ) : stateObj !== undefined && stateObj.posZoneServiceRecord !== undefined && Array.isArray(stateObj.posZoneServiceRecord) && stateObj.posZoneServiceRecord.length > 0 ? (
            <Grid container className={classes.gridContainerContent}>
              <Grid item xs={12} className={classes.gridContainerSearchContent}>
                <TextBox
                  label=""
                  textBoxId="searchTextBox"
                  placeholderText="Search by POS No, POS Name"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="start" size="small">
                        <img src={searchIcon} alt="search" className={stateObj.posZoneSearchText === "" ? classes.hiddenSearchIcon : classes.headersearchIconstyle} onClick={fetchRecordsByPosCode} />
                      </IconButton>
                    </InputAdornment>
                  }
                  value={stateObj.posZoneSearchText}
                  handleChange={handlePosZoneSearchChange}
                  className={classes.searchBoxStyle}
                  onKeyUp={handleKeyUp}
                  inputProps={{
                    autoComplete: "off",
                  }}
                ></TextBox>
              </Grid>
              <Grid item xs={12} className={classes.gridContainerItemStyle}>
                <Table padding="none" size="small" stickyHeader={true}>
                  <PosZoneMappingDialogHeader classes={classes} />
                  <PosZoneMappingDialogBody
                    classes={classes}
                    posZoneServiceRecord={stateObj.posZoneServiceFilteredRecord}
                    updatePosNumberOpenIndex={updatePosNumberOpenIndex}
                    selectedPosZoneIndex={stateObj.selectedPosZoneIndex}
                    selectPosZoneForMapping={selectPosZoneForMapping}
                  />
                </Table>
              </Grid>
            </Grid>
          ) : (
            <Card className={classes.nonServiceableCard}>
              <CardContent className={classes.nonServiceableCardContent}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <Grid item xs={12}>
                    <img src={ZoomOut} alt="Edit" />
                  </Grid>
                  <Grid item xs={12} className={classes.noDataCellGridItemStyle}>
                    <Typography className={classes.noDataCellSpanStyle}>
                      {`No POS or Zone has been created for ${capitalizeFirstLetter(stateObj.serviceTypeName)} in ${currentCountry.name}.`}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}

          {/* Footer */}
          <Grid container className={classes.gridContainerFooter}>
            <PrimaryButton className={classes.cancelButtonStyle} buttonLabel="Cancel" onClick={() => handleClose()} />
            <PrimaryButton disabled={isLoading} className={classes.saveButtonStyle} buttonLabel="Submit" onClick={() => handlePOSZoneMappingSubmit()} />
          </Grid>
        </CardContent>
      </Card>
    </ModalContainer>
  );
};

export default React.memo(PosZoneMappingDialog);
