import React from "react";

import DateFnsUtils from "@date-io/date-fns";

import { Grid, Typography } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import LinkButton from "components/common/LinkButton/LinkButton";
import { getDateFromData } from "../../../utils/DateUtils";
import { ReactComponent as DeleteIcon } from "../../assets/svg/DeleteIcon.svg";
import DlvFeeDayConfiguration from "./DlvFeeDayConfiguration";

interface DlvFeeDateConfigurationInterface {
  state: any;
  configIndex: any;
  classes: any;
  handleDateChange: any;
  removeDateRecord: any;
  handleAddDaysButton: any;
  handleSelectedDaysChange: any;
  removeDayButton: any;
  handleAddTimeButton: any;
  handleConfigTimeChange: any;
  handleThresholdChange: any;
  removeTimeSlot: any;
}

const DlvFeeDateConfiguration = (props: DlvFeeDateConfigurationInterface) => {
  const {
    state,
    configIndex,
    classes,
    handleDateChange,
    removeDateRecord,
    handleAddDaysButton,
    handleSelectedDaysChange,
    removeDayButton,
    handleAddTimeButton,
    handleConfigTimeChange,
    handleThresholdChange,
    removeTimeSlot,
  } = props;

  return (
    <>
      <div className={classes.dateTimeStyle}>
        {state && state.selectedDays && state.selectedDays.length > 0 && (
          <>
            <span>Generated for : </span>
            <span style={{ fontWeight: 600 }}>{` (${getDateFromData(state.startDate)} - ${getDateFromData(state.endDate)})}`}</span>
          </>
        )}
      </div>
      <div className={classes.boxContainer}>
        <Grid container>
          <Grid item xs={3}>
            <Typography className={classes.configDateTextStyle}> Start Date</Typography>
          </Grid>
          <Grid item xs={1}>
            {""}
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.configDateTextStyle}>End Date</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disablePast
                value={state.startDate}
                onChange={(date: any) => handleDateChange(date, "startDate", configIndex)}
                minDate={new Date()}
                format="dd/MM/yyyy"
                inputVariant="outlined"
                inputProps={{
                  disabled: true,
                }}
                className={classes.keyboardDatePickerStyle}
                disabled={state && state.allDays && Array.isArray(state.allDays) && state.allDays.length > 0 ? true : false}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={1}>
            {""}
          </Grid>
          <Grid item xs={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disablePast
                value={state.endDate}
                onChange={(date: any) => handleDateChange(date, "endDate", configIndex)}
                minDate={state.startDate}
                format="dd/MM/yyyy"
                inputVariant="outlined"
                inputProps={{
                  disabled: true,
                }}
                className={classes.keyboardDatePickerStyle}
                disabled={state && state.allDays && Array.isArray(state.allDays) && state.allDays.length > 0 ? true : false}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={1}>
            {""}
          </Grid>
          <Grid item xs={1} className={classes.iconDivStyle}>
            <DeleteIcon onClick={() => removeDateRecord(configIndex)} className={classes.iconStyle} />
          </Grid>
          <Grid item xs={3}>
            {""}
          </Grid>
        </Grid>

        {state.startDate && state.startDate !== "" && state.endDate && state.endDate !== "" && (
          <div className={classes.buttonStyle}>
            <LinkButton
              disabled={state && state.selectedDays && state.selectedDays && state.allDays && state.selectedDays.length === state.allDays.length ? true : false}
              buttonLabel="Add Days"
              name="dayButton"
              onClick={() => handleAddDaysButton(configIndex)}
            />
          </div>
        )}

        {state && state.daysConfiguration && Array.isArray(state.daysConfiguration) && state.daysConfiguration.length > 0 ? (
          state.daysConfiguration.map((dayObject: any, dayObjectIndex: any) => (
            <DlvFeeDayConfiguration
              key={`${dayObjectIndex}-daysConfiguration-configurationState-configuration`}
              state={state}
              configIndex={configIndex}
              dayObject={dayObject}
              dayObjectIndex={dayObjectIndex}
              classes={classes}
              handleSelectedDaysChange={handleSelectedDaysChange}
              removeDayButton={removeDayButton}
              handleAddTimeButton={handleAddTimeButton}
              handleConfigTimeChange={handleConfigTimeChange}
              handleThresholdChange={handleThresholdChange}
              removeTimeSlot={removeTimeSlot}
            />
          ))
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default React.memo(DlvFeeDateConfiguration);
