import React from "react";

import { Grid, Typography } from "@material-ui/core";

import MultiSelectBox from "../../common/MultiSelectBox/MultiSelectBox";
import SelectBox from "../../common/SelectBox/SelectBox";
import { POLYGON_VIEW_TYPE } from "../Utils/PlolygonServicesConstants";
import { PlolygonServicesInitialCountStateInteface, PlolygonServicesInitialStateInteface } from "../Utils/PlolygonServicesInterface";

interface PlolygonServicesCountHeaderInterface {
  classes: any;
  appStateObj: PlolygonServicesInitialStateInteface;
  appCountStateObj: PlolygonServicesInitialCountStateInteface;
  handleServiceTypeChange: any;
  handleTileClick: any;
  handleSingleServiceTypeChange?: any;
}

const PlolygonServicesCountHeader = (props: PlolygonServicesCountHeaderInterface) => {
  const { classes, appStateObj, appCountStateObj, handleServiceTypeChange, handleTileClick, handleSingleServiceTypeChange } = props;

  return (
    <Grid container spacing={0} className={classes.headerGridMainContainerStyle}>
      <Grid item xs={2} className={classes.serviceTypeFilterStyle}>
        <SelectBox name="serviceType" label="Service Type" menuItems={appCountStateObj.serviceTypeList} value={appCountStateObj.serviceTypeFilter} handleChange={handleSingleServiceTypeChange} />
      </Grid>

      <Grid item xs={10}>
        {appCountStateObj !== undefined && appCountStateObj.countHeader !== undefined && Array.isArray(appCountStateObj.countHeader) && appCountStateObj.countHeader.length > 0 ? (
          <Grid container spacing={1} className={classes.countGridMainContainerStyle}>
            {appCountStateObj.countHeader.map((countObj: any, countObjIndex: number) => (
              <Grid item xs={3} key={`${countObjIndex}-countObj-${countObj.label}`} onClick={() => handleTileClick(countObjIndex)}>
                <Grid container spacing={0} className={countObjIndex === appCountStateObj.selectedTileFilter ? classes.countGridItemSelectedStyle : classes.countGridItemStyle}>
                  <Grid item xs={8} className={classes.countItemLabelStyle}>
                    <Typography className={classes.countItemLabelSpanStyle}>{`${countObj.label}`}</Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.countItemValueStyle}>
                    <Typography className={classes.countItemValueSpanStyle}>{`${countObj.value}`}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(PlolygonServicesCountHeader);
