import React from "react";

import { Grid, Typography } from "@material-ui/core";

import { DASHBOARD_CONSTANT } from "../Utils/DashboardPageConstants";
import { DashboardPageInitialStateInterface } from "../Utils/DashboardPageInterface";
import { getMaxDate, getMinDate } from "../Utils/DashboardPageUtils";

import DateRangePickerComponent from "../../common/DateRangePickerComponent/DateRangePickerComponent";
import MultiSelectBoxWithLabel from "../../common/MultiSelectBoxWithLabel/MultiSelectBoxWithLabel";

interface DashboardPageHeaderInterface {
  classes: any;
  appStateObj: DashboardPageInitialStateInterface;
  handleClearAllFilters: any;
  handleDateChangeEvent: any;
  handleCancel: any;
  handleMultiSelectBoxChange: any;
  advanceDaySize: any;
}

const DashboardPageHeader = (props: DashboardPageHeaderInterface) => {
  const { classes, appStateObj, handleClearAllFilters, handleDateChangeEvent, handleCancel, handleMultiSelectBoxChange, advanceDaySize } = props;

  return (
    <Grid container className={classes.headerRoot}>
      <Grid item xs={4} className={classes.header}>
        <Typography className={classes.heading}>{`${DASHBOARD_CONSTANT.SLOT_UTILIZATION_HEADER_TEXT}`}</Typography>
      </Grid>
      <Grid item xs={8} className={classes.headerFilter}>
        {!appStateObj.loading && (
          <Grid container spacing={1} className={classes.headerFilterContainer}>
            <Grid item xs={3} className={classes.clearFilterDiv}>
              <Typography className={classes.clearFilterSpanStyle} onClick={handleClearAllFilters}>{`${DASHBOARD_CONSTANT.CLEAR_ALL_FILTERS}`}</Typography>
            </Grid>
            <Grid item xs={3}>
              <DateRangePickerComponent
                isTextField={true}
                label={"Date"}
                keyId={`appStateObj-slotDateLabel`}
                childClasses={classes}
                datePickerName={"date"}
                placeHolderText={"Select date"}
                datePickerValue={appStateObj && appStateObj.slotDateLabel ? appStateObj.slotDateLabel : ""}
                handleDateChangeEvent={handleDateChangeEvent}
                handleCancel={handleCancel}
                initialSettings={{
                  singleDatePicker: true,
                  autoUpdateInput: false,
                  cancelButtonClasses: "btn-primary",
                  locale: { format: "YYYY-MM-DD", cancelLabel: "Clear" },
                  minDate: getMinDate(30),
                  maxDate: getMaxDate(advanceDaySize),
                }}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={3}>
              <MultiSelectBoxWithLabel
                label={"POS"}
                name="posNos"
                className={classes.fontStyle}
                menuitems={appStateObj.posList}
                value={appStateObj.posNos}
                onChange={(e: any) => handleMultiSelectBoxChange(e, "posNos")}
              />
            </Grid>
            <Grid item xs={3}>
              <MultiSelectBoxWithLabel
                label={"Zone"}
                name="zoneNames"
                className={classes.fontStyle}
                menuitems={appStateObj.zoneList}
                value={appStateObj.zoneNames}
                onChange={(e: any) => handleMultiSelectBoxChange(e, "zoneNames")}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default DashboardPageHeader;
