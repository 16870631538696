import React from "react";

import { Grid } from "@material-ui/core";

import { DEFAULT_RULE_CONFIG } from "../../RuleEngineUtils";
import { ChangeConfigOtion } from "../ServiceRuleEngineUtils";

import SwitchButton from "../../../common/SwitchButton/SwitchButton";
import SelectBox from "components/common/SelectBox/SelectBox";
import { MAXIMUM_PROMISE_TIME_HOURS, MAXIMUM_PROMISE_TIME_MINUTES } from "../ServiceRuleEngine";

type PickKeysOfType<T, TValue> = {
  [P in keyof T]: T[P] extends TValue ? P : never;
}[keyof T];

type MyType = { sameAsEarlier: boolean };
interface RenderAutomatedDeliveryPromiseRuleInterface {
  classes: any;
  serviceName: string;
  title: string;
  config: DEFAULT_RULE_CONFIG;
  keyName: PickKeysOfType<DEFAULT_RULE_CONFIG, MyType>;
  promiseRuleEdit: boolean;
  inputHourName: string;
  hourValue: string;
  inputMinName: string;
  minValue: string;
  onUseForConfigurationSwitchChange: (serviceName: string, ruleName: string, e: any) => void;
  onIsSameAsEarlierCheckboxChange: (serviceName: string, ruleName: string, e: any) => void;
  handleAutomatedRuleConfigEdit: (event: any, keyName: string) => void;
  onAutomatedPromiseFieldsChange: (name: string, event: any, keyName: string) => void;
}

const RenderAutomatedDeliveryPromiseRule = (props: RenderAutomatedDeliveryPromiseRuleInterface) => {
  const {
    classes,
    serviceName,
    title,
    config,
    keyName,
    promiseRuleEdit,
    inputHourName,
    hourValue,
    inputMinName,
    minValue,
    onUseForConfigurationSwitchChange,
    onIsSameAsEarlierCheckboxChange,
    handleAutomatedRuleConfigEdit,
    onAutomatedPromiseFieldsChange,
  } = props;

  return (
    <Grid container className={classes.ruleContainer} spacing={0}>
      <Grid item xs={9}>
        <Grid container spacing={1}>
          <Grid item xs={5} className={classes.ruleLabel}>
            {`${title}`}
          </Grid>
          {config !== undefined &&
            keyName !== undefined &&
            config[keyName] !== undefined &&
            config[keyName].hasOwnProperty("sameAsEarlier") &&
            config[keyName].hasOwnProperty("active") &&
            !config[keyName].sameAsEarlier && (
              <>
                <Grid item xs={6} className={classes.dFlexEnd}>
                  Use this value in calculation
                </Grid>
                <Grid item xs={1}>
                  <SwitchButton
                    className={config.isEditMode && promiseRuleEdit ? classes.disableSwitch : ""}
                    size="small"
                    checked={config[keyName].active}
                    disabled={config.isEditMode && !promiseRuleEdit}
                    handleChange={(e: any) => onUseForConfigurationSwitchChange(serviceName, keyName, e)}
                    changeExisting={promiseRuleEdit}
                    leftLabel={undefined}
                    rightLabel={undefined}
                  />
                </Grid>
              </>
            )}
        </Grid>
      </Grid>
      <ChangeConfigOtion
        config={config}
        checked={config[keyName].sameAsEarlier}
        classes={classes}
        checkChangeHandler={(e: any) => onIsSameAsEarlierCheckboxChange(serviceName, keyName, e)}
        changeExisting={promiseRuleEdit}
        onConfigEdit={(event: any) => handleAutomatedRuleConfigEdit(event, keyName)}
        xsValue={4}
      />

      {config !== undefined && keyName !== undefined && config[keyName].hasOwnProperty("active") && config[keyName].active !== undefined && config[keyName].active && (
        <Grid item xs={12} className={classes.ruleEditingDivStyle}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <SelectBox
                    fieldLabel=""
                    name={inputHourName}
                    label="Hour"
                    value={hourValue}
                    handleChange={(e: any) => onAutomatedPromiseFieldsChange(inputHourName, e, keyName)}
                    menuItems={MAXIMUM_PROMISE_TIME_HOURS}
                    disabled={config.isEditMode && !promiseRuleEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectBox
                    fieldLabel=""
                    name={inputMinName}
                    label="Minutes"
                    value={minValue}
                    handleChange={(e: any) => onAutomatedPromiseFieldsChange(inputMinName, e, keyName)}
                    menuItems={MAXIMUM_PROMISE_TIME_MINUTES}
                    disabled={config.isEditMode && !promiseRuleEdit}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(RenderAutomatedDeliveryPromiseRule);
