import { makeStyles } from "@material-ui/core";
import Button, { ButtonProps } from "@material-ui/core/Button";
import React from "react";


const useStyles = makeStyles(() => ({
  linkButton: {
    textTransform: "none",
    fontWeight: 600,
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

interface LinkButtonProps extends ButtonProps {
  buttonLabel: string,
  className?: any
}

const LinkButton = (props: LinkButtonProps) => {
  const { buttonLabel, className, ...rest } = props;
  const classes = useStyles();

  return (
    <Button {...rest} disableRipple variant="text" color="primary" className={`${classes.linkButton} ${className}`}>
      {buttonLabel}
    </Button>
  );
};

export default LinkButton;
