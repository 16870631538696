import React from "react";

import moment from "moment";

import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@material-ui/core";

import ExpandMoreIcon from "../../assets/svg/ExpandAccordionIcon.svg";
import { HH_MM, HH_MM_SS } from "../../../config/constants";
import { get_class_name_based_on_status } from "../utils/ApprovalRequestRecordUtils";

interface ApprovalRequestSlotDetailsForStandardInterface {
  classes: any;
  slot_day_data: any;
  slot_data_object?: any;
  slot_data_object_key: any;
}

const ApprovalRequestSlotDetailsForStandard = (props: ApprovalRequestSlotDetailsForStandardInterface) => {
  const { classes, slot_day_data, slot_data_object, slot_data_object_key } = props;

  const get_slot_time = (slot: any) => {
    if (slot !== undefined) {
      if (slot.time !== undefined && slot.time !== "") {
        return slot.time;
      } else {
        const { startTime, endTime } = slot;
        if (startTime !== undefined && startTime !== "" && endTime !== undefined && endTime !== "") {
          return `${moment(startTime).format(HH_MM)}-${moment(endTime).format(HH_MM)}`;
        }
      }
    }
    return "";
  };

  const get_buffer_time = (slot: any) => {
    if (slot?.bufferTimeHours && slot?.bufferTimeMinutes) {
      return parseInt(slot?.bufferTimeHours * 60 + slot?.bufferTimeMinutes);
    } else if (slot?.bufferTime) {
      return slot.bufferTime;
    }
    return "0";
  };

  const calculateOrderCutOff = (slot: any) => {
    const { bufferTime, startTime } = slot;
    let orderCutOff = moment(startTime, HH_MM_SS).subtract(bufferTime, "m").format("HH:mm");
    return orderCutOff || "";
  };

  const getDayFromSlot = () => {
    let day_name = "Day";
    if (
      slot_day_data !== undefined &&
      typeof slot_day_data === "object" &&
      slot_day_data.days !== undefined &&
      Array.isArray(slot_day_data.days) &&
      slot_day_data.days.length > 0 &&
      slot_day_data.days[0] !== undefined
    ) {
      return slot_day_data.days[0];
    } else if (slot_day_data !== undefined && typeof slot_day_data === "string") {
      return slot_day_data;
    }
    return day_name;
  };

  const get_slot_details_view = (slot: any, slot_index: any) => {
    return (
      <Grid container spacing={1} key={`${slot_index}-slot_day_data-slot_index-slot_data_object`} className={get_class_name_based_on_status(slot, classes, slot_data_object_key)}>
        <Grid item xs={3} className={classes.slotDetailsValueSpanStyle}>{`${get_slot_time(slot)}`}</Grid>
        <Grid item xs={3} className={classes.slotDetailsValueSpanStyle}>{`${slot.slotCapacity}`}</Grid>
        <Grid item xs={3} className={classes.slotDetailsValueSpanStyle}>{`${get_buffer_time(slot)} mins`}</Grid>
        <Grid item xs={3} className={classes.slotDetailsValueSpanStyle}>{`${calculateOrderCutOff(slot)}`}</Grid>
      </Grid>
    );
  };

  return (
    <Accordion className={classes.slotsAccordion} defaultExpanded>
      <AccordionSummary expandIcon={<img src={ExpandMoreIcon} alt="Expand Accordion Icon" />} className={classes.slotsAccordionSummary}>
        <Typography className={classes.approverDetailsHeadingSpanStyle}>{`${getDayFromSlot()}`}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.dBlock}>
        <Grid container spacing={1}>
          <Grid item xs={3} className={classes.approverDetailsHeadingSpanStyle}>{`Slot`}</Grid>
          <Grid item xs={3} className={classes.approverDetailsHeadingSpanStyle}>{`Capacity`}</Grid>
          <Grid item xs={3} className={classes.approverDetailsHeadingSpanStyle}>{`Buffer Time`}</Grid>
          <Grid item xs={3} className={classes.approverDetailsHeadingSpanStyle}>{`Order Cut-Off`}</Grid>
        </Grid>
        {slot_day_data !== undefined && slot_data_object !== undefined && Array.isArray(slot_data_object[slot_day_data]) && slot_data_object[slot_day_data].length > 0 ? (
          slot_data_object[slot_day_data].map((slot: any, slot_index: any) => get_slot_details_view(slot, slot_index))
        ) : slot_day_data !== undefined && slot_day_data.generatedSlots !== undefined && Array.isArray(slot_day_data.generatedSlots) && slot_day_data.generatedSlots.length > 0 ? (
          slot_day_data.generatedSlots.map((slot: any, slot_index: any) => get_slot_details_view(slot, slot_index))
        ) : (
          <></>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(ApprovalRequestSlotDetailsForStandard);
