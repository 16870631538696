import React from "react";

import { Box, Grid, Tab, Typography } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

import { userStyles } from "./Styles/PolygonDetailsDrawerStyles";
import { PolygonDetailsSideDrawerInterface } from "../../Utils/PlolygonServicesInterface";

import CustomDialog from "../../../common/Dialog";
import PolygonDetailsHistoryRecord from "./PolygonDetailsHistoryRecord";

interface PolygonDetailsDrawerInterface {
  polygonDetailsSideDrawerObj: PolygonDetailsSideDrawerInterface;
  handleClose: any;
  handlePolygonDetailsServiceTypeTabChange: any;
}

const PolygonDetailsDrawer = (props: PolygonDetailsDrawerInterface) => {
  const classes = userStyles();
  const { polygonDetailsSideDrawerObj, handleClose, handlePolygonDetailsServiceTypeTabChange } = props;

  const publishDialogPaperProps = { classes: { root: classes.dialogRoot }, square: true };

  const getDialogTitleContent = () => {
    return (
      <>
        <Grid container className={classes.titleNameGridContainer}>
          <Grid item xs={12}>
            <Typography className={classes.titleNameSpanStyle}>{`Action History: ${polygonDetailsSideDrawerObj.polygonId || "ID"} - ${polygonDetailsSideDrawerObj.polygonName || "Name"}`}</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.titleSubHeaderNameGridContainer}>
          <Typography className={classes.titleSubHeadingNameSpanStyle}>{`Displaying for the last 30 days`}</Typography>
        </Grid>
      </>
    );
  };

  const getDialogDetailsContent = () => {
    return (
      <Box>
        <TabContext value={polygonDetailsSideDrawerObj.serviceType || "STANDARD"}>
          <Box>
            <TabList onChange={handlePolygonDetailsServiceTypeTabChange} className={classes.tabSelectionBox} classes={{ indicator: classes.indicator }}>
              <Tab label="Standard" value="STANDARD" className={classes.tabLabelSpanStyle} />
              <Tab label="Express" value="EXPRESS" className={classes.tabLabelSpanStyle} />
              <Tab label="Default" value="DEFAULT" className={classes.tabLabelSpanStyle} />
            </TabList>
          </Box>
          <TabPanel value="STANDARD" className={classes.tabPanelBoxStyle}>
            <PolygonDetailsHistoryRecord classes={classes} polygonDetailsSideDrawerObj={polygonDetailsSideDrawerObj} />
          </TabPanel>
          <TabPanel value="EXPRESS" className={classes.tabPanelBoxStyle}>
            <PolygonDetailsHistoryRecord classes={classes} polygonDetailsSideDrawerObj={polygonDetailsSideDrawerObj} />
          </TabPanel>
          <TabPanel value="DEFAULT" className={classes.tabPanelBoxStyle}>
            <PolygonDetailsHistoryRecord classes={classes} polygonDetailsSideDrawerObj={polygonDetailsSideDrawerObj} />
          </TabPanel>
        </TabContext>
      </Box>
    );
  };

  return (
    <CustomDialog
      open={polygonDetailsSideDrawerObj.openDrawer}
      PaperProps={publishDialogPaperProps}
      isLoadingData={polygonDetailsSideDrawerObj.loading}
      title={getDialogTitleContent()}
      content={getDialogDetailsContent()}
      handleClose={handleClose}
    />
  );
};

export default React.memo(PolygonDetailsDrawer);
