import { is_user_admin, is_user_admin_read_only_approver, is_user_maf_admin_or_super_admin, is_user_maf_admin_or_super_admin_or_admin } from "../../utils/ApplicationUtils";

export const is_basic_details_edit_visible = (userInfo: any) => {
  return is_user_maf_admin_or_super_admin(userInfo);
};

export const is_fbc_service_toggle_visible = (userInfo: any) => {
  return is_user_maf_admin_or_super_admin(userInfo);
};

export const is_all_service_toggle_visible = (userInfo: any) => {
  return is_user_maf_admin_or_super_admin(userInfo);
};

export const is_configure_service_type_link_visible = (userInfo: any) => {
  return is_user_maf_admin_or_super_admin(userInfo);
};

export const is_edit_service_type_visible = (userInfo: any) => {
  return is_user_maf_admin_or_super_admin_or_admin(userInfo);
};

export const is_user_can_create_approval_request = (userInfo: any) => {
  return is_user_admin(userInfo);
};

export const is_zone_service_toggle_visible = (userInfo: any) => {
  return is_user_maf_admin_or_super_admin(userInfo);
};

export const is_side_menu_basic_details_disabled = (userInfo: any) => {
  return is_user_admin_read_only_approver(userInfo);
};
