import React from "react";
import { Card, Grid, Typography } from "@material-ui/core";

import TextBox from "../../../common/TextBox";
import { DELIVERY_FEE_TEXT_CONSTANT } from "../../Utils/DeliveryFeeConstants";

interface AdditionalCapacityUtilisationConfigListInterface {
  classes: any;
  feeConfigList: any;
  shipment_name?: any;
  multiShipmentLevel?: any;
  handleCapacityUtilisationConfigInputChange?: any;
  stateObj: any;
}

const AdditionalCapacityUtilisationConfigList = (props: AdditionalCapacityUtilisationConfigListInterface) => {
  const { classes, feeConfigList, shipment_name, multiShipmentLevel, handleCapacityUtilisationConfigInputChange, stateObj } = props;

  return (
    <Card className={classes.additionDlvFeeMainCard}>
      <Grid container className={classes.additionDlvFeeInnerRowHeaderStyle}>
        <Grid item xs={3} className={classes.dlvFeeValueDisplayDivStyle}>
          <Typography className={classes.dlvFeeValueDisplayTitleStyle}>{`${DELIVERY_FEE_TEXT_CONSTANT.RANGE_START_GREATER_THAN_OR_EQUAL_TO}`}</Typography>
        </Grid>
        <Grid item xs={3} className={classes.dlvFeeValueDisplayDivStyle}>
          <Typography className={classes.dlvFeeValueDisplayTitleStyle}>{`${DELIVERY_FEE_TEXT_CONSTANT.RANGE_END_LESS_THAN_}`}</Typography>
        </Grid>
        <Grid item xs={2} className={classes.dlvFeeValueDisplayDivStyle}>
          <Typography className={classes.dlvFeeValueDisplayTitleStyle}>{`${DELIVERY_FEE_TEXT_CONSTANT.DELIVERY_FEES}`}</Typography>
        </Grid>
      </Grid>
      {feeConfigList && Array.isArray(feeConfigList) && feeConfigList.length > 0 ? (
        feeConfigList.map((config: any, configIndex: any) => (
          <Grid container key={`${configIndex}-feeConfigList-config`} className={classes.additionDlvFeeInnerRowHeaderStyle}>
            <Grid item xs={3} className={classes.additionDlvFeeInnerRowDivStyle}>
              <Typography className={classes.dlvFeeValueDisplayDataStyle}> {config.capacityLow}</Typography>
            </Grid>
            <Grid item xs={3} className={classes.additionDlvFeeInnerRowDivStyle}>
              <Typography className={classes.dlvFeeValueDisplayDataStyle}> {config.capacityHigh}</Typography>
            </Grid>

            <Grid item xs={2} className={classes.additionDlvFeeInnerRowDivStyle}>
              {handleCapacityUtilisationConfigInputChange !== undefined && multiShipmentLevel !== undefined && shipment_name !== undefined ? (
                <TextBox
                  name="deliveryFee"
                  type="number"
                  textBoxId="deliveryFee"
                  placeholderText="Delivery Fees"
                  value={config.deliveryFee}
                  handleChange={(e: any) => handleCapacityUtilisationConfigInputChange(e, multiShipmentLevel, shipment_name, configIndex)}
                  className={classes.rightPaddingStyle}
                  disabled={!stateObj.enableConfigEdit}
                ></TextBox>
              ) : (
                <Typography className={classes.dlvFeeValueDisplayDataStyle}> {config.deliveryFee}</Typography>
              )}
            </Grid>
          </Grid>
        ))
      ) : (
        <></>
      )}
    </Card>
  );
};

export default React.memo(AdditionalCapacityUtilisationConfigList);
