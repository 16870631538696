import React from "react";
import CustomGoogleMap from "./CustomGoogleMap";
import { googleApiCode } from "../../../config/constants";
import "./GoogleMapContainer.css";
import { MAPOPTIONSTYLES_INTERFACE } from "../../PlolygonServices/Utils/PlolygonServicesInterface";
export interface CustomLocation {
  lat: number;
  lng: number;
}

export interface CustomMarker {
  name: string;
  location: CustomLocation;
  icon?: any;
  label?: any;
}

interface GoogleMapContainerProps {
  refMap: any;
  defaultCenter?: CustomLocation;
  center: CustomLocation;
  zoom?: number;
  onIdle?: any;
  markers?: any;
  polygons?: any;
  handlePolygonClick?: any;
  polygonStateObj?: any;
  viewPolygonInfoWindow?: any;
  posZoneMarkerInfoWindow?: any;
  openPolygonPosZoneInfoWindow?: any;
  mapOptionStyles?: MAPOPTIONSTYLES_INTERFACE[];
}

const MapContainer = (props: GoogleMapContainerProps) => {
  const { refMap, center, zoom, onIdle, markers, polygons, handlePolygonClick, polygonStateObj, viewPolygonInfoWindow, posZoneMarkerInfoWindow, openPolygonPosZoneInfoWindow, mapOptionStyles } = props;

  return (
    <CustomGoogleMap
      googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + googleApiCode + "&libraries=geometry,drawing,places"}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `100%` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      refMap={refMap}
      defaultCenter={center || { lat: 0, lng: 0 }}
      defaultZoom={zoom || 8}
      onIdle={onIdle}
      markers={markers}
      polygons={polygons}
      handlePolygonClick={handlePolygonClick}
      polygonStateObj={polygonStateObj}
      viewPolygonInfoWindow={viewPolygonInfoWindow}
      posZoneMarkerInfoWindow={posZoneMarkerInfoWindow}
      openPolygonPosZoneInfoWindow={openPolygonPosZoneInfoWindow}
      mapOptionStyles={mapOptionStyles}
    />
  );
};

export default MapContainer;
