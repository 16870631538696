import Fetch from "./Fetch";
import FetchWithSiteId from "./FetchWithSiteId";

import { API_HOST_URL, API_POLYGON_SERVICE_URL } from "../config/constants";
import { handleError, handleResponse, handleXLSXResponse } from "./ApiUtils";

export const fetchPosZoneServiceTypeList = (data) => {
  return Fetch.get(`${API_HOST_URL}/delivery-bag/pos-zone-service-mapping`, data).then(handleResponse).catch(handleError);
};

export const fetchPolygonServiceRecordnBasedOnCountryId = (data) => {
  return Fetch.create(`${API_POLYGON_SERVICE_URL}/polygons/${data.countryName}`, data.data).then(handleResponse).catch(handleError);
  // return Fetch.get(`${API_POLYGON_SERVICE_URL}/polygons/${data.countryName}`).then(handleResponse).catch(handleError);
};

export const updatePolygonServiceRecordnByPayload = (data) => {
  return Fetch.create(`${API_POLYGON_SERVICE_URL}/polygon/${data.polygonId}`, data).then(handleResponse).catch(handleError);
};

export const fetchPolygonServiceCountBasedOnQuery = (data) => {
  return Fetch.get(`${API_POLYGON_SERVICE_URL}/polygons/count/${data.countryName}`, data.data).then(handleResponse).catch(handleError);
};

export const fetchMappedPosZoneListBasedOnServiceTypeAndCountry = (data) => {
  return Fetch.get(`${API_POLYGON_SERVICE_URL}/polygon-mappings/serviceType/${data.serviceType}/${data.countryName}`).then(handleResponse).catch(handleError);
};

export const fetchPolygonDetailsHistoryBasedOnIdServiceTypeAndCountry = (data) => {
  return Fetch.create(`${API_POLYGON_SERVICE_URL}/audit/polygons`, data).then(handleResponse).catch(handleError);
};

export const fetchExportToExcel = (data) => {
  return Fetch.exportRequest(`${API_POLYGON_SERVICE_URL}/polygon-mappings/download/${data.countryName}`, undefined, "arraybuffer").then(handleXLSXResponse).catch(handleError);
};

export const fetchPolygonServiceMapRecordnBasedOnBoundaries = (data) => {
  return FetchWithSiteId.create(`${API_POLYGON_SERVICE_URL}/polygon/mapping`, data).then(handleResponse).catch(handleError);
};

export const fetchPosZoneRecordPositionDetails = (data) => {
  return FetchWithSiteId.create(`${API_HOST_URL}/pos/country`, data).then(handleResponse).catch(handleError);
};
