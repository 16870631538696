import { makeStyles } from "@material-ui/core";
// Material UI Components
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";


const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    // transform: "translate(-50%, -50%)",
  },
}));

const Loader = () => {
  const classes = useStyles();
  return <CircularProgress className={classes.root} size={25} color="primary" />;
};

export default Loader;
