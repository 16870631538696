import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAdditionalServicesTypes } from "api/AdditionalServices";
import { API_RESPONSE_STATUS } from "config/constants";
import * as UserManagementService from "../../../api/UserManagementService";

export const fetchAllAdditionalServicesTypes = createAsyncThunk("userMgmtConfigurations/fetchAllAdditionalServicesTypes", async (_, { rejectWithValue }) => {
  try {
    const { response }: any = await getAdditionalServicesTypes();
    if (response && response.success && response.data && Array.isArray(response.data)) {
      let newResponse = response.data.map((service: any) => ({ id: service.id, name: service.name }));
      return newResponse;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createUser = createAsyncThunk("userMgmtConfigurations/createUser", async (userData: any, { rejectWithValue }) => {
  try {
    const { response }: any = await UserManagementService.createUser(userData);
    if (response && response.success && response.data) {
      return response.data;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchUserList = createAsyncThunk("userMgmtConfigurations/fetchUserRecordList", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await UserManagementService.fetchUserRecordList(data);
    if (response && response.success && response.data) {
      return response.data;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUserDetails = createAsyncThunk("userMgmtConfigurations/getUserDetails", async (toUpdateUserId: any, { rejectWithValue }) => {
  try {
    const { response }: any = await UserManagementService.getUserDetails(toUpdateUserId);
    if (response && response.success && response.data) {
      return response.data;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
})

export const updateUser = createAsyncThunk("userMgmtConfigurations/updateUser", async (userData: any, { rejectWithValue }) => {
  try {
    const { response }: any = await UserManagementService.updateUser(userData);
    if (response && response.success && response.data) {
      return response.data;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

const commonFetchInitialState = {
  fetchStatus: API_RESPONSE_STATUS.IDLE,
  error: "",
  data: [] as any,
  loading: false,
};

const initialState = {
  userDetailsService: {
    ...commonFetchInitialState,
  },
  additionalServices: {
    ...commonFetchInitialState,
  },
  userListService: {
    ...commonFetchInitialState,
  },
};

const userManagementSlice = createSlice({
  name: "userMgmtConfigurations",
  initialState,
  reducers: {
    resetAdditionalServicesState: (state) => {
      state.additionalServices = { ...commonFetchInitialState };
    },
  },
  extraReducers: (builders) => {
    builders
      /* Additional Services Types */
      .addCase(fetchAllAdditionalServicesTypes.pending, (state: any) => {
        state.additionalServices.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.additionalServices.data = [];
        state.additionalServices.error = "";
      })
      .addCase(fetchAllAdditionalServicesTypes.fulfilled, (state: any, action: any) => {
        state.additionalServices.data = action.payload;
        state.additionalServices.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(fetchAllAdditionalServicesTypes.rejected, (state: any, action: any) => {
        state.additionalServices.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.additionalServices.error = action.error.message || "";
      })

      /* Create User */
      .addCase(createUser.pending, (state: any) => {
        state.userDetailsService.loading = true;
        state.userDetailsService.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.userDetailsService.data = {};
        state.userDetailsService.error = "";
      })
      .addCase(createUser.fulfilled, (state: any, action: any) => {
        state.userDetailsService.loading = false;
        state.userDetailsService.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.userDetailsService.data = action.payload;
      })
      .addCase(createUser.rejected, (state: any, action: any) => {
        state.userDetailsService.loading = false;
        state.userDetailsService.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.userDetailsService.error = action.error.message;
      })

      /* Update User */
      .addCase(updateUser.pending, (state: any) => {
        state.userDetailsService.loading = true;
        state.userDetailsService.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.userDetailsService.data = {};
        state.userDetailsService.error = "";
      })
      .addCase(updateUser.fulfilled, (state: any, action: any) => {
        state.userDetailsService.loading = false;
        state.userDetailsService.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.userDetailsService.data = action.payload;
      })
      .addCase(updateUser.rejected, (state: any, action: any) => {
        state.userDetailsService.loading = false;
        state.userDetailsService.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.userDetailsService.error = action.error.message;
      })
      /* fetchUserRecordList */
      .addCase(fetchUserList.pending, (state: any) => {
        state.userListService.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.userListService.loading = true;
        state.userListService.data = [];
        state.userListService.error = "";
      })
      .addCase(fetchUserList.fulfilled, (state: any, action: any) => {
        state.userListService.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.userListService.loading = false;
        state.userListService.data = action.payload;
      })
      .addCase(fetchUserList.rejected, (state: any, action: any) => {
        state.userListService.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.userListService.loading = false;
        state.userListService.error = action.error.message;
      })

      /* Fetch User Details based on Id */
      .addCase(getUserDetails.pending, (state: any) => {
        state.userDetailsService.loading = true;
        state.userDetailsService.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.userDetailsService.data = {};
        state.userDetailsService.error = "";
      })
      .addCase(getUserDetails.fulfilled, (state: any, action: any) => {
        state.userDetailsService.loading = false;
        state.userDetailsService.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.userDetailsService.data = action.payload;
      })
      .addCase(getUserDetails.rejected, (state: any, action: any) => {
        state.userDetailsService.loading = false;
        state.userDetailsService.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.userDetailsService.error = action.error.message;
      });
  },
});

export default userManagementSlice.reducer;

export const { resetAdditionalServicesState } = userManagementSlice.actions;

export const selectAllAdditionalServices = (state: any) => state.userMgmtConfigurations.additionalServices.data;

export const selectAllUserRecord = (state: any) => state.userMgmtConfigurations.userListService;

export const selectUserRecord = (state: any) => state.userMgmtConfigurations.userDetailsService;
