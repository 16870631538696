export interface RulesData {
  countRuleApplicable: boolean;
  weightRuleApplicable: boolean;
  scalableRuleApplicable: boolean;
  orderVelocityRuleApplicable: boolean;
  vehicleTypeRuleApplicable: boolean;
  maximumPromiseRuleApplicable: boolean;
  weightRule: WeightRule;
  countRule: CountRule;
  scalableTypeRule: ScalableTypeRule;
  orderVelocityRule: OrderVelocityRule;
  vehicleTypeRule: VehicleTypeRule;
  maximumPromiseRule: MaximumPromiseRule;
}

export interface WeightRule {
  active: boolean;
  sameAsEarlier: boolean;
  incrementalTime: number;
  thresholdWeight: number;
}

export interface CountRule {
  active: boolean;
  sameAsEarlier: boolean;
  incrementalTime: number;
  thresholdCount: number;
}

export interface ScalableTypeRule {
  active: boolean;
  sameAsEarlier: boolean;
  itemList: ScalableItemType[];
}

export interface ScalableItemType {
  incrementalTime: number;
  section: string;
}

export interface OrderVelocityRule {
  active: boolean;
  sameAsEarlier: boolean;
  orderVelocityRuleValues: OrderVelocityRuleValue[];
}

export interface OrderVelocityRuleValue {
  timeRange: number;
  orderThreshold: number;
  extraPromiseTime: number;
  configuredDays: string[];
}

export interface VehicleTypeRule {
  active: boolean;
  sameAsEarlier: boolean;
  heaviestVehicle: string;
  vehicleTypeWeightList: any[];
}

export interface MaximumPromiseRule {
  active: boolean;
  sameAsEarlier: boolean;
  maximumPromiseRuleValues: MaximumPromiseRuleValue[];
}

export interface MaximumPromiseRuleValue {
  maximumPromiseTime: number;
  configuredDays: string[];
}

export interface AutomatedConfig {
  maxTime: number;
  lastMileTime: number;
}

export interface PromiseConfigMaxTime {
  days: ("MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY")[];
  time: string | number;
}
export interface PromiseConfig {
  minTime: string | number;
  maxTime: PromiseConfigMaxTime[];
}

export interface PARAM_ROW_INTERFACE {
  id: number;
  countryId: number;
  dynamicPromise: boolean;
  defaultOrFixedTime?: string | number;
  serviceType: string;
  posNo: string;
  posName: string;
  zoneId: number;
  zoneName: string;
  status: string;
  rulesData: RulesData;
  orderVelocityRuleValues: OrderVelocityRuleValue[];
  type: string;
  automatedConfig: AutomatedConfig;
  promiseConfig: PromiseConfig;
}

export interface PARAM_INTERFACE {
  row: PARAM_ROW_INTERFACE;
}

export const RULE_ENGINE_LISTING_TEXT_CONSTANT = {
  ACTIVE: "Active",
  IN_ACTIVE: "In-Active",
  STATIC: "STATIC",
  DYNAMIC: "DYNAMIC",
  AUTOMATED: "AUTOMATED",
  N_A: "N/A",
  NO_DATA: "---",
  EMPTY_COLUMN: "-",
  NO_DATA_AVAILABLE: "No data available",
};

export const HEADER_NAME_CONSTANT = {
  POS_NO: "POS No",
  POS_NAME: "POS Name",
  ZONE_NAME: "Zone Name",
  STATUS: "Status",
  TYPE_OF_PROMISE: "Type of Promise",
  PROMISE_TYPE: "Promise Type",
  MINIMUM_PROMISE_TIME: "Minimum Promise Time",
  MAXIMUM_PROMISE_TIME: "Maximum Promise Time",
  MAXIMUM_PROMISE_TIME_HOUR: "Maximum Promise Time Hour",
  MAXIMUM_PROMISE_TIME_MINUTES: "Maximum Promise Time Minutes",
  LAST_MILE_TIME: "Last Mile Time",
  TYPE_OF_VEHICLE: "Type of Vehicle",
  HEAVIEST_VEHICLE: "Heaviest Vehicle",
  VEHICLE_TYPE_WEIGHT_LIST: "Vehicle Type Weight List",
  VEHICLE_TYPE: "Vehicle Type",
  THRESHOLD_WEIGHT: "Threshold Weight",
  ITEM_COUNT: "Item Count",
  COUNT_THRESHOLD: " Count Threshold",
  COUNT_INCREMENTAL_TIME: " Count Incremental Time",
  WEIGHT_OF_CONSIGNMENT: "Weight of Consignment",
  ORDER_VELOCITY: "Order Velocity",
  WEIGHT_THRESHOLD: "Weight Threshold",
  WEIGHT_INCREMENTAL_TIME: "Weight Incremental Time",
  INCREMENTAL_TIME_FOR_SCALABLE_ITEMS: "Incremental Time for Scalable Items",
  ACTIONS: "Actions",
  DAYS: "Days",
  TIME_RANGE: "Time Range",
  ORDER_THRESHOLD: "Order Threshold",
  EXTRA_PROMISE_TIME: "Extra Promise Time",
  CHANGE_EXISTING_CONFIGURATION: "Change existing configuration",
  USE_EXISTING_CONFIGURATION: "Use existing configuration",
  ADD_OTHER_DAYS: "Add Other Days",
  PLEASE_SELECT_PROMISE_TYPE: "Please select promise type",
  PLEASE_ASSIGN_POS_ZONE: "Please assign at least one POS Zone for the rule.",
  PLEASE_DEFINE_MAXIMUM_PROMISE_FOR_ALL_DAYS: "Please define maximum promise for all days of the week.",
  PLEASE_ENTER_DAYS: "Please enter days",
  PLEASE_ENTER_MAXIMUM_PROMISE_HOUR: "Please enter maximum promise hour",
  PLEASE_ENTER_MAXIMUM_PROMISE_MINUTES: "Please enter maximum promise minutes",
  PLEASE_ENTER_VALID_PROMISE_HOUR_AND_MINUTES: "Please enter valid promise hour and minutes",
  PLEASE_PROVIDE_VEHICLE_TYPE_RULE: "Please provide vehicle type rule",
  PLEASE_PROVIDE_VALID_THRESHOLD_VALUE: "Please provide valid threshold value",
  ADD_PRODUCTS_IN_TYPE: "Please add products in type",
  SELECT_PRODUCTS_FROM_CATALOG: "Please select products from catalog",
  ENTER_INCREMENTAL_TIME: "Please enter incremental time for ",
  NO_CONFIGURATION_PRESENT: "No promise configuration change found to save/update",
  ITEM_COUNT_THRESHOLD_LIMIT_ERROR: "Please enter item count threshold limit",
  INCREMENTAL_TIME_ABOVE_THRESHOLD_ERROR: "Please enter incremental time above threshold",
  ITEM_COUNT_THRESHOLD_WEIGHT_LIMIT_ERROR: "Please enter item count threshold weight limit",
  INCREMENTAL_TIME_ABOVE_WEIGHT_THRESHOLD_ERROR: "Please enter incremental time above weight threshold",
  ORDER_VELOCITY_DAYS_ERROR: "Please enter order velocity days",
  ORDER_VELOCITY_ITEM_RANGE_ERROR: "Please enter order velocity item range",
  ORDER_VELOCITY_ORDER_THRESHOLD_ERROR: "Please enter order velocity order threshold",
  ORDER_VELOCITY_EXTRA_PROMISE_ERROR: "Please enter order velocity extra promise",
  ORDER_VELOCITY_CONFIGURATION_ERROR: "Please provide order velocity configuration",
  USE_THIS_VALUE_IN_CALCULATION: "Use this value in calculation",
};

export const rowsRecord = [
  { field: "posNo", headerName: HEADER_NAME_CONSTANT.POS_NO },
  { field: "posName", headerName: HEADER_NAME_CONSTANT.POS_NAME },
  { field: "zoneName", headerName: HEADER_NAME_CONSTANT.ZONE_NAME },
  { field: "status", headerName: HEADER_NAME_CONSTANT.STATUS },
  { field: "promiseType", headerName: HEADER_NAME_CONSTANT.PROMISE_TYPE },
  { field: "minimumPromiseTime", headerName: HEADER_NAME_CONSTANT.MINIMUM_PROMISE_TIME },
  { field: "maximumPromiseTime", headerName: HEADER_NAME_CONSTANT.MAXIMUM_PROMISE_TIME },
  { field: "lastMileTime", headerName: HEADER_NAME_CONSTANT.LAST_MILE_TIME },
  { field: "typeOfVehicleRule", headerName: HEADER_NAME_CONSTANT.TYPE_OF_VEHICLE },
  { field: "itemCountRule", headerName: HEADER_NAME_CONSTANT.ITEM_COUNT },
  { field: "weightRuleOfConsignment", headerName: HEADER_NAME_CONSTANT.WEIGHT_OF_CONSIGNMENT },
  { field: "countIncrementalTime", headerName: HEADER_NAME_CONSTANT.ORDER_VELOCITY },
  { field: "scalableItems", headerName: HEADER_NAME_CONSTANT.INCREMENTAL_TIME_FOR_SCALABLE_ITEMS },
  { field: "actions", headerName: HEADER_NAME_CONSTANT.ACTIONS },
];
