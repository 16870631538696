import React from "react";

import { toast } from "react-toastify";

import { ReactComponent as FailureIcon } from "../../assets/svg/FailureIcon.svg";
import { ReactComponent as SuccessIcon } from "../../assets/svg/SuccessIcon.svg";
import { ReactComponent as WarningIcon } from "../../assets/svg/WarningIcon.svg";

// Constants
import { ALERT_TYPES } from "../../../config/constants";

import toastStyles from "./CustomeAlertStyles";

const SuccessToast = ({ message }) => {
  const classes = toastStyles();
  return (
    <div className={classes.toastMessageContainer}>
      <SuccessIcon className={classes.icon} />
      {message}
    </div>
  );
};

const ErrorToast = ({ message }) => {
  const classes = toastStyles();

  return (
    <div className={classes.toastMessageContainer}>
      <FailureIcon className={classes.icon} />
      <div>{message}</div>
    </div>
  );
};

const WarnToast = ({ message }) => {
  const classes = toastStyles();

  return (
    <div className={classes.toastMessageContainer}>
      <WarningIcon className={classes.icon} />
      <div>{message}</div>
    </div>
  );
};

const CustomAlert = (type, message) => {
  switch (type) {
    case ALERT_TYPES.ERROR:
      return toast.error(<ErrorToast message={message} />);
    case ALERT_TYPES.SUCCESS:
      return toast.success(<SuccessToast message={message} />);
    case ALERT_TYPES.WARN:
      return toast.warn(<WarnToast message={message} />);
    default:
      return toast.success(message);
  }
};

export default CustomAlert;
