import React from "react";

import { Grid, SvgIcon, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";

import Pencil from "../../assets/svg/Pencil.svg";
import Checked from "../../assets/svg/CheckedIconBlack.svg";
import { ReactComponent as ForwardIconRight } from "../../assets/svg/ForwardIconRight.svg";

import { TABLE_BODY_NO_KML_FILE_ISSUE, TABLE_BODY_UPLOAD_KML_FILE_ISSUE } from "../Utils/PlolygonServicesConstants";
import { allowPolygonServiceEdit } from "../Utils/PlolygonServicesUtils";
import { GetNoDataPresentAfterFiltereApplied, GetNoDataPresentAfterSearchApplied, GetNoDataPresentErrorViewUtils } from "../../common/App/AppViewUtils";

interface PlolygonServicesCardBodyInterface {
  classes: any;
  rowsData: any;
  handle_make_row_editable: any;
  mapp_pos_zone_for_polygon: any;
  currentCountry: any;
  userInfo: any;
  appCountStateObj: any;
  appFilterPopUpObj: any;
  //
  hanlePolygonDetailsDrawerOpen: any;
  appStateObj: any;
}

const PlolygonServicesCardBody = (props: PlolygonServicesCardBodyInterface) => {
  const { classes, rowsData, handle_make_row_editable, mapp_pos_zone_for_polygon, currentCountry, userInfo, appCountStateObj, appFilterPopUpObj, hanlePolygonDetailsDrawerOpen, appStateObj } = props;

  return (
    <TableBody>
      {rowsData && Array.isArray(rowsData) && rowsData.length > 0 ? (
        rowsData.map((record: any, recordIndex: any) => (
          <TableRow key={`${recordIndex}-record-rowsData-PlolygonServicesCardBody`}>
            <TableCell align="left" className={`${classes.cellDataStyle} ${classes.cellPolygonIdStyle}`}>{`${record?.polygonId || ""}`}</TableCell>
            <TableCell align="left" className={`${classes.cellDataStyle} ${classes.cellPolygonNameStyle}`}>{`${record?.polygonName || ""}`}</TableCell>
            {Object.keys(record.serviceTypeMapping).map((serviceType: any, serviceTypeIndex: any) => (
              <TableCell align="left" className={`${classes.cellDataStyle} ${classes.cellPolygonServiceStyle}`} key={`${serviceTypeIndex}-serviceTypeIndex-${recordIndex}`}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    {record.serviceTypeMapping[serviceType] !== undefined && record.serviceTypeMapping[serviceType].isServiceable !== undefined ? (
                      record.serviceTypeMapping[serviceType].isServiceable ? (
                        <Typography className={classes.polygonServiceDetailsSpanStyle}>{`${record.serviceTypeMapping[serviceType]?.address || ""}`}</Typography>
                      ) : (
                        <Typography className={classes.polygonNonServiceableSpanStyle}>{`Non Serviceable Area`}</Typography>
                      )
                    ) : (
                      <Typography className={classes.polygonServiceDetailsSpanStyle}>{`-`}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    {record.isEditable ? (
                      <img src={Pencil} alt="Edit" className={classes.inlineActionIconStyle} onClick={() => mapp_pos_zone_for_polygon(recordIndex, serviceType)} />
                    ) : (
                      <img src={Pencil} alt="Edit" className={classes.inlineActionIconHiddenStyle} onClick={undefined} />
                    )}
                  </Grid>
                </Grid>
              </TableCell>
            ))}
            <TableCell align="left" className={`${classes.cellDataStyle} ${classes.cellPolygonLastUpdatedStyle}`}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  {`${record?.lastModifiedAt || "-"}`}
                  <SvgIcon
                    key={"Forward_Icon_Right"}
                    className={classes.forwardIconStyle}
                    component={ForwardIconRight}
                    onClick={() => hanlePolygonDetailsDrawerOpen(record?.polygonId, record?.polygonName)}
                  />
                </Grid>
              </Grid>
            </TableCell>
            {userInfo !== undefined &&
              userInfo.userProfile !== undefined &&
              userInfo.userProfile.role !== undefined &&
              userInfo.userProfile.role !== "" &&
              allowPolygonServiceEdit(userInfo.userProfile.role) && (
                <TableCell align="left" className={`${`${classes.cellDataStyle}`} ${classes.cellPolygonActionStyle}`}>
                  {!record.isEditable ? (
                    <img src={Pencil} alt="Edit" className={classes.actionIconStyle} onClick={() => handle_make_row_editable(recordIndex)} />
                  ) : (
                    <img src={Checked} alt="Checked" className={classes.actionIconStyle} onClick={() => handle_make_row_editable(recordIndex)} />
                  )}
                </TableCell>
              )}
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell rowSpan={1} colSpan={7} className={classes.noDataRowStyle}>
            {appFilterPopUpObj !== undefined &&
            ((appFilterPopUpObj.standardFilter !== undefined && Array.isArray(appFilterPopUpObj.standardFilter) && appFilterPopUpObj.standardFilter.length > 0) ||
              (appFilterPopUpObj.expressFilter !== undefined && Array.isArray(appFilterPopUpObj.expressFilter) && appFilterPopUpObj.expressFilter.length > 0) ||
              (appFilterPopUpObj.defaultFilter !== undefined && Array.isArray(appFilterPopUpObj.defaultFilter) && appFilterPopUpObj.defaultFilter.length > 0)) ? (
              <GetNoDataPresentAfterFiltereApplied classes={classes} />
            ) : appStateObj !== undefined && appStateObj.searchValue !== undefined && appStateObj.searchValue !== "" ? (
              <GetNoDataPresentAfterSearchApplied classes={classes} />
            ) : appCountStateObj !== undefined && appCountStateObj.selectedTileFilter !== undefined && Number(appCountStateObj.selectedTileFilter) !== 0 ? (
              <GetNoDataPresentAfterFiltereApplied classes={classes} />
            ) : (
              <GetNoDataPresentErrorViewUtils classes={classes} currentCountry={currentCountry} ErrorMessage={TABLE_BODY_NO_KML_FILE_ISSUE} SecondaryErrorMessage={TABLE_BODY_UPLOAD_KML_FILE_ISSUE} />
            )}
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default React.memo(PlolygonServicesCardBody);
