import produce from "immer";
import moment from "moment";

import CustomAlert from "../../common/CustomAlert/CustomAlert";

import { ALERT_TYPES, API_RESPONSE_STATUS, HH_MM_SS } from "../../../config/constants";
import { StandardServiceInitialStateInterface } from "./StandardInterface";
import { isEmptyOrNull, isObjectEmptyOrNull, isUndefined } from "../../../utils/DataUtils";
import { getFoodTypesFromFoodSubTypes } from "../utils/SlotServicesUtils";
import { convertMinutesToHours } from "../../../utils/helperFunctions";
import { DEFAULT_TEMPLATE_SLOT, foodSplitRegex } from "../utils/constants";
import { getBufferCapacityConfigKeyNameFromZoneTab, getKeyNameFromZoneTab } from "../../../utils/ServiceTypeUtils";
import {
  DEFAULT_CONFIG_TAB_CONFIGURATIONS_INTERFACE,
  DYNAMIC_ROUTING_CONFIG_OBJ,
  PosInterface,
  PosZoneMapListDataInterface,
  PosZoneServiceTypeList,
  STANDARD_SERVICE_CONSTANT,
  SecondaryStoresInterface,
  ZoneServiceTypeListInterface,
} from "./StandardServiceConstant";

export const handle_save_configuration_handler = (slots: any, posNo: string | number) => {
  let new_config_tabs = {} as any;
  new_config_tabs = slots.map((slot: any) => ({
    // Zone
    zoneId: getKeyNameFromZoneTab(slot, "id"),
    zoneName: getKeyNameFromZoneTab(slot, "zoneName"),
    zoneServiceCutOffTime: null,
    zoneShowFirstSlotAt: null,
    zoneServiceTypeMappingId: getKeyNameFromZoneTab(slot, "serviceTypeMappingId"),
    zoneCapacityThreshold: getKeyNameFromZoneTab(slot, "capacityThreshold"),
    zoneTimeThreshold: getKeyNameFromZoneTab(slot, "timeThreshold"),
    zoneStatus: getKeyNameFromZoneTab(slot, "capacityThreshold"),
    // optimisation
    slotOptimizationEnabled: getKeyNameFromZoneTab(slot, "capacityThreshold") && getKeyNameFromZoneTab(slot, "timeThreshold") ? true : false,
    // Food
    foodTypes: getFoodTypesFromFoodSubTypes(slot?.foodTypeMapping?.foodTypes),
    foodSubType: slot?.foodTypeMapping?.foodTypes?.filter((fst: any) => fst !== "NON_FOOD").join(foodSplitRegex),
    // slot
    showFirstSlotAtOption: slot?.zone?.zoneConfig?.showFirstSlotAt ? true : false,
    showFirstSlotAt: slot?.zone?.zoneConfig?.showFirstSlotAt ? moment(slot.zone.zoneConfig.showFirstSlotAt, HH_MM_SS) : null,
    showServiceCutOffTimeOption: slot?.zone?.zoneConfig?.serviceCutOffTime ? true : false,
    serviceCutOffTime: slot?.zone?.zoneConfig?.serviceCutOffTime ? moment(slot.zone.zoneConfig.serviceCutOffTime, HH_MM_SS) : null,
    timeThreshold: slot?.zone?.zoneConfig?.timeThreshold ? convertMinutesToHours(slot.zone.zoneConfig.timeThreshold).hours : "",
    selectedAreas: slot?.selectedAreas || [],
    slotsGeneratorForms: Object.keys(slot.dayWiseSlots).map((dayWiseSlot) => ({
      isEdit: true,
      days: [dayWiseSlot],
      generatedSlots:
        slot && slot?.dayWiseSlots && slot.dayWiseSlots[dayWiseSlot] !== undefined
          ? slot.dayWiseSlots[dayWiseSlot].map((inner_slot: any) => ({
              ...inner_slot,
              startTime: moment(inner_slot.startTime, HH_MM_SS),
              endTime: moment(inner_slot.endTime, HH_MM_SS),
              status: inner_slot.status,
              time: `${moment(inner_slot.startTime, HH_MM_SS).format("HH:mm")} - ${moment(inner_slot.endTime, HH_MM_SS).format("HH:mm")}`,
              bufferTimeHours: convertMinutesToHours(inner_slot.bufferTime).hours,
              bufferTimeMinutes: convertMinutesToHours(inner_slot.bufferTime).minutes,
              orderCutOff: moment(inner_slot.startTime, HH_MM_SS).subtract(convertMinutesToHours(inner_slot.bufferTime).minutes, "m").format("HH:mm"),
              slotAction: "CHANGE", // ADD, UPDATE, DELETE
              canBeUpdated: false,
            }))
          : [],
      quotas: slot?.quotaSetting
        ? (slot.quotaSetting.find((q: any) => q.day === dayWiseSlot) &&
            slot.quotaSetting
              .find((q: any) => q.day === dayWiseSlot)
              .quotaDetails.map((q: any) => ({
                ...q,
                quotaCutOffTime: convertMinutesToHours(q.quotaCutOffTime).hours,
              }))) ||
          []
        : [],
    })),
    capacityThreshold: getKeyNameFromZoneTab(slot, "capacityThreshold"),
    status: getKeyNameFromZoneTab(slot, "capacityThreshold"),
    bufferCapacityConfig: {
      active: slot?.zone?.bufferCapacityConfig?.active || false,
      id: getBufferCapacityConfigKeyNameFromZoneTab(slot, "id"),
      capacityUtilized: getBufferCapacityConfigKeyNameFromZoneTab(slot, "capacityUtilized"),
      reducedBufferTime: getBufferCapacityConfigKeyNameFromZoneTab(slot, "reducedBufferTime"),
      maxCapacity: getBufferCapacityConfigKeyNameFromZoneTab(slot, "maxCapacity"),
    },
    isDynamicRoutingConfigChanges: false,
    dynamicRoutingConfig: {
      ...DYNAMIC_ROUTING_CONFIG_OBJ,
      posNo: posNo,
      zone: getKeyNameFromZoneTab(slot, "zoneName"),
      secondaryStores:
        slot?.associatedStores?.length > 0
          ? slot?.associatedStores?.map((element: SecondaryStoresInterface) => ({
              posNo: element.posNo,
              posName: element.posName,
              zone: element.zone,
              rank: element.rank,
              latitude: element.latitude,
              longitude: element.longitude,
              zoneId: element.zoneId,
              status: element.status,
            }))
          : [],
    },
  }));
  return new_config_tabs;
};

export const is_request_already_presnt_by_admin_util = (configTabs: any, standardServiceState: any, pendingApprovalRequestRecordListService: any, posDetails: any) => {
  let error_message = "";
  if (
    configTabs !== undefined &&
    Array.isArray(configTabs) &&
    configTabs.length > 0 &&
    standardServiceState !== undefined &&
    standardServiceState.selectedConfigTab !== undefined &&
    configTabs[standardServiceState.selectedConfigTab] !== undefined &&
    configTabs[standardServiceState.selectedConfigTab].zoneName !== undefined &&
    configTabs[standardServiceState.selectedConfigTab].zoneName !== "" &&
    pendingApprovalRequestRecordListService !== undefined &&
    pendingApprovalRequestRecordListService.fetchStatus !== undefined &&
    pendingApprovalRequestRecordListService.fetchStatus === API_RESPONSE_STATUS.SUCCEEDED &&
    pendingApprovalRequestRecordListService.data !== undefined &&
    Array.isArray(pendingApprovalRequestRecordListService.data) &&
    pendingApprovalRequestRecordListService.data.length > 0
  ) {
    let obj = pendingApprovalRequestRecordListService.data.find(
      (request: any) => request.posNo === posDetails?.posNo && request.zoneName === configTabs[standardServiceState.selectedConfigTab].zoneName
    );
    if (obj !== undefined && obj.requestId !== undefined && obj.requestId !== "") {
      error_message = `Note : A request ${obj.requestId} is already raised for this POS : ${posDetails?.posNo || "POS"} and Zone : ${
        configTabs[standardServiceState.selectedConfigTab].zoneName || "Zone"
      } by ${obj.requester || "Admin"}.`;
    }
  }
  return error_message;
};

const is_request_already_present = (
  date_data: any,
  standardServiceState: StandardServiceInitialStateInterface,
  pendingApprovalRequestRecordListService: any,
  posNo: any,
  configTabs: any,
  serviceTypeName: any
) => {
  let obj = {} as any;
  if (
    configTabs !== undefined &&
    standardServiceState !== undefined &&
    standardServiceState.selectedConfigTab !== undefined &&
    configTabs[standardServiceState.selectedConfigTab] !== undefined &&
    configTabs[standardServiceState.selectedConfigTab].zoneName !== undefined &&
    pendingApprovalRequestRecordListService !== undefined &&
    pendingApprovalRequestRecordListService.fetchStatus !== undefined &&
    pendingApprovalRequestRecordListService.fetchStatus === API_RESPONSE_STATUS.SUCCEEDED &&
    pendingApprovalRequestRecordListService.data !== undefined &&
    Array.isArray(pendingApprovalRequestRecordListService.data) &&
    pendingApprovalRequestRecordListService.data.length > 0
  ) {
    if (date_data !== undefined && date_data !== "") {
      obj = pendingApprovalRequestRecordListService.data.find(
        (request: any) =>
          request.activationDate === date_data &&
          request.posNo === posNo &&
          request.zoneName === configTabs[standardServiceState.selectedConfigTab].zoneName &&
          request?.serviceType.toLowerCase() === serviceTypeName.toLowerCase()
      );
      if (obj !== undefined && obj.requestId !== undefined && obj.requestId !== "") {
        let error_message = `A request ${obj.requestId} is already raised for this implementation date by ${obj.requester || "Admin"}. Please request action on the same.`;
        CustomAlert(ALERT_TYPES.ERROR, error_message);
      }
    }
  }
  return obj;
};

const getDateFromPicker = (dateData: any) => {
  return moment(dateData).format("YYYY-MM-DD");
};

export const _handle_date_change_event_util = (
  standardServiceState: StandardServiceInitialStateInterface,
  event: any,
  picker: any,
  pendingApprovalRequestRecordListService: any,
  posNo: any,
  configTabs: any,
  serviceTypeName: any
) => {
  let newStandardServiceState: any = produce(standardServiceState, (draft: StandardServiceInitialStateInterface) => {
    draft.loading = false;
    if (event && event.target) {
      if (event.target.name === "date") {
        const dateStart = getDateFromPicker(picker.startDate);
        let isDateValid = false;
        let data_present_obj = {};
        if (moment(dateStart).isSameOrAfter(moment(new Date()).format("YYYY-MM-DD"))) {
          data_present_obj = is_request_already_present(dateStart, standardServiceState, pendingApprovalRequestRecordListService, posNo, configTabs, serviceTypeName);
          if (isObjectEmptyOrNull(data_present_obj)) {
            isDateValid = true;
          }
        } else {
          CustomAlert(ALERT_TYPES.ERROR, "Please provide a valid date");
        }
        draft.activationDate = dateStart;
        draft.activationDatelabel = dateStart;
        draft.isDateValid = isDateValid;
        draft.data_present_obj = data_present_obj;
      }
    }
  });
  return newStandardServiceState;
};

export const _validate_send_for_approval_form = (standardServiceState: StandardServiceInitialStateInterface, configTabs: any, countryId: any, showError: boolean, requesterComments: any) => {
  let pay_load_object = {} as any;
  let responseValue: any = {};
  let is_different_value_found: boolean = false;

  if (
    standardServiceState !== undefined &&
    standardServiceState.selectedConfigTab !== undefined &&
    standardServiceState.selectedConfigTab >= 0 &&
    standardServiceState.originalConfigTab !== undefined &&
    Array.isArray(standardServiceState.originalConfigTab) &&
    standardServiceState.originalConfigTab.length > 0 &&
    standardServiceState.originalConfigTab[standardServiceState.selectedConfigTab] !== undefined &&
    standardServiceState.originalConfigTab[standardServiceState.selectedConfigTab].zoneId !== undefined &&
    configTabs !== undefined &&
    Array.isArray(configTabs) &&
    configTabs.length > 0 &&
    configTabs[standardServiceState.selectedConfigTab] !== undefined &&
    configTabs[standardServiceState.selectedConfigTab].zoneId !== undefined &&
    configTabs[standardServiceState.selectedConfigTab].zoneId !== "" &&
    configTabs[standardServiceState.selectedConfigTab].slotsGeneratorForms !== undefined &&
    Array.isArray(configTabs[standardServiceState.selectedConfigTab].slotsGeneratorForms) &&
    configTabs[standardServiceState.selectedConfigTab].slotsGeneratorForms.length > 0
  ) {
    const originalConfigTab = standardServiceState.originalConfigTab[standardServiceState.selectedConfigTab];
    const configTab = configTabs[standardServiceState.selectedConfigTab];

    pay_load_object = {
      countryId: countryId,
      activationDate: standardServiceState.activationDate,
      serviceTypeId: "",
      cncId: "",
      zoneConfig: {
        id: configTab?.zoneId || "",
        zoneName: configTab?.zoneName || "",
        serviceCutOffTime: configTab?.zoneServiceCutOffTime || "",
        showFirstSlotAt: configTab?.zoneShowFirstSlotAt || "",
        serviceTypeMappingId: configTab?.zoneServiceTypeMappingId || "",
        capacityThreshold: configTab?.zoneCapacityThreshold || "",
        timeThreshold: configTab?.zoneTimeThreshold || "",
        status: configTab?.zoneStatus || "",
      },
      templateSlots: [],
      updatedDays: [],
      defaultTemplateSlots: [],
      requesterComments: "",
    };

    if (requesterComments !== undefined && requesterComments !== "") {
      pay_load_object.requesterComments = requesterComments;
    }

    if (
      standardServiceState !== undefined &&
      standardServiceState.configurationBulkUpdatedObj !== undefined &&
      standardServiceState.configurationBulkUpdatedObj.bulkUpdatedDays !== undefined &&
      Array.isArray(standardServiceState.configurationBulkUpdatedObj.bulkUpdatedDays) &&
      standardServiceState.configurationBulkUpdatedObj.bulkUpdatedDays.length > 0
    ) {
      pay_load_object.updatedDays = [...standardServiceState.configurationBulkUpdatedObj.bulkUpdatedDays];
    }

    configTab.slotsGeneratorForms.forEach((slot_form: any, slot_form_index: any) => {
      if (slot_form !== undefined && slot_form.generatedSlots !== undefined && Array.isArray(slot_form.generatedSlots) && slot_form.generatedSlots.length > 0) {
        let is_generated_slot_deleted: number = 0;
        slot_form.generatedSlots.forEach((slot: any, slot_index: any) => {
          if (slot !== undefined) {
            let template_slot_object = {
              id: slot?.id || 0,
              posId: slot.posId,
              day: slot.day,
              zoneId: slot.zoneId,
              status: slot.status,
              createdAt: slot.createdAt,
              slotAction: slot?.slotAction || "UPDATE", // ADD, UPDATE, DELETE
              startTime: null,
              endTime: null,
              slotCapacity: null,
              bufferTime: null,
              serviceTypeId: slot?.serviceTypeId || 1,
            } as any;

            if (slot !== undefined && slot.slotAction !== undefined && slot.slotAction !== "") {
              if (slot.slotAction === "DELETE") {
                is_generated_slot_deleted++;
                is_different_value_found = true;
              } else if (slot.slotAction === "CHANGE") {
                template_slot_object.slotAction = "UPDATE";
              }

              if (isEmptyOrNull(slot.startTime)) {
                responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-startTime`] = `Please provide slot start time ${
                  slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."
                }`;
              } else if (!moment(slot.startTime).isValid()) {
                responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-startTime`] = `Please provide valid slot start time ${
                  slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."
                }`;
              } else {
                template_slot_object.startTime = moment(slot.startTime).format(HH_MM_SS);
                if (slot.slotAction !== undefined && slot.slotAction !== "" && slot.slotAction !== "DELETE") {
                  slot_form.generatedSlots.forEach((newSlot: any, newSlotIndex: any) => {
                    if (
                      newSlotIndex !== undefined &&
                      newSlotIndex !== slot_index &&
                      newSlot.slotAction !== undefined &&
                      newSlot.slotAction !== "" &&
                      newSlot.slotAction !== "DELETE" &&
                      newSlot !== undefined &&
                      !isEmptyOrNull(newSlot.startTime) &&
                      !isEmptyOrNull(newSlot.endTime)
                    ) {
                      let startTimeFlag = moment(newSlot.startTime).isBetween(moment(slot.startTime), moment(slot.endTime));
                      if (!startTimeFlag) {
                        startTimeFlag = moment(newSlot.startTime).isSame(moment(slot.startTime));
                      }
                      //
                      let endTimeFlag = moment(newSlot.endTime).isBetween(moment(slot.startTime), moment(slot.endTime));
                      if (!endTimeFlag) {
                        endTimeFlag = moment(newSlot.endTime).isSame(moment(slot.endTime));
                      }
                      if (startTimeFlag && endTimeFlag) {
                        let error_message = "One or more slots is a subset of another slot. Please change slot timings.";
                        responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-time`] = error_message;
                      }
                    }
                  });
                }
              }

              if (isEmptyOrNull(slot.endTime)) {
                responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-endTime`] = `Please provide slot end time ${
                  slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."
                }`;
              } else if (!moment(slot.endTime).isValid()) {
                responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-endTime`] = `Please provide valid slot end time ${
                  slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."
                }`;
              } else {
                template_slot_object.endTime = moment(slot.endTime).format(HH_MM_SS);
              }

              if (moment(slot.endTime).isBefore(moment(slot.startTime))) {
                responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-slotCapacity`] = `Please provide valid slot start/end time ${
                  slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."
                }`;
              }

              if (isEmptyOrNull(slot.slotCapacity)) {
                responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-slotCapacity`] = `Please provide valid slot capacity ${
                  slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."
                }`;
              } else {
                template_slot_object.slotCapacity = parseInt(slot.slotCapacity);
              }

              if (isEmptyOrNull(slot.bufferTimeHours) || isEmptyOrNull(slot.bufferTimeMinutes)) {
                responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-bufferTime`] = `Please provide valid buffer hrs/mins ${
                  slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."
                }`;
              } else {
                template_slot_object.bufferTime = parseInt(slot.bufferTimeHours * 60 + slot.bufferTimeMinutes);
              }

              //
              // to check if change is made or the values are same and it is requested for approval
              //
              if (
                originalConfigTab !== undefined &&
                originalConfigTab.slotsGeneratorForms !== undefined &&
                Array.isArray(originalConfigTab.slotsGeneratorForms) &&
                originalConfigTab.slotsGeneratorForms.length > 0 &&
                originalConfigTab.slotsGeneratorForms[slot_form_index] !== undefined &&
                originalConfigTab.slotsGeneratorForms[slot_form_index].generatedSlots !== undefined &&
                Array.isArray(originalConfigTab.slotsGeneratorForms[slot_form_index].generatedSlots) &&
                originalConfigTab.slotsGeneratorForms[slot_form_index].generatedSlots.length > 0 &&
                originalConfigTab.slotsGeneratorForms[slot_form_index].generatedSlots[slot_index] !== undefined
              ) {
                const { startTime, endTime, slotCapacity, bufferTimeHours, bufferTimeMinutes } = originalConfigTab.slotsGeneratorForms[slot_form_index].generatedSlots[slot_index];

                if (originalConfigTab.slotsGeneratorForms[slot_form_index].generatedSlots.length !== slot_form.generatedSlots.length) {
                  is_different_value_found = true;
                }

                if (slotCapacity !== undefined) {
                  if (parseInt(slotCapacity) !== parseInt(slot.slotCapacity)) {
                    is_different_value_found = true;
                  }
                }

                if (bufferTimeHours !== undefined && bufferTimeMinutes !== undefined) {
                  if (bufferTimeHours !== slot.bufferTimeHours || bufferTimeMinutes !== slot.bufferTimeMinutes) {
                    is_different_value_found = true;
                  }
                }

                if (startTime !== undefined) {
                  if (!moment(startTime).isSame(moment(slot.startTime))) {
                    is_different_value_found = true;
                  }
                }

                if (endTime !== undefined) {
                  if (!moment(endTime).isSame(moment(slot.endTime))) {
                    is_different_value_found = true;
                  }
                }
              }
            }

            if (pay_load_object.serviceTypeId === "") {
              pay_load_object.serviceTypeId = slot.serviceTypeId;
            }
            pay_load_object.templateSlots.push(template_slot_object);
          }
        });

        if (slot_form.generatedSlots.length === is_generated_slot_deleted) {
          let day_name = "";
          if (slot_form.days !== undefined && Array.isArray(slot_form.days) && slot_form.days.length > 0 && slot_form.days[0] !== undefined && slot_form.days[0] !== "") {
            day_name = `for ${slot_form.days[0]}`;
          }
          responseValue[`slot_index-${slot_form_index}-slot_form_index-is_generated_slot_deleted`] = `No Slot found for ${day_name}.`;
        }
      }
    });
  }

  if (responseValue && Object.keys(responseValue).length !== 0) {
    showError !== undefined && showError && CustomAlert(ALERT_TYPES.ERROR, responseValue[Object.keys(responseValue)[0]]);
    return {};
  }

  if (is_different_value_found === false) {
    showError !== undefined && showError && CustomAlert(ALERT_TYPES.ERROR, "No change found for approval");
    return {};
  }

  return pay_load_object;
};

export const update_make_slot_editable = (configTab: any, tabIndex: any, generatorFormIndex: any, slot_index: any) => {
  let new_config_tab: any = produce(configTab, (draft: any) => {
    draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index].canBeUpdated = !draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index].canBeUpdated;
  });
  return new_config_tab;
};

export const update_handle_individual_slot_field_change = (configTab: any, tabIndex: any, generatorFormIndex: any, slot_index: any, name: any, value: any) => {
  let new_config_tab: any = produce(configTab, (draft: any) => {
    if (name === "isQuotaEnabled") {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index][name] = !draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index][name];
    } else {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index][name] = value;
    }
    if (draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index].slotAction !== "ADD") {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index].slotAction = "UPDATE";
    }
  });
  return new_config_tab;
};

export const add_new_template_slot_util = (configTab: any, tabIndex: any, generatorFormIndex: any, day: any, posDetails: any) => {
  let new_config_tab: any = produce(configTab, (draft: any) => {
    let newSlot = {
      ...DEFAULT_TEMPLATE_SLOT,
      bufferTimeHours: 0,
      bufferTimeMinutes: 0,
      posId: posDetails.id,
      day: day.join(","),
      zoneId: draft[tabIndex].zoneId,
      isNewSlot: true,
      canBeUpdated: true,
      slotAction: "ADD",
    };
    draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots.push(newSlot);
  });
  return new_config_tab;
};

export const delete_template_slot_util = (configTab: any, tabIndex: any, generatorFormIndex: any, slot_index: any) => {
  let new_config_tab: any = produce(configTab, (draft: any) => {
    if (
      draft !== undefined &&
      draft[tabIndex] !== undefined &&
      draft[tabIndex].slotsGeneratorForms !== undefined &&
      Array.isArray(draft[tabIndex].slotsGeneratorForms) &&
      draft[tabIndex].slotsGeneratorForms.length > 0 &&
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex] !== undefined &&
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots !== undefined &&
      Array.isArray(draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots) &&
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots.length > 0
    ) {
      if (
        draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index] !== undefined &&
        draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index].id !== undefined &&
        draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index].id !== ""
      ) {
        const { startTime, endTime, slotCapacity, bufferTimeHours, bufferTimeMinutes } = draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index];
        let responseValue: any = {};
        if (isEmptyOrNull(startTime)) {
          responseValue[`${slot_index}-slot_index-${slot_index}-slot_form_index-startTime`] = "Please provide slot start time before delete";
        } else if (!moment(startTime).isValid()) {
          responseValue[`${slot_index}-slot_index-${slot_index}-slot_form_index-startTime`] = "Please provide valid slot start time before delete";
        }

        if (isEmptyOrNull(endTime)) {
          responseValue[`${slot_index}-slot_index-${slot_index}-slot_form_index-endTime`] = "Please provide slot end time before delete";
        } else if (!moment(endTime).isValid()) {
          responseValue[`${slot_index}-slot_index-${slot_index}-slot_form_index-endTime`] = "Please provide valid slot end time before delete";
        }

        if (isEmptyOrNull(slotCapacity)) {
          responseValue[`${slot_index}-slot_index-${slot_index}-slot_form_index-slotCapacity`] = "Please provide valid slot capacity before delete";
        }

        if (isEmptyOrNull(bufferTimeHours) || isEmptyOrNull(bufferTimeMinutes)) {
          responseValue[`${slot_index}-slot_index-${slot_index}-slot_form_index-bufferTime`] = "Please provide valid buffer hrs/mins before delete.";
        }

        if (responseValue && Object.keys(responseValue).length !== 0) {
          CustomAlert(ALERT_TYPES.ERROR, responseValue[Object.keys(responseValue)[0]]);
        } else {
          draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index].slotAction = "DELETE";
        }
      } else {
        let allSlots = draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots;
        let filteredSlots = allSlots.filter((item: any) => !!item.id);
        draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots = [...filteredSlots];
      }
    }
  });
  return new_config_tab;
};

export const handle_individual_slot_checkbox_change_util = (configTab: any, tabIndex: any, generatorFormIndex: any, index: any, status: any) => {
  let new_config_tab: any = produce(configTab, (draft: any) => {
    draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index].status = status;
    draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index].slotCapacity = "";
    draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index].bufferTimeHours = "";
    draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index].bufferTimeMinutes = 0;
    draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index].orderCutOff = "";
  });
  return new_config_tab;
};

export const cancel_editing_a_slot_util = (configTab: any, tabIndex: any, generatorFormIndex: any, index: any, slot: any, standard: any) => {
  let unEditedSlotFromStore = standard.length && standard[tabIndex].dayWiseSlots[slot.day].filter((item: any) => item.id === slot.id)[0];
  let { minutes: bufferTimeMinutes, hours: bufferTimeHours } = convertMinutesToHours(unEditedSlotFromStore.bufferTime);
  let unEditedSlot = {
    ...unEditedSlotFromStore,
    time: `${moment(unEditedSlotFromStore.startTime, HH_MM_SS).format("HH:mm")} - ${moment(unEditedSlotFromStore.endTime, HH_MM_SS).format("HH:mm")}`,
    startTime: moment(unEditedSlotFromStore.startTime, HH_MM_SS),
    endTime: moment(unEditedSlotFromStore.endTime, HH_MM_SS),
    bufferTimeHours,
    bufferTimeMinutes,
  };

  let new_config_tab: any = produce(configTab, (draft: any) => {
    draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index] = { ...unEditedSlot };
    draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index].slotAction = "CHANGE";
  });
  return new_config_tab;
};

export const edit_slot_time_util = (configTab: any, tabIndex: any, generatorFormIndex: any, index: any, time: any, elem: any) => {
  let new_config_tab: any = produce(configTab, (draft: any) => {
    draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index][elem] = time;
    if (draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index].slotAction !== "ADD") {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index].slotAction = "UPDATE";
    }
  });
  return new_config_tab;
};

//
// Bulk Update
//
export const validate_generator_form_before_bulk_update = (configTab: any) => {
  let responseValue: any = {};
  if (
    configTab !== undefined &&
    configTab.zoneId !== undefined &&
    configTab.zoneId !== "" &&
    configTab.slotsGeneratorForms !== undefined &&
    Array.isArray(configTab.slotsGeneratorForms) &&
    configTab.slotsGeneratorForms.length > 0
  ) {
    configTab.slotsGeneratorForms.forEach((slot_form: any, slot_form_index: any) => {
      slot_form.generatedSlots.forEach((slot: any, slot_index: any) => {
        const start_error_message = `Please provide slot start time ${slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."}`;
        const end_error_message = `Please provide slot end time ${slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."}`;
        const capacity_error_message = `Please provide valid slot capacity ${slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."}`;
        const buffer_error_message = `Please provide valid buffer hrs/mins ${slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."}`;

        if (isEmptyOrNull(slot.startTime)) {
          responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-startTime`] = start_error_message;
        } else if (!moment(slot.startTime).isValid()) {
          responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-startTime`] = start_error_message;
        }

        if (isEmptyOrNull(slot.endTime)) {
          responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-endTime`] = end_error_message;
        } else if (!moment(slot.endTime).isValid()) {
          responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-endTime`] = end_error_message;
        }

        if (isEmptyOrNull(slot.slotCapacity)) {
          responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-slotCapacity`] = capacity_error_message;
        }

        if (isEmptyOrNull(slot.bufferTimeHours) || isEmptyOrNull(slot.bufferTimeMinutes)) {
          responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-bufferTime`] = buffer_error_message;
        }
      });
    });
  }

  if (responseValue && Object.keys(responseValue).length !== 0) {
    CustomAlert(ALERT_TYPES.ERROR, responseValue[Object.keys(responseValue)[0]]);
    return false;
  }

  return true;
};

export const reset_bulk_update_configuration_for_given_day = (configTab: any, tabIndex: any, generatorFormIndex: any, day: any, standardServiceState: StandardServiceInitialStateInterface) => {
  let new_config_tab: any = produce(configTab, (draft: any) => {
    if (
      standardServiceState !== undefined &&
      standardServiceState.selectedConfigTab !== undefined &&
      standardServiceState.selectedConfigTab >= 0 &&
      standardServiceState.originalConfigTab !== undefined &&
      Array.isArray(standardServiceState.originalConfigTab) &&
      standardServiceState.originalConfigTab.length > 0 &&
      standardServiceState.originalConfigTab[standardServiceState.selectedConfigTab] !== undefined &&
      standardServiceState.originalConfigTab[standardServiceState.selectedConfigTab].slotsGeneratorForms !== undefined &&
      Array.isArray(standardServiceState.originalConfigTab[standardServiceState.selectedConfigTab].slotsGeneratorForms) &&
      standardServiceState.originalConfigTab[standardServiceState.selectedConfigTab].slotsGeneratorForms.length > 0 &&
      standardServiceState.originalConfigTab[standardServiceState.selectedConfigTab].slotsGeneratorForms[generatorFormIndex] !== undefined &&
      standardServiceState.originalConfigTab[standardServiceState.selectedConfigTab].slotsGeneratorForms[generatorFormIndex].days !== undefined &&
      Array.isArray(standardServiceState.originalConfigTab[standardServiceState.selectedConfigTab].slotsGeneratorForms[generatorFormIndex].days) &&
      standardServiceState.originalConfigTab[standardServiceState.selectedConfigTab].slotsGeneratorForms[generatorFormIndex].days[0] !== undefined &&
      standardServiceState.originalConfigTab[standardServiceState.selectedConfigTab].slotsGeneratorForms[generatorFormIndex].days[0] === day
    ) {
      const original_data = standardServiceState.originalConfigTab[standardServiceState.selectedConfigTab].slotsGeneratorForms[generatorFormIndex];
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex] = {};
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex] = original_data;
    }
  });
  return new_config_tab;
};

export const reset_bulk_update_config_day = (standardServiceState: StandardServiceInitialStateInterface, day: any) => {
  let newStandardServiceState: any = produce(standardServiceState, (draft: StandardServiceInitialStateInterface) => {
    if (
      draft !== undefined &&
      draft.configurationBulkUpdatedObj !== undefined &&
      draft.configurationBulkUpdatedObj.bulkUpdatedDays !== undefined &&
      Array.isArray(draft.configurationBulkUpdatedObj.bulkUpdatedDays) &&
      draft.configurationBulkUpdatedObj.bulkUpdatedDays.length > 0
    ) {
      let selectedDaysCopy = [...draft.configurationBulkUpdatedObj.bulkUpdatedDays];
      if (selectedDaysCopy !== undefined && Array.isArray(selectedDaysCopy) && selectedDaysCopy.length >= 0) {
        const isElementPresent = selectedDaysCopy.indexOf(day);
        if (isElementPresent > -1) {
          selectedDaysCopy.splice(isElementPresent, 1);
          draft.configurationBulkUpdatedObj.bulkUpdatedDays = [...selectedDaysCopy];
        }
      }
    }
  });
  return newStandardServiceState;
};

// Dynamic Routing
export const getSecondaryStoreObj = (element: ZoneServiceTypeListInterface, elementIndex: number) => {
  return Object.freeze({
    posNo: element.posNo,
    posName: element.posName,
    zone: element.zoneName,
    rank: elementIndex + 1,
    latitude: 0,
    longitude: 0,
    zoneId: element.zoneId,
    status: element.status,
  }) as SecondaryStoresInterface;
};

export const handleAddSecondaryPosUtils = (configTabs: DEFAULT_CONFIG_TAB_CONFIGURATIONS_INTERFACE[], configTabIndex: number, selectedPosServiceTypeZone: ZoneServiceTypeListInterface[]) => {
  let newConfigTabs = produce(configTabs, (draft: DEFAULT_CONFIG_TAB_CONFIGURATIONS_INTERFACE[]) => {
    if (configTabIndex >= 0) {
      draft[configTabIndex].dynamicRoutingConfig.secondaryStores = [];
      selectedPosServiceTypeZone?.forEach((element: ZoneServiceTypeListInterface, elementIndex: number) => {
        draft[configTabIndex].dynamicRoutingConfig.secondaryStores.push({ ...getSecondaryStoreObj(element, elementIndex) });
      });
    }
  });
  return newConfigTabs;
};

export const removeDynamicRoutingRowUtils = (configTabs: DEFAULT_CONFIG_TAB_CONFIGURATIONS_INTERFACE[], configTabIndex: number, elementIndex: number) => {
  let newConfigTabs = produce(configTabs, (draft: DEFAULT_CONFIG_TAB_CONFIGURATIONS_INTERFACE[]) => {
    if (configTabIndex >= 0 && elementIndex >= 0) {
      draft[configTabIndex].dynamicRoutingConfig.secondaryStores.splice(elementIndex, 1);
    }
  });
  return newConfigTabs;
};

export const getPosZoneObj = (posNo: string | number, posId: string | number, storeName: string | number) => {
  return Object.freeze({
    id: posNo,
    posId: posId,
    posNo: posNo,
    posName: storeName,
    zoneServicetypeList: [] as ZoneServiceTypeListInterface[],
    serviceTypeRecord: 0,
    zoneTypeListRecord: 0,
    isOpened: false,
    isChecked: false,
    serviceTypeId: "",
  }) as PosInterface;
};

export const getZoneSubObj = (posNo: string | number, posId: string | number, storeName: string | number, zoneElement: PosZoneServiceTypeList) => {
  return Object.freeze({
    posId: posId,
    posNo: posNo,
    posName: storeName,
    zoneName: zoneElement.zoneName,
    zoneId: zoneElement.zoneId,
    serviceTypeId: zoneElement.serviceTypeId,
    serviceType: zoneElement.serviceType,
    isChecked: false,
    cncReferenceId: zoneElement.cncReferenceId !== "" ? zoneElement.cncReferenceId : "",
    status: zoneElement?.status || "",
  });
};

export const designPosZoneServiceListRecordUtils = (posZoneMapListData: PosZoneMapListDataInterface[], configTabIndex: number, configTabs: DEFAULT_CONFIG_TAB_CONFIGURATIONS_INTERFACE[]) => {
  const returnObject = { totalRowsCountData: 0, listData: [] } as { totalRowsCountData: number; listData: PosInterface[] };
  if (posZoneMapListData?.length > 0) {
    posZoneMapListData.forEach((element: PosZoneMapListDataInterface) => {
      const { posNo, posId, storeName } = element;
      const mainPosObject = { ...getPosZoneObj(posNo, posId, storeName) };
      const serviceTypeList = new Set<string>();
      const zoneTypeList = new Set<string>();
      if (element?.zoneServiceTypeList?.length > 0) {
        const { zoneServiceTypeList } = element;
        zoneServiceTypeList.forEach((zoneElement: PosZoneServiceTypeList) => {
          serviceTypeList.add(zoneElement.serviceType);
          zoneTypeList.add(zoneElement.zoneName);
          const subPosObject = { ...getZoneSubObj(posNo, posId, storeName, zoneElement) };
          mainPosObject.serviceTypeId = String(zoneElement?.serviceTypeId) || "";
          mainPosObject.zoneServicetypeList.push(subPosObject);
          returnObject.totalRowsCountData++;
        });
      }
      mainPosObject.serviceTypeRecord = serviceTypeList.size;
      mainPosObject.zoneTypeListRecord = zoneTypeList.size;
      returnObject.listData.push(mainPosObject);
    });
  }

  if (configTabIndex >= 0 && !isEmptyOrNull(configTabs[configTabIndex]?.dynamicRoutingConfig?.posNo) && returnObject?.listData?.length > 0) {
    const posIndex = returnObject?.listData?.findIndex((item: PosInterface) => String(item.posNo) === configTabs[configTabIndex]?.dynamicRoutingConfig?.posNo);
    if (posIndex !== -1) {
      returnObject.totalRowsCountData--;
      if (returnObject?.listData[posIndex]?.zoneServicetypeList?.length === 1) {
        returnObject?.listData.splice(posIndex, 1);
      } else {
        const zoneIndex = returnObject?.listData[posIndex]?.zoneServicetypeList?.findIndex(
          (item: ZoneServiceTypeListInterface) => item.zoneName === configTabs[configTabIndex]?.dynamicRoutingConfig?.zone
        );
        if (zoneIndex !== -1) {
          returnObject?.listData[posIndex]?.zoneServicetypeList.splice(zoneIndex, 1);
          if (!isUndefined(returnObject?.listData[posIndex]?.zoneTypeListRecord)) {
            returnObject.listData[posIndex].zoneTypeListRecord--;
          }
        }
      }
    }
  }

  return returnObject;
};

export const handleEmptyPosZoneMappingDataAndLoading = (standardServiceState: StandardServiceInitialStateInterface, loading: boolean, configTabIndex?: number) => {
  let newStandardServiceState: StandardServiceInitialStateInterface = produce(standardServiceState, (draft: StandardServiceInitialStateInterface) => {
    draft.configTabIndex = null;
    if (!isUndefined(configTabIndex) && Number(configTabIndex) >= 0) {
      draft.configTabIndex = configTabIndex;
    }
    draft.loading = loading;
    draft.posZoneServiceRecord = [];
    draft.posZoneServiceFilteredRecord = [];
    draft.selectedPosServiceTypeZone = [];
    draft.totalRowsCountData = 0;
    draft.totalAssignedAreaCount = 0;
    draft.posZoneSearchText = "";
    draft.assignedPosSearchText = "";
  });
  return newStandardServiceState;
};

let checker = (arr: ZoneServiceTypeListInterface[], target: ZoneServiceTypeListInterface[]) => target.every((v) => arr.includes(v));

export const updatePosZoneDetailsAndShowPopUpUtils = (
  standardServiceState: StandardServiceInitialStateInterface,
  totalRowsCountData: number,
  listData: PosInterface[],
  configTabIndex: number,
  configTabs: DEFAULT_CONFIG_TAB_CONFIGURATIONS_INTERFACE[]
) => {
  let newStandardServiceState: StandardServiceInitialStateInterface = produce(standardServiceState, (draft: StandardServiceInitialStateInterface) => {
    draft.totalAssignedAreaCount = 0;
    const recordData: PosInterface[] = [...listData];
    const selectedRecord: ZoneServiceTypeListInterface[] = [];
    if (
      configTabIndex >= 0 &&
      !isUndefined(configTabs) &&
      !isUndefined(configTabs[configTabIndex]?.dynamicRoutingConfig?.secondaryStores?.length) &&
      configTabs[configTabIndex]?.dynamicRoutingConfig?.secondaryStores?.length > 0 &&
      !isEmptyOrNull(configTabs[configTabIndex]?.dynamicRoutingConfig?.posNo)
    ) {
      configTabs[configTabIndex]?.dynamicRoutingConfig?.secondaryStores.forEach((element: SecondaryStoresInterface) => {
        const { posNo, zone } = element;
        const posIndex = listData?.findIndex((item: PosInterface) => String(item.posNo) === posNo);
        if (posIndex >= 0) {
          draft.totalAssignedAreaCount++;
          if (listData[posIndex]?.zoneServicetypeList?.length === 1) {
            recordData[posIndex].isChecked = true;
            recordData[posIndex].zoneServicetypeList[0].isChecked = true;
            selectedRecord.push(recordData[posIndex]?.zoneServicetypeList[0]);
          } else {
            const zoneIndex = recordData[posIndex]?.zoneServicetypeList?.findIndex((item: ZoneServiceTypeListInterface) => item.zoneName === zone);
            if (zoneIndex !== -1) {
              recordData[posIndex].zoneServicetypeList[zoneIndex].isChecked = true;
              selectedRecord.push(recordData[posIndex]?.zoneServicetypeList[zoneIndex]);
            }
          }
          const isAllPresnt = checker(selectedRecord, recordData[posIndex].zoneServicetypeList);
          if (isAllPresnt) {
            recordData[posIndex].isChecked = true;
          }
        }
      });
    }
    draft.loading = false;
    draft.posZoneServiceRecord = recordData;
    draft.posZoneServiceFilteredRecord = recordData;
    draft.selectedPosServiceTypeZone = selectedRecord;
    draft.totalRowsCountData = totalRowsCountData;
    draft.showDynamicRoutingPopUp = true;
    draft.configTabIndex = configTabIndex;
    draft.posZoneSearchText = "";
    draft.assignedPosSearchText = "";
  });
  return newStandardServiceState;
};

export const getAssignedPosZoneMappingListUtils = (standardServiceState: StandardServiceInitialStateInterface) => {
  if (standardServiceState?.selectedPosServiceTypeZone?.length > 0) {
    let filteredList = [...standardServiceState?.selectedPosServiceTypeZone];
    if (!isEmptyOrNull(standardServiceState?.assignedPosSearchText)) {
      filteredList = filteredList.filter(
        (list: any) => list.posNo && list.posNo.toLowerCase().includes(standardServiceState.assignedPosSearchText && standardServiceState.assignedPosSearchText.toLowerCase())
      );
    }
    return filteredList;
  }
  return [];
};

export const designCustomPosZoneMappingFilteredListBasedOnSearch = (standardServiceState: StandardServiceInitialStateInterface, name: string, value: string) => {
  let newStandardServiceState: StandardServiceInitialStateInterface = produce(standardServiceState, (draft: StandardServiceInitialStateInterface) => {
    if (name && name === STANDARD_SERVICE_CONSTANT.POS_ZONE_SEARCH_TEXT && draft?.posZoneServiceRecord?.length > 0) {
      draft.posZoneSearchText = value;
      // is selected data present
      let selectedList = [] as PosInterface[];
      draft.posZoneServiceFilteredRecord.forEach((list: PosInterface) => {
        if (list.isChecked === false) {
          let no_inner_selected: boolean = false;
          list.zoneServicetypeList.forEach((innerList: ZoneServiceTypeListInterface) => {
            if (innerList.isChecked === true) {
              no_inner_selected = true;
            }
          });
          if (no_inner_selected) {
            selectedList.push(list);
          }
        } else {
          selectedList.push(list);
        }
      });

      let resultList = [] as PosInterface[];
      if (!isEmptyOrNull(draft?.posZoneSearchText)) {
        let list_data = draft.posZoneServiceRecord.filter((list: PosInterface) => list?.posNo && String(list?.posNo).toLowerCase().includes(value.toLowerCase()));
        resultList = selectedList.concat(list_data);
      } else {
        resultList = selectedList.concat(draft.posZoneServiceRecord);
      }

      // remove duplicate
      let map = new Map();
      resultList.forEach((item: PosInterface) => {
        if (!map.has(item.id)) {
          map.set(item.id, item);
        }
      });
      draft.posZoneServiceFilteredRecord = Array.from(map.values());
    } else {
      draft.assignedPosSearchText = value;
    }
  });
  return newStandardServiceState;
};

export const updatePosNumberOpenIndexUtils = (standardServiceState: StandardServiceInitialStateInterface, posListIdName: string | number, posIndex: number) => {
  let newStandardServiceState: StandardServiceInitialStateInterface = produce(standardServiceState, (draft: StandardServiceInitialStateInterface) => {
    if (draft?.posZoneServiceFilteredRecord?.length > 0 && draft?.posZoneServiceFilteredRecord[posIndex] && draft?.posZoneServiceFilteredRecord[posIndex]?.id === posListIdName) {
      draft.posZoneServiceFilteredRecord[posIndex].isOpened = !draft.posZoneServiceFilteredRecord[posIndex].isOpened;
    }
  });
  return newStandardServiceState;
};

export const headerRowCheckboxOnchangeUtils = (standardServiceState: StandardServiceInitialStateInterface, event: React.ChangeEvent<HTMLInputElement>) => {
  let newStandardServiceState: StandardServiceInitialStateInterface = produce(standardServiceState, (draft: StandardServiceInitialStateInterface) => {
    if (!isUndefined(event?.target?.checked)) {
      const { checked } = event.target;
      if (draft?.posZoneServiceFilteredRecord?.length > 0) {
        let totalAssignedAreaCount = 0;
        let selectedPosServiceTypeZone: ZoneServiceTypeListInterface[] = [];
        draft.posZoneServiceFilteredRecord.forEach((posItem: PosInterface) => {
          posItem.isChecked = checked;
          posItem.zoneServicetypeList.forEach((posDetails: ZoneServiceTypeListInterface) => {
            posDetails.isChecked = checked;
            if (checked) {
              totalAssignedAreaCount++;
              selectedPosServiceTypeZone.push(posDetails);
            } else {
              totalAssignedAreaCount = 0;
              selectedPosServiceTypeZone = [];
            }
          });
        });
        draft.totalAssignedAreaCount = totalAssignedAreaCount;
        draft.selectedPosServiceTypeZone = selectedPosServiceTypeZone;
      }
    }
  });
  return newStandardServiceState;
};

export const headerMainRowCheckboxOnchangeUtils = (
  standardServiceState: StandardServiceInitialStateInterface,
  event: React.ChangeEvent<HTMLInputElement>,
  posListObj: PosInterface,
  configTabs: DEFAULT_CONFIG_TAB_CONFIGURATIONS_INTERFACE[],
  configTabIndex: number
) => {
  let newStandardServiceState: StandardServiceInitialStateInterface = produce(standardServiceState, (draft: StandardServiceInitialStateInterface) => {
    if (!isUndefined(event?.target?.checked)) {
      const { checked } = event.target;
      if (draft?.posZoneServiceFilteredRecord && draft?.totalAssignedAreaCount >= 0 && draft?.selectedPosServiceTypeZone) {
        if (!isEmptyOrNull(posListObj?.id)) {
          const posListIndex = draft?.posZoneServiceFilteredRecord?.findIndex((item: PosInterface) => item.id === posListObj.id);
          if (posListIndex >= 0) {
            draft.posZoneServiceFilteredRecord[posListIndex].isChecked = checked;
            draft.posZoneServiceFilteredRecord[posListIndex].zoneServicetypeList.forEach((posDetails: ZoneServiceTypeListInterface) => {
              posDetails.isChecked = checked;
              if (checked) {
                draft.totalAssignedAreaCount++;
                if (standardServiceState?.selectedPosServiceTypeZone?.length === 0) {
                  draft.selectedPosServiceTypeZone.push(posDetails);
                } else if (
                  !isUndefined(standardServiceState?.selectedPosServiceTypeZone) &&
                  !isUndefined(standardServiceState?.selectedPosServiceTypeZone?.length) &&
                  standardServiceState?.selectedPosServiceTypeZone?.length > 0
                ) {
                  const posIndex = standardServiceState?.selectedPosServiceTypeZone?.findIndex(
                    (item: ZoneServiceTypeListInterface) => String(item.posNo) === posDetails.posNo && String(item.zoneName) === posDetails.zoneName
                  );
                  if (posIndex === -1) {
                    draft.selectedPosServiceTypeZone.push(posDetails);
                  }
                }
              } else {
                draft.totalAssignedAreaCount--;
                var removeIndex = draft.selectedPosServiceTypeZone.findIndex(
                  (item: ZoneServiceTypeListInterface) => item.posNo === posDetails.posNo && item.serviceType === posDetails.serviceType && item.zoneName === posDetails.zoneName
                );
                if (removeIndex !== -1) {
                  draft.selectedPosServiceTypeZone.splice(removeIndex, 1);
                }
              }
            });
          }
        }
      }
    }
  });
  return newStandardServiceState;
};

export const headerInnerRowCheckboxOnchangeUtils = (
  standardServiceState: StandardServiceInitialStateInterface,
  event: React.ChangeEvent<HTMLInputElement>,
  posListIndex: number,
  posDataIndex: number
) => {
  let newStandardServiceState: StandardServiceInitialStateInterface = produce(standardServiceState, (draft: StandardServiceInitialStateInterface) => {
    if (!isUndefined(event?.target?.checked)) {
      const { checked } = event.target;
      if (draft?.posZoneServiceFilteredRecord && draft?.totalAssignedAreaCount >= 0 && draft?.selectedPosServiceTypeZone) {
        draft.posZoneServiceFilteredRecord[posListIndex].zoneServicetypeList[posDataIndex].isChecked = checked;
        if (checked) {
          draft.selectedPosServiceTypeZone.push(draft.posZoneServiceFilteredRecord[posListIndex].zoneServicetypeList[posDataIndex]);
          draft.totalAssignedAreaCount++;
        } else {
          const dataToDelete = draft.posZoneServiceFilteredRecord[posListIndex].zoneServicetypeList[posDataIndex];
          var removeIndex = draft.selectedPosServiceTypeZone.findIndex(
            (item: any) => item.posNo === dataToDelete.posNo && item.serviceType === dataToDelete.serviceType && item.zoneName === dataToDelete.zoneName
          );
          if (removeIndex !== -1) {
            draft.selectedPosServiceTypeZone.splice(removeIndex, 1);
          }
          draft.totalAssignedAreaCount--;
        }
        let totalRowsSelected = 0;
        draft.posZoneServiceFilteredRecord[posListIndex].zoneServicetypeList.forEach((item: any) => {
          if (item.isChecked) {
            totalRowsSelected++;
          }
        });
        if (totalRowsSelected === draft.posZoneServiceFilteredRecord[posListIndex].zoneServicetypeList.length) {
          draft.posZoneServiceFilteredRecord[posListIndex].isChecked = checked;
        } else {
          draft.posZoneServiceFilteredRecord[posListIndex].isChecked = false;
        }
      }
    }
  });
  return newStandardServiceState;
};

//
export const handleDeleteSelectedRowUtils = (standardServiceState: StandardServiceInitialStateInterface, itemData: ZoneServiceTypeListInterface, index: number) => {
  let newStandardServiceState: StandardServiceInitialStateInterface = produce(standardServiceState, (draft: StandardServiceInitialStateInterface) => {
    if (draft?.posZoneServiceFilteredRecord && Number(draft?.totalAssignedAreaCount) >= 0 && draft?.selectedPosServiceTypeZone) {
      let deletedItemIndex = draft?.selectedPosServiceTypeZone?.findIndex(
        (item: ZoneServiceTypeListInterface) => item.posNo === itemData.posNo && item.zoneId === itemData.zoneId && item.zoneName === itemData.zoneName
      );
      if (deletedItemIndex > -1) {
        draft.selectedPosServiceTypeZone.splice(deletedItemIndex, 1);
        draft.totalAssignedAreaCount--;

        var removeIndex = draft.posZoneServiceFilteredRecord.findIndex((item: PosInterface) => item.posNo === itemData.posNo);
        if (removeIndex !== -1) {
          draft.posZoneServiceFilteredRecord[removeIndex].isChecked = false;
          const innerIndex = draft.posZoneServiceFilteredRecord[removeIndex].zoneServicetypeList.findIndex(
            (item: ZoneServiceTypeListInterface) => item.posNo === itemData.posNo && item.serviceType === itemData.serviceType && item.zoneName === itemData.zoneName
          );
          if (innerIndex !== -1) {
            draft.posZoneServiceFilteredRecord[removeIndex].zoneServicetypeList[innerIndex].isChecked = false;
          }
        }
      }
    }
  });
  return newStandardServiceState;
};

export interface payloadSecondaryStoresObject {
  posNo: string | number;
  zone: string | number;
  rank: number;
  latitude: string | number;
  longitude: string | number;
  status: string;
}

export const createDynamicRoutingPayload = (tab: DEFAULT_CONFIG_TAB_CONFIGURATIONS_INTERFACE, posNo: string | number) => {
  const dynamicRoutingPayload = Object.freeze({
    posNo: posNo || tab?.dynamicRoutingConfig?.posNo,
    zone: tab.dynamicRoutingConfig?.zone || tab?.zoneName,
    serviceType: STANDARD_SERVICE_CONSTANT.STANDARD,
    secondaryStores: [] as payloadSecondaryStoresObject[],
  });

  if (tab.dynamicRoutingConfig?.secondaryStores?.length > 0) {
    tab.dynamicRoutingConfig?.secondaryStores?.map((element: SecondaryStoresInterface, elementIndex: number) => {
      const secondaryStoresObject: payloadSecondaryStoresObject = Object.freeze({
        posNo: element.posNo,
        zone: element.zone,
        rank: elementIndex + 1,
        latitude: element.latitude,
        longitude: element.longitude,
        status: element.status,
      });
      dynamicRoutingPayload.secondaryStores.push(secondaryStoresObject);
    });
  }
  return dynamicRoutingPayload;
};
