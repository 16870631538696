export const getSingleUsePlasticListColumns = () => {
  return [
    { sortable: false, flex: 2, field: "id", headerName: "POS No" },
    { sortable: false, flex: 3, field: "name", headerName: "POS Name" },
    { sortable: false, flex: 3, field: "name", headerName: "Status" },
    { sortable: false, flex: 3, field: "type", headerName: "Service" },
    { sortable: false, flex: 2, field: "zoneName", headerName: "Zone" },
  ];
};

export const SERVICE_TYPE = "Service";

export const getSingleUsePlasticTotalAreasListColumns = () => {
  return [
    { width: "10%", headerName: "" },
    { width: "15%", headerName: "POS No" },
    { width: "20%", headerName: "POS Name" },
    { width: "15%", headerName: "Status" },
    { width: "20%", headerName: SERVICE_TYPE },
    { width: "20%", headerName: "Zone" },
  ];
};
