import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const userStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogRoot: {
      width: "580px",
      height: "100vh",
      margin: 0,
      maxHeight: "none",
      marginLeft: "auto",
    },
    dialogTitleStyle: {
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "29px",
      color: "#252525",
    },
    buttonStyles: {
      height: "48px",
      minWidth: "257px",
      "&:not(:first-child)": {
        marginLeft: "18px",
        color: "#FFFFFF",
        backgroundColor: "#0E5AA7",
        border: "1px solid #0E5AA7",
      },
    },
  })
);
