import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../../config/theme/theme";

const breadcrumbStyles = makeStyles({
  root: {
    marginBottom: "16px",
  },
  linkStyle: {
    color: COLORS.PRIMARY_MAIN,
    fontWeight: 500,
    fontSize: "14px",
  },
  currentLinkStyle: {
    fontSize: "14px",
    fontWeight: 600,
    color: COLORS.DARK_GRAY,
  },
});

export default breadcrumbStyles;
