import { ADDITIONAL_SERVICES, USER_TYPES } from "../../config/constants";

export const canChangeCountrySetting = (userInfo: any) => {
    if (userInfo?.userProfile?.role === USER_TYPES.READONLY_USER) {
        return false;
    } else if (userInfo?.userProfile?.role === 'ADMIN') {
        return userInfo?.userProfile?.featureId && userInfo?.userProfile?.featureId.includes(ADDITIONAL_SERVICES.COUNTRY_SETTINGS);
    } else {
        return true;
    }
}
