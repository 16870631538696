import React from "react";

import { Grid } from "@material-ui/core";

import { ChangeConfigOtion } from "../ServiceRuleEngineUtils";

import SwitchButton from "../../../common/SwitchButton/SwitchButton";
import SelectBox from "components/common/SelectBox/SelectBox";
import TextBox from "components/common/TextBox";

import { ITEM_COUNT_INCREMENTAL_SECONDS } from "../ServiceRuleEngine";

interface RenderItemCountRuleInterface {
  classes: any;
  config: any;
  lodashGet: any;
  serviceName: any;
  onUseForConfigurationSwitchChange: any;
  onIsSameAsEarlierCheckboxChange: any;
  onCountRuleConfigEdit: any;
  onCountRuleFieldsChange: any;
}

const RenderItemCountRule = (props: RenderItemCountRuleInterface) => {
  const { classes, config, lodashGet, serviceName, onUseForConfigurationSwitchChange, onIsSameAsEarlierCheckboxChange, onCountRuleConfigEdit, onCountRuleFieldsChange } = props;
  return (
    <Grid container className={classes.ruleContainer} spacing={0}>
      <Grid item xs={9}>
        <Grid container spacing={1}>
          <Grid item xs={5} className={classes.ruleLabel}>
            Item Count
          </Grid>
          {!config.countRule.sameAsEarlier && (
            <>
              <Grid item xs={6} className={classes.dFlexEnd}>
                Use this value in calculation
              </Grid>
              <Grid item xs={1}>
                <SwitchButton
                  className={config.isEditMode && !config.enableCountRuleEdit ? classes.disableSwitch : ""}
                  size="small"
                  checked={config.countRule.active}
                  changeExisting={config.enableCountRuleEdit}
                  disabled={config.isEditMode && !config.enableCountRuleEdit}
                  handleChange={(e: any) => onUseForConfigurationSwitchChange(serviceName, "countRule", e)}
                  leftLabel={undefined}
                  rightLabel={undefined}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <ChangeConfigOtion
        config={config}
        checked={config.countRule.sameAsEarlier}
        classes={classes}
        xsValue={4}
        checkChangeHandler={(e: any) => onIsSameAsEarlierCheckboxChange(serviceName, "countRule", e)}
        onConfigEdit={onCountRuleConfigEdit}
        changeExisting={config.enableCountRuleEdit}
      />
      {config.countRule.active && (
        <>
          <Grid item xs={8} className={classes.ruleEditingDivStyle}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextBox
                  fieldLabel="Threshold Limit"
                  name="thresholdCount"
                  type="number"
                  isNegativeAllowed={false}
                  value={lodashGet(config, "countRule.thresholdCount", "")}
                  handleChange={(e: any) => onCountRuleFieldsChange(serviceName, e)}
                  disabled={config.isEditMode && !config.enableCountRuleEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectBox
                  fieldLabel="Incremental Time Above Threshold"
                  name="incrementalTime"
                  label="Seconds"
                  value={lodashGet(config, "countRule.incrementalTime", "")}
                  handleChange={(e: any) => onCountRuleFieldsChange(serviceName, e)}
                  menuItems={ITEM_COUNT_INCREMENTAL_SECONDS}
                  disabled={config.isEditMode && !config.enableCountRuleEdit}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default React.memo(RenderItemCountRule);
