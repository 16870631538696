import React from "react";

import { Grid, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import { isArrayValid, isEmptyOrNull } from "../../../../../utils/DataUtils";
import { PREFERRED_SLOT_FEES_BOX_TITLE, DELIVERY_FEE_TEXT_CONSTANT, USE_THIS_VALUE_IN_CALCULATION } from "../../../Utils/DeliveryFeeConstants";
import { ChangeConfigOtion } from "../../utils/DeliveryFeeConfigViewUtils";
import { ShipmentServiceLevelPromiseSlotFeeInterface, capacityUtilisationConfigurationInterface } from "../../../Utils/DeliveryFeeInterface";

import SwitchButton from "../../../../common/SwitchButton/SwitchButton";
import TextBoxWithLabel from "../../../../common/TextBoxWithLabel/TextBoxWithLabel";
import AddPlusIcon from "../../../../assets/svg/AddPlusIcon.svg";
import SubtractMinusIcon from "../../../../assets/svg/SubtractMinusIcon.svg";
import SelectBox from "../../../../common/SelectBox/SelectBox";
import { generateDropdownWithMentionedIntervalItems } from "utils/helperFunctions";

interface PreferredSlotFeesConfigurationInterface {
  classes: any;
  serviceName: string;
  isEditMode: boolean;
  stateLevelIndex: number;
  updateConfigPosId: string;
  updateConfigZoneName: string;
  enableThisFeature: boolean;
  multiShipmentLevel: boolean;
  stateObj: ShipmentServiceLevelPromiseSlotFeeInterface;
  handlePromiseSlotFeeCalculationToggleChange: (multiShipmentLevel: boolean, serviceName: string) => void;
  promiseSlotExistingConfigChangeHandler: (serviceName: string, fieldName: string) => void;
  configPromiseSlotChangeExistingHandler: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, serviceName: string, fieldName: string) => void;
  handleCapacityRangeChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, serviceName: string, fieldName: string, slotObjIndex: number) => void;
  handleCapacityFeesChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, serviceName: string, fieldName: string, slotObjIndex: number) => void;
  handlePromiseSlotAddCapacityRange: (serviceName: string, fieldName: string) => void;
  handlePromiseSlotRemoveCapacityRange: (slotObjIndex: number, serviceName: string, fieldName: string) => void;
}

const PreferredSlotFeesConfiguration = (props: PreferredSlotFeesConfigurationInterface) => {
  const {
    classes,
    serviceName,
    isEditMode,
    stateLevelIndex,
    updateConfigPosId,
    updateConfigZoneName,
    enableThisFeature,
    multiShipmentLevel,
    stateObj,
    handlePromiseSlotFeeCalculationToggleChange,
    promiseSlotExistingConfigChangeHandler,
    configPromiseSlotChangeExistingHandler,
    handleCapacityRangeChange,
    handleCapacityFeesChange,
    handlePromiseSlotAddCapacityRange,
    handlePromiseSlotRemoveCapacityRange,
  } = props;

  const isCapacityUtilisationConfigurationRecordPresent = () => {
    return isArrayValid(stateObj.capacityUtilisationConfiguration);
  };

  const isRangeStartInputDisabled = (slotObjIndex: number) => {
    if (slotObjIndex === 0 && stateObj.capacityUtilisationConfiguration.length === 1) {
      return false;
    } else if (slotObjIndex !== stateObj.capacityUtilisationConfiguration.length - 1 || slotObjIndex === stateObj.capacityUtilisationConfiguration.length - 1) {
      return true;
    }
    return false;
  };

  const isParentConfiguratopnPresent = (slotObj: capacityUtilisationConfigurationInterface) => {
    return !isEmptyOrNull(slotObj.capacityLow) && !isEmptyOrNull(slotObj.capacityHigh) && Number(slotObj.capacityHigh) < 100 && !isEmptyOrNull(slotObj.deliveryFee);
  };

  const renderAddCapacityUtilisationRangeText = (slotObj: capacityUtilisationConfigurationInterface) => {
    return (
      <Grid
        item
        xs={12}
        className={isParentConfiguratopnPresent(slotObj) ? classes.addConfigIconDivStyle : classes.addConfigIconDivStyleDisabled}
        onClick={() => isParentConfiguratopnPresent(slotObj) && handlePromiseSlotAddCapacityRange(serviceName, DELIVERY_FEE_TEXT_CONSTANT.PROMISE_FEE)}
      >
        <AddCircleOutlineIcon className={classes.addConfigIconStyle} />
        <Typography className={classes.addConfigTextStyle}>Add Range</Typography>
      </Grid>
    );
  };

  const renderAddSubtractCapacityRangeObjectButtons = (slotObj: capacityUtilisationConfigurationInterface, slotObjIndex: number) => {
    return (
      <>
        <img
          src={SubtractMinusIcon}
          alt="SubtractMinusIcon"
          className={classes.actionIconStyle}
          onClick={() => handlePromiseSlotRemoveCapacityRange(slotObjIndex, serviceName, DELIVERY_FEE_TEXT_CONSTANT.PROMISE_FEE)}
        />
        {isParentConfiguratopnPresent(slotObj) && (
          <img src={AddPlusIcon} alt="AddPlusIcon" className={classes.actionIconStyle} onClick={() => handlePromiseSlotAddCapacityRange(serviceName, DELIVERY_FEE_TEXT_CONSTANT.PROMISE_FEE)} />
        )}
      </>
    );
  };

  const getRangeStartMenuItems = (slotObj: capacityUtilisationConfigurationInterface) => {
    let start = 0;
    const end = 95;
    return generateDropdownWithMentionedIntervalItems(start, end, DELIVERY_FEE_TEXT_CONSTANT.PREFERED_FEE_DROP_DOWN_INERVAL_VALUE);
  };

  const getRangeEndMenuItems = (slotObj: capacityUtilisationConfigurationInterface) => {
    let start = isEmptyOrNull(slotObj.capacityLow) ? 5 : Number(slotObj.capacityLow) + 5;
    const end = 100;
    return generateDropdownWithMentionedIntervalItems(start, end, DELIVERY_FEE_TEXT_CONSTANT.PREFERED_FEE_DROP_DOWN_INERVAL_VALUE);
  };

  const renderCapacityUtilisationConfigurationRecord = () => {
    return (
      <>
        {stateObj.capacityUtilisationConfiguration &&
          stateObj.capacityUtilisationConfiguration.map((slotObj: capacityUtilisationConfigurationInterface, slotObjIndex: number) => (
            <Grid container key={`slotObj-capacityUtilisationConfiguration-${slotObjIndex}`} className={classes.capacityUtilisationValueDiv} spacing={1}>
              <Grid item xs={3}>
                <SelectBox
                  label={"Range Start (Greater Than or Equal To) (%)"}
                  name="capacityLow"
                  value={slotObj.capacityLow}
                  menuItems={getRangeStartMenuItems(slotObj)}
                  handleChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleCapacityRangeChange(e, serviceName, DELIVERY_FEE_TEXT_CONSTANT.PROMISE_FEE, slotObjIndex)}
                  className={classes.rightPaddingStyle}
                  disabled={!stateObj.enableConfigEdit || isRangeStartInputDisabled(slotObjIndex)}
                  error={isEmptyOrNull(slotObj.capacityLowErrorMessage) ? false : true}
                />
                {/* <TextBoxWithLabel
                  label={"Range Start (Greater Than or Equal To) (%)"}
                  name="capacityLow"
                  value={slotObj.capacityLow}
                  placeholderText="Range Start (%)"
                  handleChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleCapacityRangeChange(e, serviceName, DELIVERY_FEE_TEXT_CONSTANT.PROMISE_FEE, slotObjIndex)}
                  type="number"
                  className={classes.rightPaddingStyle}
                  disabled={!stateObj.enableConfigEdit || isRangeStartInputDisabled(slotObjIndex)}
                  error={isEmptyOrNull(slotObj.capacityLowErrorMessage)}
                  helperText={slotObj.capacityLowErrorMessage}
                /> */}
              </Grid>
              <Grid item xs={2}>
                <SelectBox
                  label={"Range End (Less Than) (%)"}
                  name="capacityHigh"
                  value={slotObj.capacityHigh}
                  menuItems={getRangeEndMenuItems(slotObj)}
                  handleChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleCapacityRangeChange(e, serviceName, DELIVERY_FEE_TEXT_CONSTANT.PROMISE_FEE, slotObjIndex)}
                  className={classes.rightPaddingStyle}
                  disabled={!stateObj.enableConfigEdit || slotObjIndex !== stateObj.capacityUtilisationConfiguration.length - 1}
                  error={isEmptyOrNull(slotObj.capacityHighErrorMessage) ? false : true}
                />
                {/* <TextBoxWithLabel
                  label={"Range End (Less Than) (%)"}
                  name="capacityHigh"
                  value={slotObj.capacityHigh}
                  placeholderText="Range End (%)"
                  handleChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleCapacityRangeChange(e, serviceName, DELIVERY_FEE_TEXT_CONSTANT.PROMISE_FEE, slotObjIndex)}
                  type="number"
                  className={classes.rightPaddingStyle}
                  disabled={!stateObj.enableConfigEdit || slotObjIndex !== stateObj.capacityUtilisationConfiguration.length - 1}
                  error={isEmptyOrNull(slotObj.capacityHighErrorMessage)}
                  helperText={slotObj.capacityHighErrorMessage}
                /> */}
              </Grid>
              <Grid item xs={2}>
                <TextBoxWithLabel
                  label={"Preferred Slot Fees"}
                  name="deliveryFee"
                  value={slotObj.deliveryFee}
                  placeholderText="Preferred Slot Fees"
                  handleChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleCapacityFeesChange(e, serviceName, DELIVERY_FEE_TEXT_CONSTANT.PROMISE_FEE, slotObjIndex)}
                  type="number"
                  className={classes.rightPaddingStyle}
                  disabled={!stateObj.enableConfigEdit}
                  error={isEmptyOrNull(slotObj.capacityFeeErrorMessage)}
                  helperText={slotObj.capacityFeeErrorMessage}
                />
              </Grid>
              {stateObj.enableConfigEdit && (
                <Grid item xs={2} className={classes.promiseSlotDetailsButton}>
                  {stateObj.capacityUtilisationConfiguration.length === 1 ? (
                    renderAddCapacityUtilisationRangeText(slotObj)
                  ) : slotObjIndex === stateObj.capacityUtilisationConfiguration.length - 1 ? (
                    renderAddSubtractCapacityRangeObjectButtons(slotObj, slotObjIndex)
                  ) : (
                    <></>
                  )}
                </Grid>
              )}
              {slotObjIndex === 0 && (
                <Grid item xs={3} className={classes.configDateNoteGridItemStyle}>
                  <Typography className={classes.configDateNoteTextStyle}>{"** Maximum Range End Value 100"}</Typography>
                </Grid>
              )}
            </Grid>
          ))}
      </>
    );
  };

  return (
    <Grid container className={classes.dlvFeeServiceTypeDiv} key={`${serviceName}-${stateLevelIndex}-Preferred-Slot-Fee`}>
      <Grid container>
        <Grid item xs={4} className={classes.dlvFeeServiceTypeTextDivStyle}>
          <Typography className={classes.dlvFeeServiceTypeTextStyle}>{PREFERRED_SLOT_FEES_BOX_TITLE}</Typography>
        </Grid>
        <Grid item xs={4} className={classes.marginAuto}>
          {!stateObj.sameAsEarlier && (
            <Grid container justifyContent="flex-end">
              <Grid item xs={10} className={classes.useCalculationDivStyle}>
                <Typography className={classes.dlvFeeCalculationTextStyle}>{`${USE_THIS_VALUE_IN_CALCULATION}`}</Typography>
              </Grid>
              <Grid item xs={2}>
                <SwitchButton
                  className={isEditMode && !stateObj.enableConfigEdit ? classes.disableSwitch : classes.switchStyle}
                  size="small"
                  checked={stateObj.active}
                  disabled={false}
                  handleChange={() => handlePromiseSlotFeeCalculationToggleChange(multiShipmentLevel, serviceName)}
                  leftLabel={undefined}
                  rightLabel={undefined}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={4} className={classes.marginAuto}>
          <Grid container justifyContent="flex-end">
            <ChangeConfigOtion
              is_edit_mode={updateConfigPosId === "" && updateConfigZoneName === "" ? false : true}
              classes={classes}
              use_existing_checked={stateObj.sameAsEarlier}
              use_existing_config_change_handler={() => enableThisFeature && promiseSlotExistingConfigChangeHandler(serviceName, DELIVERY_FEE_TEXT_CONSTANT.PROMISE_FEE)}
              change_existing_checked={stateObj.enableConfigEdit}
              use_change_existing_config_change_handler={(event: any) => enableThisFeature && configPromiseSlotChangeExistingHandler(event, serviceName, DELIVERY_FEE_TEXT_CONSTANT.PROMISE_FEE)}
            />
          </Grid>
        </Grid>
      </Grid>

      {stateObj.active && isCapacityUtilisationConfigurationRecordPresent() && renderCapacityUtilisationConfigurationRecord()}
    </Grid>
  );
};

export default PreferredSlotFeesConfiguration;
