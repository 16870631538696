import Fetch from "./Fetch";
import FetchWithSiteId from "./FetchWithSiteId";

import { API_HOST_URL, API_HOST_URL_V2, API_HOST_DUMMY_URL, API_POLYGON_SERVICE_BASIC_URL } from "../config/constants";
import { handleError, handleResponse } from "./ApiUtils";

export const getPosTypes = () => {
  return Fetch.get(`${API_HOST_URL}/postypes`).then(handleResponse).catch(handleError);
};

export const getServiceTypes = () => {
  return Fetch.get(`${API_HOST_URL}/servicetypes`).then(handleResponse).catch(handleError);
};

export const createPos = (data) => {
  return Fetch.create(`${API_HOST_URL}/pos`, data).then(handleResponse).catch(handleError);
};

export const fetchPOSList = (data) => {
  return Fetch.get(`${API_HOST_URL}/pos`, data).then(handleResponse).catch(handleError);
};

export const fetchPOSDetails = (posNo) => {
  return Fetch.get(`${API_HOST_URL}/pos/${posNo}`).then(handleResponse).catch(handleError);
};

export const updatePOSDetails = (posData) => {
  return Fetch.update(`${API_HOST_URL}/pos`, posData).then(handleResponse).catch(handleError);
};

export const fetchTemplateSlots = (posNo, params) => {
  return Fetch.get(`${API_HOST_URL}/${posNo}/service-config`, params).then(handleResponse).catch(handleError);
};

export const createTemplateSlots = (posNo, zoneCongifurations) => {
  return Fetch.create(`${API_HOST_URL}/${posNo}/service-config`, zoneCongifurations).then(handleResponse).catch(handleError);
};

export const updateTemplateSlots = (posNo, zoneCongifurations) => {
  return Fetch.patch(`${API_HOST_URL}/${posNo}/service-config`, zoneCongifurations).then(handleResponse).catch(handleError);
};

export const toggleServiceOfPOS = (posNo, data) => {
  return Fetch.patch(`${API_HOST_URL}/pos/${posNo}/activation-toggle?active=${data.active}&serviceType=${data.serviceType}`).then(handleResponse).catch(handleError);
};

export const deleteZoneConfigByZoneId = (data) => {
  return Fetch.patch(`${API_HOST_URL}/zone-action/${data.posNo}/${data.serviceTypeName}/${data.zoneId}?status=DELETED`).then(handleResponse).catch(handleError);
};

export const toggleCollectionPoint = (collectionPointId, data) => {
  return Fetch.patch(`${API_HOST_URL}/pos/cnc/${collectionPointId}?toggleFlag=${data.toggleFlag}`).then(handleResponse).catch(handleError);
};

export const toggleZoneOfServiceOfPOS = (posNo, data) => {
  return Fetch.patch(`${API_HOST_URL}/zone-action/${posNo}/${data.serviceTypeName}/${data.zoneId}?status=${data.activstatus}`).then(handleResponse).catch(handleError);
};

export const toggleZoneServiceableState = (data) => {
  return Fetch.update(`${API_HOST_URL}/zone/${data.zoneId}`, data.payload).then(handleResponse).catch(handleError);
};

export const fetchStandardTemplateSlots = (posNo, params) => {
  return Fetch.get(`${API_HOST_URL_V2}/${posNo}/service-config`, params).then(handleResponse).catch(handleError);
};
export const createTemplateSlotsForStandard = (posNo, zoneCongifurations) => {
  return Fetch.create(`${API_HOST_URL_V2}/${posNo}/service-config`, zoneCongifurations).then(handleResponse).catch(handleError);
};
export const updateTemplateSlotsForStandard = (posNo, zoneCongifurations) => {
  return Fetch.patch(`${API_HOST_URL_V2}/${posNo}/service-config`, zoneCongifurations).then(handleResponse).catch(handleError);
};

export const updateDynamicRoutingForStandard = (dynamicRoutingPayload) => {
  return FetchWithSiteId.create(`${API_POLYGON_SERVICE_BASIC_URL}/api/store/v1/mappings/pos-zone`, dynamicRoutingPayload).then(handleResponse).catch(handleError);
};

export const fetchStandardTemplateSlotsVersionThree = (posNo, params) => {
  return Fetch.get(`${API_HOST_DUMMY_URL}/api/v3/${posNo}/service-config`, params).then(handleResponse).catch(handleError);
};
