import React, { useEffect, useRef } from "react";

import { ReactComponent as TableHeaderFilterIcon } from "../../assets/svg/TableHeaderFilterIcon.svg";
import { SvgIcon } from "@material-ui/core";

import DateRangePicker from "react-bootstrap-daterangepicker";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

const CustomDateRangePickerPopUp = ({ classes, handleFilterPopupOpen, anchorName = "", isSingleDatePicker, handleDateChangeEvent, handleCancel, value }) => {
  const dateRangeRef = useRef(null);

  useEffect(() => {
    if (dateRangeRef.current) {
      dateRangeRef.current.setStartDate(value?.startDate || new Date());
      dateRangeRef.current.setEndDate(value?.endDate || new Date());
    }
  }, [value]);

  return (
    <>
      <DateRangePicker
        key={anchorName}
        onApply={handleDateChangeEvent}
        onCancel={handleCancel}
        initialSettings={{
          singleDatePicker: isSingleDatePicker,
          cancelButtonClasses: "btn-primary",
          locale: { format: "YYYY-MM-DD", cancelLabel: "Reset" },
        }}
        ref={dateRangeRef}
      >
        <SvgIcon key={anchorName} className={classes.rootIcon} component={TableHeaderFilterIcon} onClick={handleFilterPopupOpen} />
      </DateRangePicker>
    </>
  );
};

export default CustomDateRangePickerPopUp;
