export const NO_APPROVAL_REQUEST_FOUND_ERROR = "No approval request was created for";
export const HEADER_TEXT = "Slot Change Requests";
export const ERROR_OCCURRED_WHILE_SAVING = "An error occurred, please try again";
export const NO_APPROVAL_STATUS_SELECTED_ERROR = "Please provide atleast one approval status";

export const POS_NO = "posNo";
export const POS_NAME = "posName";
export const APPROVAL_STATUS = "approvalStatus";
export const REQUEST_DATE = "requestDate";
export const IMPLEMENTATION_DATE = "implementationDate";

export interface ApprovalRecordInitialStateInterface {
  pageNumber: any;
  pageSize: any;
  maxRecordPresent: any;
  isLoading: boolean;
  recordList: any;
  //
}

export const ApprovalRecordInitialState: ApprovalRecordInitialStateInterface = {
  pageNumber: 0,
  pageSize: 40,
  maxRecordPresent: 0,
  isLoading: true,
  recordList: [],
};

export interface ApprovalRecordFilterPopUpStateInterface {
  loading: boolean;
  isDataFetched: boolean;
  //
  openPosNoPopUp: any;
  openPosNamePopUp: any;
  openApprovalStatusPopUp: any;
  openRequestDatePopUp: any;
  openImplementationDatePopUp: any;
  //
  anchorEl: any;
  //
  filterByHeader: any;
  //
  posNoNameSearchText: any;
  posNoNameRecord: any;
  posNoNameFilteredRecord: any;
  //
  requestDate: any;
  implementationDate: any;
  requestDatelabel: any;
  implementationDatelabel: any;
  //
  posNoFilter: any;
  posNameFilter: any;
  statusFilter: any;
}

export const ApprovalRecordFilterPopUpState: ApprovalRecordFilterPopUpStateInterface = {
  loading: false,
  isDataFetched: false,
  //
  openPosNoPopUp: false,
  openPosNamePopUp: false,
  openApprovalStatusPopUp: false,
  openRequestDatePopUp: false,
  openImplementationDatePopUp: false,
  //
  anchorEl: null,
  //
  filterByHeader: "",
  //
  posNoNameSearchText: "",
  posNoNameRecord: [],
  posNoNameFilteredRecord: [],
  //
  requestDate: { startDate: "", endDate: "" },
  implementationDate: { startDate: "", endDate: "" },
  requestDatelabel: "",
  implementationDatelabel: "",
  //
  posNoFilter: [],
  posNameFilter: [],
  statusFilter: [], // APPROVAL_FLOW_ACTIONS.APPROVED, APPROVAL_FLOW_ACTIONS.PENDING, APPROVAL_FLOW_ACTIONS.REJECTED
};

export interface RECORD_INTERFACE {
  actionDate: string;
  actionTakenBy: string;
  activationDate: string;
  approvalStatus: string;
  pendingDays: string | number;
  posNo: string;
  requestDate: string;
  requestedId: string | number;
  requester: string;
  serviceType: string;
  storeName: string;
  zoneName: string;
}

export const APPROVAL_RECORD_TEXT_CONSTANT = {
  TITLE: "Requested Configuration Details",
  POS_NO: "POS No",
  POS_NAME: "POS Name",
  SERVICE_TYPE: "Service Type",
  ZONE_NAME: "Zone Name",
  APPROVAL_STATUS: "Status",
  NO_DATA_AVAILABLE: "No Data available",
  COMMENT_TEXT_BOX_PLACE_HOLDER: "Action message (Max 200 characters)",
  ACCORDIAN_TITLE_COMMENT: "Comment",
  APPROVER_NAME: "Approver Name",
  APPROVED_DATE: "Approved Date",
  ALIGN_LEFT: true,
  ALIGN_CENTER: false,
  ZONE_NO: "Zone No",
  REQUESTOR_NAME: "Requestor Name",
  REQUEST_DATE: "Request Date",
  DAYS_PENDING: " Days Pending",
  VIEW: "View",
  IMPLEMENTATION_DATE: "Implementation Date",
  REJECT: "Reject",
  APPROVE: "Approve",
  BACK_TO_LIST: "Back to list",
  REQUESTED_ID: "requestedId",
  ERROR_WHILE_FETCHING_DETAILS: "Error while fetching details",
  ERROR_OCCURRED: "Error Occurred",
  NO_MESSAGE_AVAILABLE: "No message available",
  REQUESTER_NAME: "Requester Name",
  REQUESTED_DATE: "Requested Date",
  RESPONDER_NAME: "Responder Name",
  RESPONSE_DATE: "Response Date",
  STATUS: "Status",
};
