import Fetch from "./Fetch";

import { API_HOST_URL } from "../config/constants";
import { handleError, handleCSVResponse } from "./ApiUtils";

export const downloadPOSBasicReport = (body) => {
  return Fetch.create(`${API_HOST_URL}/report`, body).then(handleCSVResponse).catch(handleError);
};

export const downloadServiceConfigurationsReport = (body) => {
  return Fetch.create(`${API_HOST_URL}/report`, body).then(handleCSVResponse).catch(handleError);
};
