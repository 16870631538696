import { makeStyles } from "@material-ui/core";

import { COLORS } from "../../../config/theme/theme";

const timePickerStyles = makeStyles(() => ({
  fieldLabel: { marginBottom: "8px" },
  timePicker: {
    border: "1px solid #B2B2B2",
    borderRadius: "8px",
    height: "40px",
    backgroundColor: COLORS.WHITE,
    "& .MuiInput-underline:before": {
      border: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "none",
    },
    "& .MuiInput-underline.Mui-focused:after": {
      transform: "none",
      border: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
  },
  timePickerDisabled: {
    borderColor: "#E6E6E6",
  },
  timePickerInput: {
    marginLeft: "12px",
    paddingTop: "11px",
    fontSize: "12px",
    color: COLORS.TEXT_PRIMARY,
    fontWeight: 500,
  },
  timePickerLabelProps: {
    marginLeft: "10px",
    fontSize: "12px",
    color: COLORS.TEXT_PRIMARY,
    fontWeight: 500,
    " &.MuiFormLabel-root.Mui-focused": {
      marginTop: "5px",
    },
  },
  hideTimeIcon: {
    "& .MuiInputAdornment-root": {
      display: "none",
    },
  },
  timerIcon: {
    marginBottom: "10px",
  },
}));

export default timePickerStyles;
