import React, { useState } from "react";

import { Accordion, AccordionDetails, AccordionSummary, Card, Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { userStyles } from "./Styles/DeliveryFeeDateTimeStyles";
import { capitalizeFirstLetter } from "./Utils/DeliveryFeeUtils";
import { ADD_EXPRESS_SERVICE_FEE_TEXT } from "./Utils/DeliveryFeeConstants";
import { FEE_REG_EXP_VALUE } from "../../config/constants";

import ModalContainer from "../../base/ModalContainer";
import PrimaryButton from "../common/PrimaryButton/PrimaryButton";
import TextBoxWithLabel from "../common/TextBoxWithLabel/TextBoxWithLabel";

interface DeliveryFeeExpressServiceFeeInterface {
  open: any;
  handleClose: any;
  name: any;
  value: any;
  _handle_on_proceed_express_service_fees: any;
}

const DeliveryFeeExpressServiceFee = (props: DeliveryFeeExpressServiceFeeInterface) => {
  const { open, handleClose, name, value, _handle_on_proceed_express_service_fees } = props;
  const classes = userStyles();

  const [serviceFeeConfiguration, setServiceFeeConfiguration] = useState({
    isLoading: false,
    openAddServiceFee: true,
    enableServiceFee: value !== undefined && value !== "" ? true : false,
    serviceFeeValue: value !== undefined && value !== "" ? value : "",
    service_fee_value_error_message: "",
  });

  const _handle_accordion_open_state = (accordian_name: any) => {
    if (accordian_name === "openAddServiceFee") {
      setServiceFeeConfiguration({
        ...serviceFeeConfiguration,
        openAddServiceFee: !serviceFeeConfiguration.openAddServiceFee,
      });
    }

    if (accordian_name === "enableServiceFee") {
      setServiceFeeConfiguration({
        ...serviceFeeConfiguration,
        enableServiceFee: !serviceFeeConfiguration.enableServiceFee,
        serviceFeeValue: "",
        service_fee_value_error_message: "",
      });
    }
  };

  const handleServiceFeeOnChange = (event: any) => {
    if (event !== undefined && event.target !== undefined) {
      const { name, value } = event.target;
      if (name === "serviceFeeValue") {
        if (value !== "" && (isNaN(Number(value)) || !FEE_REG_EXP_VALUE.test(value) || parseInt(value) < 0)) {
          return;
        } else {
          setServiceFeeConfiguration({
            ...serviceFeeConfiguration,
            serviceFeeValue: value,
            service_fee_value_error_message: "",
          });
        }
      }
    }
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    if (serviceFeeConfiguration !== undefined && serviceFeeConfiguration.enableServiceFee !== undefined && serviceFeeConfiguration.enableServiceFee) {
      if (serviceFeeConfiguration.serviceFeeValue !== undefined && serviceFeeConfiguration.serviceFeeValue === "") {
        setServiceFeeConfiguration({
          ...serviceFeeConfiguration,
          service_fee_value_error_message: "Please provide valid Service Fees",
        });
      } else {
        _handle_on_proceed_express_service_fees(name, serviceFeeConfiguration.serviceFeeValue);
      }
    } else {
      _handle_on_proceed_express_service_fees(name, "");
    }
  };

  return (
    <ModalContainer open={open} className={classes.mainContainer} onClose={handleClose} disableBackdropClick>
      <Card className={classes.cardContainer}>
        <Grid container className={classes.gridContainerMain}>
          <Grid item xs={12} className={classes.gridContainerTitleDivStyle}>
            <div className={classes.headerStyleNormalDiv}>{`Delivery Fee Customization - Express`}</div>
          </Grid>
        </Grid>
        <Grid container className={classes.gridContainerContent}>
          <Grid item xs={12}>
            <Accordion expanded={serviceFeeConfiguration.openAddServiceFee} className={classes.accordionStyle} onChange={() => _handle_accordion_open_state("openAddServiceFee")} disabled={false}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryStyle}>
                <Grid container alignItems="center">
                  <Grid item xs={8}>
                    <Typography className={classes.accordionTitle}>{capitalizeFirstLetter(ADD_EXPRESS_SERVICE_FEE_TEXT)}</Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetailsStyle}>
                <Grid container className={classes.gridContentContainerMain}>
                  <Grid item xs={8} className={classes.gridContainerServiceFeesTitleDivStyle}>
                    {""}
                  </Grid>
                  <Grid item xs={4} className={classes.gridContainerServiceFeesTitleDivStyle}>
                    <FormControlLabel
                      className={classes.serviceTypeItem}
                      control={
                        <Checkbox
                          checked={serviceFeeConfiguration.enableServiceFee}
                          value={serviceFeeConfiguration.enableServiceFee}
                          onChange={() => _handle_accordion_open_state("enableServiceFee")}
                        />
                      }
                      label={<Typography className={classes.checkboxLabel}>{"Enable Service Fees for Express"}</Typography>}
                    />
                  </Grid>
                </Grid>
                {serviceFeeConfiguration !== undefined && serviceFeeConfiguration.enableServiceFee !== undefined && serviceFeeConfiguration.enableServiceFee && (
                  <Grid container className={classes.gridContentContainerMain}>
                    <Card className={classes.capacityUtilisationMainBxContainer}>
                      <Grid container className={classes.capacityUtilisationValueDiv}>
                        <Grid item xs={3}>
                          <TextBoxWithLabel
                            label={"Service Fee"}
                            name="serviceFeeValue"
                            value={serviceFeeConfiguration.serviceFeeValue}
                            placeholderText="Express Service Fee"
                            handleChange={handleServiceFeeOnChange}
                            type="number"
                            className={classes.rightPaddingStyle}
                            error={serviceFeeConfiguration.service_fee_value_error_message === "" ? false : true}
                            helperText={serviceFeeConfiguration.service_fee_value_error_message}
                            disabled={false}
                          ></TextBoxWithLabel>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        {/* Footer */}
        <Grid container className={classes.gridContainerFooter}>
          <PrimaryButton className={classes.cancelButtonStyle} buttonLabel="Cancel" onClick={() => handleClose()} />
          <PrimaryButton disabled={false} className={classes.saveButtonStyle} buttonLabel="Proceed" onClick={(e: any) => handleSave(e)} />
        </Grid>
      </Card>
    </ModalContainer>
  );
};

export default React.memo(DeliveryFeeExpressServiceFee);
