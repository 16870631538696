import React from "react";

import { Grid, Typography } from "@material-ui/core";

import { ChangeConfigOtion } from "../ServiceRuleEngineUtils";
import SwitchButton from "../../../common/SwitchButton/SwitchButton";
import SelectBox from "components/common/SelectBox/SelectBox";
import TextBox from "components/common/TextBox";
import MultiSelectBox from "../../../common/MultiSelectBox/MultiSelectBox";
import LinkButton from "../../../common/LinkButton/LinkButton";

import { EXTRA_PROMISE_INCREMENTAL_MINUTES, TIME_RANGE_INCREMENTAL_MINUTES } from "../ServiceRuleEngine";
import { calculateAvailableDaysForSelection } from "../../RuleEngineUtils";

import { ReactComponent as DeleteIcon } from "../../../assets/svg/DeleteIcon.svg";

interface RenderOrderVelocityInterface {
  classes: any;
  config: any;
  lodashGet: any;
  handleOrderVelocityConfigEdit: any;
  handleOrderVelocityIsSameAsEarlierChange: any;
  handleOrderVelocityRuleConfigEdit: any;
  onOrderVelocityFieldsChange: any;
  isAddOtherDaysButtonDisabled: any;
  onAddOtherDays: any;
  onRemoveOtherDays: any;
}

const RenderOrderVelocity = (props: RenderOrderVelocityInterface) => {
  const {
    classes,
    config,
    lodashGet,
    handleOrderVelocityConfigEdit,
    handleOrderVelocityIsSameAsEarlierChange,
    handleOrderVelocityRuleConfigEdit,
    onOrderVelocityFieldsChange,
    isAddOtherDaysButtonDisabled,
    onAddOtherDays,
    onRemoveOtherDays,
  } = props;

  return (
    <Grid container className={classes.ruleContainer} spacing={0}>
      <Grid item xs={9}>
        <Grid container spacing={1}>
          <Grid item xs={5} className={classes.ruleLabel}>
            Order Velocity
          </Grid>
          {!config.orderVelocityRule.sameAsEarlier && (
            <>
              <Grid item xs={6} className={classes.dFlexEnd}>
                Use this value in calculation
              </Grid>
              <Grid item xs={1}>
                <SwitchButton
                  className={config.isEditMode && !config.enableOrderVelocityRuleEdit ? classes.disableSwitch : ""}
                  size="small"
                  checked={config.orderVelocityRule.active}
                  disabled={config.isEditMode && !config.enableOrderVelocityRuleEdit}
                  handleChange={handleOrderVelocityConfigEdit}
                  changeExisting={config.enableOrderVelocityRuleEdit}
                  leftLabel={undefined}
                  rightLabel={undefined}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <ChangeConfigOtion
        config={config}
        checked={config.orderVelocityRule.sameAsEarlier}
        classes={classes}
        checkChangeHandler={handleOrderVelocityIsSameAsEarlierChange}
        changeExisting={config.enableOrderVelocityRuleEdit}
        onConfigEdit={handleOrderVelocityRuleConfigEdit}
        xsValue={4}
      />

      {config.orderVelocityRule !== undefined && config.orderVelocityRule.active !== undefined && config.orderVelocityRule.active && (
        <Grid item xs={12} className={classes.ruleEditingDivStyle}>
          {config !== undefined &&
          config.orderVelocityRule !== undefined &&
          config.orderVelocityRule.orderVelocityRuleValues !== undefined &&
          Array.isArray(config.orderVelocityRule.orderVelocityRuleValues) &&
          config.orderVelocityRule.orderVelocityRuleValues.length > 0 ? (
            <Grid container>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Typography className={classes.orderVelocityRuleHeaderConfigurationStyle}>Days</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.orderVelocityRuleHeaderConfigurationStyle}>Time Range</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.orderVelocityRuleHeaderConfigurationStyle}>Order Threshold</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.orderVelocityRuleHeaderConfigurationStyle}>Extra Promise Time</Typography>
                </Grid>
                <Grid item xs={3} className={classes.addOtherDays}>
                  <LinkButton buttonLabel={"Add Other Days"} disabled={isAddOtherDaysButtonDisabled()} onClick={onAddOtherDays} />
                </Grid>
              </Grid>
              {config.orderVelocityRule.orderVelocityRuleValues.map((configured_obj: any, configured_obj_index: any) => (
                <Grid container spacing={1} key={`${configured_obj_index}-orderVelocityRule-orderVelocityRuleValues`} className={classes.orderVelocityRuleConfigurationStyle}>
                  <Grid item xs={3}>
                    <MultiSelectBox
                      fieldLabel=""
                      menuitems={calculateAvailableDaysForSelection(configured_obj_index, config.orderVelocityRule.orderVelocityRuleValues)}
                      value={lodashGet(configured_obj, "configuredDays", "")}
                      onChange={(e: any) => onOrderVelocityFieldsChange("configuredDays", configured_obj_index, e)}
                      disabled={config.isEditMode && !config.enableOrderVelocityRuleEdit}
                      doNotShowAllInAllSelected={true}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <SelectBox
                      fieldLabel=""
                      name="timeRange"
                      label="Minutes"
                      value={lodashGet(configured_obj, "timeRange", "")}
                      handleChange={(e: any) => onOrderVelocityFieldsChange("timeRange", configured_obj_index, e)}
                      menuItems={TIME_RANGE_INCREMENTAL_MINUTES}
                      disabled={config.isEditMode && !config.enableOrderVelocityRuleEdit}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextBox
                      fieldLabel=""
                      name="orderThreshold"
                      type="number"
                      isNegativeAllowed={false}
                      value={lodashGet(configured_obj, "orderThreshold", "")}
                      handleChange={(e: any) => onOrderVelocityFieldsChange("orderThreshold", configured_obj_index, e)}
                      disabled={config.isEditMode && !config.enableOrderVelocityRuleEdit}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <SelectBox
                      fieldLabel=""
                      name="extraPromiseTime"
                      label="Minutes"
                      value={lodashGet(configured_obj, "extraPromiseTime", "")}
                      handleChange={(e: any) => onOrderVelocityFieldsChange("extraPromiseTime", configured_obj_index, e)}
                      menuItems={EXTRA_PROMISE_INCREMENTAL_MINUTES}
                      disabled={config.isEditMode && !config.enableOrderVelocityRuleEdit}
                    />
                  </Grid>
                  {configured_obj_index !== 0 && (
                    <Grid item xs={1} className={classes.orderVelocityRuleRemoveStyle}>
                      <DeleteIcon className={classes.orderVelocityRuleStyleRemoveStyle} onClick={(e: any) => onRemoveOtherDays(configured_obj_index)} />
                    </Grid>
                  )}
                </Grid>
              ))}
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(RenderOrderVelocity);
