import React from "react";

import { Grid } from "@material-ui/core";

import { userStyles } from "../Styles/DeliveryFeePageStyles";
import { DeliveryFeeInitialStateInterface } from "../Utils/DeliveryFeeInterface";
import { DELIVERY_FEE_SEARCH_TYPES } from "../../../config/constants";

import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import SearchHeaderCustom from "../../common/SearchHeaderCustom/SearchHeaderCustom";

interface DeliveryFeeSearchSectionInterface {
  deliveryFeeState: DeliveryFeeInitialStateInterface;
  resetDeliveryFeeRecord: any;
  searchObj: any;
  handleSearchTypeChange: any;
  handleSearchBoxTextChange: any;
  handleSearchBoxKeyDownChange: any;
  handleSearchIconClick: any;
}
const DeliveryFeeSearchSection = (props: DeliveryFeeSearchSectionInterface) => {
  const classes = userStyles();
  const { deliveryFeeState, resetDeliveryFeeRecord, searchObj, handleSearchTypeChange, handleSearchBoxTextChange, handleSearchBoxKeyDownChange, handleSearchIconClick } = props;

  return (
    <Grid container className={classes.headerRoot}>
      <Grid item xs={6}>
        <SearchHeaderCustom
          searchTypes={DELIVERY_FEE_SEARCH_TYPES}
          searchType={searchObj.searchTypeValue}
          handleSearchTypeChange={handleSearchTypeChange}
          searchValue={searchObj.searchValue}
          handleSearchBoxTextChange={handleSearchBoxTextChange}
          handleSearchBoxKeyDownChange={handleSearchBoxKeyDownChange}
          placeholderText={`Search ${
            searchObj.searchTypeValue !== undefined &&
            searchObj.searchTypeValue !== "" &&
            DELIVERY_FEE_SEARCH_TYPES !== undefined &&
            Array.isArray(DELIVERY_FEE_SEARCH_TYPES) &&
            DELIVERY_FEE_SEARCH_TYPES.length > 0
              ? `by ${DELIVERY_FEE_SEARCH_TYPES.filter((x: any) => x.value === searchObj.searchTypeValue)[0].name || searchObj.searchTypeValue}`
              : ""
          }`}
          handleIconClick={handleSearchIconClick}
          resetRecord={resetDeliveryFeeRecord}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(DeliveryFeeSearchSection);
