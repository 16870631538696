import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import commonStyles from "../RuleEngineCommonStyles";
import currentConfigStyle from "./CurrentConfigurationStyles";

import { ALERT_TYPES, API_RESPONSE_STATUS, DELIVERY_PROMISE_SEARCH_TYPES, SERVICE_TYPES } from "../../../config/constants";

import Table from "../../common/Table/Table";
import CustomAlert from "../../common/CustomAlert/CustomAlert";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import SearchHeaderCustom from "../../common/SearchHeaderCustom/SearchHeaderCustom";

import { selectUserDetails } from "../../Login/redux/userSlice";
import { fetchCurrentConfigurationAction, fetchCurrentConfigurationBasedOnSearchAction, selectDeliveryPromiseServiceCurrConfiguration } from "../redux/ruleEngineSlice";
import { RuleEngineCreateConfigButtonAccess } from "../Utils/RuleEngineAccessUtils";
import { getCurrentConfigTableColumn } from "./CurrentConfigUtils";

// const BREADCRUMB_LINKS = [{ name: "Rule Engine", to: APP_ROUTES.RULE_ENGINE, isCurrentRoute: true }];
interface DeliveryPromiseServiceCurrentConfigurationsInterface {
  serviceName: any;
  userInfo: any;
}
const DeliveryPromiseServiceCurrentConfigurations = (props: DeliveryPromiseServiceCurrentConfigurationsInterface) => {
  const { serviceName, userInfo } = props;

  const dispatch = useDispatch();

  const commonClasses = commonStyles();
  const classes = currentConfigStyle();

  const [tableColumns, setTableColumns] = useState<any>([]);
  const [apiParams, setApiParams] = useState({ query: "", currentPage: 0, size: 40, maxRecordPresent: 0 });
  const [searchObj, setSearchObj] = useState({ searchTypeValue: "posNo", searchValue: "" });

  const {
    currentCountry: { countryId },
  } = useSelector((state: any) => state.appConfig);
  const { fetchStatus: deliveryPromiseServiceCurrConfigStatus, data: deliveryPromiseServiceCurrConfiguration } = useSelector((state) =>
    selectDeliveryPromiseServiceCurrConfiguration(state, serviceName)
  );

  const getTableRows = () => {
    let tableRows = [];
    const data = deliveryPromiseServiceCurrConfiguration?.data;

    if (data && data.content) {
      tableRows = data.content;
    }
    return tableRows;
  };

  const fetchServiceCurrentConfigurations = (pageNumber: any, size: any, query: any) => {
    const requestPayload = {
      serviceTypeName: SERVICE_TYPES.EXPRESS,
      countryId: countryId,
      page: pageNumber,
      size: size,
    } as any;
    if (query !== undefined && query !== "") {
      requestPayload.search = query;
    }
    dispatch(fetchCurrentConfigurationBasedOnSearchAction(requestPayload));
  };

  const designAPIParamQuery = (currentPage: any, size: any, searchTypeValue: any, searchValue: any) => {
    let query = "";
    if (searchTypeValue !== undefined && searchTypeValue !== "") {
      if (searchValue !== undefined && searchValue !== "") {
        if (searchTypeValue === "posNo") {
          query = `${searchTypeValue}:${searchValue.substring(0, 3)}`;
        } else {
          query = `${searchTypeValue}:${searchValue}`;
        }
      }
    } else {
      query = "";
    }
    fetchServiceCurrentConfigurations(currentPage, size, query);
  };

  const fetchNextRecord = (pageNumber: any) => {
    setApiParams({ ...apiParams, currentPage: pageNumber });
    let searchTypeValue = "";
    let searchValue = "";
    if (searchObj !== undefined && searchObj.searchTypeValue !== undefined && searchObj.searchTypeValue !== "") {
      searchTypeValue = searchObj.searchTypeValue;
    }
    if (searchObj !== undefined && searchObj.searchValue !== undefined && searchObj.searchValue !== "") {
      searchValue = searchObj.searchValue;
    }
    designAPIParamQuery(pageNumber, apiParams.size, searchTypeValue, searchValue);
  };

  const getPageNumber = (userDataRecord: any) => {
    if (userDataRecord && userDataRecord.number && userDataRecord.number >= 0) {
      return userDataRecord.number;
    }
    return 0;
  };

  const getMaxRecordPresent = (userDataRecord: any) => {
    if (userDataRecord && userDataRecord.totalElements) {
      return userDataRecord.totalElements;
    }
    return 0;
  };

  const resetDeliveryPromiseRecord = () => {
    setApiParams({ ...apiParams, currentPage: 0, query: "", maxRecordPresent: 0 });
    setSearchObj({ ...searchObj, searchTypeValue: "", searchValue: "" });
    designAPIParamQuery(apiParams.currentPage, apiParams.size, "", "");
  };

  const handleSearchTypeChange = (selected: any) => {
    if (selected !== undefined && selected !== "") {
      setSearchObj({ ...searchObj, searchTypeValue: selected });
    }
  };

  const handleSearchBoxTextChange = (event: any) => {
    if (event !== undefined && event.target !== undefined) {
      const { value } = event.target;
      setSearchObj({ ...searchObj, searchValue: value });
    }
  };

  const handleSearchIconClick = () => {
    if (searchObj !== undefined && searchObj.searchTypeValue !== undefined && searchObj.searchTypeValue !== "") {
      if (searchObj !== undefined && searchObj.searchValue !== undefined && searchObj.searchValue !== "") {
        designAPIParamQuery(apiParams.currentPage, apiParams.size, searchObj.searchTypeValue, searchObj.searchValue);
      } else {
        CustomAlert(ALERT_TYPES.ERROR, "Please provide search value");
      }
    } else {
      CustomAlert(ALERT_TYPES.ERROR, "Please provide search type");
    }
  };

  const handleSearchBoxKeyDownChange = (event: any) => {
    if ((event.key !== undefined && event.key === "Enter") || event.keyCode === 13) {
      handleSearchIconClick();
    }
  };

  useEffect(() => {
    function updateTableFooterData() {
      if (deliveryPromiseServiceCurrConfiguration?.data?.content) {
        const tableRows = deliveryPromiseServiceCurrConfiguration.data;
        setApiParams({ ...apiParams, currentPage: getPageNumber(tableRows), maxRecordPresent: getMaxRecordPresent(tableRows) });
      }
    }
    updateTableFooterData();
  }, [
    deliveryPromiseServiceCurrConfiguration,
    deliveryPromiseServiceCurrConfiguration?.data,
    deliveryPromiseServiceCurrConfiguration?.data?.content,
    deliveryPromiseServiceCurrConfiguration?.data?.pageNumber,
  ]);

  useEffect(() => {
    userInfo && userInfo.userProfile && userInfo.userProfile.role && setTableColumns(getCurrentConfigTableColumn(classes, userInfo));
  }, [userInfo]);

  useEffect(() => {
    if (countryId !== "") {
      fetchServiceCurrentConfigurations(apiParams.currentPage, apiParams.size, apiParams.query);
    }
  }, [dispatch, countryId]);

  return (
    <Accordion defaultExpanded className={commonClasses.accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={commonClasses.accordionSummary}>
        {serviceName}
      </AccordionSummary>
      <AccordionDetails className={commonClasses.accordionDetailsDeliveryPromiseTable}>
        <div className={commonClasses.fullScreenStyle}>
          <Grid container>
            <Grid item xs={6} style={{ paddingLeft: "16px" }}>
              <SearchHeaderCustom
                searchTypes={DELIVERY_PROMISE_SEARCH_TYPES}
                searchType={searchObj.searchTypeValue}
                handleSearchTypeChange={handleSearchTypeChange}
                searchValue={searchObj.searchValue}
                handleSearchBoxTextChange={handleSearchBoxTextChange}
                handleSearchBoxKeyDownChange={handleSearchBoxKeyDownChange}
                placeholderText={`Search ${
                  searchObj !== undefined &&
                  searchObj.searchTypeValue !== undefined &&
                  searchObj.searchTypeValue !== "" &&
                  DELIVERY_PROMISE_SEARCH_TYPES !== undefined &&
                  Array.isArray(DELIVERY_PROMISE_SEARCH_TYPES) &&
                  DELIVERY_PROMISE_SEARCH_TYPES.length > 0
                    ? `by ${DELIVERY_PROMISE_SEARCH_TYPES.filter((x: any) => x.value === searchObj.searchTypeValue)[0].name || searchObj.searchTypeValue}`
                    : ""
                }`}
                handleIconClick={handleSearchIconClick}
                resetRecord={resetDeliveryPromiseRecord}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.tableStyle}>
                <Table
                  columnsData={tableColumns}
                  rowsData={getTableRows()}
                  loading={deliveryPromiseServiceCurrConfigStatus === API_RESPONSE_STATUS.LOADING}
                  hideFooter={false}
                  pageSize={apiParams.size}
                  rowCount={apiParams.maxRecordPresent}
                  pageNumber={apiParams.currentPage}
                  fetchNextRecord={fetchNextRecord}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const CurrentConfiguration = () => {
  const classes = currentConfigStyle();
  const userInfo = useSelector(selectUserDetails);

  return (
    <div className={classes.root}>
      <div className={classes.headerRoot}>
        <div className={classes.header}>
          <span className={classes.heading}>{`Delivery Promise Rule Engine Current Configuration`}</span>
        </div>
        <RuleEngineCreateConfigButtonAccess userInfo={userInfo} classes={classes} />
      </div>
      {userInfo && <DeliveryPromiseServiceCurrentConfigurations serviceName={SERVICE_TYPES.EXPRESS} userInfo={userInfo} />}
    </div>
  );
};

export default React.memo(CurrentConfiguration);
