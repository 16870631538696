import Fetch from "./Fetch";

import { API_HOST_URL, REACT_APP_DOS_RULE_ENGINE_API_URL } from "../config/constants";
import { handleError, handleResponse } from "./ApiUtils";

export const fetchPOSZoneServiceMapping = (data) => {
  return Fetch.get(`${API_HOST_URL}/delivery-bag/pos-zone-service-mapping`, data).then(handleResponse).catch(handleError);
};

export const fetchPOSBagMapping = (posId, countryId) => {
  return Fetch.get(`${REACT_APP_DOS_RULE_ENGINE_API_URL}/singleusebag/countries/${countryId}/config/${posId}`).then(handleResponse).catch(handleError);
};

export const updatePOSBagMapping = (posBagMappingDetail, posId, countryId) => {
  return Fetch.update(`${REACT_APP_DOS_RULE_ENGINE_API_URL}/singleusebag/countries/${countryId}/config/${posId}`, posBagMappingDetail).then(handleResponse).catch(handleError);
};

export const fetchPlasticBagRecordList = (data) => {
  return Fetch.get(`${REACT_APP_DOS_RULE_ENGINE_API_URL}/singleusebag/countries/${data.countryId}/config`).then(handleResponse).catch(handleError);
};

export const fetchPosZoneServiceTypeList = (data) => {
  return Fetch.get(`${API_HOST_URL}/delivery-bag/pos-zone-service-mapping`, data).then(handleResponse).catch(handleError);
};

export const postNewPlasticBagConfig = (data) => {
  return Fetch.create(`${REACT_APP_DOS_RULE_ENGINE_API_URL}/singleusebag/config`, data).then(handleResponse).catch(handleError);
};
