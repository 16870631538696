import { ALERT_TYPES } from "config/constants";
import { isEmptyOrNull } from "utils/DataUtils";
import CustomAlert from "../../../common/CustomAlert/CustomAlert";

export const capitalizeFirstLetter = (nameString: any) => {
  return nameString.charAt(0).toUpperCase() + nameString.slice(1).toLowerCase();
};

export const designPosZoneServiceListRecord = (responseObject: any) => {
  let listData = [] as any;
  let totalRowsCountData = 0;
  if (responseObject && responseObject.content && Array.isArray(responseObject.content) && responseObject.content.length > 0) {
    responseObject.content.forEach((element: any) => {
      const { posId, posNo, storeName } = element;
      const mainPosObject = {
        id: posNo,
        posId: posId,
        posNo: posNo,
        posName: storeName,
        zoneServicetypeList: [] as any,
        serviceTypeRecord: 0,
        zoneTypeListRecord: 0,
        isOpened: false,
        isChecked: false,
      };
      const serviceTypeList = new Set<string>();
      const zoneTypeList = new Set<string>();
      if (element && element.zoneServiceTypeList && Array.isArray(element.zoneServiceTypeList) && element.zoneServiceTypeList.length > 0) {
        const { zoneServiceTypeList } = element;
        zoneServiceTypeList.forEach((elementType: any) => {
          serviceTypeList.add(elementType.serviceType);
          zoneTypeList.add(elementType.zoneName);
          const subPosObject = {
            posId: posId,
            posNo: posNo,
            posName: storeName,
            zoneName: elementType.zoneName,
            zoneId: elementType.zoneId,
            serviceTypeId: elementType.serviceTypeId,
            serviceType: elementType.serviceType,
            isChecked: false,
            cncReferenceId: elementType.cncReferenceId !== "" ? elementType.cncReferenceId : "",
            status: elementType?.status || "",
          };
          mainPosObject.zoneServicetypeList.push(subPosObject);
          totalRowsCountData++;
        });
      }
      mainPosObject.serviceTypeRecord = serviceTypeList.size;
      mainPosObject.zoneTypeListRecord = zoneTypeList.size;
      listData.push(mainPosObject);
    });
  }
  const returnObject = {
    totalRowsCountData: totalRowsCountData,
    listData: listData,
  };
  return returnObject;
};

export const validateNewPosRecord = (configurationState: any, posZoneMappingState: any) => {
  if (configurationState) {
    if (isEmptyOrNull(configurationState.itemsPerBag)) {
      CustomAlert(ALERT_TYPES.ERROR, "Items per bag is required");
      return true;
    }

    if (Number(configurationState.itemsPerBag) <= 0) {
      CustomAlert(ALERT_TYPES.ERROR, "Items per bag cannot be less than one");
      return true;
    }

    if (isEmptyOrNull(configurationState.pricePerBag)) {
      CustomAlert(ALERT_TYPES.ERROR, "Price per bag is required");
      return true;
    }

    if (
      posZoneMappingState &&
      posZoneMappingState.selectedPosServiceTypeZone &&
      Array.isArray(posZoneMappingState.selectedPosServiceTypeZone) &&
      posZoneMappingState.selectedPosServiceTypeZone.length &&
      posZoneMappingState.selectedPosServiceTypeZone.length > 0
    ) {
      return false;
    } else {
      CustomAlert(ALERT_TYPES.ERROR, "Assigned area(s) list is required");
      return true;
    }
  }
  return true;
};

export const handleHeaderInnerRowCheckboxOnchange = (checked: any, posListIndex: any, posDataIndex: any, posZoneMappingState: any) => {
  const obj: any = {
    currentData: [...posZoneMappingState.posZoneServiceFilteredRecord],
    totalAssignedAreaCount: posZoneMappingState.totalAssignedAreaCount,
    selectedListItems: [...posZoneMappingState.selectedPosServiceTypeZone],
  };
  if (obj.currentData && obj.totalAssignedAreaCount >= 0 && obj.selectedListItems) {
    obj.currentData[posListIndex].zoneServicetypeList[posDataIndex].isChecked = checked;

    if (checked) {
      obj.selectedListItems.push(obj.currentData[posListIndex].zoneServicetypeList[posDataIndex]);
      obj.totalAssignedAreaCount++;
    } else {
      const dataToDelete = obj.currentData[posListIndex].zoneServicetypeList[posDataIndex];
      var removeIndex = obj.selectedListItems.findIndex((item: any) => item.posNo === dataToDelete.posNo && item.serviceType === dataToDelete.serviceType && item.zoneName === dataToDelete.zoneName);
      if (removeIndex !== -1) {
        obj.selectedListItems.splice(removeIndex, 1);
      }
      obj.totalAssignedAreaCount--;
    }

    // Checking if all inner are checked then make parent checked also
    // Checking if any inner are un-checked then make parent un-checked also
    //
    let totalRowsSelected = 0;
    obj.currentData[posListIndex].zoneServicetypeList.forEach((item: any) => {
      if (item.isChecked) {
        totalRowsSelected++;
      }
    });
    if (totalRowsSelected === obj.currentData[posListIndex].zoneServicetypeList.length) {
      obj.currentData[posListIndex].isChecked = checked;
    } else {
      obj.currentData[posListIndex].isChecked = false;
    }
  }
  return obj;
};

export const handleHeaderMainRowCheckboxOnchange = (checked: any, posListObj: any, posZoneMappingState: any) => {
  const obj: any = {
    currentData: [...posZoneMappingState.posZoneServiceRecord],
    totalAssignedAreaCount: posZoneMappingState.totalAssignedAreaCount,
    selectedListItems: [...posZoneMappingState.selectedPosServiceTypeZone],
  };
  if (obj.currentData && obj.totalAssignedAreaCount >= 0 && obj.selectedListItems) {
    // Bug on search
    let posListIndex = 0;
    if (posListObj !== undefined && posListObj.id !== undefined && posListObj.id !== "") {
      posListIndex = obj.currentData.findIndex((item: any) => item.id === posListObj.id);
      if (posListIndex >= 0) {
        //
        obj.currentData[posListIndex].isChecked = checked;
        obj.currentData[posListIndex].zoneServicetypeList.forEach((posDetails: any) => {
          posDetails.isChecked = checked;
          if (checked) {
            obj.totalAssignedAreaCount++;
            obj.selectedListItems.push(posDetails);
          } else {
            obj.totalAssignedAreaCount--;
            var removeIndex = obj.selectedListItems.findIndex((item: any) => item.posNo === posDetails.posNo && item.serviceType === posDetails.serviceType && item.zoneName === posDetails.zoneName);
            if (removeIndex !== -1) {
              obj.selectedListItems.splice(removeIndex, 1);
            }
          }
        });
      }
    }
  }
  return obj;
};

export const handleDeleteSelectedRow = (itemData: any, index: any, posZoneMappingState: any) => {
  const obj: any = {
    currentData: [...posZoneMappingState.posZoneServiceRecord],
    totalAssignedAreaCount: posZoneMappingState.totalAssignedAreaCount,
    selectedListItems: [...posZoneMappingState.selectedPosServiceTypeZone],
  };
  if (obj.currentData && obj.totalAssignedAreaCount >= 0 && obj.selectedListItems) {
    let deleted_item_index = obj.selectedListItems.findIndex((item: any) => item.posNo === itemData.posNo && item.zoneId === itemData.zoneId && item.zoneName === itemData.zoneName);
    if (deleted_item_index > -1) {
      obj.selectedListItems.splice(deleted_item_index, 1);
      obj.totalAssignedAreaCount--;

      var removeIndex = obj.currentData.findIndex((item: any) => item.posNo === itemData.posNo);
      if (removeIndex !== -1) {
        obj.currentData[removeIndex].isChecked = false;
        const innerIndex = obj.currentData[removeIndex].zoneServicetypeList.findIndex(
          (item: any) => item.posNo === itemData.posNo && item.serviceType === itemData.serviceType && item.zoneName === itemData.zoneName
        );
        if (innerIndex !== -1) {
          obj.currentData[removeIndex].zoneServicetypeList[innerIndex].isChecked = false;
        }
      }
    }
  }
  return obj;
};

export const generateRequestPayload = (countryId: any, configurationState: any, posZoneMappingState: any) => {
  const obj: any = {
    countryId: countryId,
    bagDetails: [],
    selectedPosServiceTypeZone: [],
  };
  const bagDetailsData = {
    itemsPerBag: Number(configurationState.itemsPerBag),
    pricePerBag: Number(configurationState.pricePerBag),
  };
  obj.bagDetails.push(bagDetailsData);
  let selectedListItems: any = [];
  if (posZoneMappingState && posZoneMappingState.selectedPosServiceTypeZone && Array.isArray(posZoneMappingState.selectedPosServiceTypeZone)) {
    posZoneMappingState.selectedPosServiceTypeZone.forEach((item: any) => {
      const obj = {
        posId: item.posId,
        posNo: item.posNo,
        storeName: item.posName,
        zoneId: item.zoneId,
        zone: item.zoneName,
        serviceTypeId: item.serviceTypeId,
        serviceType: item.serviceType,
        cncReferenceId: item.cncReferenceId,
        status: item?.status || "",
      };
      selectedListItems.push(obj);
    });
  }
  obj.selectedPosServiceTypeZone = [...selectedListItems];
  return obj;
};

export const validateUpdatePosRecord = (editedBagList: any, itemsPerBagRegExp: any, pricePerBagRegExp: any) => {
  if (editedBagList) {
    let isErrorFound: boolean = false;
    Object.values(editedBagList).forEach((item: any) => {
      if (item) {
        if (!itemsPerBagRegExp.test(item.itemsPerBag)) {
          CustomAlert(ALERT_TYPES.ERROR, "Please provide valid input for Item Per Bag.");
          isErrorFound = true;
          return true;
        }
        if (Number(item.itemsPerBag) <= 0) {
          CustomAlert(ALERT_TYPES.ERROR, "Items per bag cannot be less than one");
          isErrorFound = true;
          return true;
        }
        if (!pricePerBagRegExp.test(item.pricePerBag)) {
          CustomAlert(ALERT_TYPES.ERROR, "Please provide valid input for Bag Price.");
          isErrorFound = true;
          return true;
        }
      }
    });

    if (!isErrorFound) {
      return false;
    }
  }
  return true;
};

export const designPosZoneServiceFilterListBasedOnSearchRecord = (dataList: any, searchText: any, currentList: any) => {
  // If any data checked
  let selected_list = currentList.filter((list: any) => list.isChecked === true);
  currentList.forEach((list: any) => {
    if (list.isChecked === false) {
      let no_inner_selected: any = false;
      list.zoneServicetypeList.forEach((innerList: any) => {
        if (innerList.isChecked === true) {
          no_inner_selected = true;
        }
      });
      if (no_inner_selected === true) {
        selected_list.push(list);
      }
    } else {
      selected_list.push(list);
    }
  });
  //
  let result_list = [] as any;
  if (dataList !== undefined && Array.isArray(dataList) && dataList.length > 0) {
    if (searchText !== undefined && searchText !== "") {
      let list_data = dataList.filter((list: any) => list.posNo && list.posNo.toLowerCase().includes(searchText.toLowerCase()));
      result_list = selected_list.concat(list_data);
    } else {
      result_list = selected_list.concat(dataList);
    }
  }

  // remove duplicate
  let map = new Map();
  result_list.forEach((item: any) => {
    if (!map.has(item.id)) {
      map.set(item.id, item);
    }
  });
  let listData = Array.from(map.values());

  const returnObject = {
    listData: listData,
  };
  return returnObject;
};

export const design_selected_pos_zone_mapping_filtered_list_based_on_search = (dataList: any, searchText: any) => {
  if (dataList && Array.isArray(dataList) && dataList.length > 0) {
    let filteredList = [...dataList];
    if (searchText !== undefined && searchText !== "") {
      filteredList = filteredList.filter((list: any) => list.posNo && list.posNo.toLowerCase().includes(searchText.toLowerCase()));
    }
    return filteredList;
  }
  return [];
};
