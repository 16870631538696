export interface LoginPageState {
    loading: boolean,
    openNewUserPopup: boolean,
    dialogHeader: string,
    error: any
}

export const loginInitState = {
    loading: false,
    openNewUserPopup: false,
    dialogHeader: '',
    error: {}
}