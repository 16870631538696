import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../../config/theme/theme";

const drawerWidth = "320";
const topBarHeight = "80";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "auto",
    fontFamily: "Montserrat",
    display: "flex",
    margin: 0,
    padding: 0,
    outline: "none",
    background: "#FFFFFF",
  },
  content: {
    position: "absolute",
    flexGrow: 1,
    paddingLeft: "65px",
    paddingRight: "12px",
    overflow: "hidden",
  },
  mainRouteDiv: {
    padding: 0,
    margin: 0,
    background: "#FFFFFF",
    width: "calc(100vw - 77px)",
  },
  drawer: {
    flexShrink: 0,
    width: `${drawerWidth}px`,
  },
  drawerPaperOpen: {
    position: "fixed",
    paddingLeft: "0px",
    paddingRight: "0px",
    background: "#F5F5F5",
    border: "none",
    height: "calc(100vh - 80px)",
    width: `${drawerWidth}px`,
  },
  drawerPaperClose: {
    paddingLeft: "0px",
    paddingRight: "0px",
    background: "#F5F5F5",
    border: "none",
    overflowX: "hidden",
    width: "65px",
    display: "block",
  },
  listStyle: {
    color: "#4D4D4D",
    height: "60px",
    borderBottom: "1px solid #fff",
    "&.Mui-selected": {
      backgroundColor: "#E9EFF2",
      color: "#0E5AA7",
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: "bold",
      fill: "#0E5AA7",
      borderLeft: "8px solid #0E5AA7",
    },
    "&.MuiListItemText-root": {
      color: "#0E5AA7",
      fontWeight: "bold",
    },
    "&.MuiListItemText-root.MuiTypography-body1": {
      color: "#0E5AA7",
      fontWeight: "bold",
    },
  },
  listIconSVG: {
    margin: 0,
    padding: 0,
  },
  listItemSelectedSpan: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    color: "#0E5AA7",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  listItemSpan: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px",
    color: "#4D4D4D",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  paperAnchorLeft: {
    marginTop: `${topBarHeight}px`,
  },
  approvalCountListItemSpanStyle: {
    height: "20px",
    width: "35px",
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: 600,
    background: COLORS.LIGHT_RED_BACKGROUND,
    border: "1px solid",
    borderColor: COLORS.DARK_RED,
    borderRadius: "8px",
    textAlign: "center",
    color: COLORS.DARK_RED,
    "& .MuiTypography-body1": {
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: 600,
    },
  },
  arrowIconStyle: {
    transform: "rotate(90deg)",
  },
  collapselistItemSelectedSpan: {
    display: "flex",
    justifyContent: "flex-start",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    color: "#0E5AA7",
    paddingLeft: "30%",
    paddingRight: "0px",
  },
  collapselistItemSpan: {
    display: "flex",
    justifyContent: "flex-start",
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px",
    color: "#4D4D4D",
    paddingLeft: "30%",
    paddingRight: "0px",
  },
  approvalCountSpanStyle: {
    height: "20px",
    width: "35px",
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: 600,
    background: COLORS.LIGHT_RED_BACKGROUND,
    border: "1px solid",
    borderColor: COLORS.DARK_RED,
    borderRadius: "8px",
    textAlign: "center",
    color: COLORS.DARK_RED,
    "& .MuiTypography-body1": {
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: 600,
    },
  },
}));
