function handleUnauthroizedRequest() {
  localStorage.clear();
}

function constructErrorMsg(errorBody) {
  let errorObj = new Error("Error occured while processing your request.");
  if (errorBody && errorBody.message) {
    throw errorBody;
  } else if (errorBody && errorBody.errors) {
    if (Array.isArray(errorBody.errors) && errorBody.errors.length > 0) {
      const error = errorBody.errors[0];
      if (error && error.errorMessage) {
        errorObj = new Error(error.errorMessage);
      } else if (error !== undefined && error !== "" && typeof error === "string") {
        errorObj = new Error(error);
      }
    }
  }
  return errorObj;
}

export async function handleResponse(response) {
  if (response.ok) {
    try {
      let result = await response.json();
      return { response: result };
    } catch (error) {
      return { response: { status: response.status } };
    }
  } else {
    if (response.status === 401 || response.status === 403) {
      handleUnauthroizedRequest();
    }
    const errorBody = await response.json();
    throw constructErrorMsg(errorBody);
  }
}

export async function handleResponseLogin(response) {
  if (response.ok) {
    let result = await response.json();
    return { response: result };
  } else {
    const errorBody = await response.json();
    throw constructErrorMsg(errorBody);
  }
}

export function handleError(error) {
  console.error("API call failed. " + error);
  throw error;
}

export const getFileNameFromHeader = (response) => {
  let fileName = "Download.csv";
  if (response !== undefined) {
    const contentDispositionHeader = response.headers.get("Content-disposition");
    if (contentDispositionHeader !== undefined) {
      if (contentDispositionHeader.includes("filename")) {
        let arr = contentDispositionHeader.split(`filename=`);
        if (arr !== undefined && Array.isArray(arr) && arr.length >= 1) {
          fileName = arr[1];
          fileName = fileName.replace(/"/g, "");
        }
      } else {
        let arr = contentDispositionHeader.split(`"`);
        if (arr !== undefined && Array.isArray(arr) && arr.length >= 1) {
          fileName = arr[1];
          fileName = fileName.replace(/"/g, "");
        }
      }
    }
  }

  return fileName;
};

export async function handleCSVResponse(response) {
  if (response.ok) {
    let fileName = getFileNameFromHeader(response);
    let result = await response.text();
    return { response: result, fileName };
  } else {
    if (response.status === 401) {
      handleUnauthroizedRequest();
    }
    const errorBody = await response.json();
    throw constructErrorMsg(errorBody);
  }
}

export async function handleXLSXResponse(response) {
  if (response.ok) {
    let fileName = getFileNameFromHeader(response);
    let result = await response.blob();
    return { response: result, fileName };
  } else {
    if (response.status === 401) {
      handleUnauthroizedRequest();
    }
    const errorBody = await response.json();
    throw constructErrorMsg(errorBody);
  }
}
