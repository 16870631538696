import React from "react";

import { Link } from "react-router-dom";

import { APP_ROUTES, USER_TYPES } from "../../../config/constants";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";

import { userStyles } from "../Styles/DeliveryFeePageStyles";
import { CREATE_NEW_CONFIGURATION_BUTTON, HEADER_TEXT } from "../Utils/DeliveryFeeConstants";

interface DeliveryFeeHeaderInterface {
  userInfo: any;
}

const DeliveryFeeHeader = (props: DeliveryFeeHeaderInterface) => {
  const classes = userStyles();
  const { userInfo } = props;
  return (
    <div className={classes.headerRoot}>
      <div className={classes.header}>
        <span className={classes.heading}>{`${HEADER_TEXT}`}</span>
      </div>

      {userInfo && userInfo.userProfile && userInfo.userProfile.role && (userInfo.userProfile.role === USER_TYPES.MAF_ADMIN || userInfo.userProfile.role === USER_TYPES.SUPER_ADMIN) && (
        <Link to={APP_ROUTES.DELIVERY_FEE_CONFIGURATION} style={{ textDecoration: "none" }}>
          <PrimaryButton className={classes.buttonStyles} buttonLabel={`${CREATE_NEW_CONFIGURATION_BUTTON}`} buttonVariant="outlined" />
        </Link>
      )}
    </div>
  );
};

export default React.memo(DeliveryFeeHeader);
