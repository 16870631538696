// Material UI Components
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { QUOTA_VIEW_OPTIONS } from "../../../../config/constants";
// Utils
import { generateDropdownItems } from "../../../../utils/helperFunctions";
import { ReactComponent as CloseButton } from "../../../assets/svg/closeButton.svg";
import CustomAlert from "../../../common/CustomAlert/CustomAlert";
import PrimaryButton from "../../../common/PrimaryButton/PrimaryButton";
import SelectBox from "../../../common/SelectBox/SelectBox";
import TextBox from "../../../common/TextBox";
// Styles
import quotaSettingsStyles from "./QuotaSettingsStyles";
import ViewQuotaSettings from "./ViewQuotaSettings";








const NUMBER_OF_QUOTA_OPTIONS = [
  { id: 1, name: "1" },
  { id: 2, name: "2" },
];

const HOURS = generateDropdownItems(0, 23, "hours");

const generateQuotas = (noOfQuota) => {
  let quotas = [];
  if (noOfQuota >= 1) {
    let quota = {};
    for (let i = 1; i <= noOfQuota; i++) {
      quota = { ...quota, quotaName: `Quota ${i}`, quotaCutOffTime: "", quotaCapacityPercentage: "" };
      quotas.push(quota);
    }
  }

  return quotas;
};

const QuotaDetails = ({
  index,
  hoursAvailableForSelection,
  classes,
  quotaName,
  quotaCutOffTime,
  quotaCapacityPercentage,
  quotaCutOffTimeError,
  quotaCapacityPercentageError,
  handleQuotaFieldsChange,
}) => {
  return (
    <div className={classes.quotaDetailContainer}>
      <div className={classes.smallerTitle}>{quotaName}</div>
      <Grid container spacing={2} className={classes.quotaInputs}>
        <Grid item xs={6}>
          <SelectBox
            fieldLabel="Hrs before Order Cut-off"
            name="quotaCutOffTime"
            value={quotaCutOffTime}
            menuItems={hoursAvailableForSelection}
            error={!!quotaCutOffTimeError && quotaCutOffTime === ""}
            handleChange={handleQuotaFieldsChange}
          />
          {quotaCutOffTimeError && quotaCutOffTime === "" && (
            <div className={classes.error}>{quotaCutOffTimeError}</div>
          )}
        </Grid>
        <Grid item xs={6}>
          <TextBox
            fieldLabel="Capactiy %"
            name="quotaCapacityPercentage"
            type="number"
            value={quotaCapacityPercentage}
            error={!!quotaCapacityPercentageError}
            helperText={quotaCapacityPercentageError ? quotaCapacityPercentageError : ""}
            handleChange={handleQuotaFieldsChange}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const QuotaSettings = ({
  action,
  drawerAnchor,
  open,
  handleClose,
  quotas,
  selectedSlot,
  handleModifyQuotaSettings,
}) => {
  const classes = quotaSettingsStyles();

  const [noOfQuota, setNoOfQuota] = useState(quotas.length || 1);
  const [localQuotas, setLocalQuotas] = useState(quotas);

  const getAvailableHoursForSelection = (index) => {
    let hours = [...HOURS];

    if (index > 0 && localQuotas.length) {
      let selectedHours = parseInt(localQuotas[index - 1].quotaCutOffTime);
      hours = hours.filter((hour) => hour.id < selectedHours);
    }

    return hours;
  };

  const handleNoOfQuotaChange = (e) => {
    setNoOfQuota(e.target.value);
  };

  /**
   * Function to create dynamic inputs for adding quotas
   */
  const handleQuotaCreations = () => {
    if (localQuotas.length !== noOfQuota) {
      const generatedQuotas = generateQuotas(noOfQuota);
      setLocalQuotas(generatedQuotas);
    }
  };

  /**
   *
   * @param {number} index
   * @param {*} e
   * Handler for quota forms dynamic fields
   */
  const handleIndividualQuotaFieldsChange = (index, e) => {
    const { name, value } = e.target;

    let updatedQuotas = localQuotas.map((q, i) => (i === index ? { ...q, [name]: value } : { ...q }));
    setLocalQuotas(updatedQuotas);
  };

  /**
   * Validator for quota creation form
   */
  const validateQuotaSettings = () => {
    let newErrors = [];
    let formSubmitError = "";
    let newLocalQuotas = JSON.parse(JSON.stringify(localQuotas));
    // let totalCapacity = localQuotas.reduce((acc, val) => acc + parseInt(val.quotaCapacityPercentage), 0);

    // if (totalCapacity > 100) {
    //   CustomAlert("error", "Total Capacity of the quotas can't be greater than 100");
    //   return false;
    // }

    localQuotas.forEach((q, index) => {
      let error = {};
      newLocalQuotas[index].capacityError = "";
      newLocalQuotas[index].cutOffTimeError = "";
      if (!q.quotaCapacityPercentage) {
        let errorMsg = "Please input capacity in percentage";
        error.quotaCapacityPercentage = errorMsg;
        newLocalQuotas[index].capacityError = errorMsg;
      }

      if (q.quotaCutOffTime === "") {
        let errorMsg = "Please input hours before buffer time";
        error.quotaCutOffTime = errorMsg;
        newLocalQuotas[index].cutOffTimeError = errorMsg;
      }

      if (index > 0 && parseInt(q.quotaCapacityPercentage) > parseInt(localQuotas[index - 1].quotaCapacityPercentage)) {
        formSubmitError = `${q.quotaName} Capacity should be lesser than the previous quota capacity`;
      }

      if (Object.keys(error).length) {
        newErrors[index] = { ...error };
      }
    });

    if (formSubmitError) {
      CustomAlert("error", formSubmitError);
      return false;
    }

    setLocalQuotas(newLocalQuotas);

    return newErrors.length === 0;
  };

  const handleSubmit = () => {
    if (validateQuotaSettings()) {
      handleModifyQuotaSettings(localQuotas);
      handleClose();
    }
  };

  const renderConfigureQuotaSettingsForm = () => {
    return (
      <>
        <div className={classes.quotaSettingsTitle}>Quota Settings</div>
        <div className={classes.closeButton}>
          <CloseButton className={classes.closeButtonSVG} onClick={handleClose} />
        </div>
        <div container className={classes.creataQuotaForm}>
          <div className={classes.smallerTitle}>Create Quota</div>
          <div className={classes.fieldLabel}>Select No of Quota</div>
          <Grid container spacing={3}>
            <Grid item xs={9}>
              <SelectBox menuItems={NUMBER_OF_QUOTA_OPTIONS} value={noOfQuota} handleChange={handleNoOfQuotaChange} />
            </Grid>
            <Grid item xs={3}>
              <PrimaryButton buttonLabel="Add" className={classes.buttonStyles} onClick={handleQuotaCreations} />
            </Grid>
          </Grid>

          {localQuotas.length ? (
            <div className={classes.generatedQuotasContainer}>
              {localQuotas.map((quotaDetail, index) => (
                <QuotaDetails
                  key={quotaDetail.quotaName}
                  index={index}
                  hoursAvailableForSelection={getAvailableHoursForSelection(index)}
                  classes={classes}
                  quotaName={quotaDetail.quotaName}
                  quotaCutOffTime={quotaDetail.quotaCutOffTime}
                  quotaCutOffTimeError={quotaDetail.cutOffTimeError}
                  quotaCapacityPercentage={quotaDetail.quotaCapacityPercentage}
                  quotaCapacityPercentageError={quotaDetail.capacityError}
                  handleQuotaFieldsChange={(e) => handleIndividualQuotaFieldsChange(index, e)}
                />
              ))}
            </div>
          ) : null}
        </div>
        <div className={classes.createButton}>
          <PrimaryButton
            buttonLabel={quotas.length ? "Update" : "Create"}
            className={`${classes.buttonStyles} ${classes.updateButton}`}
            onClick={handleSubmit}
            disabled={!localQuotas.length}
          />
        </div>
      </>
    );
  };

  return (
    <div className={classes.quotaSettings}>
      <Drawer anchor={drawerAnchor} open={open} onClose={handleClose} disableBackdropClick>
        <div className={classes.quotaSettingsContainer}>
          {action === QUOTA_VIEW_OPTIONS.VIEW ? (
            <ViewQuotaSettings
              handleClose={handleClose}
              quotas={quotas}
              startTime={selectedSlot.startTime}
              slotTime={selectedSlot.time}
              capacity={selectedSlot.slotCapacity}
              bufferTime={selectedSlot.bufferTime}
              noOfQuotas={quotas.length}
            />
          ) : (
            renderConfigureQuotaSettingsForm()
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default QuotaSettings;

QuotaSettings.propTypes = {
  action: PropTypes.string,
  drawerAnchor: PropTypes.string,
  open: PropTypes.bool.isRequired,
};

QuotaSettings.defaultProps = {
  action: QUOTA_VIEW_OPTIONS.MODIFY,
  drawerAnchor: "right",
};
