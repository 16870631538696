import React from "react";
import produce from "immer";

import { Accordion, Grid, AccordionDetails, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { isObjectEmptyOrNull } from "utils/DataUtils";
import { generateDropdownItems } from "../../../../utils/helperFunctions";
import { ReactComponent as EditIcon } from "../../../assets/svg/Pencil.svg";

import styles from "./RangeGeneratorFormStyles";
import LinkButton from "../../../common/LinkButton/LinkButton";
import SelectBox from "../../../common/SelectBox/SelectBox";
import TimePicker from "../../../common/TimePicker/TimePicker";
import { TEXT_CONSTANT } from "../DefaultInterface";

const HOURS = generateDropdownItems(0, 96, "hours");

const MINIMUM_DAYS = generateDropdownItems(0, 10, "days");
const MAXIMUM_DAYS = generateDropdownItems(1, 20, "days");

interface EditSlotRangeProps {
  tabIndex: number;
  formIndex: number;
  dayWiseSlots: any;
  generatedSlots: any;
  onIndividualRangeFieldsChange: any;
  handleUpadteConfig: any;
  makeSlotEditable: any;
  cancelEditingASlot: any;
}

const EditSlotRange = (props: EditSlotRangeProps) => {
  const { tabIndex, formIndex, dayWiseSlots, generatedSlots, handleUpadteConfig, onIndividualRangeFieldsChange, makeSlotEditable, cancelEditingASlot } = props;
  const classes = styles();
  const reg = new RegExp("^[0-9]+$");

  const handleConfigChange = (event: any, dayName: string) => {
    const { name, value } = event.target;
    if (name === "slotCapacity" && value !== "" && !reg.test(value)) {
      return;
    }

    let newRangeConfig: any = produce(generatedSlots[dayName], (draft: any) => {
      draft[0][name] = value;
    });
    onIndividualRangeFieldsChange(tabIndex, formIndex, { ...generatedSlots, [dayName]: newRangeConfig });
  };

  const cutOffTimeChange = (date: any, dayName: string) => {
    let newRangeConfig: any = produce(generatedSlots[dayName], (draft: any) => {
      draft[0].cutOff = date;
    });
    onIndividualRangeFieldsChange(tabIndex, formIndex, { ...generatedSlots, [dayName]: newRangeConfig });
  };

  const renderEditSlotRange = (slotInfo: any, index: number) => {
    const { canBeUpdated, isUpdating }: any = dayWiseSlots && slotInfo && dayWiseSlots[slotInfo.day] && dayWiseSlots[slotInfo.day][0] ? dayWiseSlots[slotInfo.day][0] : {};
    slotInfo = isObjectEmptyOrNull(slotInfo) ? {} : slotInfo;

    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={3} className={classes.slotsTableLabel}>
            {`${TEXT_CONSTANT.MINIMUM_DAYS}`}
          </Grid>
          <Grid item xs={3} className={classes.slotsTableLabel}>
            {`${TEXT_CONSTANT.MAXIMUM_DAYS}`}
          </Grid>
          <Grid item xs={3} className={classes.slotsTableLabel}>
            {`${TEXT_CONSTANT.CUT_OFF}`}
          </Grid>
          <Grid item xs={3} className={classes.slotsTableLabel}>
            {`${TEXT_CONSTANT.ACTIONS}`}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <SelectBox name="minDay" menuItems={MINIMUM_DAYS} value={slotInfo.minDay} disabled={!canBeUpdated} handleChange={(e: any) => handleConfigChange(e, slotInfo.day)} />
          </Grid>
          <Grid item xs={3}>
            <SelectBox name="maxDay" menuItems={MAXIMUM_DAYS} disabled={!canBeUpdated} value={slotInfo.maxDay} handleChange={(e: any) => handleConfigChange(e, slotInfo.day)} />
          </Grid>
          <Grid item xs={3}>
            <TimePicker
              value={slotInfo.cutOff}
              handleChange={(date: any) => cutOffTimeChange(date, slotInfo.day)}
              disabled={!canBeUpdated}
              fieldLabel={undefined}
              className={undefined}
              timerProps={undefined}
            />
            {/* <SelectBox
              name="bufferTime"
              menuItems={HOURS}
              value={slotInfo.bufferTime}
              disabled={!canBeUpdated}
              labelClass={classes.hideShrinkedLabel}
              selectClass={classes.selectClass}
              handleChange={(e: any) => handleConfigChange(e, slotInfo.day)}
            /> */}
          </Grid>
          <Grid item xs={3} style={{ alignSelf: "center" }}>
            <Grid container spacing={4}>
              {canBeUpdated ? (
                <Grid item xs={10}>
                  <Grid container spacing={3}>
                    <Grid item xs={5}>
                      <LinkButton
                        className={classes.updateSlotButton}
                        buttonLabel={isUpdating ? "saving..." : "Save"}
                        disabled={isUpdating || slotInfo.slotCapacity === ""}
                        onClick={() => handleUpadteConfig(index, slotInfo)}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <LinkButton
                        className={classes.updateSlotButton}
                        buttonLabel="Cancel"
                        disabled={isUpdating}
                        onClick={() => {
                          cancelEditingASlot(index, slotInfo);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={2}>
                  <EditIcon className={classes.iconButton} onClick={() => makeSlotEditable(slotInfo)} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <div className={classes.slotsGeneratorForm}>
      {Object.keys(generatedSlots).map((element: any, index: number) => (
        <Accordion key={element} defaultExpanded={true} className={classes.whiteBackground}>
          <AccordionSummary className={classes.slotsGeneratorAccordionSummary} expandIcon={<ExpandMoreIcon />} classes={{ expanded: classes.expandedStyle }}>
            <Grid container>
              <Grid item xs={6} className={classes.daysInput}>
                <div>
                  {`Slots for : `}
                  <span className={classes.dayStyles}>{element.toLowerCase()}</span>
                </div>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.slotsGeneratorAccordionDetails} style={{ paddingBottom: 20 }}>
            {renderEditSlotRange(generatedSlots[element] && generatedSlots[element][0], index)}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default EditSlotRange;
