import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PrimaryButton from "components/common/PrimaryButton/PrimaryButton";
import TextBox from "components/common/TextBox";
import { ALERT_TYPES, CURRENT_COUNTRY, FEE_REG_EXP_VALUE, ITEMS_PER_BAG_REG_EXP } from "../../config/constants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { SINGLE_USE_BAG_CONFIGURATION } from "./CurrentConfiguration/Utils/PlasticBagCurrentCofigurationConstants";
import { capitalizeFirstLetter, validateUpdatePosRecord } from "./CurrentConfiguration/Utils/PlasticBagCurrentCofigurationUtils";
import { fetchPOSBagMapping, updatePosBagMapping } from "./redux/singleUsePlasticSlice";
import { userStyles } from "./SingleUsePlasticStyles";
import { ReactComponent as EditIcon } from "../assets/svg/Pencil.svg";
import LinkButton from "components/common/LinkButton/LinkButton";
import Loader from "../common/Loader/Loader";
import CustomAlert from "../common/CustomAlert/CustomAlert";

interface EditPlasticBagConfigurationProps {}

const EditPlasticBagConfiguration = (props: EditPlasticBagConfigurationProps) => {
  const classes = userStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  let location = useLocation<any>();

  const [editState, setEditState] = useState({ posId: "", posNo: "", countryId: "", loader: false });
  const [editableBagList, setEditableBagList] = useState<any>([]);
  const [editedBagList, setEditedBagList] = useState<any>({});
  const [posBagMapping, setPosBagMapping] = useState({ bagServiceZoneDetailsList: [] });

  const posBagMappingDetail: any = useSelector<any>((state) => (state.singleUsePlastic ? state.singleUsePlastic.posBagMappingDetail : {}));
  const updatePosBagMappingDetail: any = useSelector<any>((state) => (state.singleUsePlastic ? state.singleUsePlastic.updatePosBagMapping : {}));

  useEffect(() => {
    let posBagMapping: any = posBagMappingDetail && posBagMappingDetail.data ? posBagMappingDetail.data : {};
    setPosBagMapping(posBagMapping);
    let editableBagList = {};
    if (posBagMapping && posBagMapping.bagServiceZoneDetailsList && posBagMapping.bagServiceZoneDetailsList.length > 0)
      posBagMapping.bagServiceZoneDetailsList.map((item: any) => {
        editableBagList = { ...editableBagList, [item.id]: item };
      });
    setEditedBagList(editableBagList);
  }, [posBagMappingDetail]);

  useEffect(() => {
    if (updatePosBagMappingDetail && updatePosBagMappingDetail.success) {
      CustomAlert(ALERT_TYPES.SUCCESS, "Configuration updated successfully");
      history.push("/single-use-plastic");
    } else {
      setEditState({ ...editState, loader: false });
    }
  }, [updatePosBagMappingDetail]);

  const handleChange = (e: any, id: any) => {
    let bagItem: any = {};
    const { name, value, checked } = e.target;
    if (name === "itemsPerBag" && value !== "" && !ITEMS_PER_BAG_REG_EXP.test(value)) {
      return;
    } else if (name === "pricePerBag" && value !== "" && !FEE_REG_EXP_VALUE.test(value)) {
      return;
    }
    if (editedBagList[id]) {
      bagItem = editedBagList[id];
    } else {
      bagItem = posBagMapping.bagServiceZoneDetailsList.filter((item: any) => item.id === id)[0];
    }
    if (name === "active") {
      bagItem = { ...bagItem, [name]: checked };
    } else {
      bagItem = { ...bagItem, [name]: value };
    }
    setEditedBagList({ ...editedBagList, [id]: bagItem });
  };

  const makeEditable = (e: any, id: any, type: any) => {
    if (type === "CANCEL") {
      let obj = posBagMapping.bagServiceZoneDetailsList.filter((item: any) => item.id === id)[0];
      let editableArray = editableBagList.filter((item: any) => item !== id);
      setEditableBagList(editableArray);
      let editedList = { ...editedBagList, [id]: obj };
      setEditedBagList(editedList);
      return;
    }
    if (editableBagList.indexOf(id) !== -1) {
      return;
    }
    let arr = [...editableBagList, id];
    setEditableBagList(arr);
  };

  const handleUpdate = () => {
    const isValid = validateUpdatePosRecord(editedBagList, ITEMS_PER_BAG_REG_EXP, FEE_REG_EXP_VALUE);
    if (!isValid) {
      setEditState({ ...editState, loader: true });
      let posBagMappingEdited = { ...posBagMapping, bagServiceZoneDetailsList: Object.values(editedBagList) };
      dispatch(
        updatePosBagMapping({
          posBagMappingDetail: posBagMappingEdited,
          posId: editState.posId,
          countryId: editState.countryId,
        })
      );
    }
  };

  const renderSingleUseBagConfiguration = () => {
    return (
      <Accordion defaultExpanded={true} className={classes.accordionStyle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryStyle}>
          <Grid container alignItems="center">
            <Grid>
              <Typography className={classes.accordionTitle}>{capitalizeFirstLetter(SINGLE_USE_BAG_CONFIGURATION)}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetailsStyle}>
          <Grid container spacing={1}>
            <Grid item xs={1} className={classes.editLableNames}>
              Status
            </Grid>
            <Grid item xs={2} className={classes.editLableNames}>
              Service Type
            </Grid>
            <Grid item xs={2} className={classes.editLableNames}>
              Zone
            </Grid>
            <Grid item xs={2} className={classes.editLableNames}>
              CNC Reference Id
            </Grid>
            <Grid item xs={2} className={classes.editLableNames}>
              Items Per Bag
            </Grid>
            <Grid item xs={2} className={classes.editLableNames}>
              Price Per Bag
            </Grid>
            <Grid item xs={1} className={classes.editLableNames}>
              Actions
            </Grid>
          </Grid>
          {editedBagList &&
            Object.values(editedBagList).length > 0 &&
            Object.values(editedBagList).map((item: any, index: any) => (
              <Grid container spacing={1} key={`${index}-editedBagList`}>
                <Grid item xs={1} style={{ margin: "auto" }}>
                  <Typography className={classes.editLableNames}>{capitalizeFirstLetter(item.status)}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextBox name="serviceType" textBoxId="serviceType" placeholderText="Service Type" value={item.serviceType} className={classes.textBoxpaddingStyle} disabled={true}></TextBox>
                </Grid>
                <Grid item xs={2}>
                  <TextBox name="zone" textBoxId="zone" placeholderText="Zone" value={item.zone} className={classes.textBoxpaddingStyle} disabled={true}></TextBox>
                </Grid>
                <Grid item xs={2}>
                  <TextBox
                    name="cncReferenceId"
                    textBoxId="cncReferenceId"
                    placeholderText="CNC Reference Id"
                    value={item.cncReferenceId || ""}
                    className={classes.textBoxpaddingStyle}
                    disabled={true}
                  ></TextBox>
                </Grid>
                <Grid item xs={2}>
                  <TextBox
                    name="itemsPerBag"
                    textBoxId="itemsPerBag"
                    placeholderText="Number of Items Per Bag"
                    value={item.itemsPerBag}
                    handleChange={(e: any) => handleChange(e, item.id)}
                    className={classes.textBoxpaddingStyle}
                    disabled={editableBagList.indexOf(item.id) == -1}
                  ></TextBox>
                </Grid>
                <Grid item xs={2}>
                  <TextBox
                    name="pricePerBag"
                    textBoxId="pricePerBag"
                    placeholderText="Price Per Bag"
                    value={item.pricePerBag}
                    handleChange={(e: any) => handleChange(e, item.id)}
                    className={classes.textBoxpaddingStyle}
                    disabled={editableBagList.indexOf(item.id) == -1}
                  ></TextBox>
                </Grid>
                <Grid item xs={1} className={classes.textBoxpaddingStyle} style={{ marginTop: "auto", marginBottom: "auto" }}>
                  {editableBagList.indexOf(item.id) === -1 ? (
                    <EditIcon className={classes.iconButton} onClick={(e) => makeEditable(e, item.id, "EDIT")} />
                  ) : (
                    <LinkButton buttonLabel="Reset" disabled={false} onClick={(e) => makeEditable(e, item.id, "CANCEL")} />
                  )}
                </Grid>
              </Grid>
            ))}
        </AccordionDetails>
      </Accordion>
    );
  };

  useEffect(() => {
    const query: any = new URLSearchParams(location.search);
    let posId = query.get("posId");
    let posNo = query.get("posNo");
    if (posId && posId !== "" && posNo && posNo !== "") {
      let currentCountry: any = localStorage.getItem(CURRENT_COUNTRY);
      let countryId: any = "";
      if (currentCountry) {
        countryId = JSON.parse(currentCountry).countryId;
      }
      setEditState({ ...editState, countryId: countryId, posId: posId, posNo: posNo });
      dispatch(fetchPOSBagMapping({ posId, countryId }));
    } else {
      history.push("/single-use-plastic");
    }
  }, [location.search]);

  return (
    <div className={classes.container}>
      <Grid container justifyContent="space-between">
        <Typography className={classes.heading}>{`Configuration For Pos - ${editState.posNo}`}</Typography>
      </Grid>

      {editState.loader ? (
        <Loader />
      ) : (
        <>
          <Grid className={classes.configDetailsDiv}>{renderSingleUseBagConfiguration()}</Grid>
          <Grid className={classes.configButtonDiv}>
            <PrimaryButton
              className={classes.cancelConfigStyle}
              buttonLabel="Back to list"
              disableFocusRipple={true}
              disableRipple={true}
              onClick={() => {
                history.push("/single-use-plastic");
              }}
            />
            <PrimaryButton
              className={classes.previewAndUpdateConfigStyle}
              buttonLabel="Update"
              disableFocusRipple={true}
              disableRipple={true}
              onClick={() => handleUpdate()}
              disabled={editState.loader}
            />
          </Grid>
        </>
      )}
    </div>
  );
};

export default React.memo(EditPlasticBagConfiguration);
