import { makeStyles } from "@material-ui/core";

const styles = makeStyles({
  checkboxLabel: {
    fontSize: "12px",
    paddingLeft: "8px",
  },
});

export default styles;
