import { SERVICE_TYPES } from "../../../config/constants";

export const DASHBOARD_CONSTANT = {
  ERROR_MESSAGE: "An error occurred",
  PAGE_SIZE: 1000,
  ZONE_STATUS_ACTIVATED: "ACTIVATED",
  SLOT_UTILIZATION_DASHBOARD_SERVICE_TYPE: SERVICE_TYPES.STANDARD,
  SLOT_UTILIZATION_HEADER_TEXT: "Slot Utilization Dashboard",
  CLEAR_ALL_FILTERS: "Clear All Filters",
  COUNTRY_DATA_NOT_FOUND: "Error while fetching country data.",
  PLEASE_TRY_AGAIN: "Please try again.",
};
