import React from "react";

import { Collapse, Drawer, List, ListItem, ListItemIcon, ListItemText, SvgIcon, Typography } from "@material-ui/core";

import NavLinkDOS from "../App/NavLinkDOS";

import Phone from "../../assets/svg/Phone.svg";
import DashboardSideMenu from "../../assets/svg/SideMenu/DashboardSideMenu.svg";
import PosListSideMenu from "../../assets/svg/SideMenu/PosListSideMenu.svg";
import UserManagementSideMenu from "../../assets/svg/SideMenu/UserManagementSideMenu.svg";
import RuleEngineSideMenu from "../../assets/svg/SideMenu/RuleEngineSideMenu.svg";
import PlasticBagSideMenu from "../../assets/svg/SideMenu/PlasticBagSideMenu.svg";
import DeliveryFeeSideMenu from "../../assets/svg/SideMenu/DeliveryFeeSideMenu.svg";
import ApprovalFlowSideMenu from "../../assets/svg/SideMenu/ApprovalFlowSideMenu.svg";
import PolygonServicesSideMenu from "../../assets/svg/SideMenu/PolygonServicesSideMenu.svg";
import DownArrowIcon from "../../assets/svg/DownArrow.svg";
import IconArrowClose from "../../assets/svg/IconArrowClose.svg";

import { API_RESPONSE_STATUS, APP_ROUTES, SIDE_MENU_LABEL, USER_TYPES } from "../../../config/constants";

interface SideMenuInterface {
  openSideMenu: any;
  classes: any;
  SideBarMenuItemsList: any;
  mouseOverEventOnSideMenu: any;
  selectedIndex: any;
  handleListItemClick: any;
  pendingApprovalRequestRecordListService: any;
  userDetails: any;
  handleMultiOptionListItemClick: any;
  openRuleEngineMenu: any;
  ruleEngineItems: any;
}
const SideMenu = (props: SideMenuInterface) => {
  const {
    openSideMenu,
    classes,
    SideBarMenuItemsList,
    mouseOverEventOnSideMenu,
    selectedIndex,
    handleListItemClick,
    pendingApprovalRequestRecordListService,
    userDetails,
    handleMultiOptionListItemClick,
    openRuleEngineMenu,
    ruleEngineItems,
  } = props;

  const getSVGIconName = (pathLink: any) => {
    switch (pathLink) {
      case APP_ROUTES.DASHBOARD:
        return DashboardSideMenu;

      case APP_ROUTES.POS_LIST:
        return PosListSideMenu;

      case APP_ROUTES.APPROVAL_REQUESTS:
        return ApprovalFlowSideMenu;

      case APP_ROUTES.USER_MANAGEMENT:
        return UserManagementSideMenu;

      case APP_ROUTES.RULE_ENGINE:
        return RuleEngineSideMenu;

      case APP_ROUTES.SINGLE_USE_PLASTIC:
        return PlasticBagSideMenu;

      case APP_ROUTES.DELIVERY_FEE:
        return DeliveryFeeSideMenu;

      case APP_ROUTES.POLYGON_SERVICES:
        return PolygonServicesSideMenu;

      default:
        return Phone;
    }
  };

  const get_svg_icon_by_direction = (sideItem: any) => {
    return <img src={getSVGIconName(sideItem.to)} className={classes.listIconSVG} alt="route_icon" />;
  };

  const is_approval_request_present = () => {
    if (
      pendingApprovalRequestRecordListService !== undefined &&
      pendingApprovalRequestRecordListService.fetchStatus !== undefined &&
      pendingApprovalRequestRecordListService.fetchStatus === API_RESPONSE_STATUS.SUCCEEDED &&
      pendingApprovalRequestRecordListService.data !== undefined &&
      Array.isArray(pendingApprovalRequestRecordListService.data) &&
      pendingApprovalRequestRecordListService.data.length > 0
    ) {
      return true;
    }
    return false;
  };

  return (
    <Drawer
      open={openSideMenu}
      id="drawer-container"
      className={classes.drawer}
      variant="permanent"
      anchor="left"
      classes={{ paperAnchorLeft: classes.paperAnchorLeft, paper: openSideMenu ? classes.drawerPaperOpen : classes.drawerPaperClose }}
      onMouseOver={mouseOverEventOnSideMenu}
    >
      <List>
        {SideBarMenuItemsList !== undefined &&
          Array.isArray(SideBarMenuItemsList) &&
          SideBarMenuItemsList.length > 0 &&
          SideBarMenuItemsList.map((sideItem: any, sideItemIndex: any) =>
            sideItem !== undefined && sideItem.isSingleItem !== undefined && sideItem.isSingleItem ? (
              <ListItem
                key={`${sideItemIndex}-sideItemIndex-sideItem-${sideItem.value}`}
                button
                component={NavLinkDOS}
                to={sideItem.to}
                className={classes.listStyle}
                selected={selectedIndex === sideItemIndex}
                onClick={(event: any) => handleListItemClick(event, sideItemIndex, sideItem.to)}
                disabled={sideItem.isDisabled}
              >
                <ListItemIcon>
                  {openSideMenu ? (
                    get_svg_icon_by_direction(sideItem)
                  ) : sideItem.to === APP_ROUTES.APPROVAL_REQUESTS ? (
                    is_approval_request_present() ? (
                      <Typography className={classes.approvalCountSpanStyle}>{`${pendingApprovalRequestRecordListService.data.length}`}</Typography>
                    ) : (
                      get_svg_icon_by_direction(sideItem)
                    )
                  ) : (
                    get_svg_icon_by_direction(sideItem)
                  )}
                </ListItemIcon>
                <ListItemText primary={`${sideItem.label}`} classes={{ root: selectedIndex === sideItemIndex ? classes.listItemSelectedSpan : classes.listItemSpan }} disableTypography />
                {sideItem !== undefined && sideItem.to !== undefined && sideItem.to === APP_ROUTES.APPROVAL_REQUESTS && is_approval_request_present() && (
                  <ListItemText primary={`${pendingApprovalRequestRecordListService.data.length}`} classes={{ root: classes.approvalCountListItemSpanStyle }} disableTypography />
                )}
              </ListItem>
            ) : sideItem.value === SIDE_MENU_LABEL.RULE_ENGINE_SET_UP ? (
              <>
                <ListItem
                  key={`${sideItemIndex}-sideItemIndex-sideItem-${sideItem.value}-multi-option`}
                  button
                  className={classes.listStyle}
                  selected={selectedIndex === sideItemIndex}
                  onClick={(event: any) => handleMultiOptionListItemClick(event, sideItemIndex, SIDE_MENU_LABEL.RULE_ENGINE_SET_UP)}
                  disabled={sideItem.isDisabled}
                >
                  <ListItemIcon className="itemIcon">
                    <img src={getSVGIconName(sideItem.to)} className={classes.listIconSVG} alt="route_icon" />
                  </ListItemIcon>
                  <ListItemText primary={`${sideItem.label}`} classes={{ root: selectedIndex === sideItemIndex ? classes.listItemSelectedSpan : classes.listItemSpan }} disableTypography />
                  {openRuleEngineMenu ? <img src={IconArrowClose} alt="~^" className={classes.arrowIconStyle} /> : <img src={IconArrowClose} alt=">" />}
                </ListItem>
                <Collapse in={openRuleEngineMenu} timeout="auto" unmountOnExit key={`${sideItemIndex}-sideItemIndex-sideItem-${sideItem.value}-Collapse`}>
                  <List>
                    {ruleEngineItems !== undefined &&
                      Array.isArray(ruleEngineItems) &&
                      ruleEngineItems.length > 0 &&
                      ruleEngineItems.map((ruleEngineItem: any, ruleEngineItemIndex: any) => (
                        <ListItem
                          key={`${sideItemIndex}-ruleEngineItem-${ruleEngineItemIndex}sideItemIndex-${sideItem.value}-${ruleEngineItem.value}`}
                          button
                          component={NavLinkDOS}
                          to={ruleEngineItem.to}
                          className={classes.listStyle}
                          selected={selectedIndex === ruleEngineItem.selectedIndex}
                          onClick={(event: any) => handleListItemClick(event, ruleEngineItem.selectedIndex, ruleEngineItem.to)}
                          disabled={ruleEngineItem.isDisabled}
                        >
                          <ListItemText
                            primary={`${ruleEngineItem.label}`}
                            classes={{ root: selectedIndex === ruleEngineItem.selectedIndex ? classes.collapselistItemSelectedSpan : classes.collapselistItemSpan }}
                            disableTypography
                          />
                        </ListItem>
                      ))}
                  </List>
                </Collapse>
              </>
            ) : (
              <></>
            )
          )}
      </List>
    </Drawer>
  );
};

export default React.memo(SideMenu);
