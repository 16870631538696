import Fetch from "./Fetch";
import { handleError, handleResponse } from "./ApiUtils";

import { API_HOST_URL } from "../config/constants";

export const fetchAllCountries = () => {
  return Fetch.get(`${API_HOST_URL}/countries`).then(handleResponse).catch(handleError);
};

export const fetchAllRegions = (countryCode) => {
  return Fetch.get(`${API_HOST_URL}/${countryCode}`).then(handleResponse).catch(handleError);
};

export const fetchAllAreas = (countryCode) => {
  return Fetch.get(`${API_HOST_URL}/${countryCode}/regions/areas`).then(handleResponse).catch(handleError);
};

export const fetchServiceInActiveStatusByCountryId = (countryCode) => {
  return Fetch.get(`${API_HOST_URL}/country/service/${countryCode}`).then(handleResponse).catch(handleError);
};
