import { combineReducers } from "@reduxjs/toolkit";

import userSlice from "../../components/Login/redux/userSlice";
import appConfigSlice from "./appConfigSlice";
import configurationsSlice from "./configurationsSlice";
import posSlice from "../../components/CreatePOS/redux/posSlice";
import posListSlice from "../../components/POSList/redux/posListSlice";
import completeSetupSlice from "../../components/CompleteSetup/redux/completeSetupSlice";
import templateSlotsSlice from "../../components/ServiceAndSlots/redux/templateSlotsSlice";
import countrySettingsSlice from "../../components/CountrySettings/redux/countrySettingsSlice";
import ruleEngineSlice from "../../components/RuleEnginePage/redux/ruleEngineSlice";
import UserManagementSlice from "../../components/UserManagement/Redux/UserManagementSlice";
import singleUsePlasticSlice from "../../components/SingleUsePlastic/redux/singleUsePlasticSlice";
import DeliveryFeeSlice from "../../components/DeliveryFee/Redux/DeliveryFeeSlice";
import ApprovalRequestSlice from "../../components/ApprovalRequest/Redux/ApprovalRequestSlice";
import PlolygonServicesSlice from "../../components/PlolygonServices/Redux/PlolygonServicesSlice";
import DashboardPageSlice from "../../components/Dashboard/Redux/DashboardPageSlice";

const appReducer = combineReducers({
  user: userSlice,
  appConfig: appConfigSlice,
  configurations: configurationsSlice, // Data related to - POS types, service Types, Countries
  pos: posSlice,
  slots: templateSlotsSlice,
  posList: posListSlice,
  completeSetup: completeSetupSlice,
  countrySettings: countrySettingsSlice,
  ruleEngine: ruleEngineSlice,
  userMgmtConfigurations: UserManagementSlice,
  singleUsePlastic: singleUsePlasticSlice,
  deliveryFee: DeliveryFeeSlice,
  approvalRequestConfigurations: ApprovalRequestSlice,
  plolygonService: PlolygonServicesSlice,
  dashboardPage: DashboardPageSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "user/logout/fulfilled") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
