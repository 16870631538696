import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
//Material UI Components
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API_RESPONSE_STATUS } from "../../../config/constants";
import { selectAllAreas, selectAllRegions, selectAreas } from "../../../config/redux/configurationsSlice";
import NavigateItem from "../../assets/svg/ExpandAccordionIcon.svg";
// Svg
import searchIcon from "../../assets/svg/SearchIcon.svg";
import MultiSelectBox from "../../common/MultiSelectBox/MultiSelectBox";
//Common Components
import TextBox from "../../common/TextBox/index";
import { useStyles } from "./AreaTransferListStyle";








const operation = (list1, list2, isUnion = false) =>
  list1.filter(((set) => (a) => isUnion === set.has(a.id))(new Set(list2.map((b) => b.id))));

const inBoth = (list1, list2) => operation(list1, list2, true);

const inFirstOnly = operation;

const AreaTransferList = ({ handleAssignedAreaListChange, selectedAreas = [] }) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");

  const allAreas = useSelector(selectAllAreas);

  const { fetchStatus } = useSelector(selectAreas);

  const [selectedRegions, setSelectedRegions] = useState([]);

  const [selectedArea, setSelectedArea] = useState([]);

  const [assignedAreaSearchText, setAssignedAreaSearchText] = useState("");
  const [assignedAreaList, setAssignedAreaList] = useState(
    selectedAreas.map((sa) => ({ ...allAreas.find((aa) => aa.id === sa) }))
  );

  const allRegions = useSelector(selectAllRegions);

  const getAvailableAreas = React.useMemo(() => {
    let availableAreas = [];
    if (selectedRegions.length) {
      availableAreas = selectedRegions.reduce(
        (acc, region) => [...acc, ...allAreas.filter((area) => area.regionId === region)],
        []
      );
    }

    if (availableAreas.length && searchText) {
      let searchedValue = searchText.toLowerCase();
      availableAreas = availableAreas.filter((area) => area.name.toLowerCase().includes(searchedValue));
    }

    if (availableAreas.length && assignedAreaList.length) {
      availableAreas = inFirstOnly(availableAreas, assignedAreaList);
    }

    return availableAreas;
  }, [selectedRegions, allAreas, searchText, assignedAreaList]);

  const getAssignedAreaList = React.useMemo(() => {
    let assignedAreas = [...assignedAreaList];

    if (assignedAreaSearchText) {
      let searchedText = assignedAreaSearchText.toLowerCase();
      assignedAreas = assignedAreas.filter((area) => area.name.toLowerCase().includes(searchedText));
    }

    return assignedAreas;
  }, [assignedAreaList, assignedAreaSearchText]);

  const selectedAssignedAreaListItem = inBoth(selectedArea, assignedAreaList);
  const selectedAreaListItem = inBoth(getAvailableAreas, selectedArea);

  const handleRegionChange = (value) => {
    setSelectedRegions(value);
  };

  const handleSearchBoxTextChange = (event) => {
    let { value } = event.target;
    setSearchText(value);
  };

  const handleAssignedAreaSearchTextChane = (event) => {
    let { value } = event.target;
    setAssignedAreaSearchText(value);
  };

  const handleToggle = (value) => () => {
    const currentIndex = selectedArea.indexOf(value);
    const newChecked = [...selectedArea];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedArea(newChecked);
  };

  const moveToAssignedList = () => {
    let newAssignedAreaList = [...new Set([...selectedAreaListItem, ...assignedAreaList])];
    setAssignedAreaList(newAssignedAreaList);
    setSelectedArea(inFirstOnly(selectedArea, selectedAreaListItem));
    handleAssignedAreaListChange(newAssignedAreaList);
  };

  const moveToAreaList = () => {
    setAssignedAreaList(inFirstOnly(assignedAreaList, selectedAssignedAreaListItem));
    setSelectedArea(inFirstOnly(selectedArea, selectedAssignedAreaListItem));
    handleAssignedAreaListChange(inFirstOnly(assignedAreaList, selectedAssignedAreaListItem));
  };

  useEffect(() => {
    if (selectedAreas.length && fetchStatus === API_RESPONSE_STATUS.SUCCEEDED) {
      setSelectedRegions([
        ...new Set(selectedAreas.reduce((acc, sA) => [...acc, allAreas.filter((aA) => aA.id === sA)[0].regionId], [])),
      ]);
      setAssignedAreaList(selectedAreas.map((sa) => ({ ...allAreas.find((aa) => aa.id === sa) })));
    }

    if (selectedAreas.length === 0) {
      setSelectedRegions([]);
      setAssignedAreaList([]);
    }
  }, [allAreas, selectedAreas, fetchStatus]);

  const renderCustomList = (items) => {
    return (
      <List component="div" className={`${classes.areaList} ${items.length ? classes.areasListNotEmpty : ""}`}>
        {items.map((item) => (
          <div key={item.id}>
            <ListItem
              role="listitem"
              button
              onClick={handleToggle(item)}
              className={`${classes.areaListItems} ${selectedArea.indexOf(item) !== -1 ? classes.areaSelectedStyles : ""
                }`}
            >
              <ListItemText primary={`${item.name} - ${item.regionName}`} />
            </ListItem>
            <Divider component="li" />
          </div>
        ))}
      </List>
    );
  };

  return (
    <Accordion className={classes.accordionStyle}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.areasAccordionSummary}>
        <Grid item>
          <Typography className={classes.accordionTitle}>Areas</Typography>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.areasAccordionDetails}>
        <Grid container>
          <Grid item xs={5} className={classes.selectAreasContainer}>
            <Card className={classes.areaListCards}>
              <CardContent className={classes.cardContent}>
                <Typography
                  className={classes.areaTitles}
                >{`Select Areas (${selectedAreaListItem.length})`}</Typography>
                <Grid className={classes.root} container>
                  <Grid className={classes.searchSelect} item xs={4}>
                    <MultiSelectBox
                      className={classes.regionsSelector}
                      label="Select Region"
                      name="regions"
                      menuitems={allRegions}
                      value={selectedRegions}
                      onChange={handleRegionChange}
                    />
                  </Grid>
                  <Grid className={classes.searchTextbox} item xs={8}>
                    <TextBox
                      handleChange={handleSearchBoxTextChange}
                      value={searchText}
                      label="Search Areas"
                      type="text"
                      placeholderText="Search Areas"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton edge="end" size="small">
                            <img src={searchIcon} alt="search" />
                          </IconButton>
                        </InputAdornment>
                      }
                      required={false}
                    ></TextBox>
                  </Grid>
                </Grid>
                {getAvailableAreas.length ? renderCustomList(getAvailableAreas) : null}
              </CardContent>
            </Card>
          </Grid>
          <Grid item className={classes.moveToListButtons}>
            <img
              className={`${classes.areaListTransferButton} ${classes.moveToRightListButton}`}
              src={NavigateItem}
              alt="moveRight"
              onClick={moveToAssignedList}
              disabled={selectedAreaListItem.length === 0}
            />

            <img
              className={`${classes.areaListTransferButton} ${classes.moveToLeftListButton}`}
              src={NavigateItem}
              alt="moveLeft"
              onClick={moveToAreaList}
              disabled={selectedAssignedAreaListItem.length === 0}
            />
          </Grid>
          <Grid item className={classes.assignedAreasContainer}>
            <Card className={classes.areaListCards}>
              <CardContent>
                <Typography className={classes.areaTitles}>{`Assigned Areas (${assignedAreaList.length})`}</Typography>
                <Grid className={classes.assignedAreaSearchBar}>
                  <TextBox
                    handleChange={handleAssignedAreaSearchTextChane}
                    value={assignedAreaSearchText}
                    label="Search Areas"
                    type="text"
                    placeholderText="Search Areas"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton edge="end" size="small">
                          <img src={searchIcon} alt="search" />
                        </IconButton>
                      </InputAdornment>
                    }
                    required={false}
                  ></TextBox>
                </Grid>
                <Grid>{getAssignedAreaList.length ? renderCustomList(getAssignedAreaList) : null}</Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default AreaTransferList;
