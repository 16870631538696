import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../config/theme/theme";

export const multiSelectBoxWithLabelStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      border: "none",
      "& .MuiOutlinedInput-input": {
        fontFamily: "Montserrat",
        fontSize: "14px",
        padding: "12px",
        paddingBottom: "12px",
      },
      "& .MuiInputBase-formControl": {
        backgroundColor: COLORS.WHITE,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #DCDCDC",
        top: "0px",
      },
      "& .MuiOutlinedInput-root.Mui-disabled": {
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #E6E6E6",
          top: "0px",
          cursor: "default",
        },
      },
      "& .open": {
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #0A437C",
          borderRadius: "4px 4px 0px 0px",
          borderBottom: "none",
        },
      },
      "& .MuiFormHelperText-contained": {
        fontSize: "12px",
      },
    },
    inputStyles: {
      width: "100%",
      height: "40px",
      border: "none",
      background: "#FFFFFF",
    },
    inputlabelStyles: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "12px",
      color: COLORS.TEXT_PRIMARY,
      top: "auto",
      "&.Mui-focused": {
        color: COLORS.TEXT_PRIMARY,
        top: "0px",
        background: COLORS.WHITE,
        padding: "0px 4px",
        lineHeight: "16px",
      },
      "&.MuiInputLabel-shrink": {
        color: COLORS.TEXT_PRIMARY,
        top: "0px",
        background: COLORS.WHITE,
        padding: "0px 4px",
        lineHeight: "16px",
      },
    },
    menuPropsList: {
      "& .MuiListItem-root": {
        fontSize: 12,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        whiteSpace: "normal",
      },
      "& .MuiCheckbox-root": {
        backgroundSize: "16px 16px",
        marginRight: theme.spacing(0.75),
      },
    },
    menuPropsPaper: {
      "& .open": {
        border: "1px solid #0A437C",
      },
      maxHeight: 240,
      border: "1px solid #0A437C",
      borderRadius: theme.spacing(1),
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      boxShadow: "none",
      overflowX: "hidden",
      maxWidth: "155px",
    },
  })
);

export const anchorOriginDefault = {
  vertical: "bottom",
  horizontal: "left",
};
