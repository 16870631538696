import React from "react";

// Material UI Components
import FormControl from "@material-ui/core/FormControl";
import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

// Assets
import TimerIcon from "../../assets/svg/Timer.svg";

// Styles
import timePickerStyles from "./TimePickerStyles";

const TimePicker = ({
  fieldLabel,
  className,
  value ,
  handleChange,
  showTimeIcon = true,
  timerProps,
  ...props
}) => {
  const classes = timePickerStyles();

  const handleTimeChange = (time) => {
    let removedSecTime = time ? new Date(time.setSeconds(0, 0)) : time;
    handleChange(removedSecTime);
  };

  return (
    <>
      {fieldLabel && <div className={classes.fieldLabel}>{fieldLabel}</div>}
      <FormControl variant="filled" className={`${classes.dropdown} ${className}`}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
            {...props}
            ampm={false}
            className={`${classes.timePicker} ${!showTimeIcon ? classes.hideTimeIcon : ""} ${
              props.disabled ? classes.timePickerDisabled : ""
            }`}
            InputProps={{
              className: `${classes.timePickerInput} ${timerProps}`,
            }}
            InputLabelProps={{
              className: classes.timePickerLabelProps,
            }}
            placeholder="HH:MM"
            value={value}
            keyboardIcon={showTimeIcon ? <img className={classes.timerIcon} src={TimerIcon} alt="timer" /> : null}
            onChange={handleTimeChange}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
    </>
  );
};

export default React.memo(TimePicker);
