import React from "react";

import { Grid, Typography } from "@material-ui/core";

import SelectBox from "../../../common/SelectBox/SelectBox";
import LinkButton from "../../..//common/LinkButton/LinkButton";
import MultiSelectBox from "../../../common/MultiSelectBox/MultiSelectBox";
import SwitchButton from "../../../common/SwitchButton/SwitchButton";

import { ReactComponent as DeleteIcon } from "../../../assets/svg/DeleteIcon.svg";

import { DEFAULT_RULE_CONFIG, PromiseConfigMaxTime, calculateAvailableDaysForSelection } from "../../RuleEngineUtils";
import { ChangeConfigOtion } from "../ServiceRuleEngineUtils";
import { MAXIMUM_PROMISE_TIME_HOURS, MAXIMUM_PROMISE_TIME_MINUTES } from "../ServiceRuleEngine";
import { HEADER_NAME_CONSTANT } from "../../CurrentConfiguration/CurrentConfigConstant";

interface RenderMaximumPromiseTypeConfigurationInterface {
  classes: any;
  config: DEFAULT_RULE_CONFIG;
  onMaxTimeIsSameAsEarlierChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleMaxTimeConfigEdit: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isAddMaximumDeliveryPromiseOtherDaysButtonDisabled: () => boolean;
  onAddMaximumDeliveryPromiseOtherDays: () => void;
  onMaximumDeliveryPromiseFieldsChange: (name: string, promiseIndex: number, event: React.ChangeEvent<HTMLInputElement>) => void;
  onRemoveMaximumDeliveryPromiseOtherDays: (promiseIndex: number) => void;
  handleMaxPromiseTimeConfigEdit: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RenderMaximumPromiseTypeConfiguration = (props: RenderMaximumPromiseTypeConfigurationInterface) => {
  const {
    classes,
    config,
    onMaxTimeIsSameAsEarlierChange,
    handleMaxTimeConfigEdit,
    isAddMaximumDeliveryPromiseOtherDaysButtonDisabled,
    onAddMaximumDeliveryPromiseOtherDays,
    onMaximumDeliveryPromiseFieldsChange,
    onRemoveMaximumDeliveryPromiseOtherDays,
    handleMaxPromiseTimeConfigEdit,
  } = props;

  return (
    <Grid container className={classes.ruleContainer} spacing={0}>
      <Grid item xs={9}>
        <Grid container spacing={1}>
          <Grid item xs={5} className={classes.ruleLabel}>
            {`${HEADER_NAME_CONSTANT.MAXIMUM_PROMISE_TIME}`}
          </Grid>
          {!config.promiseConfig.maxTimeSameAsEarlier && (
            <>
              <Grid item xs={6} className={classes.dFlexEnd}>
                {`${HEADER_NAME_CONSTANT.USE_THIS_VALUE_IN_CALCULATION}`}
              </Grid>
              <Grid item xs={1}>
                <SwitchButton
                  className={config.isEditMode && config.promiseConfig.enableMaxTimeEdit ? classes.disableSwitch : ""}
                  size="small"
                  checked={config.promiseConfig.maxActive}
                  disabled={config.isEditMode && !config.promiseConfig.enableMaxTimeEdit}
                  handleChange={handleMaxPromiseTimeConfigEdit}
                  changeExisting={config.promiseConfig.enableMaxTimeEdit}
                  leftLabel={undefined}
                  rightLabel={undefined}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <ChangeConfigOtion
        config={config}
        checked={config.promiseConfig.maxTimeSameAsEarlier}
        classes={classes}
        xsValue={4}
        checkChangeHandler={onMaxTimeIsSameAsEarlierChange}
        changeExisting={config.promiseConfig.enableMaxTimeEdit}
        onConfigEdit={handleMaxTimeConfigEdit}
      />

      {config.promiseConfig.maxActive && (
        <Grid item xs={12} className={classes.ruleEditingDivStyle}>
          {config?.promiseConfig?.maximumPromiseRuleValues?.length > 0 ? (
            <Grid container>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Typography className={classes.orderVelocityRuleHeaderConfigurationStyle}> {`${HEADER_NAME_CONSTANT.DAYS}`}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.orderVelocityRuleHeaderConfigurationStyle}>{`${HEADER_NAME_CONSTANT.MAXIMUM_PROMISE_TIME_HOUR}`}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.orderVelocityRuleHeaderConfigurationStyle}>{`${HEADER_NAME_CONSTANT.MAXIMUM_PROMISE_TIME_MINUTES}`}</Typography>
                </Grid>
                <Grid item xs={3} className={classes.addOtherDays}>
                  <LinkButton buttonLabel={`${HEADER_NAME_CONSTANT.ADD_OTHER_DAYS}`} disabled={isAddMaximumDeliveryPromiseOtherDaysButtonDisabled()} onClick={onAddMaximumDeliveryPromiseOtherDays} />
                </Grid>
              </Grid>
              {config.promiseConfig.maximumPromiseRuleValues.map((promise: PromiseConfigMaxTime, promiseIndex: number) => (
                <Grid container spacing={1} key={`${promiseIndex}-maximum-promise-promise-index`} className={classes.orderVelocityRuleConfigurationStyle}>
                  <Grid item xs={3}>
                    <MultiSelectBox
                      fieldLabel=""
                      menuitems={calculateAvailableDaysForSelection(promiseIndex, config.promiseConfig.maximumPromiseRuleValues)}
                      value={promise.configuredDays}
                      onChange={(e: any) => onMaximumDeliveryPromiseFieldsChange("configuredDays", promiseIndex, e)}
                      disabled={config.isEditMode && !config.promiseConfig.enableMaxTimeEdit}
                      doNotShowAllInAllSelected={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <SelectBox
                      fieldLabel=""
                      name="maxTimeHour"
                      label="Hour"
                      value={promise.maxTimeHour}
                      handleChange={(e: any) => onMaximumDeliveryPromiseFieldsChange("maxTimeHour", promiseIndex, e)}
                      menuItems={MAXIMUM_PROMISE_TIME_HOURS}
                      disabled={config.isEditMode && !config.promiseConfig.enableMaxTimeEdit}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <SelectBox
                      fieldLabel=""
                      name="maxTimeMinutes"
                      label="Minute"
                      value={promise.maxTimeMinutes}
                      handleChange={(e: any) => onMaximumDeliveryPromiseFieldsChange("maxTimeMinutes", promiseIndex, e)}
                      menuItems={MAXIMUM_PROMISE_TIME_MINUTES}
                      disabled={config.isEditMode && !config.promiseConfig.enableMaxTimeEdit}
                    />
                  </Grid>
                  {promiseIndex !== 0 && (
                    <Grid item xs={1} className={classes.orderVelocityRuleRemoveStyle}>
                      <DeleteIcon className={classes.orderVelocityRuleStyleRemoveStyle} onClick={(e: any) => onRemoveMaximumDeliveryPromiseOtherDays(promiseIndex)} />
                    </Grid>
                  )}
                </Grid>
              ))}
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(RenderMaximumPromiseTypeConfiguration);
