import React from "react";

import { Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import { PROMISE_TYPE } from "../../RuleEngineUtils";

interface RenderTypeOfPromiseCardInterface {
  classes: any;
  config: any;
  handlePromiseTypeCheckboxChange: (typeOfPromise: string) => void;
}

const RenderTypeOfPromiseCard = (props: RenderTypeOfPromiseCardInterface) => {
  const { classes, config, handlePromiseTypeCheckboxChange } = props;

  return (
    <Grid className={classes.typeOfPromiseContainer} container spacing={0}>
      <Grid item xs={2}>
        <Typography className={classes.typeOfPromiseText}>Type of Promise</Typography>
      </Grid>
      <Grid item xs={10} className={classes.dFlexEnd}>
        <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
          <FormControlLabel
            control={<Checkbox checked={config.type === PROMISE_TYPE.STATIC} onChange={() => handlePromiseTypeCheckboxChange("STATIC")} />}
            label={<Typography className={classes.typeFormControlLabelStyle}>{`Static`}</Typography>}
          />
          <FormControlLabel
            control={<Checkbox checked={config.type === PROMISE_TYPE.DYNAMIC} onChange={() => handlePromiseTypeCheckboxChange("DYNAMIC")} />}
            label={<Typography className={classes.typeFormControlLabelStyle}>{`Dynamic`}</Typography>}
          />
          <FormControlLabel
            control={<Checkbox checked={config.type === PROMISE_TYPE.AUTOMATED} onChange={() => handlePromiseTypeCheckboxChange("AUTOMATED")} />}
            label={<Typography className={classes.typeFormControlLabelStyle}>{`Automated`}</Typography>}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(RenderTypeOfPromiseCard);
