import { API_HOST_URL, APP_USER_MGMT_URL } from "../config/constants";
import { handleError, handleResponseLogin } from "./ApiUtils";
import Fetch from "./Fetch";

const LOGIN_URL = API_HOST_URL.substring(0, API_HOST_URL.indexOf("/api/v1"));

export function login(userCredentials) {
  return Fetch.create(`${LOGIN_URL}/login`, userCredentials).then(handleResponseLogin).catch(handleError);
}

export function validateToken(requestURL) {
  return request(`${APP_USER_MGMT_URL}/auth/login`, requestURL).then(handleResponseLogin).catch(handleError);
}

async function request(url, params) {
  const options = {
    method: "GET",
    headers: {
      Authorization: params.token,
    },
  };
  const response = await fetch(url, options);
  return response;
}
