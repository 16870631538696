import React from "react";

import { TableCell, TableHead, TableRow, Typography } from "@material-ui/core";

interface PosZoneMappingDialogHeaderInterface {
  classes: any;
}

const PosZoneMappingDialogHeader = (props: PosZoneMappingDialogHeaderInterface) => {
  const { classes } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell align="left" padding="none" size="small" sortDirection={false} width="20%" className={classes.tableDataRowHeaderCell}>
          <Typography className={classes.tableDataHeaderCell}>{`POS No`}</Typography>
        </TableCell>
        <TableCell align="left" padding="none" size="small" sortDirection={false} width="35%" className={classes.tableDataRowHeaderCell}>
          <Typography className={classes.tableDataHeaderCell}>{`POS Name`}</Typography>
        </TableCell>
        <TableCell align="left" padding="none" size="small" sortDirection={false} width="20%" className={classes.tableDataRowHeaderCell}>
          <Typography className={classes.tableDataHeaderCell}>{`Zone`}</Typography>
        </TableCell>
        <TableCell align="left" padding="none" size="small" sortDirection={false} width="25%" className={classes.tableDataRowHeaderCell}>
          <Typography className={classes.tableDataHeaderCell}>{`Status`}</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default React.memo(PosZoneMappingDialogHeader);
