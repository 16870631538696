import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { userStyles } from "../SingleUsePlasticStyles";
import {
  capitalizeFirstLetter,
  designPosZoneServiceFilterListBasedOnSearchRecord,
  designPosZoneServiceListRecord,
  design_selected_pos_zone_mapping_filtered_list_based_on_search,
  generateRequestPayload,
  handleDeleteSelectedRow,
  handleHeaderInnerRowCheckboxOnchange,
  handleHeaderMainRowCheckboxOnchange,
  validateNewPosRecord,
} from "./Utils/PlasticBagCurrentCofigurationUtils";
import { POS_ZONE_MAPPING, SINGLE_USE_BAG_CONFIGURATION, TOTAL_AREAS, ASSIGNED_AREAS, ERROR_CREATE_CONFIG, NO_MESSAGE_AVAILABLE } from "./Utils/PlasticBagCurrentCofigurationConstants";

import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import TextBox from "../../common/TextBox";
import CustomPosZoneMapping from "../../common/CustomPosZoneMapping/CustomPosZoneMapping";
import CustomPosZoneAssignedList from "../../common/CustomPosZoneMapping/CustomPosZoneAssignedList";
import CustomAlert from "../../common/CustomAlert/CustomAlert";

import { createNewPlasticBagConfig, fetchPosZoneServiceTypeListByCountryId, selectAllPosZoneMapRecord } from "../redux/singleUsePlasticSlice";
import { ALERT_TYPES, APP_ROUTES, FEE_REG_EXP_VALUE, ITEMS_PER_BAG_REG_EXP } from "../../../config/constants";

const PlasticBagCurrentCofiguration = () => {
  const history = useHistory();
  const classes = userStyles();
  const dispatch = useDispatch();

  const {
    currentCountry: { countryId },
  } = useSelector((state: any) => state.appConfig);
  const poszoneMapListService = useSelector(selectAllPosZoneMapRecord);

  const [errors, setErrors] = useState({ itemsPerBag: "", pricePerBag: "" });
  const [configurationState, setConfigurationstate] = useState({ itemsPerBag: "", pricePerBag: "" });
  const [posZoneMappingState, setPosZoneMappingState] = useState({
    posZoneSearchText: "",
    assignedPosSearchText: "",
    totalAreaCount: 0,
    totalAssignedAreaCount: 0,
    isLoading: false,
    posZoneServiceRecord: [],
    posZoneServiceFilteredRecord: [],
    selectedPosServiceTypeZone: [],
    totalRowsCountData: 0,
  });

  const handleInputChange = (event: any) => {
    if (event && event.target) {
      let { name, value } = event.target;
      if (name === "itemsPerBag" && value !== "" && (isNaN(Number(value)) || !ITEMS_PER_BAG_REG_EXP.test(value) || parseInt(value) < 0)) {
        return;
      }

      if (name === "pricePerBag" && value !== "" && (isNaN(Number(value)) || !FEE_REG_EXP_VALUE.test(value))) {
        return;
      }

      setConfigurationstate({ ...configurationState, [name]: value });
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handlePosSearchTextChange = (event: any) => {
    if (event && event.target) {
      let { name, value } = event.target;
      if (name === "posZoneSearchText") {
        const { listData }: any = designPosZoneServiceFilterListBasedOnSearchRecord(posZoneMappingState.posZoneServiceRecord, value, posZoneMappingState.posZoneServiceFilteredRecord);
        setPosZoneMappingState({ ...posZoneMappingState, posZoneSearchText: value, posZoneServiceFilteredRecord: listData });
      } else {
        setPosZoneMappingState({ ...posZoneMappingState, [name]: value });
      }
    }
  };

  const updatePosNumberOpenIndex = (posListIdName: any, posIndex: any) => {
    let currentData: any = [...posZoneMappingState.posZoneServiceFilteredRecord];
    if (currentData[posIndex] && currentData[posIndex].id && currentData[posIndex].id !== "" && currentData[posIndex].id === posListIdName) {
      currentData[posIndex].isOpened = !currentData[posIndex].isOpened;
    }
    setPosZoneMappingState({ ...posZoneMappingState, posZoneServiceFilteredRecord: currentData });
  };

  const headerRowCheckboxOnchange = (event: any) => {
    if (event && event.target) {
      const { checked } = event.target;
      let totalAssignedAreaCount = 0;
      let selectedListItems = [] as any;
      let currentData: any = [...posZoneMappingState.posZoneServiceFilteredRecord];
      currentData.forEach((posItem: any) => {
        posItem.isChecked = checked;
        posItem.zoneServicetypeList.forEach((posDetails: any) => {
          posDetails.isChecked = checked;
          if (checked) {
            totalAssignedAreaCount++;
            selectedListItems.push(posDetails);
          }
        });
      });
      setPosZoneMappingState({ ...posZoneMappingState, posZoneServiceFilteredRecord: currentData, totalAssignedAreaCount: totalAssignedAreaCount, selectedPosServiceTypeZone: selectedListItems });
    }
  };

  const headerMainRowCheckboxOnchange = (event: any, posListObj: any) => {
    if (event && event.target) {
      const { checked } = event.target;
      const { currentData, totalAssignedAreaCount, selectedListItems } = handleHeaderMainRowCheckboxOnchange(checked, posListObj, posZoneMappingState);
      setPosZoneMappingState({
        ...posZoneMappingState,
        posZoneServiceFilteredRecord: currentData,
        totalAssignedAreaCount: totalAssignedAreaCount,
        selectedPosServiceTypeZone: selectedListItems,
        posZoneSearchText: "",
      });
    }
  };

  const headerInnerRowCheckboxOnchange = (event: any, posListIndex: any, posDataIndex: any) => {
    if (event && event.target) {
      const { checked } = event.target;
      const { currentData, totalAssignedAreaCount, selectedListItems } = handleHeaderInnerRowCheckboxOnchange(checked, posListIndex, posDataIndex, posZoneMappingState);
      setPosZoneMappingState({ ...posZoneMappingState, posZoneServiceFilteredRecord: currentData, totalAssignedAreaCount: totalAssignedAreaCount, selectedPosServiceTypeZone: selectedListItems });
    }
  };

  const deleteSelectedRow = (event: any, item: any, index: any) => {
    const { currentData, totalAssignedAreaCount, selectedListItems } = handleDeleteSelectedRow(item, index, posZoneMappingState);
    setPosZoneMappingState({ ...posZoneMappingState, posZoneServiceFilteredRecord: currentData, totalAssignedAreaCount: totalAssignedAreaCount, selectedPosServiceTypeZone: selectedListItems });
  };

  const configurePosZoneData = () => {
    const { totalRowsCountData, listData }: any = designPosZoneServiceListRecord(poszoneMapListService.data);
    setPosZoneMappingState({
      ...posZoneMappingState,
      isLoading: false,
      posZoneServiceRecord: listData,
      posZoneServiceFilteredRecord: listData,
      selectedPosServiceTypeZone: [],
      totalRowsCountData: totalRowsCountData,
    });
  };

  const saveNewPosConfig = async () => {
    const isValid = validateNewPosRecord(configurationState, posZoneMappingState);
    if (!isValid) {
      const requestPayload = generateRequestPayload(countryId, configurationState, posZoneMappingState);
      const { payload }: any = await dispatch(createNewPlasticBagConfig(requestPayload));
      if (payload && payload.success) {
        CustomAlert(ALERT_TYPES.SUCCESS, `Configuration added successfully`);
        history.push("/single-use-plastic");
      } else {
        let errorMessage = ERROR_CREATE_CONFIG;
        if (payload.message && payload.message !== NO_MESSAGE_AVAILABLE) {
          errorMessage = payload.message;
        }
        CustomAlert(ALERT_TYPES.ERROR, errorMessage);
      }
    }
  };

  const handleSearch = () => {
    const { listData }: any = designPosZoneServiceFilterListBasedOnSearchRecord(
      posZoneMappingState.posZoneServiceRecord,
      posZoneMappingState.posZoneSearchText,
      posZoneMappingState.posZoneServiceFilteredRecord
    );
    setPosZoneMappingState({ ...posZoneMappingState, posZoneServiceFilteredRecord: listData });
  };

  const get_assigned_pos_zone_mapping_list = () => {
    const listData: any = design_selected_pos_zone_mapping_filtered_list_based_on_search(posZoneMappingState.selectedPosServiceTypeZone, posZoneMappingState.assignedPosSearchText);
    return [...new Set(listData)];
  };

  useEffect(() => {
    poszoneMapListService && poszoneMapListService.data && configurePosZoneData();
  }, [poszoneMapListService, poszoneMapListService.data]);

  const fetchPosZoneServiceTypeRecord = useCallback(async () => {
    setPosZoneMappingState({ ...posZoneMappingState, isLoading: true, posZoneServiceRecord: [], posZoneServiceFilteredRecord: [], selectedPosServiceTypeZone: [], totalRowsCountData: 0 });
    dispatch(
      fetchPosZoneServiceTypeListByCountryId({
        countryId: countryId,
        pageSize: 1000,
        pageNumber: 0,
        query: "",
      })
    );
  }, [dispatch, countryId]);

  useEffect(() => {
    countryId && countryId !== "" && fetchPosZoneServiceTypeRecord();
  }, [countryId, fetchPosZoneServiceTypeRecord]);

  const renderSingleUseBagConfiguration = () => {
    return (
      <Accordion defaultExpanded={true} className={classes.accordionStyle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryStyle}>
          <Grid container alignItems="center">
            <Grid>
              <Typography className={classes.accordionTitle}>{capitalizeFirstLetter(SINGLE_USE_BAG_CONFIGURATION)}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetailsStyle}>
          <Grid className={classes.bagConfigTextBoxDiv}>
            <TextBox
              name="itemsPerBag"
              type="number"
              textBoxId="itemsPerBag"
              placeholderText="Number of Items Per Bag"
              value={configurationState.itemsPerBag}
              handleChange={handleInputChange}
              className={classes.rightPaddingStyle}
            ></TextBox>
            <TextBox
              name="pricePerBag"
              type="number"
              textBoxId="pricePerBag"
              placeholderText="Price Per Bag"
              value={configurationState.pricePerBag}
              handleChange={handleInputChange}
              className={classes.leftPaddingStyle}
            ></TextBox>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderPOSZoneMapping = () => {
    return (
      <Accordion defaultExpanded={true} className={classes.accordionStyle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryStyle}>
          <Grid container alignItems="center">
            <Grid>
              <Typography className={classes.accordionTitle}>{capitalizeFirstLetter(POS_ZONE_MAPPING)}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetailsStyle}>
          <Grid className={classes.posZoneConfigDiv}>
            <CustomPosZoneMapping
              title={TOTAL_AREAS}
              titleCount={posZoneMappingState.totalRowsCountData}
              searchTextValue={posZoneMappingState.posZoneSearchText}
              searhTextName="posZoneSearchText"
              handlePosSearchTextChange={handlePosSearchTextChange}
              fetchingList={posZoneMappingState.isLoading}
              posZoneServiceDataList={posZoneMappingState.posZoneServiceFilteredRecord}
              selectedPosServices={posZoneMappingState.selectedPosServiceTypeZone}
              updatePosNumberOpenIndex={updatePosNumberOpenIndex}
              headerRowCheckboxOnchange={headerRowCheckboxOnchange}
              headerMainRowCheckboxOnchange={headerMainRowCheckboxOnchange}
              headerInnerRowCheckboxOnchange={headerInnerRowCheckboxOnchange}
              totalRowsCountData={posZoneMappingState.totalRowsCountData}
              handleSearch={handleSearch}
            />
            <CustomPosZoneAssignedList
              title={ASSIGNED_AREAS}
              titleCount={posZoneMappingState.totalAssignedAreaCount}
              searchTextValue={posZoneMappingState.assignedPosSearchText}
              searhTextName="assignedPosSearchText"
              handlePosSearchTextChange={handlePosSearchTextChange}
              fetchingList={posZoneMappingState.isLoading}
              selectedPosServices={get_assigned_pos_zone_mapping_list()}
              deleteSelectedRow={deleteSelectedRow}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <div className={classes.container}>
      <Grid container justifyContent="space-between">
        <Typography className={classes.heading}>Create New Configuration</Typography>
      </Grid>

      <Grid className={classes.configDetailsDiv}>
        <>
          {renderSingleUseBagConfiguration()}
          {renderPOSZoneMapping()}
        </>
      </Grid>

      <Grid className={classes.configButtonDiv}>
        <Link to={{ pathname: `${APP_ROUTES.SINGLE_USE_PLASTIC}` }} style={{ textDecoration: "none" }}>
          <PrimaryButton className={classes.cancelConfigStyle} buttonLabel="Cancel" disableFocusRipple={true} disableRipple={true} />
        </Link>
        <PrimaryButton className={classes.previewAndUpdateConfigStyle} buttonLabel="Save" disableFocusRipple={true} disableRipple={true} onClick={saveNewPosConfig} />
      </Grid>
    </div>
  );
};

export default React.memo(PlasticBagCurrentCofiguration);
