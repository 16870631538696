import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { makeStyles } from "@material-ui/core";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import Separator from "../../assets/svg/BreadcrumbsSeparator.svg";

import breadcrumbStyles from "./CustomBreadcrumbsStyles";

const separatorStyles = makeStyles({
  root: {
    margin: "0px 5px",
    height: "15px",
  },
});

const BreadcrumbsSeparator = () => {
  const classes = separatorStyles();
  return <img className={classes.root} alt="Separator" src={Separator} />;
};

const CustomBreadcrumbs = ({ links = [], ...props }) => {
  const classes = breadcrumbStyles();
  return (
    <Breadcrumbs {...props} className={classes.root} separator={<BreadcrumbsSeparator />} aria-label="breadcrumb">
      {links.map((link) => {
        if (!link.isCurrentRoute) {
          return (
            <Link
              component={RouterLink}
              className={classes.linkStyle}
              key={`${link.name} - ${link.to}`}
              color={"inherit"}
              to={link.to}
            >
              {link.name}
            </Link>
          );
        } else {
          return (
            <Typography key={`${link.name} - ${link.to}`} className={classes.currentLinkStyle}>
              {link.name}
            </Typography>
          );
        }
      })}
    </Breadcrumbs>
  );
};

export default React.memo(CustomBreadcrumbs);
