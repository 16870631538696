import React from "react";
import { useSelector } from "react-redux";

import { Route, Switch, useRouteMatch } from "react-router-dom";

import NewStandardRequest from "../../ServiceAndSlots/Standard/NewApprovalRequest/NewStandardRequest";
import NewExpressRequest from "../../ServiceAndSlots/Express/NewApprovalRequest/NewExpressRequest";

import { selectServiceTypes } from "../../../config/redux/configurationsSlice";
import { APP_ROUTES, EXPRESS_TYPES, SERVICE_TYPES, STATUS_OK } from "../../../config/constants";

import { getServiceTypeDetailsFromServiceName } from "../../../utils/helperFunctions";
import { selectCountrySettings } from "../../CountrySettings/redux/countrySettingsSlice";

const NewRequestForServiceAndSlot = () => {
  const { path } = useRouteMatch();
  const serviceTypes = useSelector(selectServiceTypes);
  const { countryDetails } = useSelector(selectCountrySettings);

  const getExpressType = React.useMemo(() => {
    let expressType = EXPRESS_TYPES.ON_DEMAND.value;

    if (countryDetails && countryDetails.status === STATUS_OK && serviceTypes.length) {
      let {
        data: { serviceTypes: countrySettingServices },
      } = countryDetails;

      let expressId = getServiceTypeDetailsFromServiceName(serviceTypes, SERVICE_TYPES.EXPRESS).id;

      expressType = countrySettingServices.find((s: any) => s.serviceTypeID === expressId).expressType;
    }

    return expressType;
  }, [countryDetails, serviceTypes]);

  return (
    <Switch>
      <Route exact path={`${path}${APP_ROUTES.STANDARD}/:posNo/:zoneId?`}>
        <NewStandardRequest serviceTypeId={getServiceTypeDetailsFromServiceName(serviceTypes, SERVICE_TYPES.STANDARD).id} serviceTypeName={SERVICE_TYPES.STANDARD.toLowerCase()} />
      </Route>
      <Route exact path={`${path}${APP_ROUTES.EXPRESS}/:posNo/:zoneId?`}>
        <NewExpressRequest
          serviceTypeId={getServiceTypeDetailsFromServiceName(serviceTypes, SERVICE_TYPES.EXPRESS).id}
          serviceTypeName={SERVICE_TYPES.EXPRESS.toLowerCase()}
          expressType={getExpressType}
        />
      </Route>
    </Switch>
  );
};

export default React.memo(NewRequestForServiceAndSlot);
