import React, { useEffect, useRef, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

import produce from "immer";
import lodashGet from "lodash.get";
import moment from "moment";

import standardServiceStyles from "./StandardStyles";

import { Card, CardContent, Grid, Typography } from "@material-ui/core";

import { fetchPOSDetailsByPOSNo } from "../../CreatePOS/redux/posSlice";
import { fetchPendingApprovalRequest, selectAllPendingApprovalRequestRecordListServices } from "../../ApprovalRequest/Redux/ApprovalRequestSlice";
import { ALERT_TYPES, APP_ROUTES, BUTTON_TEXT_CONSTANT, HH_MM_SS, PHARMACY, TEXT_CONSTANT } from "../../../config/constants";
import { selectServiceTypes } from "../../../config/redux/configurationsSlice";
import { calculateAvailableDaysForSelection, createNextZoneName, createServiceConfigPayload, createServiceConfigPayloadForStandard, generateSlots } from "../utils/serviceUtils";
import { handleFoodSubType, handleProductType } from "../utils/SlotServicesUtils";
import { validateStandardZoneConfig } from "../utils/StandardUtils";
import { check_if_slot_obj_contains_sub_set } from "../../../utils/ApplicationUtils";

import ConfirmModal from "../../../components/common/ConfirmModal/ConfirmModal";
import CustomAlert from "../../../components/common/CustomAlert/CustomAlert";
import OverlayLoader from "../../common/Loader/OverlayLoader";
import PageTitle from "../../common/PageTitle/PageTitle";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import RemoveButton from "../../common/RemoveButton/RemoveButton";
import BasicServiceDetails from "../common/BasicServiceDetails/BasicServiceDetails";
import CreateTemplateSlotsForm from "../common/Slots/CreateTemplateSlots";
import EditTemplateSlots from "../common/Slots/EditTemplateSlots";
import PreviewModal from "../PreviewModal/PreviewModal";
import BulkUpdateSummaryDetailsAndConfirmationPopUp from "./Common/BulkUpdateSummaryDetailsAndConfirmationPopUp";
import RenderZoneConfigurationsCard from "../common/RenderZoneConfigurationsCard";
import PreviewApprovalRequestPopUp from "../../ApprovalRequest/common/PreviewApprovalRequestPopUp";
import RenderConfigureSlotsAccordion from "./Common/RenderConfigureSlotsAccordion";
import RenderConfigureSecondarySlotMapping from "../common/RenderConfigureSecondarySlotMapping";
import ShowDynamicRoutingMappingPopUp from "./Common/ShowDynamicRoutingMappingPopUp";

import { is_user_creating_approval_request, is_user_creating_updating_pos, is_product_type_visible, is_zone_add_allowed, is_zone_delete_allowed } from "./StandardAccessUtils";
import { selectUserDetails } from "../../Login/redux/userSlice";
import { StandardServiceInitialState } from "./StandardInterface";
import {
  createNewTemplateSlot,
  createTemplateSlotsForStandard,
  deleteATemplateSlot,
  deleteZoneConfig,
  fetchSlotsInformationByServiceType,
  fetchSlotsInformationForStandard,
  resetSlotsInformation,
  sendConfigurationForApproval,
  updateDynamicRoutingForStandard,
  updateTemplateSlotBySlotId,
  updateTemplateSlots,
  updateTemplateSlotsForStandard,
} from "../redux/templateSlotsSlice";
import {
  _handle_clear_bulk_update_confimation_pop_up_utils,
  _handle_close_bulk_update_confimation_pop_up_utils,
  _handle_day_delected_for_bulk_update_utils,
  _handle_open_bulk_update_confimation_pop_up_utils,
} from "../BulkUpdateCommon/BulkUpdateCommon";
import {
  _handle_date_change_event_util,
  _validate_send_for_approval_form,
  add_new_template_slot_util,
  cancel_editing_a_slot_util,
  createDynamicRoutingPayload,
  delete_template_slot_util,
  designCustomPosZoneMappingFilteredListBasedOnSearch,
  designPosZoneServiceListRecordUtils,
  edit_slot_time_util,
  getAssignedPosZoneMappingListUtils,
  handleAddSecondaryPosUtils,
  handleDeleteSelectedRowUtils,
  handleEmptyPosZoneMappingDataAndLoading,
  handle_individual_slot_checkbox_change_util,
  handle_save_configuration_handler,
  headerInnerRowCheckboxOnchangeUtils,
  headerMainRowCheckboxOnchangeUtils,
  headerRowCheckboxOnchangeUtils,
  is_request_already_presnt_by_admin_util,
  removeDynamicRoutingRowUtils,
  reset_bulk_update_config_day,
  reset_bulk_update_configuration_for_given_day,
  updatePosNumberOpenIndexUtils,
  updatePosZoneDetailsAndShowPopUpUtils,
  update_handle_individual_slot_field_change,
  update_make_slot_editable,
  validate_generator_form_before_bulk_update,
} from "./StandardUtils";
import {
  DEFAULT_CONFIG_TAB_CONFIGURATIONS,
  DEFAULT_SLOTS_GENERATOR_FORMS_VALUES,
  DEFAULT_TAB_BUFFER_CAPACITY_CONFIGURATIONS,
  ALL_DAYS_VALUES,
  STANDARD_SERVICE_CONSTANT,
} from "./StandardServiceConstant";
import { isEmptyOrNull, isUndefined } from "../../../utils/DataUtils";
import { fetchPosZoneServiceTypeListByCountryIdAndServiceType } from "components/DeliveryFee/Redux/DeliveryFeeSlice";

const StandardService = ({ serviceTypeId, serviceTypeName, isPOSMappingAreaBased }) => {
  const { posNo } = useParams();

  const classes = standardServiceStyles();
  const history = useHistory();

  const slotsGeneratorFormRef = React.useRef(null);
  const dragItem = useRef();

  const dispatch = useDispatch();

  const userInfo = useSelector(selectUserDetails);
  const { posDetails, loading: fetchingDetails } = useSelector((state) => state.pos);
  const { fetchingSlots, standard, isDeleting } = useSelector((state) => state.slots);
  const pendingApprovalRequestRecordListService = useSelector(selectAllPendingApprovalRequestRecordListServices);
  const serviceTypes = useSelector(selectServiceTypes);
  const {
    currentCountry: { countryId },
  } = useSelector((state) => state.appConfig);

  // New state from here
  const isPosTypePharmacy = lodashGet(posDetails, "posType", "") === PHARMACY;
  const [standardServiceState, setStandardServiceState] = useState(StandardServiceInitialState);
  const [configTabs, setConfigTabs] = useState([
    { ...DEFAULT_CONFIG_TAB_CONFIGURATIONS, zoneName: "Zone01", slotsGeneratorForms: [{ ...DEFAULT_SLOTS_GENERATOR_FORMS_VALUES, days: ALL_DAYS_VALUES }] },
  ]);
  const [errors, setErrors] = useState([{ foodTypes: "", selectedAreas: "" }]);

  const savedConfigurationsHandler = (slots) => {
    let new_config_tabs = handle_save_configuration_handler(slots, posNo);
    setConfigTabs(new_config_tabs);
    setStandardServiceState({ ...standardServiceState, loading: false, configurationBulkUpdatedObj: { isConfigurationBulkUpdated: false, bulkUpdatedDays: [] }, originalConfigTab: new_config_tabs });
  };

  const fetchSlots = () => {
    if (posNo !== undefined && posNo !== "" && serviceTypeId !== undefined && serviceTypeId !== "" && serviceTypeName !== undefined && serviceTypeName !== "") {
      dispatch(fetchSlotsInformationForStandard({ posNo, serviceType: serviceTypeId, serviceTypeName }));
      setStandardServiceState({ ...standardServiceState, loading: false });
    }
  };

  const openSwitchZonePopUpWarningPopUp = (tabIndex) => {
    setStandardServiceState({ ...standardServiceState, loading: true, showZoneChangeWarningPopUp: true, temporarySelectedZone: tabIndex });
  };

  const closeSwitchZonePopUpWarningPopUp = () => {
    setStandardServiceState({ ...standardServiceState, loading: false, showZoneChangeWarningPopUp: false, temporarySelectedZone: "" });
  };

  const call_send_for_approval = async (payload_Object) => {
    const { payload } = await dispatch(sendConfigurationForApproval(payload_Object));
    if (payload && payload.response !== undefined && payload.response.data === "success") {
      CustomAlert(ALERT_TYPES.SUCCESS, `Configuration sent for approval successfully`);
      dispatch(fetchPendingApprovalRequest({ countryId: countryId, status: ["PENDING"] }));
      history.push(APP_ROUTES.POS_LIST);
    } else {
      setStandardServiceState({ ...standardServiceState, loading: false });
      let errorMessage = "Error Occurred";
      if (payload.message && payload.message !== "No message available") {
        errorMessage = payload.message;
      }
      CustomAlert(ALERT_TYPES.ERROR, errorMessage);
    }
  };

  const updateSelectedTabIndex = (tabIndex) => {
    setStandardServiceState({
      ...standardServiceState,
      loading: true,
      selectedConfigTab: tabIndex,
      activationDate: "",
      activationDatelabel: "",
      isDateValid: false,
      data_present_obj: {},
      showZoneChangeWarningPopUp: false,
      temporarySelectedZone: "",
    });
  };

  const handleTabChange = (e, tabIndex) => {
    if (is_user_creating_approval_request(userInfo)) {
      if (standardServiceState !== undefined && standardServiceState.activationDate !== undefined && standardServiceState.activationDate !== "") {
        const pay_load_object = _validate_send_for_approval_form(standardServiceState, configTabs, countryId, false, "");
        if (pay_load_object && typeof pay_load_object === "object" && Object.keys(pay_load_object).length !== 0) {
          openSwitchZonePopUpWarningPopUp(tabIndex);
          return;
        }
      }
    }
    updateSelectedTabIndex(tabIndex);
  };

  const addConfigTabs = () => {
    let newConfigTab = { ...DEFAULT_CONFIG_TAB_CONFIGURATIONS, ...DEFAULT_TAB_BUFFER_CAPACITY_CONFIGURATIONS, zoneName: createNextZoneName(configTabs) };
    let newTabs = [...configTabs, newConfigTab];
    let newError = { foodTypes: "" };
    let newErrors = produce(errors, (draft) => {
      draft.push(newError);
    });
    setConfigTabs(newTabs);
    setErrors(newErrors);
    setStandardServiceState({ ...standardServiceState, selectedConfigTab: configTabs.length });
  };

  const handleDeleteZoneConfigClick = (tabIndexToBeDeleted, e) => {
    e.stopPropagation();
    setStandardServiceState({ ...standardServiceState, selectedConfigTab: tabIndexToBeDeleted, showDeleteZCModal: true });
  };

  const resetConfigTab = (tabIndexToBeReset) => {
    let defaultConfig = { ...DEFAULT_CONFIG_TAB_CONFIGURATIONS };
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndexToBeReset] = { ...defaultConfig, zoneName: `Zone01` };
    });
    setConfigTabs(newTabs);
    setStandardServiceState({ ...standardServiceState, showDeleteZCModal: false, loading: false });
  };

  const removeZoneTabAfterDeletion = (tabIndexToBeDeleted) => {
    if (configTabs.length > 1) {
      let newTabs = produce(configTabs, (draft) => {
        draft.splice(tabIndexToBeDeleted, 1);
      });
      setStandardServiceState({ ...standardServiceState, selectedConfigTab: configTabs.length - 2, showDeleteZCModal: false, loading: false });
      setConfigTabs(newTabs);
      let newErrors = produce(errors, (draft) => {
        draft.splice(tabIndexToBeDeleted, 1);
      });
      setErrors(newErrors);
    } else {
      resetConfigTab(tabIndexToBeDeleted);
    }
  };

  const deleteConfigTab = async (tabIndexToBeDeleted) => {
    let zoneId = configTabs[tabIndexToBeDeleted].zoneId;
    if (!zoneId) {
      removeZoneTabAfterDeletion(tabIndexToBeDeleted);
    } else {
      setStandardServiceState({ ...standardServiceState, loading: true });
      const {
        payload: { data, message: deleteError },
      } = await dispatch(
        deleteZoneConfig({
          serviceTypeName,
          zoneConfigId: configTabs[tabIndexToBeDeleted].zoneId,
          posNo: posNo,
        })
      );

      if (data === true || (data !== undefined && data.success !== undefined && data.success === true)) {
        CustomAlert(ALERT_TYPES.SUCCESS, "Zone configuration deleted successfully!");
        removeZoneTabAfterDeletion(tabIndexToBeDeleted);
        if (posNo !== undefined && posNo !== "" && serviceTypeId !== undefined && serviceTypeId !== "" && serviceTypeName !== undefined && serviceTypeName !== "") {
          dispatch(fetchSlotsInformationByServiceType({ posNo, serviceType: serviceTypeId, serviceTypeName }));
        }
      } else {
        if (deleteError) {
          CustomAlert(ALERT_TYPES.ERROR, deleteError);
        } else {
          CustomAlert(ALERT_TYPES.ERROR, "Error occured");
        }
        setStandardServiceState({ ...standardServiceState, loading: false, showDeleteZCModal: false });
      }
    }
  };

  const handleDaysChange = (tabIndex, generatorFormIndex, value) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].days = value;
    });
    setConfigTabs(newTabs);
  };

  const handleSlotsGeneratorFormFields = (tabIndex, generatorFormIndex, event) => {
    const { name, value } = event.target;
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex][name] = value;
    });
    setConfigTabs(newTabs);
  };

  const handleServiceTimeChange = (tabIndex, generatorFormIndex, time, element) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex][element] = time;
    });
    setConfigTabs(newTabs);
  };

  const handleModifyQuotaSettings = (tabIndex, generatorFormIndex, quotas) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].quotas = quotas;
      if (draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots.length) {
        draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots.forEach((slot) => (slot.isQuotaEnabled = true));
      }
    });
    setConfigTabs(newTabs);
  };

  const handleSwitchButton = (tabIndex, event) => {
    const { name, checked } = event.target;
    const newTabs = produce(configTabs, (draft) => {
      if (name !== "capacityOptimizationFlag") {
        draft[tabIndex][name] = checked;
      } else {
        draft[tabIndex].bufferCapacityConfig.active = checked;
      }
      if (!checked) {
        switch (name) {
          case "showFirstSlotAtOption":
            draft[tabIndex].showFirstSlotAt = null;
            break;
          case "showServiceCutOffTimeOption":
            draft[tabIndex].serviceCutOffTime = null;
            break;
          case "slotOptimizationEnabled":
            draft[tabIndex].capacityThreshold = "";
            draft[tabIndex].timeThreshold = "";
            break;
          default:
            break;
        }
      }
    });
    setConfigTabs(newTabs);
  };

  const handleServiceCutOffAndShowSlotAtTime = (tabIndex, time, element) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex][element] = time;
    });
    setConfigTabs(newTabs);
  };

  const handleSlotOptimizationFields = (tabIndex, e) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex][e.target.name] = e.target.value;
    });
    setConfigTabs(newTabs);
  };

  /**
   *
   * Handling of edit flow - Start
   *
   *  */

  const addNewSlot = (tabIndex, generatorFormIndex, day) => {
    let new_config_tabs = add_new_template_slot_util(configTabs, tabIndex, generatorFormIndex, day, posDetails);
    setConfigTabs(new_config_tabs);
  };

  const handleDeleteTemplateSlot = async (tabIndex, generatorFormIndex, slotIndex, slot) => {
    if (is_user_creating_approval_request(userInfo)) {
      let new_config_tabs = delete_template_slot_util(configTabs, tabIndex, generatorFormIndex, slotIndex);
      setConfigTabs(new_config_tabs);
    } else {
      if (!slot || !slot.id) {
        let newTabs = produce(configTabs, (draft) => {
          let allSlots = draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots;
          let filteredSlots = allSlots.filter((item) => !!item.id);
          draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots = [...filteredSlots];
        });
        setConfigTabs(newTabs);
        return;
      } else {
        setStandardServiceState({ ...standardServiceState, loading: true });
        const payload = await dispatch(deleteATemplateSlot({ serviceTypeName, slotData: slot }));
        let deletedSlot = unwrapResult(payload);
        if (deletedSlot && deletedSlot.data && deletedSlot.data.id) {
          fetchSlots();
        } else {
          setStandardServiceState({ ...standardServiceState, loading: false });
        }
      }
    }
  };

  const makeSlotEditable = (tabIndex, generatorFormIndex, index, slot) => {
    if (!slot || !slot.id) return;
    if (tabIndex !== undefined && tabIndex >= 0 && generatorFormIndex !== undefined && generatorFormIndex >= 0 && index !== undefined && index >= 0) {
      let new_config_tabs = update_make_slot_editable(configTabs, tabIndex, generatorFormIndex, index);
      setConfigTabs(new_config_tabs);
    }
  };

  const handleIndividualSlotFieldsChange = (tabIndex, generatorFormIndex, index, event) => {
    if (event !== undefined && event.target !== undefined) {
      const { name, value } = event.target;
      let new_config_tabs = update_handle_individual_slot_field_change(configTabs, tabIndex, generatorFormIndex, index, name, value);
      setConfigTabs(new_config_tabs);
    }
  };

  const handleIndividualSlotCheckbox = (tabIndex, generatorFormIndex, index, status) => {
    let newTabs = handle_individual_slot_checkbox_change_util(configTabs, tabIndex, generatorFormIndex, index, status);
    setConfigTabs(newTabs);
  };

  const cancelEditingASlot = (tabIndex, generatorFormIndex, index, slot) => {
    if (!slot || !slot.id) return;

    let newTabs = cancel_editing_a_slot_util(configTabs, tabIndex, generatorFormIndex, index, slot, standard);
    setConfigTabs(newTabs);
  };

  const editSlotTime = (tabIndex, generatorFormIndex, index, time, elem) => {
    let newTabs = edit_slot_time_util(configTabs, tabIndex, generatorFormIndex, index, time, elem);
    setConfigTabs(newTabs);
  };

  const updateProductTypeConfigTabs = (configTabs, configTabIndex, e, checked) => {
    setConfigTabs(handleProductType(configTabs, configTabIndex, e, checked));
  };

  const updateFoodSubTypeConfigTabs = (configTabs, configTabIndex, e) => {
    setConfigTabs(handleFoodSubType(configTabs, configTabIndex, e));
  };

  const handleSaveNewTemplateSlot = async (tabIndex, generatorFormIndex, index, slot) => {
    let newTemplateSlotBody = {
      posId: slot.posId,
      zoneId: slot.zoneId,
      day: slot.day,
      startTime: moment(slot.startTime).format(HH_MM_SS),
      endTime: moment(slot.endTime).format(HH_MM_SS),
      bufferTime: parseInt(slot.bufferTimeHours * 60 + slot.bufferTimeMinutes),
      slotCapacity: parseInt(slot.slotCapacity),
      serviceTypeId,
      isQuotaEnabled: slot.isQuotaEnabled,
    };

    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index].isCreating = true;
    });
    setConfigTabs(newTabs);
    setStandardServiceState({ ...standardServiceState, loading: true });

    const { payload } = await dispatch(createNewTemplateSlot({ serviceTypeName, slotData: newTemplateSlotBody }));
    if (payload && payload.data) {
      fetchSlots();
    }
    if (payload && payload.message) {
      newTabs = produce(configTabs, (draft) => {
        draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index] = {
          ...draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index],
          isCreating: false,
          isCreated: false,
        };
      });
      setConfigTabs(newTabs);
      setStandardServiceState({ ...standardServiceState, loading: false });
      CustomAlert(ALERT_TYPES.ERROR, payload.message);
    }
  };

  const updateTemplateSlotHandler = async (tabIndex, generatorFormIndex, index, slot) => {
    setStandardServiceState({ ...standardServiceState, loading: true });
    let requestBody = {
      id: slot.id,
      posId: slot.posId,
      zoneId: slot.zoneId,
      day: slot.day,
      startTime: moment(slot.startTime).format(HH_MM_SS),
      endTime: moment(slot.endTime).format(HH_MM_SS),
      slotCapacity: slot.slotCapacity,
      bufferTime: slot.bufferTimeHours * 60 + slot.bufferTimeMinutes,
      serviceTypeId,
      isQuotaEnabled: slot.isQuotaEnabled,
      createdAt: slot.createdAt || "",
    };
    const { payload } = await dispatch(updateTemplateSlotBySlotId({ serviceTypeName, slotData: requestBody }));
    if (payload && payload.message) {
      setStandardServiceState({ ...standardServiceState, loading: false });
      CustomAlert(ALERT_TYPES.ERROR, payload.message);
    } else if (payload && payload.data && payload.data.id) {
      fetchSlots();
    }
  };

  const handleAssignedAreas = (tabIndex, selectedAreaList) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].selectedAreas = selectedAreaList.map((area) => area.id);
    });
    setConfigTabs(newTabs);
  };

  const isAddOtherDaysButtonDisabled = (tabIndex) => {
    let isButtonDisabled = false;
    let daysSelectedSoFar = configTabs[tabIndex].slotsGeneratorForms.reduce((days, value) => [...days, ...value.days], []);
    let remainingDays = ALL_DAYS_VALUES.filter((day) => !daysSelectedSoFar.includes(day));
    if (remainingDays.length === 0) isButtonDisabled = true;
    return isButtonDisabled;
  };

  const executeScroll = (ref) => ref.current.scrollIntoView({ behavior: "smooth" });

  const addOtherDays = (tabIndex, e) => {
    e.stopPropagation();
    let newForm = { ...DEFAULT_SLOTS_GENERATOR_FORMS_VALUES };
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms.push(newForm);
    });
    setConfigTabs(newTabs);
    setTimeout(() => {
      executeScroll(slotsGeneratorFormRef);
    }, 100);
  };

  const deleteConfigureSlotsOption = (tabIndex, slotGenertorFormIndex) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms.splice(slotGenertorFormIndex, 1);
    });
    setConfigTabs(newTabs);
  };

  const getGeneratedSlots = (tabIndex, slotsGeneratorFormIndex) => {
    const slotsGeneratorFormFields = configTabs[tabIndex].slotsGeneratorForms[slotsGeneratorFormIndex];
    const calculatedSlots = generateSlots({ ...slotsGeneratorFormFields });
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[slotsGeneratorFormIndex].generatedSlots = [...calculatedSlots];
    });
    setConfigTabs(newTabs);
  };

  const resetSlotsGeneratorForm = (tabIndex, formIndex) => {
    const newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[formIndex] = { ...DEFAULT_SLOTS_GENERATOR_FORMS_VALUES };
    });
    setConfigTabs(newTabs);
  };

  const resetSlots = (tabIndex, generatorFormIndex) => {
    resetSlotsGeneratorForm(tabIndex, generatorFormIndex);
  };

  const saveZoneConfigurations = async (configurations) => {
    setStandardServiceState({ ...standardServiceState, loading: true });
    const { payload } = await dispatch(
      createTemplateSlotsForStandard({
        posNo,
        zoneConfigurations: configurations,
        service: serviceTypeName,
        zoneConfigIndex: standardServiceState.selectedConfigTab,
      })
    );

    if (payload && payload.message) {
      setStandardServiceState({ ...standardServiceState, loading: false });
      CustomAlert(ALERT_TYPES.ERROR, payload.message);
    } else if (payload && payload.zone && payload.zone.zoneConfig && payload.zone.zoneConfig.id) {
      fetchSlots();
      CustomAlert(ALERT_TYPES.SUCCESS, "Zone Configurations saved successfully");
    }
  };

  const updateZoneConfigurations = async (configs) => {
    const requestObject = {
      posNo,
      zoneConfigurations: configs,
      service: serviceTypeName,
      zoneConfigIndex: standardServiceState.selectedConfigTab,
    };
    if (
      standardServiceState !== undefined &&
      standardServiceState.configurationBulkUpdatedObj !== undefined &&
      standardServiceState.configurationBulkUpdatedObj.bulkUpdatedDays !== undefined &&
      Array.isArray(standardServiceState.configurationBulkUpdatedObj.bulkUpdatedDays) &&
      standardServiceState.configurationBulkUpdatedObj.bulkUpdatedDays.length > 0
    ) {
      requestObject.zoneConfigurations = {
        ...requestObject.zoneConfigurations,
        updatedDays: [...standardServiceState.configurationBulkUpdatedObj.bulkUpdatedDays],
      };
    }
    setStandardServiceState({ ...standardServiceState, loading: true, configurationBulkUpdatedObj: { isConfigurationBulkUpdated: false, bulkUpdatedDays: [] } });
    const { payload } = await dispatch(updateTemplateSlotsForStandard(requestObject));

    if (payload && payload.message) {
      setStandardServiceState({ ...standardServiceState, loading: false });
      CustomAlert(ALERT_TYPES.ERROR, payload.message);
    } else if (payload && payload.zone && payload.zone.zoneConfig && payload.zone.zoneConfig.id) {
      CustomAlert(ALERT_TYPES.SUCCESS, "Zone Configurations updated successfully");
      fetchSlots();
    }
  };

  const showDynamicRoutingError = (payload) => {
    let errorMessage = STANDARD_SERVICE_CONSTANT.DYNAMIC_ROUTING_ERROR;
    if (!isEmptyOrNull(payload.message)) {
      errorMessage = payload.message;
    }
    CustomAlert(ALERT_TYPES.ERROR, errorMessage);
  };

  const saveUpdateZoneConfigurations = (tab, isUpdate) => {
    let configurationsPayload = createServiceConfigPayloadForStandard(tab, serviceTypeId, posDetails);
    if (isUpdate) {
      updateZoneConfigurations(configurationsPayload);
    } else {
      saveZoneConfigurations(configurationsPayload);
    }
  };

  const updateDynamicRoutingForPos = async (tab, isUpdate) => {
    const dynamicRoutingPayload = createDynamicRoutingPayload(tab, posNo);
    const { payload } = await dispatch(updateDynamicRoutingForStandard(dynamicRoutingPayload));
    if (!isEmptyOrNull(payload?.posNo)) {
      CustomAlert(ALERT_TYPES.SUCCESS, STANDARD_SERVICE_CONSTANT.DYNAMIC_ROUTING_SUCCESS);
    } else {
      showDynamicRoutingError(payload);
    }
    saveUpdateZoneConfigurations(tab, isUpdate);
  };

  const handleSaveOrUpdateZoneConfigurationsSubmit = (configTabIndex = standardServiceState.selectedConfigTab, isUpdate = false) => {
    const { isError, currentTabErrors } = validateStandardZoneConfig(configTabs, standardServiceState.selectedConfigTab, isPOSMappingAreaBased, errors);
    setErrors(currentTabErrors);
    if (!isError) {
      let tab = configTabs[configTabIndex];
      setStandardServiceState({ ...standardServiceState, loading: true });
      if (tab.isDynamicRoutingConfigChanges) {
        updateDynamicRoutingForPos(tab, isUpdate);
      } else {
        saveUpdateZoneConfigurations(tab, isUpdate);
      }
    }
  };

  // Bulk Update
  //
  //
  const handleBulkUpdateAction = (configTabIndex, generatorFormIndex) => {
    if (configTabs !== undefined && Array.isArray(configTabs) && configTabs.length > 0) {
      if (configTabIndex !== undefined && configTabIndex >= 0 && configTabs[configTabIndex] !== undefined && configTabs[configTabIndex].slotsGeneratorForms !== undefined) {
        const is_all_slots_valid = validate_generator_form_before_bulk_update(configTabs[configTabIndex]);
        if (is_all_slots_valid !== undefined && is_all_slots_valid === true) {
          const { slotsGeneratorForms } = configTabs[configTabIndex];
          if (
            slotsGeneratorForms !== undefined &&
            Array.isArray(slotsGeneratorForms) &&
            slotsGeneratorForms.length > 0 &&
            generatorFormIndex !== undefined &&
            generatorFormIndex !== "" &&
            generatorFormIndex >= 0 &&
            slotsGeneratorForms[generatorFormIndex] !== undefined &&
            slotsGeneratorForms[generatorFormIndex].generatedSlots !== undefined
          ) {
            const is_slot_obj_valid = check_if_slot_obj_contains_sub_set(slotsGeneratorForms[generatorFormIndex].generatedSlots);
            if (is_slot_obj_valid !== undefined && typeof is_slot_obj_valid === "object" && Object.keys(is_slot_obj_valid).length > 0) {
              CustomAlert(ALERT_TYPES.ERROR, is_slot_obj_valid[Object.keys(is_slot_obj_valid)[0]]);
              return;
            } else {
              let allDays = [];
              const currentDay = slotsGeneratorForms[generatorFormIndex]?.days[0];
              slotsGeneratorForms.forEach((slot) => {
                if (slot !== undefined && slot.days !== undefined && Array.isArray(slot.days) && slot.days.length > 0) {
                  if (slot.days[0] !== undefined && slot.days[0] !== "" && slot.days[0] !== currentDay) {
                    allDays = [...allDays, ...slot.days];
                  }
                }
              });
              let newStandardServiceState = _handle_open_bulk_update_confimation_pop_up_utils(standardServiceState, slotsGeneratorForms, configTabIndex, generatorFormIndex, currentDay, allDays);
              setStandardServiceState(newStandardServiceState);
            }
          }
        }
      }
    }
  };

  const handleCloseBulkUpdateConfimationPopUp = () => {
    let newStandardServiceState = _handle_close_bulk_update_confimation_pop_up_utils(standardServiceState);
    setStandardServiceState(newStandardServiceState);
  };

  const handleDaySelectedForBulkUpdate = (dayValue) => {
    if (dayValue !== undefined && dayValue !== "") {
      let newStandardServiceState = _handle_day_delected_for_bulk_update_utils(standardServiceState, standardServiceState.selctedDays, standardServiceState.configuredDays, dayValue);
      setStandardServiceState(newStandardServiceState);
    }
  };

  const handleBulkUpdateConfirmAction = () => {
    if (standardServiceState !== undefined && standardServiceState.selctedDays !== undefined && Array.isArray(standardServiceState.selctedDays) && standardServiceState.selctedDays.length > 0) {
      let newStandardServiceState = _handle_clear_bulk_update_confimation_pop_up_utils(standardServiceState, standardServiceState.selctedDays);
      if (configTabs !== undefined && Array.isArray(configTabs) && configTabs.length > 0) {
        if (
          standardServiceState.configTabIndex !== undefined &&
          standardServiceState.configTabIndex >= 0 &&
          configTabs[standardServiceState.configTabIndex] !== undefined &&
          configTabs[standardServiceState.configTabIndex].slotsGeneratorForms !== undefined
        ) {
          const { slotsGeneratorForms } = configTabs[standardServiceState.configTabIndex];
          if (
            slotsGeneratorForms !== undefined &&
            Array.isArray(slotsGeneratorForms) &&
            slotsGeneratorForms.length > 0 &&
            standardServiceState.generatorFormIndex !== undefined &&
            standardServiceState.generatorFormIndex >= 0 &&
            slotsGeneratorForms[standardServiceState.generatorFormIndex] !== undefined
          ) {
            const slotForm = JSON.parse(JSON.stringify(standardServiceState.currentBulkUpdateConfigurationSummary));
            if (slotForm !== undefined && slotForm.generatedSlots !== undefined && Array.isArray(slotForm.generatedSlots) && slotForm.generatedSlots.length > 0) {
              let newTabs = produce(configTabs, (draft) => {
                draft[standardServiceState.configTabIndex].slotsGeneratorForms.forEach((slot, slotIndex) => {
                  if (
                    slot !== undefined &&
                    slot.days !== undefined &&
                    Array.isArray(slot.days) &&
                    slot.days.length > 0 &&
                    slot.days[0] !== undefined &&
                    slot.days[0] !== "" &&
                    standardServiceState.currentDay !== undefined &&
                    standardServiceState.currentDay !== "" &&
                    slot.days[0] !== standardServiceState.currentDay &&
                    standardServiceState.selctedDays.indexOf(slot.days[0]) > -1
                  ) {
                    let slot_form_copy = JSON.parse(JSON.stringify(slotForm));
                    slot_form_copy.generatedSlots.forEach((data) => {
                      data.day = slot.days[0];
                      data.id = "";
                      data.slotAction = "ADD";
                    });
                    draft[standardServiceState.configTabIndex].slotsGeneratorForms[slotIndex].generatedSlots = [];
                    draft[standardServiceState.configTabIndex].slotsGeneratorForms[slotIndex].generatedSlots = [...slot_form_copy.generatedSlots];
                  }
                });
              });
              setConfigTabs(newTabs);
              setStandardServiceState(newStandardServiceState);
            }
          }
        }
      }
    } else {
      CustomAlert(ALERT_TYPES.ERROR, TEXT_CONSTANT.BULK_UPDATE_NO_DAYS_SELECTED);
    }
  };

  const resetCurrentConfigurationAfterBulkUpdateRevert = (configTabIndex, generatorFormIndex, day) => {
    if (day !== undefined && day !== "" && configTabIndex !== "" && configTabIndex >= 0 && generatorFormIndex !== undefined && generatorFormIndex >= 0) {
      let new_config_tab = reset_bulk_update_configuration_for_given_day(configTabs, configTabIndex, generatorFormIndex, day, standardServiceState);
      setConfigTabs(new_config_tab);
      let newStandardServiceState = reset_bulk_update_config_day(standardServiceState, day);
      setStandardServiceState(newStandardServiceState);
    }
  };

  // Approval Request
  //
  //
  const handleDateChangeEvent = (event, picker) => {
    setStandardServiceState({ ...standardServiceState, loading: true });
    let newStandardServiceState = _handle_date_change_event_util(standardServiceState, event, picker, pendingApprovalRequestRecordListService, posNo, configTabs, serviceTypeName);
    setStandardServiceState(newStandardServiceState);
  };

  const handleCancel = (event, picker) => {
    event.preventDefault();
    picker.element.val("");
    setStandardServiceState({ ...standardServiceState, activationDate: "", activationDatelabel: "", isDateValid: false, data_present_obj: {} });
  };

  const send_request_for_approval = (requesterComments) => {
    setStandardServiceState({ ...standardServiceState, loading: true, showApprovalRequestPopUpPreview: false });
    if (standardServiceState !== undefined && standardServiceState.activationDate !== undefined && standardServiceState.activationDate !== "") {
      const pay_load_object = _validate_send_for_approval_form(standardServiceState, configTabs, countryId, true, requesterComments);
      if (pay_load_object && typeof pay_load_object === "object" && Object.keys(pay_load_object).length !== 0) {
        call_send_for_approval(pay_load_object);
      } else {
        setStandardServiceState({ ...standardServiceState, loading: false });
      }
    } else {
      CustomAlert(ALERT_TYPES.ERROR, "Please provide implementation date.");
      setStandardServiceState({ ...standardServiceState, loading: false });
    }
  };

  const preview_request_for_approval = () => {
    if (standardServiceState !== undefined && standardServiceState.activationDate !== undefined && standardServiceState.activationDate !== "") {
      const pay_load_object = _validate_send_for_approval_form(standardServiceState, configTabs, countryId, true, "");
      if (pay_load_object && typeof pay_load_object === "object" && Object.keys(pay_load_object).length !== 0) {
        setStandardServiceState({ ...standardServiceState, loading: true, showApprovalRequestPopUpPreview: true });
      }
    } else {
      CustomAlert(ALERT_TYPES.ERROR, "Please provide implementation date.");
      setStandardServiceState({ ...standardServiceState, loading: false });
    }
  };

  const close_preview_request_for_approval = () => {
    setStandardServiceState({ ...standardServiceState, loading: false, showApprovalRequestPopUpPreview: false });
  };

  const handleRequesterCommentChange = (event) => {
    if (event !== undefined && event.target !== undefined) {
      const { name, value } = event.target;
      setStandardServiceState({ ...standardServiceState, requesterComments: value });
    }
  };

  const handleCapacityOptimizationInputsChange = (tabIndex, e) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].bufferCapacityConfig[e.target.name] = e.target.value;
    });
    setConfigTabs(newTabs);
  };

  const renderSlotsGeneratorForm = (configTab, configTabIndex, generatorFormIndex) => {
    const isEditMode = !!configTab.zoneId;
    const { days, slotDurationHours, slotDurationMinutes, bufferTimeHours, bufferTimeMinutes, serviceStartTime, serviceEndTime, defaultSlotCapacity, generatedSlots, quotas } =
      configTabs[configTabIndex].slotsGeneratorForms[generatorFormIndex];

    let remainingDays = calculateAvailableDaysForSelection(generatorFormIndex, configTab);
    const dayWiseSlots = isEditMode ? standard && standard.length && standard[configTabIndex] && standard[configTabIndex].dayWiseSlots && standard[configTabIndex].dayWiseSlots[days[0]] : null;

    return (
      <div className={classes.slotsGeneratorFormContainer}>
        {generatorFormIndex !== 0 && !isEditMode ? <RemoveButton className={classes.deleteSlotsGeneratorForm} onClick={() => deleteConfigureSlotsOption(configTabIndex, generatorFormIndex)} /> : null}
        {isEditMode ? (
          <EditTemplateSlots
            serviceTypeName={serviceTypeName}
            days={days}
            generatedSlots={generatedSlots}
            dayWiseSlots={dayWiseSlots}
            quotas={quotas}
            handleIndividualSlotCheckbox={(slotIndex, e) => handleIndividualSlotCheckbox(configTabIndex, generatorFormIndex, slotIndex, e.target.checked)}
            handleIndividualSlotFieldsChange={(slotIndex, e) => handleIndividualSlotFieldsChange(configTabIndex, generatorFormIndex, slotIndex, e)}
            handleIndividualSlotTimeChange={(slotIndex, date, elem) => editSlotTime(configTabIndex, generatorFormIndex, slotIndex, date, elem)}
            handleModifyQuotaSettings={(quotas) => handleModifyQuotaSettings(configTabIndex, generatorFormIndex, quotas)}
            makeSlotEditable={(slotIndex, slot) => makeSlotEditable(configTabIndex, generatorFormIndex, slotIndex, slot)}
            cancelEditingASlot={(slotIndex, slot) => cancelEditingASlot(configTabIndex, generatorFormIndex, slotIndex, slot)}
            updateTemplateSlot={(slotIndex, slot) => updateTemplateSlotHandler(configTabIndex, generatorFormIndex, slotIndex, slot)}
            deleteTemplateSlot={(slotIndex, slot) => handleDeleteTemplateSlot(configTabIndex, generatorFormIndex, slotIndex, slot)}
            addNewTemplateSlot={() => addNewSlot(configTabIndex, generatorFormIndex, days)}
            saveNewTemplateSlot={(slotIndex, slot) => handleSaveNewTemplateSlot(configTabIndex, generatorFormIndex, slotIndex, slot)}
            handleBulkUpdateAction={() => handleBulkUpdateAction(configTabIndex, generatorFormIndex)}
            configurationBulkUpdatedObj={standardServiceState.configurationBulkUpdatedObj}
            resetCurrentConfigurationAfterBulkUpdateRevert={(day) => resetCurrentConfigurationAfterBulkUpdateRevert(configTabIndex, generatorFormIndex, day)}
            userInfo={userInfo}
          />
        ) : (
          <CreateTemplateSlotsForm
            days={days}
            serviceTypeName={serviceTypeName}
            remainingDays={remainingDays}
            slotDurationHours={slotDurationHours}
            slotDurationMinutes={slotDurationMinutes}
            bufferTimeHours={bufferTimeHours}
            bufferTimeMinutes={bufferTimeMinutes}
            serviceStartTime={serviceStartTime}
            serviceEndTime={serviceEndTime}
            defaultSlotCapacity={defaultSlotCapacity}
            generatedSlots={generatedSlots}
            quotas={quotas}
            handleDaysChange={(e) => handleDaysChange(configTabIndex, generatorFormIndex, e)}
            handleSlotsGeneratorFormFields={(e) => handleSlotsGeneratorFormFields(configTabIndex, generatorFormIndex, e)}
            handleServiceTimeChange={(date, elem) => handleServiceTimeChange(configTabIndex, generatorFormIndex, date, elem)}
            handleGenerateSlots={() => getGeneratedSlots(configTabIndex, generatorFormIndex)}
            handleResetSlots={() => resetSlots(configTabIndex, generatorFormIndex)}
            handleModifyQuotaSettings={(quotas) => handleModifyQuotaSettings(configTabIndex, generatorFormIndex, quotas)}
            handleIndividualSlotFieldsChange={(slotIndex, e) => handleIndividualSlotFieldsChange(configTabIndex, generatorFormIndex, slotIndex, e)}
            handleIndividualSlotCheckbox={(slotIndex, e) => handleIndividualSlotCheckbox(configTabIndex, generatorFormIndex, slotIndex, e.target.checked)}
          />
        )}
      </div>
    );
  };

  const renderConfigureSlotsAccordion = (configTab, configTabIndex) => {
    return (
      <>
        {!is_user_creating_approval_request(userInfo) && renderConfigureSecondarySlotMapping(configTab, configTabIndex)}
        <RenderConfigureSlotsAccordion
          classes={classes}
          userInfo={userInfo}
          configTab={configTab}
          configTabIndex={configTabIndex}
          handleSwitchButton={handleSwitchButton}
          handleServiceCutOffAndShowSlotAtTime={handleServiceCutOffAndShowSlotAtTime}
          handleSlotOptimizationFields={handleSlotOptimizationFields}
          isAddOtherDaysButtonDisabled={isAddOtherDaysButtonDisabled}
          addOtherDays={addOtherDays}
          renderSlotsGeneratorForm={renderSlotsGeneratorForm}
          handleCapacityOptimizationInputsChange={handleCapacityOptimizationInputsChange}
        />
      </>
    );
  };

  const updatePosZoneDetailsAndShowPopUp = (posZoneMapListData, configTabIndex) => {
    const { totalRowsCountData, listData } = designPosZoneServiceListRecordUtils(posZoneMapListData, configTabIndex, configTabs);
    let newStandardServiceState = updatePosZoneDetailsAndShowPopUpUtils(standardServiceState, totalRowsCountData, listData, configTabIndex, configTabs);
    setStandardServiceState(newStandardServiceState);
  };

  const fetchPosZoneServiceTypeRecord = async (configTabIndex) => {
    const { pageSize, pageNumber, STANDARD } = STANDARD_SERVICE_CONSTANT;
    const payloadRequest = { countryId: countryId || "", pageSize: pageSize, pageNumber: pageNumber, serviceType: STANDARD };
    const response = await dispatch(fetchPosZoneServiceTypeListByCountryIdAndServiceType(payloadRequest));
    if (response?.payload?.length > 0) {
      updatePosZoneDetailsAndShowPopUp(response?.payload, configTabIndex);
    } else {
      setStandardServiceState({ ...standardServiceState, loading: false, showDynamicRoutingPopUp: false, configTabIndex: null });
    }
  };

  const handleAddSecondaryPosZoneClick = (configTabIndex) => {
    let newConfigTabs = produce(configTabs, (draft) => {
      draft[configTabIndex].isDynamicRoutingConfigChanges = true;
    });
    setConfigTabs(newConfigTabs);
    let newStandardServiceState = handleEmptyPosZoneMappingDataAndLoading(standardServiceState, true, configTabIndex);
    setStandardServiceState(newStandardServiceState);
    fetchPosZoneServiceTypeRecord(configTabIndex);
  };

  const handleCloseDynamicRoutingPopUp = () => {
    setStandardServiceState({ ...standardServiceState, loading: false, showDynamicRoutingPopUp: false, configTabIndex: null, posZoneSearchText: "", assignedPosSearchText: "" });
  };

  const handlePosSearchTextChange = (event) => {
    if (event?.target?.name && !isUndefined(event.target?.value)) {
      const { name, value } = event?.target;
      let newStandardServiceState = designCustomPosZoneMappingFilteredListBasedOnSearch(standardServiceState, name, value);
      setStandardServiceState(newStandardServiceState);
    }
  };

  const updatePosNumberOpenIndex = (posListIdName, posIndex) => {
    let newStandardServiceState = updatePosNumberOpenIndexUtils(standardServiceState, posListIdName, posIndex);
    setStandardServiceState(newStandardServiceState);
  };

  const headerRowCheckboxOnchange = (event) => {
    let newStandardServiceState = headerRowCheckboxOnchangeUtils(standardServiceState, event);
    setStandardServiceState(newStandardServiceState);
  };

  const headerMainRowCheckboxOnchange = (event, posListObj) => {
    let newStandardServiceState = headerMainRowCheckboxOnchangeUtils(standardServiceState, event, posListObj, configTabs, standardServiceState.configTabIndex);
    setStandardServiceState(newStandardServiceState);
  };

  const headerInnerRowCheckboxOnchange = (event, posListIndex, posDataIndex) => {
    let newStandardServiceState = headerInnerRowCheckboxOnchangeUtils(standardServiceState, event, posListIndex, posDataIndex);
    setStandardServiceState(newStandardServiceState);
  };

  const searchCustomPosZoneMappingList = () => {
    let newStandardServiceState = designCustomPosZoneMappingFilteredListBasedOnSearch(standardServiceState, STANDARD_SERVICE_CONSTANT.POS_ZONE_SEARCH_TEXT, standardServiceState.posZoneSearchText);
    setStandardServiceState(newStandardServiceState);
  };

  const getAssignedPosZoneMappingList = () => {
    let assigned_list_data = getAssignedPosZoneMappingListUtils(standardServiceState);
    return [...new Set(assigned_list_data)];
  };

  const handleDeleteSelectedRow = (event, item, index) => {
    let newStandardServiceState = handleDeleteSelectedRowUtils(standardServiceState, item, index);
    setStandardServiceState(newStandardServiceState);
  };

  const handleAddSecondaryPos = () => {
    if (standardServiceState?.selectedPosServiceTypeZone?.length > 0) {
      let newConfigTabs = handleAddSecondaryPosUtils(configTabs, standardServiceState.configTabIndex, standardServiceState?.selectedPosServiceTypeZone);
      setConfigTabs(newConfigTabs);
      setStandardServiceState({ ...standardServiceState, loading: false, showDynamicRoutingPopUp: false, configTabIndex: null });
    } else {
      CustomAlert(ALERT_TYPES.ERROR, STANDARD_SERVICE_CONSTANT.SECONDARY_POS_NOT_FOUND);
    }
  };

  const handleDynamicRoutingActionPopUpClose = () => {
    setStandardServiceState({
      ...standardServiceState,
      dragRemoveConfigTabIndex: null,
      dragTargetItem: null,
      dragItemIndex: null,
      removeTargetItemIndex: null,
      showRemovePopUp: false,
      showDragPopUp: false,
    });
  };

  const removeDynamicRoutingRowProceed = () => {
    let newConfigTabs = removeDynamicRoutingRowUtils(configTabs, standardServiceState.dragRemoveConfigTabIndex, standardServiceState.removeTargetItemIndex);
    setConfigTabs(newConfigTabs);
    handleDynamicRoutingActionPopUpClose();
  };

  const getPOSZoneNameForRemove = () => {
    let returnString = STANDARD_SERVICE_CONSTANT.POP_UP_RETURN_STRING;
    if (
      standardServiceState.dragRemoveConfigTabIndex >= 0 &&
      standardServiceState.removeTargetItemIndex >= 0 &&
      !isUndefined(configTabs[standardServiceState.dragRemoveConfigTabIndex]) &&
      !isUndefined(configTabs[standardServiceState.dragRemoveConfigTabIndex]?.dynamicRoutingConfig?.secondaryStores?.length > 0)
    ) {
      const { posNo, zone } = configTabs[standardServiceState.dragRemoveConfigTabIndex]?.dynamicRoutingConfig?.secondaryStores[standardServiceState.removeTargetItemIndex];
      returnString = `${posNo}/${zone}`;
    }
    return returnString;
  };

  const removeDynamicRoutingRow = (configTabIndex, elementIndex) => {
    let newConfigTabs = produce(configTabs, (draft) => {
      draft[configTabIndex].isDynamicRoutingConfigChanges = true;
    });
    setConfigTabs(newConfigTabs);
    setStandardServiceState({
      ...standardServiceState,
      dragRemoveConfigTabIndex: configTabIndex,
      dragTargetItem: null,
      dragItemIndex: null,
      removeTargetItemIndex: elementIndex,
      showRemovePopUp: true,
      showDragPopUp: false,
    });
  };

  const handleOnDragStart = (event, element) => {
    event.dataTransfer.setData("text", `${element?.zoneId}`);
    dragItem.current = element;
  };

  const handleOnDragEnd = (event, element) => {
    event.dataTransfer.clearData();
    dragItem.current = null;
  };

  const handleOnDragOver = (event) => {
    event.preventDefault();
  };

  const getDragDropTitleMessage = () => {
    let returnString = STANDARD_SERVICE_CONSTANT.CHANGE_PRIORITY;
    if (standardServiceState.dragItemIndex >= 0 && standardServiceState.removeTargetItemIndex >= 0) {
      returnString = STANDARD_SERVICE_CONSTANT.INCREASE_PRIORITY;
      if (standardServiceState.removeTargetItemIndex > standardServiceState.dragItemIndex) {
        returnString = STANDARD_SERVICE_CONSTANT.DECREASE_PRIORITY;
      }
    }
    return returnString;
  };

  const getDragDropDescriptionMessage = () => {
    let returnString = STANDARD_SERVICE_CONSTANT.CHANGE_PRIORITY_OF;
    if (standardServiceState.dragItemIndex >= 0 && standardServiceState.removeTargetItemIndex >= 0) {
      returnString = STANDARD_SERVICE_CONSTANT.INCREASE_PRIORITY_OF;
      if (standardServiceState.removeTargetItemIndex > standardServiceState.dragItemIndex) {
        returnString = STANDARD_SERVICE_CONSTANT.DECREASE_PRIORITY_OF;
      }
      if (
        standardServiceState.dragRemoveConfigTabIndex >= 0 &&
        standardServiceState.dragItemIndex >= 0 &&
        !isUndefined(configTabs[standardServiceState.dragRemoveConfigTabIndex]) &&
        !isUndefined(configTabs[standardServiceState.dragRemoveConfigTabIndex]?.dynamicRoutingConfig?.secondaryStores?.length > 0)
      ) {
        const { posNo, zone } = configTabs[standardServiceState.dragRemoveConfigTabIndex]?.dynamicRoutingConfig?.secondaryStores[standardServiceState.dragItemIndex];
        returnString = `${returnString} ${posNo}/${zone}`;
      }
    }
    return returnString;
  };

  const handleOnDragDropProceed = () => {
    let newConfigTabs = produce(configTabs, (draft) => {
      draft[standardServiceState.dragRemoveConfigTabIndex]?.dynamicRoutingConfig?.secondaryStores?.splice(standardServiceState.dragItemIndex, 1);
      draft[standardServiceState.dragRemoveConfigTabIndex]?.dynamicRoutingConfig?.secondaryStores?.splice(standardServiceState.removeTargetItemIndex, 0, standardServiceState.dragTargetItem);
    });
    setConfigTabs(newConfigTabs);
    handleDynamicRoutingActionPopUpClose();
  };

  const handleOnDragDrop = (event, targetItem, configTabIndex) => {
    event.preventDefault();
    const copyDynamicRoutingConfigSecondaryStores = [...configTabs[configTabIndex].dynamicRoutingConfig?.secondaryStores];
    const dragItemIndex = copyDynamicRoutingConfigSecondaryStores?.findIndex((element) => element.posNo === dragItem.current.posNo && element.zone === dragItem.current.zone);
    const dragOverItemIndex = copyDynamicRoutingConfigSecondaryStores?.findIndex((element) => element.posNo === targetItem.posNo && element.zone === targetItem.zone);
    if (dragItemIndex !== -1 && dragOverItemIndex !== -1 && dragOverItemIndex !== dragItemIndex) {
      let newConfigTabs = produce(configTabs, (draft) => {
        draft[configTabIndex].isDynamicRoutingConfigChanges = true;
      });
      setConfigTabs(newConfigTabs);
      setStandardServiceState({
        ...standardServiceState,
        dragRemoveConfigTabIndex: configTabIndex,
        dragTargetItem: dragItem.current,
        dragItemIndex: dragItemIndex,
        removeTargetItemIndex: dragOverItemIndex,
        showRemovePopUp: false,
        showDragPopUp: true,
      });
    }
  };

  const renderConfigureSecondarySlotMapping = (configTab, configTabIndex) => {
    return (
      <>
        <RenderConfigureSecondarySlotMapping
          classes={classes}
          configTab={configTab}
          configTabIndex={configTabIndex}
          dragItem={dragItem?.current}
          handleAddSecondaryPosZoneClick={handleAddSecondaryPosZoneClick}
          removeDynamicRoutingRow={removeDynamicRoutingRow}
          handleOnDragStart={handleOnDragStart}
          handleOnDragEnd={handleOnDragEnd}
          handleOnDragOver={handleOnDragOver}
          handleOnDragDrop={handleOnDragDrop}
        />
      </>
    );
  };

  useEffect(() => {
    setStandardServiceState({
      ...standardServiceState,
      request_already_presnt_by_admin_error: is_request_already_presnt_by_admin_util(configTabs, standardServiceState, pendingApprovalRequestRecordListService, posDetails),
      loading: false,
    });
  }, [standardServiceState.selectedConfigTab, pendingApprovalRequestRecordListService, pendingApprovalRequestRecordListService.fetchStatus]);

  useEffect(() => {
    if (posNo && !posDetails.posNo && serviceTypes.length) {
      dispatch(fetchPOSDetailsByPOSNo(posNo));
    }
  }, [dispatch, posNo, serviceTypeId, serviceTypeName, posDetails.posNo, serviceTypes.length]);

  useEffect(() => {
    if (posNo && serviceTypeId) {
      fetchSlots();
    }
  }, [dispatch, posNo, serviceTypeId, serviceTypeName]);

  useEffect(() => {
    if (standard && standard.length && !standardServiceState.loading) {
      savedConfigurationsHandler(standard);
      dispatch(fetchPendingApprovalRequest({ countryId: countryId, status: ["PENDING"] }));
    }
  }, [standard]);

  useEffect(() => {
    return () => {
      dispatch(resetSlotsInformation());
    };
  }, [dispatch]);

  return (
    <>
      <OverlayLoader loading={standardServiceState.loading || fetchingDetails || fetchingSlots} />
      <PageTitle title={`POS - ${posNo}`} />

      {is_user_creating_updating_pos(userInfo) && (
        <>
          <BasicServiceDetails
            serviceTypeId={serviceTypeId}
            serviceTypeName={serviceTypeName}
            country={posDetails && posDetails.posAddress && posDetails.posAddress.country}
            region={posDetails && posDetails.posAddress && posDetails.posAddress.region}
          />
          {standardServiceState !== undefined && standardServiceState.request_already_presnt_by_admin_error !== undefined && standardServiceState.request_already_presnt_by_admin_error !== "" && (
            <Card className={classes.errorCardStyle}>
              <CardContent>
                <Typography className={classes.errorTextSpanStyle}>{`${standardServiceState.request_already_presnt_by_admin_error}`}</Typography>
              </CardContent>
            </Card>
          )}
        </>
      )}

      <RenderZoneConfigurationsCard
        classes={classes}
        serviceTypeName={serviceTypeName}
        userInfo={userInfo}
        service={standard}
        serviceState={standardServiceState}
        handleTabChange={handleTabChange}
        configTabs={configTabs}
        handleDeleteZoneConfigClick={handleDeleteZoneConfigClick}
        addConfigTabs={addConfigTabs}
        isPosTypePharmacy={isPosTypePharmacy}
        errors={errors}
        updateProductTypeConfigTabs={updateProductTypeConfigTabs}
        updateFoodSubTypeConfigTabs={updateFoodSubTypeConfigTabs}
        handleDateChangeEvent={handleDateChangeEvent}
        handleCancel={handleCancel}
        handleSaveOrUpdateZoneConfigurationsSubmit={handleSaveOrUpdateZoneConfigurationsSubmit}
        preview_request_for_approval={preview_request_for_approval}
        // access
        is_product_type_visible={is_product_type_visible}
        is_user_creating_approval_request={is_user_creating_approval_request}
        is_zone_add_allowed={is_zone_add_allowed}
        is_zone_delete_allowed={is_zone_delete_allowed}
        // component
        renderConfigureSlotsAccordion={renderConfigureSlotsAccordion}
        handleSwitchButton={handleSwitchButton}
        handleServiceCutOffAndShowSlotAtTime={handleServiceCutOffAndShowSlotAtTime}
        handleCapacityOptimizationInputsChange={handleCapacityOptimizationInputsChange}
      />

      <Grid container direction="row" className={classes.footerButtons}>
        <Grid item xs={6}>
          <Grid container spacing={1} justifyContent="flex-start">
            <Grid item className={classes.footerButtonsStyle}>
              <Link to={`${APP_ROUTES.POS_LIST}`} className={classes.linkstyle}>
                <PrimaryButton buttonLabel={`${BUTTON_TEXT_CONSTANT.BACK_TO_POS_LIST}`} buttonVariant="outlined" disabled={false} />
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item className={classes.footerButtonsStyle}>
              <PrimaryButton
                buttonLabel={`${BUTTON_TEXT_CONSTANT.BACK_TO_POS}`}
                buttonVariant="outlined"
                disabled={!standard.length}
                onClick={() => history.push(`${APP_ROUTES.COMPLETE_SETUP}/${posDetails.posNo}`)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <>
        {standardServiceState !== undefined && standardServiceState.showPreviewModal !== undefined && standardServiceState.showPreviewModal && (
          <PreviewModal show={standardServiceState.showPreviewModal} onClose={() => setStandardServiceState({ ...standardServiceState, showPreviewModal: false })} posDetails={posDetails} />
        )}
        {standardServiceState !== undefined && standardServiceState.showDeleteZCModal !== undefined && standardServiceState.showDeleteZCModal && (
          <ConfirmModal
            show={standardServiceState.showDeleteZCModal}
            title="Delete Zone Config"
            description="All the data for the zone configuration would be permanently lost. Do you wish to proceed?"
            confirmButtonText="Approve"
            isLoading={isDeleting}
            onClose={() => setStandardServiceState({ ...standardServiceState, showDeleteZCModal: false })}
            onProceed={() => deleteConfigTab(standardServiceState.selectedConfigTab)}
          />
        )}
        {standardServiceState !== undefined && standardServiceState.openBulkUpdateConfirmationPopUp !== undefined && standardServiceState.openBulkUpdateConfirmationPopUp && (
          <BulkUpdateSummaryDetailsAndConfirmationPopUp
            popUpOpenFlag={standardServiceState.openBulkUpdateConfirmationPopUp}
            currentConfiguration={standardServiceState.currentBulkUpdateConfigurationSummary}
            configuredDays={standardServiceState.configuredDays}
            selctedDays={standardServiceState.selctedDays}
            currentDay={standardServiceState.currentDay}
            handleClose={handleCloseBulkUpdateConfimationPopUp}
            handleDaySelectedForBulkUpdate={handleDaySelectedForBulkUpdate}
            handleBulkUpdateConfirmAction={handleBulkUpdateConfirmAction}
          />
        )}
        {standardServiceState !== undefined && standardServiceState.showZoneChangeWarningPopUp !== undefined && standardServiceState.showZoneChangeWarningPopUp && (
          <ConfirmModal
            show={standardServiceState.showZoneChangeWarningPopUp}
            title="Change Zone"
            description="All the data for the zone configuration would be permanently lost. Do you wish to proceed?"
            confirmButtonText="Change"
            isLoading={false}
            onClose={() => closeSwitchZonePopUpWarningPopUp()}
            onProceed={() => updateSelectedTabIndex(standardServiceState.temporarySelectedZone)}
          />
        )}
        {standardServiceState !== undefined && standardServiceState.showApprovalRequestPopUpPreview !== undefined && standardServiceState.showApprovalRequestPopUpPreview && (
          <PreviewApprovalRequestPopUp
            popUpOpenFlag={standardServiceState.showApprovalRequestPopUpPreview}
            handleClose={close_preview_request_for_approval}
            originalConfigTab={standardServiceState.originalConfigTab}
            selectedConfigTab={standardServiceState.selectedConfigTab}
            configTabs={configTabs}
            send_request_for_approval={send_request_for_approval}
            serviceTypeName={"STANDARD"}
          />
        )}
        {standardServiceState?.showDynamicRoutingPopUp && (
          <ShowDynamicRoutingMappingPopUp
            classes={classes}
            popUpOpenFlag={standardServiceState?.showDynamicRoutingPopUp}
            handleClose={handleCloseDynamicRoutingPopUp}
            standardServiceState={standardServiceState}
            handlePosSearchTextChange={handlePosSearchTextChange}
            updatePosNumberOpenIndex={updatePosNumberOpenIndex}
            headerRowCheckboxOnchange={headerRowCheckboxOnchange}
            headerMainRowCheckboxOnchange={headerMainRowCheckboxOnchange}
            headerInnerRowCheckboxOnchange={headerInnerRowCheckboxOnchange}
            searchCustomPosZoneMappingList={searchCustomPosZoneMappingList}
            getAssignedPosZoneMappingList={getAssignedPosZoneMappingList}
            handleDeleteSelectedRow={handleDeleteSelectedRow}
            handleAddSecondaryPos={handleAddSecondaryPos}
          />
        )}
        {standardServiceState.showRemovePopUp && (
          <ConfirmModal
            show={standardServiceState.showRemovePopUp}
            title={`${STANDARD_SERVICE_CONSTANT.REMOVE_ASSOCIATE_POS_ZONE}`}
            description={`${STANDARD_SERVICE_CONSTANT.THIS_ACTION_WILL} ${STANDARD_SERVICE_CONSTANT.REMOVE} ${getPOSZoneNameForRemove()} ${STANDARD_SERVICE_CONSTANT.FROM_RECEIVING_ROUTED_DEMAND}`}
            confirmButtonText={`${STANDARD_SERVICE_CONSTANT.CONFIRM}`}
            isLoading={false}
            onClose={() => handleDynamicRoutingActionPopUpClose()}
            onProceed={() => removeDynamicRoutingRowProceed()}
          />
        )}
        {standardServiceState.showDragPopUp && (
          <ConfirmModal
            show={standardServiceState.showDragPopUp}
            title={getDragDropTitleMessage()}
            description={`${STANDARD_SERVICE_CONSTANT.THIS_ACTION_WILL} ${getDragDropDescriptionMessage()} ${STANDARD_SERVICE_CONSTANT.WHEN_RECEIVING_ROUTED_DEMAND}`}
            confirmButtonText={`${STANDARD_SERVICE_CONSTANT.CONFIRM}`}
            isLoading={false}
            onClose={() => handleDynamicRoutingActionPopUpClose()}
            onProceed={() => handleOnDragDropProceed()}
          />
        )}
      </>
    </>
  );
};

export default StandardService;
