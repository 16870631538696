import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import LinkButton from "../../common/LinkButton/LinkButton";
import RemoveButton from "../../common/RemoveButton/RemoveButton";
import ProductType from "./ProductType/ProductType";
import RenderApprovalRequestCard from "./ApprovalRequest/RenderApprovalRequestCard";
import RenderZoneConfigurationsFooter from "./RenderZoneConfigurationsFooter";
import { APPLICATION_TEXT_CONSTANT, SERVICE_TYPES } from "../../../config/constants";
import { is_show_first_slot_and_service_cut_off_time_visible } from "../Express/Utils/ExpressAccessUtils";
import ShowFirstSlotAndServiceCutOffTime from "./ShowFirstSlotAndServiceCuttOff/ShowFirstSlotAndServiceCuttOff";
import CapacityOptimization from "../Standard/CapacityOptimization/CapacityOptimization";
import { is_slot_optimization_visible } from "../Standard/StandardAccessUtils";

interface RenderZoneConfigurationsCardInterface {
  classes: any;
  serviceTypeName: string;
  userInfo: any;
  service: any;
  serviceState: any;
  handleTabChange: any;
  configTabs: any;
  handleDeleteZoneConfigClick: any;
  addConfigTabs: any;
  isPosTypePharmacy: any;
  errors: any;
  updateProductTypeConfigTabs: any;
  updateFoodSubTypeConfigTabs: any;
  handleDateChangeEvent: any;
  handleCancel: any;
  handleSaveOrUpdateZoneConfigurationsSubmit: any;
  preview_request_for_approval: any;
  // access
  is_product_type_visible: any;
  is_user_creating_approval_request: any;
  is_zone_add_allowed: any;
  is_zone_delete_allowed: any;
  // component
  renderConfigureSlotsAccordion: any;
  handleSwitchButton?: any;
  handleServiceCutOffAndShowSlotAtTime?: any;
  handleCapacityOptimizationInputsChange?: any;
}

const RenderZoneConfigurationsCard = (props: RenderZoneConfigurationsCardInterface) => {
  const {
    classes,
    serviceTypeName,
    userInfo,
    service,
    serviceState,
    handleTabChange,
    configTabs,
    handleDeleteZoneConfigClick,
    addConfigTabs,
    isPosTypePharmacy,
    errors,
    updateProductTypeConfigTabs,
    updateFoodSubTypeConfigTabs,
    handleDateChangeEvent,
    handleCancel,
    handleSaveOrUpdateZoneConfigurationsSubmit,
    preview_request_for_approval,
    // access
    is_product_type_visible,
    is_user_creating_approval_request,
    is_zone_add_allowed,
    is_zone_delete_allowed,
    // component
    renderConfigureSlotsAccordion,
    handleSwitchButton,
    handleServiceCutOffAndShowSlotAtTime,
    handleCapacityOptimizationInputsChange,
  } = props;

  return (
    <Card className={classes.cards}>
      <CardContent className={classes.zoneConfigContainer}>
        <TabContext value={serviceState.selectedConfigTab.toString()}>
          <Grid container alignItems="center" className={classes.borderBelowTabs}>
            <Grid item xs={9}>
              <Tabs classes={{ root: classes.tabsRoot }} variant="scrollable" value={serviceState.selectedConfigTab} onChange={handleTabChange}>
                {configTabs !== undefined &&
                  Array.isArray(configTabs) &&
                  configTabs.length > 0 &&
                  configTabs.map((configTab: any, configTabIndex: any) => (
                    <Tab
                      id={`config-tab-${configTabIndex}`}
                      value={configTabIndex}
                      key={configTabIndex}
                      disableRipple
                      classes={{ labelIcon: classes.tabLabelIcon }}
                      label={configTab.zoneName}
                      icon={
                        is_zone_delete_allowed(userInfo) ? (
                          (configTabs.length === 1 && configTabs[serviceState.selectedConfigTab] && configTabs[serviceState.selectedConfigTab].zoneId) || configTabs.length > 1 ? (
                            <RemoveButton className={classes.deleteConfigTabIcon} onClick={(e: any) => handleDeleteZoneConfigClick(configTabIndex, e)} />
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )
                      }
                    ></Tab>
                  ))}
              </Tabs>
            </Grid>
            <Grid item xs={3} className={classes.textAlignRight}>
              {is_zone_add_allowed(userInfo) && (
                <LinkButton
                  buttonLabel="Add Zone Configuration"
                  onClick={addConfigTabs}
                  disabled={!(configTabs[serviceState.selectedConfigTab] && configTabs[serviceState.selectedConfigTab].zoneId)}
                />
              )}
            </Grid>
          </Grid>

          {configTabs !== undefined &&
            Array.isArray(configTabs) &&
            configTabs.length > 0 &&
            configTabs.map((configTab: any, configTabIndex: any) => (
              <TabPanel key={configTabIndex} className={classes.tabPanel} value={configTabIndex.toString()}>
                <Accordion className={classes.accordionStyle}>
                  <AccordionSummary className={classes.accordionSummaryStyle} expandIcon={<ExpandMoreIcon />}>
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <Typography className={classes.accordionTitle}>{`${APPLICATION_TEXT_CONSTANT.ADDITIONAL_ZONE_CONFIGURATION}`}</Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetailsStyle}>
                    {!isPosTypePharmacy && is_product_type_visible(userInfo) && (
                      <ProductType
                        isProductTypeDisabled={configTab.zoneId}
                        foodType={configTab.foodTypes}
                        foodSubType={configTab.foodSubType}
                        error={errors[configTabIndex] && errors[configTabIndex].foodTypes}
                        onFoodTypeChange={(e: any, checked: any) => updateProductTypeConfigTabs(configTabs, configTabIndex, e, checked)}
                        onSubFoodTypeChange={(e: any) => updateFoodSubTypeConfigTabs(configTabs, configTabIndex, e)}
                      />
                    )}
                    {serviceTypeName.toUpperCase() === SERVICE_TYPES.EXPRESS && !is_user_creating_approval_request(userInfo) && is_show_first_slot_and_service_cut_off_time_visible(userInfo) && (
                      <ShowFirstSlotAndServiceCutOffTime
                        showFirstSlotAtOption={configTab.showFirstSlotAtOption}
                        showFirstSlotAt={configTab.showFirstSlotAt}
                        showServiceCutOffTimeOption={configTab.showServiceCutOffTimeOption}
                        serviceCutOffTime={configTab.serviceCutOffTime}
                        handleSwitchButton={(e: any) => handleSwitchButton(configTabIndex, e)}
                        handleShowFirstSlotOrServiceCutOffTime={(date: any, elem: any) => handleServiceCutOffAndShowSlotAtTime(configTabIndex, date, elem)}
                      />
                    )}
                    {serviceTypeName.toUpperCase() === SERVICE_TYPES.STANDARD && !is_user_creating_approval_request(userInfo) && is_slot_optimization_visible(userInfo) && (
                      <CapacityOptimization
                        classes={classes}
                        capacityOptimizationFlag={configTab.bufferCapacityConfig.active}
                        bufferCapacityConfig={configTab.bufferCapacityConfig}
                        handleCapacityOptimizationSwitch={(e: any) => handleSwitchButton(configTabIndex, e)}
                        handleCapacityOptimizationInputsChange={(e: any) => handleCapacityOptimizationInputsChange(configTabIndex, e)}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>

                {is_user_creating_approval_request(userInfo) ? (
                  <RenderApprovalRequestCard
                    classes={classes}
                    serviceState={serviceState}
                    handleDateChangeEvent={handleDateChangeEvent}
                    handleCancel={handleCancel}
                    configTab={configTab}
                    configTabIndex={configTabIndex}
                    renderConfigureSlotsAccordion={renderConfigureSlotsAccordion}
                  />
                ) : (
                  renderConfigureSlotsAccordion(configTab, configTabIndex)
                )}

                <RenderZoneConfigurationsFooter
                  classes={classes}
                  userInfo={userInfo}
                  configTabs={configTabs}
                  configTab={configTab}
                  service={service}
                  serviceState={serviceState}
                  handleSaveOrUpdateZoneConfigurationsSubmit={handleSaveOrUpdateZoneConfigurationsSubmit}
                  preview_request_for_approval={preview_request_for_approval}
                />
              </TabPanel>
            ))}
        </TabContext>
      </CardContent>
    </Card>
  );
};

export default React.memo(RenderZoneConfigurationsCard);
