import React from "react";

import { Grid, Typography } from "@material-ui/core";
import { HEADER_TEXT, POLYGON_VIEW_TYPE } from "../Utils/PlolygonServicesConstants";
import { PlolygonServicesInitialStateInteface } from "../Utils/PlolygonServicesInterface";

import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";

interface PlolygonServicesHeaderInterface {
  classes: any;
  appStateObj: PlolygonServicesInitialStateInteface;
  isExporting: any;
  handleExportCSVClick: any;
  switchPolygonView: any;
}

const PlolygonServicesHeader = (props: PlolygonServicesHeaderInterface) => {
  const { classes, appStateObj, isExporting, handleExportCSVClick, switchPolygonView } = props;

  return (
    <Grid container className={classes.headerRoot}>
      <Grid item xs={7} className={classes.header}>
        <Typography className={classes.heading}>{`${HEADER_TEXT}`}</Typography>
      </Grid>
      <Grid item xs={5} className={classes.clearFilterDiv}>
        <PrimaryButton
          buttonLabel={appStateObj.polygonView === POLYGON_VIEW_TYPE.LISTING ? "Map View" : "List View"}
          buttonVariant="outlined"
          disabled={isExporting}
          onClick={switchPolygonView}
          className={classes.fullWidthButton}
        />
        {appStateObj?.polygonServiceListData?.length > 0 && (
          <PrimaryButton
            buttonLabel={isExporting ? "Exporting..." : "Export Mapping"}
            buttonVariant="outlined"
            disabled={isExporting}
            onClick={handleExportCSVClick}
            className={classes.fullWidthButton}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(PlolygonServicesHeader);
