import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Accordion, AccordionSummary, Grid } from "@material-ui/core";

import Loader from "../../common/Loader/Loader";
import CustomAlert from "../../common/CustomAlert/CustomAlert";

import slotsInformationStyles from "../SlotsInformation/SlotsInformationStyles";

import { PosStatusToggle } from "../../POSList/posUtils/PosAccessUtils";
import { activateOrDeactivateAServiceOfPOS } from "../redux/completeSetupSlice";
import { selectServiceTypes } from "../../../config/redux/configurationsSlice";
import { ALERT_TYPES, STATUS_OK } from "../../../config/constants";
import { is_fbc_service_toggle_visible } from "../CompleteSetupUtils";

const FBCInformation = (props) => {
  const classes = slotsInformationStyles();
  const dispatch = useDispatch();

  const serviceTypes = useSelector(selectServiceTypes);

  const { posNo, userInfo, serviceTypeName, serviceTypeId, slotsInformation } = props;

  const handleServiceActiveInactiveSwitch = async (e) => {
    const { checked } = e.target;
    const payloadRequest = {
      posNo,
      serviceType: serviceTypeId,
      serviceTypeName: serviceTypeName.toUpperCase(),
      active: checked,
    };

    const {
      payload: { statusCode, message: error },
    } = await dispatch(activateOrDeactivateAServiceOfPOS(payloadRequest));

    if (statusCode === STATUS_OK) {
      let message = checked ? `Service activated Successfully` : `Service de-activated Successfully`;
      CustomAlert(ALERT_TYPES.SUCCESS, message);
    }

    if (error) {
      CustomAlert(ALERT_TYPES.SUCCESS, error);
    }
  };

  return (
    <div className={classes.slotsInfoContainer}>
      {slotsInformation !== undefined && slotsInformation.loading !== undefined && slotsInformation.loading ? (
        <Loader />
      ) : (
        <Accordion className={classes.activeAccordion} expanded={false}>
          <AccordionSummary expandIcon={null} className={`${classes.accordionSummary} ${classes.serviceAccSummary}`}>
            <Grid container alignItems="center">
              <Grid item xs={4} className={classes.activeServiceType}>
                <div className={classes.textCapitalize}>{`${serviceTypes.find((s) => s.name === serviceTypeName).displayName}`}</div>
              </Grid>
              <Grid item xs={2}>
                {" "}
              </Grid>

              <Grid item xs={6}>
                {is_fbc_service_toggle_visible(userInfo) ? (
                  <Grid container justifyContent="flex-end">
                    {slotsInformation !== undefined && slotsInformation.toggling !== undefined && slotsInformation.toggling ? (
                      <Grid item xs={5} className={classes.posRelative}>
                        <Loader />
                      </Grid>
                    ) : (
                      <PosStatusToggle
                        classes={classes}
                        userInfo={userInfo}
                        checked={Boolean(slotsInformation !== undefined && slotsInformation.active !== undefined && slotsInformation.active)}
                        handleServiceActiveInactiveSwitch={(e) => handleServiceActiveInactiveSwitch(e)}
                        disabled={slotsInformation !== undefined && slotsInformation.toggling !== undefined && slotsInformation.toggling}
                      />
                    )}
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </AccordionSummary>
        </Accordion>
      )}
    </div>
  );
};

export default React.memo(FBCInformation);
