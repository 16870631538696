import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { createCamelCase } from "../../Utils/DeliveryFeeUtils";
import { CLICK_N_COLLECT, STANDARD_SERVICE_TYPE } from "../../Utils/DeliveryFeeConstants";

import DeliveryFeeConfiguration from "./Configuration/DeliveryFeeConfiguration";
import ServiceFeeConfiguration from "./Configuration/ServiceFeeConfiguration";
import MOVThresholdConfiguration from "./Configuration/MOVThresholdConfiguration";
import PreferredSlotFeesConfiguration from "./Configuration/PreferredSlotFeesConfiguration";
import { isEmptyOrNull } from "../../../../utils/DataUtils";

interface ShipmentLevelConfigurationInterface {
  classes: any;
  state: any;
  updateConfigPosId: any;
  updateConfigZoneName: any;
  _handle_service_type_accordion_open_state: any;
  use_existing_config_change_handler: any;
  config_change_existing_handler: any;
  // Delivery Fee
  _handle_delivery_fee_calculation_toggle_change: any;
  handleDeliveryFeeInputChange: any;
  _handle_click_add_threshold_range: any;
  _handle_click_delete_threshold_range: any;
  handleRangeInputChange: any;
  handleDrawerConfigChange: any;
  _handle_open_configuration_dialog: any;
  removeTimeConfigRecord: any;
  handleAdditionalConfigInputChange: any;
  handleCapacityUtilisationConfigInputChange: any;
  // Service Fee
  _handle_service_fee_calculation_toggle_change: any;
  _handle_service_fee_static_dynamic_toggle_change: any;
  handle_static_sevice_fees_input_change: any;
  handle_dynamic_sevice_fees_input_change: any;
  // MOV
  _handle_mov_threshold_calculation_toggle_change: any;
  handleMovThresholdInputChange: any;
  //
  _handle_calculation_active_toggle_change: any;
  _handle_open_express_service_fee_dialog: any;
  _handle_edit_toggle_express_service_fees_change: any;
  // Promise Slot Fee
  handlePromiseSlotFeeCalculationToggleChange: (multiShipmentLevel: boolean, serviceName: string) => void;
  promiseSlotExistingConfigChangeHandler: (serviceName: string, fieldName: string) => void;
  configPromiseSlotChangeExistingHandler: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, serviceName: string, fieldName: string) => void;
  handleCapacityRangeChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, serviceName: string, fieldName: string, slotObjIndex: number) => void;
  handleCapacityFeesChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, serviceName: string, fieldName: string, slotObjIndex: number) => void;
  handlePromiseSlotAddCapacityRange: (serviceName: string, fieldName: string) => void;
  handlePromiseSlotRemoveCapacityRange: (slotObjIndex: number, serviceName: string, fieldName: string) => void;
}

const ShipmentLevelConfiguration = (props: ShipmentLevelConfigurationInterface) => {
  const {
    classes,
    state,
    updateConfigPosId,
    updateConfigZoneName,
    _handle_service_type_accordion_open_state,
    use_existing_config_change_handler,
    config_change_existing_handler,
    // Delivery Fee
    _handle_delivery_fee_calculation_toggle_change,
    handleDeliveryFeeInputChange,
    _handle_click_add_threshold_range,
    _handle_click_delete_threshold_range,
    handleRangeInputChange,
    handleDrawerConfigChange,
    _handle_open_configuration_dialog,
    removeTimeConfigRecord,
    handleAdditionalConfigInputChange,
    handleCapacityUtilisationConfigInputChange,
    // Service Fee
    _handle_service_fee_calculation_toggle_change,
    _handle_service_fee_static_dynamic_toggle_change,
    handle_static_sevice_fees_input_change,
    handle_dynamic_sevice_fees_input_change,
    // MOV
    _handle_mov_threshold_calculation_toggle_change,
    handleMovThresholdInputChange,
    //
    _handle_calculation_active_toggle_change,
    _handle_open_express_service_fee_dialog,
    _handle_edit_toggle_express_service_fees_change,
    // Promise Slot Fee
    handlePromiseSlotFeeCalculationToggleChange,
    promiseSlotExistingConfigChangeHandler,
    configPromiseSlotChangeExistingHandler,
    handleCapacityRangeChange,
    handleCapacityFeesChange,
    handlePromiseSlotAddCapacityRange,
    handlePromiseSlotRemoveCapacityRange,
  } = props;

  const renderAccordian = (stateLevel: any, stateLevelIndex: any) => {
    return (
      <Accordion
        key={`${stateLevelIndex}-${stateLevel}-shipmentLevel-stateLevel-state`}
        expanded={state[stateLevel].openAccordian}
        className={classes.accordionStyle}
        onChange={() => _handle_service_type_accordion_open_state(stateLevel, false)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryStyle}>
          <Grid container justifyContent="space-between" spacing={0}>
            <Grid item xs={12} className={classes.marginAuto}>
              <Typography className={classes.accordionTitle}>{createCamelCase(stateLevel)}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetailsStyle}>{renderConfigurationCommonInputView(stateLevel, stateLevelIndex)}</AccordionDetails>
      </Accordion>
    );
  };

  const showPreferredSlotFeesConfiguration = (stateLevelName: string) => {
    return !isEmptyOrNull(stateLevelName) && stateLevelName === STANDARD_SERVICE_TYPE;
  };

  const renderConfigurationCommonInputView = (stateLevelName: any, stateLevelIndex: any) => {
    return (
      <>
        <DeliveryFeeConfiguration
          classes={classes}
          name={stateLevelName}
          multiShipmentLevel={false}
          stateObj={state[stateLevelName].deliveryFee}
          isEditMode={state[stateLevelName].isEditMode}
          stateLevelIndex={stateLevelIndex}
          updateConfigPosId={updateConfigPosId}
          enableThisFeature={true}
          updateConfigZoneName={updateConfigZoneName}
          use_existing_config_change_handler={use_existing_config_change_handler}
          config_change_existing_handler={config_change_existing_handler}
          _handle_delivery_fee_calculation_toggle_change={_handle_delivery_fee_calculation_toggle_change}
          handleDeliveryFeeInputChange={handleDeliveryFeeInputChange}
          _handle_click_add_threshold_range={_handle_click_add_threshold_range}
          _handle_click_delete_threshold_range={_handle_click_delete_threshold_range}
          handleRangeInputChange={handleRangeInputChange}
          handleDrawerConfigChange={handleDrawerConfigChange}
          _handle_open_configuration_dialog={_handle_open_configuration_dialog}
          removeTimeConfigRecord={removeTimeConfigRecord}
          handleAdditionalConfigInputChange={handleAdditionalConfigInputChange}
          handleCapacityUtilisationConfigInputChange={handleCapacityUtilisationConfigInputChange}
        />
        {showPreferredSlotFeesConfiguration(stateLevelName) && (
          <PreferredSlotFeesConfiguration
            classes={classes}
            serviceName={stateLevelName}
            isEditMode={state[stateLevelName].isEditMode}
            stateLevelIndex={stateLevelIndex}
            updateConfigPosId={updateConfigPosId}
            enableThisFeature={true}
            updateConfigZoneName={updateConfigZoneName}
            multiShipmentLevel={false}
            stateObj={state[stateLevelName].promiseSlotFee}
            handlePromiseSlotFeeCalculationToggleChange={handlePromiseSlotFeeCalculationToggleChange}
            promiseSlotExistingConfigChangeHandler={promiseSlotExistingConfigChangeHandler}
            configPromiseSlotChangeExistingHandler={configPromiseSlotChangeExistingHandler}
            handleCapacityRangeChange={handleCapacityRangeChange}
            handleCapacityFeesChange={handleCapacityFeesChange}
            handlePromiseSlotAddCapacityRange={handlePromiseSlotAddCapacityRange}
            handlePromiseSlotRemoveCapacityRange={handlePromiseSlotRemoveCapacityRange}
          />
        )}
        <ServiceFeeConfiguration
          classes={classes}
          name={stateLevelName}
          multiShipmentLevel={false}
          stateObj={state[stateLevelName].serviceFee}
          isEditMode={state[stateLevelName].isEditMode}
          stateLevelIndex={stateLevelIndex}
          updateConfigPosId={updateConfigPosId}
          enableThisFeature={stateLevelName !== CLICK_N_COLLECT ? true : false}
          updateConfigZoneName={updateConfigZoneName}
          use_existing_config_change_handler={use_existing_config_change_handler}
          config_change_existing_handler={config_change_existing_handler}
          _handle_service_fee_calculation_toggle_change={_handle_service_fee_calculation_toggle_change}
          _handle_service_fee_static_dynamic_toggle_change={_handle_service_fee_static_dynamic_toggle_change}
          handle_static_sevice_fees_input_change={handle_static_sevice_fees_input_change}
          handle_dynamic_sevice_fees_input_change={handle_dynamic_sevice_fees_input_change}
        />
        <MOVThresholdConfiguration
          classes={classes}
          name={stateLevelName}
          multiShipmentLevel={false}
          stateObj={state[stateLevelName].minimumOrderValue}
          isEditMode={state[stateLevelName].isEditMode}
          stateLevelIndex={stateLevelIndex}
          updateConfigPosId={updateConfigPosId}
          enableThisFeature={true}
          updateConfigZoneName={updateConfigZoneName}
          use_existing_config_change_handler={use_existing_config_change_handler}
          config_change_existing_handler={config_change_existing_handler}
          _handle_mov_threshold_calculation_toggle_change={_handle_mov_threshold_calculation_toggle_change}
          handleMovThresholdInputChange={handleMovThresholdInputChange}
        />
      </>
    );
  };

  return (
    <>
      {state !== undefined && typeof state === "object" && Object.keys(state).length > 0 ? (
        Object.keys(state).map((stateLevel: any, stateLevelIndex: any) =>
          updateConfigPosId == "" && updateConfigZoneName == "" ? (
            renderAccordian(stateLevel, stateLevelIndex)
          ) : updateConfigPosId !== "" && updateConfigZoneName !== "" && state[stateLevel] && state[stateLevel].isEditMode === true ? (
            renderAccordian(stateLevel, stateLevelIndex)
          ) : (
            <></>
          )
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default React.memo(ShipmentLevelConfiguration);
