import React from "react";

import { userStyles } from "./Styles/ActivateDeactivateZoneErrorPopUpStyle";

import ModalContainer from "../../../../base/ModalContainer";
import PrimaryButton from "../../../common/PrimaryButton/PrimaryButton";

import { Card, Grid, Typography } from "@material-ui/core";
import { APPLICATION_TEXT_CONSTANT, BUTTON_TEXT_CONSTANT, ZONE_ACTIVATION_CONSTANT, ZONE_ACTIVATION_REASON_CONSTANT } from "../../../../config/constants";
import { isEmptyOrNull, isUndefined } from "utils/DataUtils";

interface ActivateDeactivateZoneErrorPopUpInterface {
  open: boolean;
  handleClose: () => void;
  detailsObj: any;
}

const ActivateDeactivateZoneErrorPopUp = (props: ActivateDeactivateZoneErrorPopUpInterface) => {
  const { open, handleClose, detailsObj } = props;
  const classes = userStyles();

  const getAbsentConfigurationText = (dataString: string) => {
    let returnString: string = "N/A";
    const ReasonText: string | undefined = Object.keys(ZONE_ACTIVATION_REASON_CONSTANT).find((key) => key && ZONE_ACTIVATION_REASON_CONSTANT[key] === dataString);
    if (!isUndefined(ReasonText) && !isEmptyOrNull(ReasonText)) {
      returnString = (ReasonText && ZONE_ACTIVATION_CONSTANT[ReasonText]) || "";
    }
    return returnString;
  };

  return (
    <ModalContainer open={open} className={classes.mainContainer} onClose={handleClose} disableBackdropClick>
      <Card className={classes.cardContainer}>
        {/* Header */}
        <Grid container className={classes.gridContainerMain}>
          <Grid item xs={12} className={classes.gridContainerTitleDivStyle}>
            <div className={classes.headerStyleNormalDiv}>{`${APPLICATION_TEXT_CONSTANT.ZONE_ACTIVATION_FAILED}`}</div>
          </Grid>
        </Grid>
        {/* Body */}
        <Grid container className={classes.gridContainerContent}>
          <Grid item xs={12}>
            <Typography className={classes.headingTextStyle}>{`${APPLICATION_TEXT_CONSTANT.PENDING_CONFIGURATION_HEADER}`}</Typography>
            <Grid container>
              {detailsObj !== undefined &&
                detailsObj.data !== undefined &&
                Object.keys(detailsObj.data).length > 0 &&
                Object.keys(detailsObj.data).map((item: any, index: any) => (
                  <Grid item xs={12} key={`${index}-detailsObj-data`} className={classes.valueTextDivStyle}>
                    <Typography className={classes.valueTextStyle}>{`${index + 1} : ${getAbsentConfigurationText(detailsObj.data[item])}`}</Typography>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
        {/* Footer */}
        <Grid container className={classes.gridContainerFooter}>
          <PrimaryButton className={classes.cancelButtonStyle} buttonLabel={BUTTON_TEXT_CONSTANT.CLOSE} onClick={() => handleClose()} />
        </Grid>
      </Card>
    </ModalContainer>
  );
};

export default React.memo(ActivateDeactivateZoneErrorPopUp);
