import React from "react";

import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";

import DashboardCustomLinearProgress from "./DashboardCustomLinearProgress";
import { isNonEmptyArray } from "../../../utils/DataUtils";
import { isPosListRecordPresent, isTotalRecordPresent } from "../Utils/response.transform";

interface CustomSlotUtilizationDashboardInterface {
  classes: any;
  appStateObj: any;
}

const CustomSlotUtilizationDashboard = (props: CustomSlotUtilizationDashboardInterface) => {
  const { classes, appStateObj } = props;

  return (
    <>
      <TableContainer className={classes.tableContainerStyle}>
        <Table padding="none" size="small" stickyHeader={true}>
          <TableHead>
            <TableRow className={classes.tableHeaderRowStyle}>
              <TableCell className={`${classes.tableBodyCellStickyRelativeStyle} ${classes.tableBodyCellStickyStyle} ${classes.customZIndex}`}>{""}</TableCell>
              <TableCell className={`${classes.tableBodyCellStyle}`}>
                <Typography className={classes.tableBodyCellTotalSpanStyle}>{`POS Total`}</Typography>
              </TableCell>
              {isNonEmptyArray(appStateObj.slotList) &&
                appStateObj.slotList.map((header: any, headerIndex: number) => (
                  <TableCell className={classes.tableHeaderCellStyle} key={`${headerIndex}-headerIndex-appStateObj.slotList`}>{`${header}`}</TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.tableCellRowStyle}>
              <TableCell className={`${classes.tableBodyCellStickyRelativeStyle} ${classes.tableBodyCellStickyStyle}`}>
                <Typography className={classes.tableBodyCellTotalSpanStyle}>{`Slot Total`}</Typography>
              </TableCell>
              <TableCell className={`${classes.tableBodyCellStyle}`}>
                <DashboardCustomLinearProgress
                  classes={classes}
                  totalCapacity={appStateObj.totalSlotCapacity}
                  usedCapacity={appStateObj.totalUsedCapacity}
                  availableCapacity={appStateObj.totoalAvailableCapacity}
                  usedCapacityPercentage={appStateObj.totalUsedCapacityPercentage}
                />
              </TableCell>
              {isTotalRecordPresent(appStateObj) &&
                appStateObj?.recordData?.totalRecord.map((totalDataRow: any, totalDataRowIndex: number) => (
                  <TableCell className={classes.tableBodyCellStyle} key={`${totalDataRowIndex}-totalDataRowIndex-recordData-totalRecord.totalDataRow`}>
                    <DashboardCustomLinearProgress
                      classes={classes}
                      totalCapacity={totalDataRow.totalCapacity}
                      usedCapacity={totalDataRow.usedCapacity}
                      availableCapacity={totalDataRow.availableCapacity}
                      usedCapacityPercentage={totalDataRow.usedCapacityPercentage}
                    />
                  </TableCell>
                ))}
            </TableRow>
            {isPosListRecordPresent(appStateObj) &&
              appStateObj?.recordData?.posListRecord.map((posDataRow: any, posDataRowIndex: number) => (
                <TableRow className={classes.tableCellRowStyle} key={`${posDataRowIndex}-posDataRowIndex-recordData-posListRecord.posDataRow`}>
                  <TableCell className={`${classes.tableBodyCellStickyRelativeStyle} ${classes.tableBodyCellStickyStyle}`}>
                    <Typography className={`${classes.tableBodyCellPosSpanStyle} ${classes.displayFlex}`}>
                      {`${posDataRow.posNo}`} <Typography className={classes.tableBodyCellPosNormalSpanStyle}>{`${posDataRow.posName}`}</Typography>
                    </Typography>
                  </TableCell>
                  <TableCell className={`${classes.tableBodyCellStyle}`}>
                    <DashboardCustomLinearProgress
                      classes={classes}
                      totalCapacity={posDataRow.totalSlotCapacity}
                      usedCapacity={posDataRow.totalUsedCapacity}
                      availableCapacity={posDataRow.totoalAvailableCapacity}
                      usedCapacityPercentage={posDataRow.totalUsedCapacityPercentage}
                    />
                  </TableCell>
                  {isNonEmptyArray(posDataRow.slotDetails) &&
                    posDataRow.slotDetails.map((slotData: any, slotDataIndex: number) => (
                      <TableCell className={classes.tableBodyCellStyle} key={`${slotDataIndex}-slotDataIndex-${posDataRowIndex}-posDataRowIndex-recordData-posListRecord.posDataRow`}>
                        {slotData.slotPresent ? (
                          <DashboardCustomLinearProgress
                            classes={classes}
                            totalCapacity={slotData.totalCapacity}
                            usedCapacity={slotData.usedCapacity}
                            availableCapacity={slotData.availableCapacity}
                            usedCapacityPercentage={slotData.usedCapacityPercentage}
                          />
                        ) : (
                          <></>
                        )}
                      </TableCell>
                    ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid className={classes.tableFooterGridContainer}>
        <Typography className={classes.tableFooterSpanStyle}>
          <Box className={`${classes.tableFooterBoxStyle} ${classes.linearProgressPrimaryColor}`}></Box>
          {`Available Slots`}
          <Box className={`${classes.tableFooterBoxStyle} ${classes.linearProgressFillColorBlueStyle}`}></Box>
          {`Under Utilization (0%-70%)`}
          <Box className={`${classes.tableFooterBoxStyle} ${classes.linearProgressFillColorYellowStyle}`}></Box>
          {`Normal Utilization (70%-99%)`}
          <Box className={`${classes.tableFooterBoxStyle} ${classes.linearProgressFillColorGreenStyle}`}></Box>
          {`Fully Utilization (=100%)`}
          <Box className={`${classes.tableFooterBoxStyle} ${classes.linearProgressFillColorRedStyle}`}></Box>
          {`Over Utilization  (>100%)`}
        </Typography>
      </Grid>
    </>
  );
};

export default React.memo(CustomSlotUtilizationDashboard);
