import React from "react";

import { FormControl, Grid, IconButton, InputAdornment, InputLabel, SvgIcon, TextField, Typography } from "@material-ui/core";

import { useStyles } from "./SearchHeaderCustomStyles";

import SearchHeaderCustomSelectBox from "./SearchHeaderCustomSelectBox";
import { ReactComponent as searchIcon } from "../../assets/svg/SearchIcon.svg";
import { isUndefined } from "../../../utils/DataUtils";

interface SearchHeaderCustomInterface {
  searchTypes: any;
  searchType: any;
  handleSearchTypeChange: any;
  // Seacrh Box
  searchValue: any;
  handleSearchBoxTextChange: any;
  handleSearchBoxKeyDownChange: any;
  placeholderText: any;
  handleIconClick: any;
  resetRecord: any;
  showInputBox?: boolean;
}

const SearchHeaderCustom = (props: SearchHeaderCustomInterface) => {
  const { searchTypes, searchType, handleSearchTypeChange, searchValue, handleSearchBoxTextChange, handleSearchBoxKeyDownChange, placeholderText, handleIconClick, resetRecord, showInputBox } = props;
  const classes = useStyles();

  return (
    <Grid className={isUndefined(showInputBox) ? classes.root : classes.rootNoInputSelect} container>
      <Grid className={isUndefined(showInputBox) ? classes.searchSelect : classes.searchNoInputSelect} item>
        <SearchHeaderCustomSelectBox
          items={searchTypes}
          value={searchType}
          handleChange={handleSearchTypeChange}
          menuPaperProps={classes.searchSelectPaperProps}
          listItemTextProps={classes.listItemTextProps}
          classes={classes}
        />
      </Grid>
      {isUndefined(showInputBox) && (
        <>
          <Grid className={classes.searchTextbox} item>
            <FormControl className={classes.textBoxRoot} variant={"outlined"}>
              <InputLabel htmlFor={"searchTextBox"} shrink>
                {"Search"}
              </InputLabel>
              <TextField
                type={"text"}
                variant={"outlined"}
                className={`hasLabel`}
                placeholder={placeholderText}
                label=""
                id="searchTextBox"
                onChange={handleSearchBoxTextChange}
                onKeyDown={handleSearchBoxKeyDownChange}
                value={searchValue}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" size="small" onClick={handleIconClick}>
                        <SvgIcon component={searchIcon} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          {searchType !== undefined && searchType !== "" && <Typography className={classes.resetSpanStyle} onClick={resetRecord}>{`Reset`}</Typography>}
        </>
      )}
    </Grid>
  );
};

export default React.memo(SearchHeaderCustom);
