import React, { useRef, useState } from "react";

import { FormControl, ListItemText, MenuItem, Select } from "@material-ui/core";

import { ReactComponent as downArrowIcon } from "../../assets/svg/DownArrow.svg";

import { anchorOriginDefault } from "./SearchHeaderCustomStyles";
import { SELECT_NONE } from "../../../config/constants";

interface SearchHeaderCustomSelectBoxInterface {
  items: any;
  value: any;
  handleChange: any;
  menuPaperProps?: any;
  classes: any;
  className?: any;
  listItemTextProps?: any;
}

export interface SelectItemProps {
  name?: string;
  value: any;
}

const SearchHeaderCustomSelectBox = (props: SearchHeaderCustomSelectBoxInterface) => {
  const { items, value, handleChange, menuPaperProps, classes, className, listItemTextProps } = props;

  const anchorEl = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const handleOnChange = (event: any) => {
    if (event !== undefined && event.target !== undefined) {
      let selected = event.target.value;
      if (Array.isArray(selected)) {
        selected.filter((item: any) => item);
        if (!selected.includes(undefined)) {
          event.target.value = selected;
          handleChange && handleChange(selected);
        }
      } else {
        event.target.value = selected;
        handleChange && handleChange(selected);
      }
    }
  };

  const getChips = (selected: any) => {
    const item: SelectItemProps = items && items.filter((i: any) => i.value === selected).length ? items.filter((i: any) => i.value === selected)[0] : SELECT_NONE;
    return item.name;
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        displayEmpty
        className={`${className || ""} ${isOpen ? "open " : ""}`}
        ref={anchorEl}
        labelId={""}
        multiple={false}
        value={value}
        onOpen={() => {
          setIsOpen(true);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        onChange={handleOnChange}
        renderValue={getChips}
        IconComponent={downArrowIcon}
        MenuProps={{
          classes: {
            paper: menuPaperProps || classes.menuPropsPaper,
          },
          autoFocus: false,
          anchorEl: anchorEl.current,
          getContentAnchorEl: null,
          anchorOrigin: anchorOriginDefault,
        }}
      >
        {items && items.length ? (
          items.map((item: any, index: any) => (
            <MenuItem key={`${item.value}-${index}`} value={item.value}>
              <ListItemText primary={item.name} classes={{ root: classes.listItemTextRootProps, primary: listItemTextProps || classes.listItemTextProps }} />
            </MenuItem>
          ))
        ) : (
          <></>
        )}
      </Select>
    </FormControl>
  );
};

export default React.memo(SearchHeaderCustomSelectBox);
