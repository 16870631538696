import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@material-ui/core";

import { ExpandMoreAccordianIcon } from "../../common/App/AppViewUtils";

interface RenderExpressOnDemandSlotsInterface {
  classes: any;
  dayWiseSlots: any;
}

export const RenderExpressOnDemandSlots = (props: RenderExpressOnDemandSlotsInterface) => {
  const { classes, dayWiseSlots } = props;

  return (
    <>
      {dayWiseSlots !== undefined &&
        typeof dayWiseSlots === "object" &&
        Object.keys(dayWiseSlots).length > 0 &&
        Object.keys(dayWiseSlots).map((dayWiseSlot) => (
          <Accordion key={dayWiseSlot} className={classes.slotsAccordion} onClick={(e) => e.stopPropagation()}>
            <AccordionSummary expandIcon={<ExpandMoreAccordianIcon />} className={classes.slotsAccordionSummary}>
              <div className={classes.fieldTitle}>
                <span className={classes.fontWeight500}>Slots for : </span>
                <span className={classes.dayLabel}>{`${dayWiseSlot.toLowerCase()} (Capacity - ${
                  dayWiseSlots !== undefined &&
                  dayWiseSlots[dayWiseSlot] !== undefined &&
                  Array.isArray(dayWiseSlots[dayWiseSlot]) &&
                  dayWiseSlots[dayWiseSlot].length > 0 &&
                  dayWiseSlots[dayWiseSlot][0] !== undefined &&
                  dayWiseSlots[dayWiseSlot][0].maximum_slot_capacity !== undefined
                    ? dayWiseSlots[dayWiseSlot][0].maximum_slot_capacity
                    : "0"
                })`}</span>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.dBlock}>
              <Grid container>
                <Grid item xs={8}>
                  <Grid container spacing={1} className={classes.slotsContainer}>
                    <Grid item xs={4} className={classes.slotsTableHeader}>
                      First customer order time
                    </Grid>
                    <Grid item xs={4} className={classes.slotsTableHeader}>
                      Last order Cut-off Time
                    </Grid>
                    <Grid item xs={4} className={classes.slotsTableHeader}>
                      Capacity
                    </Grid>
                  </Grid>

                  {dayWiseSlots[dayWiseSlot].map((slot: any, index: any) => (
                    <div key={slot.id} className={classes.slotDivider} style={{ fontSize: "12px" }}>
                      <Grid container spacing={1} className={classes.slotsContainer}>
                        <Grid item xs={4}>{`${slot.startTime.substr(0, 5)}`}</Grid>
                        <Grid item xs={4}>{`${slot.endTime.substr(0, 5)}`}</Grid>
                        <Grid item xs={4}>
                          {slot.slotCapacity}
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
    </>
  );
};
