import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../../../config/theme/theme";

const slotsGeneratorFormStyles = makeStyles(() => ({
  whiteBackground: {
    marginTop: "12px",
    backgroundColor: "#FFFFFF !important",
  },
  greyBackground: {
    marginTop: "12px",
    backgroundColor: "#F9F9F9",
  },
  generatedSlotsAccordionDetails: {
    margin: 0,
    padding: 0,
    display: "block",
  },
  generatedSlotsInfoGridItem: {
    margin: 0,
    padding: "0px 24px 0px 24px",
  },
  dayStyles: {
    fontWeight: 600,
    paddingLeft: "5px",
    textTransform: "capitalize",
  },
  isSlotUpdatedIconStyle: { marginLeft: "8px" },
  error: {
    marginTop: "5px",
    fontSize: "12px",
    color: "red",
  },
  slotsTableLabel: {
    fontWeight: 400,
    fontSize: "12px",
  },
  slotsAccordion: {
    padding: "4px 24px !important",
    "&.MuiAccordionSummary-content": {
      "&.Mui-expanded": {
        margin: "16px 0px",
      },
    },
  },
  addNewSlot: {
    padding: "0px",
  },
  slotsContainer: {
    marginTop: "16px",
  },
  bulkUpdateItemGridMain: {
    margin: "16px 0px 16px 0px",
    padding: "16px",
    width: "100%",
    height: "auto",
    background: COLORS.FOAM,
    borderRadius: 0,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  bulkUpdateInfoIconStyle: {
    width: "24px",
    height: "24px",
    margin: "0px 4px 0px 0px",
    padding: 0,
  },
  bulkUpdateInfoSpanStyle: {
    fontSize: "14px",
    fontWeight: 400,
    color: COLORS.MATISSE,
    margin: "0px 8px 0px 0px",
    padding: 0,
  },
  bulkUpdateLinkSpanStyle: {
    fontSize: "14px",
    fontWeight: 600,
    color: COLORS.MATISSE,
    cursor: "pointer",
  },
  bulkUpdateRevertItemGridMain: {
    margin: "16px 0px 16px 0px",
    padding: "16px",
    width: "100%",
    height: "auto",
    background: COLORS.LIGHT_RED_BACKGROUND,
    borderRadius: 0,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  bulkUpdateRevertSpanStyle: {
    fontSize: "14px",
    fontWeight: 400,
    color: COLORS.DARK_RED,
    margin: "0px 8px 0px 0px",
    padding: 0,
  },
  bulkUpdateRevertLinkSpanStyle: {
    fontSize: "14px",
    fontWeight: 600,
    color: COLORS.DARK_RED,
    cursor: "pointer",
  },
}));

export default slotsGeneratorFormStyles;
