import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../../config/theme/theme";

const currentConfigStyle = makeStyles({
  root: {
    marginTop: "80px",
    padding: "24px 12px 24px 24px",
  },
  tabPanelStyle: {
    padding: "12px 0px 0px 0px",
    overflow: "auto",
    height: "calc(100vh - 420px)",
  },
  tabStyle: {
    margin: "6px 8px",
    fontSize: "18px",
  },
  tabsBorder: {
    borderBottom: `2px solid ${COLORS.SECONDARY_DARK}`,
    marginTop: "-2px",
  },
  dFlexEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
  completeSetupSettingsButton: {
    display: "flex",
    "& img": {
      height: "18px",
      marginLeft: "20px",
    },
  },
  tableCellTrucate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  headerStyle: {
    background: "rgba(0, 0, 0, 0.04)",
    margin: 0,
    padding: 0,
    minHeight: "60px",
    "& .MuiDataGrid-root": {
      margin: 0,
      padding: 0,
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      margin: 0,
      padding: 0,
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      lineHeight: "normal",
      textOverflow: "clip",
      whiteSpace: "normal",
      fontWeight: 600,
    },
  },
  ScalableItemsSpanStyle: {
    fontWeight: 400,
    fontSize: "12px",
    color: "#0E5AA7",
  },
  searchBoxDivStyle: {
    padding: 0,
    margin: "0px 0px 0px 12px",
  },
  tableStyle: {
    margin: 0,
    marginTop: "24px",
    padding: 0,
    minHeight: "500px",
    maxHeight: "calc(100vh - 418px)",
    width: "100%",
    overflow: "auto",
  },
  headerRoot: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "24px",
  },
  header: {
    display: "flex",
    justifyContent: "flex-start",
  },
  heading: {
    fontWeight: 600,
    fontSize: "24px",
    color: COLORS.DARK_GRAY,
  },
  buttonStyles: {
    width: "364px",
    height: "40px",
    background: COLORS.DOS_BLUE_DARK,
    color: "#FFFFFF",
    border: "1px solid",
    borderColor: COLORS.DOS_BLUE_DARK,
    borderRadius: "8px",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    "&:hover": {
      backgroundColor: COLORS.DOS_BLUE_DARK,
      color: "#FFFFFF",
      border: "1px solid",
      borderColor: COLORS.DOS_BLUE_DARK,
      borderRadius: "8px",
    },
    "&:disabled": {
      border: "1px solid rgba(0, 0, 0, 0.12)",
    },
  },
  resetButtonDivStyle: {
    padding: 0,
    paddingRight: "8px",
    margin: 0,
    display: "flex",
    justifyContent: "flex-end",
  },
  resetButtonStyle: {
    width: "212px",
    border: "1px solid #0E5AA7",
    background: "#fff",
    color: "#0E5AA7",
    "&:hover": {
      border: "1px solid #0E5AA7",
      background: "#fff",
      color: "#0E5AA7",
    },
  },
  VehicleTypeLabelSpanStyle: {
    fontWeight: 500,
    fontSize: "0.625rem",
    lineHeight: "1.4em",
    color: "#fff",
  },
  VehicleTypeValueSpanStyle: {
    fontWeight: 600,
    fontSize: "0.625rem",
    lineHeight: "1.4em",
    color: "#fff",
  },
  successSpanStyle: {
    fontSize: "12px",
    fontWeight: 500,
    color: COLORS.PERSIAN_GREEN,
  },
  failureSpanStyle: {
    fontSize: "12px",
    fontWeight: 500,
    color: COLORS.DARK_RED,
  },
  recordSpanStyle: {
    fontSize: "12px",
    fontWeight: 400,
    color: COLORS.TUATARA,
  },
  recordToolTipSpanStyle: {
    fontSize: "12px",
    fontWeight: 400,
    color: COLORS.WHITE,
  },
  toolTipContainerStyel: {
    minWidth: "212px",
    padding: "4px 12px",
  },
  lineHeightStyle: {
    lineHeight: 1.5,
  },
});

export default currentConfigStyle;
