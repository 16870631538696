import React from "react";

import moment from "moment";

import { Accordion, AccordionDetails, AccordionSummary, Card, CardActionArea, CardContent, CardHeader, FormControl, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { ReactComponent as EditIcon } from "../../../../assets/svg/Pencil.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/svg/DeleteIcon.svg";
import { ReactComponent as BulkUpdateInfoIcon } from "../../../../assets/svg/BulkUpdateInfoIcon.svg";

import TextBox from "../../../../common/TextBox";
import SelectBox from "../../../../common/SelectBox/SelectBox";
import TimePicker from "../../../../common/TimePicker/TimePicker";

import { generateDropdownItems } from "../../../../../utils/helperFunctions";
import LinkButton from "components/common/LinkButton/LinkButton";

interface RenderConfigurationSlotsAccordionInterface {
  classes: any;
  configTab: any;
  handleIndividualSlotFieldsChange: any;
  makeSlotEditable: any;
  handleIndividualSlotTimeChange: any;
  deleteTemplateSlot: any;
  addNewTemplateSlot: any;
  handleBulkUpdateAction: any;
  bulkUpdatedDays: any;
  resetCurrentConfigurationAfterBulkUpdateRevert: any;
}

export const HOURS = generateDropdownItems(0, 95, "hours");
export const MINUTES = generateDropdownItems(0, 59, "mins");

const RenderConfigurationSlotsAccordion = (props: RenderConfigurationSlotsAccordionInterface) => {
  const {
    classes,
    configTab,
    handleIndividualSlotFieldsChange,
    makeSlotEditable,
    handleIndividualSlotTimeChange,
    deleteTemplateSlot,
    addNewTemplateSlot,
    handleBulkUpdateAction,
    bulkUpdatedDays,
    resetCurrentConfigurationAfterBulkUpdateRevert,
  } = props;

  const calculateOrderCutOff = (slot: any) => {
    let orderCutOff = "";
    const { bufferTimeHours, bufferTimeMinutes, startTime } = slot;
    if (startTime && moment(startTime).isValid()) {
      const totalBufferTimeInMinutes = bufferTimeHours * 60 + bufferTimeMinutes;
      orderCutOff = moment(startTime).subtract(totalBufferTimeInMinutes, "m").format("HH:mm");
    }

    return orderCutOff || "";
  };

  const show_bulk_update_view = (slotGeneratorForm: any, generatorFormIndex: any) => {
    if (bulkUpdatedDays !== undefined && Array.isArray(bulkUpdatedDays)) {
      if (bulkUpdatedDays.length == 0) {
        return (
          <Grid item xs={12} className={classes.bulkUpdateItemGridMain}>
            <BulkUpdateInfoIcon className={classes.bulkUpdateInfoIconStyle} />
            <Typography className={classes.bulkUpdateInfoSpanStyle}>{`You can now duplicate the above configuration for other days as well`}</Typography>
            <Typography className={classes.bulkUpdateLinkSpanStyle} onClick={() => handleBulkUpdateAction(generatorFormIndex)}>{`Click here to bulk update`}</Typography>
          </Grid>
        );
      } else if (
        slotGeneratorForm !== undefined &&
        slotGeneratorForm.days !== undefined &&
        Array.isArray(slotGeneratorForm.days) &&
        slotGeneratorForm.days.length !== undefined &&
        slotGeneratorForm.days[0] !== undefined &&
        slotGeneratorForm.days[0] !== "" &&
        bulkUpdatedDays.indexOf(slotGeneratorForm.days[0]) > -1
      ) {
        return (
          <Grid item xs={12} className={classes.bulkUpdateRevertItemGridMain}>
            <Typography className={classes.bulkUpdateRevertSpanStyle}>{`Configuration for this days is changed in bulk. Want to revert back ?`}</Typography>
            <Typography className={classes.bulkUpdateRevertLinkSpanStyle} onClick={() => resetCurrentConfigurationAfterBulkUpdateRevert()}>{`Click here to refresh page`}</Typography>
          </Grid>
        );
      }
    }
    return <></>;
  };

  return (
    <Card>
      <CardContent>
        {configTab.slotsGeneratorForms.map((slotGeneratorForm: any, generatorFormIndex: any) => (
          <div key={`${slotGeneratorForm?.days[0] || generatorFormIndex}`} className={classes.slotsGeneratorFormContainer}>
            <Accordion defaultExpanded={false} className={classes.greyBackground}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.slotsAccordion}>
                <Grid container>
                  <Grid item xs={6}>
                    <div>
                      {`Slots for : `}
                      <span className={classes.dayStyles}>{slotGeneratorForm.days[0].toLowerCase()}</span>
                    </div>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails className={classes.generatedSlotsAccordionDetails}>
                <Grid container spacing={0}>
                  {slotGeneratorForm !== undefined &&
                    slotGeneratorForm.generatedSlots !== undefined &&
                    Array.isArray(slotGeneratorForm.generatedSlots) &&
                    slotGeneratorForm.generatedSlots.length > 0 && (
                      <Grid container spacing={1}>
                        <Grid item xs={3} className={classes.newRequestGridHeader}>
                          Slots
                        </Grid>
                        <Grid item xs={2} className={classes.newRequestGridHeader}>
                          Capacity
                        </Grid>
                        <Grid item xs={3} className={classes.newRequestGridHeader}>
                          Buffer Time (Hrs/Mins)
                        </Grid>
                        <Grid item xs={2} className={classes.newRequestGridHeader}>
                          Order Cut-off
                        </Grid>
                        <Grid item xs={1} className={classes.newRequestGridHeader}>
                          Actions
                        </Grid>

                        {slotGeneratorForm.generatedSlots.map(
                          (slot: any, slot_index: any) =>
                            slot !== undefined &&
                            slot.slotAction !== undefined &&
                            slot.slotAction !== "" &&
                            slot.slotAction !== "DELETE" && (
                              <Grid container spacing={1} key={`${slot_index}-slot-generatedSlots-slotGeneratorForm-${slotGeneratorForm.days[0].toLowerCase()}}`}>
                                {/* <Grid item xs={2} className={classes.newRequestGridValuesLabel}>
                            <Typography className={classes.checkboxLabel}>{slot.time}</Typography>
                          </Grid> */}
                                {/* DOS 1769 */}
                                <Grid item xs={3} className={classes.newRequestGridValues}>
                                  <Grid container spacing={1} className={classes.slotEditTimeContainer}>
                                    <Grid item xs={6}>
                                      <TimePicker
                                        showTimeIcon={true}
                                        value={slot.startTime}
                                        handleChange={(date: any) => handleIndividualSlotTimeChange(generatorFormIndex, slot_index, date, "startTime")}
                                        fieldLabel={undefined}
                                        className={undefined}
                                        timerProps={undefined}
                                        disabled={!slot.canBeUpdated}
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <TimePicker
                                        showTimeIcon={true}
                                        value={slot.endTime}
                                        handleChange={(date: any) => handleIndividualSlotTimeChange(generatorFormIndex, slot_index, date, "endTime")}
                                        fieldLabel={undefined}
                                        className={undefined}
                                        timerProps={undefined}
                                        disabled={!slot.canBeUpdated}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={2} className={classes.newRequestGridValues}>
                                  <FormControl className={classes.slotField}>
                                    <TextBox
                                      type="number"
                                      name="slotCapacity"
                                      value={slot.slotCapacity}
                                      handleChange={(e: any) => handleIndividualSlotFieldsChange(e, generatorFormIndex, slot_index)}
                                      inputProps={{ maxLength: 15 }}
                                      textBoxId="capacityGenerated"
                                      disabled={!slot.canBeUpdated}
                                      placeholderText="Enter Capacity"
                                      isNegativeAllowed={false}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={3} className={classes.newRequestGridValues}>
                                  <Grid container spacing={1}>
                                    <Grid item xs={5}>
                                      <SelectBox
                                        label="Hrs"
                                        name="bufferTimeHours"
                                        menuItems={HOURS}
                                        value={slot.bufferTimeHours}
                                        handleChange={(e: any) => handleIndividualSlotFieldsChange(e, generatorFormIndex, slot_index)}
                                        disabled={!slot.canBeUpdated}
                                        labelClass={classes.hideShrinkedLabel}
                                        selectClass={classes.selectClass}
                                        className={classes.slotField}
                                      />
                                    </Grid>
                                    <Grid item xs={5} className={classes.newRequestGridValues}>
                                      <SelectBox
                                        label="Mins"
                                        name="bufferTimeMinutes"
                                        menuItems={MINUTES}
                                        value={slot.bufferTimeMinutes}
                                        handleChange={(e: any) => handleIndividualSlotFieldsChange(e, generatorFormIndex, slot_index)}
                                        disabled={!slot.canBeUpdated}
                                        labelClass={classes.hideShrinkedLabel}
                                        selectClass={classes.selectClass}
                                        className={classes.slotField}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={2} className={classes.newRequestGridValuesLabel}>
                                  {calculateOrderCutOff(slot)}
                                </Grid>
                                <Grid item xs={1} className={classes.newRequestGridValuesLabel}>
                                  {!slot.canBeUpdated ? (
                                    <EditIcon className={classes.iconButton} onClick={() => makeSlotEditable(generatorFormIndex, slot_index)} />
                                  ) : (
                                    <DeleteIcon className={classes.iconButton} onClick={() => deleteTemplateSlot(generatorFormIndex, slot_index)} />
                                  )}
                                </Grid>
                              </Grid>
                            )
                        )}
                      </Grid>
                    )}
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12} className={classes.newRequestGridValues}>
                    <LinkButton buttonLabel="Add New Slot" onClick={() => addNewTemplateSlot(generatorFormIndex, slotGeneratorForm.days[0])} />
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  {slotGeneratorForm !== undefined &&
                    slotGeneratorForm.generatedSlots !== undefined &&
                    Array.isArray(slotGeneratorForm.generatedSlots) &&
                    slotGeneratorForm.generatedSlots.length > 0 &&
                    show_bulk_update_view(slotGeneratorForm, generatorFormIndex)}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </CardContent>
      <CardActionArea></CardActionArea>
    </Card>
  );
};

export default React.memo(RenderConfigurationSlotsAccordion);
