import { makeStyles } from "@material-ui/core";

const styles = makeStyles({
  onDemandContainer: {
    padding: "24px",
    backgroundColor: "white",
  },
  addSlots: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

export default styles;
