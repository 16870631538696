import React from "react";

import { Card, CardContent, Divider, Grid, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { ReactComponent as DeleteSlotIcon } from "../../assets/svg/DeleteIcon.svg";

import { DAYS_DROPDOWN_OPTIONS, SERVICE_TYPES } from "../../../config/constants";
import { PlasticBagconfigurationViewUtils, ServiceActiveFlagViewUtils } from "../CountrySettingView";

import MultiSelectBox from "../../common/MultiSelectBox/MultiSelectBox";
import CountryDefaultTypeDatePicker from "../CountryDefaultTypeDatePicker";

interface RenderDefaultTypeDetailsInterface {
  classes: any;
  defaultDetails: any;
  handleBagChargeChange: any;
  weeklyHolidays: any;
  setWeeklyHolidays: any;
  defaultDateState: any;
  handleDateChangeEvent: any;
  handleCancel: any;
  handleCalendarHoliday: any;
  calendarHoliday: any;
  displayHolidays: any;
  displayElementCount: any;
  handleDetailsChange: any;
  handleServiceTypeActiveChange: any;
}

const RenderDefaultTypeDetails = (props: RenderDefaultTypeDetailsInterface) => {
  const {
    classes,
    defaultDetails,
    handleBagChargeChange,
    weeklyHolidays,
    setWeeklyHolidays,
    defaultDateState,
    handleDateChangeEvent,
    handleCancel,
    handleCalendarHoliday,
    calendarHoliday,
    displayHolidays,
    displayElementCount,
    handleDetailsChange,
    handleServiceTypeActiveChange,
  } = props;

  return (
    <Card className={classes.serviceTypeCard}>
      <CardContent>
        <Grid container justifyContent="flex-start" spacing={2}>
          <ServiceActiveFlagViewUtils
            classes={classes}
            serviceTypeName={SERVICE_TYPES.DEFAULT}
            serviceActiveFlag={defaultDetails.serviceActive}
            isDisabled={false}
            handleServiceTypeActiveChange={handleServiceTypeActiveChange}
          />
          <Grid item xs={3}>
            <>
              <Grid item xs={12}>
                <Typography className={classes.formControlLabel}>{`Weekly Holidays`}</Typography>
              </Grid>
              <Grid item xs={12} className={classes.defaultHolidayGridItem}>
                <MultiSelectBox name="holidays" menuitems={DAYS_DROPDOWN_OPTIONS} value={weeklyHolidays.holidays} onChange={handleDetailsChange} />
              </Grid>
              {displayHolidays !== undefined &&
                typeof displayHolidays === "object" &&
                Object.keys(displayHolidays).length > 0 &&
                Object.keys(displayHolidays).map((itemKey: any, itemKeyIndex: any) => (
                  <Grid item xs={12} className={classes.defaultHolidayGridItem} key={`${itemKeyIndex}-${itemKey}`}>
                    <Typography className={classes.formControlLabel}>{`${displayHolidays[itemKey] || "-"}`}</Typography>
                  </Grid>
                ))}
            </>
          </Grid>
          <Grid item xs={3}>
            <>
              <Grid item xs={12}>
                <Typography className={classes.formControlLabel}>{`Calendar Holidays`}</Typography>
              </Grid>
              <Grid item xs={12} className={classes.defaultHolidayGridItem}>
                <Grid container justifyContent="flex-start" spacing={1}>
                  <Grid item xs={10}>
                    <CountryDefaultTypeDatePicker classes={classes} defaultDateState={defaultDateState} handleDateChangeEvent={handleDateChangeEvent} handleCancel={handleCancel} />
                  </Grid>
                  <Grid item xs={2} className={classes.addCalendarDateIconGridItem}>
                    <AddCircleOutlineIcon className={classes.addCalendarDateIconStyle} onClick={(e) => handleCalendarHoliday(e, "ADD", defaultDateState.dateLabel)} />
                  </Grid>
                </Grid>
              </Grid>
              {calendarHoliday !== undefined &&
                typeof calendarHoliday === "object" &&
                Object.keys(calendarHoliday).length > 0 &&
                Object.keys(calendarHoliday).map((calendarKey: any, calendarKeyIndex: any) => (
                  <Grid container spacing={1} key={`${calendarKeyIndex}-${calendarKey}-${calendarHoliday[calendarKey]?.dateLabel}`}>
                    <Grid item xs={12} className={classes.defaultCustomHolidayCalendarGridItem}>
                      <DeleteSlotIcon className={classes.deleteCalendarIconStyle} onClick={(e: any) => handleCalendarHoliday(e, "REMOVE", calendarHoliday[calendarKey]?.dateLabel)} />
                      <Typography className={classes.formControlLabel}>{`${calendarHoliday[calendarKey]?.dateLabel || "-"}`}</Typography>
                    </Grid>
                  </Grid>
                ))}
            </>
          </Grid>
          <Grid item xs={3}>
            {""}
          </Grid>
          <Grid item xs={3}>
            <PlasticBagconfigurationViewUtils classes={classes} bagChargeEnabled={defaultDetails.bagChargeEnabled} handleBagChargeChange={handleBagChargeChange} name={SERVICE_TYPES.DEFAULT} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default React.memo(RenderDefaultTypeDetails);
