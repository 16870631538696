import { makeStyles } from "@material-ui/core";

const slotsGeneratorFormStyles = makeStyles(() => ({
  whiteBackground: { marginTop: "12px", backgroundColor: "#FFFFFF !important" },
  greyBackground: { marginTop: "12px", backgroundColor: "#F9F9F9" },
  error: {
    marginTop: "5px",
    fontSize: "12px",
    color: "red",
  },
  fieldLabel: { marginBottom: "8px" },
  slotsTableLabel: {
    fontWeight: 600,
  },
  orderCutOffHeader: {
    paddingLeft: "24px",
  },
  hideShrinkedLabel: {
    "&.MuiInputLabel-outlined": {
      color: "#B2B2B2",
    },
    "&.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0)",
    },
  },
  selectClass: {
    "&.hasLabel": {
      "& .MuiSelect-root": {
        paddingTop: 16,
        paddingBottom: 14,
      },
    },
  },
  expandedStyle: {
    "&.MuiAccordionSummary-content": {
      "&.Mui-expanded": {
        margin: "0px",
      },
    },
  },
  slotsGeneratorAccordionSummary: {
    padding: "0px 24px",
  },
  daysInput: {
    padding: "16px 0px",
  },
  slotsGeneratorAccordionDetails: {
    padding: "0px 24px",
    display: "block",
  },
  buttons: {
    marginTop: "24px",
    alignItems: "center",
    marginBottom: "16px",
  },
  generateSlotsButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  separation: {
    borderTop: "1px solid #D4D4D4",
    marginBottom: "16px",
  },
  fontSize16: {
    fontSize: "16px",
  },
  slotDays: {
    fontWeight: 600,
    textTransform: "capitalize",
  },
  generatedSlotsTable: {
    margin: "12px 0px",
  },
  slotsGeneratorForm: {
    marginTop: "16px",
  },
  buttonStyle: {
    width: "100%",
    maxWidth: "212px",
    "&:last-child": {
      marginLeft: "12px",
    },
  },
}));

export default slotsGeneratorFormStyles;
