import React from "react";

import { TableFooter, TablePagination, TableRow } from "@material-ui/core";

interface CustomTableFooterInterface {
  rowsData: any;
  classes: any;
  colSpan: any;
  pageSize: any;
  rowCount: any;
  page: any;
  fetchNextRecord: any;
}

const CustomTableFooter = (props: CustomTableFooterInterface) => {
  const { rowsData, classes, colSpan, pageSize, rowCount, page, fetchNextRecord } = props;

  return (
    <TableFooter>
      <TableRow>
        {rowsData && Array.isArray(rowsData) && rowsData.length > 0 && (
          <TablePagination
            className={classes.tablePaginationStyle}
            colSpan={colSpan}
            rowsPerPageOptions={[pageSize]}
            count={rowCount}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={(event, pageNumber) => {
              fetchNextRecord && fetchNextRecord(event, pageNumber);
            }}
          />
        )}
      </TableRow>
    </TableFooter>
  );
};

export default React.memo(CustomTableFooter);
