import { DeliveryFeeHeaderDataListInterface } from "./DeliveryFeeInterface";

export const CREATE_NEW_CONFIGURATION_BUTTON = "Create New Configuration";
export const HEADER_TEXT = "Delivery Fees Configurations";

export const TABLE_BODY_NO_DATA_AVAILABLE = "No Delivery Fee Record found.";

export const DELIVERY_FEE_SHIPMENT_LEVEL = "Delivery Fee - Shipment Level ";
export const SHIPMENT_LEVEL_DELIVERY_FEE = "Shipment Level Delivery Fee";
export const DELIVERY_FEE_FOR_MULTI_SHIPMENT_LEVEL = "Delivery Fee For Multishipment";
export const SHIPMENT_LEVEL_CONFIGURATION = "Shipment level configuration";

export const DELIVERY_FEE_MULTI_SHIPMENT_LEVEL = "Delivery Fee - Multishipment";
export const POS_ZONE_MAPPING = "POS-Zone Mapping";

export const SERVICE_TYPE = "Service Type";

export const SHIPMENT_LEVEL_DUMMY_DATA = [
  { id: 1, shipment: "STANDARD", multi_shipment: false },
  { id: 2, shipment: "EXPRESS", multi_shipment: false },
  { id: 3, shipment: "DEFAULT", multi_shipment: false },
  { id: 4, shipment: "EXPRESS-STANDARD", multi_shipment: true },
  { id: 5, shipment: "DEFAULT-EXPRESS", multi_shipment: true },
  { id: 6, shipment: "DEFAULT-STANDARD", multi_shipment: true },
  { id: 7, shipment: "DEFAULT-EXPRESS-STANDARD", multi_shipment: true },
];

export const TOTAL_AREAS = "Total Pos";
export const ASSIGNED_AREAS = "Assigned Pos";
export const ERROR_CREATE_CONFIG = "Error while creating configuration";
export const NO_MESSAGE_AVAILABLE = "No message available";

export const STANDARD_SERVICE_TYPE = "STANDARD";
export const ADD_CONFIGURATION_TEXT = "Sub Configurations for Delivery Fee";
export const CAPACITY_CONFIGURATION_TEXT = "Capacity Configurations";
export const EXPRESS_SERVICE_TYPE = "EXPRESS";
export const ADD_EXPRESS_SERVICE_FEE_TEXT = "Express Service Fee Configurations";

export const DELIVERY_FEE_BOX_TITLE = "Delivery Fees";
export const SERVICE_FEE_BOX_TITLE = "Service Fees";
export const M_O_V_THRESHOLD_BOX_TITLE = "Minimum Order Value Threshold";
export const PREFERRED_SLOT_FEES_BOX_TITLE = "Preferred Slot Fees";

export const CLICK_N_COLLECT = "CLICK_N_COLLECT";

export const THRESHOLD_ZERO_ERROR = "Threshold cannot be less than or equal to zero";
export const VALID_THRESHOLD_ERROR = "Enter valid Threshold";
export const VALID_ABOVE_THRESHOLD_ERROR = "Enter valid Above Threshold";
export const VALID_BELOW_THRESHOLD_ERROR = "Enter valid Below Threshold";
export const VALID_THRESHOLD_AMOUNT_ERROR = "Enter valid Threshold amount";
export const VALID_SERVICE_FEE_ERROR = "Enter valid service fee";
export const VALID_DYNAMIC_SERVICE_FEE_ERROR = "Enter valid dynamic service fee";
export const VALID_DYNAMIC_SERVICE_FEE_PERCENTAGE_ERROR = "Enter valid dynamic fee percentage";
export const VALID_DYNAMIC_SERVICE_FEE_MIN_MAX_ERROR = "Enter valid dynamic min/max value";
export const VALID_DYNAMIC_SERVICE_FEE_MIN_ERROR = "Enter valid dynamic min value";
export const VALID_DYNAMIC_SERVICE_FEE_MAX_ERROR = "Enter valid dynamic max value";
export const VALID_DYNAMIC_SERVICE_FEE_MIN_MAX_VALUE_ERROR = "Max value cannot be less than/equal to min value.";
export const VALID_MOV_THRESHOLD_ERROR = "Enter valid Minimum Order Value Threshold";
export const VALID_PREFERRED_SLOT_LOW_CAPACITY_THRESHOLD_ERROR = "Enter valid preferred slot low capacity";
export const VALID_PREFERRED_SLOT_HIGH_CAPACITY_THRESHOLD_ERROR = "Enter valid preferred slot high capacity";
export const VALID_PREFERRED_SLOT_DELIVERY_FEE_ERROR = "Enter valid preferred slot Delivery Fees";

export const DELIVER_FEE_ACTIVE_FLAG_ERROR = "Please enter delivery fee configuration.";
export const SERVICE_FEE_ACTIVE_FLAG_ERROR = "Please enter service fee configuration.";
export const MOV_THRESHOLD_ACTIVE_FLAG_ERROR = "Please enter minimum order value threshold configuration.";

export const MINIMUM_ORDER_VALUE_THRESHOLD = "Minimum Order Value Threshold";
export const USE_THIS_VALUE_IN_CALCULATION = "Use this value in calculation";

export const DELIVERY_FEE_TEXT_CONSTANT = {
  PROMISE_FEE: "promiseSlotFee",
  LESS_THAN_THRESHOLD_VALUE: "Less than (Threshold Value)",
  DELIVERY_FEES_BELOW_THRESHOLD: "Delivery Fees Below Threshold",
  STATUS: "Status",
  GREATER_THAN_OR_EQUALS_TO_THRESHOLD_VALUE: "Greater than or Equals to (Threshold Value)",
  DELIVERY_FEES_ABOVE_THRESHOLD: "Delivery Fees Above Threshold",
  SERVICE_FEE_CONFIGURATION: "Service Fee configuration",
  DELIVERY_FEES: "Delivery Fees",
  FEE_PERCENTAGE: "Fee Percentage",
  MIN_VALUE: "Min Value",
  MAX_VALUE: "Max Value",
  RANGE_START_GREATER_THAN_OR_EQUAL_TO: "Range Start (Greater Than or Equal To)",
  RANGE_END_LESS_THAN_: "Range End (Less Than)",
  PREFERED_FEE_DROP_DOWN_INERVAL_VALUE: 5,
  RANGE_CLOSURE_VALUE_REQUIRED: "Preferred Slot fee range closure (end value 100) is required",
  SERVICE_FEES: "Service Fees",
  PREFERRED_SLOT_FEES: "Preferred Slot Fees",
  MINIMUM_ORDER_VALUE_THRESHOLD: "Minimum Order Value Threshold",
};
export const STANDARD_SERVICE_TYPE_NUMBER = 1;
export const CREATE_TIME_BASED_CONFIGURATIONS = "Create Time Based Configurations";
