import moment from "moment";

import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@material-ui/core";

import { ExpandMoreAccordianIcon } from "../../common/App/AppViewUtils";
import { HH_MM_SS } from "config/constants";

interface RenderTemplateSlotsInterface {
  classes: any;
  dayWiseSlots: any;
}

export const RenderTemplateSlots = (props: RenderTemplateSlotsInterface) => {
  const { classes, dayWiseSlots } = props;

  let isExpressTypeOnDemand = false;

  // if (serviceTypeName === SERVICE_TYPES.EXPRESS) {
  //   isExpressTypeOnDemand = getExpressType === EXPRESS_TYPES.ON_DEMAND.value;
  // }

  const calculateOrderCutOff = (slot: any) => {
    const { bufferTime, startTime } = slot;
    let orderCutOff = moment(startTime, HH_MM_SS).subtract(bufferTime, "m").format("HH:mm");

    return orderCutOff || "";
  };

  return (
    <>
      {dayWiseSlots !== undefined && typeof dayWiseSlots === "object" && Object.keys(dayWiseSlots).length > 0 ? (
        Object.keys(dayWiseSlots).map((dayWiseSlot) => (
          <Accordion key={dayWiseSlot} className={classes.slotsAccordion} onClick={(e) => e.stopPropagation()}>
            <AccordionSummary expandIcon={<ExpandMoreAccordianIcon />} className={classes.slotsAccordionSummary}>
              <div className={classes.fieldTitle}>
                <span className={classes.fontWeight500}>Slots for : </span>
                <span className={classes.dayLabel}>{`${dayWiseSlot.toLowerCase()} (Capacity - ${
                  dayWiseSlots !== undefined &&
                  dayWiseSlots[dayWiseSlot] !== undefined &&
                  Array.isArray(dayWiseSlots[dayWiseSlot]) &&
                  dayWiseSlots[dayWiseSlot].length > 0 &&
                  dayWiseSlots[dayWiseSlot][0] !== undefined &&
                  dayWiseSlots[dayWiseSlot][0].maximum_slot_capacity !== undefined
                    ? dayWiseSlots[dayWiseSlot][0].maximum_slot_capacity
                    : "0"
                })`}</span>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.dBlock}>
              <Grid container>
                <Grid item xs={8}>
                  <Grid container spacing={1} className={classes.slotsContainer}>
                    <Grid item xs={3} className={classes.slotsTableHeader}>
                      Slots
                    </Grid>
                    <Grid item xs={3} className={classes.slotsTableHeader}>
                      Capacity
                    </Grid>
                    {!isExpressTypeOnDemand && (
                      <Grid item xs={3} className={classes.slotsTableHeader}>
                        Buffer Time
                      </Grid>
                    )}
                    <Grid item xs={3} className={classes.slotsTableHeader}>
                      Order Cut-Off
                    </Grid>
                  </Grid>

                  {dayWiseSlots[dayWiseSlot].map((slot: any, index: any) => (
                    <div key={slot.id} className={classes.slotDivider}>
                      <Grid container spacing={1} className={classes.slotsContainer} style={{ fontSize: "12px" }}>
                        <Grid item xs={3}>
                          {`${slot.startTime.substr(0, 5)} - ${slot.endTime.substr(0, 5)}`}
                        </Grid>
                        <Grid item xs={3}>
                          {slot.slotCapacity}
                        </Grid>
                        {!isExpressTypeOnDemand && (
                          <Grid item xs={3}>
                            {`${slot.bufferTime} mins`}
                          </Grid>
                        )}
                        <Grid item xs={3}>
                          {calculateOrderCutOff(slot)}
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <></>
      )}
    </>
  );
};
