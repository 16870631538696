import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@material-ui/core";

import { userStyles } from "./Styles/DashboardPageStyles";
import { DashboardPageInitialState, DashboardPageInitialStateInterface } from "./Utils/DashboardPageInterface";
import { DASHBOARD_CONSTANT } from "./Utils/DashboardPageConstants";
import { fetchPosZoneServiceTypeListByCountryIdAndServiceType, fetchSlotUtilizationRecord, getAdvanceDayCalculate } from "./Redux/DashboardPageSlice";
import { GetNoDataPresentAfterFiltereApplied } from "../common/App/AppViewUtils";
import { ALERT_TYPES } from "../../config/constants";
import { isDesignPosZoneListParamsValid, isGetSlotUtilizationRecordParamsValid } from "./Utils/response.transform";
import { isNonEmptyArray, isNull, objectContainsKey } from "../../utils/DataUtils";

import * as DashboardPageUtils from "./Utils/DashboardPageUtils";

import Loader from "../common/Loader/Loader";
import DashboardPageHeader from "./Common/DashboardPageHeader";
import CustomAlert from "../common/CustomAlert/CustomAlert";
import CustomSlotUtilizationDashboard from "./Common/CustomSlotUtilizationDashboard";

const DashboardPage = () => {
  const classes = userStyles();
  const appDispatch = useDispatch();

  const { currentCountry } = useSelector((state: any) => state.appConfig);
  const { loading, error, posZoneRecord, slotUtilization, advanceDaySize } = useSelector((state: any) => state.dashboardPage);

  const [appStateObj, setAppStateObj] = useState<DashboardPageInitialStateInterface>({ ...DashboardPageInitialState });

  function countryErrorToast(message: string) {
    CustomAlert(ALERT_TYPES.ERROR, message);
  }

  const fetchAdvanceDayCalculate = () => {
    if (objectContainsKey(currentCountry, "countryId")) {
      appDispatch(getAdvanceDayCalculate(currentCountry.countryId));
    } else {
      countryErrorToast(`${DASHBOARD_CONSTANT.COUNTRY_DATA_NOT_FOUND} ${DASHBOARD_CONSTANT.PLEASE_TRY_AGAIN}`);
    }
  };

  const getSlotUtilizationRecord = (newAppStateObj: DashboardPageInitialStateInterface) => {
    if (isGetSlotUtilizationRecordParamsValid(currentCountry, newAppStateObj)) {
      const requestPayload = { currentCountryId: currentCountry.id, payload: { slotDate: newAppStateObj.slotDateLabel, posNos: [...newAppStateObj.posNos], zoneNames: [...newAppStateObj.zoneNames] } };
      appDispatch(fetchSlotUtilizationRecord(requestPayload));
    } else {
      let appStateObjCopy: DashboardPageInitialStateInterface = DashboardPageUtils.handleCloseLoaderUtils(newAppStateObj);
      setAppStateObj(appStateObjCopy);
    }
  };

  const fetchPosZoneList = () => {
    if (currentCountry && currentCountry.countryId) {
      const requestPayload = { countryId: currentCountry.countryId, pageSize: DASHBOARD_CONSTANT.PAGE_SIZE, pageNumber: 0, serviceType: DASHBOARD_CONSTANT.SLOT_UTILIZATION_DASHBOARD_SERVICE_TYPE };
      appDispatch(fetchPosZoneServiceTypeListByCountryIdAndServiceType(requestPayload));
    } else {
      countryErrorToast(`${DASHBOARD_CONSTANT.COUNTRY_DATA_NOT_FOUND} ${DASHBOARD_CONSTANT.PLEASE_TRY_AGAIN}`);
    }
  };

  const designPosZoneList = () => {
    let newAppStateObj: DashboardPageInitialStateInterface = DashboardPageUtils.designPosZoneListUtils(appStateObj, posZoneRecord);
    setAppStateObj(newAppStateObj);
    if (isDesignPosZoneListParamsValid(newAppStateObj)) {
      getSlotUtilizationRecord(newAppStateObj);
    }
  };

  const designSlotUtilizationRecord = () => {
    let newAppStateObj: DashboardPageInitialStateInterface = DashboardPageUtils.designSlotUtilizationRecordtUtils(appStateObj, slotUtilization);
    setAppStateObj(newAppStateObj);
  };

  const handleClearAllFilters = () => {
    let newAppStateObj = DashboardPageUtils.handleResetAppStatetUtil(appStateObj);
    setAppStateObj(newAppStateObj);
    getSlotUtilizationRecord(newAppStateObj);
  };

  const handleDateChangeEvent = (event: any, picker: any) => {
    let newAppStateObj = DashboardPageUtils.handleDateChangeEventUtil(appStateObj, event, picker);
    setAppStateObj(newAppStateObj);
    getSlotUtilizationRecord(newAppStateObj);
  };

  const handleCancel = (event: any, picker: any) => {
    event.preventDefault();
    picker.element.val("");
    setAppStateObj({ ...appStateObj, slotDate: "", slotDateLabel: "" });
  };

  const handleMultiSelectBoxChange = (event: any, name: any) => {
    if (!isNull(name)) {
      let newAppStateObj = DashboardPageUtils.handleMultiSelectBoxChangeUtil(appStateObj, event, name);
      setAppStateObj(newAppStateObj);
      getSlotUtilizationRecord(newAppStateObj);
    }
  };

  useEffect(() => {
    slotUtilization && designSlotUtilizationRecord();
  }, [slotUtilization]);

  useEffect(() => {
    if (error || error?.message) {
      setAppStateObj({ ...appStateObj, loading: false, posList: [], zoneList: [] });
      CustomAlert(ALERT_TYPES.ERROR, error?.message || DASHBOARD_CONSTANT.ERROR_MESSAGE);
    }
  }, [error]);

  useEffect(() => {
    posZoneRecord && designPosZoneList();
  }, [posZoneRecord]);

  useEffect(() => {
    setAppStateObj({ ...appStateObj, loading: true });
    fetchPosZoneList();
    fetchAdvanceDayCalculate();
  }, [currentCountry && currentCountry.countryId]);

  return (
    <div className={classes.container}>
      <DashboardPageHeader
        key={`${advanceDaySize}`}
        classes={classes}
        appStateObj={appStateObj}
        handleClearAllFilters={handleClearAllFilters}
        handleDateChangeEvent={handleDateChangeEvent}
        handleCancel={handleCancel}
        handleMultiSelectBoxChange={handleMultiSelectBoxChange}
        advanceDaySize={advanceDaySize}
      />
      {appStateObj.loading || loading ? (
        <div className={classes.loaderDiv}>
          <Loader />
        </div>
      ) : isNonEmptyArray(appStateObj.slotList) ? (
        <CustomSlotUtilizationDashboard classes={classes} appStateObj={appStateObj} />
      ) : (
        <Grid className={classes.noDataRowStyle}>
          <GetNoDataPresentAfterFiltereApplied classes={classes} />
        </Grid>
      )}
    </div>
  );
};

export default React.memo(DashboardPage);
