import { makeStyles } from "@material-ui/core";

import { COLORS } from "../../../config/theme/theme";

const basicInformationStyles = makeStyles(() => ({
  accordion: {
    backgroundColor: COLORS.WHITE,
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    marginTop: "16px",
  },
  fieldTitle: {
    color: COLORS.TEXT_PRIMARY,
    marginBottom: "4px",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "17px",
  },
  fieldData: {
    fontWeight: 600,
    fontSize: "14px",
  },
  accordionSummary: {
    color: COLORS.DARK_GRAY,
    fontSize: "18px",
    fontWeight: 600,
    borderBottom: "1px solid #E6E6E6",
    padding: "14px 24px",
  },
  dFlex: {
    display: "flex",
  },
  accordionDetails: {
    margin: "0px",
    padding: "14px 24px",
    minHeight: "100px",
    position: "relative",
  },
  primaryInfoTitle: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#2D2D2D",
  },
  informationContainer: {
    marginTop: "16px",
  },
  horizontalLine: {
    borderBottom: "1px solid #E6E6E6",
    padding: "0px 0px",
    margin: "12px 0px",
    width: "100%",
  },
}));

export default basicInformationStyles;
