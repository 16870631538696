import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../config/theme/theme";

const statusButtonStyle = {
  margin: 0,
  padding: "4px",
  width: "95%",
  height: "auto",
  border: "1px solid",
  borderRadius: "4px",
  fontWeight: 600,
  fontSize: "10px",
  lineHeight: "12px",
};

export const userStyles = makeStyles(() =>
  createStyles({
    container: {
      marginTop: "80px",
      padding: "24px 12px 24px 24px",
    },
    headerRoot: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "24px",
    },
    header: {
      display: "flex",
      justifyContent: "flex-start",
    },
    clearFilterHeader: {
      display: "flex",
      justifyContent: "flex-end",
      margin: "auto 0px",
    },
    heading: {
      fontWeight: 600,
      fontSize: "24px",
      color: COLORS.DARK_GRAY,
    },
    clearFilterSpanStyle: {
      fontWeight: 600,
      fontSize: "14px",
      color: COLORS.DOS_BLUE_DARK,
      cursor: "pointer",
    },
    loaderDiv: {
      height: "calc(75vh)",
    },
    // Table Style
    root: {
      background: COLORS.WHITE,
      borderRadius: "3px 3px 0px 0px",
      borderColor: "transparent",
    },
    tableDivStyle: {
      margin: 0,
      padding: 0,
      width: "100%",
      height: "calc(100vh - 203px)",
      background: "#FFFFFF",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      overflow: "auto",
    },
    tableStyle: {
      height: "calc(100vh - 250px)",
    },
    tableHeaderStyle: {
      width: "auto",
      height: "auto",
    },
    cellHeaderStyle: {
      width: "auto",
      position: "sticky",
      top: 0,
      left: 0,
      backgroundColor: COLORS.ATHENS_GRAY,
      padding: "12px 0px 0px 8px",
      margin: 0,
      color: COLORS.EMPEROR,
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: "14px",
      border: "none",
      zIndex: 0,
      height: "65px",
      "&.MuiTableCell-paddingNone:last-child": {
        padding: "12px 12px 0px 8px",
      },
      "&.MuiTableCell-paddingNone": {
        padding: "12px 0px 0px 8px",
      },
      "&.MuiTableCell-sizeSmall": {
        padding: "12px 0px 0px 8px",
      },
      "&.MuiTableCell-root": {
        padding: "12px 0px 0px 8px",
        color: COLORS.EMPEROR,
        border: "none",
      },
    },
    // cellPosNoStyle: {
    //   minWidth: "10%",
    //   width: "10%",
    //   maxWidth: "10%",
    //   wordBreak: "break-word",
    // },
    // cellPosNameStyle: {
    //   minWidth: "10%",
    //   width: "10%",
    //   maxWidth: "10%",
    //   wordBreak: "break-word",
    // },
    // cellZoneNoStyle: {
    //   minWidth: "10%",
    //   width: "10%",
    //   maxWidth: "10%",
    //   wordBreak: "break-word",
    // },
    // cellServiceTypeStyle: {
    //   minWidth: "10%",
    //   width: "10%",
    //   maxWidth: "10%",
    //   wordBreak: "break-word",
    // },
    // cellRequesterStyle: {
    //   minWidth: "10%",
    //   width: "10%",
    //   maxWidth: "10%",
    //   wordBreak: "break-word",
    // },
    // cellRequesteDateStyle: {
    //   minWidth: "10%",
    //   width: "10%",
    //   maxWidth: "10%",
    //   wordBreak: "break-word",
    // },
    // cellImplementationDateStyle: {
    //   minWidth: "10%",
    //   width: "10%",
    //   maxWidth: "10%",
    //   wordBreak: "break-word",
    // },
    // cellDaysPendingStyle: {
    //   minWidth: "10%",
    //   width: "10%",
    //   maxWidth: "10%",
    //   wordBreak: "break-word",
    // },
    // cellApprovalStatusStyle: {
    //   minWidth: "10%",
    //   width: "10%",
    //   maxWidth: "10%",
    //   wordBreak: "break-word",
    // },
    // cellActionStyle: {
    //   minWidth: "5%",
    //   width: "5%",
    //   maxWidth: "5%",
    // },
    cellDataStyle: {
      width: "auto",
      minWidth: "88px",
      height: "40px",
      verticalAlign: "middle",
      color: COLORS.MINE_SHAFT,
      fontWeight: 400,
      fontSize: "12px",
      border: "none",
      borderBottom: `1px solid ${COLORS.MERCURY}`,
      padding: "8px 0px 0px 8px",
      "&.MuiTableCell-paddingNone:last-child": {
        padding: "8px 8px 0px 8px",
      },
      "&.MuiTableCell-paddingNone": {
        padding: "8px 0px 0px 8px",
      },
      "&.MuiTableCell-sizeSmall": {
        padding: "8px 0px 0px 8px",
      },
      "&.MuiTableCell-root": {
        padding: "8px 0px 0px 8px",
        color: COLORS.MINE_SHAFT,
        border: "none",
        borderBottom: `1px solid ${COLORS.MERCURY}`,
        verticalAlign: "middle",
      },
    },
    approvedStatusStyle: {
      ...statusButtonStyle,
      background: COLORS.LIGHT_GREEN_BACKGROUND,
      color: COLORS.DARK_GREEN_BACKGROUND,
      borderColor: COLORS.LIGHT_GREEN_BACKGROUND,
      textAlign: "center",
    },
    rejectedStatusStyle: {
      ...statusButtonStyle,
      background: COLORS.LIGHT_RED_BACKGROUND,
      color: COLORS.DARK_RED,
      borderColor: COLORS.LIGHT_RED_BACKGROUND,
      textAlign: "center",
    },
    pendingStatusStyle: {
      ...statusButtonStyle,
      background: COLORS.GIVRY,
      color: COLORS.PUMPKIN_SKIN,
      borderColor: COLORS.GIVRY,
      textAlign: "center",
    },
    editIconStyle: {
      width: "100%",
      height: "100%",
      cursor: "pointer",
      textAlign: "center",
    },
    noDataRowStyle: {
      width: "auto",
      height: "calc(100vh - 315px)",
      verticalAlign: "middle",
      textAlign: "center",
      borderRadius: "8px",
      border: "none",
      boxShadow: "none",
      "&.MuiTableCell-root": {
        textAlign: "center",
      },
    },
    //
    //
    //
    //
    //
    //
    //
    //
    tablePaginationStyle: {
      position: "fixed",
      bottom: 0,
      justifyContent: "flex-end",
      width: "calc(100vw - 110px)",
      marginBottom: "24px",
      marginLeft: "-2px",
      background: "#E6E6E6",
      borderRadius: "0px 0px 8px 8px",
      minHeight: "54px",
      maxHeight: "54px",
    },
    //
    //
    //
    //
    //
    //
    //
    headerStyle: {
      background: "rgba(0, 0, 0, 0.04)",
      margin: 0,
      padding: 0,
      minHeight: "60px",
      "& .MuiDataGrid-root": {
        margin: 0,
        padding: 0,
      },
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        margin: 0,
        padding: 0,
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        lineHeight: "normal",
        textOverflow: "clip",
        whiteSpace: "normal",
        fontWeight: 600,
      },
    },
    configDetailsMainDiv: {
      margin: 0,
      marginTop: "24px",
      padding: "8px",
    },
    footerButtonMainDiv: {
      margin: 0,
      marginTop: "24px",
      padding: 0,
    },
    cancelConfigStyle: {
      width: "212px",
      background: "#FFFFFF",
      border: "1px solid #0E5AA7",
      borderRadius: "4px",
      color: "#0E5AA7",
      textDecoration: "none",
      marginRight: "24px",
      "&:hover": {
        background: "#FFFFFF",
        border: "1px solid #0E5AA7",
      },
      "&.MuiButton-label": {
        background: "#FFFFFF",
        border: "1px solid #0E5AA7",
        "&:hover": {
          background: "#FFFFFF",
          border: "1px solid #0E5AA7",
        },
      },
    },
    configApproverRequestDetailsMainDiv: {
      margin: 0,
      marginTop: "24px",
      padding: 0,
      boxShadow: "none",
    },
    serviceTypeSpanDivStyle: {
      margin: 0,
      marginBottom: "16px",
      padding: 0,
    },
    serviceTypeSpanStyle: {
      fontWeight: 800,
      fontSize: "14px",
      color: COLORS.DOS_BLUE_DARK,
    },
    approverDetailsMainHeadingDivStyle: {
      margin: 0,
      marginBottom: "8px",
      padding: 0,
    },
    approverDetailsHeadingDivStyle: {
      margin: 0,
      marginBottom: "4px",
      padding: 0,
    },
    approverDetailsHeadingSpanStyle: {
      fontWeight: 400,
      fontSize: "14px",
      color: COLORS.DARK_GRAY,
    },
    approverDetailsHeadingValueOriginalSpanStyle: {
      fontWeight: 600,
      fontSize: "16px",
      color: COLORS.DARK_GREEN_BACKGROUND,
    },
    approverDetailsHeadingValueRequestedSpanStyle: {
      fontWeight: 600,
      fontSize: "16px",
      color: COLORS.DARK_GRAY,
    },
    slotsCarDivStyleRequested: {
      margin: 0,
      padding: "10px",
      backgroundColor: COLORS.PRIMARY_LIGHT,
      borderRadius: "8px",
    },
    slotsCarDivStyleOriginal: {
      margin: 0,
      padding: "10px",
      backgroundColor: COLORS.LIGHT_GREEN_BACKGROUND,
      borderRadius: "8px",
    },
    slotsCardGridStyle: {
      margin: 0,
      padding: "8px",
    },
    slotsAccordionMaindiv: {
      margin: 0,
      marginBottom: "8px",
      padding: 0,
    },
    slotsAccordion: {
      backgroundColor: COLORS.WHITE,
      borderRadius: "8px",
      marginBottom: "0px",
      padding: "0px",
    },
    slotsAccordionSummary: {
      margin: "0px !important",
      padding: "0px 24px",
      minHeight: "40px",
      height: "40px",
      color: COLORS.DARK_GRAY,
      background: COLORS.WILDSAND,
      fontSize: "14px",
      fontWeight: 600,
      borderRadius: "8px",
      "&.MuiAccordionSummary-content": {
        margin: "0px",
      },
      "&.MuiAccordionSummary-root.Mui-expanded": {
        minHeight: "40px",
        borderRadius: "8px 8px 0px 0px",
      },
    },
    dBlock: {
      display: "block",
    },
    slotDetailsValueSpanStyle: {
      margin: "auto",
      fontWeight: 400,
      fontSize: "14px",
      color: COLORS.DARK_GRAY,
    },
    approverDetailsStatusSpanStylePending: {
      fontWeight: 600,
      fontSize: "14px",
      color: "#C1720C",
    },
    approverDetailsStatusSpanStyleApproved: {
      fontWeight: 600,
      fontSize: "14px",
      color: "#007F73",
    },
    approveButtonConfigDivStyle: {
      display: "flex",
      justifyContent: "flex-end",
    },
    approveButtonConfigStyle: {
      width: "212px",
      background: COLORS.PRIMARY_MAIN,
      border: `1px solid ${COLORS.PRIMARY_MAIN}`,
      borderRadius: "4px",
      color: COLORS.WHITE,
      textDecoration: "none",
      "&:hover": {
        background: COLORS.PRIMARY_MAIN,
        border: `1px solid ${COLORS.PRIMARY_MAIN}`,
      },
      "&.MuiButton-label": {
        background: COLORS.PRIMARY_MAIN,
        border: `1px solid ${COLORS.PRIMARY_MAIN}`,
        "&:hover": {
          background: COLORS.PRIMARY_MAIN,
          border: `1px solid ${COLORS.PRIMARY_MAIN}`,
        },
      },
    },
    rejectButtonConfigStyle: {
      width: "212px",
      background: COLORS.LIGHT_RED_BACKGROUND,
      border: `1px solid ${COLORS.ALIZARIN_CRIMSON}`,
      borderRadius: "4px",
      color: COLORS.ALIZARIN_CRIMSON,
      textDecoration: "none",
      "&:hover": {
        background: COLORS.LIGHT_RED_BACKGROUND,
        border: `1px solid ${COLORS.ALIZARIN_CRIMSON}`,
      },
      "&.MuiButton-label": {
        background: COLORS.LIGHT_RED_BACKGROUND,
        border: `1px solid ${COLORS.ALIZARIN_CRIMSON}`,
        "&:hover": {
          background: COLORS.LIGHT_RED_BACKGROUND,
          border: `1px solid ${COLORS.ALIZARIN_CRIMSON}`,
        },
      },
    },
    approverDetailsStatusSpanStyleRejected: {
      fontWeight: 600,
      fontSize: "14px",
      color: COLORS.DARK_RED,
    },
    approverDetailsHeadingValueSpanStyle: {
      fontWeight: 600,
      fontSize: "14px",
      color: COLORS.DARK_GRAY,
    },
    approverDetailsMainSlotHeadingDivStyle: {
      margin: 0,
      padding: 0,
      textAlign: "center",
    },
    filteredListSpanstyle: {
      margin: "auto 4px",
      padding: "4px",
      background: COLORS.DOS_BLUE_LIGHT,
      color: COLORS.DOS_BLUE_DARK,
      borderRadius: "4px",
      fontSize: "12px",
    },
    deletedSlot: {
      backgroundColor: COLORS.WEWAK,
      minHeight: "35px",
      border: "none",
      borderTop: "1px solid",
      borderColor: COLORS.MERCURY_LIGHT,
    },
    addedSlot: {
      backgroundColor: COLORS.LIGHT_GREEN_BACKGROUND,
      minHeight: "35px",
      border: "none",
      borderTop: "1px solid",
      borderColor: COLORS.MERCURY_LIGHT,
    },
    updatededSlot: {
      backgroundColor: COLORS.GIVRY,
      minHeight: "35px",
      border: "none",
      borderTop: "1px solid",
      borderColor: COLORS.MERCURY_LIGHT,
    },
    /**
     * PreviewApprovalRequestPopUp
     */
    cardStyle: {
      margin: "24px auto 24px auto",
      padding: "24px",
      height: "96vh",
      width: "90vw",
      border: "none",
      borderRadius: "12px",
      overflow: "auto",
    },
    cardContentStyle: {
      margin: 0,
      padding: 0,
      "&:last-child": {
        margin: 0,
        padding: 0,
      },
    },
    headerGridItemStyle: {
      margin: 0,
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
    },
    headerTitleGridItemStyle: {
      margin: 0,
      padding: 0,
      display: "flex",
      justifyContent: "flex-start",
    },
    headerSpanStyle: {
      fontSize: "24px",
      fontWeight: 600,
      color: COLORS.BLACK,
    },
    headerDaySpanStyle: {
      margin: "0pc 0px 0pc 8px",
      padding: 0,
      fontSize: "24px",
      fontWeight: 600,
      color: COLORS.DOS_BLUE_DARK,
    },
    closeButtonIconStyle: {
      margin: "auto 0px",
      padding: 0,
      width: "24px",
      height: "24px",
      cursor: "pointer",
    },
    popupCardBodyGrid: {
      margin: "16px 0px 0px 0px",
      padding: 0,
      height: "calc(64vh)",
      overflow: "hidden",
    },
    popupCardFooterGrid: {
      margin: "24px 0px 0px 0px",
      padding: 0,
    },
    popupCardMessageGridSection: {
      margin: "16px 0px 0px 0px",
      padding: 0,
      overflow: "auto",
    },
    // Comment
    accordionStyle: {},
    accordionSummaryStyle: {
      padding: "8px 16px",
    },
    accordionTitle: {
      fontSize: "16px",
      fontWeight: 600,
    },
    accordionTitleNoteStyle: {
      fontSize: "12px",
      fontWeight: 400,
    },
    accordionDetailsStyle: {
      display: "block",
      padding: "0px 16px 16px 16px",
    },
    slotBoxGridContainerStyle: {
      margin: "8px 0px 0px 0px",
      padding: 0,
    },
    slotsGridContainerDetailsStyleOnPopUp: {
      maxHeight: "calc(58vh)",
      overflow: "auto",
    },
    slotsGridContainerDetailsStyle: {
      maxHeight: "calc(51vh)",
      overflow: "auto",
    },
    slotsGridContainerDetailsStyleWithMessage: {
      maxHeight: "calc(46vh)",
      overflow: "auto",
    },
    slotBoxGridItemStyle: {
      margin: 0,
      padding: "0px 4px 0px 0px",
    },
    messageGridItemStyle: {
      margin: "16px 0px 0px 0px",
      padding: 0,
    },
    cellHeaderSpanStyle: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "17px",
      color: COLORS.TEXT_PRIMARY,
    },
    cellDataSpanStyle: {
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "17px",
      color: COLORS.TEXT_PRIMARY,
    },
    commonApproverDetailsHeadingSpanStyle: {
      fontWeight: 400,
      fontSize: "12px",
      color: COLORS.DARK_GRAY,
    },
  })
);
