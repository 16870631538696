import React from "react";

import PropTypes from "prop-types";
import moment from "moment";

// Material UI Components
import Grid from "@material-ui/core/Grid";

import { ReactComponent as CloseButton } from "../../../assets/svg/closeButton.svg";

// Styles
import viewQuotaSettingsStyles from "./ViewQuotaSettingsStyles";

const ViewQuotaSettings = ({ handleClose, startTime, slotTime, capacity, bufferTime, noOfQuotas, quotas }) => {
  const classes = viewQuotaSettingsStyles();

  const calculateOrderCutOffTime = (cutOffTimeInMinues) => {
    let totalMinutes = cutOffTimeInMinues + bufferTime;
    let orderCutOffTime = moment(startTime).subtract(totalMinutes, "m").format("HH:mm");

    return orderCutOffTime;
  };

  return (
    <div className={classes.viewQuotaSettings}>
      <div className={classes.title}>View Quota</div>
      <div className={classes.closeButton}>
        <CloseButton className={classes.closeButtonSVG} onClick={handleClose} />
      </div>
      <div className={classes.slotDetails}>
        <Grid container>
          <Grid item xs={3}>
            <div className={classes.fieldLabel}>Slot</div>
            <div className={classes.fieldData}>{slotTime}</div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.fieldLabel}>Total Capacity</div>
            <div className={classes.fieldData}>{capacity}</div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.fieldLabel}>Buffer Time</div>
            <div className={classes.fieldData}>{`${bufferTime} mins`}</div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.fieldLabel}>Number of Quotas</div>
            <div className={classes.fieldData}>{noOfQuotas}</div>
          </Grid>
        </Grid>
      </div>

      <div className={classes.quotaDetailsContainer}>
        {quotas.map((quota) => (
          <div key={quota.quotaName} className={classes.quotaContainer}>
            <div className={classes.quotaName}>{quota.quotaName}</div>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <div className={classes.fieldLabel}>Order Cut-off Time</div>
                <div className={classes.fieldData}>{`${calculateOrderCutOffTime(quota.quotaCutOffTime * 60)} (${
                  quota.quotaCutOffTime
                } hrs before order cut-off)`}</div>
              </Grid>
              <Grid item xs={3}>
                <div className={classes.fieldLabel}>Capactiy</div>
                <div className={classes.fieldData}>{`${Math.floor((quota.quotaCapacityPercentage * capacity) / 100)} (${
                  quota.quotaCapacityPercentage
                }%)`}</div>
              </Grid>
            </Grid>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewQuotaSettings;

ViewQuotaSettings.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  slotTime: PropTypes.string.isRequired,
  capacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  bufferTime: PropTypes.number.isRequired,
  noOfQuotas: PropTypes.number.isRequired,
  quotas: PropTypes.arrayOf(
    PropTypes.shape({
      quotaName: PropTypes.string.isRequired,
      quotaCutOffTime: PropTypes.string.isRequired,
      quotaCapacityPercentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
};
