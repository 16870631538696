import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../../config/theme/theme";

const BUTTON_WIDTH = "100px";

export const userStyles = makeStyles(() =>
  createStyles({
    cardContainer: {
      margin: "30vh auto 0px auto",
      padding: 0,
      width: "calc(50vw)",
      maxHeight: "calc(55vh)",
      overflow: "auto",
      border: "none",
      borderRadius: "12px",
      boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.15)",
      "&:focus-visible": {
        border: "none",
        outline: "none",
      },
    },
    cardContentStyle: {
      margin: 0,
      padding: 0,
      "&:last-child": {
        margin: 0,
        padding: 0,
      },
    },
    gridContainerMain: {
      margin: 0,
      padding: "24px 8px 24px 24px",
    },
    gridItemTitleDivStyle: {
      margin: 0,
      padding: 0,
    },
    gridItemServiceableDivStyle: {
      margin: 0,
      padding: 0,
      display: "flex",
      justifyContent: "flex-end",
    },
    headerSpanStyle: {
      fontWeight: 600,
      fontSize: "16px",
      color: COLORS.MINE_SHAFT_LIGHT,
    },
    checkboxLabel: {
      fontWeight: 600,
      fontSize: "14px",
      color: COLORS.EMPEROR,
      margin: "auto 0px auto 8px",
    },
    hiddenSearchIcon: {
      opacity: "0",
    },
    headersearchIconstyle: {
      opacity: 1,
    },
    searchBoxStyle: {
      width: "100%",
      background: COLORS.WHITE,
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
    },
    // Footer
    gridContainerFooter: {
      margin: 0,
      padding: "24px 24px 16px 24px",
      display: "flex",
      justifyContent: "flex-end",
    },
    cancelButtonStyle: {
      width: BUTTON_WIDTH,
      background: COLORS.WHITE,
      border: "1px solid",
      borderColor: COLORS.DOS_BLUE_DARK,
      borderRadius: "12px",
      color: COLORS.DOS_BLUE_DARK,
      textDecoration: "none",
      marginRight: "24px",
      "&:hover": {
        background: COLORS.WHITE,
        border: "1px solid",
        borderColor: COLORS.DOS_BLUE_DARK,
      },
      "&.MuiButton-label": {
        background: COLORS.WHITE,
        border: "1px solid",
        borderColor: COLORS.DOS_BLUE_DARK,
        "&:hover": {
          background: COLORS.WHITE,
          border: "1px solid",
          borderColor: COLORS.DOS_BLUE_DARK,
        },
      },
    },
    saveButtonStyle: {
      width: BUTTON_WIDTH,
      background: COLORS.DOS_BLUE_DARK,
      border: "1px solid",
      borderRadius: "12px",
      color: COLORS.WHITE,
      textDecoration: "none",
      "&:hover": {
        background: COLORS.DOS_BLUE_DARK,
        border: "1px solid",
        borderColor: COLORS.DOS_BLUE_DARK,
      },
      "&.MuiButton-label": {
        background: COLORS.DOS_BLUE_DARK,
        border: "1px solid",
        borderColor: COLORS.DOS_BLUE_DARK,
        "&:hover": {
          background: COLORS.DOS_BLUE_DARK,
          border: "1px solid",
          borderColor: COLORS.DOS_BLUE_DARK,
        },
      },
    },
    gridContainerContent: {
      margin: 0,
      padding: "0px 24px 0px 24px",
    },
    gridContainerItemStyle: {
      margin: 0,
      padding: 0,
      maxHeight: "calc(30vh)",
      overflow: "auto",
    },
    gridContainerSearchContent: {
      margin: "0px 0px 16px 0px",
      padding: 0,
    },

    tableDataRowHeaderCell: {
      padding: 0,
      paddingLeft: "12px",
      height: "36px",
      border: "1px solid",
      borderColor: COLORS.MERCURY_LIGHT,
      background: COLORS.WHITE,
      "&:last-child": {
        padding: 0,
        paddingLeft: "12px",
      },
    },
    tableDataHeaderCell: {
      margin: "auto 0",
      fontSize: "14px",
      fontWeight: 500,
      color: COLORS.TUNDORA,
    },
    noDataCellStyle: {
      padding: 0,
      margin: 0,
      height: "calc(31vh - 48px)",
      border: "1px solid",
      borderColor: COLORS.ATHENS_GRAY,
      borderRadius: "0px 0px 12px 12px",
      background: COLORS.ATHENS_GRAY,
      "&:last-child": {
        padding: 0,
      },
    },
    noDataCellGridItemStyle: {
      padding: "16px 0px 0px 0px",
      margin: 0,
    },
    noDataCellSpanStyle: {
      fontSize: "14px",
      fontWeight: 400,
      color: COLORS.MINE_SHAFT,
    },
    tableDataRowCell: {
      padding: 0,
      paddingLeft: "8px",
      height: "36px",
      fontSize: "12px",
      fontWeight: 500,
      color: COLORS.MINE_SHAFT_LIGHT,
      border: "1px solid",
      borderColor: COLORS.WHITE,
      background: COLORS.WHITE,
      "&:last-child": {
        padding: 0,
        paddingLeft: "8px",
      },
    },
    downArrowIconButtonStyle: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: COLORS.WHITE,
      },
    },
    downArrowIconStyleOpen: {
      marginLeft: "8px",
      width: "20px",
      height: "20px",
      cursor: "pointer",
      transform: "rotate(180deg)",
    },
    downArrowIconStyle: {
      marginLeft: "8px",
      width: "20px",
      height: "20px",
      cursor: "pointer",
    },
    collapsableTableDataRow: {
      cursor: "pointer",
      backgroundColor: COLORS.WHITE,
      "&.MuiTableRow-root.MuiTableRow-hover:hover": {
        backgroundColor: COLORS.DOS_BLUE_SUPER_LIGHT,
      },
      "&.MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover": {
        backgroundColor: COLORS.DOS_BLUE_LIGHT,
      },
    },
    collapsableTableDataRowCell: {
      padding: 0,
      paddingLeft: "8px",
      height: "36px",
      fontSize: "12px",
      fontWeight: 500,
      color: COLORS.MINE_SHAFT_LIGHT,
      border: "none",
      "&:last-child": {
        padding: 0,
        paddingLeft: "8px",
        border: "none",
      },
    },
    nonServiceableCard: {
      margin: 0,
      padding: "24px",
      boxShadow: "none",
    },
    nonServiceableCardContent: {
      padding: 0,
      margin: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "calc(36vh - 48px)",
      fontSize: "14px",
      fontWeight: 400,
      color: COLORS.MINE_SHAFT,
      border: "1px solid",
      borderColor: COLORS.ATHENS_GRAY,
      borderRadius: "0px 0px 12px 12px",
      background: COLORS.ATHENS_GRAY,
      "&:last-child": {
        padding: 0,
      },
    },
    polygonNonServiceableSpanStyle: {
      color: COLORS.FROLY,
      fontWeight: 500,
      fontSize: "14px",
    },
  })
);
