import React from "react";

import { Grid, FormControl, FormControlLabel, FormGroup, Typography, Checkbox, Box } from "@material-ui/core";

import { isEmptyOrNull } from "../../../../utils/DataUtils";
import { FOOD_SUB_TYPES, PRODUCT_TYPES } from "../../../../config/constants";
import { exclusionMessage, subExclusionMessage } from "../../../../utils/MessageUtils";
import { foodSplitRegex } from "../../utils/constants";

import productTypeStyles from "./ProductTypeStyles";

const ProductType = ({ isProductTypeDisabled, foodType, foodSubType, error, onFoodTypeChange, onSubFoodTypeChange }) => {
  const selectedFoodType = isEmptyOrNull(foodType) ? [] : foodType.split(foodSplitRegex);
  let selectedSubFoodType = isEmptyOrNull(foodSubType) ? [] : foodSubType.split(foodSplitRegex);
  const classes = productTypeStyles();

  const handleFoodTypeChange = (event) => {
    const { value, checked } = event.target;
    onFoodTypeChange(value, checked);
  };

  const handleSubFoodTypeChange = (event, checked) => {
    const { value } = event.target;
    if (!checked) {
      selectedSubFoodType = selectedSubFoodType.filter((item) => item !== value);
    } else {
      selectedSubFoodType.push(value);
    }

    onSubFoodTypeChange(selectedSubFoodType.join(foodSplitRegex));
  };

  return (
    <Box className={classes.accordionDetails}>
      <>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography className={classes.accordionHeadingTitleStyle}>{`${exclusionMessage}`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.accordionSubHeadingTitleStyle}>{subExclusionMessage}</Typography>
          </Grid>
        </Grid>
        <FormControl component="fieldset">
          <FormGroup>
            <div className={classes.dFlex}>
              <FormControlLabel
                control={<Checkbox checked={selectedFoodType.includes(PRODUCT_TYPES.FOOD) && selectedSubFoodType.length > 0} onChange={handleFoodTypeChange} value={PRODUCT_TYPES.FOOD} />}
                label={<Typography className={classes.checkboxLabel}>Food</Typography>}
              />
              {selectedFoodType.includes(PRODUCT_TYPES.FOOD) ? (
                <div className={classes.dFlex}>
                  <div className={classes.vertical} />
                  {FOOD_SUB_TYPES.map((subType) => (
                    <FormControlLabel
                      key={subType.value}
                      className={classes.foodSubTypeCheckbox}
                      control={<Checkbox name={subType.name} value={subType.value} checked={!!selectedSubFoodType.includes(subType.value)} onChange={handleSubFoodTypeChange} />}
                      label={<Typography className={classes.checkboxLabel}>{subType.label}</Typography>}
                    />
                  ))}
                </div>
              ) : null}
            </div>
            <FormControlLabel
              className={classes.nonFood}
              control={<Checkbox checked={selectedFoodType.includes(PRODUCT_TYPES.NON_FOOD)} onChange={handleFoodTypeChange} value={PRODUCT_TYPES.NON_FOOD} />}
              label={<Typography className={classes.checkboxLabel}>Non Food</Typography>}
            />
          </FormGroup>
        </FormControl>
        {error && isEmptyOrNull(foodType) && <div className={classes.error}>{error}</div>}
      </>
    </Box>
  );
};

export default React.memo(ProductType);
