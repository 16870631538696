import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ADDITIONAL_SERVICES, APP_ROUTES, USER_TYPES } from "../../config/constants";

interface CreateNewConfigButtonProps {
  classes: any;
  userInfo: any;
}

export const canChangeRuleEngineConfig = (userInfo: any) => {
  if (userInfo && userInfo.userProfile && userInfo.userProfile.role && userInfo.userProfile.role !== "") {
    if (userInfo?.userProfile?.role === USER_TYPES.READONLY_USER || userInfo?.userProfile?.role === USER_TYPES.APPROVER || userInfo?.userProfile?.role === USER_TYPES.ADMIN) {
      return false;
    }
    return true;
  }
  return false;
};

export const CreateNewConfigButton = (props: CreateNewConfigButtonProps) => {
  const { classes, userInfo } = props;

  return canChangeRuleEngineConfig(userInfo) ? (
    <Grid item xs={2}>
      <Link className={classes.dFlexEnd} to={APP_ROUTES.RULE_ENGINE}>
        CREATE ENGINE CONFIGURATION
      </Link>
    </Grid>
  ) : null;
};
