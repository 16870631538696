import { makeStyles } from "@material-ui/core";

const templateSlotStyles = makeStyles(() => ({
  templateSlotContainer: {
    marginBottom: "10px",
    alignItems: "center",
  },
  checkboxLabel: {
    fontSize: "12px",
    paddingLeft: "8px",
  },
  capacityInput: { paddingRight: "24px" },
  hideShrinkedLabel: {
    "&.MuiInputLabel-outlined": {
      color: "#B2B2B2",
    },
    "&.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0)",
    },
  },
  selectClass: {
    "&.hasLabel": {
      "& .MuiSelect-root": {
        paddingTop: 5,
        paddingBottom: 5,
      },
    },
  },
  orderCutOff: {
    paddingLeft: "24px",
  },
}));

export default templateSlotStyles;
