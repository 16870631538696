import React from "react";

import { Collapse, Grid, IconButton, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";

import DownArrowIcon from "../../../assets/svg/DownArrow.svg";
import ZoomOut from "../../../assets/svg/ZoomOut.svg";
import { capitalizeFirstLetter } from "../../../../utils/DataUtils";

interface PosZoneMappingDialogBodyInterface {
  classes: any;
  posZoneServiceRecord: any;
  updatePosNumberOpenIndex: any;
  selectedPosZoneIndex: any;
  selectPosZoneForMapping: any;
}

const PosZoneMappingDialogBody = (props: PosZoneMappingDialogBodyInterface) => {
  const { classes, posZoneServiceRecord, updatePosNumberOpenIndex, selectedPosZoneIndex, selectPosZoneForMapping } = props;

  return (
    <TableBody>
      {posZoneServiceRecord !== undefined && Array.isArray(posZoneServiceRecord) && posZoneServiceRecord.length > 0 ? (
        posZoneServiceRecord.map((posList: any, posListIndex: any) => (
          <>
            <TableRow key={`main-table-row-posZoneServiceRecord-${posListIndex}`}>
              <TableCell align="left" padding="none" size="small" width="20%" className={classes.tableDataRowCell}>
                {posList.id}
                <IconButton
                  disableRipple={true}
                  disableFocusRipple={true}
                  size="small"
                  className={classes.downArrowIconButtonStyle}
                  onClick={() => {
                    updatePosNumberOpenIndex(posList.id, posListIndex);
                  }}
                >
                  {posList.isOpened ? (
                    <img src={DownArrowIcon} alt="DropdownIcon" className={classes.downArrowIconStyleOpen} />
                  ) : (
                    <img src={DownArrowIcon} alt="DropdownIcon" className={classes.downArrowIconStyle} />
                  )}
                </IconButton>
              </TableCell>
              <TableCell align="left" padding="none" size="small" width="35%" className={classes.tableDataRowCell}>
                {posList.posName}
              </TableCell>
              <TableCell align="left" padding="none" size="small" width="20%" className={classes.tableDataRowCell}>
                {posList.zoneTypeListRecord}
              </TableCell>
              <TableCell align="left" padding="none" size="small" width="25%" className={classes.tableDataRowCell}>
                {"-"}
              </TableCell>
            </TableRow>
            {posList.isOpened && (
              <TableRow>
                <TableCell className={classes.collapseTableCell} colSpan={4}>
                  <Collapse in={posList.isOpened} timeout="auto" unmountOnExit className={classes.collapseTableCellDiv}>
                    <Table padding="none" size="small" stickyHeader={true}>
                      <TableBody>
                        {posList.zoneServicetypeList && Array.isArray(posList.zoneServicetypeList) && posList.zoneServicetypeList.length > 0 ? (
                          posList.zoneServicetypeList.map((posData: any, posDataIndex: any) => (
                            <TableRow
                              key={`${posDataIndex}-posList.zoneServicetypeList-${posDataIndex}`}
                              className={classes.collapsableTableDataRow}
                              hover={true}
                              selected={posData?.zoneId === selectedPosZoneIndex ? true : false}
                              onClick={() => selectPosZoneForMapping(posList.id, posListIndex, posData.zoneId, posDataIndex)}
                            >
                              <TableCell align="center" padding="none" size="small" width="20%" className={classes.collapsableTableDataRowCell}>
                                {posList.id}
                              </TableCell>
                              <TableCell align="left" padding="none" size="small" width="35%" className={classes.collapsableTableDataRowCell}>
                                {posData.posName}
                              </TableCell>
                              <TableCell align="left" padding="none" size="small" width="20%" className={classes.collapsableTableDataRowCell}>
                                {posData.zoneName}
                              </TableCell>
                              <TableCell align="left" padding="none" size="small" width="25%" className={classes.collapsableTableDataRowCell}>
                                {capitalizeFirstLetter(posData.status) || ""}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow key={`zoneServicetypeList-${posListIndex}-no-data`}>
                            <TableCell colSpan={4}>No data available</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </Collapse>
                </TableCell>
              </TableRow>
            )}
          </>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={4} padding="none" align="center" className={classes.noDataCellStyle}>
            <Grid container>
              <Grid item xs={12}>
                <img src={ZoomOut} alt="Edit" />
              </Grid>
              <Grid item xs={12} className={classes.noDataCellGridItemStyle}>
                <Typography className={classes.noDataCellSpanStyle}> {`No search results available.`}</Typography>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default React.memo(PosZoneMappingDialogBody);
