import { React } from "react";

import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const styles = makeStyles({
  root: {
    fontWeight: 600,
  },
});

const PageTitle = ({ title = "" }) => {
  const classes = styles();

  return (
    <Typography variant="h5" className={classes.root}>
      {title}
    </Typography>
  );
};

export default PageTitle;
