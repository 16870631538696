import produce from "immer";
import moment from "moment";

import { ALERT_TYPES, API_RESPONSE_STATUS } from "../config/constants";
import { isObjectEmptyOrNull } from "./DataUtils";

import CustomAlert from "../components/common/CustomAlert/CustomAlert";

export const getKeyNameFromZoneTab = (tab: any, keyName: any) => {
  if (tab !== undefined) {
    if (tab.zoneConfig !== undefined && tab.zoneConfig[keyName] !== undefined) {
      return tab.zoneConfig[keyName];
    } else if (tab.zone !== undefined && tab.zone.zoneConfig !== undefined && tab.zone.zoneConfig[keyName] !== undefined) {
      return tab.zone.zoneConfig[keyName];
    }
  }
  return "";
};

export const getBufferCapacityConfigKeyNameFromZoneTab = (tab: any, keyName: any) => {
  if (tab !== undefined) {
    if (tab.zone !== undefined && tab.zone.bufferCapacityConfig !== undefined && tab.zone.bufferCapacityConfig[keyName] !== undefined && tab.zone.bufferCapacityConfig[keyName] !== "") {
      return tab.zone.bufferCapacityConfig[keyName];
    }
  }
  return "";
};

export const getDateKeValueFromZoneTab = (tab: any, keyName: any, momentFormat?: any, requestedFormat?: any) => {
  if (tab !== undefined) {
    if (tab.zoneConfig !== undefined && tab.zoneConfig[keyName] !== undefined) {
      return moment(tab.zoneConfig[keyName], momentFormat);
    } else if (tab.zone !== undefined && tab.zone.zoneConfig !== undefined && tab.zone.zoneConfig[keyName] !== undefined) {
      return moment(tab.zone.zoneConfig[keyName], momentFormat);
    }
  }
  return null;
};

export const is_request_already_presnt_by_admin_util = (configTabs: any, serviceState: any, pendingApprovalRequestRecordListService: any, posDetails: any) => {
  let error_message = "";
  if (
    configTabs !== undefined &&
    Array.isArray(configTabs) &&
    configTabs.length > 0 &&
    serviceState !== undefined &&
    serviceState.selectedConfigTab !== undefined &&
    configTabs[serviceState.selectedConfigTab] !== undefined &&
    configTabs[serviceState.selectedConfigTab].zoneName !== undefined &&
    configTabs[serviceState.selectedConfigTab].zoneName !== "" &&
    pendingApprovalRequestRecordListService !== undefined &&
    pendingApprovalRequestRecordListService.fetchStatus !== undefined &&
    pendingApprovalRequestRecordListService.fetchStatus === API_RESPONSE_STATUS.SUCCEEDED &&
    pendingApprovalRequestRecordListService.data !== undefined &&
    Array.isArray(pendingApprovalRequestRecordListService.data) &&
    pendingApprovalRequestRecordListService.data.length > 0
  ) {
    let obj = pendingApprovalRequestRecordListService.data.find((request: any) => request.posNo === posDetails?.posNo && request.zoneName === configTabs[serviceState.selectedConfigTab].zoneName);
    if (obj !== undefined && obj.requestId !== undefined && obj.requestId !== "") {
      error_message = `Note : A request ${obj.requestId} is already raised for this POS : ${posDetails?.posNo || "POS"} and Zone : ${
        configTabs[serviceState.selectedConfigTab].zoneName || "Zone"
      } by ${obj.requester || "Admin"}.`;
    }
  }
  return error_message;
};

export const is_request_already_present = (date_data: any, serviceState: any, pendingApprovalRequestRecordListService: any, posNo: any, configTabs: any, serviceTypeName: any) => {
  let obj = {} as any;
  if (
    configTabs !== undefined &&
    serviceState !== undefined &&
    serviceState.selectedConfigTab !== undefined &&
    configTabs[serviceState.selectedConfigTab] !== undefined &&
    configTabs[serviceState.selectedConfigTab].zoneName !== undefined &&
    pendingApprovalRequestRecordListService !== undefined &&
    pendingApprovalRequestRecordListService.fetchStatus !== undefined &&
    pendingApprovalRequestRecordListService.fetchStatus === API_RESPONSE_STATUS.SUCCEEDED &&
    pendingApprovalRequestRecordListService.data !== undefined &&
    Array.isArray(pendingApprovalRequestRecordListService.data) &&
    pendingApprovalRequestRecordListService.data.length > 0
  ) {
    if (date_data !== undefined && date_data !== "") {
      obj = pendingApprovalRequestRecordListService.data.find(
        (request: any) =>
          request.activationDate === date_data &&
          request.posNo === posNo &&
          request.zoneName === configTabs[serviceState.selectedConfigTab].zoneName &&
          request?.serviceType.toLowerCase() === serviceTypeName.toLowerCase()
      );
      if (obj !== undefined && obj.requestId !== undefined && obj.requestId !== "") {
        let error_message = `A request ${obj.requestId} is already raised for this implementation date by ${obj.requester || "Admin"}. Please request action on the same.`;
        CustomAlert(ALERT_TYPES.ERROR, error_message);
      }
    }
  }
  return obj;
};

const getDateFromPicker = (dateData: any) => {
  return moment(dateData).format("YYYY-MM-DD");
};

export const _handle_date_change_event_util = (serviceState: any, event: any, picker: any, pendingApprovalRequestRecordListService: any, posNo: any, configTabs: any, serviceTypeName: any) => {
  let newServiceState: any = produce(serviceState, (draft: any) => {
    draft.loading = false;
    if (event && event.target) {
      if (event.target.name === "date") {
        const dateStart = getDateFromPicker(picker.startDate);
        let isDateValid = false;
        let data_present_obj = {};
        if (moment(dateStart).isSameOrAfter(moment(new Date()).format("YYYY-MM-DD"))) {
          data_present_obj = is_request_already_present(dateStart, serviceState, pendingApprovalRequestRecordListService, posNo, configTabs, serviceTypeName);
          if (isObjectEmptyOrNull(data_present_obj)) {
            isDateValid = true;
          }
        } else {
          CustomAlert(ALERT_TYPES.ERROR, "Please provide a valid date");
        }
        draft.activationDate = dateStart;
        draft.activationDatelabel = dateStart;
        draft.isDateValid = isDateValid;
        draft.data_present_obj = data_present_obj;
      }
    }
  });
  return newServiceState;
};

export const reset_bulk_update_configuration_for_given_day = (configTab: any, tabIndex: any, generatorFormIndex: any, day: any, serviceState: any) => {
  let new_config_tab: any = produce(configTab, (draft: any) => {
    if (
      serviceState !== undefined &&
      serviceState.selectedConfigTab !== undefined &&
      serviceState.selectedConfigTab >= 0 &&
      serviceState.originalConfigTab !== undefined &&
      Array.isArray(serviceState.originalConfigTab) &&
      serviceState.originalConfigTab.length > 0 &&
      serviceState.originalConfigTab[serviceState.selectedConfigTab] !== undefined &&
      serviceState.originalConfigTab[serviceState.selectedConfigTab].slotsGeneratorForms !== undefined &&
      Array.isArray(serviceState.originalConfigTab[serviceState.selectedConfigTab].slotsGeneratorForms) &&
      serviceState.originalConfigTab[serviceState.selectedConfigTab].slotsGeneratorForms.length > 0 &&
      serviceState.originalConfigTab[serviceState.selectedConfigTab].slotsGeneratorForms[generatorFormIndex] !== undefined &&
      serviceState.originalConfigTab[serviceState.selectedConfigTab].slotsGeneratorForms[generatorFormIndex].days !== undefined &&
      Array.isArray(serviceState.originalConfigTab[serviceState.selectedConfigTab].slotsGeneratorForms[generatorFormIndex].days) &&
      serviceState.originalConfigTab[serviceState.selectedConfigTab].slotsGeneratorForms[generatorFormIndex].days[0] !== undefined &&
      serviceState.originalConfigTab[serviceState.selectedConfigTab].slotsGeneratorForms[generatorFormIndex].days[0] === day
    ) {
      const original_data = serviceState.originalConfigTab[serviceState.selectedConfigTab].slotsGeneratorForms[generatorFormIndex];
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex] = {};
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex] = original_data;
    }
  });
  return new_config_tab;
};

export const reset_bulk_update_config_day = (serviceState: any, day: any) => {
  let newServiceState: any = produce(serviceState, (draft: any) => {
    if (
      draft !== undefined &&
      draft.configurationBulkUpdatedObj !== undefined &&
      draft.configurationBulkUpdatedObj.bulkUpdatedDays !== undefined &&
      Array.isArray(draft.configurationBulkUpdatedObj.bulkUpdatedDays) &&
      draft.configurationBulkUpdatedObj.bulkUpdatedDays.length > 0
    ) {
      let selectedDaysCopy = [...draft.configurationBulkUpdatedObj.bulkUpdatedDays];
      if (selectedDaysCopy !== undefined && Array.isArray(selectedDaysCopy) && selectedDaysCopy.length >= 0) {
        const isElementPresent = selectedDaysCopy.indexOf(day);
        if (isElementPresent > -1) {
          selectedDaysCopy.splice(isElementPresent, 1);
          draft.configurationBulkUpdatedObj.bulkUpdatedDays = [...selectedDaysCopy];
        }
      }
    }
  });
  return newServiceState;
};
