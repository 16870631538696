import { createStyles, Theme } from "@material-ui/core/styles";

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(3),
      paddingBottom: 0,
    },
    closeButton: {
      position: "absolute",
      right: 0,
      top: theme.spacing(0.5),
      padding: theme.spacing(3),
    },
  });
