import React, { useState } from "react";

import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import LinkButton from "../../../common/LinkButton/LinkButton";
import OnDemandSlotInEditMode from "./OnDemandSlotInEditMode/OnDemandSlotInEditMode";

import { ReactComponent as BulkUpdateInfoIcon } from "../../../assets/svg/BulkUpdateInfoIcon.svg";

import editOnDemandSlotsStyles from "./EditOnDemandSlotsStyles";

const EditOnDemandSlots = ({
  tabIndex,
  formIndex,
  days,
  generatedSlots,
  dayWiseSlots,
  handleOnDemandServiceTimeChange,
  handleIndividualSlotFieldsChange,
  handleCustomOnDemandSlotFieldsChange,
  updateOnDemandSlot,
  addNewOnDemandSlot,
  handleSaveNewOnDemandSlot,
  configurationBulkUpdatedObj,
  handleBulkUpdateAction,
  resetCurrentConfigurationAfterBulkUpdateRevert,
  loading,
  userInfo,
  makeSlotEditable,
  deleteTemplateSlot,
  cancelEditingASlot,
}) => {
  const classes = editOnDemandSlotsStyles();

  const renderOnDemandSlots = () => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography className={classes.slotsHeaderSpanStyle}>First customer order time</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.slotsHeaderSpanStyle}> Last order cut-off time</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.slotsHeaderSpanStyle}>Capacity</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.slotsHeaderSpanStyle}>Actions</Typography>
          </Grid>
        </Grid>

        <div className={classes.slotsContainer}>
          {generatedSlots !== undefined && Array.isArray(generatedSlots) && generatedSlots.length > 0 ? (
            generatedSlots.map(
              (slot, index) =>
                slot !== undefined &&
                slot.slotAction !== undefined &&
                slot.slotAction !== "" &&
                slot.slotAction !== "DELETE" && (
                  <OnDemandSlotInEditMode
                    userInfo={userInfo}
                    tabIndex={tabIndex}
                    formIndex={formIndex}
                    slotIndex={index}
                    slot={slot}
                    handleOnDemandServiceTimeChange={handleOnDemandServiceTimeChange}
                    handleIndividualSlotFieldsChange={handleIndividualSlotFieldsChange}
                    loading={loading}
                    canSlotBeDeleted={!!(generatedSlots && generatedSlots.length !== 1)}
                    configurationBulkUpdatedObj={configurationBulkUpdatedObj}
                    generatedSlots={generatedSlots}
                    makeSlotEditable={(slot) => makeSlotEditable(index, slot)}
                    saveNewOnDemandSlot={handleSaveNewOnDemandSlot}
                    updateOnDemandSlot={updateOnDemandSlot}
                    handleDeleteTemplateSlot={(slot) => deleteTemplateSlot(index, slot)}
                    cancelEditingASlot={(slot) => cancelEditingASlot(index, slot)}
                  />
                )
            )
          ) : (
            <></>
          )}
        </div>
      </>
    );
  };

  return (
    <Accordion defaultExpanded={false} className={classes.whiteBackground}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.slotsAccordion}>
        <Grid container>
          <Grid item xs={6}>
            <div className={classes.dayDivStyle}>
              <Typography className={classes.slotforSpanStyle}>{`Slots for : `} </Typography>
              <Typography className={classes.dayStyles}>{days[0].toLowerCase()}</Typography>
            </div>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.generatedSlotsAccordionDetails}>
        <Grid item xs={12} className={classes.generatedSlotsInfoGridItem}>
          {generatedSlots !== undefined && Array.isArray(generatedSlots) && generatedSlots.length > 0 ? renderOnDemandSlots() : <></>}
        </Grid>

        <Grid item xs={12} className={classes.generatedSlotsInfoGridItem}>
          <LinkButton buttonLabel="Add New Slot" onClick={() => addNewOnDemandSlot(tabIndex, formIndex, days)} />
        </Grid>

        {generatedSlots !== undefined && generatedSlots.length > 0 ? (
          configurationBulkUpdatedObj !== undefined &&
          configurationBulkUpdatedObj.bulkUpdatedDays !== undefined &&
          Array.isArray(configurationBulkUpdatedObj.bulkUpdatedDays) &&
          configurationBulkUpdatedObj.bulkUpdatedDays.length == 0 ? (
            <Grid item xs={12} className={classes.bulkUpdateItemGridMain}>
              <BulkUpdateInfoIcon className={classes.bulkUpdateInfoIconStyle} />
              <Typography className={classes.bulkUpdateInfoSpanStyle}>{`You can now duplicate the above configuration for other days as well`}</Typography>
              <Typography className={classes.bulkUpdateLinkSpanStyle} onClick={handleBulkUpdateAction}>{`Click here to bulk update`}</Typography>
            </Grid>
          ) : configurationBulkUpdatedObj !== undefined &&
            configurationBulkUpdatedObj.bulkUpdatedDays !== undefined &&
            Array.isArray(configurationBulkUpdatedObj.bulkUpdatedDays) &&
            configurationBulkUpdatedObj.bulkUpdatedDays.length > 0 &&
            resetCurrentConfigurationAfterBulkUpdateRevert !== undefined &&
            days !== undefined &&
            Array.isArray(days) &&
            days.length !== undefined &&
            days[0] !== undefined &&
            days[0] !== "" &&
            configurationBulkUpdatedObj.bulkUpdatedDays.indexOf(days[0]) > -1 ? (
            <Grid item xs={12} className={classes.bulkUpdateRevertItemGridMain}>
              <Typography className={classes.bulkUpdateRevertSpanStyle}>{`Configuration for this days is changed in bulk. Want to revert back ?`}</Typography>
              <Typography className={classes.bulkUpdateRevertLinkSpanStyle} onClick={() => resetCurrentConfigurationAfterBulkUpdateRevert(days[0])}>{`Click here to revert back`}</Typography>
            </Grid>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(EditOnDemandSlots);
