import React from "react";

import { Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, Typography } from "@material-ui/core";
import { APPROVAL_STATUS, ApprovalRecordFilterPopUpStateInterface, POS_NAME } from "./ApprovalRequestConstant";

import searchIcon from "../../assets/svg/SearchIcon.svg";
import ZoomOut from "../../assets/svg/ZoomOut.svg";

import TextBox from "components/common/TextBox";

interface ApprovalRequestPosNameFilterRecordViewInterface {
  classes: any;
  appFilterPopUpObj: ApprovalRecordFilterPopUpStateInterface;
  handlePosNoNameSearchTextChange: any;
  headerPosNoRowCheckboxOnChange: any;
  anchorName: any;
}

const ApprovalRequestPosNameFilterRecordView = (props: ApprovalRequestPosNameFilterRecordViewInterface) => {
  const { classes, appFilterPopUpObj, handlePosNoNameSearchTextChange, headerPosNoRowCheckboxOnChange, anchorName } = props;

  return (
    <>
      <TextBox
        textBoxId="searchTextBox"
        placeholderText={`${anchorName !== undefined && anchorName !== "" && anchorName === APPROVAL_STATUS ? "Search by Approval Status" : "Search by POS No. or Name"}`}
        endAdornment={
          <InputAdornment position="end">
            <IconButton edge="end" size="small">
              <img src={searchIcon} alt="search" onClick={undefined} />
            </IconButton>
          </InputAdornment>
        }
        value={appFilterPopUpObj.posNoNameSearchText}
        name="posZoneSearchText"
        handleChange={handlePosNoNameSearchTextChange}
        className={classes.searchBoxGridDiv}
      />
      {appFilterPopUpObj !== undefined &&
      appFilterPopUpObj.posNoNameFilteredRecord !== undefined &&
      Array.isArray(appFilterPopUpObj.posNoNameFilteredRecord) &&
      appFilterPopUpObj.posNoNameFilteredRecord.length > 0 ? (
        appFilterPopUpObj.posNoNameFilteredRecord.map(
          (listData: any, listDataIndex: any) =>
            listData !== undefined &&
            listData.hide_row !== undefined &&
            listData.hide_row === false && (
              <Grid container spacing={1} key={`${listDataIndex}-listData-posNoNameFilteredRecord`} className={classes.listMainGridStyle}>
                <Grid item className={classes.listItemGridStyle}>
                  <FormControlLabel
                    key={`listData-${listDataIndex}-checkbox`}
                    control={
                      <Checkbox
                        name={"listDataCheckbox"}
                        checked={listData !== undefined && listData.isChecked !== undefined && listData.isChecked ? true : false}
                        onChange={(event: any) => headerPosNoRowCheckboxOnChange(event, listDataIndex, anchorName)}
                      />
                    }
                    label={""}
                  />
                </Grid>
                {anchorName === APPROVAL_STATUS ? (
                  <Grid item className={classes.listItemGridStyle}>
                    <Typography className={classes.listItemSpanStyle}>{listData?.name || "-"}</Typography>
                  </Grid>
                ) : (
                  <>
                    <Grid item className={classes.listItemGridStyle}>
                      <Typography className={classes.listItemSpanStyle}>{listData?.posNo || "-"}</Typography>
                    </Grid>
                    <Grid item className={classes.listItemGridStyle}>
                      <Typography className={classes.listItemSpanStyle}>{listData?.posName || "-"}</Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            )
        )
      ) : (
        <div className={classes.noDataRowStyle}>
          <Grid container>
            <Grid item xs={12}>
              <img src={ZoomOut} alt="Edit" />
            </Grid>
            <Grid item xs={12}>
              {appFilterPopUpObj !== undefined && appFilterPopUpObj.posNoNameSearchText !== undefined && appFilterPopUpObj.posNoNameSearchText !== "" ? (
                <Typography> {`No search results available`}</Typography>
              ) : (
                <Typography> {`No POS details found`}</Typography>
              )}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default ApprovalRequestPosNameFilterRecordView;
