import React from "react";

import { Grid, Typography } from "@material-ui/core";

import { SERVICE_FEE_BOX_TITLE } from "../../../Utils/DeliveryFeeConstants";
import { ChangeConfigOtion } from "../../utils/DeliveryFeeConfigViewUtils";

import SwitchButton from "../../../../common/SwitchButton/SwitchButton";
import TextBoxWithLabel from "../../../../common/TextBoxWithLabel/TextBoxWithLabel";

interface ServiceFeeConfigurationInterface {
  classes: any;
  name: any;
  multiShipmentLevel: any;
  stateObj: any;
  isEditMode: any;
  stateLevelIndex: any;
  updateConfigPosId: any;
  updateConfigZoneName: any;
  enableThisFeature: any;
  use_existing_config_change_handler: any;
  config_change_existing_handler: any;
  _handle_service_fee_calculation_toggle_change: any;
  _handle_service_fee_static_dynamic_toggle_change: any;
  handle_static_sevice_fees_input_change: any;
  handle_dynamic_sevice_fees_input_change: any;
}

const ServiceFeeConfiguration = (props: ServiceFeeConfigurationInterface) => {
  const {
    classes,
    name,
    multiShipmentLevel,
    stateObj,
    isEditMode,
    stateLevelIndex,
    updateConfigPosId,
    updateConfigZoneName,
    enableThisFeature,
    use_existing_config_change_handler,
    config_change_existing_handler,
    _handle_service_fee_calculation_toggle_change,
    _handle_service_fee_static_dynamic_toggle_change,
    handle_static_sevice_fees_input_change,
    handle_dynamic_sevice_fees_input_change,
  } = props;

  return (
    <Grid container className={classes.dlvFeeServiceTypeDiv} key={`${name}-${stateLevelIndex}-service-Fee`}>
      <Grid container>
        <Grid item xs={4} className={classes.dlvFeeServiceTypeTextDivStyle}>
          <Typography className={classes.dlvFeeServiceTypeTextStyle}>{SERVICE_FEE_BOX_TITLE}</Typography>
        </Grid>
        <Grid item xs={4} className={classes.marginAuto}>
          {!stateObj.sameAsEarlier && (
            <Grid container justifyContent="flex-end">
              <Grid item xs={10} className={classes.useCalculationDivStyle}>
                <Typography className={classes.dlvFeeCalculationTextStyle}>Use this value in calculation</Typography>
              </Grid>
              <Grid item xs={2}>
                <SwitchButton
                  className={isEditMode && !stateObj.enableConfigEdit ? classes.disableSwitch : classes.switchStyle}
                  size="small"
                  checked={stateObj.active}
                  disabled={false}
                  handleChange={(e: any) => _handle_service_fee_calculation_toggle_change(e, multiShipmentLevel, name)}
                  leftLabel={undefined}
                  rightLabel={undefined}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={4} className={enableThisFeature ? classes.marginAuto : classes.marginAutoDisabled}>
          <Grid container justifyContent="flex-end">
            <ChangeConfigOtion
              is_edit_mode={updateConfigPosId === "" && updateConfigZoneName === "" ? false : true}
              classes={classes}
              use_existing_checked={stateObj.sameAsEarlier}
              use_existing_config_change_handler={(event: any) => use_existing_config_change_handler(event, multiShipmentLevel, name, "serviceFee", "sameAsEarlier", enableThisFeature)}
              change_existing_checked={stateObj.enableConfigEdit}
              use_change_existing_config_change_handler={(e: any) => config_change_existing_handler(e, false, name, "serviceFee", "enableConfigEdit", enableThisFeature)}
            />
          </Grid>
        </Grid>
      </Grid>

      {stateObj !== undefined && stateObj.active !== undefined && stateObj.active == true && (
        <>
          <Grid container className={classes.serviceFeeFlexStartInputMainDiv}>
            {stateObj.isDynamic ? (
              stateObj !== undefined && stateObj.dynamicFees !== undefined && Array.isArray(stateObj.dynamicFees) && stateObj.dynamicFees.length > 0 ? (
                stateObj.dynamicFees.map((dynamicObj: any, dynamicObjIndex: any) => (
                  <>
                    <Grid item xs={3} className={classes.thresholdInputDiv} key={`${dynamicObjIndex}-dynamicObjIndex-feePercentage-dynamicFees`}>
                      <TextBoxWithLabel
                        label={"Percentage of Cart Value *"}
                        name="feePercentage"
                        value={dynamicObj.feePercentage}
                        placeholderText="Percentage of Cart Value *"
                        handleChange={(e: any) => handle_dynamic_sevice_fees_input_change(e, multiShipmentLevel, name, dynamicObjIndex)}
                        type="number"
                        className={classes.rightPaddingStyle}
                        disabled={!stateObj.enableConfigEdit}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.thresholdInputDiv} key={`${dynamicObjIndex}-dynamicObjIndex-minValue-dynamicFees`}>
                      <TextBoxWithLabel
                        label={"Minimum Service Fees"}
                        name="minValue"
                        value={dynamicObj.minValue}
                        placeholderText="Minimum Service Fees"
                        handleChange={(e: any) => handle_dynamic_sevice_fees_input_change(e, multiShipmentLevel, name, dynamicObjIndex)}
                        type="number"
                        className={classes.rightPaddingStyle}
                        disabled={!stateObj.enableConfigEdit}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.thresholdInputDiv} key={`${dynamicObjIndex}-dynamicObjIndex-maxValue-dynamicFees`}>
                      <TextBoxWithLabel
                        label={"Maximum Service Fees"}
                        name="maxValue"
                        value={dynamicObj.maxValue}
                        placeholderText="Maximum Service Fees"
                        handleChange={(e: any) => handle_dynamic_sevice_fees_input_change(e, multiShipmentLevel, name, dynamicObjIndex)}
                        type="number"
                        className={classes.rightPaddingStyle}
                        disabled={!stateObj.enableConfigEdit}
                      />
                    </Grid>
                  </>
                ))
              ) : (
                <Grid item xs={3} className={classes.thresholdInputDiv}>
                  {""}
                </Grid>
              )
            ) : (
              <>
                <Grid item xs={3} className={classes.thresholdInputDiv}>
                  <TextBoxWithLabel
                    label={"Service Fees"}
                    name="staticFees"
                    value={stateObj.staticFees}
                    placeholderText="Service Fees"
                    handleChange={(e: any) => handle_static_sevice_fees_input_change(e, multiShipmentLevel, name)}
                    type="number"
                    className={classes.rightPaddingStyle}
                    disabled={!stateObj.enableConfigEdit}
                  />
                </Grid>
                <Grid item xs={6} className={classes.thresholdInputDiv}>
                  {""}
                </Grid>
              </>
            )}
            <Grid item xs={3} className={classes.dFlexEnd}>
              <SwitchButton
                className={!stateObj.enableConfigEdit ? classes.disableSwitch : classes.switchStyle}
                size="small"
                checked={stateObj.isDynamic}
                disabled={!stateObj.enableConfigEdit}
                handleChange={(e: any) => _handle_service_fee_static_dynamic_toggle_change(e, multiShipmentLevel, name)}
                leftLabel={"Static"}
                rightLabel={"Dynamic"}
              />
            </Grid>
            {stateObj !== undefined && stateObj.isErrorText !== undefined && stateObj.isErrorText !== "" && <Typography className={classes.errorMessageSpanStyle}>{stateObj.isErrorText}</Typography>}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default React.memo(ServiceFeeConfiguration);
