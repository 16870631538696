import React from "react";

import { Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";

import { ChangeConfigOtion } from "../ServiceRuleEngineUtils";
import SwitchButton from "../../../common/SwitchButton/SwitchButton";
import TextBox from "components/common/TextBox";

import { HARD_CODED_VEHICLE_TYPE_LIST } from "../ServiceRuleEngine";

interface RenderTypeOfVehicleInterface {
  classes: any;
  config: any;
  lodashGet: any;
  handleTypeOfVehicleConfigEdit: any;
  handleTypeOfVehicleIsSameAsEarlierChange: any;
  handleTypeOfVehicleRuleConfigEdit: any;
  handleVehicleTypeConfigActiveCheckbox: any;
  onTypeOfVehicleFromToFieldsChange: any;
}

const RenderTypeOfVehicle = (props: RenderTypeOfVehicleInterface) => {
  const {
    classes,
    config,
    lodashGet,
    handleTypeOfVehicleConfigEdit,
    handleTypeOfVehicleIsSameAsEarlierChange,
    handleTypeOfVehicleRuleConfigEdit,
    handleVehicleTypeConfigActiveCheckbox,
    onTypeOfVehicleFromToFieldsChange,
  } = props;
  return (
    <Grid container className={classes.ruleContainer} spacing={0}>
      <Grid item xs={9}>
        <Grid container spacing={1}>
          <Grid item xs={5} className={classes.ruleLabel}>
            Type of Vehicle
          </Grid>
          {!config.vehicleTypeRule.sameAsEarlier && (
            <>
              <Grid item xs={6} className={classes.dFlexEnd}>
                Use this value in calculation
              </Grid>
              <Grid item xs={1}>
                <SwitchButton
                  className={config.isEditMode && !config.enableVehicleTypeRuleEdit ? classes.disableSwitch : ""}
                  size="small"
                  checked={config.vehicleTypeRule.active}
                  disabled={config.isEditMode && !config.enableVehicleTypeRuleEdit}
                  handleChange={handleTypeOfVehicleConfigEdit}
                  changeExisting={config.enableVehicleTypeRuleEdit}
                  leftLabel={undefined}
                  rightLabel={undefined}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <ChangeConfigOtion
        config={config}
        checked={config.vehicleTypeRule.sameAsEarlier}
        classes={classes}
        checkChangeHandler={handleTypeOfVehicleIsSameAsEarlierChange}
        changeExisting={config.enableVehicleTypeRuleEdit}
        onConfigEdit={handleTypeOfVehicleRuleConfigEdit}
        xsValue={4}
      />
      {/* Use this value in calculation */}
      {config.vehicleTypeRule !== undefined &&
        config.vehicleTypeRule.active !== undefined &&
        config.vehicleTypeRule.active &&
        HARD_CODED_VEHICLE_TYPE_LIST !== undefined &&
        Array.isArray(HARD_CODED_VEHICLE_TYPE_LIST) &&
        HARD_CODED_VEHICLE_TYPE_LIST.length > 0 &&
        config.vehicleTypeRule !== undefined && (
          <Grid item xs={8} className={classes.ruleEditingDivStyle}>
            <Grid container spacing={1} className={classes.vehicleTypeHeaderDivStyle}>
              <Grid item xs={1}>
                {""}
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.vehicleTypeHeaderSpanStyle}>Vehicle Type</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.vehicleTypeHeaderSpanStyle}>From (Kg)</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.vehicleTypeHeaderSpanStyle}>To (Kg)</Typography>
              </Grid>
            </Grid>
            {HARD_CODED_VEHICLE_TYPE_LIST.map((vehicleValueObject, vehicleValueObjectIndex) => (
              <Grid container spacing={1} key={`${vehicleValueObjectIndex}-vehicleValueObject`}>
                <Grid item xs={1} className={classes.vehicleTypeTopBottomMargin}>
                  {vehicleValueObject.name === "BIKE" && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={classes.sameAsEarlierCheckbox}
                          checked={config.vehicleTypeRule.bikeThresholdActive}
                          onChange={(e) => handleVehicleTypeConfigActiveCheckbox(e, "BIKE")}
                          disabled={undefined}
                        />
                      }
                      label={""}
                      className={classes.formcontrolStyle}
                      disabled={config.isEditMode && !config.enableVehicleTypeRuleEdit}
                    />
                  )}

                  {vehicleValueObject.name === "CAR" && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={classes.sameAsEarlierCheckbox}
                          checked={config.vehicleTypeRule.carThresholdActive}
                          onChange={(e) => handleVehicleTypeConfigActiveCheckbox(e, "CAR")}
                          disabled={undefined}
                        />
                      }
                      label={""}
                      className={classes.formcontrolStyle}
                      disabled={config.isEditMode && !config.enableVehicleTypeRuleEdit}
                    />
                  )}

                  {vehicleValueObject.name === "OTHER" && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={classes.sameAsEarlierCheckbox}
                          checked={config.vehicleTypeRule.otherThresholdActive}
                          onChange={(e) => handleVehicleTypeConfigActiveCheckbox(e, "OTHER")}
                          disabled={undefined}
                        />
                      }
                      label={""}
                      className={classes.formcontrolStyle}
                      disabled={config.isEditMode && !config.enableVehicleTypeRuleEdit}
                    />
                  )}
                </Grid>
                <Grid item xs={3} className={classes.vehicleTypeTopBottomMargin}>
                  <Typography className={classes.vehicleTypeSpanStyle}>{`${vehicleValueObject.name}`}</Typography>
                </Grid>
                {vehicleValueObject.name === "BIKE" && config.vehicleTypeRule.bikeThresholdActive !== undefined && config.vehicleTypeRule.bikeThresholdActive && (
                  <>
                    <Grid item xs={3}>
                      <TextBox
                        fieldLabel={``}
                        name="From"
                        type="number"
                        isNegativeAllowed={false}
                        value={lodashGet(config, "vehicleTypeRule.thresholdFrom", "")}
                        handleChange={(e: any) => onTypeOfVehicleFromToFieldsChange(e, "thresholdFrom")}
                        disabled={true}
                      />
                    </Grid>
                    {config.vehicleTypeRule.bikeThresholdActive === true && config.vehicleTypeRule.carThresholdActive === false ? (
                      <></>
                    ) : (
                      <Grid item xs={3}>
                        <TextBox
                          fieldLabel={``}
                          name="To"
                          type="number"
                          isNegativeAllowed={false}
                          value={lodashGet(config, "vehicleTypeRule.bikeThresholdTo", "")}
                          handleChange={(e: any) => onTypeOfVehicleFromToFieldsChange(e, "bikeThresholdTo")}
                          disabled={config.isEditMode && !config.enableVehicleTypeRuleEdit}
                        />
                      </Grid>
                    )}
                  </>
                )}

                {vehicleValueObject.name === "CAR" && config.vehicleTypeRule.carThresholdActive !== undefined && config.vehicleTypeRule.carThresholdActive && (
                  <>
                    <Grid item xs={3}>
                      <TextBox
                        fieldLabel={``}
                        name="From"
                        type="number"
                        isNegativeAllowed={false}
                        value={lodashGet(config, "vehicleTypeRule.bikeThresholdTo", "")}
                        handleChange={(e: any) => onTypeOfVehicleFromToFieldsChange(e, "bikeThresholdTo")}
                        disabled={true}
                      />
                    </Grid>
                    {config.vehicleTypeRule.bikeThresholdActive && config.vehicleTypeRule.carThresholdActive ? (
                      <></>
                    ) : config.vehicleTypeRule.bikeThresholdActive === false && config.vehicleTypeRule.carThresholdActive === true ? (
                      <></>
                    ) : (
                      <Grid item xs={3}>
                        <TextBox
                          fieldLabel={``}
                          name="To"
                          type="number"
                          isNegativeAllowed={false}
                          value={lodashGet(config, "vehicleTypeRule.carThresholdTo", "")}
                          handleChange={(e: any) => onTypeOfVehicleFromToFieldsChange(e, "carThresholdTo")}
                          disabled={config.isEditMode && !config.enableVehicleTypeRuleEdit}
                        />
                      </Grid>
                    )}
                  </>
                )}

                {vehicleValueObject.name === "OTHER" && config.vehicleTypeRule.otherThresholdActive !== undefined && config.vehicleTypeRule.otherThresholdActive && (
                  <>
                    <Grid item xs={3}>
                      <TextBox
                        fieldLabel={``}
                        name="From"
                        type="number"
                        isNegativeAllowed={false}
                        value={lodashGet(config, "vehicleTypeRule.carThresholdTo", "")}
                        handleChange={(e: any) => onTypeOfVehicleFromToFieldsChange(e, "carThresholdTo")}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextBox
                        fieldLabel={``}
                        name="To"
                        type="number"
                        isNegativeAllowed={false}
                        value={lodashGet(config, "vehicleTypeRule.otherThresholdTo", "")}
                        handleChange={(e: any) => onTypeOfVehicleFromToFieldsChange(e, "otherThresholdTo")}
                        disabled={config.isEditMode && !config.enableVehicleTypeRuleEdit}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            ))}
          </Grid>
        )}
    </Grid>
  );
};

export default React.memo(RenderTypeOfVehicle);
