import React from "react";

import { Checkbox, Grid } from "@material-ui/core";

import { ChangeConfigOtion } from "../ServiceRuleEngineUtils";

import SwitchButton from "../../../common/SwitchButton/SwitchButton";
import SelectBox from "components/common/SelectBox/SelectBox";
import TextBox from "components/common/TextBox";
import { DEFAULT_TIME_MINUTES } from "../ServiceRuleEngine";

interface RenderWeightRuleInterface {
  classes: any;
  config: any;
  serviceName: any;
  onUseForConfigurationSwitchChange: any;
  onIsSameAsEarlierCheckboxChange: any;
  onWeightRuleConfigEdit: any;
  onWeightRuleFieldsChange: any;
}

const RenderWeightRule = (props: RenderWeightRuleInterface) => {
  const { classes, config, serviceName, onUseForConfigurationSwitchChange, onIsSameAsEarlierCheckboxChange, onWeightRuleConfigEdit, onWeightRuleFieldsChange } = props;
  return (
    <Grid container className={classes.ruleContainer} spacing={0}>
      <Grid item xs={9}>
        <Grid container spacing={1}>
          <Grid item xs={5} className={classes.ruleLabel}>
            Weight of Consignment
          </Grid>
          {!config.weightRule.sameAsEarlier && (
            <>
              <Grid item xs={6} className={classes.dFlexEnd}>
                Use this value in calculation
              </Grid>
              <Grid item xs={1}>
                <SwitchButton
                  className={config.isEditMode && !config.enableWeightRuleEdit ? classes.disableSwitch : ""}
                  size="small"
                  checked={config.weightRule.active}
                  disabled={config.isEditMode && !config.enableWeightRuleEdit}
                  handleChange={(e: any) => onUseForConfigurationSwitchChange(serviceName, "weightRule", e)}
                  leftLabel={undefined}
                  rightLabel={undefined}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <ChangeConfigOtion
        config={config}
        checked={config.weightRule.sameAsEarlier}
        changeExisting={config.enableWeightRuleEdit}
        classes={classes}
        checkChangeHandler={(e: any) => onIsSameAsEarlierCheckboxChange(serviceName, "weightRule", e)}
        onConfigEdit={onWeightRuleConfigEdit}
        xsValue={4}
      />

      {config.weightRule.active && (
        <Grid item xs={8} className={classes.ruleEditingDivStyle}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextBox
                fieldLabel="Threshold Weight Limit (Kgs)"
                name="thresholdWeight"
                type="number"
                isNegativeAllowed={false}
                value={config.weightRule.thresholdWeight}
                handleChange={(e: any) => onWeightRuleFieldsChange(serviceName, e)}
                disabled={config.isEditMode && !config.enableWeightRuleEdit}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectBox
                fieldLabel="Incremental Time Above Threshold"
                name="incrementalTime"
                label="Minutes"
                menuItems={DEFAULT_TIME_MINUTES}
                value={config.weightRule.incrementalTime}
                handleChange={(e: any) => onWeightRuleFieldsChange(serviceName, e)}
                disabled={config.isEditMode && !config.enableWeightRuleEdit}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(RenderWeightRule);
