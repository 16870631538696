import React from "react";

import { FormControl, TextField } from "@material-ui/core";

import { useStyles } from "./TextBoxWithLabelStyles";

interface CustomTextAreaInterface {
  label: any;
  name: any;
  value: any;
  placeholderText: any;
  handleChange: any;
  className?: any;
  error?: any;
  helperText?: any;
  disabled: any;
}

const CustomTextArea = (props: CustomTextAreaInterface) => {
  const { label, name, value, placeholderText, handleChange, className, helperText, disabled } = props;

  const classes = useStyles();

  const handleInputChange = (e: any) => {
    if (e.target.value < 0) {
      e.target.value = "";
      handleChange(e);
      return;
    }
    handleChange(e);
  };

  return (
    <FormControl className={classes.formControl} size="small" variant="outlined" margin="none">
      <TextField
        variant="outlined"
        fullWidth={true}
        id={name}
        label={label}
        name={name}
        value={value}
        type={"text"}
        disabled={disabled}
        placeholder={placeholderText}
        className={`${className || ""} ${label ? "hasLabel " : ""}`}
        InputProps={{
          className: classes.textAreainputStyles,
        }}
        InputLabelProps={{
          variant: "outlined",
          className: classes.inputlabelStyles,
        }}
        error={helperText !== undefined && helperText !== "" ? true : false}
        helperText={helperText !== undefined && helperText !== "" ? helperText : ""}
        onChange={handleInputChange}
        multiline={true}
        minRows={2}
      />
    </FormControl>
  );
};

export default CustomTextArea;
