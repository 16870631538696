import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import { Link } from "react-router-dom";
import { APP_ROUTES, USER_TYPES } from "../../../config/constants";
import SingleUsePlasticCard from "../SingleUsePlasticCard";

interface SingleUsePlasticCardAccessInterface {
  userInfo: any;
  classes: any;
  usePlasticState: any;
  handleSearchChange: any;
  fetchNextRecord: any;
  handleSearchOnClick: any;
  getPlasticListRecordBasedOnFilter: any;
}

export const SingleUsePlasticCardAccess = (props: SingleUsePlasticCardAccessInterface) => {
  const { userInfo, classes, usePlasticState, handleSearchChange, fetchNextRecord, handleSearchOnClick, getPlasticListRecordBasedOnFilter } = props;
  return userInfo?.userProfile?.role !== "" ? (
    <SingleUsePlasticCard
      classes={classes}
      usePlasticState={usePlasticState}
      handleSearchChange={handleSearchChange}
      fetchNextRecord={fetchNextRecord}
      userInfo={userInfo}
      handleSearchOnClick={handleSearchOnClick}
      getPlasticListRecordBasedOnFilter={getPlasticListRecordBasedOnFilter}
    />
  ) : (
    <div className={classes.cardRoot}>
      <div className={classes.accessDiv}>
        <span className={classes.heading}>Access Denied</span>
      </div>
    </div>
  );
};

export const designPlsticBagRecordList = (record: any) => {
  if (record && Array.isArray(record) && record.length > 0) {
    let recordData: any = record.map((item: any) => ({
      ...item,
      id: item.posId,
    }));
    return recordData;
  }
  return [];
};

interface SingleUsePlasticCreateConfigInterface {
  userInfo: any;
  classes: any;
}

export const SingleUsePlasticCreateConfig = (props: SingleUsePlasticCreateConfigInterface) => {
  const { userInfo, classes } = props;
  return userInfo?.userProfile?.role !== USER_TYPES.READONLY_USER && userInfo?.userProfile?.role !== USER_TYPES.ADMIN && userInfo?.userProfile?.role !== USER_TYPES.APPROVER ? (
    <Link to={APP_ROUTES.SINGLE_USE_PLASTIC_CURRENT_CONFIGURATION} style={{ textDecoration: "none" }}>
      <PrimaryButton className={classes.buttonStyles} buttonLabel="Create New Configuration" disableFocusRipple={true} disableRipple={true} />
    </Link>
  ) : (
    <></>
  );
};

export const design_selected_plastic_bag_filtered_list_based_on_search = (stateObj: any) => {
  if (stateObj !== undefined && stateObj.usePlasticList !== undefined && Array.isArray(stateObj.usePlasticList) && stateObj.usePlasticList.length > 0) {
    let filteredList = [...stateObj.usePlasticList];
    if (stateObj.searchText !== undefined && stateObj.searchText !== "") {
      filteredList = filteredList.filter(
        (list: any) =>
          (list.posNo && list.posNo.toLowerCase().includes(stateObj.searchText.toLowerCase())) || (list.storeName && list.storeName.toLowerCase().includes(stateObj.searchText.toLowerCase()))
      );
    }
    return filteredList;
  }
  return [];
};
