import produce from "immer";

import { DashboardPageInitialStateInterface } from "./DashboardPageInterface";
import { getDateFromPicker, getTimeFormatData } from "../../../utils/DateUtils";
import { isNonEmptyArray, isNonEmptyObject, objectContainsKey } from "../../../utils/DataUtils";
import { isZoneStatusActivePresent } from "./response.transform";

export function getValueBasedOnTotal(usedCapacity: number, totalCapacity: number) {
  return (usedCapacity * 100) / totalCapacity;
}

export function getBarColor(classes: any, usedCapacityPercentage: number) {
  if (Number(usedCapacityPercentage) > 100) {
    return classes.linearProgressFillColorRedStyle;
  } else if (Number(usedCapacityPercentage) === 100) {
    return classes.linearProgressFillColorGreenStyle;
  } else if (Number(usedCapacityPercentage) < 100 && Number(usedCapacityPercentage) > 69) {
    return classes.linearProgressFillColorYellowStyle;
  }
  return classes.linearProgressFillColorBlueStyle;
}

export function getUsedValueSpanColor(classes: any, usedCapacityPercentage: number) {
  if (Number(usedCapacityPercentage) < 15) {
    return classes.progressBarCountAvailableSpanStyle;
  } else if (Number(usedCapacityPercentage) > 50) {
    return classes.progressBarCountUsedSpanStyle;
  }
  return classes.progressBarCountUsedSpanStyle;
}

export function getAvailableValueSpanColor(classes: any, usedCapacityPercentage: number) {
  if (Number(usedCapacityPercentage) > 93) {
    return classes.progressBarCountUsedSpanStyle;
  }
  return classes.progressBarCountAvailableSpanStyle;
}

export function getMinDate(minDaySize: number) {
  const date = new Date();
  date.setDate(date.getDate() - 30);
  return date;
}

export function getMaxDate(advanceDaySize: number) {
  const date = new Date();
  date.setDate(date.getDate() + Number(advanceDaySize));
  return date;
}

export const handleDateChangeEventUtil = (appStateObj: DashboardPageInitialStateInterface, event: any, picker: any) => {
  let newAppStateObj: any = produce(appStateObj, (draft: DashboardPageInitialStateInterface) => {
    draft.loading = true;
    if (event && event.target) {
      if (event.target.name === "date") {
        const dateStart = getDateFromPicker(picker.startDate);
        draft.slotDate = dateStart;
        draft.slotDateLabel = dateStart;
      }
    }
  });
  return newAppStateObj;
};

export const designPosZoneListUtils = (appStateObj: DashboardPageInitialStateInterface, posZoneRecord: any) => {
  let newAppStateObj: any = produce(appStateObj, (draft: DashboardPageInitialStateInterface) => {
    draft.posList = [];
    draft.zoneList = [];
    draft.posNos = [];
    draft.zoneNames = [];
    if (isNonEmptyArray(posZoneRecord)) {
      posZoneRecord.forEach((data: any) => {
        if (data && objectContainsKey(data, "zoneServiceTypeList") && isNonEmptyArray(data.zoneServiceTypeList)) {
          let activeZone = 0;
          data.zoneServiceTypeList.forEach((zoneData: any) => {
            if (isZoneStatusActivePresent(zoneData)) {
              activeZone++;
              if (draft.zoneList.findIndex((item: any) => item.id === zoneData.zoneName) === -1) {
                let zoneListObj = { name: zoneData.zoneName, id: zoneData.zoneName };
                draft.zoneList.push(zoneListObj);
                draft.zoneNames.push(zoneData.zoneName);
              }
            }
          });
          if (activeZone !== 0) {
            draft.posList.push({ name: `${data.posNo} - ${data.storeName}`, id: data.posNo });
            draft.posNos.push(data.posNo);
          }
        }
      });
    } else {
      draft.loading = false;
    }
  });
  return newAppStateObj;
};

export const designSlotUtilizationRecordtUtils = (appStateObj: DashboardPageInitialStateInterface, record: any) => {
  let newAppStateObj: any = produce(appStateObj, (draft: DashboardPageInitialStateInterface) => {
    draft.loading = false;
    draft.slotList = [];
    draft.recordData = { totalRecord: [], posListRecord: [] };
    draft.totalSlotCapacity = 0;
    draft.totalUsedCapacity = 0;
    draft.totoalAvailableCapacity = 0;
    draft.totalUsedCapacityPercentage = 0;

    if (isNonEmptyObject(record)) {
      if (record?.totalUtilization) {
        const recordData = record.totalUtilization;
        if (recordData && objectContainsKey(recordData, "slotDetails") && isNonEmptyArray(recordData.slotDetails)) {
          recordData.slotDetails.forEach((slot: any) => {
            let footerObjName = `${getTimeFormatData(slot.startTime)} - ${getTimeFormatData(slot.endTime)}`;
            draft.slotList.push(footerObjName);
            draft.recordData.totalRecord.push({ ...slot, slotPresent: true });
            draft.totalSlotCapacity = Number(draft.totalSlotCapacity) + Number(slot.totalCapacity);
            draft.totalUsedCapacity = Number(draft.totalUsedCapacity) + Number(slot.usedCapacity);
            draft.totoalAvailableCapacity = Number(draft.totoalAvailableCapacity) + Number(slot.availableCapacity);
          });
        }
        // Calculate totalUsedCapacityPercentage
        if (Number(draft.totalUsedCapacity) > 0) {
          draft.totalUsedCapacityPercentage = getValueBasedOnTotal(draft.totalUsedCapacity, draft.totalSlotCapacity);
        }
      }
      if (objectContainsKey(record, "storesUtilization") && isNonEmptyArray(record.storesUtilization)) {
        const recordData = record.storesUtilization;
        recordData.forEach((slot: any) => {
          let dummySlotList = draft.recordData.totalRecord.map((item: any) => {
            return { ...item, slotPresent: false };
          });
          let posObj = {
            posNo: slot.posNo,
            posName: slot.posName,
            slotDetails: [...dummySlotList],
            totalSlotCapacity: 0,
            totalUsedCapacity: 0,
            totoalAvailableCapacity: 0,
            totalUsedCapacityPercentage: 0,
          } as any;
          if (objectContainsKey(slot, "slotDetails") && isNonEmptyArray(slot.slotDetails)) {
            slot.slotDetails.forEach((posSlot: any) => {
              const index = posObj.slotDetails.findIndex((item: any) => item.startTime === posSlot.startTime && item.endTime === posSlot.endTime);
              if (index !== -1) {
                posObj.slotDetails[index] = { ...posSlot, slotPresent: true };
                posObj.totalSlotCapacity = Number(posObj.totalSlotCapacity) + Number(posSlot.totalCapacity);
                posObj.totalUsedCapacity = Number(posObj.totalUsedCapacity) + Number(posSlot.usedCapacity);
                posObj.totoalAvailableCapacity = Number(posObj.totoalAvailableCapacity) + Number(posSlot.availableCapacity);
              }
            });
          }
          if (Number(posObj.totalUsedCapacity) > 0) {
            posObj.totalUsedCapacityPercentage = getValueBasedOnTotal(posObj.totalUsedCapacity, posObj.totalSlotCapacity);
          }
          draft.recordData.posListRecord.push(posObj);
        });
      }
    }
  });
  return newAppStateObj;
};

export const handleCloseLoaderUtils = (appStateObj: DashboardPageInitialStateInterface) => {
  let newAppStateObj: any = produce(appStateObj, (draft: DashboardPageInitialStateInterface) => {
    draft.loading = false;
  });
  return newAppStateObj;
};

export const handleMultiSelectBoxChangeUtil = (appStateObj: DashboardPageInitialStateInterface, event: any, picker: any) => {
  let newAppStateObj: any = produce(appStateObj, (draft: DashboardPageInitialStateInterface) => {
    draft.loading = true;
    switch (picker) {
      case "posNos":
        draft.posNos = event;
        break;
      case "zoneNames":
        draft.zoneNames = event;
        break;
    }
  });
  return newAppStateObj;
};

export const handleResetAppStatetUtil = (appStateObj: DashboardPageInitialStateInterface) => {
  let newAppStateObj: any = produce(appStateObj, (draft: DashboardPageInitialStateInterface) => {
    draft.loading = true;
    draft.slotDate = getDateFromPicker(new Date());
    draft.slotDateLabel = getDateFromPicker(new Date());
    draft.posNos = appStateObj.posList.map((pos: any) => pos.id);
    draft.zoneNames = appStateObj.zoneList.map((zone: any) => zone.id);
    draft.slotList = [];
    draft.recordData = { totalRecord: [], posListRecord: [] };
  });
  return newAppStateObj;
};
