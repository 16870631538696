import { APP_USER_MGMT_URL } from "../config/constants";
import { handleError, handleResponse } from "./ApiUtils";
import Fetch from "./Fetch";


export const createUser = (userData) => {
  return Fetch.create(`${APP_USER_MGMT_URL}/user`, userData).then(handleResponse).catch(handleError);
};

export const fetchUserRecordList = (data) => {
  return Fetch.get(`${APP_USER_MGMT_URL}/users`, data).then(handleResponse).catch(handleError);
};

export const getUserDetails = (toUpdateUserId) => {
  return Fetch.get(`${APP_USER_MGMT_URL}/user/${toUpdateUserId}`).then(handleResponse).catch(handleError);
}

export const updateUser = (userData) => {
  return Fetch.update(`${APP_USER_MGMT_URL}/user`, userData).then(handleResponse).catch(handleError);
};