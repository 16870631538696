import { createStyles, makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) =>
  createStyles({
    modal: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    modalContent: {
      border: "none",
      width: "80%",
      backgroundColor: "white",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: "24px",
      borderRadius: "8px",
      height: "80vh",
      overflow: "auto",
      "&:focus": {
        outline: "none",
      },
    },
    mainDivStyle: {
      margin: 0,
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
    },
    closeButton: {
      display: "flex",
      flexDirection: "row-reverse",
    },
    closeButtonSVG: {
      cursor: "pointer",
      fill: theme.palette.primary.main,
    },
    modalTitle: {
      fontSize: "24px",
      fontWeight: 600,
    },
    modalBody: {
      // padding: "24px",
      padding: "24px 0px",
    },
    modalFooter: {
      marginTop: "24px",
    },
    buttonStyles: {
      minWidth: "100%",
    },
    fieldLabel: {
      fontSize: "14px",
      fontWeight: 500,
      marginTop: "12px",
    },
    deliveryPromiseDetails: {
      margin: 0,
      padding: 0,
    },
    fieldValue: {
      fontSize: "14px",
      fontWeight: 600,
      marginTop: "8px",
    },
    posZoneMappingDetails: {
      margin: 0,
      padding: 0,
    },
    selectedZonesText: {
      fontSize: "18px",
      fontWeight: 600,
    },
    posZoneName: {
      marginTop: "16px",
      fontWeight: 500,
    },
    vehicleFieldLabel: {
      fontSize: "12px",
      fontWeight: 400,
      marginTop: "4px",
    },
  })
);

export default styles;
