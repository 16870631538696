import React from "react";
import { Link } from "react-router-dom";

import { Grid } from "@material-ui/core";
import { APP_ROUTES } from "../../../config/constants";

import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";

interface CountrySettingsFooterInterface {
  classes: any;
  handleApplyChangesClick: any;
}

const CountrySettingsFooter = (props: CountrySettingsFooterInterface) => {
  const { classes, handleApplyChangesClick } = props;

  return (
    <Grid container justifyContent="flex-end" spacing={2} className={classes.footerGridContainer}>
      <Grid item>
        <Link to={`${APP_ROUTES.POS_LIST}`} style={{ textDecoration: "none" }}>
          <PrimaryButton buttonLabel="Back to POS List" buttonVariant="outlined" />
        </Link>
      </Grid>
      <Grid item>
        <PrimaryButton buttonLabel="APPLY CHANGES" onClick={handleApplyChangesClick} />
      </Grid>
    </Grid>
  );
};

export default React.memo(CountrySettingsFooter);
