import React from "react";

import { Grid } from "@material-ui/core";

import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";

import { is_user_creating_approval_request } from "../Standard/StandardAccessUtils";

interface RenderZoneConfigurationsFooterInterafce {
  classes: any;
  userInfo: any;
  configTabs: any;
  configTab: any;
  service: any;
  serviceState: any;
  //
  handleSaveOrUpdateZoneConfigurationsSubmit: any;
  preview_request_for_approval: any;
}

const RenderZoneConfigurationsFooter = (props: RenderZoneConfigurationsFooterInterafce) => {
  const { classes, userInfo, configTabs, configTab, service, serviceState, handleSaveOrUpdateZoneConfigurationsSubmit, preview_request_for_approval } = props;

  return (
    <Grid container className={classes.buttonsContainer}>
      <Grid item xs={6}>
        {""}
      </Grid>

      {is_user_creating_approval_request(userInfo) ? (
        serviceState !== undefined &&
        serviceState.activationDatelabel !== undefined &&
        serviceState.activationDatelabel !== "" &&
        serviceState.isDateValid && (
          <Grid item xs={6} className={classes.buttonsStyle}>
            <PrimaryButton buttonLabel="PREVIEW APPROVAL REQUEST" onClick={preview_request_for_approval} className={classes.buttonStyle} />{" "}
          </Grid>
        )
      ) : configTab.zoneId ? (
        <Grid item xs={6} className={classes.buttonsStyle}>
          <PrimaryButton
            buttonLabel={
              service && service[serviceState.selectedConfigTab] && service[serviceState.selectedConfigTab].isUpdating
                ? "updating..."
                : `Update ${configTabs[serviceState.selectedConfigTab]?.zoneName} Configurations`
            }
            disabled={!!(service && service[serviceState.selectedConfigTab] && service[serviceState.selectedConfigTab].isUpdating)}
            onClick={() => handleSaveOrUpdateZoneConfigurationsSubmit(serviceState.selectedConfigTab, true)}
          />
        </Grid>
      ) : (
        <Grid item xs={6} className={classes.buttonsStyle}>
          <PrimaryButton
            buttonLabel={
              service && service[serviceState.selectedConfigTab] && service[serviceState.selectedConfigTab].isCreating
                ? "creating..."
                : `Save ${configTabs[serviceState.selectedConfigTab]?.zoneName} Configurations`
            }
            disabled={!!(service && service[serviceState.selectedConfigTab] && (service[serviceState.selectedConfigTab].isCreating || service[serviceState.selectedConfigTab].isCreated))}
            onClick={() => handleSaveOrUpdateZoneConfigurationsSubmit(serviceState.selectedConfigTab)}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(RenderZoneConfigurationsFooter);
