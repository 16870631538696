import React from "react";

import { Grid, Typography } from "@material-ui/core";

import { M_O_V_THRESHOLD_BOX_TITLE } from "../../../Utils/DeliveryFeeConstants";

import { ChangeConfigOtion } from "../../utils/DeliveryFeeConfigViewUtils";

import SwitchButton from "../../../../common/SwitchButton/SwitchButton";
import TextBoxWithLabel from "../../../../common/TextBoxWithLabel/TextBoxWithLabel";

interface MOVThresholdConfigurationInterface {
  classes: any;
  name: any;
  multiShipmentLevel: any;
  stateObj: any;
  isEditMode: any;
  stateLevelIndex: any;
  updateConfigPosId: any;
  updateConfigZoneName: any;
  enableThisFeature: any;
  use_existing_config_change_handler: any;
  config_change_existing_handler: any;
  _handle_mov_threshold_calculation_toggle_change: any;
  handleMovThresholdInputChange: any;
}

const MOVThresholdConfiguration = (props: MOVThresholdConfigurationInterface) => {
  const {
    classes,
    name,
    multiShipmentLevel,
    stateObj,
    isEditMode,
    stateLevelIndex,
    updateConfigPosId,
    updateConfigZoneName,
    enableThisFeature,
    use_existing_config_change_handler,
    config_change_existing_handler,
    _handle_mov_threshold_calculation_toggle_change,
    handleMovThresholdInputChange,
  } = props;

  return (
    <Grid container className={classes.dlvFeeServiceTypeDiv} key={`${name}-${stateLevelIndex}-service-Fee`}>
      <Grid container>
        <Grid item xs={4} className={classes.dlvFeeServiceTypeTextDivStyle}>
          <Typography className={classes.dlvFeeServiceTypeTextStyle}>{M_O_V_THRESHOLD_BOX_TITLE}</Typography>
        </Grid>
        <Grid item xs={4} className={classes.marginAuto}>
          {!stateObj.sameAsEarlier && (
            <Grid container justifyContent="flex-end">
              <Grid item xs={10} className={classes.useCalculationDivStyle}>
                <Typography className={classes.dlvFeeCalculationTextStyle}>Use this value in calculation</Typography>
              </Grid>
              <Grid item xs={2}>
                <SwitchButton
                  className={isEditMode && !stateObj.enableConfigEdit ? classes.disableSwitch : classes.switchStyle}
                  size="small"
                  checked={stateObj.active}
                  disabled={false}
                  handleChange={(e: any) => _handle_mov_threshold_calculation_toggle_change(e, multiShipmentLevel, name)}
                  leftLabel={undefined}
                  rightLabel={undefined}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={4} className={enableThisFeature ? classes.marginAuto : classes.marginAutoDisabled}>
          <Grid container justifyContent="flex-end">
            <ChangeConfigOtion
              is_edit_mode={updateConfigPosId === "" && updateConfigZoneName === "" ? false : true}
              classes={classes}
              use_existing_checked={stateObj.sameAsEarlier}
              use_existing_config_change_handler={(event: any) => use_existing_config_change_handler(event, multiShipmentLevel, name, "minimumOrderValue", "sameAsEarlier", enableThisFeature)}
              change_existing_checked={stateObj.enableConfigEdit}
              use_change_existing_config_change_handler={(e: any) => config_change_existing_handler(e, multiShipmentLevel, name, "minimumOrderValue", "enableConfigEdit", enableThisFeature)}
            />
          </Grid>
        </Grid>
      </Grid>

      {stateObj !== undefined && stateObj.active !== undefined && stateObj.active == true && (
        <>
          <Grid container className={classes.thresholdInputMainDiv}>
            <Grid item xs={3} className={classes.thresholdInputDiv}>
              <TextBoxWithLabel
                label={"Minimum Order Value Threshold"}
                name="movThreshold"
                value={stateObj.movThreshold}
                placeholderText="Minimum Order Value Threshold"
                handleChange={(e: any) => handleMovThresholdInputChange(e, multiShipmentLevel, name)}
                type="number"
                className={classes.rightPaddingStyle}
                disabled={!stateObj.enableConfigEdit}
              ></TextBoxWithLabel>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default React.memo(MOVThresholdConfiguration);
