import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../config/theme/theme";

export const userStyles = makeStyles(() =>
  createStyles({
    container: {
      marginTop: "80px",
      padding: "24px 12px 24px 24px",
    },
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "24px",
    },
    heading: {
      fontWeight: 600,
      fontSize: "24px",
      color: COLORS.DARK_GRAY,
    },
    cardRoot: {
      width: "100%",
      height: "calc(100vh - 242px)",
      background: "#FFFFFF",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px 8px 0px 0px",
      overflow: "auto",
    },
    topHeaderDiv: {
      position: "fixed",
      zIndex: 1,
      width: "calc(100vw - 120px)",
      height: "64px",
      background: "#FFFFFF",
      borderRadius: "8px 8px 0px 0px",
    },
    searchAndFilterContainer: {
      width: "calc(100vw - 170px)",
      margin: "24px 24px 0px 24px",
    },
    searchFilter: {
      width: "364px",
      background: COLORS.WHITE,
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#E6E6E6",
        top: "0px",
      },
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#E6E6E6",
          top: "0px",
        },
      },
    },
    buttonContainer: {
      width: "50%",
    },
    exportButton: {
      margin: 0,
      padding: 0,
      width: "100%",
      marginRight: "0px",
    },
    createButton: {
      width: "170px",
    },
    tableDivStyle: {
      margin: 0,
      marginTop: "90px",
      padding: 0,
      minHeight: "500px",
      maxHeight: "calc(100vh - 340px)",
      width: "100%",
      overflow: "auto",
    },

    statusTypeContainer: {
      marginLeft: "1px",
    },
    activeStatus: {
      background: "#BFE4E1",
      borderRadius: "4px",
      display: "inline-block",
      color: "#007F73",
      fontWeight: 600,
    },
    inactiveStatus: {
      background: "#E6E6E6",
      borderRadius: "4px",
      display: "inline-block",
      // marginLeft: "8px",
      color: "#B2B2B2",
      fontWeight: 600,
    },
    status: {
      fontSize: "12px",
      padding: "0px 12px",
      display: "flex",
      alignItems: "center",
      height: "23px",
    },
    editButton: {
      display: "flex",
      "& img": {
        height: "18px",
      },
    },
    editIconStyle: {
      width: "100%",
      height: "100%",
      cursor: "pointer",
      textAlign: "center",
    },
    accessDiv: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    headerRoot: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "24px",
    },
    header: {
      display: "flex",
      justifyContent: "flex-start",
    },
    buttonStyles: {
      width: "364px",
      height: "40px",
      background: COLORS.DOS_BLUE_DARK,
      color: "#FFFFFF",
      border: "1px solid",
      borderColor: COLORS.DOS_BLUE_DARK,
      borderRadius: "8px",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "20px",
      "&:hover": {
        backgroundColor: COLORS.DOS_BLUE_DARK,
        color: "#FFFFFF",
        border: "1px solid",
        borderColor: COLORS.DOS_BLUE_DARK,
        borderRadius: "8px",
      },
      "&:disabled": {
        border: "1px solid rgba(0, 0, 0, 0.12)",
      },
    },
    headerStyle: {
      background: "rgba(0, 0, 0, 0.04)",
      margin: 0,
      padding: 0,
      minHeight: "60px",
      "& .MuiDataGrid-root": {
        margin: 0,
        padding: 0,
      },
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        margin: 0,
        padding: 0,
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        lineHeight: "normal",
        textOverflow: "clip",
        whiteSpace: "normal",
        fontWeight: 600,
      },
    },
    searchIconStyle: {
      opacity: "0.3",
    },
  })
);
