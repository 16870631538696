import { IconButton, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Close";
import React from "react";

const styles = makeStyles((theme) => ({
  iconRoot: {
    cursor: "pointer",
    "&:hover": {
      background: "transparent !important",
      backgroundColor: "transparent !important",
    },
  },
  root: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    "&:hover": {
      background: "transparent !important",
      backgroundColor: "transparent !important",
    },
  },
}));

const RemoveButton = (props) => {
  const classes = styles();
  return (
    <IconButton aria-label="delete" {...props} className={`${classes.iconRoot}`}>
      <DeleteIcon {...props} className={`${classes.root}${props.className}`} />
    </IconButton>
  );
};

export default RemoveButton;
