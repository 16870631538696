import React from "react";

import { FormControl, InputLabel, TextField } from "@material-ui/core";

import { useStyles } from "./TextBoxStyles";

const TextBox = (props) => {
  const classes = useStyles();
  const {
    fieldLabel,
    handleChange,
    handleKeyDownChange,
    fullWidth,
    variant = "outlined",
    value,
    label,
    name,
    textBoxId,
    inputProps,
    error,
    helperText,
    type,
    placeholderText,
    readOnly,
    className,
    endAdornment,
    startAdornment,
    required = false,
    isDeliveryFee = false,
    isNegativeAllowed = true,
    InputProps,
    ...rest
  } = props;

  const handleInputChange = (e) => {
    if (isNegativeAllowed) {
      handleChange(e);
      return;
    }
    if (e.target.value < 0) {
      e.target.value = "";
      handleChange(e);
      return;
    }
    handleChange(e);
  };

  return (
    <>
      {fieldLabel && <div className={classes.fieldLabel}>{fieldLabel}</div>}
      <FormControl className={classes.root} variant={isDeliveryFee ? "filled" : variant}>
        <InputLabel htmlFor={textBoxId}></InputLabel>
        <TextField
          {...rest}
          className={`${className || ""} ${label ? "hasLabel " : ""}`}
          variant={isDeliveryFee ? "filled" : variant}
          fullWidth={fullWidth}
          id={textBoxId}
          placeholder={placeholderText}
          required={required}
          type={type}
          name={name}
          value={value}
          label={label}
          InputProps={{
            ...InputProps,
            className: classes.inputStyles,
            readOnly: readOnly,
            endAdornment: endAdornment,
            startAdornment: startAdornment,
          }}
          inputProps={inputProps}
          error={error}
          helperText={helperText}
          onKeyDown={handleKeyDownChange}
          onChange={handleInputChange}
        />
      </FormControl>
    </>
  );
};

export default React.memo(TextBox);
