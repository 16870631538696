import React from "react";
import { Link } from "react-router-dom";

import EyeIcon from "../../assets/svg/EyeIconDetails.svg";

import { TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import { APPROVAL_FLOW_ACTIONS, APP_ROUTES } from "../../../config/constants";
import { GetNoDataPresentAfterFiltereApplied, GetNoDataPresentErrorViewUtils } from "../../common/App/AppViewUtils";
import { NO_APPROVAL_REQUEST_FOUND_ERROR, RECORD_INTERFACE } from "./ApprovalRequestConstant";
import { storeRecordDataReducer } from "../Redux/ApprovalRequestSlice";
import { isArrayValid, isEmptyOrNull, isUndefined } from "../../../utils/DataUtils";

interface ApprovalRequestTableBodyInterface {
  classes: any;
  rowsData: RECORD_INTERFACE[];
  currentCountry: any;
  appFilterPopUpObj: any;
  appDispatch: any;
}

const ApprovalRequestTableBody = (props: ApprovalRequestTableBodyInterface) => {
  const { classes, rowsData, currentCountry, appFilterPopUpObj, appDispatch } = props;

  const storeRecordDataInSlice = (record: any) => {
    appDispatch(storeRecordDataReducer(record));
  };

  const renderTabelCell = (data: string | number) => {
    return (
      <TableCell padding="none" size="small" align="left" className={classes.cellDataStyle}>
        <Typography className={classes.cellDataSpanStyle}>{`${data || "-"}`}</Typography>
      </TableCell>
    );
  };

  const getStatusClassName = (record: RECORD_INTERFACE): string => {
    if (record.approvalStatus === APPROVAL_FLOW_ACTIONS.APPROVED) {
      return classes.approvedStatusStyle;
    } else if (record.approvalStatus === APPROVAL_FLOW_ACTIONS.REJECTED) {
      return classes.rejectedStatusStyle;
    }
    return classes.pendingStatusStyle;
  };
  const isActionTaken = (record: RECORD_INTERFACE): string => {
    let returnText: string = "";
    if (!isUndefined(record) && record.approvalStatus !== APPROVAL_FLOW_ACTIONS.PENDING) {
      returnText = record.actionTakenBy || "-";
    }
    return returnText;
  };

  const isActionTakenDate = (record: RECORD_INTERFACE): string => {
    let returnText: string = "";
    if (!isUndefined(record) && record.approvalStatus !== APPROVAL_FLOW_ACTIONS.PENDING) {
      returnText = record.actionDate || "-";
    }
    return returnText;
  };

  return (
    <TableBody>
      {!isUndefined(rowsData) && isArrayValid(rowsData) ? (
        rowsData.map((record: RECORD_INTERFACE, recordIndex: number) => (
          <TableRow key={`${recordIndex}-record-rowsData-ApprovalRequestTableBody`}>
            {renderTabelCell(record?.posNo)}
            {renderTabelCell(record?.storeName)}
            {renderTabelCell(record?.zoneName)}
            {renderTabelCell(record?.serviceType)}
            {renderTabelCell(record?.requester)}
            {renderTabelCell(record?.requestDate)}
            {renderTabelCell(record?.activationDate)}
            {renderTabelCell(record?.pendingDays)}
            <TableCell padding="none" size="small" align="left" className={classes.cellDataStyle}>
              {!isUndefined(record?.approvalStatus) && !isEmptyOrNull(record?.approvalStatus) ? <div className={getStatusClassName(record)}>{`${record?.approvalStatus || "N/A"}`}</div> : "-"}
            </TableCell>
            {renderTabelCell(isActionTaken(record))}
            {renderTabelCell(isActionTakenDate(record))}
            <TableCell padding="none" size="small" align="center" className={classes.cellDataStyle}>
              {
                <Link
                  to={{
                    pathname: `${APP_ROUTES.APPROVAL_REQUESTS_DETAILS}`,
                    search: `?posNo=${record?.posNo}&zoneName=${record?.zoneName}&serviceType=${record?.serviceType}&approvalStatus=${record?.approvalStatus}&requestedId=${record?.requestedId}&storeName=${record?.storeName}`,
                  }}
                >
                  <div className={classes.editIconStyle}>
                    <img src={EyeIcon} alt="view config" onClick={() => storeRecordDataInSlice(record)} />
                  </div>
                </Link>
              }
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell rowSpan={1} colSpan={12} className={classes.noDataRowStyle}>
            {appFilterPopUpObj !== undefined && appFilterPopUpObj.isDataFetched !== undefined && appFilterPopUpObj.isDataFetched ? (
              <GetNoDataPresentAfterFiltereApplied classes={classes} />
            ) : (
              <GetNoDataPresentErrorViewUtils classes={classes} currentCountry={currentCountry} ErrorMessage={NO_APPROVAL_REQUEST_FOUND_ERROR} SecondaryErrorMessage={""} />
            )}
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default ApprovalRequestTableBody;
