import React, { useState } from "react";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core";

const toastAlertStyles = makeStyles(() => ({
  toastAlert: {
    color: "white",
    fontWeight: 500,
    height: "64px",
    padding: "24px",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    "& .MuiAlert-icon": {
      color: "white",
    },
    "& .MuiSnackbarContent-message": {
      color: "white",
    },
  },
  success: {
    backgroundColor: "#009688",
  },
  error: {
    backgroundColor: "#F34041",
  },
}));

const ToastAlert = ({ handleClose, severity = "error", message }) => {
  const classes = toastAlertStyles();
  return (
    <Alert
      onClose={handleClose}
      className={`${classes.toastAlert} ${severity === "success" ? classes.success : classes.error}`}
      severity={severity}
      elevation={6}
    >
      {message}
    </Alert>
  );
};

const Toast = ({ severity = "error", message = "", close = ()=>{} }) => {
  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    close();
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      style={{ marginTop: "40px" }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <ToastAlert handleClose={handleClose} message={message} severity={severity} />
    </Snackbar>
  );
};

export default Toast;
