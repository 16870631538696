import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from "@material-ui/core";
import SwitchButton from "components/common/SwitchButton/SwitchButton";
import { SERVICE_TYPES_KEYS, STATUS } from "../../config/constants";

interface PosTabInfoProps {
  handleServiceTypeDetails: (type: SERVICE_TYPES_KEYS) => {};
  selectedServiceType: SERVICE_TYPES_KEYS;
  serviceType: SERVICE_TYPES_KEYS;
  serviceTypeLabel: string;
  classes: any;
  posCount: any;
}

export function PosTabInfo(props: PosTabInfoProps) {
  const { handleServiceTypeDetails, selectedServiceType, serviceType, classes, posCount, serviceTypeLabel } = props;
  return (
    <Grid item xs={2} onClick={() => handleServiceTypeDetails(serviceType)} className={`${classes.serviceTab} ${selectedServiceType === serviceType ? classes.serviceTypeDetailsSectionActive : ""}`}>
      <Grid container justifyContent="space-between" className={classes.activePOSGridInnerContainer} spacing={0}>
        <Grid item className={classes.activePOSGridInnerItem}>
          <Typography className={classes.serviceTypeStyle}>{serviceTypeLabel}</Typography>
        </Grid>
        <Grid item className={classes.activePOSGridInnerItem}>
          <Typography className={classes.serviceTypeCountData}>{posCount !== undefined ? posCount : "-"}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

interface PlasticBagconfigurationViewUtilsInterface {
  classes: any;
  bagChargeEnabled: any;
  handleBagChargeChange: any;
  name: any;
}

export const PlasticBagconfigurationViewUtils = (props: PlasticBagconfigurationViewUtilsInterface) => {
  const { classes, bagChargeEnabled, handleBagChargeChange, name } = props;
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" className={classes.serviceTypeContentTitle}>
        Plastic Bag Charge Enabled
      </FormLabel>
      <RadioGroup name={name} value={bagChargeEnabled} onChange={handleBagChargeChange}>
        <FormControlLabel value={true} control={<Radio color="primary" />} label="On" classes={{ label: classes.formControlLabel }} />
        <FormControlLabel value={false} control={<Radio color="primary" />} label="Off" classes={{ label: classes.formControlLabel }} />
      </RadioGroup>
    </FormControl>
  );
};

interface ServiceActiveFlagViewUtilsInterface {
  classes: any;
  serviceTypeName: any;
  serviceActiveFlag: any;
  isDisabled: any;
  handleServiceTypeActiveChange: any;
}

export const ServiceActiveFlagViewUtils = (props: ServiceActiveFlagViewUtilsInterface) => {
  const { classes, serviceTypeName, serviceActiveFlag, isDisabled, handleServiceTypeActiveChange } = props;
  return (
    <Grid item xs={12} className={isDisabled ? classes.enableServiceTypeGridMainDisabled : classes.enableServiceTypeGridMain}>
      <Grid container spacing={0} justifyContent="flex-end" className={classes.enableServiceTypeGridConatiner}>
        <Grid item xs={7} className={classes.enableServiceTypeGridItem}>
          {""}
        </Grid>
        <Grid item xs={3} className={classes.enableServiceTypeGridItem}>
          <Typography className={classes.enableServiceTypeGridItemTextStyle}>{`Enable Service Type`}</Typography>
        </Grid>
        <Grid item xs={2} className={classes.enableServiceTypeSwitchGridItem}>
          <SwitchButton
            className={!isDisabled ? classes.switchStyle : classes.disableSwitch}
            size="small"
            checked={serviceActiveFlag}
            disabled={isDisabled}
            handleChange={(e: any) => handleServiceTypeActiveChange(e, serviceTypeName)}
            leftLabel={undefined}
            rightLabel={undefined}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

interface ServiceTypeBasicDetailsInterface {
  classes: any;
  serviceTypeName: any;
  serviceActiveStatus?: any;
  nextDaySlotVisibilityStatus?: any;
  editSlotDaysStatus?: any;
  plasticBagChargeStatus: any;
  expressTypeStatus?: any;
}

export const ServiceTypeBasicDetails = (props: ServiceTypeBasicDetailsInterface) => {
  const { classes, serviceTypeName, serviceActiveStatus, nextDaySlotVisibilityStatus, editSlotDaysStatus, plasticBagChargeStatus, expressTypeStatus } = props;

  return (
    <>
      <Grid item xs={12} className={classes.serviceTypeHeaderDetailsGridItem}>
        <Typography className={classes.applyModalBodyTitle}>{`${serviceTypeName || ""}`}</Typography>
      </Grid>
      {serviceActiveStatus !== undefined && (
        <Grid item xs={12} className={classes.serviceTypeDetailsGridItem}>
          <Typography className={classes.applyModalBodyContent}>{`Service Type : `}</Typography>
          <Typography className={serviceActiveStatus === STATUS.ACTIVE ? classes.selectedValuesDataTrue : classes.selectedValuesDataFalse}>{`${serviceActiveStatus}`}</Typography>
        </Grid>
      )}
      {nextDaySlotVisibilityStatus !== undefined && (
        <Grid item xs={12} className={classes.serviceTypeDetailsGridItem}>
          <Typography className={classes.applyModalBodyContent}>{`Next day slot visibility : `}</Typography>
          <Typography className={classes.selectedValuesData}>{`${nextDaySlotVisibilityStatus}`}</Typography>
        </Grid>
      )}
      {editSlotDaysStatus !== undefined && (
        <Grid item xs={12} className={classes.serviceTypeDetailsGridItem}>
          <Typography className={classes.applyModalBodyContent}>{`Advance slot days : `}</Typography>
          <Typography className={classes.selectedValuesData}>{`${editSlotDaysStatus}`}</Typography>
        </Grid>
      )}
      <Grid item xs={12} className={classes.serviceTypeDetailsGridItem}>
        <Typography className={classes.applyModalBodyContent}>{`Plastic bag charge : `}</Typography>
        <Typography className={classes.selectedValuesData}>{`${plasticBagChargeStatus}`}</Typography>
      </Grid>
      {expressTypeStatus !== undefined && (
        <Grid item xs={12} className={classes.serviceTypeDetailsGridItem}>
          <Typography className={classes.applyModalBodyContent}>{`Express Type : `}</Typography>
          <Typography className={classes.selectedValuesData}>{`${expressTypeStatus}`}</Typography>
        </Grid>
      )}
    </>
  );
};
