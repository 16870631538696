import React from "react";

import { Grid, Typography } from "@material-ui/core";

import TextBoxWithLabel from "../../../common/TextBoxWithLabel/TextBoxWithLabel";
import SelectBox from "../../../common/SelectBox/SelectBox";
import SwitchButton from "../../../common/SwitchButton/SwitchButton";

import { CAPACITY_OPTIMIZATION } from "../../../../config/constants";

interface CapacityOptimizationInterface {
  classes: any;
  capacityOptimizationFlag: any;
  bufferCapacityConfig: any;
  handleCapacityOptimizationSwitch: any;
  handleCapacityOptimizationInputsChange: any;
}

const CapacityOptimization = (props: CapacityOptimizationInterface) => {
  const { classes, capacityOptimizationFlag, bufferCapacityConfig, handleCapacityOptimizationSwitch, handleCapacityOptimizationInputsChange } = props;

  return (
    <Grid container direction="row" justifyContent="space-between" className={classes.mainContainer}>
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-start", margin: 0, padding: 0 }}>
            <Typography className={classes.fontWeight500} style={{ margin: 0, padding: "0px 8px 8px 0px" }}>
              {`Capacity Optimization`}
            </Typography>
            <SwitchButton
              name="capacityOptimizationFlag"
              checked={!!capacityOptimizationFlag}
              handleChange={handleCapacityOptimizationSwitch}
              leftLabel={undefined}
              rightLabel={undefined}
              disabled={undefined}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.inputsContainer} style={{ margin: "0px 0px 0px 0px" }}>
        {bufferCapacityConfig !== undefined && typeof bufferCapacityConfig === "object" ? (
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <SelectBox
                name="capacityUtilized"
                label="Capacity Utilized"
                menuItems={CAPACITY_OPTIMIZATION.CAPACITY_UTILIZED}
                value={bufferCapacityConfig.capacityUtilized || ""}
                disabled={!capacityOptimizationFlag}
                handleChange={handleCapacityOptimizationInputsChange}
              />
            </Grid>
            <Grid item xs={4}>
              <SelectBox
                name="reducedBufferTime"
                label="Buffer Time Decrease"
                menuItems={CAPACITY_OPTIMIZATION.BUFFER_TIME_DECREASE}
                value={bufferCapacityConfig.reducedBufferTime || ""}
                disabled={!capacityOptimizationFlag}
                handleChange={handleCapacityOptimizationInputsChange}
                placeholder="minutes"
              />
            </Grid>
            <Grid item xs={4}>
              <TextBoxWithLabel
                type="number"
                name="maxCapacity"
                label={"Max Order"}
                placeholderText="Max Orders"
                value={bufferCapacityConfig.maxCapacity || ""}
                handleChange={handleCapacityOptimizationInputsChange}
                disabled={!capacityOptimizationFlag}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(CapacityOptimization);
