import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectUserDetails } from "../Login/redux/userSlice";
import { userStyles } from "./SingleUsePlasticStyles";

import Loader from "../common/Loader/Loader";
import { designPlsticBagRecordList, design_selected_plastic_bag_filtered_list_based_on_search, SingleUsePlasticCardAccess, SingleUsePlasticCreateConfig } from "./Utils/SingleUsePlasticUtils";

import { fetchPlasticBagList, resetUpdatePosBagMappingState, selectAllPlasticBagRecord } from "./redux/singleUsePlasticSlice";

const SingleUsePlasticPage = () => {
  const classes = userStyles();

  const dispatch = useDispatch();

  const {
    currentCountry: { countryId },
  } = useSelector((state: any) => state.appConfig);
  const plasticBagListService = useSelector(selectAllPlasticBagRecord);

  const userInfo: any = useSelector(selectUserDetails);

  const [usePlasticState, setUsePlasticState] = useState({
    loading: false,
    searchText: "",
    usePlasticList: [],
    pageSize: 100,
    maxRecordPresent: 0,
    page: 0,
  });

  const getPlasticListRecordBasedOnFilter = () => {
    let assigned_list_data = design_selected_plastic_bag_filtered_list_based_on_search(usePlasticState);
    return [...new Set(assigned_list_data)];
  };

  const handleSearchOnClick = () => {};

  const handleSearchChange = (event: any) => {
    if (event && event.target) {
      let { value } = event.target;
      setUsePlasticState({
        ...usePlasticState,
        searchText: value,
      });
    }
  };

  const fetchNextRecord = useCallback(
    (pageNumber: any) => {
      setUsePlasticState({ ...usePlasticState, usePlasticList: [], page: pageNumber });
    },
    [usePlasticState.page]
  );

  const fetchPlasticBagDataList = useCallback(async () => {
    setUsePlasticState({ ...usePlasticState, usePlasticList: [], loading: true });
    dispatch(resetUpdatePosBagMappingState());
    dispatch(
      fetchPlasticBagList({
        countryId: countryId,
      })
    );
  }, [countryId, dispatch]);

  useEffect(() => {
    countryId && fetchPlasticBagDataList();
  }, [countryId, fetchPlasticBagDataList]);

  useEffect(() => {
    setUsePlasticState({ ...usePlasticState, usePlasticList: designPlsticBagRecordList(plasticBagListService.data), loading: false });
  }, [plasticBagListService, plasticBagListService.data]);

  return (
    <div className={classes.container}>
      <div className={classes.headerRoot}>
        <div className={classes.header}>
          <span className={classes.heading}>{`Single Use Bag Configuration`}</span>
        </div>
        <SingleUsePlasticCreateConfig userInfo={userInfo} classes={classes} />
      </div>

      {usePlasticState.loading ? (
        <Loader />
      ) : (
        <SingleUsePlasticCardAccess
          userInfo={userInfo}
          classes={classes}
          usePlasticState={usePlasticState}
          handleSearchChange={handleSearchChange}
          fetchNextRecord={fetchNextRecord}
          handleSearchOnClick={handleSearchOnClick}
          getPlasticListRecordBasedOnFilter={getPlasticListRecordBasedOnFilter}
        />
      )}
    </div>
  );
};

export default React.memo(SingleUsePlasticPage);
