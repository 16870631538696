import { makeStyles } from "@material-ui/core";
import Button, { ButtonProps } from "@material-ui/core/Button";
import React from "react";


const useStyles = makeStyles(() => ({
  root: {
    borderRadius: "8px",
    fontWeight: 600,
    // minWidth: "80%",
    height: "40px",
    textTransform: "uppercase",
    fontSize: "14px",
    padding: "16px 24px",
  },
}));

interface PrimaryButtonProps extends ButtonProps {
  buttonLabel: string,
  buttonSize?: any,
  buttonVariant?: any,
  className?: any,

}

function PrimaryButton(props: PrimaryButtonProps) {
  const {
    buttonLabel,
    buttonSize = "large",
    buttonVariant = "contained",
    className = "",
    ...rest
  } = props;
  const classes = useStyles();
  return (
    <Button
      {...rest}
      variant={buttonVariant}
      color="primary"
      size={buttonSize}
      className={`${classes.root} ${className}`}
    >
      {buttonLabel}
    </Button>
  );
};

export default React.memo(PrimaryButton);
