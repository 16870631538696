import { makeStyles } from "@material-ui/core";

const createPOSStyles = makeStyles({
  sideBarContainer: {
    height: "50vh",
    overflow: "hidden",
  },
  mainContentSection: {
    marginTop: "80px",
    padding: "24px 12px 24px 24px",
  },
  mainContent: {
    margin: 0,
    padding: "0px 24px 0px 24px",
    overflow: "hidden",
  },
});

export default createPOSStyles;
