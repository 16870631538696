import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import moment from "moment";

import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Grid, Typography } from "@material-ui/core";

import expressServiceStyles from "../ExpressStyles";

import OverlayLoader from "../../../common/Loader/OverlayLoader";
import PageTitle from "../../../common/PageTitle/PageTitle";
import CustomDateRangePicker from "../../../common/CustomDateRangePicker/CustomDateRangePicker";
import CustomAlert from "../../../common/CustomAlert/CustomAlert";
import RenderExpressConfigurationSlotsAccordion from "./common/RenderExpressConfigurationSlotsAccordion";
import BulkUpdateExpressPopUp from "../common/BulkUpdateExpressPopUp";

import { ALERT_TYPES, API_RESPONSE_STATUS, APP_ROUTES, EXPRESS_TYPES, TEXT_CONSTANT } from "../../../../config/constants";
import { getDateFromPicker } from "../../../CountrySettings/CountrySettingsUtils";
import { isObjectEmptyOrNull } from "../../../../utils/DataUtils";
import { check_if_slot_obj_contains_sub_set } from "../../../../utils/ApplicationUtils";
import { fetchPOSDetailsByPOSNo } from "../../../CreatePOS/redux/posSlice";
import { selectServiceTypes } from "../../../../config/redux/configurationsSlice";
import { fetchPendingApprovalRequest, selectAllPendingApprovalRequestRecordListServices } from "../../../ApprovalRequest/Redux/ApprovalRequestSlice";
import { fetchSlotsInformationByServiceType, resetSlotsInformation, sendConfigurationForApproval } from "../../../ServiceAndSlots/redux/templateSlotsSlice";
import {
  _validate_send_for_approval_form,
  add_new_template_slot_util,
  bulk_update_slot_changes_util,
  delete_template_slot_util,
  design_express_configuration_handler_utils,
  update_handle_individual_slot_field_change,
  update_make_slot_editable,
  validate_generator_form_before_bulk_update,
} from "./common/Utils/NewExpressRequestUtils";
import RenderFooterButtons from "components/ServiceAndSlots/Standard/NewApprovalRequest/common/RenderFooterButtons";
import { ExpandMoreAccordianIcon } from "components/common/App/AppViewUtils";
import CustomTextArea from "components/common/TextBoxWithLabel/CustomTextArea";

interface NewExpressRequestInterface {
  serviceTypeId: any;
  serviceTypeName: any;
  expressType: any;
}

const NewExpressRequest = (props: NewExpressRequestInterface) => {
  const { serviceTypeId, serviceTypeName, expressType } = props;

  const { posNo, zoneId }: any = useParams();
  // const isExpressTypeOnDemand = expressType === EXPRESS_TYPES.ON_DEMAND.value;

  const classes: any = expressServiceStyles();
  const history = useHistory();
  const appDispatch = useDispatch();

  const { posDetails, loading: fetchingDetails } = useSelector((state: any) => state.pos);
  const { fetchingSlots, express } = useSelector((state: any) => state.slots);
  const pendingApprovalRequestRecordListService = useSelector(selectAllPendingApprovalRequestRecordListServices);
  const serviceTypes = useSelector(selectServiceTypes);
  const {
    currentCountry: { countryId },
  } = useSelector((state: any) => state.appConfig);

  const [configTab, setConfigTab] = useState({} as any);
  const [originalConfigTab, setOriginalConfigTab] = useState({} as any);
  const [dateObject, setDateObject] = useState({
    activationDate: "",
    activationDatelabel: "",
    isDateValid: false,
    data_present_obj: {},
  } as any);
  const [stateObj, setStateObj] = useState({
    loading: false,
    selectedConfigTab: 0,
    request_already_presnt_by_admin_error: "",
    // Bulk Update
    openBulkUpdateConfirmationPopUp: false,
    configTabIndex: null,
    generatorFormIndex: null,
    configuredDays: [],
    currentBulkUpdateConfigurationSummary: {},
    selctedDays: [],
    currentDay: "",
    configurationBulkUpdatedObj: {
      isConfigurationBulkUpdated: false,
      bulkUpdatedDays: [],
    },
    approverComment: "",
  } as any);

  const is_request_already_present = (date_data: any) => {
    let obj = {} as any;
    if (
      pendingApprovalRequestRecordListService !== undefined &&
      pendingApprovalRequestRecordListService.fetchStatus !== undefined &&
      pendingApprovalRequestRecordListService.fetchStatus === API_RESPONSE_STATUS.SUCCEEDED &&
      pendingApprovalRequestRecordListService.data !== undefined &&
      Array.isArray(pendingApprovalRequestRecordListService.data) &&
      pendingApprovalRequestRecordListService.data.length > 0
    ) {
      if (date_data !== undefined && date_data !== "") {
        obj = pendingApprovalRequestRecordListService.data.find(
          (request: any) =>
            request.activationDate === date_data && request.posNo === posNo && request.zoneName === configTab.zoneName && request?.serviceType.toLowerCase() === serviceTypeName.toLowerCase()
        );
        if (obj !== undefined && obj.requestId !== undefined && obj.requestId !== "") {
          let error_message = `A request ${obj.requestId} is already raised for this implementation date by ${obj.requester || "Admin"}. Please request action on the same.`;
          CustomAlert(ALERT_TYPES.ERROR, error_message);
        }
      }
    }
    return obj;
  };

  const handleDateChangeEvent = (event: any, picker: any) => {
    setStateObj({ ...stateObj, loading: true });
    if (event && event.target) {
      if (event.target.name === "date") {
        let isDateValid = false;
        let data_present_obj = {};
        const dateStart = getDateFromPicker(picker.startDate);
        if (moment(dateStart).isSameOrAfter(moment(new Date()).format("YYYY-MM-DD"))) {
          data_present_obj = is_request_already_present(dateStart);
          if (isObjectEmptyOrNull(data_present_obj)) {
            isDateValid = true;
          }
        } else {
          CustomAlert(ALERT_TYPES.ERROR, "Please provide a valid date");
        }

        setDateObject({
          ...dateObject,
          activationDate: dateStart,
          activationDatelabel: dateStart,
          isDateValid: isDateValid,
          data_present_obj: data_present_obj,
        });
      }
    }
    setStateObj({ ...stateObj, loading: false });
  };

  const handleCancel = (event: any, picker: any) => {
    event.preventDefault();
    picker.element.val("");
    setDateObject({
      ...dateObject,
      activationDate: "",
      activationDatelabel: "",
      isDateValid: false,
      data_present_obj: {},
    });
  };

  const makeSlotEditable = (generatorFormIndex: any, slot_index: any) => {
    if (generatorFormIndex !== undefined && generatorFormIndex >= 0 && slot_index !== undefined && slot_index >= 0) {
      let new_config_tab = update_make_slot_editable(configTab, generatorFormIndex, slot_index);
      setConfigTab(new_config_tab);
    }
  };

  const handleOnDemandServiceTimeChange = (generatorFormIndex: any, slot_index: any, selected_date: any, input_name: any) => {
    if (selected_date !== undefined && selected_date !== "" && input_name !== undefined && input_name !== "") {
      let new_config_tab = update_handle_individual_slot_field_change(configTab, generatorFormIndex, slot_index, input_name, selected_date);
      setConfigTab(new_config_tab);
    }
  };

  const handleIndividualSlotFieldsChange = (generatorFormIndex: any, slot_index: any, event: any) => {
    if (event !== undefined && event.target !== undefined) {
      const { name, value } = event.target;
      let new_config_tab = update_handle_individual_slot_field_change(configTab, generatorFormIndex, slot_index, name, value);
      setConfigTab(new_config_tab);
    }
  };

  const addNewTemplateSlot = (generatorFormIndex: any, days: any) => {
    if (generatorFormIndex !== undefined && generatorFormIndex >= 0) {
      if (configTab !== undefined && configTab.zoneId !== undefined && configTab.zoneId !== "") {
        let new_config_tab = add_new_template_slot_util(configTab, generatorFormIndex, configTab.zoneId, days, posDetails);
        setConfigTab(new_config_tab);
      } else {
        CustomAlert(ALERT_TYPES.ERROR, "Inernal error : No Zone Id found.");
      }
    }
  };

  const deleteTemplateSlot = (generatorFormIndex: any, slot_index: any) => {
    if (generatorFormIndex !== undefined && generatorFormIndex >= 0 && slot_index !== undefined && slot_index >= 0) {
      let new_config_tab = delete_template_slot_util(configTab, generatorFormIndex, slot_index);
      setConfigTab(new_config_tab);
    }
  };

  const handleBulkUpdateAction = (generatorFormIndex: any) => {
    if (generatorFormIndex !== undefined && generatorFormIndex >= 0) {
      const is_all_slots_valid = validate_generator_form_before_bulk_update(configTab);
      if (is_all_slots_valid !== undefined && is_all_slots_valid === true) {
        if (
          configTab !== undefined &&
          configTab.slotsGeneratorForms !== undefined &&
          Array.isArray(configTab.slotsGeneratorForms) &&
          configTab.slotsGeneratorForms.length > 0 &&
          generatorFormIndex !== undefined &&
          generatorFormIndex >= 0 &&
          configTab.slotsGeneratorForms[generatorFormIndex] !== undefined &&
          configTab.slotsGeneratorForms[generatorFormIndex].generatedSlots !== undefined
        ) {
          const is_slot_obj_valid = check_if_slot_obj_contains_sub_set(configTab.slotsGeneratorForms[generatorFormIndex].generatedSlots);
          if (is_slot_obj_valid !== undefined && typeof is_slot_obj_valid === "object" && Object.keys(is_slot_obj_valid).length > 0) {
            CustomAlert(ALERT_TYPES.ERROR, is_slot_obj_valid[Object.keys(is_slot_obj_valid)[0]]);
            return;
          } else {
            let allDays = [] as any;
            const currentDay = configTab.slotsGeneratorForms[generatorFormIndex]?.days[0];
            configTab.slotsGeneratorForms.forEach((slot: any) => {
              if (slot !== undefined && slot.days !== undefined && Array.isArray(slot.days) && slot.days.length > 0) {
                if (slot.days[0] !== undefined && slot.days[0] !== "" && slot.days[0] !== currentDay) {
                  allDays = [...allDays, ...slot.days];
                }
              }
            });

            let generated_form = { ...configTab.slotsGeneratorForms[generatorFormIndex] };
            if (generated_form !== undefined && generated_form.generatedSlots !== undefined && Array.isArray(generated_form.generatedSlots) && generated_form.generatedSlots.length > 0) {
              let generatedSlots = [] as any;
              generated_form.generatedSlots.forEach((slot: any) => {
                if (slot !== undefined && slot.slotAction !== undefined && slot.slotAction !== "" && slot.slotAction !== "DELETE") {
                  generatedSlots.push(slot);
                }
              });
              generated_form.generatedSlots = [...generatedSlots];
            }

            setStateObj({
              ...stateObj,
              loading: true,
              openBulkUpdateConfirmationPopUp: true,
              generatorFormIndex: generatorFormIndex,
              configuredDays: allDays,
              currentBulkUpdateConfigurationSummary: { ...generated_form },
              selctedDays: [],
              currentDay: currentDay,
              configurationBulkUpdatedObj: {
                isConfigurationBulkUpdated: false,
                bulkUpdatedDays: [],
              },
            });
          }
        }
      }
    }
  };

  const handleCloseBulkUpdateConfimationPopUp = () => {
    setStateObj({
      ...stateObj,
      loading: false,
      openBulkUpdateConfirmationPopUp: false,
      generatorFormIndex: "",
      configuredDays: [],
      currentBulkUpdateConfigurationSummary: {},
      selctedDays: [],
      currentDay: "",
      configurationBulkUpdatedObj: {
        isConfigurationBulkUpdated: false,
        bulkUpdatedDays: [],
      },
    });
  };

  const clearBulkUpdatedConfimationPopUpObj = (days: any) => {
    setStateObj({
      ...stateObj,
      loading: false,
      openBulkUpdateConfirmationPopUp: false,
      generatorFormIndex: "",
      configuredDays: [],
      currentBulkUpdateConfigurationSummary: {},
      selctedDays: [],
      currentDay: "",
      configurationBulkUpdatedObj: {
        isConfigurationBulkUpdated: true,
        bulkUpdatedDays: [...days],
      },
    });
  };

  const updateStandardServiceStateSelctedDays = (selectedDaysCopy: any) => {
    setStateObj({ ...stateObj, selctedDays: selectedDaysCopy });
  };

  const handleDaySelectedForBulkUpdate = (dayValue: any) => {
    if (dayValue !== undefined && dayValue !== "") {
      const selectedDaysCopy: any = [...stateObj.selctedDays];
      if (selectedDaysCopy !== undefined && Array.isArray(selectedDaysCopy) && selectedDaysCopy.length >= 0) {
        if (dayValue === TEXT_CONSTANT.ALL) {
          if (selectedDaysCopy.length === stateObj.configuredDays.length) {
            updateStandardServiceStateSelctedDays([]);
          } else {
            updateStandardServiceStateSelctedDays([...stateObj.configuredDays]);
          }
        } else {
          const isElementPresent = selectedDaysCopy.indexOf(dayValue);
          if (isElementPresent > -1) {
            selectedDaysCopy.splice(isElementPresent, 1);
          } else {
            selectedDaysCopy.push(dayValue);
          }
          updateStandardServiceStateSelctedDays(selectedDaysCopy);
        }
      }
    }
  };

  const handleBulkUpdateConfirmAction = () => {
    if (stateObj !== undefined && stateObj.selctedDays !== undefined && Array.isArray(stateObj.selctedDays) && stateObj.selctedDays.length > 0) {
      if (
        configTab !== undefined &&
        configTab.slotsGeneratorForms !== undefined &&
        Array.isArray(configTab.slotsGeneratorForms) &&
        configTab.slotsGeneratorForms.length > 0 &&
        stateObj.generatorFormIndex !== undefined &&
        Number(stateObj.generatorFormIndex) >= 0 &&
        configTab.slotsGeneratorForms[stateObj.generatorFormIndex] !== undefined &&
        stateObj.currentBulkUpdateConfigurationSummary !== undefined &&
        typeof stateObj.currentBulkUpdateConfigurationSummary == "object"
      ) {
        const slotForm = JSON.parse(JSON.stringify(stateObj.currentBulkUpdateConfigurationSummary));
        if (slotForm !== undefined && slotForm.generatedSlots !== undefined && Array.isArray(slotForm.generatedSlots) && slotForm.generatedSlots.length > 0) {
          let new_config_tab = bulk_update_slot_changes_util(configTab, stateObj.currentDay, stateObj.selctedDays, slotForm);
          setConfigTab(new_config_tab);
          clearBulkUpdatedConfimationPopUpObj(stateObj.selctedDays);
        }
      }
    } else {
      CustomAlert(ALERT_TYPES.ERROR, TEXT_CONSTANT.BULK_UPDATE_NO_DAYS_SELECTED);
    }
  };

  const resetCurrentConfigurationAfterBulkUpdateRevert = () => {
    if (express !== undefined && Array.isArray(express) && express.length > 0) {
      designExpressConfigurationsHandler(express);
    }
  };

  const call_send_for_approval = async (payload_Object: any) => {
    const { payload }: any = await appDispatch(sendConfigurationForApproval(payload_Object));
    if (payload && payload.response !== undefined && payload.response.data === "success") {
      CustomAlert(ALERT_TYPES.SUCCESS, `Configuration was successfully sent for approval.`);
      appDispatch(
        fetchPendingApprovalRequest({
          countryId: countryId,
          status: ["PENDING"],
        })
      );
      history.push(APP_ROUTES.POS_LIST);
    } else {
      setStateObj({ ...stateObj, loading: false });
      let errorMessage = "Error Occurred";
      if (payload.message && payload.message !== "No message available") {
        errorMessage = payload.message;
      }
      CustomAlert(ALERT_TYPES.ERROR, errorMessage);
    }
  };

  const send_request_for_approval = () => {
    setStateObj({ ...stateObj, loading: true });
    if (dateObject !== undefined && dateObject.activationDate !== undefined && dateObject.activationDate !== "") {
      const pay_load_object = _validate_send_for_approval_form(originalConfigTab, configTab, dateObject, countryId, stateObj);
      if (pay_load_object && typeof pay_load_object === "object" && Object.keys(pay_load_object).length !== 0) {
        call_send_for_approval(pay_load_object);
      } else {
        setStateObj({ ...stateObj, loading: false });
      }
    } else {
      CustomAlert(ALERT_TYPES.ERROR, "Please provide implementation date.");
      setStateObj({ ...stateObj, loading: false });
    }
  };

  //
  //

  const designExpressConfigurationsHandler = (slots: any) => {
    if (zoneId !== undefined && zoneId !== "") {
      let new_config_tabs = {} as any;
      new_config_tabs = design_express_configuration_handler_utils(slots, zoneId);
      setOriginalConfigTab(new_config_tabs);
      setConfigTab(new_config_tabs);
      setStateObj({ ...stateObj, loading: false, configurationBulkUpdatedObj: { isConfigurationBulkUpdated: false, bulkUpdatedDays: [] } });
    }
  };

  const handleCommentInputChange = (event: any) => {
    if (event !== undefined && event.target !== undefined) {
      const { name, value } = event.target;
      if (value !== undefined && value.length !== undefined && value.length > 200) {
        return;
      } else {
        setStateObj({ ...stateObj, approverComment: value });
      }
    }
  };

  const fetchSlots = () => {
    appDispatch(fetchSlotsInformationByServiceType({ posNo, serviceType: serviceTypeId, serviceTypeName }));
  };

  useEffect(() => {
    if (express && express.length) {
      designExpressConfigurationsHandler(express);
      appDispatch(
        fetchPendingApprovalRequest({
          countryId: countryId,
          status: ["PENDING"],
        })
      );
    }
  }, [express]);

  useEffect(() => {
    if (posNo && !posDetails.posNo && serviceTypes.length) {
      appDispatch(fetchPOSDetailsByPOSNo(posNo));
    }
  }, [appDispatch, posNo, serviceTypeId, serviceTypeName, posDetails.posNo, serviceTypes.length]);

  useEffect(() => {
    posNo !== undefined && posNo !== "" && serviceTypeId !== undefined && serviceTypeId !== "" && fetchSlots();
  }, [appDispatch, posNo, serviceTypeId, serviceTypeName]);

  useEffect(() => {
    return () => {
      appDispatch(resetSlotsInformation());
    };
  }, [appDispatch]);

  return (
    <>
      <Grid container spacing={0} className={classes.mainContentSection}>
        <OverlayLoader loading={fetchingSlots || stateObj.loading || fetchingDetails} />
        {/* Title */}
        <Grid item xs={12} className={classes.titleSectionDiv}>
          <PageTitle title={`Request Approval for Express POS - ${posNo} - ${configTab.zoneName || ""} `} />
        </Grid>

        <Grid item xs={12}>
          <Card className={classes.slotCardStyle}>
            <CardContent className={classes.slotCardContentStyle}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography className={classes.dateSearchTitleSpanStyle}>{`Implemantation time for changes`}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    {/* <CustomDateRangePicker
                      classes={classes}
                      value={dateObject.activationDatelabel}
                      handleDateChangeEvent={handleDateChangeEvent}
                      handleCancel={handleCancel}
                      isSingleDatePicker={true}
                      placeHolderText="Select implementation date"
                      allowPrevious={false}
                    /> */}
                  </Grid>
                </Grid>
              </Grid>

              {/*  */}
              {dateObject !== undefined && dateObject.activationDatelabel !== undefined && dateObject.activationDatelabel !== "" && dateObject.isDateValid && (
                <>
                  <Grid item xs={12} className={classes.titleSectionDiv}>
                    {configTab !== undefined &&
                    configTab.zoneId !== undefined &&
                    configTab.zoneId !== "" &&
                    configTab.slotsGeneratorForms !== undefined &&
                    Array.isArray(configTab.slotsGeneratorForms) &&
                    configTab.slotsGeneratorForms.length > 0 ? (
                      <RenderExpressConfigurationSlotsAccordion
                        classes={classes}
                        configTab={configTab}
                        makeSlotEditable={makeSlotEditable}
                        deleteTemplateSlot={deleteTemplateSlot}
                        addNewTemplateSlot={addNewTemplateSlot}
                        handleOnDemandServiceTimeChange={handleOnDemandServiceTimeChange}
                        handleIndividualSlotFieldsChange={handleIndividualSlotFieldsChange}
                        // Bulk Update
                        bulkUpdatedDays={stateObj.configurationBulkUpdatedObj.bulkUpdatedDays}
                        handleBulkUpdateAction={handleBulkUpdateAction}
                        resetCurrentConfigurationAfterBulkUpdateRevert={resetCurrentConfigurationAfterBulkUpdateRevert}
                      />
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={12} className={classes.messageGridItemStyle}>
                    <Accordion defaultExpanded={true} className={classes.accordionStyle}>
                      <AccordionSummary expandIcon={<ExpandMoreAccordianIcon />} className={classes.accordionSummaryStyle}>
                        <Grid container alignItems="center">
                          <Grid>
                            <Typography className={classes.accordionTitle}>
                              Approver Comment <span className={classes.accordionTitleNoteStyle}>(optional)</span>
                            </Typography>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionDetailsStyle}>
                        <CustomTextArea
                          label={""}
                          name={"requesterComments"}
                          value={stateObj.requesterComments}
                          placeholderText={"Message for Approver (Max 200 characters)"}
                          handleChange={handleCommentInputChange}
                          disabled={false}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </>
              )}

              {dateObject !== undefined && dateObject.data_present_obj !== undefined && dateObject.data_present_obj.requestId !== undefined && dateObject.data_present_obj.requestId !== "" && (
                <Card className={classes.errorCardStyle}>
                  <CardContent>
                    <Typography className={classes.errorTextSpanStyle}>{`A request ${dateObject.data_present_obj.requestId} is already raised for this implementation date by ${
                      dateObject.data_present_obj.requester || "Admin"
                    }. Please request action on the same.`}</Typography>
                  </CardContent>
                </Card>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Footer */}
        <RenderFooterButtons classes={classes} serviceData={express} history={history} posDetails={posDetails} send_request_for_approval={send_request_for_approval} dateObject={dateObject} />
      </Grid>

      <BulkUpdateExpressPopUp
        popUpOpenFlag={stateObj.openBulkUpdateConfirmationPopUp}
        currentConfiguration={stateObj.currentBulkUpdateConfigurationSummary}
        configuredDays={stateObj.configuredDays}
        selctedDays={stateObj.selctedDays}
        currentDay={stateObj.currentDay}
        handleClose={handleCloseBulkUpdateConfimationPopUp}
        handleDaySelectedForBulkUpdate={handleDaySelectedForBulkUpdate}
        handleBulkUpdateConfirmAction={handleBulkUpdateConfirmAction}
      />
    </>
  );
};

export default React.memo(NewExpressRequest);
