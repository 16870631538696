import moment from "moment";

import { createListBetweenTwoNumbers } from "../../utils/helperFunctions";
import { EXPRESS_TYPES, SERVICE_TYPES } from "./../../config/constants";

// Tab list for cuntry setting

export const ServiceTypeTabList: Array<any> = [
  {
    serviceTypeLabel: "Standard",
    serviceType: SERVICE_TYPES.STANDARD,
    countKey: "standard",
    countStyle: "standardServiceTypeCount",
  },
  {
    serviceTypeLabel: "Express",
    serviceType: SERVICE_TYPES.EXPRESS,
    countKey: "express",
    countStyle: "expressServiceTypeCount",
  },
  {
    serviceTypeLabel: "Click & Collect",
    serviceType: SERVICE_TYPES.CLICK_N_COLLECT,
    countKey: "cnc",
    countStyle: "cncServiceTypeCount",
    hideDivisonView: true,
  },
  {
    serviceTypeLabel: "Default",
    serviceType: SERVICE_TYPES.DEFAULT,
    countKey: "default",
    countStyle: "defaultServiceTypeCount",
  },
  {
    serviceTypeLabel: "FBC",
    serviceType: SERVICE_TYPES.FBC,
    countKey: "fbc",
    countStyle: "fbcServiceTypeCount",
  },
];

// Create Payload Data For Api
export function countrySettingsDetailsPayload(
  countryName: string,
  standardDetails: any,
  expressDetails: any,
  clickCollectDetails: any,
  defaultDetails: any,
  areaPosMappingType: any,
  weeklyHolidays: any
) {
  let calendarHoliday =
    defaultDetails.holidays && defaultDetails.holidays.length > 0
      ? defaultDetails.holidays.map((info: any) => ({
          offDay: "",
          offDate: info?.objectDate || "",
          active: true,
        }))
      : [];

  let weeklyholiday =
    weeklyHolidays.holidays && weeklyHolidays.holidays.length > 0
      ? weeklyHolidays.holidays.map((info: any) => ({
          offDay: info,
          offDate: "",
          active: true,
        }))
      : [];

  const body = {
    countryName,
    serviceTypes: [
      {
        serviceTypeID: standardDetails.serviceTypeID,
        advanceSlotDays: standardDetails.editSlotDays,
        nextDaySlotVisibility: standardDetails.nextDaySlotVisibility,
        bagChargeEnabled: standardDetails.bagChargeEnabled,
        serviceActive: standardDetails.serviceActive,
      },
      {
        serviceTypeID: expressDetails.serviceTypeID,
        advanceSlotDays: expressDetails.editSlotDays,
        nextDaySlotVisibility: expressDetails.nextDaySlotVisibility,
        expressType: expressDetails.expressType,
        bagChargeEnabled: expressDetails.bagChargeEnabled,
        serviceActive: expressDetails.serviceActive,
      },
      {
        serviceTypeID: clickCollectDetails.serviceTypeID,
        advanceSlotDays: clickCollectDetails.editSlotDays,
        nextDaySlotVisibility: clickCollectDetails.nextDaySlotVisibility,
        bagChargeEnabled: clickCollectDetails.bagChargeEnabled,
        serviceActive: clickCollectDetails.serviceActive,
      },
      {
        serviceTypeID: defaultDetails.serviceTypeID,
        holidays: [...calendarHoliday, ...weeklyholiday],
        bagChargeEnabled: defaultDetails.bagChargeEnabled,
        serviceActive: defaultDetails.serviceActive,
      },
    ],
    areaPosMappingType: areaPosMappingType,
  };
  return body;
}

// initial value for standard service type
export const StandardInitConfig = {
  serviceTypeID: 1,
  nextDaySlotVisibility: true,
  showAdvanceSlotDaysInput: false,
  advanceSlotDays: 7,
  editSlotDays: 7,
  bagChargeEnabled: false,
  serviceActive: true,
};

// initial value for Express service type
export const ExpressInitConfig = {
  serviceTypeID: 3,
  nextDaySlotVisibility: true,
  expressType: EXPRESS_TYPES.ON_DEMAND.value,
  showAdvanceSlotDaysInput: false,
  advanceSlotDays: 2,
  editSlotDays: 2,
  bagChargeEnabled: false,
  serviceActive: true,
};

// initial value for standard Click & Collect type
export const ClickCollectInitConfig = {
  serviceTypeID: 4,
  nextDaySlotVisibility: true,
  showAdvanceSlotDaysInput: false,
  advanceSlotDays: 7,
  editSlotDays: 7,
  bagChargeEnabled: false,
  serviceActive: true,
};

export const DefaultInitConfig = {
  serviceTypeID: 2,
  holidays: [],
  bagChargeEnabled: false,
  serviceActive: true,
};

export const FBCInitialConfig = {
  serviceTypeID: 124124,
  serviceActive: true,
};

export const WeeklyHolidayConfig = {
  holidays: [],
};

export const ADVANCE_DAYS_DDN_OPTIONS = createListBetweenTwoNumbers(2, 28);

export function getDateFromPicker(dateData: any) {
  return moment(dateData).format("YYYY-MM-DD");
}

function getDates(startDate: Date, stopDate: Date) {
  var dateArray: any = [];

  var currDate = moment(startDate).startOf("day");
  var lastDate = moment(stopDate).startOf("day");

  if (currDate) {
    const objectDate = moment(currDate.toDate()).format("YYYY-MM-DD");
    dateArray.push({ objectDate });
  }
  while (currDate.add(1, "days").diff(lastDate) <= 0) {
    const objectDate = moment(currDate.toDate()).format("YYYY-MM-DD");
    dateArray.push({ objectDate });
  }
  return dateArray;
}

export function getHolidaysListObject(dateObject: any) {
  if (dateObject && dateObject.dateFrom && dateObject.dateTo) {
    const dateDataArray = getDates(dateObject.dateFrom, dateObject.dateTo);
    return dateDataArray;
  }
  return [];
}
