import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchCurrentConfigurationService,
  fetchCurrentConfigurationServiceBasedOnSearch,
  fetchCurrentConfigurationServiceBasedOnSearchVersionTwo,
  getPOSZoneMappingList,
  getRuleEngineConfigurations,
  getRuleEngineConfigurationsVersionTwo,
  saveRuleEngineConfigurations,
  saveRuleEngineConfigurationsVersionTwo,
} from "../../../api/RuleEngineService";
import { API_RESPONSE_STATUS } from "../../../config/constants";

const transformResponseHelper = ({ data: rawResponse, ...rest }: any): any => {
  let newResponse = {
    ...rawResponse,
    list: rawResponse.posAndZoneDetails.content
      ? rawResponse.posAndZoneDetails.content.map((p: any) => ({
          ...p,
          value: p.zoneId,
          label: `${p.posNo}-${p.posName}-${p.zoneName}-${p.status}` || p.posNoPosNameZoneName,
        }))
      : [],
    posAndZoneDetails: {
      ...rawResponse.posAndZoneDetails,
      content: rawResponse.posAndZoneDetails.content
        ? rawResponse.posAndZoneDetails.content.map((p: any) => ({
            ...p,
            value: p.zoneId,
            label: `${p.posNo}-${p.posName}-${p.zoneName}-${p.status}` || p.posNoPosNameZoneName,
          }))
        : [],
    },
  };

  return { data: { ...newResponse }, ...rest };
};

export const fetchPOSZoneMappingList: any = createAsyncThunk(
  "ruleEngine/fetchPOSZoneMappingList",
  async ({ countryId, serviceName, serviceTypeId, query, pageNumber, dynamicPromiseType }: any, { rejectWithValue }) => {
    try {
      const payload = {
        countryId,
        serviceTypeId,
        pageNumber,
        pageSize: 1000,
        query,
        dynamicPromiseType,
      };
      const { response }: any = await getPOSZoneMappingList(payload);
      let tranformedResponse = transformResponseHelper(response);
      return tranformedResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saveDeliveryRuleEngineConfig: any = createAsyncThunk("ruleEngine/saveDeliveryRuleEngineConfig", async (requestPayload: any, { rejectWithValue }) => {
  try {
    const { response }: any = await saveRuleEngineConfigurationsVersionTwo(requestPayload);
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDeliveryRuleEngineConfig: any = createAsyncThunk("ruleEngine/getDeliveryRuleEngineConfig", async ({ requestPayload }: any, { rejectWithValue }) => {
  try {
    const { response }: any = await getRuleEngineConfigurationsVersionTwo(requestPayload);
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchCurrentConfigurationAction: any = createAsyncThunk("ruleEngine/serviceCurrentConfiguration", async ({ countryId, serviceTypeName, page, size, search }: any, { rejectWithValue }) => {
  try {
    const { response }: any = await fetchCurrentConfigurationService({ countryId, serviceTypeName }, { size, page, search });
    return response;
  } catch (error) {
    rejectWithValue(error);
  }
});

export const fetchCurrentConfigurationBasedOnSearchAction: any = createAsyncThunk(
  "ruleEngine/serviceCurrentConfigurationBasedOnSearch",
  async ({ countryId, serviceTypeName, page, size, search }: any, { rejectWithValue }) => {
    try {
      const { response }: any = await fetchCurrentConfigurationServiceBasedOnSearchVersionTwo({ countryId, serviceTypeName }, { size, page, search });
      return response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const getArgsFromAction = (action: any) => {
  const {
    meta: {
      arg: { serviceTypeName },
    },
    payload,
  } = action;

  return { serviceTypeName, payload };
};

const commonFetchInitialState = {
  fetchStatus: API_RESPONSE_STATUS.IDLE,
  error: "",
  data: { list: [] },
};

const ruleEngineSlice = createSlice({
  name: "ruleEngine",
  initialState: {
    setUp: {
      STANDARD: {},
      EXPRESS: {},
    },
    currentConfiguration: {
      STANDARD: { ...commonFetchInitialState, data: [] },
      EXPRESS: { ...commonFetchInitialState, data: [] },
    },
  },
  reducers: {
    resetPOSZoneMappingList: (state: any, action: any) => {
      const { serviceTypeName } = action.payload;
      state.setUp[serviceTypeName] = { ...commonFetchInitialState };
    },
  },
  extraReducers: {
    [fetchPOSZoneMappingList.pending]: (state: any, action: any) => {
      const { serviceTypeName } = getArgsFromAction(action);
      state.setUp[serviceTypeName] = {
        ...commonFetchInitialState,
        ...state.setUp[serviceTypeName],
        fetchStatus: API_RESPONSE_STATUS.LOADING,
      };
    },
    [fetchPOSZoneMappingList.fulfilled]: (state: any, action: any) => {
      const { serviceTypeName, payload } = getArgsFromAction(action);

      state.setUp[serviceTypeName] = {
        ...commonFetchInitialState,
        fetchStatus: API_RESPONSE_STATUS.SUCCEEDED,
        data: { ...payload.data, list: [...state.setUp[serviceTypeName].data.list, ...payload.data.list] },
      };
    },
    [fetchPOSZoneMappingList.rejected]: (state: any, action: any) => {
      const { serviceTypeName, payload } = getArgsFromAction(action);

      state.setUp[serviceTypeName] = {
        ...commonFetchInitialState,
        fetchStatus: API_RESPONSE_STATUS.FAILED,
        error: payload.message,
      };
    },
    /** Current Configuration */
    [fetchCurrentConfigurationAction.pending]: (state: any, action: any) => {
      const { serviceTypeName } = getArgsFromAction(action);
      state.currentConfiguration[serviceTypeName] = {
        ...commonFetchInitialState,
        fetchStatus: API_RESPONSE_STATUS.LOADING,
      };
    },
    [fetchCurrentConfigurationAction.fulfilled]: (state: any, action: any) => {
      const { serviceTypeName, payload } = getArgsFromAction(action);
      state.currentConfiguration[serviceTypeName] = {
        ...commonFetchInitialState,
        fetchStatus: API_RESPONSE_STATUS.SUCCEEDED,
        data: payload,
      };
    },
    [fetchCurrentConfigurationAction.rejected]: (state: any, action: any) => {
      const { serviceTypeName, payload } = getArgsFromAction(action);
      state.currentConfiguration[serviceTypeName] = {
        ...commonFetchInitialState,
        fetchStatus: API_RESPONSE_STATUS.FAILED,
        error: payload.message,
        data: [],
      };
    },
    /** Current Configuration Based On Search */
    [fetchCurrentConfigurationBasedOnSearchAction.pending]: (state: any, action: any) => {
      const { serviceTypeName } = getArgsFromAction(action);
      state.currentConfiguration[serviceTypeName] = {
        ...commonFetchInitialState,
        fetchStatus: API_RESPONSE_STATUS.LOADING,
      };
    },
    [fetchCurrentConfigurationBasedOnSearchAction.fulfilled]: (state: any, action: any) => {
      const { serviceTypeName, payload } = getArgsFromAction(action);
      state.currentConfiguration[serviceTypeName] = {
        ...commonFetchInitialState,
        fetchStatus: API_RESPONSE_STATUS.SUCCEEDED,
        data: payload,
      };
    },
    [fetchCurrentConfigurationBasedOnSearchAction.rejected]: (state: any, action: any) => {
      const { serviceTypeName, payload } = getArgsFromAction(action);
      state.currentConfiguration[serviceTypeName] = {
        ...commonFetchInitialState,
        fetchStatus: API_RESPONSE_STATUS.FAILED,
        error: payload.message,
        data: [],
      };
    },
  },
});

export default ruleEngineSlice.reducer;

export const { resetPOSZoneMappingList } = ruleEngineSlice.actions;

export const selectServicesPOSZoneMappingList = (state: any, serviceName: string) => state.ruleEngine.setUp[serviceName];

export const selectDeliveryPromiseServiceCurrConfiguration = (state: any, serviceName: string) => state.ruleEngine.currentConfiguration[serviceName];
