import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Tooltip } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Grid from "@material-ui/core/Grid";

// SVG
import ExpandMoreIcon from "../../assets/svg/ExpandAccordionIcon.svg";
import EditIcon from "../../assets/svg/Pencil.svg";

// Files
import CustomAlert from "../../common/CustomAlert/CustomAlert";
import LinkButton from "../../common/LinkButton/LinkButton";
import Loader from "../../common/Loader/Loader";

import {
  ALERT_TYPES,
  APP_ROUTES,
  EXPORT_STANDARD_AND_EXPRESS_ENABLE_FLAG,
  EXPRESS_TYPES,
  HH_MM_SS,
  SERVICE_TYPES,
  STATUS_OK,
  STORE_STATE,
  USER_TYPES,
  ZONE_CHANGE_STATUS,
} from "../../../config/constants";
import { selectAllAreas, selectServiceTypes } from "../../../config/redux/configurationsSlice";
import { downloadCSVFile, getServiceTypeDetailsFromServiceName } from "../../../utils/helperFunctions";
import { selectCountrySettings } from "../../CountrySettings/redux/countrySettingsSlice";
import {
  activateDeactivateCollectionPoint,
  activateOrDeactivateAServiceOfPOS,
  downloadServiceConfigCSV,
  activateOrDeactivateAServiceZoneOfPOS,
  updateZoneStateForOrders,
} from "../redux/completeSetupSlice";
import { PosStatusToggle } from "../../POSList/posUtils/PosAccessUtils";

// Styles
import slotsInformationStyles from "./SlotsInformationStyles";

// Pop Up
import ActivateDeactivateZoneErrorPopUp from "./common/ActivateDeactivateZoneErrorPopUp";
import { is_all_service_toggle_visible, is_configure_service_type_link_visible, is_edit_service_type_visible, is_user_can_create_approval_request } from "../CompleteSetupUtils";
import DetailedSlotInformationOnCompleteSetup from "./common/DetailedSlotInformationOnCompleteSetup";

const SlotsInformation = ({ posNo, serviceTypeName, serviceTypeId, slotsInformation = {}, userInfo = {} }) => {
  const classes = slotsInformationStyles();
  const dispatch = useDispatch();

  const allAreas = useSelector(selectAllAreas);
  const serviceTypes = useSelector(selectServiceTypes);
  const { countryDetails } = useSelector(selectCountrySettings);

  const { loading, error, active, slots, toggling } = slotsInformation;

  const [selectedTab, setSelectedTab] = useState(
    slots && slots.length && slots[0].zoneConfig && slots[0].zoneConfig.id
      ? slots[0].zoneConfig.id.toString()
      : slots && slots.length && slots[0].zone && slots[0].zone.zoneConfig && slots[0].zone.zoneConfig.id
      ? slots[0].zone.zoneConfig.id.toString()
      : 0
  );
  const [expanded, setExpanded] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [activateDeactivateZoneError, setActivateDeactivateZoneError] = useState({
    showActivateDeactivateZoneErrorPopUp: false,
    activateDeactivateZoneErrorDetails: {},
  });

  const getExpressType = React.useMemo(() => {
    let expressType = EXPRESS_TYPES.SLOT_BASED.value;

    if (countryDetails && countryDetails.status === STATUS_OK && serviceTypes.length) {
      let {
        data: { serviceTypes: countrySettingServices },
      } = countryDetails;

      let expressId = getServiceTypeDetailsFromServiceName(serviceTypes, SERVICE_TYPES.EXPRESS).id;

      expressType = countrySettingServices.find((s) => s.serviceTypeID === expressId).expressType;
    }

    return expressType;
  }, [countryDetails, serviceTypes]);

  /* Temporary disabling standard and express service export */
  const isExportCSVDisabled = (serviceName = "") => {
    let isDisabled = true;

    if (serviceName === SERVICE_TYPES.CLICK_N_COLLECT) return false;

    if (EXPORT_STANDARD_AND_EXPRESS_ENABLE_FLAG) return false;

    return isDisabled;
  };

  const getCollectionPointDetailsByCollectionName = (cpName, allCollectionPoints) => {
    let allCollectionPointsObj = { ...allCollectionPoints };

    let cpDetails = allCollectionPointsObj[cpName] && allCollectionPointsObj[cpName].collectionPointDetails;
    return cpDetails || {};
  };

  const handleServiceActiveInactiveSwitch = async (e, selectedService) => {
    const { checked } = e.target;
    const {
      payload: { statusCode, message: error },
    } = await dispatch(
      activateOrDeactivateAServiceOfPOS({
        posNo,
        serviceType: serviceTypeId,
        serviceTypeName: serviceTypeName.toUpperCase(),
        active: checked,
      })
    );
    if (statusCode === STATUS_OK) {
      let message = checked ? `Service activated Successfully` : `Service de-activated Successfully`;
      CustomAlert(ALERT_TYPES.SUCCESS, message);
    }

    if (error) {
      CustomAlert(ALERT_TYPES.SUCCESS, error);
    }
  };

  const handleCollectionPointToggleSwitch = async (e, cpName, allCollectionPoints) => {
    const { checked } = e.target;
    const { collectionPointId: cpID, zoneId } = getCollectionPointDetailsByCollectionName(cpName, allCollectionPoints);

    const {
      payload: { status, message: error },
    } = await dispatch(
      activateDeactivateCollectionPoint({
        serviceType: serviceTypeId,
        serviceTypeName: serviceTypeName.toUpperCase(),
        zoneId,
        collectionPointId: cpID,
        active: checked,
      })
    );
    if (status === STATUS_OK) {
      let message = checked ? `Collection point - ${cpName} activated Successfully` : `Collection point - ${cpName} de-activated Successfully`;
      CustomAlert(ALERT_TYPES.SUCCESS, message);
    }

    if (error) {
      CustomAlert(ALERT_TYPES.SUCCESS, error);
    }
  };

  const handleExportCSVClick = async (e) => {
    e.stopPropagation();
    setIsExporting(true);
    const {
      payload: { message: reportDownloadError, csvData, fileName },
    } = await dispatch(downloadServiceConfigCSV({ serviceTypeName, posNo }));

    setIsExporting(false);
    if (reportDownloadError) CustomAlert(ALERT_TYPES.ERROR, reportDownloadError);

    if (csvData && fileName) {
      downloadCSVFile(fileName, csvData);
    }
  };

  const handleActivateDeactivateZoneConfigClick = async (event, tabIndex, id) => {
    const { checked } = event.target;
    event.stopPropagation();
    const requestObject = {
      posNo,
      serviceType: serviceTypeId,
      serviceTypeName: serviceTypeName.toUpperCase(),
      activstatus: checked === true ? ZONE_CHANGE_STATUS.ACTIVATED : ZONE_CHANGE_STATUS.DEACTIVATED,
      tabIndex: tabIndex,
      zoneId: id,
      checked: checked,
    };
    const { payload } = await dispatch(activateOrDeactivateAServiceZoneOfPOS(requestObject));
    if (payload && payload.status !== undefined && payload.status === "OK") {
      if (payload !== undefined && payload.data !== undefined && payload.data.success !== undefined && payload.data.success === true) {
        let message = checked ? `Zone activated Successfully` : `Zone de-activated Successfully`;
        CustomAlert(ALERT_TYPES.SUCCESS, message);
      } else {
        setActivateDeactivateZoneError({
          showActivateDeactivateZoneErrorPopUp: true,
          activateDeactivateZoneErrorDetails: {
            message: payload.message,
            data: payload.data.data || [],
          },
        });
      }
    } else {
      CustomAlert(ALERT_TYPES.ERROR, payload.message);
    }
  };

  const is_all_pos_active_disable_status = (slotsRecord) => {
    if (slotsRecord !== undefined && Array.isArray(slotsRecord) && slotsRecord.length > 0) {
      let count = 0;
      slotsRecord.forEach((slot) => {
        if (slot !== undefined && slot.zoneConfig !== undefined && slot.zoneConfig.status !== undefined && slot.zoneConfig.status === "DEACTIVATED") {
          count++;
        } else if (slot !== undefined && slot.zone !== undefined && slot.zone.zoneConfig !== undefined && slot.zone.zoneConfig.status !== undefined && slot.zone.zoneConfig.status === "DEACTIVATED") {
          count++;
        }
      });
      if (count === slotsRecord.length) {
        return true;
      }
    }
    return false;
  };

  const handle_close_activate_deactivate_zone_pop_up = () => {
    setActivateDeactivateZoneError({
      showActivateDeactivateZoneErrorPopUp: false,
      activateDeactivateZoneErrorDetails: {},
    });
  };

  const handleExpressStoreStateChange = async (event, tabIndex, zoneId, stateValue) => {
    event.stopPropagation();
    const requestObject = {
      posNo,
      serviceType: serviceTypeId,
      serviceTypeName: serviceTypeName.toUpperCase(),
      tabIndex: tabIndex,
      zoneId: zoneId,
      orderState: stateValue,
      payload: { state: stateValue },
    };
    const { payload } = await dispatch(updateZoneStateForOrders(requestObject));
    if (payload && payload.status && payload.status === "OK") {
      let message = stateValue === STORE_STATE.AVAILABLE ? `Zone is accepting orders` : `Zone is not accepting orders`;
      CustomAlert(ALERT_TYPES.SUCCESS, message);
    } else {
      CustomAlert(ALERT_TYPES.ERROR, payload.message);
    }
  };

  useEffect(() => {
    if (!error && !loading && slots) {
      const tabId =
        slots && slots.length && slots[0].zoneConfig && slots[0].zoneConfig.id
          ? slots[0].zoneConfig.id.toString()
          : slots && slots.length && slots[0].zone && slots[0].zone.zoneConfig && slots[0].zone.zoneConfig.id
          ? slots[0].zone.zoneConfig.id.toString()
          : null;
      setSelectedTab(tabId);
    }
  }, [error, loading, slots]);

  return (
    <>
      <div className={classes.slotsInfoContainer}>
        {loading ? (
          <Loader />
        ) : slots !== undefined && Array.isArray(slots) && slots.length > 0 ? (
          <Accordion className={classes.activeAccordion} expanded={expanded} onClick={() => setExpanded(!expanded)}>
            <AccordionSummary expandIcon={<img src={ExpandMoreIcon} alt="Expand Accordion Icon" />} className={`${classes.accordionSummary} ${classes.serviceAccSummary}`}>
              <Grid container alignItems="center">
                <Grid item xs={7} className={classes.activeServiceType}>
                  <div className={classes.textCapitalize}>{`${serviceTypes.find((s) => s.name === serviceTypeName).displayName}`}</div>
                </Grid>
                <Grid item xs={5}>
                  <Grid container justifyContent="flex-end">
                    {toggling ? (
                      <Grid item xs={5} className={classes.posRelative}>
                        <Loader />
                      </Grid>
                    ) : is_all_service_toggle_visible(userInfo) ? (
                      <PosStatusToggle
                        userInfo={userInfo}
                        serviceTypeName={serviceTypeName}
                        classes={classes}
                        checked={Boolean(active)}
                        handleServiceActiveInactiveSwitch={(e) => handleServiceActiveInactiveSwitch(e, serviceTypeName)}
                        disabled={is_all_pos_active_disable_status(slots)}
                      />
                    ) : (
                      <></>
                    )}
                    <Grid item xs={3}>
                      <LinkButton buttonLabel="Export CSV" onClick={handleExportCSVClick} disabled={isExporting || isExportCSVDisabled(serviceTypeName)} />
                    </Grid>
                    <Grid item xs={2} className={classes.editServiceIcon}>
                      {is_edit_service_type_visible(userInfo) ? (
                        is_user_can_create_approval_request(userInfo) ? (
                          (serviceTypeName.toUpperCase() === SERVICE_TYPES.STANDARD || serviceTypeName.toUpperCase() === SERVICE_TYPES.EXPRESS) && (
                            <Link className={classes.dFlex} to={`${APP_ROUTES.CREATE_POS}${APP_ROUTES.SERVICE_AND_SLOTS}/${serviceTypeName.toLowerCase()}/${posNo}`}>
                              <Tooltip title={`${is_user_can_create_approval_request(userInfo) ? "Create a request for a new POS Zone configuration" : "Edit POS"}`}>
                                <img src={EditIcon} alt="Edit POS" />
                              </Tooltip>
                            </Link>
                          )
                        ) : (
                          <Link className={classes.dFlex} to={`${APP_ROUTES.CREATE_POS}${APP_ROUTES.SERVICE_AND_SLOTS}/${serviceTypeName.toLowerCase()}/${posNo}`}>
                            <Tooltip title={`Edit POS`}>
                              <img src={EditIcon} alt="Edit POS" />
                            </Tooltip>
                          </Link>
                        )
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionSummary>
            {slots !== undefined && selectedTab !== undefined && (
              <AccordionDetails className={classes.serviceAccDetails} onClick={(e) => e.stopPropagation()}>
                {slots.map((tab, tabIndex) => (
                  <DetailedSlotInformationOnCompleteSetup
                    classes={classes}
                    userInfo={userInfo}
                    toggling={toggling}
                    tab={tab}
                    tabIndex={tabIndex}
                    serviceTypeName={serviceTypeName}
                    handleActivateDeactivateZoneConfigClick={handleActivateDeactivateZoneConfigClick}
                    handleCollectionPointToggleSwitch={handleCollectionPointToggleSwitch}
                    posNo={posNo}
                    handleExpressStoreStateChange={handleExpressStoreStateChange}
                  />
                ))}
              </AccordionDetails>
            )}
          </Accordion>
        ) : is_configure_service_type_link_visible(userInfo) ? (
          <Accordion className={classes.disableAccordion} expanded={expanded} onClick={undefined}>
            <AccordionSummary expandIcon={null} className={`${classes.accordionSummary} ${classes.serviceAccSummary}`}>
              <Grid container alignItems="center">
                <Grid item xs={7} className={classes.disabledServiceType}>
                  <Link to={`${APP_ROUTES.CREATE_POS}${APP_ROUTES.SERVICE_AND_SLOTS}/${serviceTypeName.toLowerCase()}/${posNo}`} className={classes.linkstyle}>
                    <LinkButton className={classes.configureServiceButton} buttonLabel={`Configure ${serviceTypeName.toLowerCase()}`} />
                  </Link>
                </Grid>
              </Grid>
            </AccordionSummary>
          </Accordion>
        ) : (
          <></>
        )}
      </div>

      {activateDeactivateZoneError && activateDeactivateZoneError.showActivateDeactivateZoneErrorPopUp === true && (
        <ActivateDeactivateZoneErrorPopUp
          open={activateDeactivateZoneError.showActivateDeactivateZoneErrorPopUp}
          handleClose={handle_close_activate_deactivate_zone_pop_up}
          detailsObj={activateDeactivateZoneError.activateDeactivateZoneErrorDetails}
        />
      )}
    </>
  );
};

export default SlotsInformation;
