import produce from "immer";
import CustomAlert from "../../../components/common/CustomAlert/CustomAlert";
import { ALERT_TYPES } from '../../../config/constants';
import { ALL_DAYS_VALUES, DEFAULT_SLOTS_GENERATOR_FORMS_VALUES } from "./constants";

export const COLLECTION_POINT_EDFAULT_STATE = {
    displayName: "",
    clickAndCollectType: "",
    referenceId: "",
    contactName: "",
    contactEmail: "",
    contactNumber: "",
    country: "",
    region: "",
    area: "",
    iso: "",
    addressLine1: "",
    addressLine2: "",
    street: "",
    poBox: "",
    latitude: "",
    longitude: "",
    addressSameAsPos: true,
    slotsGeneratorForms: [{ ...DEFAULT_SLOTS_GENERATOR_FORMS_VALUES, days: ALL_DAYS_VALUES }],
};

export const copyAddressDetailsFromPOSAddress = (posDetails: any) => {
    const {
        posAddress: { id, ...posAddressWithoutId },
        posContacts,
    } = posDetails;

    let addressSameAsPOSObj = {
        ...posAddressWithoutId,
        contactName: posContacts[0].name,
        contactEmail: posContacts[0].email,
        contactNumber: posContacts[0].phoneNumber || "",
    };

    return addressSameAsPOSObj;
};

export const validateZoneConfig = (configTabs: any, selectedConfigTab: any, errors: any) => {
    let currentTab = configTabs[selectedConfigTab];
    let newErrors: any = {};
    let isAnyError = false;

    // Remove as we implemented exclusion logic zone may not exclusion any type
    // if (!currentTab.foodTypes) {
    //     isAnyError = true;
    //     let errorMsg = "Please select product type";
    //     CustomAlert(ALERT_TYPES.ERROR, errorMsg);

    //     return !isAnyError;
    // }

    currentTab.collectionPoints.forEach((cp: any, index: number) => {
        if (!cp.displayName) {
            isAnyError = true;
            CustomAlert(ALERT_TYPES.ERROR, `Please enter display name for a collection point: ${index + 1}`);
            return !isAnyError;
        }

        if (!cp.clickAndCollectType) {
            isAnyError = true;
            CustomAlert(ALERT_TYPES.ERROR, `Please select a collection type for a collection point: ${index + 1}`);
            return !isAnyError;
        }

        if (!cp.referenceId) {
            isAnyError = true;
            CustomAlert("error", `Please enter reference ID for collection point: ${index + 1}`);
            return !isAnyError;
        }

        if (!cp.addressLine1) {
            isAnyError = true;
            CustomAlert("error", `Please enter Address Line 1 for collection point: ${index + 1}`);
            return !isAnyError;
        }

        if (!cp.latitude) {
            isAnyError = true;
            CustomAlert("error", `Please enter Latitude for collection point: ${index + 1}`);
            return !isAnyError;
        }

        if (!cp.longitude) {
            isAnyError = true;
            CustomAlert("error", `Please enter Longitude for collection point: ${index + 1}`);
            return !isAnyError;
        }

        cp.slotsGeneratorForms.forEach((sgf: any, sgfIndex: number) => {
            if (!sgf.generatedSlots.length) {
                isAnyError = true;
                CustomAlert(ALERT_TYPES.ERROR, `Please generate slots for collection point: ${index + 1}`);
                return !isAnyError;
            }
        });
    });

    let currentTabErrors = produce(errors, (draft: any) => {
        draft[selectedConfigTab] = { ...newErrors };
    });
    return { isError: (Object.keys(newErrors).filter((err: any) => newErrors[err]).length > 0 || isAnyError), currentTabErrors };
};