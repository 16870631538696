export const getFullAddress = (address) => {
  let fullAddressArr = [];

  let newAdress = {
    line1: address.addressLine1,
    line2: address.addressLine2,
    street: address.street,
    area: address.area,
    region: address.region,
    country: address.country,
    poBox: address.poBox,
    lat: address.latitude,
    long: address.longitude,
  };

  fullAddressArr = Object.keys(newAdress)
    .map((a) => newAdress[a])
    .filter((b) => b);

  return fullAddressArr.join(", ");
};

export const isObjectEmpty = (obj) => !!(obj && Object.keys(obj).length === 0);

export const getRegionNameFromRegionId = (regionId, regions) => {
  let regionObj = regions.find((region) => region.id === parseInt(regionId));
  let regionName = regionObj ? regionObj.name : regionId;
  return regionName;
};

export const getAreaNameFromAreaId = (areaId, areas, shouldAppendRegionName = false) => {
  let areaObj = areas.find((a) => a.id === parseInt(areaId));
  let areaName = areaObj ? (shouldAppendRegionName ? { areaName: areaObj.name, regionName: areaObj.regionName } : areaObj.name) : areaId;

  // Append region name with areaName
  return areaName;
};

export const getPOSTypeNameFromPosId = (posTypeId, posTypes) => {
  let posTypeObj = posTypes.filter((pt) => pt.id === parseInt(posTypeId))[0];

  let posTypeName = posTypeObj ? posTypeObj.name : posTypeId;
  return posTypeName;
};

export const convertMinutesToHours = (totalMinutes) => {
  let hours = Math.floor(totalMinutes / 60);
  let minutes = totalMinutes % 60;

  return { hours, minutes };
};

export const generateDropdownItems = (startingFrom = 0, endingAt = 20, itemLabel = "") => {
  let items = [];

  for (let i = startingFrom; i <= endingAt; i++) {
    items.push({ id: i, name: `${i} ${itemLabel}` });
  }

  return items;
};

export const getMaxNumberFromAnArray = (arr = []) => {
  let max = arr[0];

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] > max) {
      max = arr[i];
    }
  }

  return max || 0;
};

export const convertHoursToMinutes = (hours = 0) => {
  return hours * 60;
};

export const createListBetweenTwoNumbers = (min, max) => {
  let options = [];

  for (let i = min; i <= max; i++) {
    options.push({ id: i, name: i });
  }

  return options;
};

export const getServiceTypeDetailsFromServiceName = (services = [], serviceName) => {
  let serviceTypeDetails = services.find((s) => s.name.toUpperCase() === serviceName.toUpperCase());

  return serviceTypeDetails || {};
};

export const downloadCSVFile = (filename, text) => {
  if (window.navigator.msSaveBlob) {
    let blobObject = new Blob([text], { type: "text/csv" });
    window.navigator.msSaveBlob(blobObject, filename);
  } else {
    let anchor = document.createElement("a");
    anchor.download = filename;
    if (window.URL.createObjectURL) {
      let blobObject = new Blob([text], { type: "text/csv" });
      anchor.href = window.URL.createObjectURL(blobObject);
    } else {
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(text);
    }
    // Now, click it.
    if (document.createEvent) {
      let event = document.createEvent("MouseEvents");
      event.initEvent("click", true, true);
      anchor.dispatchEvent(event);
    } else {
      anchor.click();
    }
  }
};

export const convertSecondsIntoMinutes = (seconds) => {
  return seconds ? Math.floor(seconds / 60) : 0;
};

export const convertMinutesToHoursAndMinutes = (minutes) => {
  let hours = Math.floor(minutes / 60);
  let mins = minutes % 60;

  return hours === 0 ? `${mins} mins` : `${hours} hrs  ${mins} mins`;
};

export const getMinNumberFromAnArray = (arr = []) => {
  let min = arr[0];

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] < min) {
      min = arr[i];
    }
  }

  return min || 0;
};

export const generateDropdownWithMentionedIntervalItems = (startingFrom = 0, endingAt = 20, interval = 1, itemLabel = "") => {
  let items = [];
  for (let i = startingFrom; i <= endingAt; i += interval) {
    items.push({ id: i, name: `${i} ${itemLabel}` });
  }
  return items;
};

export const generateCustomVehicleTypeList = (listData) => {
  let items = [];
  if (listData !== undefined && Array.isArray(listData) && listData.length > 0) {
    listData.forEach((vehicle) => {
      items.push({ id: vehicle, name: vehicle });
    });
  }
  return items;
};

export const downloadXLSXFile = (fileName, response) => {
  let anchor = document.createElement("a");
  anchor.download = fileName;
  if (window.URL.createObjectURL) {
    let blobObject = new Blob([response], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    anchor.href = window.URL.createObjectURL(blobObject);
  } else {
    anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(response);
  }
  // Now, click it.
  if (document.createEvent) {
    let event = document.createEvent("MouseEvents");
    event.initEvent("click", true, true);
    anchor.dispatchEvent(event);
  } else {
    anchor.click();
  }
};

export const amountValueRegExp = new RegExp("^[0-9]*(.[0-9]{0,2})?$");
export const decimalRegex = /^(\d+([.]{1}\d*)?)$/;