import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../../../config/theme/theme";

export const userStyles = makeStyles(() =>
  createStyles({
    mainContainer: {
      top: "10%",
      left: "auto",
      right: "auto",
      width: "100%",
      height: "auto",
      margin: 0,
      padding: "24px",
      overflow: "auto",
    },
    cardContainer: {
      maxWidth: "calc(50vw)",
      maxHeight: "calc(40vh)",
      margin: "30vh auto 0px auto",
      padding: 0,
      overflow: "auto",
    },
    // Header
    gridContainerMain: {
      margin: 0,
      padding: 0,
    },
    gridContainerTitleDivStyle: {
      margin: 0,
      padding: "24px 0px 0px 0px",
      textAlign: "center",
      borderRadius: "12px",
      background: COLORS.WILDSAND,
    },
    headerStyleNormalDiv: {
      margin: 0,
      padding: "0px 12px 8px 12px",
      border: "none",
      borderBottom: "1px solid rgba(151, 151, 151, 0.5)",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "26px",
      color: "#DD2326",
    },
    // Header End
    // Body
    gridContainerContent: {
      margin: 0,
      padding: "8px 24px 24px 24px",
    },
    headingTextStyle: {
      fontSize: "14px",
      fontWeight: 300,
    },
    valueTextDivStyle: {
      margin: 0,
      padding: 0,
      paddingTop: "8px",
      display: "flex",
    },
    valueTextStyle: {
      fontSize: "16px",
      fontWeight: 500,
    },
    // Body End
    // Footer
    gridContainerFooter: {
      margin: 0,
      padding: "16px",
      display: "flex",
      justifyContent: "flex-end",
      borderTop: "1px solid rgba(151, 151, 151, 0.5)",
    },
    cancelButtonStyle: {
      width: "150px",
      background: COLORS.WHITE,
      border: `1px solid ${COLORS.PRIMARY_MAIN}`,
      borderRadius: "8px",
      color: COLORS.PRIMARY_MAIN,
      textDecoration: "none",
      "&:hover": {
        background: COLORS.WHITE,
        border: `1px solid ${COLORS.PRIMARY_MAIN}`,
      },
      "&.MuiButton-label": {
        background: COLORS.WHITE,
        border: `1px solid ${COLORS.PRIMARY_MAIN}`,
        "&:hover": {
          background: COLORS.WHITE,
          border: `1px solid ${COLORS.PRIMARY_MAIN}`,
        },
      },
    },
    // Footer End
  })
);
