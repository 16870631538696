import React from "react";

// Material UI Components
import FormControl from "@material-ui/core/FormControl";
import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import TimerIcon from "../../assets/svg/Timer.svg";
import timePickerWithLabelStyles from "./TimePickerWithLabelStyles";
import { InputLabel } from "@material-ui/core";

const TimePickerWithLabel = ({ label, className, value, handleChange, showTimeIcon = true, timerProps, ...props }) => {
  const classes = timePickerWithLabelStyles();

  const handleTimeChange = (time) => {
    let removedSecTime = time ? new Date(time.setSeconds(0, 0)) : time;
    handleChange(removedSecTime);
  };

  return (
    <FormControl className={classes.formControl} size="small" variant="outlined" margin="none">
      <InputLabel className={classes.inputlabelStyles}>{label}</InputLabel>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          {...props}
          ampm={false}
          className={`${classes.timePicker} ${!showTimeIcon ? classes.hideTimeIcon : ""} ${props.disabled ? classes.timePickerDisabled : ""}`}
          InputProps={{
            className: `${classes.timePickerInput} ${timerProps}`,
          }}
          InputLabelProps={{
            className: classes.timePickerLabelProps,
          }}
          placeholder="HH:MM"
          value={value}
          keyboardIcon={showTimeIcon ? <img className={classes.timerIcon} src={TimerIcon} alt="timer" /> : null}
          onChange={handleTimeChange}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
};

export default React.memo(TimePickerWithLabel);
