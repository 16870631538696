export interface ConfigurationBulkUpdatedInitialObjInterface {
  isConfigurationBulkUpdated: any;
  bulkUpdatedDays: any;
}

export const ConfigurationBulkUpdatedInitialObj: ConfigurationBulkUpdatedInitialObjInterface = {
  isConfigurationBulkUpdated: "",
  bulkUpdatedDays: [],
};

export interface ExpressServiceInitialStateInterface {
  loading: boolean;
  selectedConfigTab: any;
  request_already_presnt_by_admin_error: any;
  openBulkUpdateConfirmationPopUp: any;
  configTabIndex: any;
  generatorFormIndex: any;
  configuredDays: any;
  currentBulkUpdateConfigurationSummary: any;
  selctedDays: any;
  currentDay: any;
  configurationBulkUpdatedObj: ConfigurationBulkUpdatedInitialObjInterface;
  // Dialog
  showDeleteZCModal: any;
  showPreviewModal: any;
  // Implemantation date for admin
  activationDate: any;
  activationDatelabel: any;
  isDateValid: any;
  data_present_obj: any;
  // Original Data
  originalConfigTab: any;
  //
  showZoneChangeWarningPopUp: boolean;
  temporarySelectedZone: any;
  // approval request
  showApprovalRequestPopUpPreview: boolean;
  requesterComments: any;
}

export const ExpressServiceInitialState: ExpressServiceInitialStateInterface = {
  loading: false,
  selectedConfigTab: 0,
  request_already_presnt_by_admin_error: "",
  // Bulk Update
  openBulkUpdateConfirmationPopUp: false,
  configTabIndex: null,
  generatorFormIndex: null,
  configuredDays: [],
  currentBulkUpdateConfigurationSummary: {},
  selctedDays: [],
  currentDay: "",
  configurationBulkUpdatedObj: ConfigurationBulkUpdatedInitialObj,
  // Dialog
  showDeleteZCModal: false,
  showPreviewModal: false,
  // Implemantation date for admin
  activationDate: "",
  activationDatelabel: "",
  isDateValid: false,
  data_present_obj: {},
  // Original Data
  originalConfigTab: {},
  //
  showZoneChangeWarningPopUp: false,
  temporarySelectedZone: "",
  // approval request
  showApprovalRequestPopUpPreview: false,
  requesterComments: "",
};
