import { makeStyles } from "@material-ui/core";

import { COLORS } from "../../../config/theme/theme";

const timePickerWithLabelStyles = makeStyles(() => ({
  formControl: {
    border: "none",
    "& .MuiOutlinedInput-input": {
      fontFamily: "Montserrat",
      fontSize: "14px",
    },
    "& .MuiInputBase-input": {
      margin: 0,
      padding: 0,
      fontSize: "12px",
      color: COLORS.TEXT_PRIMARY,
      fontWeight: 500,
    },

    "& .MuiFormHelperText-contained": {
      fontSize: "12px",
    },
  },
  inputlabelStyles: {
    top: "-18px",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    color: COLORS.TEXT_PRIMARY,
    backgroundColor: COLORS.WHITE,
  },
  timePicker: {
    border: "1px solid #DCDCDC",
    borderRadius: "8px",
    height: "40px",
    backgroundColor: COLORS.WHITE,
    "& .MuiInput-underline:before": {
      border: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "none",
    },
    "& .MuiInput-underline.Mui-focused:after": {
      transform: "none",
      border: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
  },
  timePickerDisabled: {
    borderColor: "#E6E6E6",
  },
  timePickerInput: {
    margin: 0,
    padding: "12px",
    fontSize: "12px",
    color: COLORS.TEXT_PRIMARY,
    fontWeight: 500,
  },
  timePickerLabelProps: {
    marginLeft: "10px",
    fontSize: "12px",
    color: COLORS.TEXT_PRIMARY,
    fontWeight: 500,
    " &.MuiFormLabel-root.Mui-focused": {
      marginTop: "5px",
    },
  },
  hideTimeIcon: {
    "& .MuiInputAdornment-root": {
      display: "none",
    },
  },
  timerIcon: {
    marginBottom: "10px",
  },
}));

export default timePickerWithLabelStyles;
