import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import moment from "moment";

import { Grid, Paper, Typography } from "@material-ui/core";

import previewModalStyles from "components/ServiceAndSlots/PreviewModal/PreviewModalStyles";

import { ALERT_TYPES, APP_ROUTES, AREA_POS_MAPPING_TYPE, DISPLAY_WEEKLY_HOLIDAYS, EXPRESS_TYPES, SERVICE_TYPES, STATUS_OK } from "../../config/constants";
import { fetchAllAreasOfCountry, fetchAllRegionsOfCountry, fetchServiceInActiveStatus, selectAllCountries, selectServiceTypes } from "../../config/redux/configurationsSlice";
import { ReactComponent as CloseButton } from "../assets/svg/closeButton.svg";

import ModalContainer from "base/ModalContainer";
import ConfirmModal from "../common/ConfirmModal/ConfirmModal";
import CustomAlert from "../common/CustomAlert/CustomAlert";
import Loader from "../common/Loader/Loader";

import { countrySettingsStyles } from "./CountrySettingsStyles";
import {
  ClickCollectInitConfig,
  countrySettingsDetailsPayload,
  DefaultInitConfig,
  ExpressInitConfig,
  FBCInitialConfig,
  getDateFromPicker,
  getHolidaysListObject,
  ServiceTypeTabList,
  StandardInitConfig,
  WeeklyHolidayConfig,
} from "./CountrySettingsUtils";

import { DefaultServiceTypeDetails, PosTabInfo, PlasticBagconfigurationViewUtils } from "./CountrySettingView";
import { CountryChangesConfirmationModal } from "./modal/CountryChangesConfirmationModal";
import { createCountrySettings, fetchActivePOSAndServiceTypesCount, fetchCountrySettings, updateCountrySettings } from "./redux/countrySettingsSlice";

import CountryMappingTypeView from "./common/CountryMappingTypeView";
import CountrySettingsFooter from "./common/CountrySettingsFooter";
import RenderStandardServiceTypeDetails from "./common/RenderStandardServiceTypeDetails";
import RenderExpressServiceTypeDetails from "./common/RenderExpressServiceTypeDetails";
import RenderClickAndCollectServiceTypeDetails from "./common/RenderClickAndCollectServiceTypeDetails";
import RenderDefaultTypeDetails from "./common/RenderDefaultTypeDetails";
import RenderFBCTypeDetails from "./common/RenderFBCTypeDetails";

const CountrySettings = () => {
  const { countryName } = useParams();

  const classes = countrySettingsStyles();
  const dispatch = useDispatch();

  const {
    countrySettings: {
      posCount,
      fetchingPosCount,
      posCountError,
      loading,
      countryDetails: { data: savedCountryDetails },
      countrySettingsError,
    },
  } = useSelector((state) => state);
  const avialbleServiceTypes = useSelector(selectServiceTypes);

  const allCountries = useSelector(selectAllCountries);
  const { countryId } = allCountries.length ? allCountries.find((country) => country.name === countryName) : {};

  const [selectedServiceType, setSelectedServiceType] = useState(SERVICE_TYPES.STANDARD);
  const [expressType, setExpressType] = useState("");

  const [standardDetails, setStandardDetails] = useState(StandardInitConfig);
  const [expressDetails, setExpressDetails] = useState(ExpressInitConfig);
  const [clickCollectDetails, setClickCollectDetails] = useState(ClickCollectInitConfig);
  const [defaultDetails, setDefaultDetails] = useState(DefaultInitConfig);
  const [fbcDetails, setFBCDetails] = useState(FBCInitialConfig);

  const [showApplyChangesModal, setShowApplyChangesModal] = useState(false);
  const [showExpressTypeChangeConfirmModal, setShowExpressTypeChangeConfirmModal] = useState(false);

  const [weeklyHolidays, setWeeklyHolidays] = useState(WeeklyHolidayConfig);
  const [calendarHoliday, setCalendarHoliday] = useState([]);
  const [holidayDates, setHolidayDates] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState({ show: false, duplicates: [], oldDates: false });

  const [displayHolidays, setDisplayHolidays] = useState([]);
  const [displayElementCount, setDisplayelementCount] = useState([]);
  const [defaultDateState, setDefaultDateState] = useState({
    dateFrom: "",
    dateTo: "",
    dateLabel: "",
  });

  const [areaPosMappingType, setAreaPosMappingType] = useState(AREA_POS_MAPPING_TYPE.AREA_BASED);

  const handleServiceTypeDetails = (serviceType) => {
    setSelectedServiceType(serviceType.toUpperCase());
  };

  const handleAdvanceDaysClick = (serviceType) => {
    // eslint-disable-next-line
    switch (serviceType) {
      case SERVICE_TYPES.STANDARD:
        setStandardDetails({ ...standardDetails, showAdvanceSlotDaysInput: true });
        break;
      case SERVICE_TYPES.EXPRESS:
        setExpressDetails({ ...expressDetails, showAdvanceSlotDaysInput: true });
        break;
      case SERVICE_TYPES.CLICK_N_COLLECT:
        setClickCollectDetails({ ...clickCollectDetails, showAdvanceSlotDaysInput: true });
        break;
    }
  };

  const handleStandardDetailsChange = (event) => {
    const { name, value } = event.target;
    let newValue = value === "true";
    switch (name) {
      case "standardNextDaySlotVisibility":
        setStandardDetails({ ...standardDetails, nextDaySlotVisibility: newValue, [name]: newValue });
        break;
      case "standardAdvanceSlotDays":
        setStandardDetails({ ...standardDetails, [name]: newValue, editSlotDays: value });
        break;
      default:
        setStandardDetails({ ...standardDetails, [name]: newValue });
        return "";
    }
  };

  const handleExpressDetailsChange = (event) => {
    const { name, value } = event.target;
    let newValue = value === "true";
    switch (name) {
      case "expressNextDaySlotVisibility":
        setExpressDetails({ ...expressDetails, nextDaySlotVisibility: newValue, [name]: newValue });
        break;
      case "expressType":
        setExpressDetails({ ...expressDetails, expressType: value, [name]: newValue });
        break;
      case "expressAdvanceSlotDays":
        setExpressDetails({ ...expressDetails, editSlotDays: value, [name]: newValue });
        break;
      default:
        setExpressDetails({ ...expressDetails, [name]: newValue });
    }
  };

  const handleExpressTypeChangeApprove = () => {
    setExpressDetails({ ...expressDetails, expressType });
    setShowExpressTypeChangeConfirmModal(false);
  };

  const handleClickAndCollectDetailsChange = (event) => {
    const { name, value } = event.target;
    let newValue = value === "true";
    setClickCollectDetails({ ...clickCollectDetails, [name]: newValue });
    switch (name) {
      case "cncNextDaySlotVisibility":
        setClickCollectDetails({ ...clickCollectDetails, nextDaySlotVisibility: newValue });
        break;
      case "cncAdvanceSlotDays":
        setClickCollectDetails({ ...clickCollectDetails, editSlotDays: value });
        break;
      default:
        return "";
    }
  };

  const handleMappingType = (event) => {
    setAreaPosMappingType(event.target.value);
  };

  const handleApplyChangesModalClose = () => {
    setShowApplyChangesModal(false);
  };

  const handleApplyChangesClick = () => {
    let holidaysList = [];
    calendarHoliday.forEach((defaultDateState) => {
      if (defaultDateState && defaultDateState.dateLabel !== "") {
        let list = getHolidaysListObject(defaultDateState);
        holidaysList = [...holidaysList, ...list];
      }
    });
    holidaysList = [...new Map(holidaysList.map((item) => [item["objectDate"], item])).values()];
    setDefaultDetails({ ...defaultDetails, holidays: holidaysList });
    setShowApplyChangesModal(true);
  };

  const handleSubmit = async () => {
    let requestPayload = {
      countryId,
      countryData: countrySettingsDetailsPayload(countryName, standardDetails, expressDetails, clickCollectDetails, defaultDetails, areaPosMappingType, weeklyHolidays),
    };
    // If country setting avialble then call update Api else call Create
    if (!(savedCountryDetails && Object.keys(savedCountryDetails).length)) {
      const {
        payload: { status },
      } = await dispatch(createCountrySettings(requestPayload));
      if (status === STATUS_OK) {
        CustomAlert(ALERT_TYPES.SUCCESS, "Country Settings saved successfully");
        dispatch(fetchServiceInActiveStatus(countryId));
      }
    } else {
      const {
        payload: { status },
      } = await dispatch(updateCountrySettings(requestPayload));
      if (status === STATUS_OK) {
        CustomAlert(ALERT_TYPES.SUCCESS, "Country Settings updated successfully");
        dispatch(fetchServiceInActiveStatus(countryId));
      }
    }
    setShowApplyChangesModal(false);
  };

  const handleDateChangeEvent = (event, picker) => {
    if (event && event.target) {
      if (event.target.name === "date") {
        const dateStart = getDateFromPicker(picker.startDate);
        const dateEnd = getDateFromPicker(picker.endDate);
        let label = "";
        label = dateStart === dateEnd ? dateStart : dateStart + " - " + dateEnd;
        setDefaultDateState({
          dateFrom: dateStart,
          dateTo: dateEnd,
          dateLabel: label,
        });
      }
    }
  };

  const handleCalendarHoliday = async (event, type, label) => {
    if (!label || label.trim().length == 0) {
      return;
    }

    const dateFrom = new Date(defaultDateState.dateFrom);
    const dateTo = new Date(defaultDateState.dateTo);
    const currentDate = new Date();

    if (type === "ADD") {
      if (dateFrom <= currentDate || dateTo <= currentDate) {
        setShowInfoModal({ show: true, duplicates: [], oldDates: true });
        return;
      }

      let list = await getHolidaysListObject(defaultDateState);
      let arr = list.map((item) => item.objectDate);

      let allDates = [...arr, ...holidayDates];
      let duplicate = allDates.filter((e, i, a) => a.indexOf(e) !== i);
      if (duplicate && duplicate.length > 0) {
        setShowInfoModal({ show: true, duplicates: duplicate, oldDates: false });
        return;
      }
      setHolidayDates((prev) => [...prev, ...arr]);
      setCalendarHoliday((prev) => [...prev, defaultDateState]);
      setDefaultDateState({
        dateFrom: "",
        dateTo: "",
        dateLabel: "",
      });
    }
    if (type === "REMOVE") {
      let dateState = calendarHoliday.filter((item) => item.dateLabel == label)[0];
      let list = await getHolidaysListObject(dateState);
      let dateArray = list.map((item) => item.objectDate);
      let allDates = [];
      for (let i = 0; i < holidayDates.length; i++) {
        if (dateArray.indexOf(holidayDates[i]) == -1) {
          allDates.push(holidayDates[i]);
        }
      }
      setHolidayDates(allDates);
      let arr = calendarHoliday.filter((item) => item.dateLabel != label);
      setCalendarHoliday(arr);
    }
  };

  const handleCancel = (event, picker) => {
    event.preventDefault();
    picker.element.val("");
    setDefaultDateState({
      dateFrom: "",
      dateTo: "",
      dateLabel: "",
    });
  };

  const handleBagChargeChange = (event) => {
    const { value } = event.target;
    let newValue = value === "true";
    setExpressDetails({ ...expressDetails, bagChargeEnabled: newValue });
    setStandardDetails({ ...standardDetails, bagChargeEnabled: newValue });
    setClickCollectDetails({ ...clickCollectDetails, bagChargeEnabled: newValue });
    setDefaultDetails({ ...defaultDetails, bagChargeEnabled: newValue });
  };

  const handleDetailsChange = (event) => {
    if (event !== undefined) {
      setWeeklyHolidays((prev) => ({ ...prev, holidays: [...event] }));
    }
  };

  const handleServiceTypeActiveChange = (event, serviceTypeName) => {
    if (serviceTypeName !== undefined && serviceTypeName !== "") {
      switch (serviceTypeName) {
        case SERVICE_TYPES.STANDARD:
          setStandardDetails({ ...standardDetails, serviceActive: !standardDetails.serviceActive });
          break;
        case SERVICE_TYPES.EXPRESS:
          setExpressDetails({ ...expressDetails, serviceActive: !expressDetails.serviceActive });
          break;
        case SERVICE_TYPES.CLICK_N_COLLECT:
          setClickCollectDetails({ ...clickCollectDetails, serviceActive: !clickCollectDetails.serviceActive });
          break;
        case SERVICE_TYPES.DEFAULT:
          setDefaultDetails({ ...defaultDetails, serviceActive: !defaultDetails.serviceActive });
          break;
        case SERVICE_TYPES.FBC:
          setFBCDetails({ ...fbcDetails, serviceActive: !fbcDetails.serviceActive });
          break;
      }
    }
  };

  const updateComponentStateObject = () => {
    const { areaPosMappingType, serviceTypes: savedServiceTypes } = savedCountryDetails;
    setAreaPosMappingType(areaPosMappingType);
    if (savedServiceTypes !== undefined && Array.isArray(savedServiceTypes) && savedServiceTypes.length > 0) {
      savedServiceTypes.forEach((serviceInfo) => {
        let serviceTypeDetails = avialbleServiceTypes && avialbleServiceTypes.filter((element) => element.id == serviceInfo.serviceTypeID);
        if (serviceTypeDetails && serviceTypeDetails.length > 0) {
          switch (serviceTypeDetails[0].name) {
            case SERVICE_TYPES.STANDARD:
              setStandardDetails({
                ...standardDetails,
                serviceTypeID: 1,
                nextDaySlotVisibility: serviceInfo.nextDaySlotVisibility,
                advanceSlotDays: serviceInfo.advanceSlotDays,
                editSlotDays: serviceInfo.advanceSlotDays,
                bagChargeEnabled: serviceInfo.bagChargeEnabled,
                serviceActive: serviceInfo.serviceActive !== undefined ? serviceInfo.serviceActive : true,
              });
              break;
            case SERVICE_TYPES.EXPRESS:
              setExpressDetails({
                ...expressDetails,
                serviceTypeID: 3,
                nextDaySlotVisibility: serviceInfo.nextDaySlotVisibility,
                advanceSlotDays: serviceInfo.advanceSlotDays,
                editSlotDays: serviceInfo.advanceSlotDays,
                expressType: serviceInfo.expressType,
                bagChargeEnabled: serviceInfo.bagChargeEnabled,
                serviceActive: serviceInfo.serviceActive !== undefined ? serviceInfo.serviceActive : true,
              });
              setExpressType(serviceInfo.expressType ? serviceInfo.expressType : "");
              break;
            case SERVICE_TYPES.CLICK_N_COLLECT:
              setClickCollectDetails({
                ...clickCollectDetails,
                serviceTypeID: 4,
                nextDaySlotVisibility: serviceInfo.nextDaySlotVisibility,
                advanceSlotDays: serviceInfo.advanceSlotDays,
                editSlotDays: serviceInfo.advanceSlotDays,
                bagChargeEnabled: serviceInfo.bagChargeEnabled,
                serviceActive: serviceInfo.serviceActive !== undefined ? serviceInfo.serviceActive : true,
              });
              break;
            case SERVICE_TYPES.DEFAULT:
              setDefaultDetails({ ...defaultDetails, bagChargeEnabled: serviceInfo.bagChargeEnabled, serviceActive: serviceInfo.serviceActive !== undefined ? serviceInfo.serviceActive : true });
              if (serviceInfo.holidays && serviceInfo.holidays) {
                let calHolidays = [];
                let weekHolidays = [];
                let holidayDates = [];
                serviceInfo.holidays.forEach((item) => {
                  if (item.offDay != null || item.offDay != undefined) {
                    weekHolidays.push(item.offDay);
                  } else if (item.offDate != null || item.offDate != undefined) {
                    let actualDate = moment(item.offDate).format("YYYY-MM-DD");
                    holidayDates.push(actualDate);
                    calHolidays.push({
                      dateFrom: actualDate,
                      dateTo: actualDate,
                      dateLabel: actualDate,
                    });
                  }
                });
                setCalendarHoliday(calHolidays);
                setHolidayDates(holidayDates);
                setWeeklyHolidays({
                  holidays: weekHolidays,
                });
              }
              break;
          }
        }
      });
    }
  };

  useEffect(() => {
    if (savedCountryDetails !== undefined && typeof savedCountryDetails === "object" && Object.keys(savedCountryDetails).length > 0) {
      updateComponentStateObject();
    }
  }, [savedCountryDetails]);

  useEffect(() => {
    let holidaysList = [];
    if (defaultDateState && defaultDateState.dateLabel !== "") {
      holidaysList = getHolidaysListObject(defaultDateState);
    }
    setDefaultDetails((prev) => ({
      ...prev,
      holidays: holidaysList,
    }));
  }, [defaultDateState]);

  useEffect(() => {
    let sortedArray = calendarHoliday.sort((a, b) => (a.dateLabel > b.dateLabel ? 1 : -1));
    setCalendarHoliday(sortedArray);
    if (calendarHoliday.length >= displayHolidays.length) {
      setDisplayelementCount(Array.from(Array(calendarHoliday.length).keys()));
    } else {
      setDisplayelementCount(Array.from(Array(displayHolidays.length).keys()));
    }
  }, [calendarHoliday]);

  useEffect(() => {
    let displayHolidays = [];
    weeklyHolidays &&
      weeklyHolidays.holidays.length > 0 &&
      DISPLAY_WEEKLY_HOLIDAYS.map((item) => {
        let itemName = item.name.toUpperCase().trim();
        if (weeklyHolidays.holidays.indexOf(itemName) != -1) {
          displayHolidays.push(item.name);
        }
      });
    if (calendarHoliday.length >= displayHolidays.length) {
      setDisplayelementCount(Array.from(Array(calendarHoliday.length).keys()));
    } else {
      setDisplayelementCount(Array.from(Array(displayHolidays.length).keys()));
    }
    setDisplayHolidays(displayHolidays);
  }, [weeklyHolidays]);

  useEffect(() => {
    if (allCountries.length) {
      dispatch(fetchCountrySettings({ countryId }));
      dispatch(fetchAllRegionsOfCountry(countryId));
      dispatch(fetchAllAreasOfCountry(countryId));
    }
  }, [dispatch, countryId, allCountries.length]);

  useEffect(() => {
    dispatch(fetchActivePOSAndServiceTypesCount({ country: countryName }));
  }, [dispatch, countryName]);

  return (
    <div className={classes.countrySection}>
      <Typography className={classes.title}>{`Country Setting - ${countryName}`}</Typography>

      {fetchingPosCount ? (
        <Loader />
      ) : (
        <>
          <Paper className={classes.posDetailsPaperStyle}>
            <Grid container spacing={0} justifyContent="flex-start">
              <Grid item xs={2} className={classes.activePOSGridItem}>
                <Grid container spacing={0} className={classes.activePOSGridInnerContainer} justifyContent="space-between">
                  <Grid item className={classes.activePOSGridInnerItem}>
                    <Typography className={classes.activePosCount}>{`Active POS`}</Typography>
                  </Grid>
                  <Grid item className={classes.activePOSGridInnerItem}>
                    <Typography className={classes.posCountNo}>{posCount.activePos}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              {ServiceTypeTabList.map((element, index) => (
                <PosTabInfo
                  key={index}
                  handleServiceTypeDetails={handleServiceTypeDetails}
                  selectedServiceType={selectedServiceType}
                  serviceType={element.serviceType}
                  serviceTypeLabel={element.serviceTypeLabel}
                  classes={classes}
                  posCount={posCount[element.countKey]}
                />
              ))}
            </Grid>
          </Paper>
          {selectedServiceType === SERVICE_TYPES.STANDARD && (
            <RenderStandardServiceTypeDetails
              classes={classes}
              standardDetails={standardDetails}
              handleStandardDetailsChange={handleStandardDetailsChange}
              handleBagChargeChange={handleBagChargeChange}
              handleAdvanceDaysClick={handleAdvanceDaysClick}
              handleServiceTypeActiveChange={handleServiceTypeActiveChange}
            />
          )}
          {selectedServiceType === SERVICE_TYPES.EXPRESS && (
            <RenderExpressServiceTypeDetails
              classes={classes}
              expressDetails={expressDetails}
              handleExpressDetailsChange={handleExpressDetailsChange}
              handleBagChargeChange={handleBagChargeChange}
              handleAdvanceDaysClick={handleAdvanceDaysClick}
              handleServiceTypeActiveChange={handleServiceTypeActiveChange}
            />
          )}
          {selectedServiceType === SERVICE_TYPES.CLICK_N_COLLECT && (
            <RenderClickAndCollectServiceTypeDetails
              classes={classes}
              CountryMappingTypeView
              posCount={posCount}
              clickCollectDetails={clickCollectDetails}
              handleClickAndCollectDetailsChange={handleClickAndCollectDetailsChange}
              handleBagChargeChange={handleBagChargeChange}
              handleAdvanceDaysClick={handleAdvanceDaysClick}
              handleServiceTypeActiveChange={handleServiceTypeActiveChange}
            />
          )}
          {selectedServiceType === SERVICE_TYPES.DEFAULT && (
            <RenderDefaultTypeDetails
              classes={classes}
              defaultDetails={defaultDetails}
              handleBagChargeChange={handleBagChargeChange}
              weeklyHolidays={weeklyHolidays}
              setWeeklyHolidays={setWeeklyHolidays}
              defaultDateState={defaultDateState}
              handleDateChangeEvent={handleDateChangeEvent}
              handleCancel={handleCancel}
              handleCalendarHoliday={handleCalendarHoliday}
              calendarHoliday={calendarHoliday}
              displayHolidays={displayHolidays}
              displayElementCount={displayElementCount}
              handleDetailsChange={handleDetailsChange}
              handleServiceTypeActiveChange={handleServiceTypeActiveChange}
            />
          )}
          {selectedServiceType === SERVICE_TYPES.FBC && <RenderFBCTypeDetails classes={classes} fbcDetails={fbcDetails} handleServiceTypeActiveChange={handleServiceTypeActiveChange} />}

          <CountryMappingTypeView classes={classes} areaPosMappingType={areaPosMappingType} />
          <CountrySettingsFooter classes={classes} handleApplyChangesClick={handleApplyChangesClick} />
        </>
      )}

      {/* Modals */}
      {/*  */}
      <InfoModal show={showInfoModal.show} oldDates={showInfoModal.oldDates} onClose={() => setShowInfoModal({ show: false, duplicates: [], oldDates: false })} holidays={showInfoModal.duplicates} />
      <CountryChangesConfirmationModal
        open={showApplyChangesModal}
        onClose={handleApplyChangesModalClose}
        classes={classes}
        standardDetails={standardDetails}
        expressDetails={expressDetails}
        clickCollectDetails={clickCollectDetails}
        defaultDetails={defaultDetails}
        loading={loading}
        areaPosMappingType={areaPosMappingType}
        handleSubmit={handleSubmit}
        weeklyHolidays={weeklyHolidays}
      />
      <ConfirmModal
        show={showExpressTypeChangeConfirmModal}
        title="Confirm Express Type Change"
        description="Slots needs to be re-validated as the express type is changed"
        confirmButtonText="Approve"
        onClose={() => setShowExpressTypeChangeConfirmModal(false)}
        onProceed={handleExpressTypeChangeApprove}
      />
    </div>
  );
};

export default CountrySettings;

const InfoModal = ({ show, onClose, holidays, oldDates }) => {
  const classes = previewModalStyles({});

  return (
    <div className={classes.modalContainer}>
      <ModalContainer className={classes.modal} open={show} onClose={onClose} disableBackdropClick>
        <div className={`${classes.modalContent} ${classes.modalContentWindow}`}>
          <div className={classes.closeButton}>
            <CloseButton className={classes.closeButtonSVG} onClick={onClose} />
          </div>

          <div className={classes.modalHeader}>
            <div className={classes.modalTitle}>{oldDates ? "Older Dates" : "Duplicate Holidays"}</div>
          </div>

          <div className={classes.modalBody}>
            {oldDates ? (
              <Typography> Please don't use older dates. Use dates tomorrow onwards.</Typography>
            ) : (
              <>
                <Typography className={classes.applyInfoModalBodyContent}>Following dates are already selected as holiday. Please check date range and select appropriately.</Typography>
                <div style={{ marginTop: "10px" }}>{holidays?.map((info) => info).join(", ")}</div>
              </>
            )}
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};
