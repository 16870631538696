import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../config/theme/theme";

export const basicDetailsStyles = makeStyles((theme) => ({
  title: {
    fontSize: "24px",
    fontWeight: 600,
    letterSpacing: "0em",
    textAlign: "left",
  },
  cards: {
    minWidth: 275,
    marginTop: "16px",
    background: COLORS.WHITE,
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
  },
  cardTitle: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "22px",
    color: COLORS.TEXT_PRIMARY,
  },
  customerDetailsContainer: {
    marginTop: "18px",
  },
  storeDetailsContainer: {
    marginTop: "24px",
  },
  container: {
    marginTop: "16px",
  },
  gridSection: {
    position: "relative",
    paddingRight: "15px",
  },
  divButton: {
    display: "flex",
    float: "right",
    padding: "0.5em",
    marginBottom: "0.5em",
    marginTop: "20px",
  },
  smallTitle: {
    fontWeight: 500,
    fontSize: "14px",
    color: COLORS.TEXT_PRIMARY,
    marginBottom: "8px",
  },
  serviceTypeContainer: {
    marginTop: "20px",
  },
  serviceStyles: {
    display: "flex",
    marginTop: "12px",
  },
  serviceTypeItem: {
    marginRight: "70px",
  },
  addressSectionFields: {
    paddingTop: "8px",
  },
  addressSectionFirstField: {
    paddingRight: "8px",
  },
  addressSectionSecondField: {
    paddingLeft: "8px",
  },
  previewButton: {
    border: "1px solid #B2B2B2",
    borderRadius: "8px",
    width: "166px",
    height: "48px",
  },
  buttonsSection: {
    display: "flex",
  },
  previewText: {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "17px",
    letterSpacing: "0em",
    textAlign: "center",
    color: "#B2B2B2",
  },
  error: {
    color: "red",
    fontSize: "0.75rem",
    marginTop: "2px",
  },
  checkboxLabel: {
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "15px",
    paddingLeft: "8px",
  },
  buttonContainer: {
    marginTop: "16px",
  },
  linkstyle: {
    textDecoration: "none",
  },
}));
