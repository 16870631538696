import { ADDITIONAL_SERVICES, APP_ROUTES, SIDE_MENU_LABEL, USER_TYPES } from "./../../../config/constants";

const show_user_managemnet_screen = (role: any) => {
  if (role !== undefined && role !== "") {
    let is_user_managemnet_screen_visible = false;
    switch (role) {
      case USER_TYPES.MAF_ADMIN:
        is_user_managemnet_screen_visible = true;
        break;
      default: {
        is_user_managemnet_screen_visible = false;
        break;
      }
    }
    return is_user_managemnet_screen_visible;
  }
  return false;
};

const show_polygon_services_screen = (role: any) => {
  if (role !== undefined && role !== "") {
    let is_polygon_services_screen_visible = false;
    switch (role) {
      case USER_TYPES.MAF_ADMIN:
      case USER_TYPES.SUPER_ADMIN:
      case USER_TYPES.ADMIN:
      case USER_TYPES.APPROVER:
      case USER_TYPES.READONLY_USER:
        is_polygon_services_screen_visible = true;
        break;
      default: {
        is_polygon_services_screen_visible = false;
        break;
      }
    }
    return is_polygon_services_screen_visible;
  }
  return false;
};

const show_approval_request_screen = (role: any) => {
  if (role !== undefined && role !== "") {
    let is_approval_request_screen_visible = false;
    switch (role) {
      case USER_TYPES.ADMIN:
      case USER_TYPES.MAF_ADMIN:
      case USER_TYPES.SUPER_ADMIN:
      case USER_TYPES.APPROVER:
        is_approval_request_screen_visible = true;
        break;
      default: {
        is_approval_request_screen_visible = false;
        break;
      }
    }
    return is_approval_request_screen_visible;
  }
  return false;
};

export function getTopBarMenuItems(userProfile: any) {
  let userOption: any = [
    {
      label: SIDE_MENU_LABEL.DASHBOARD,
      value: SIDE_MENU_LABEL.DASHBOARD,
      to: APP_ROUTES.DASHBOARD,
      isDefault: true,
      isDisabled: false,
      isSingleItem: true,
    },
    {
      label: SIDE_MENU_LABEL.POS,
      value: SIDE_MENU_LABEL.POS,
      to: APP_ROUTES.POS_LIST,
      isDefault: false,
      isDisabled: false,
      isSingleItem: true,
    },
    {
      label: SIDE_MENU_LABEL.RULE_ENGINE_SET_UP,
      value: SIDE_MENU_LABEL.RULE_ENGINE_SET_UP,
      to: APP_ROUTES.RULE_ENGINE,
      isDefault: false,
      isDisabled: false,
      isSingleItem: false,
    },
  ];

  if (userProfile !== undefined && userProfile.role !== undefined && userProfile.role !== "") {
    // POLYGON_SERVICES
    if (show_polygon_services_screen(userProfile.role)) {
      userOption.push({
        label: SIDE_MENU_LABEL.POLYGON_SERVICES,
        value: SIDE_MENU_LABEL.POLYGON_SERVICES,
        to: APP_ROUTES.POLYGON_SERVICES,
        isDefault: false,
        isDisabled:
          userProfile !== undefined &&
          userProfile.featureId !== undefined &&
          userProfile.featureId !== "" &&
          Array.isArray(userProfile.featureId) &&
          userProfile.featureId.length > 0 &&
          userProfile.featureId.includes(ADDITIONAL_SERVICES.POLYGON_SERVICE)
            ? false
            : true,
        isSingleItem: true,
      });
    }

    // APPROVAL_REQUESTS
    if (show_approval_request_screen(userProfile.role)) {
      userOption.push({
        label: SIDE_MENU_LABEL.APPROVAL_REQUESTS,
        value: SIDE_MENU_LABEL.APPROVAL_REQUESTS,
        to: APP_ROUTES.APPROVAL_REQUESTS,
        isDefault: false,
        isDisabled: false,
        isSingleItem: true,
      });
    }

    // USER_MANAGEMENT
    if (show_user_managemnet_screen(userProfile.role)) {
      userOption.push({
        label: SIDE_MENU_LABEL.USER_MANAGEMENT,
        value: SIDE_MENU_LABEL.USER_MANAGEMENT,
        to: APP_ROUTES.USER_MANAGEMENT,
        isDefault: false,
        isDisabled: false,
        isSingleItem: true,
      });
    }
  }

  return userOption;
}

export function getRuleEngineItems(userProfile: any) {
  let userOption: any = [
    {
      label: SIDE_MENU_LABEL.DELIVERY_PROMISE,
      value: SIDE_MENU_LABEL.DELIVERY_PROMISE,
      to: APP_ROUTES.RULE_ENGINE,
      isDefault: false,
      isDisabled:
        userProfile !== undefined &&
        userProfile.featureId !== undefined &&
        userProfile.featureId !== "" &&
        Array.isArray(userProfile.featureId) &&
        userProfile.featureId.length > 0 &&
        userProfile.featureId.includes(ADDITIONAL_SERVICES.DELIVERY_PROMISE_RULE_ENGINE)
          ? false
          : true,
      selectedIndex: 6,
    },
    {
      label: SIDE_MENU_LABEL.DELIVERY_FEE,
      value: SIDE_MENU_LABEL.DELIVERY_FEE,
      to: APP_ROUTES.DELIVERY_FEE,
      isDefault: false,
      isDisabled:
        userProfile !== undefined &&
        userProfile.featureId !== undefined &&
        userProfile.featureId !== "" &&
        Array.isArray(userProfile.featureId) &&
        userProfile.featureId.length > 0 &&
        userProfile.featureId.includes(ADDITIONAL_SERVICES.DELIVERY_FEE)
          ? false
          : true,
      selectedIndex: 7,
    },
    {
      label: SIDE_MENU_LABEL.SINGLE_USE_PLASTIC,
      value: SIDE_MENU_LABEL.SINGLE_USE_PLASTIC,
      to: APP_ROUTES.SINGLE_USE_PLASTIC,
      isDefault: false,
      isDisabled: false,
      selectedIndex: 8,
    },
  ];
  return userOption;
}
