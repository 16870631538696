import produce from "immer";
import moment from "moment";

import { getFoodTypesFromFoodSubTypes } from "../../utils/SlotServicesUtils";
import { getDateKeValueFromZoneTab, getKeyNameFromZoneTab } from "../../../../utils/ServiceTypeUtils";
import { DEFAULT_ON_DEMAND_SLOT, foodSplitRegex } from "../../utils/constants";
import { ALERT_TYPES, HH_MM_SS, STORE_STATE } from "../../../../config/constants";
import { convertMinutesToHours } from "../../../../utils/helperFunctions";
import { ExpressServiceInitialStateInterface } from "../ExpressInterface";
import { isEmptyOrNull } from "utils/DataUtils";
import CustomAlert from "components/common/CustomAlert/CustomAlert";

export const handle_design_config_tab_utils = (slots: any) => {
  let new_config_tabs = {} as any;
  new_config_tabs = slots.map((slot: any) => ({
    zoneId: getKeyNameFromZoneTab(slot, "id"),
    zoneName: getKeyNameFromZoneTab(slot, "zoneName"),
    foodTypes: getFoodTypesFromFoodSubTypes(slot?.foodTypeMapping?.foodTypes),
    foodSubType: slot?.foodTypeMapping?.foodTypes?.filter((fst: any) => fst !== "NON_FOOD").join(foodSplitRegex),
    showFirstSlotAtOption: !!getKeyNameFromZoneTab(slot, "showFirstSlotAt"),
    showFirstSlotAt: getDateKeValueFromZoneTab(slot, "showFirstSlotAt", HH_MM_SS),
    showServiceCutOffTimeOption: !!getKeyNameFromZoneTab(slot, "serviceCutOffTime"),
    serviceCutOffTime: getDateKeValueFromZoneTab(slot, "serviceCutOffTime", HH_MM_SS),
    selectedAreas: slot.selectedAreas || [],
    slotsGeneratorForms: Object.keys(slot.dayWiseSlots).map((dayWiseSlot) => ({
      isEdit: true,
      days: [dayWiseSlot],
      generatedSlots:
        slot.dayWiseSlots[dayWiseSlot] !== undefined && Array.isArray(slot.dayWiseSlots[dayWiseSlot])
          ? slot.dayWiseSlots[dayWiseSlot].map((slot: any) => ({
              ...slot,
              startTime: moment(slot.startTime, HH_MM_SS),
              endTime: moment(slot.endTime, HH_MM_SS),
              status: slot.status,
              time: `${moment(slot.startTime, HH_MM_SS).format("HH:mm")} - ${moment(slot.endTime, HH_MM_SS).format("HH:mm")}`,
              bufferTimeHours: convertMinutesToHours(slot.bufferTime).hours,
              bufferTimeMinutes: convertMinutesToHours(slot.bufferTime).minutes,
              orderCutOff: moment(slot.startTime, HH_MM_SS).subtract(convertMinutesToHours(slot.bufferTime).minutes, "m").format("HH:mm"),
              isEditable: false,
              slotAction: "CHANGE", // ADD, UPDATE, DELETE
              canBeUpdated: false,
            }))
          : {},
    })),
    state: getKeyNameFromZoneTab(slot, "state"), // Available or Busy
  }));
  return new_config_tabs;
};

export const _validate_send_for_approval_form = (expressServiceState: ExpressServiceInitialStateInterface, configTabs: any, countryId: any, showError: boolean, requesterComments: any) => {
  let pay_load_object = {} as any;
  let responseValue: any = {};
  let is_different_value_found: boolean = false;

  if (
    expressServiceState !== undefined &&
    expressServiceState.selectedConfigTab !== undefined &&
    expressServiceState.selectedConfigTab >= 0 &&
    expressServiceState.originalConfigTab !== undefined &&
    Array.isArray(expressServiceState.originalConfigTab) &&
    expressServiceState.originalConfigTab.length > 0 &&
    expressServiceState.originalConfigTab[expressServiceState.selectedConfigTab] !== undefined &&
    expressServiceState.originalConfigTab[expressServiceState.selectedConfigTab].zoneId !== undefined &&
    configTabs !== undefined &&
    Array.isArray(configTabs) &&
    configTabs.length > 0 &&
    configTabs[expressServiceState.selectedConfigTab] !== undefined &&
    configTabs[expressServiceState.selectedConfigTab].zoneId !== undefined &&
    configTabs[expressServiceState.selectedConfigTab].zoneId !== "" &&
    configTabs[expressServiceState.selectedConfigTab].slotsGeneratorForms !== undefined &&
    Array.isArray(configTabs[expressServiceState.selectedConfigTab].slotsGeneratorForms) &&
    configTabs[expressServiceState.selectedConfigTab].slotsGeneratorForms.length > 0
  ) {
    const originalConfigTab = expressServiceState.originalConfigTab[expressServiceState.selectedConfigTab];
    const configTab = configTabs[expressServiceState.selectedConfigTab];

    pay_load_object = {
      countryId: countryId,
      activationDate: expressServiceState.activationDate,
      serviceTypeId: "",
      cncId: "",
      zoneConfig: {
        id: configTab?.zoneId || "",
        zoneName: configTab?.zoneName || "",
        serviceCutOffTime: configTab?.zoneServiceCutOffTime || "",
        showFirstSlotAt: configTab?.zoneShowFirstSlotAt || "",
        serviceTypeMappingId: configTab?.zoneServiceTypeMappingId || "",
        capacityThreshold: configTab?.zoneCapacityThreshold || "",
        timeThreshold: configTab?.zoneTimeThreshold || "",
        status: configTab?.zoneStatus || "",
        state: configTab?.state || STORE_STATE.AVAILABLE,
      },
      templateSlots: [],
      updatedDays: [],
      defaultTemplateSlots: [],
      requesterComments: "",
    };

    if (requesterComments !== undefined && requesterComments !== "") {
      pay_load_object.requesterComments = requesterComments;
    }

    if (
      expressServiceState !== undefined &&
      expressServiceState.configurationBulkUpdatedObj !== undefined &&
      expressServiceState.configurationBulkUpdatedObj.bulkUpdatedDays !== undefined &&
      Array.isArray(expressServiceState.configurationBulkUpdatedObj.bulkUpdatedDays) &&
      expressServiceState.configurationBulkUpdatedObj.bulkUpdatedDays.length > 0
    ) {
      pay_load_object.updatedDays = [...expressServiceState.configurationBulkUpdatedObj.bulkUpdatedDays];
    }

    configTab.slotsGeneratorForms.forEach((slot_form: any, slot_form_index: any) => {
      let day_name = "";
      if (slot_form.days !== undefined && Array.isArray(slot_form.days) && slot_form.days.length > 0 && slot_form.days[0] !== undefined && slot_form.days[0] !== "") {
        day_name = `for ${slot_form.days[0]}`;
      }
      if (slot_form !== undefined && slot_form.generatedSlots !== undefined && Array.isArray(slot_form.generatedSlots) && slot_form.generatedSlots.length > 0) {
        let is_generated_slot_deleted: number = 0;
        slot_form.generatedSlots.forEach((slot: any, slot_index: any) => {
          if (slot !== undefined) {
            let template_slot_object = {
              id: slot?.id || 0,
              posId: slot.posId,
              day: slot.day,
              zoneId: slot.zoneId,
              status: slot.status,
              createdAt: slot.createdAt,
              slotAction: slot?.slotAction || "UPDATE", // ADD, UPDATE, DELETE
              startTime: null,
              endTime: null,
              slotCapacity: null,
              serviceTypeId: slot?.serviceTypeId || 3,
              bufferTime: 0,
            } as any;

            if (slot !== undefined && slot.slotAction !== undefined && slot.slotAction !== "") {
              if (slot.slotAction === "DELETE") {
                is_generated_slot_deleted++;
                is_different_value_found = true;
              } else if (slot.slotAction === "CHANGE") {
                template_slot_object.slotAction = "UPDATE";
              }

              if (isEmptyOrNull(slot.startTime)) {
                responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-startTime`] = `Please provide slot start time ${
                  slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."
                }`;
              } else if (!moment(slot.startTime).isValid()) {
                responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-startTime`] = `Please provide valid slot start time ${
                  slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."
                }`;
              } else {
                template_slot_object.startTime = moment(slot.startTime).format(HH_MM_SS);
                if (slot.slotAction !== undefined && slot.slotAction !== "" && slot.slotAction !== "DELETE") {
                  slot_form.generatedSlots.forEach((newSlot: any, newSlotIndex: any) => {
                    if (
                      newSlotIndex !== undefined &&
                      newSlotIndex !== slot_index &&
                      newSlot.slotAction !== undefined &&
                      newSlot.slotAction !== "" &&
                      newSlot.slotAction !== "DELETE" &&
                      newSlot !== undefined &&
                      !isEmptyOrNull(newSlot.startTime) &&
                      !isEmptyOrNull(newSlot.endTime)
                    ) {
                      let startTimeFlag = moment(newSlot.startTime).isBetween(moment(slot.startTime), moment(slot.endTime));
                      if (!startTimeFlag) {
                        startTimeFlag = moment(newSlot.startTime).isSame(moment(slot.startTime));
                      }
                      //
                      let endTimeFlag = moment(newSlot.endTime).isBetween(moment(slot.startTime), moment(slot.endTime));
                      if (!endTimeFlag) {
                        endTimeFlag = moment(newSlot.endTime).isSame(moment(slot.endTime));
                      }
                      if (startTimeFlag && endTimeFlag) {
                        let error_message = "One or more slots is a subset of another slot. Please change slot timings.";
                        responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-time`] = error_message;
                      }
                    }
                  });
                }
              }

              if (isEmptyOrNull(slot.endTime)) {
                responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-endTime`] = `Please provide slot end time ${
                  slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."
                }`;
              } else if (!moment(slot.endTime).isValid()) {
                responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-endTime`] = `Please provide valid slot end time ${
                  slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."
                }`;
              } else {
                template_slot_object.endTime = moment(slot.endTime).format(HH_MM_SS);
              }

              if (moment(slot.endTime).isBefore(moment(slot.startTime))) {
                responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-slotCapacity`] = `Please provide valid slot start/end time ${
                  slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."
                }`;
              }

              if (isEmptyOrNull(slot.slotCapacity)) {
                responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-slotCapacity`] = `Please provide valid slot capacity ${
                  slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."
                }`;
              } else {
                template_slot_object.slotCapacity = parseInt(slot.slotCapacity);
              }

              if (
                originalConfigTab !== undefined &&
                originalConfigTab.slotsGeneratorForms !== undefined &&
                Array.isArray(originalConfigTab.slotsGeneratorForms) &&
                originalConfigTab.slotsGeneratorForms.length > 0 &&
                originalConfigTab.slotsGeneratorForms[slot_form_index] !== undefined &&
                originalConfigTab.slotsGeneratorForms[slot_form_index].generatedSlots !== undefined &&
                Array.isArray(originalConfigTab.slotsGeneratorForms[slot_form_index].generatedSlots) &&
                originalConfigTab.slotsGeneratorForms[slot_form_index].generatedSlots.length > 0 &&
                originalConfigTab.slotsGeneratorForms[slot_form_index].generatedSlots[slot_index] !== undefined
              ) {
                //
                // to check if change is made or the values are same and it is requested for approval
                //
                const { startTime, endTime, slotCapacity } = originalConfigTab.slotsGeneratorForms[slot_form_index].generatedSlots[slot_index];

                if (originalConfigTab.slotsGeneratorForms[slot_form_index].generatedSlots.length !== slot_form.generatedSlots.length) {
                  is_different_value_found = true;
                }

                if (slotCapacity !== undefined) {
                  if (parseInt(slotCapacity) !== parseInt(slot.slotCapacity)) {
                    is_different_value_found = true;
                  }
                }

                if (startTime !== undefined) {
                  if (!moment(startTime).isSame(moment(slot.startTime))) {
                    is_different_value_found = true;
                  }
                }

                if (endTime !== undefined) {
                  if (!moment(endTime).isSame(moment(slot.endTime))) {
                    is_different_value_found = true;
                  }
                }
              }
            }

            if (pay_load_object.serviceTypeId === "") {
              pay_load_object.serviceTypeId = slot.serviceTypeId;
            }
            pay_load_object.templateSlots.push(template_slot_object);
          }
        });

        if (slot_form.generatedSlots.length === is_generated_slot_deleted) {
          responseValue[`slot_index-${slot_form_index}-slot_form_index-is_generated_slot_deleted`] = `No Slot found for ${day_name}.`;
        }
      } else {
        responseValue[`slot_index-${slot_form_index}-slot_form_index-is_generated_slot_deleted`] = `No Slot present for ${day_name}.`;
      }
    });
  }

  if (responseValue && Object.keys(responseValue).length !== 0) {
    showError !== undefined && showError && CustomAlert(ALERT_TYPES.ERROR, responseValue[Object.keys(responseValue)[0]]);
    return {};
  }

  if (is_different_value_found === false) {
    showError !== undefined && showError && CustomAlert(ALERT_TYPES.ERROR, "No change found for approval");
    return {};
  }

  return pay_load_object;
};

export const add_new_template_slot_util = (configTab: any, tabIndex: any, generatorFormIndex: any, day: any, posDetails: any) => {
  let new_config_tab: any = produce(configTab, (draft: any) => {
    let newSlot = {
      ...DEFAULT_ON_DEMAND_SLOT,
      posId: posDetails.id,
      day: day.join(","),
      zoneId: draft[tabIndex].zoneId,
      isNewSlot: true,
      slotAction: "ADD",
      canBeUpdated: true,
    };
    draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots.push(newSlot);
  });
  return new_config_tab;
};

export const validate_generator_form_before_bulk_update = (configTab: any) => {
  let responseValue: any = {};
  if (
    configTab !== undefined &&
    configTab.zoneId !== undefined &&
    configTab.zoneId !== "" &&
    configTab.slotsGeneratorForms !== undefined &&
    Array.isArray(configTab.slotsGeneratorForms) &&
    configTab.slotsGeneratorForms.length > 0
  ) {
    configTab.slotsGeneratorForms.forEach((slot_form: any, slot_form_index: any) => {
      slot_form.generatedSlots.forEach((slot: any, slot_index: any) => {
        const start_error_message = `Please provide slot start time ${slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."}`;
        const end_error_message = `Please provide slot end time ${slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."}`;
        const capacity_error_message = `Please provide valid slot capacity ${slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."}`;

        if (isEmptyOrNull(slot.startTime)) {
          responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-startTime`] = start_error_message;
        } else if (!moment(slot.startTime).isValid()) {
          responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-startTime`] = start_error_message;
        }

        if (isEmptyOrNull(slot.endTime)) {
          responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-endTime`] = end_error_message;
        } else if (!moment(slot.endTime).isValid()) {
          responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-endTime`] = end_error_message;
        }

        if (isEmptyOrNull(slot.slotCapacity)) {
          responseValue[`${slot_index}-slot_index-${slot_form_index}-slot_form_index-slotCapacity`] = capacity_error_message;
        }
      });
    });
  }

  if (responseValue && Object.keys(responseValue).length !== 0) {
    CustomAlert(ALERT_TYPES.ERROR, responseValue[Object.keys(responseValue)[0]]);
    return false;
  }

  return true;
};

export const validateExpressZoneConfig = (configTabs: any, selectedConfigTab: any, isPOSMappingAreaBased: boolean, errors: any) => {
  let currentTab = configTabs[selectedConfigTab];
  let newErrors: any = {};
  let isAnyError = false;

  if (isPOSMappingAreaBased) {
    if (!currentTab.selectedAreas.length) {
      let errorMsg = "Please assign areas to the zone configurations";
      newErrors.selectedAreas = errorMsg;
      CustomAlert(ALERT_TYPES.ERROR, errorMsg);
    }
  }

  currentTab.slotsGeneratorForms.forEach((sgf: any, sgfIndex: any) => {
    sgf.generatedSlots.forEach((slot: any, slotIndex: number) => {
      if (slot.slotCapacity === "") {
        isAnyError = true;
        newErrors[`slotsGeneratorForms-${sgfIndex}-${slotIndex}-slotCapacity`] = `Please enter capacity for a slot ${slot.day !== undefined && slot.day !== "" ? "for : " + slot.day : ""}`;
        return;
      } else if (slot.endTime === null || slot.endTime === "" || !moment(slot.endTime).isValid()) {
        isAnyError = true;
        newErrors[`slotsGeneratorForms-${sgfIndex}-${slotIndex}-endTime`] = `Please enter valid slot end time ${slot.day !== undefined && slot.day !== "" ? "for : " + slot.day : ""}`;
        return;
      } else if (slot.startTime === null || slot.startTime === "" || !moment(slot.startTime).isValid()) {
        isAnyError = true;
        newErrors[`slotsGeneratorForms-${sgfIndex}-${slotIndex}-startTime`] = `Please enter valid slot start time ${slot.day !== undefined && slot.day !== "" ? "for : " + slot.day : ""}`;
        return;
      } else if (moment(slot.startTime).isSame(moment(slot.endTime))) {
        isAnyError = true;
        newErrors[`slotsGeneratorForms-${sgfIndex}-${slotIndex}-sameTime`] = `Slot Start time cannot be same as End time ${slot.day !== undefined && slot.day !== "" ? "for : " + slot.day : ""}`;
        return;
      } else if (moment(slot.startTime).isAfter(moment(slot.endTime))) {
        isAnyError = true;
        newErrors[`slotsGeneratorForms-${sgfIndex}-${slotIndex}-afterTime`] = `Start time cannot be before End time ${slot.day !== undefined && slot.day !== "" ? "for : " + slot.day : ""}`;
        return;
      } else if (Object.keys(newErrors).length === 0) {
        sgf.generatedSlots.forEach((newSlot: any, newSlotIndex: any) => {
          if (newSlotIndex !== slotIndex) {
            if (newSlot.slotAction !== undefined && newSlot.slotAction !== "" && newSlot.slotAction !== "DELETE") {
              let startTimeFlag = moment(newSlot.startTime).isBetween(moment(slot.startTime), moment(slot.endTime));
              if (!startTimeFlag) {
                startTimeFlag = moment(newSlot.startTime).isSame(moment(slot.startTime));
              }
              let endTimeFlag = moment(newSlot.endTime).isBetween(moment(slot.startTime), moment(slot.endTime));
              if (!endTimeFlag) {
                endTimeFlag = moment(newSlot.endTime).isSame(moment(slot.endTime));
              }
              if (startTimeFlag && endTimeFlag) {
                let error_message = "One or more slots is a subset of another slot. Please change slot timings.";
                newErrors[`${sgfIndex}-${slotIndex}-slot_index-sub-set`] = error_message;
                return;
              }
            }
          }
        });
      }
    });
  });

  let currentTabErrors = produce(errors, (draft: any) => {
    draft[selectedConfigTab] = { ...newErrors };
  });

  if (newErrors && Object.keys(newErrors).length !== 0) {
    CustomAlert(ALERT_TYPES.ERROR, newErrors[Object.keys(newErrors)[0]]);
  }

  return { isError: Object.keys(newErrors).filter((err) => newErrors[err]).length > 0 || isAnyError, currentTabErrors };
};

export const update_make_slot_editable = (configTab: any, tabIndex: any, generatorFormIndex: any, slot_index: any) => {
  let new_config_tab: any = produce(configTab, (draft: any) => {
    draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index].canBeUpdated = !draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index].canBeUpdated;
  });
  return new_config_tab;
};

export const delete_template_slot_util = (configTab: any, tabIndex: any, generatorFormIndex: any, slot_index: any) => {
  let new_config_tab: any = produce(configTab, (draft: any) => {
    if (
      draft !== undefined &&
      draft[tabIndex] !== undefined &&
      draft[tabIndex].slotsGeneratorForms !== undefined &&
      Array.isArray(draft[tabIndex].slotsGeneratorForms) &&
      draft[tabIndex].slotsGeneratorForms.length > 0 &&
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex] !== undefined &&
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots !== undefined &&
      Array.isArray(draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots) &&
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots.length > 0
    ) {
      if (
        draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index] !== undefined &&
        draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index].id !== undefined &&
        draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index].id !== ""
      ) {
        const { startTime, endTime, slotCapacity, bufferTimeHours, bufferTimeMinutes } = draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index];
        let responseValue: any = {};
        if (isEmptyOrNull(startTime)) {
          responseValue[`${slot_index}-slot_index-${slot_index}-slot_form_index-startTime`] = "Please provide slot start time before delete";
        } else if (!moment(startTime).isValid()) {
          responseValue[`${slot_index}-slot_index-${slot_index}-slot_form_index-startTime`] = "Please provide valid slot start time before delete";
        }

        if (isEmptyOrNull(endTime)) {
          responseValue[`${slot_index}-slot_index-${slot_index}-slot_form_index-endTime`] = "Please provide slot end time before delete";
        } else if (!moment(endTime).isValid()) {
          responseValue[`${slot_index}-slot_index-${slot_index}-slot_form_index-endTime`] = "Please provide valid slot end time before delete";
        }

        if (isEmptyOrNull(slotCapacity)) {
          responseValue[`${slot_index}-slot_index-${slot_index}-slot_form_index-slotCapacity`] = "Please provide valid slot capacity before delete";
        }

        if (responseValue && Object.keys(responseValue).length !== 0) {
          CustomAlert(ALERT_TYPES.ERROR, responseValue[Object.keys(responseValue)[0]]);
        } else {
          draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[slot_index].slotAction = "DELETE";
        }
      } else {
        let allSlots = draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots;
        let filteredSlots = allSlots.filter((item: any) => !!item.id);
        draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots = [...filteredSlots];
      }
    }
  });
  return new_config_tab;
};

export const cancel_editing_a_slot_util = (configTab: any, tabIndex: any, generatorFormIndex: any, index: any, slot: any, express: any) => {
  let unEditedSlotFromStore = express.length && express[tabIndex].dayWiseSlots[slot.day].filter((item: any) => item.id === slot.id)[0];
  let unEditedSlot = {
    ...unEditedSlotFromStore,
    time: `${moment(unEditedSlotFromStore.startTime, HH_MM_SS).format("HH:mm")} - ${moment(unEditedSlotFromStore.endTime, HH_MM_SS).format("HH:mm")}`,
    startTime: moment(unEditedSlotFromStore.startTime, HH_MM_SS),
    endTime: moment(unEditedSlotFromStore.endTime, HH_MM_SS),
  };

  let new_config_tab: any = produce(configTab, (draft: any) => {
    draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index] = { ...unEditedSlot };
    draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index].slotAction = "CHANGE";
  });
  return new_config_tab;
};
