import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../config/theme/theme";

export const userStyles = makeStyles(() =>
  createStyles({
    root: {
      background: COLORS.WHITE,
      borderRadius: "3px 3px 0px 0px",
      borderColor: "transparent",
    },
    container: {
      marginTop: "80px",
      padding: "24px 12px 24px 24px",
    },
    headerRoot: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "24px",
    },
    header: {
      display: "flex",
      justifyContent: "flex-start",
    },
    heading: {
      fontWeight: 600,
      fontSize: "24px",
      color: COLORS.DARK_GRAY,
    },
    buttonStyles: {
      width: "364px",
      height: "40px",
      background: COLORS.DOS_BLUE_DARK,
      color: "#FFFFFF",
      border: "1px solid",
      borderColor: COLORS.DOS_BLUE_DARK,
      borderRadius: "8px",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "20px",
      "&:hover": {
        backgroundColor: COLORS.DOS_BLUE_DARK,
        color: "#FFFFFF",
        border: "1px solid",
        borderColor: COLORS.DOS_BLUE_DARK,
        borderRadius: "8px",
      },
      "&:disabled": {
        border: "1px solid rgba(0, 0, 0, 0.12)",
      },
    },
    searchBoxStyle: {
      width: "364px",
      background: "#FFFFFF",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
    },
    cardRoot: {
      width: "100%",
      height: "calc(100vh - 256px)",
      background: "#FFFFFF",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      overflow: "auto",
    },
    tableHeaderStyle: {
      width: "auto",
      height: "auto",
    },
    cellHeaderStyle: {
      position: "sticky",
      top: 0,
      left: 0,
      backgroundColor: COLORS.DOS_BLUE_SUPER_LIGHT,
      padding: "12px 0px 0px 12px",
      margin: 0,
      color: "#252525",
      fontWeight: 600,
      fontSize: "12px",
      border: "none",
      zIndex: 0,
      "&.MuiTableCell-paddingNone:last-child": {
        padding: "12px 12px 0px 12px",
      },
    },
    cellSubHeaderStyle: {
      position: "sticky",
      top: "33px",
      backgroundColor: COLORS.DOS_BLUE_SUPER_LIGHT,
      padding: "0px 6px 0px 6px",
      margin: 0,
      minWidth: "75px",
      color: "#505050",
      fontWeight: 500,
      fontSize: "10px",
      border: "none",
      borderCollapse: "collapse",
      verticalAlign: "middle",
      zIndex: 0,
      "&.MuiTableCell-paddingNone:last-child": {
        padding: "0px 6px 0px 6px",
      },
    },
    noDataRowStyle: {
      width: "auto",
      height: "calc(100vh - 415px)",
      verticalAlign: "middle",
      textAlign: "center",
      borderRadius: "8px",
      border: "none",
      boxShadow: "none",
    },
    cellDataStyle: {
      width: "auto",
      height: "40px",
      verticalAlign: "middle",
      color: "#2D2D2D",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "15px",
      border: "none",
      borderBottom: "1px solid #F4F4F4",
      padding: "8px 8px 0px 8px",
      "&.MuiTableCell-paddingNone:last-child": {
        padding: "8px 8px 0px 8px",
      },
    },
    cellActionStyle: {
      width: "auto",
      height: "40px",
      verticalAlign: "middle",
      border: "none",
      borderBottom: "1px solid #F4F4F4",
      padding: "12px 0px 0px 12px",
    },
    actionDiv: {
      display: "flex",
      justifyContent: "space-between",
    },
    actionIconStyle: {
      padding: 0,
      margin: "0px 8px 0px 8px",
      cursor: "pointer",
    },
    tablePaginationStyle: {
      position: "fixed",
      bottom: 0,
      justifyContent: "flex-end",
      width: "calc(100vw - 110px)",
      marginBottom: "24px",
      marginLeft: "-2px",
      background: "#E6E6E6",
      borderRadius: "0px 0px 8px 8px",
      minHeight: "54px",
      maxHeight: "54px",
    },
    tableContainerStyle: {
      height: "calc(100vh - 310px)",
    },
    thresholdValueTextStyle: {
      color: "#2D2D2D",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "15px",
    },
    rangeConfiguredTextStyle: {
      color: "#0E5AA7",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "15px",
      cursor: "pointer",
    },
    hiddenSearchIcon: {
      opacity: "0",
    },
    headersearchIconstyle: {
      opacity: 1,
    },
    resetButtonStyle: {
      width: "212px",
      border: "1px solid #0E5AA7",
      background: "#fff",
      color: "#0E5AA7",
      "&:hover": {
        border: "1px solid #0E5AA7",
        background: "#fff",
        color: "#0E5AA7",
      },
    },
    cellHeaderPosNoStyle: {
      minWidth: "70px",
      left: 0,
      zIndex: 999,
    },
    cellHeaderPosNameStyle: {
      minWidth: "150px",
      left: "70px",
      zIndex: 999,
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    cellHeaderZoneNameStyle: {
      minWidth: "100px",
      padding: "12px 12px 0px 12px",
      left: "220px",
      zIndex: 999,
    },
    cellHeaderActionStyle: {
      minWidth: "90px",
    },
    cellHeaderServiceTypeStyle: {
      minWidth: "450px",
      textAlign: "center",
      border: "none",
    },
    cellDataStickyStyle: {
      position: "sticky",
      top: 0,
      zIndex: 0,
      height: "40px",
      verticalAlign: "middle",
      color: "#2D2D2D",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "15px",
      border: "none",
      borderBottom: "1px solid #F4F4F4",
      padding: "12px 12px 0px 12px",
      backgroundColor: COLORS.DOS_BLUE_SUPER_LIGHT,
      "&.MuiTableCell-paddingNone:last-child": {
        padding: "12px 12px 0px 12px",
      },
    },
    thresholdRangeTooltip: {
      margin: 0,
      padding: "2px",
      "&.MuiTooltip-tooltip": {
        margin: 0,
        padding: "2px",
      },
    },
    thresholdRangeTooltipCardContent: {
      margin: 0,
      padding: "4px",
      "&.MuiCardContent-root:last-child": {
        padding: "4px",
      },
    },
    thresholdRangeTooltipTableCell: {
      margin: 0,
      padding: "4px",
      border: "1px solid #141414",
      borderRadius: "4px",
      fontSize: "12px",
      color: COLORS.COD_GRAY,
      background: COLORS.DOS_BLUE_SUPER_LIGHT,
    },
    resetButtonDivStyle: {
      padding: 0,
      paddingRight: "8px",
      margin: 0,
      display: "flex",
      justifyContent: "flex-end",
    },
  })
);
