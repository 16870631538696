import React from "react";

import { Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import { generateDropdownItems } from "../../../utils/helperFunctions";
import { DEFAULT_RULE_CONFIG, RULE_ENGINE_TEXT_CONSTANT } from "../RuleEngineUtils";

interface ChangeConfigOtionProps {
  config: DEFAULT_RULE_CONFIG;
  classes: any;
  checkChangeHandler: any;
  checked: boolean;
  changeExisting?: boolean;
  xsValue?: any;
  onConfigEdit: any;
}

export const ChangeConfigOtion = (props: ChangeConfigOtionProps) => {
  const { config, classes, checkChangeHandler, checked, xsValue, changeExisting, onConfigEdit } = props;
  return (
    <Grid item xs={3} className={classes.dFlexEnd}>
      {config.isEditMode ? (
        <FormControlLabel
          control={<Checkbox className={classes.sameAsEarlierCheckbox} checked={changeExisting} onChange={onConfigEdit} />}
          label={<Typography className={classes.checkboxLabel}>{`${RULE_ENGINE_TEXT_CONSTANT.CHANGE_EXISTING_CONFIGURATION}`}</Typography>}
        />
      ) : (
        <FormControlLabel
          control={<Checkbox className={classes.sameAsEarlierCheckbox} checked={checked} onChange={checkChangeHandler} />}
          label={<Typography className={classes.checkboxLabel}>{`${RULE_ENGINE_TEXT_CONSTANT.USE_EXISTING_CONFIGURATION}`}</Typography>}
        />
      )}
    </Grid>
  );
};

export const getDefaultOrFixedTimeMinutes = (defaultOrFixedTimeHours: any) => {
  let minutesItems = [];

  if (!defaultOrFixedTimeHours) {
    minutesItems = generateDropdownItems(15, 59, "minutes");
    return minutesItems;
  }

  minutesItems = generateDropdownItems(0, 59, "minutes");
  return minutesItems;
};
