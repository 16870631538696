import React from "react";

import { Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, Typography } from "@material-ui/core";

import TextBox from "../../common/TextBox";

import searchIcon from "../../assets/svg/SearchIcon.svg";
import ZoomOut from "../../assets/svg/ZoomOut.svg";

import { capitalizeFirstLetter } from "../../../utils/DataUtils";

interface PolygonPOSZoneRecordViewInterface {
  classes: any;
  currentCountry: any;
  appFilterPopUpObj: any;
  headerRowCheckboxOnChange: any;
  handlePosZoneSearch: any;
  handlePosZoneSearchTextChange: any;
}

const PolygonPOSZoneRecordView = (props: PolygonPOSZoneRecordViewInterface) => {
  const { classes, currentCountry, appFilterPopUpObj, headerRowCheckboxOnChange, handlePosZoneSearch, handlePosZoneSearchTextChange } = props;

  return (
    <>
      <TextBox
        textBoxId="searchTextBox"
        placeholderText="Search by POS No. or Name"
        endAdornment={
          <InputAdornment position="end">
            <IconButton edge="end" size="small">
              <img src={searchIcon} alt="search" onClick={handlePosZoneSearch} />
            </IconButton>
          </InputAdornment>
        }
        value={appFilterPopUpObj.posZoneSearchText}
        name="posZoneSearchText"
        handleChange={handlePosZoneSearchTextChange}
        className={classes.searchBoxGridDiv}
      />
      {appFilterPopUpObj !== undefined &&
      appFilterPopUpObj.posZoneServiceFilteredRecord !== undefined &&
      Array.isArray(appFilterPopUpObj.posZoneServiceFilteredRecord) &&
      appFilterPopUpObj.posZoneServiceFilteredRecord.length > 0 ? (
        appFilterPopUpObj.posZoneServiceFilteredRecord.map(
          (listData: any, listDataIndex: any) =>
            listData !== undefined &&
            listData.hide_row !== undefined &&
            listData.hide_row === false && (
              <Grid container spacing={1} key={`${listDataIndex}-listData-posZoneServiceFilteredRecord`} className={classes.listMainGridStyle}>
                <Grid item className={classes.listItemGridStyle}>
                  <FormControlLabel
                    key={`listData-${listDataIndex}-checkbox`}
                    control={
                      <Checkbox
                        name={"listDataCheckbox"}
                        checked={listData !== undefined && listData.isChecked !== undefined && listData.isChecked ? true : false}
                        onChange={(event: any) => headerRowCheckboxOnChange(event, listDataIndex)}
                      />
                    }
                    label={""}
                  />
                </Grid>
                <Grid item className={classes.listItemGridStyle}>
                  <Typography className={classes.listItemSpanStyle}>{listData?.posNo || "-"}</Typography>
                </Grid>
                <Grid item className={classes.listItemGridStyle}>
                  <Typography className={classes.listItemSpanStyle}>{listData?.posName || "-"}</Typography>
                </Grid>
                <Grid item className={classes.listItemGridStyle}>
                  <Typography className={classes.listItemSpanStyle}>{listData?.zone || "-"}</Typography>
                </Grid>
              </Grid>
            )
        )
      ) : (
        <div className={classes.noDataRowStyle}>
          <Grid container>
            <Grid item xs={12}>
              <img src={ZoomOut} alt="Edit" />
            </Grid>
            <Grid item xs={12}>
              {appFilterPopUpObj !== undefined && appFilterPopUpObj.posZoneSearchText !== undefined && appFilterPopUpObj.posZoneSearchText !== "" ? (
                <Typography> {`No search results available`}</Typography>
              ) : (
                <Typography> {`No POS or Zone has been created for ${capitalizeFirstLetter(appFilterPopUpObj.filterName) || "Service Type"} in ${currentCountry.name || "country"}`}</Typography>
              )}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default React.memo(PolygonPOSZoneRecordView);
