import React from "react";

import { Button, Grid, SvgIcon, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";

import { useStyles } from "../../common/TableHeaderFilter/TableHeaderFilterStyles";
import { APPROVAL_RECORD_TEXT_CONSTANT, APPROVAL_STATUS, ApprovalRecordFilterPopUpStateInterface, IMPLEMENTATION_DATE, POS_NAME, POS_NO, REQUEST_DATE } from "./ApprovalRequestConstant";

import { ReactComponent as closeButton } from "../../assets/svg/closeButton.svg";

import TableHeaderFilter from "../../common/TableHeaderFilter/TableHeaderFilter";
import Loader from "../../common/Loader/Loader";
import ApprovalRequestPosNameFilterRecordView from "./ApprovalRequestPosNameFilterRecordView";
import CustomDateRangePickerPopUp from "../../common/CustomDateRangePickerPopUp/CustomDateRangePickerPopUp";
import { isArrayValid, isUndefined } from "../../../utils/DataUtils";

interface ApprovalRequestTableHeaderInterface {
  classes: any;
  //
  appFilterPopUpObj: ApprovalRecordFilterPopUpStateInterface;
  handleFilterPopupOpen: any;
  handleFilterPopupClose: any;
  handlePosNoNameSearchTextChange: any;
  headerPosNoRowCheckboxOnChange: any;
  //
  handleResetPopUpOnClick: any;
  handleApplyPopUpOnClick: any;
  handleDateChangeEvent: any;
  handleCancel: any;
}

const ApprovalRequestTableHeader = (props: ApprovalRequestTableHeaderInterface) => {
  const {
    classes,
    //
    appFilterPopUpObj,
    handleFilterPopupOpen,
    handleFilterPopupClose,
    handlePosNoNameSearchTextChange,
    headerPosNoRowCheckboxOnChange,
    //
    handleResetPopUpOnClick,
    handleApplyPopUpOnClick,
    handleDateChangeEvent,
    handleCancel,
  } = props;
  const popUpClasses = useStyles();

  const popUpContentViewForDateFilter = (anchorName: any, value: any) => {
    return (
      <CustomDateRangePickerPopUp
        classes={popUpClasses}
        handleFilterPopupOpen={(event: any) => handleFilterPopupOpen(event, anchorName)}
        isSingleDatePicker={false}
        handleDateChangeEvent={(event: any, picker: any) => handleDateChangeEvent(event, picker, anchorName)}
        handleCancel={(event: any, picker: any) => handleCancel(event, picker, anchorName)}
        anchorName={anchorName}
        value={value}
      />
    );
  };

  const renderTableCell = (alignLeft: boolean, title: string) => {
    return (
      <TableCell padding="none" size="small" align={alignLeft ? "left" : "center"} className={classes.cellHeaderStyle}>
        <Typography className={classes.cellHeaderSpanStyle}>{`${title}`}</Typography>
      </TableCell>
    );
  };

  const popUpContentViewForPosNoAndNameFilter = (anchorName: any, popUpHeaderText: any) => {
    return (
      <Grid className={popUpClasses.popoverFilter} item>
        <Grid container className={popUpClasses.headerContainer}>
          <Typography className={popUpClasses.headerContainerSpanStyle}>{`${popUpHeaderText || "Filter List"}`}</Typography>
          <SvgIcon key={"Close_icon"} className={popUpClasses.closeIcon} component={closeButton} onClick={() => handleFilterPopupClose()} />
        </Grid>
        <Grid container className={popUpClasses.mainContainer}>
          {appFilterPopUpObj && appFilterPopUpObj.loading ? (
            <div className={popUpClasses.loaderDiv}>
              <Loader />
            </div>
          ) : (
            <ApprovalRequestPosNameFilterRecordView
              classes={popUpClasses}
              appFilterPopUpObj={appFilterPopUpObj}
              handlePosNoNameSearchTextChange={handlePosNoNameSearchTextChange}
              headerPosNoRowCheckboxOnChange={headerPosNoRowCheckboxOnChange}
              anchorName={anchorName}
            />
          )}
        </Grid>
        <Grid container className={popUpClasses.buttonsContainer}>
          <Button className={`${popUpClasses.button} ${popUpClasses.resetButtonStyle}`} onClick={() => handleResetPopUpOnClick(anchorName)}>
            {"Reset"}
          </Button>
          <Button className={`${popUpClasses.button} ${popUpClasses.applyButtonStyle}`} onClick={() => handleApplyPopUpOnClick(anchorName)}>
            {"Apply"}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <TableHead className={classes.tableHeaderStyle}>
      <TableRow>
        <TableCell padding="none" size="small" align="left" className={classes.cellHeaderStyle}>
          <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
            <Typography className={classes.cellHeaderSpanStyle}>{`${APPROVAL_RECORD_TEXT_CONSTANT.POS_NO}`}</Typography>
            {!isUndefined(appFilterPopUpObj?.posNoFilter) && isArrayValid(appFilterPopUpObj?.posNoFilter) && (
              <span className={classes.filteredListSpanstyle}>{`${appFilterPopUpObj.posNoFilter.length}`}</span>
            )}
            <TableHeaderFilter
              openPopUp={appFilterPopUpObj.openPosNoPopUp}
              anchorEl={appFilterPopUpObj.anchorEl}
              handleFilterPopupOpen={(event: any) => handleFilterPopupOpen(event, POS_NO)}
              handleFilterPopupClose={handleFilterPopupClose}
              popUpContent={popUpContentViewForPosNoAndNameFilter(POS_NO, "POS No Details List")}
              anchorName={POS_NO}
            />
          </Grid>
        </TableCell>
        <TableCell padding="none" size="small" align="left" className={classes.cellHeaderStyle}>
          <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
            <Typography className={classes.cellHeaderSpanStyle}>{`${APPROVAL_RECORD_TEXT_CONSTANT.POS_NAME}`}</Typography>
            {!isUndefined(appFilterPopUpObj?.posNameFilter) && isArrayValid(appFilterPopUpObj?.posNameFilter) && (
              <span className={classes.filteredListSpanstyle}>{`${appFilterPopUpObj.posNameFilter.length}`}</span>
            )}
            <TableHeaderFilter
              openPopUp={appFilterPopUpObj.openPosNamePopUp}
              anchorEl={appFilterPopUpObj.anchorEl}
              handleFilterPopupOpen={(event: any) => handleFilterPopupOpen(event, POS_NAME)}
              handleFilterPopupClose={handleFilterPopupClose}
              popUpContent={popUpContentViewForPosNoAndNameFilter(POS_NAME, "POS Name Details List")}
              anchorName={POS_NAME}
            />
          </Grid>
        </TableCell>
        {renderTableCell(APPROVAL_RECORD_TEXT_CONSTANT.ALIGN_LEFT, APPROVAL_RECORD_TEXT_CONSTANT.ZONE_NO)}
        {renderTableCell(APPROVAL_RECORD_TEXT_CONSTANT.ALIGN_LEFT, APPROVAL_RECORD_TEXT_CONSTANT.SERVICE_TYPE)}
        {renderTableCell(APPROVAL_RECORD_TEXT_CONSTANT.ALIGN_LEFT, APPROVAL_RECORD_TEXT_CONSTANT.REQUESTOR_NAME)}
        <TableCell padding="none" size="small" align="left" className={classes.cellHeaderStyle}>
          <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
            <Typography className={classes.cellHeaderSpanStyle}>{`${APPROVAL_RECORD_TEXT_CONSTANT.REQUEST_DATE}`}</Typography>
            <TableHeaderFilter
              openPopUp={appFilterPopUpObj.openRequestDatePopUp}
              anchorEl={appFilterPopUpObj.anchorEl}
              handleFilterPopupOpen={(event: any) => handleFilterPopupOpen(event, REQUEST_DATE)}
              handleFilterPopupClose={handleFilterPopupClose}
              popUpContent={popUpContentViewForDateFilter(REQUEST_DATE, appFilterPopUpObj.requestDate)}
              anchorName={REQUEST_DATE}
              filterType={"dateRange"}
            />
          </Grid>
        </TableCell>
        <TableCell padding="none" size="small" align="left" className={classes.cellHeaderStyle}>
          <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
            <Typography className={classes.cellHeaderSpanStyle}>{`${APPROVAL_RECORD_TEXT_CONSTANT.IMPLEMENTATION_DATE}`}</Typography>
            <TableHeaderFilter
              openPopUp={appFilterPopUpObj.openImplementationDatePopUp}
              anchorEl={appFilterPopUpObj.anchorEl}
              handleFilterPopupOpen={(event: any) => handleFilterPopupOpen(event, IMPLEMENTATION_DATE)}
              handleFilterPopupClose={handleFilterPopupClose}
              popUpContent={popUpContentViewForDateFilter(IMPLEMENTATION_DATE, appFilterPopUpObj.implementationDate)}
              anchorName={IMPLEMENTATION_DATE}
              filterType={"dateRange"}
            />
          </Grid>
        </TableCell>
        {renderTableCell(APPROVAL_RECORD_TEXT_CONSTANT.ALIGN_LEFT, APPROVAL_RECORD_TEXT_CONSTANT.DAYS_PENDING)}
        <TableCell padding="none" size="small" align="left" className={classes.cellHeaderStyle}>
          <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
            <Typography className={classes.cellHeaderSpanStyle}>{`${APPROVAL_RECORD_TEXT_CONSTANT.APPROVAL_STATUS}`}</Typography>
            {!isUndefined(appFilterPopUpObj?.statusFilter) && isArrayValid(appFilterPopUpObj?.statusFilter) && (
              <span className={classes.filteredListSpanstyle}>{`${appFilterPopUpObj.statusFilter.length}`}</span>
            )}
            <TableHeaderFilter
              openPopUp={appFilterPopUpObj.openApprovalStatusPopUp}
              anchorEl={appFilterPopUpObj.anchorEl}
              handleFilterPopupOpen={(event: any) => handleFilterPopupOpen(event, APPROVAL_STATUS)}
              handleFilterPopupClose={handleFilterPopupClose}
              popUpContent={popUpContentViewForPosNoAndNameFilter(APPROVAL_STATUS, "Status List")}
              anchorName={APPROVAL_STATUS}
            />
          </Grid>
        </TableCell>
        {renderTableCell(APPROVAL_RECORD_TEXT_CONSTANT.ALIGN_LEFT, APPROVAL_RECORD_TEXT_CONSTANT.RESPONDER_NAME)}
        {renderTableCell(APPROVAL_RECORD_TEXT_CONSTANT.ALIGN_LEFT, APPROVAL_RECORD_TEXT_CONSTANT.RESPONSE_DATE)}
        {renderTableCell(APPROVAL_RECORD_TEXT_CONSTANT.ALIGN_CENTER, APPROVAL_RECORD_TEXT_CONSTANT.VIEW)}
      </TableRow>
    </TableHead>
  );
};

export default ApprovalRequestTableHeader;
