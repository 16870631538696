import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const userStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogRoot: {
      width: "580px",
      height: "100vh",
      margin: 0,
      maxHeight: "none",
      marginLeft: "auto",
    },
    dialogTitleStyle: {
      fontSize: "24px",
      fontWeight: 400,
      lineHeight: "29px",
      color: "#252525",
    },
    userDetailsMainDiv: {
      margin: "24px 0px 0px 0px",
      padding: 0,
      width: "100%",
      height: "auto",
    },
    userDetailsDiv: {
      display: "flex",
      justifyContent: "space-between",
      margin: 0,
      padding: "24px",
      width: "100%",
      height: "128px",
      background: "#F3F6F8",
      borderRadius: "8px",
    },
    userDetailsIconDiv: {
      display: "flex",
      justifyContent: "flex-start",
      width: "120px",
      height: "auto",
    },
    userDetailsHeaderDiv: {
      width: "460px",
      height: "auto",
    },
    headerTextNameStyle: {
      fontWeight: 600,
      fontSize: "24px",
      color: "#252525",
      lineHeight: "29px",
    },
    userDetailsEmailDiv: {
      margin: "12px 0px 0px 0px",
      padding: 0,
      display: "flex",
      justifyContent: "flex-start",
    },
    emailIconstyle: {
      padding: 0,
      margin: "0px 12px 0px 0px",
    },
    headerTextStyle: {
      fontWeight: 600,
      fontSize: "14px",
      color: "#252525",
      lineHeight: "17px",
    },
    userDetailsInfoDiv: {
      margin: 0,
      padding: 0,
    },
    infoDivMain: {
      margin: "24px 0px 0px 0px",
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
    },
    infoDiv: {
      margin: 0,
      padding: 0,
      display: "grid",
      width: "50%",
      height: "auto",
    },
    infoTextHeaderStyle: {
      fontWeight: 400,
      fontSize: "14px",
      color: "#252525",
      lineHeight: "17px",
    },
    infoTextValueStyle: {
      margin: "8px 0px 0px 0px",
      padding: 0,
      fontWeight: 500,
      fontSize: "14px",
      color: "#252525",
      lineHeight: "17px",
    },

    buttonStyles: {
      height: "48px",
      minWidth: "257px",
    },
    buttonStylesSubmit: {
      height: "48px",
      minWidth: "257px",
      marginLeft: "18px",
      color: "#FFFFFF",
      backgroundColor: "#0E5AA7",
      border: "1px solid #0E5AA7",
      "&:hover": {
        backgroundColor: "#0E5AA7",
        color: "#FFFFFF",
        border: "1px solid #0E5AA7",
        borderRadius: "8px",
      },
    },
  })
);
