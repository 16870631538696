import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../config/theme/theme";

const styles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: "16px",
    },
    accordionStyle: {
      marginTop: "12px",
    },
    accordionTitle: {
      fontSize: "18px",
      fontWeight: 600,
    },
    accordionSummary: {
      padding: "8px 24px",
    },
    accordionDetails: {
      display: "block",
      padding: "0px 24px 24px 24px",
    },
    cardContent: {
      padding: "16px",
    },
    leftContainer: {
      maxWidth: "47%",
      flexBasis: "47%",
      position: "relative",
    },
    moveToListButtons: {
      maxWidth: "6%",
      flexBasis: "6%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    moveToRightListButton: {
      transform: "rotate(270deg)",
    },
    moveToLeftListButton: {
      marginTop: "20px",
      transform: "rotate(90deg)",
    },

    areaListTransferButton: {
      cursor: "pointer",
    },
    assignedItemsContainer: {
      maxWidth: "47%",
      flexBasis: "47%",
    },
    areaTitles: {
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "20px",
      color: "#2D2D2D",
    },
    itemsCards: {
      boxShadow: "none",
      borderRadius: "8px",
      height: "100%",
    },
    list: {
      width: "-webkit-fill-available",
      overflow: "scroll",
      "&::-webkit-scrollbar": {
        width: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "rgba(77, 77, 77, 0.2)",
        borderRadius: "10px",
        height: "50px",
      },
      padding: "0px",
    },
    listNotEmpty: {
      height: "328px",
      marginTop: "8px",
    },
    listItems: {
      color: COLORS.BLACK,
      "&.MuiListItem-button:hover": {
        backgroundColor: "#E9EFF2",
      },
    },

    selectedStyles: {
      backgroundColor: "#E9EFF2",
      "& span": {
        fontWeight: 500,
      },
    },
    error: {
      marginTop: "10px",
      fontSize: "14px",
      color: "red",
    },
    assignedItemsSearchBar: {
      marginTop: "10px",
    },
  })
);

export default styles;
