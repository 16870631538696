import { Link } from "react-router-dom";
import moment from "moment";
import produce from "immer";

import EyeIcon from "../../assets/svg/EyeIconDetails.svg";

import { APPROVAL_FLOW_ACTIONS, APP_ROUTES, HH_MM_SS } from "../../../config/constants";
import { userStyles } from "../Styles/ApprovalRequestRecordStyle";
import { convertMinutesToHours } from "../../../utils/helperFunctions";
import { Grid, Typography } from "@material-ui/core";
import { APPROVAL_STATUS, ApprovalRecordFilterPopUpStateInterface, ApprovalRecordInitialStateInterface, IMPLEMENTATION_DATE, POS_NAME, POS_NO, REQUEST_DATE } from "../common/ApprovalRequestConstant";

export const getSingleUsePlasticListColumns = (userInfo: any) => {
  const classes = userStyles();

  return [
    { sortable: false, flex: 0.5, field: "posNo", headerName: "POS No", headerClassName: classes.headerStyle },
    { sortable: false, flex: 1, field: "storeName", headerName: "POS Name", headerClassName: classes.headerStyle },
    { sortable: false, flex: 0.5, field: "zoneName", headerName: "Zone No", headerClassName: classes.headerStyle },
    { sortable: false, flex: 0.5, field: "serviceType", headerName: "Service Type", headerClassName: classes.headerStyle },
    { sortable: false, flex: 1, field: "requester", headerName: "Requestor Name", headerClassName: classes.headerStyle },
    { sortable: false, flex: 1, field: "requestDate", headerName: "Request Date", headerClassName: classes.headerStyle },
    { sortable: false, flex: 1, field: "activationDate", headerName: "Implementation Date", headerClassName: classes.headerStyle },
    {
      sortable: false,
      flex: 0.5,
      field: "pendingDays",
      headerName: "Days Pending",
      headerClassName: classes.headerStyle,
      renderCell: (params: any) => {
        return params !== undefined && params.row !== undefined && params.row.pendingDays !== undefined && params.row.pendingDays !== null && params.row.pendingDays !== ""
          ? params.row.pendingDays
          : "-";
      },
    },
    {
      sortable: false,
      flex: 1,
      field: "approvalStatus",
      headerName: "Approval Status",
      headerClassName: classes.headerStyle,
      renderCell: (params: any) => {
        const {
          row: { approvalStatus },
        } = params;
        return (
          <div
            className={
              approvalStatus === APPROVAL_FLOW_ACTIONS.APPROVED
                ? classes.approvedStatusStyle
                : approvalStatus === APPROVAL_FLOW_ACTIONS.REJECTED
                ? classes.rejectedStatusStyle
                : classes.pendingStatusStyle
            }
          >{`${approvalStatus || "n/a"}`}</div>
        );
      },
    },
    {
      sortable: false,
      flex: 0.5,
      field: "actions",
      headerName: "View",
      headerClassName: classes.headerStyle,
      renderCell: (params: any) => {
        const {
          row: { posNo, zoneName, serviceType, approvalStatus, requestedId, storeName },
        } = params;
        return (
          <Link
            to={{
              pathname: `${APP_ROUTES.APPROVAL_REQUESTS_DETAILS}`,
              search: `?posNo=${posNo}&zoneName=${zoneName}&serviceType=${serviceType}&approvalStatus=${approvalStatus}&requestedId=${requestedId}&storeName=${storeName}`,
            }}
          >
            <div className={classes.editIconStyle}>
              <img src={EyeIcon} alt="view config" />
            </div>
          </Link>
        );
      },
    },
  ];
};

// export const design_selected_approval_record_filtered_list_based_on_search = (stateObj: any) => {
//   if (stateObj !== undefined && stateObj.recordList !== undefined && Array.isArray(stateObj.recordList) && stateObj.recordList.length > 0) {
//     let filteredList = [...stateObj.recordList];
//     if (stateObj.searchText !== undefined && stateObj.searchText !== "") {
//       filteredList = filteredList.filter(
//         (list: any) =>
//           (list.posNo && list.posNo.toLowerCase().includes(stateObj.searchText.toLowerCase())) || (list.storeName && list.storeName.toLowerCase().includes(stateObj.searchText.toLowerCase()))
//       );
//     }
//     return filteredList;
//   }
//   return [];
// };

export const designApprovalFlowRecordList = (record: any) => {
  if (record && Array.isArray(record) && record.length > 0) {
    let recordData: any = record.map((item: any) => ({
      ...item,
      id: item?.requestId || Math.random(),
      requestedId: item?.requestId || "",
    }));
    return recordData;
  }
  return [];
};

export const design_approval_request_record_based_on_request_id_util = (data: any) => {
  const response_obj = {
    slots: {},
    requestSlots: {},
  } as any;
  if (data !== undefined && typeof data === "object" && Object.keys(data).length > 0) {
    //
    Object.keys(data).forEach((slot_obj: any) => {
      if (slot_obj !== undefined && data[slot_obj] !== undefined) {
        const { templateSlots } = data[slot_obj];
        if (templateSlots !== undefined && Array.isArray(templateSlots) && templateSlots.length > 0) {
          templateSlots.forEach((slot: any) => {
            if (slot !== undefined && slot.id !== undefined) {
              const reques_slot_obj = {
                ...slot,
                startTime: moment(slot.startTime, HH_MM_SS),
                endTime: moment(slot.endTime, HH_MM_SS),
                status: slot.status,
                time: `${moment(slot.startTime, HH_MM_SS).format("HH:mm")} - ${moment(slot.endTime, HH_MM_SS).format("HH:mm")}`,
                bufferTimeHours: convertMinutesToHours(slot.bufferTime).hours,
                bufferTimeMinutes: convertMinutesToHours(slot.bufferTime).minutes,
                orderCutOff: moment(slot.startTime, HH_MM_SS).subtract(convertMinutesToHours(slot.bufferTime).minutes, "m").format("HH:mm"),
              };

              if (slot_obj === "requestSlots") {
                if (response_obj.requestSlots[slot.day] !== undefined) {
                  response_obj.requestSlots[slot.day].push(reques_slot_obj);
                } else {
                  response_obj.requestSlots[slot.day] = [reques_slot_obj];
                }
              } else if (slot_obj === "slots") {
                if (response_obj.slots[slot.day] !== undefined) {
                  response_obj.slots[slot.day].push(reques_slot_obj);
                } else {
                  response_obj.slots[slot.day] = [reques_slot_obj];
                }
              }
            }
          });
        }
      }
    });
  }
  return response_obj;
};

interface ApprovalCongigurationDetailsHeadingInterface {
  xs: any;
  classes: any;
  headingName: any;
  headingValue: any;
}
export const ApprovalCongigurationDetailsHeading = (props: ApprovalCongigurationDetailsHeadingInterface) => {
  const { xs, classes, headingName, headingValue } = props;

  return (
    <Grid item className={classes.approverDetailsMainHeadingDivStyle}>
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.approverDetailsHeadingDivStyle}>
          <Typography className={classes.commonApproverDetailsHeadingSpanStyle}>{`${headingName}`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.approverDetailsHeadingValueSpanStyle}>{`${headingValue || "-"}`}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const desgin_approval_flow_record_list = (approvalRecordState: ApprovalRecordInitialStateInterface, record_data: any) => {
  let newApprovalRecordState: ApprovalRecordInitialStateInterface = produce(approvalRecordState, (draft: ApprovalRecordInitialStateInterface) => {
    let recordListData = [] as any;
    if (record_data !== undefined && record_data.content !== undefined && Array.isArray(record_data.content) && record_data.content.length > 0) {
      record_data.content.forEach((record: any) => {
        let obj = { ...record } as any;
        obj.id = record?.requestId || Math.random();
        obj.requestedId = record?.requestId || "";
        recordListData.push(obj);
      });
    }
    draft.isLoading = false;
    draft.pageNumber = record_data.pageNumber;
    draft.pageSize = record_data.size;
    draft.maxRecordPresent = record_data.totalSize;
    draft.recordList = [...recordListData];
  });
  return newApprovalRecordState;
};

export const handle_open_filter_pop_up_utils = (event: any, appFilterPopUpObj: ApprovalRecordFilterPopUpStateInterface, open: any, anchorName: any) => {
  let newAppFilterPopUpObj: ApprovalRecordFilterPopUpStateInterface = produce(appFilterPopUpObj, (draft: ApprovalRecordFilterPopUpStateInterface) => {
    draft.loading = open;
    if (open) {
      if (anchorName === POS_NO) {
        draft.openPosNoPopUp = open;
      }
      if (anchorName === POS_NAME) {
        draft.openPosNamePopUp = open;
      }
      if (anchorName === APPROVAL_STATUS) {
        draft.openApprovalStatusPopUp = open;
      }
      if (anchorName === REQUEST_DATE) {
        draft.openRequestDatePopUp = open;
      }
      if (anchorName === IMPLEMENTATION_DATE) {
        draft.openImplementationDatePopUp = open;
      }
    } else {
      draft.openPosNoPopUp = false;
      draft.openPosNamePopUp = false;
      draft.openApprovalStatusPopUp = false;
      draft.openRequestDatePopUp = false;
      draft.openImplementationDatePopUp = false;
    }

    draft.anchorEl = null;
    if (event !== undefined && event.currentTarget !== undefined && event.currentTarget !== null) {
      draft.anchorEl = event.currentTarget;
    }
    //
    draft.filterByHeader = anchorName;
    //
    draft.posNoNameSearchText = "";
    draft.posNoNameRecord = [];
    draft.posNoNameFilteredRecord = [];
  });
  return newAppFilterPopUpObj;
};

export const clear_all_filter_utils = (appFilterPopUpObj: ApprovalRecordFilterPopUpStateInterface) => {
  let newAppFilterPopUpObj: ApprovalRecordFilterPopUpStateInterface = produce(appFilterPopUpObj, (draft: ApprovalRecordFilterPopUpStateInterface) => {
    draft.loading = false;
    draft.isDataFetched = false;
    draft.openPosNoPopUp = false;
    draft.openPosNamePopUp = false;
    draft.openApprovalStatusPopUp = false;
    draft.openRequestDatePopUp = false;
    draft.openImplementationDatePopUp = false;
    draft.anchorEl = null;
    draft.filterByHeader = "";
    draft.posNoNameSearchText = "";
    draft.posNoNameRecord = [];
    draft.posNoNameFilteredRecord = [];
    draft.posNoFilter = [];
    draft.posNameFilter = [];
    draft.statusFilter = [];
    draft.requestDatelabel = "";
    draft.requestDate = { startDate: "", endDate: "" };
    draft.implementationDatelabel = "";
    draft.implementationDate = { startDate: "", endDate: "" };
  });
  return newAppFilterPopUpObj;
};

export const _handle_design_mapped_pos_no_name_record_util = (appFilterPopUpObj: ApprovalRecordFilterPopUpStateInterface, listData: any, anchorName: any) => {
  let newAppFilterPopUpObj: any = produce(appFilterPopUpObj, (draft: ApprovalRecordFilterPopUpStateInterface) => {
    listData = listData.map((value: any) => ({ ...value, isChecked: false, hide_row: false }));
    draft.loading = false;
    if (draft.filterByHeader !== undefined && draft.filterByHeader !== "") {
      if (draft.filterByHeader === POS_NO && draft.posNoFilter !== undefined && Array.isArray(draft.posNoFilter) && draft.posNoFilter.length > 0) {
        listData = listData.map((value: any) =>
          value !== undefined && value.id !== undefined && draft.posNoFilter.findIndex((item: any) => item.id === value.id) > -1 ? { ...value, isChecked: true } : value
        );
      } else if (draft.filterByHeader === POS_NAME && draft.posNameFilter !== undefined && Array.isArray(draft.posNameFilter) && draft.posNameFilter.length > 0) {
        listData = listData.map((value: any) =>
          value !== undefined && value.id !== undefined && draft.posNameFilter.findIndex((item: any) => item.id === value.id) > -1 ? { ...value, isChecked: true } : value
        );
      } else if (draft.filterByHeader === APPROVAL_STATUS && draft.statusFilter !== undefined && Array.isArray(draft.statusFilter) && draft.statusFilter.length > 0) {
        listData = listData.map((value: any) =>
          value !== undefined && value.id !== undefined && value.id !== "" && draft.statusFilter.findIndex((item: any) => item === value.id) > -1 ? { ...value, isChecked: true } : value
        );
      }
    }
    draft.posNoNameRecord = listData;
    draft.posNoNameFilteredRecord = listData;
  });
  return newAppFilterPopUpObj;
};

export const _handle_design_record_based_on_search_util = (data_list: any, searchText: any, current_list: any) => {
  let result_list = [] as any;
  let selected_list = current_list.filter((list: any) => list.isChecked === true);
  //
  if (data_list !== undefined && Array.isArray(data_list) && data_list.length > 0) {
    if (searchText !== undefined && searchText !== "") {
      let filtered_list_data = data_list.filter(
        (list: any) =>
          (list.posNo && list.posNo.toLowerCase().includes(searchText.toLowerCase())) ||
          (list.posName && list.posName.toLowerCase().includes(searchText.toLowerCase())) ||
          (list.value && list.value.toLowerCase().includes(searchText.toLowerCase()))
      );
      result_list = [...filtered_list_data];
    } else {
      result_list = [...data_list];
    }
  }

  let filtered_list = JSON.parse(JSON.stringify(result_list));
  if (selected_list !== undefined && Array.isArray(selected_list) && selected_list.length > 0) {
    selected_list.forEach((selected: any) => {
      if (selected !== undefined && selected.id !== undefined && filtered_list.findIndex((item: any) => item.id === selected.id) === -1) {
        let selected_copy = JSON.parse(JSON.stringify(selected));
        selected_copy.hide_row = true;
        filtered_list.push(selected_copy);
      } else {
        let index = filtered_list.findIndex((item: any) => item.id === selected.id);
        filtered_list[index].isChecked = true;
      }
    });
  }
  const returnObject = { listData: filtered_list };
  return returnObject;
};

export const handle_check_box_click_utils = (appFilterPopUpObj: ApprovalRecordFilterPopUpStateInterface, checked: any, listIndex: any, filterType: any) => {
  let newAppFilterPopUpObj: ApprovalRecordFilterPopUpStateInterface = produce(appFilterPopUpObj, (draft: ApprovalRecordFilterPopUpStateInterface) => {
    draft.posNoNameFilteredRecord[listIndex].isChecked = checked;
    if (filterType !== undefined && filterType !== "") {
      if (checked) {
        switch (filterType) {
          case POS_NO:
            draft.posNoFilter.push(draft.posNoNameFilteredRecord[listIndex]);
            break;
          case POS_NAME:
            draft.posNameFilter.push(draft.posNoNameFilteredRecord[listIndex]);
            break;
          case APPROVAL_STATUS:
            draft.statusFilter.push(draft.posNoNameFilteredRecord[listIndex].id);
            break;
        }
      } else {
        switch (filterType) {
          case POS_NO:
            draft.posNoFilter = draft.posNoFilter.filter((obj: any) => obj.id !== draft.posNoNameFilteredRecord[listIndex].id);
            break;
          case POS_NAME:
            draft.posNameFilter = draft.posNameFilter.filter((obj: any) => obj.id !== draft.posNoNameFilteredRecord[listIndex].id);
            break;
          case APPROVAL_STATUS:
            draft.statusFilter = draft.statusFilter.filter((obj: any) => obj !== draft.posNoNameFilteredRecord[listIndex].id);
            break;
        }
      }
    }
  });
  return newAppFilterPopUpObj;
};

export const handle_reset_pos_no_name_filter_pop_up_utils = (appFilterPopUpObj: ApprovalRecordFilterPopUpStateInterface, anchorName: any) => {
  let newAppFilterPopUpObj: ApprovalRecordFilterPopUpStateInterface = produce(appFilterPopUpObj, (draft: ApprovalRecordFilterPopUpStateInterface) => {
    let listData = appFilterPopUpObj.posNoNameRecord.map((value: any) => ({ ...value, isChecked: false }));
    draft.posNoNameSearchText = "";
    draft.posNoNameRecord = listData;
    draft.posNoNameFilteredRecord = listData;
    if (anchorName !== undefined && anchorName !== "") {
      switch (anchorName) {
        case POS_NO:
          draft.posNoFilter = [];
          break;
        case POS_NAME:
          draft.posNameFilter = [];
          break;
        case APPROVAL_STATUS:
          draft.statusFilter = [];
          break;
      }
    }
  });
  return newAppFilterPopUpObj;
};

export const handle_close_pop_and_fetch_data = (appFilterPopUpObj: ApprovalRecordFilterPopUpStateInterface) => {
  let newAppFilterPopUpObj: ApprovalRecordFilterPopUpStateInterface = produce(appFilterPopUpObj, (draft: ApprovalRecordFilterPopUpStateInterface) => {
    draft.loading = false;
    draft.isDataFetched = true;
    draft.openPosNoPopUp = false;
    draft.openPosNamePopUp = false;
    draft.openApprovalStatusPopUp = false;
    draft.openRequestDatePopUp = false;
    draft.openImplementationDatePopUp = false;
    draft.anchorEl = null;
    draft.filterByHeader = "";
    draft.posNoNameSearchText = "";
    draft.posNoNameRecord = [];
    draft.posNoNameFilteredRecord = [];
  });
  return newAppFilterPopUpObj;
};

export const design_filter_object_from_header = (appFilterPopUpObj: ApprovalRecordFilterPopUpStateInterface) => {
  const servive_type_filter_object = {
    // requestDate: { startDate: "", endDate: "" },
    // implementationDate: { startDate: "", endDate: "" },
  } as any;
  //
  if (appFilterPopUpObj !== undefined) {
    if (appFilterPopUpObj.posNoFilter !== undefined && Array.isArray(appFilterPopUpObj.posNoFilter) && appFilterPopUpObj.posNoFilter.length > 0) {
      servive_type_filter_object.posNos = [];
      appFilterPopUpObj.posNoFilter.forEach((arr: any) => {
        servive_type_filter_object.posNos.push(arr.posNo);
      });
    }
    if (appFilterPopUpObj.posNameFilter !== undefined && Array.isArray(appFilterPopUpObj.posNameFilter) && appFilterPopUpObj.posNameFilter.length > 0) {
      servive_type_filter_object.posNames = [];
      appFilterPopUpObj.posNameFilter.forEach((arr: any) => {
        servive_type_filter_object.posNames.push(arr.posName);
      });
    }
    if (appFilterPopUpObj.statusFilter !== undefined && Array.isArray(appFilterPopUpObj.statusFilter) && appFilterPopUpObj.statusFilter.length > 0) {
      servive_type_filter_object.statuses = [...appFilterPopUpObj.statusFilter];
    }
    if (appFilterPopUpObj.requestDatelabel !== undefined && appFilterPopUpObj.requestDatelabel !== "") {
      const date_range = appFilterPopUpObj.requestDatelabel.split(" - ");
      if (date_range !== undefined && Array.isArray(date_range) && date_range.length > 0) {
        servive_type_filter_object.requestDate = {} as any;
        servive_type_filter_object.requestDate.startDate = date_range[0];
        servive_type_filter_object.requestDate.endDate = date_range[1];
      }
    }
    if (appFilterPopUpObj.implementationDatelabel !== undefined && appFilterPopUpObj.implementationDatelabel !== "") {
      const date_range = appFilterPopUpObj.implementationDatelabel.split(" - ");
      if (date_range !== undefined && Array.isArray(date_range) && date_range.length > 0) {
        servive_type_filter_object.implementationDate = {} as any;
        servive_type_filter_object.implementationDate.startDate = date_range[0];
        servive_type_filter_object.implementationDate.endDate = date_range[1];
      }
    }
  }
  return servive_type_filter_object;
};

export const update_filter_date_based_on_value = (appFilterPopUpObj: ApprovalRecordFilterPopUpStateInterface, anchorName: any, startDate: any, endDate: any) => {
  let newAppFilterPopUpObj: ApprovalRecordFilterPopUpStateInterface = produce(appFilterPopUpObj, (draft: ApprovalRecordFilterPopUpStateInterface) => {
    if (anchorName !== undefined && anchorName !== "") {
      draft.isDataFetched = false;
      if (anchorName === REQUEST_DATE) {
        draft.openRequestDatePopUp = false;
        draft.requestDatelabel = "";
        draft.requestDate = { startDate: "", endDate: "" };
        if (startDate !== "" && endDate !== "") {
          draft.requestDatelabel = `${startDate} - ${endDate}`;
          draft.requestDate.startDate = startDate;
          draft.requestDate.endDate = endDate;
          draft.isDataFetched = true;
        }
      } else if (anchorName === IMPLEMENTATION_DATE) {
        draft.openImplementationDatePopUp = false;
        draft.implementationDatelabel = "";
        draft.implementationDate = { startDate: "", endDate: "" };
        if (startDate !== "" && endDate !== "") {
          draft.implementationDatelabel = `${startDate} - ${endDate}`;
          draft.implementationDate.startDate = startDate;
          draft.implementationDate.endDate = endDate;
          draft.isDataFetched = true;
        }
      }

      if (draft.posNoFilter !== undefined && Array.isArray(draft.posNoFilter) !== undefined && draft.posNoFilter.length > 0) {
        draft.isDataFetched = true;
      } else if (draft.posNameFilter !== undefined && Array.isArray(draft.posNameFilter) !== undefined && draft.posNameFilter.length > 0) {
        draft.isDataFetched = true;
      } else if (draft.statusFilter !== undefined && Array.isArray(draft.statusFilter) !== undefined && draft.statusFilter.length > 0) {
        draft.isDataFetched = true;
      }
    }
  });
  return newAppFilterPopUpObj;
};

export const get_class_name_based_on_status = (slot: any, classes: any, slot_data_object_key: any) => {
  if (slot !== undefined && slot.slotAction !== undefined && slot.slotAction !== "") {
    switch (slot.slotAction) {
      case "UPDATE":
        return classes.updatededSlot;
      case "DELETE":
        return classes.deletedSlot;
      case "ADD":
        if (slot_data_object_key !== undefined && slot_data_object_key !== "" && slot_data_object_key !== "slots") {
          return classes.addedSlot;
        }
        return "";
    }
  }
  return "";
};
