import Grid from "@material-ui/core/Grid";
import { DataGrid } from "@material-ui/data-grid";
import * as React from "react";
import { tableStyles } from "./TableStyles";

const Table = (props) => {
  const classes = tableStyles();
  const { rowsData, columnsData, checkboxSelection, hideFooter = true, pageSize = 100, rowCount = undefined, pageNumber = 0, fetchNextRecord = undefined } = props;
  return (
    <Grid className={classes.tableSection}>
      <DataGrid
        {...props}
        rows={rowsData}
        columns={columnsData}
        headerHeight={48}
        rowHeight={48}
        disableColumnMenu={true}
        disableColumnSelector={true}
        pageSize={pageSize}
        checkboxSelection={checkboxSelection}
        className={classes.root}
        autoHeight={true}
        hideFooter={hideFooter}
        rowsPerPageOptions={[pageSize]}
        paginationMode={rowCount ? "server" : "client"}
        rowCount={rowCount}
        page={pageNumber}
        onPageChange={(pageNumber) => {
          fetchNextRecord && fetchNextRecord(pageNumber);
        }}
        density="standard"
        disableColumnFilter={true}
      />
    </Grid>
  );
};

export default React.memo(Table);
