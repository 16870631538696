import React from "react";

import { Card, Grid, Typography } from "@material-ui/core";

import { getDateFromData, getTimeFormatData } from "../../../../utils/DateUtils";
import { ReactComponent as DeleteIcon } from "../../../assets/svg/DeleteIcon.svg";
import TextBox from "../../../common/TextBox/index";

interface AdditionalDeliveryFeeConfigListInterface {
  feeConfigList: any;
  classes: any;
  stateObj: any;
  multiShipmentLevel?: any;
  shipment_name?: any;
  removeTimeConfigRecord?: any;
  handleAdditionalConfigInputChange?: any;
}

const AdditionalDeliveryFeeConfigList = (props: AdditionalDeliveryFeeConfigListInterface) => {
  const { feeConfigList, classes, stateObj, multiShipmentLevel, shipment_name, handleAdditionalConfigInputChange, removeTimeConfigRecord } = props;

  return (
    <Card className={classes.additionConfigDlvFeeMainCard}>
      <Grid container className={classes.additionDlvFeeMainTitleDivStyle}>
        <Grid item xs={12} className={classes.additionDlvFeeTitleItemDivStyle}>
          <Typography className={classes.additionDlvFeeMainTitleStyle}>Additional delivery fee configuration</Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.additionDlvFeeInnerRowItemStyle}>
        <Grid item xs={4} className={classes.dlvFeeValueDisplayDivStyle}>
          <Typography className={classes.dlvFeeValueDisplayTitleStyle}>{`Generated range for`}</Typography>
        </Grid>
        <Grid item xs={8} className={classes.dlvFeeValueDisplayDivStyle} spacing={0}>
          <Grid container>
            <Grid item xs={3} className={classes.dlvFeeValueDisplayDivStyle}>
              <Typography className={classes.dlvFeeValueDisplayTitleStyle}>Start - End Time</Typography>
            </Grid>
            <Grid item xs={2} className={classes.dlvFeeValueDisplayDivStyle}>
              <Typography className={classes.dlvFeeValueDisplayTitleStyle}>Threshold</Typography>
            </Grid>
            <Grid item xs={3} className={classes.dlvFeeValueDisplayDivStyle}>
              <Typography className={classes.dlvFeeValueDisplayTitleStyle}>Below Threshold Fee</Typography>
            </Grid>
            <Grid item xs={3} className={classes.dlvFeeValueDisplayDivStyle}>
              <Typography className={classes.dlvFeeValueDisplayTitleStyle}>Above Threshold Fee</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {feeConfigList && Array.isArray(feeConfigList) && feeConfigList.length > 0 ? (
        feeConfigList.map((config: any, configIndex: any) => (
          <Grid container key={`${configIndex}-feeConfigList-config`}>
            {config &&
              config.daysConfiguration &&
              Array.isArray(config.daysConfiguration) &&
              config.daysConfiguration.length > 0 &&
              config.daysConfiguration.map((daysConfig: any, daysConfigIndex: any) => (
                <Card key={`${daysConfigIndex}-daysConfiguration-config`} className={classes.additionDlvFeeInnerCardStyle}>
                  <Grid container className={classes.additionDlvFeeInnerRowItemStyle}>
                    <Grid item xs={4} className={classes.dlvFeeValueDisplayDivStyle}>
                      <Typography className={classes.dlvFeeValueDisplayDataStyle}>{`${
                        daysConfig && daysConfig.days && Array.isArray(daysConfig.days) && daysConfig.days.length > 0 ? daysConfig.days.join(", ") : ""
                      }, (${config.startDate ? getDateFromData(config.startDate) : ""} - ${config.endDate ? getDateFromData(config.endDate) : ""})`}</Typography>
                    </Grid>
                    <Grid item xs={8} className={classes.dlvFeeValueDisplayDivStyle} spacing={0}>
                      <Grid container>
                        {daysConfig.timeZoneConfiguration &&
                          Array.isArray(daysConfig.timeZoneConfiguration) &&
                          daysConfig.timeZoneConfiguration.length > 0 &&
                          daysConfig.timeZoneConfiguration.map((timeConfig: any, timeConfigIndex: any) => (
                            <>
                              <Grid item xs={3} className={classes.additionDlvFeeInnerRowDivStyle}>
                                <Typography className={classes.dlvFeeValueDisplayDataStyle}>{`${timeConfig.startTime ? getTimeFormatData(timeConfig.startTime) : ""} - ${
                                  timeConfig.endTime ? getTimeFormatData(timeConfig.endTime) : ""
                                }`}</Typography>
                              </Grid>
                              <Grid item xs={2} className={classes.additionDlvFeeInnerRowDivStyle}>
                                {handleAdditionalConfigInputChange !== undefined ? (
                                  <TextBox
                                    name="thresholdAmount"
                                    type="number"
                                    textBoxId="thresholdAmount"
                                    placeholderText="Threshold Fee"
                                    value={timeConfig.thresholdAmount}
                                    handleChange={(e: any) => handleAdditionalConfigInputChange(e, multiShipmentLevel, shipment_name, configIndex, daysConfigIndex, timeConfigIndex)}
                                    className={classes.rightPaddingStyle}
                                    disabled={!stateObj.enableConfigEdit}
                                  ></TextBox>
                                ) : (
                                  <Typography className={classes.dlvFeeValueDisplayDataStyle}>{`${timeConfig.thresholdAmount}`}</Typography>
                                )}
                              </Grid>
                              <Grid item xs={3} className={classes.additionDlvFeeInnerRowDivStyle}>
                                {handleAdditionalConfigInputChange !== undefined ? (
                                  <TextBox
                                    name="thresholdBelowAmount"
                                    type="number"
                                    textBoxId="thresholdBelowAmount"
                                    placeholderText="Fees Below Threshold"
                                    value={timeConfig.thresholdBelowAmount}
                                    handleChange={(e: any) => handleAdditionalConfigInputChange(e, multiShipmentLevel, shipment_name, configIndex, daysConfigIndex, timeConfigIndex)}
                                    className={classes.rightPaddingStyle}
                                    disabled={!stateObj.enableConfigEdit}
                                  ></TextBox>
                                ) : (
                                  <Typography className={classes.dlvFeeValueDisplayDataStyle}>{`${timeConfig.thresholdBelowAmount}`}</Typography>
                                )}
                              </Grid>
                              <Grid item xs={3} className={classes.additionDlvFeeInnerRowDivStyle}>
                                {handleAdditionalConfigInputChange !== undefined ? (
                                  <TextBox
                                    name="thresholdAboveAmount"
                                    type="number"
                                    textBoxId="thresholdAboveAmount"
                                    placeholderText="Fees Above Threshold"
                                    value={timeConfig.thresholdAboveAmount}
                                    handleChange={(e: any) => handleAdditionalConfigInputChange(e, multiShipmentLevel, shipment_name, configIndex, daysConfigIndex, timeConfigIndex)}
                                    className={classes.rightPaddingStyle}
                                    disabled={!stateObj.enableConfigEdit}
                                  ></TextBox>
                                ) : (
                                  <Typography className={classes.dlvFeeValueDisplayDataStyle}>{`${timeConfig.thresholdAboveAmount}`}</Typography>
                                )}
                              </Grid>

                              {stateObj.enableConfigEdit && removeTimeConfigRecord !== undefined && (
                                <Grid item xs={1} className={classes.additionDlvFeeInnerRowDivStyle}>
                                  <DeleteIcon onClick={() => removeTimeConfigRecord(multiShipmentLevel, shipment_name, configIndex, daysConfigIndex, timeConfigIndex)} style={{ cursor: "pointer" }} />
                                </Grid>
                              )}
                            </>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              ))}
          </Grid>
        ))
      ) : (
        <></>
      )}
    </Card>
  );
};

export default React.memo(AdditionalDeliveryFeeConfigList);
