import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { DAYS_DROPDOWN_OPTIONS } from "../../../config/constants";
import ExpandMoreIcon from "../../assets/svg/ExpandAccordionIcon.svg";
import SelectBox from "../../common/SelectBox/SelectBox";
import styles from "./ServiceInformationStyles";




const services = [{ id: "Standard", name: "Standard" }];

const zones = [
  { id: "Zone01", name: "Zone01" },
  { id: "Zone02", name: "Zone02" },
];

const getMarks = () => {
  let marks = [];
  for (let i = 0; i <= 23; i++) {
    marks.push({ value: i, label: `${i < 10 ? "0" : ""}${i}:00` });
  }

  return marks;
};

const ServiceInformation = () => {
  const classes = styles();

  const [sliderValue, setSliderValue] = useState(4);

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const renderOrderingTime = () => {
    return (
      <div className={classes.sliderContainer}>
        <Typography gutterBottom>Ordering Time</Typography>
        <Slider
          classes={{
            root: classes.sliderRoot,
            track: classes.sliderTrack,
            rail: classes.sliderRail,
            mark: classes.sliderMark,
            thumb: classes.sliderThumb,
          }}
          value={sliderValue}
          onChange={handleSliderChange}
          step={0.25}
          marks={getMarks()}
          max={23}
        />
      </div>
    );
  };

  return (
    <div className={classes.serviceInformation}>
      <Accordion className={classes.serviceAndSlotsAcc} defaultExpanded>
        <AccordionSummary
          expandIcon={<img src={ExpandMoreIcon} alt="Expand Accordion Icon" />}
          className={classes.serviceAndSlotsAccSummary}
        >
          Services & Slots
        </AccordionSummary>
        <AccordionDetails className={classes.serviceAndSlotsAccDetails}>
          {/* <div className={classes.serviceAndSlotsDetails}></div> */}
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <SelectBox fieldLabel="Service Type" menuItems={services} />
            </Grid>
            <Grid item xs={3}>
              <SelectBox fieldLabel="Zone Configuration" menuItems={zones} />
            </Grid>
            <Grid item xs={3}>
              <SelectBox fieldLabel="Product Type" menuItems={services} />
            </Grid>
            <Grid item xs={3}>
              <SelectBox fieldLabel="Days" menuItems={DAYS_DROPDOWN_OPTIONS} />
            </Grid>
            <Grid item xs={12}>
              {renderOrderingTime()}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ServiceInformation;
