import { createStyles, makeStyles, Theme } from "@material-ui/core";

import { COLORS } from "../../../../../config/theme/theme";

export const userStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogRoot: {
      width: "580px",
      height: "100vh",
      margin: 0,
      maxHeight: "none",
      marginLeft: "auto",
    },
    titleNameGridContainer: {
      padding: "0px",
      margin: "0px",
    },
    titleNameSpanStyle: {
      fontSize: "18px",
      fontWeight: 600,
      color: COLORS.TEXT_PRIMARY,
    },
    titleSubHeaderNameGridContainer: {
      padding: "0px",
      margin: "4px 0px 16px 0px",
    },
    titleSubHeadingNameSpanStyle: {
      fontSize: "10px",
      fontWeight: 400,
      color: COLORS.TEXT_PRIMARY,
    },
    tabSelectionBox: {
      margin: "0px",
      padding: "0px",
      height: "40px",
      maxHeight: "40px",
      minHeight: "40px",
      background: COLORS.ATHENS_GRAY,
      borderRadius: "4px",
    },
    indicator: {
      background: COLORS.TEXT_PRIMARY,
    },
    tabLabelSpanStyle: {
      fontSize: "14px",
      fontWeight: 400,
      color: COLORS.TEXT_PRIMARY,
      "&:hover": { fontSize: "14px", fontWeight: 400, color: COLORS.TEXT_PRIMARY },
      "&.MuiTab-root.Mui-selected": { fontSize: "14px", fontWeight: 600, color: COLORS.TEXT_PRIMARY },
    },
    //
    tabPanelBoxStyle: {
      margin: "0px",
      padding: "16px 0px 16px 13px",
      height: "87vh",
      minHeight: "87vh",
      maxHeight: "87vh",
      overflow: "auto",
    },
    historyGridContainer: {
      width: "100%",
      height: " 100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    historyGridNoDataSpanstyle: {
      fontSize: "14px",
      fontWeight: 500,
      color: COLORS.TEXT_PRIMARY,
    },
    //
    //
    stepperRootStyle: {
      "&.MuiStepConnector-vertical": {
        marginLeft: "14px",
      },
      "&.MuiStepper-root": {
        padding: "0px 0px 24px 0px",
      },
    },
    stepContentStyle: {
      padding: "0px 0px 0px 15px",
      borderTop: "0px",
      borderRight: "0px",
      borderBottom: "0px",
      borderLeft: "1px",
      borderColor: COLORS.SILVER,
      borderStyle: "dashed",
      "&.MuiStepContent-root": {
        marginTop: "0px",
        marginLeft: "8px",
      },
    },
    stepIconStyle: {
      height: 16,
      width: 16,
      backgroundColor: COLORS.DOS_BLUE_DARK,
      borderRadius: "50%",
    },
    stepLabelSpanStyle: {
      fontSize: "14px",
      fontWeight: 500,
      color: COLORS.TEXT_PRIMARY,
    },
    stepContentSpanStyle: {
      fontSize: "12px",
      fontWeight: 500,
      color: COLORS.SILVERCHALICE,
    },
  })
);
