import React from "react";

import { Grid, Typography } from "@material-ui/core";

import SwitchButton from "../../../common/SwitchButton/SwitchButton";
import TimePicker from "../../../common/TimePicker/TimePicker";

import useStyles from "./ShowFirstSlotAndServiceCutOffStyles";

const ShowFirstSlotAndServiceCutOffTime = ({ showFirstSlotAtOption, showFirstSlotAt, showServiceCutOffTimeOption, serviceCutOffTime, handleSwitchButton, handleShowFirstSlotOrServiceCutOffTime }) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" justifyContent="space-between" className={classes.mainContainer}>
      <Grid item xs={5}>
        <Grid container spacing={0}>
          <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-start", margin: 0, padding: 0 }}>
            <Typography className={classes.fontWeight500} style={{ margin: 0, padding: "0px 8px 0px 0px" }}>
              Show First Slot at
            </Typography>
            <SwitchButton name="showFirstSlotAtOption" checked={!!showFirstSlotAtOption} handleChange={handleSwitchButton} />
          </Grid>
        </Grid>
        <TimePicker className={classes.timePicker} value={showFirstSlotAt} handleChange={(date) => handleShowFirstSlotOrServiceCutOffTime(date, "showFirstSlotAt")} disabled={!showFirstSlotAtOption} />
      </Grid>
      <Grid item xs={5}>
        <Grid container spacing={0}>
          <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-start", margin: 0, padding: 0 }}>
            <Typography className={classes.fontWeight500} style={{ margin: 0, padding: "0px 8px 0px 0px" }}>
              Service Cut-off
            </Typography>
            <SwitchButton name="showServiceCutOffTimeOption" checked={!!showServiceCutOffTimeOption} handleChange={handleSwitchButton} />
          </Grid>
        </Grid>
        <TimePicker
          className={classes.timePicker}
          value={serviceCutOffTime}
          handleChange={(date) => handleShowFirstSlotOrServiceCutOffTime(date, "serviceCutOffTime")}
          disabled={!showServiceCutOffTimeOption}
        />
      </Grid>
    </Grid>
  );
};

export default ShowFirstSlotAndServiceCutOffTime;
