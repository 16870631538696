import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../../../../config/theme/theme";

const templateSlotInEditModeStyles = makeStyles({
  templateSlot: {
    marginBottom: "5px",
    alignItems: "center",
  },
  isInEditMode: {
    backgroundColor: "#F2F5F8",
  },
  slotEditTimeContainer: { paddingLeft: "8px" },
  slotCheckboxStyle: { marginRight: "8px" },
  isSlotUpdatedIconStyle: { marginLeft: "8px" },
  hideShrinkedLabel: {
    "&.MuiInputLabel-outlined": {
      color: "#B2B2B2",
    },
    "&.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0)",
    },
  },
  slotField: {
    backgroundColor: COLORS.WHITE,
  },
  timerInput: {
    "&.MuiInputAdornment-positionEnd": {
      marginLeft: "0px",
    },
  },
  timePickerInput: {
    paddingTop: "7px",
  },
  selectClass: {
    "&.hasLabel": {
      "& .MuiSelect-root": {
        paddingTop: 16,
        paddingBottom: 14,
      },
    },
  },
  updateSlotButton: {
    justifyContent: "left",
    padding: "0px",
    "&:hover": { backgroundColor: "transparent" },
  },
  iconButton: { cursor: "pointer" },
});

export default templateSlotInEditModeStyles;
