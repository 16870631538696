import React from "react";

import { Grid, Typography } from "@material-ui/core";

import { SERVICE_TYPE } from "../../Utils/DeliveryFeeConstants";

import TextBoxWithLabel from "../../../common/TextBoxWithLabel/TextBoxWithLabel";
import SwitchButton from "../../../common/SwitchButton/SwitchButton";
import Pencil from "../../../assets/svg/Pencil.svg";

interface MultiShipmentLevelConfigurationCommonInputViewInterface {
  classes: any;
  stateLevelIndex: any;
  name: any;
  stateObj: any;
  multiShipmentLevel: any;
  _handle_calculation_toggle_change: any;
  _handle_multi_shiment_input_change: any;
  _handle_edit_multi_shipment_enable_toggle_change: any;
  isPercentageBased: any;
}

const MultiShipmentLevelConfigurationCommonInputView = (props: MultiShipmentLevelConfigurationCommonInputViewInterface) => {
  const {
    classes,
    stateLevelIndex,
    name,
    stateObj,
    multiShipmentLevel,
    _handle_calculation_toggle_change,
    _handle_multi_shiment_input_change,
    _handle_edit_multi_shipment_enable_toggle_change,
    isPercentageBased,
  } = props;

  return (
    <Grid container className={classes.dlvFeeServiceTypeDiv} key={`${stateLevelIndex}-inner-object`}>
      <Grid container>
        <Grid item xs={7} className={classes.multiShipmentDlvFeeServiceTypeTextDivStyle}>
          <Typography className={classes.dlvFeeServiceTypeTextStyle}>{name || SERVICE_TYPE}</Typography>
        </Grid>
        <Grid item xs={5} className={classes.marginAuto}>
          <Grid container justifyContent="flex-end">
            <Grid item xs={6} className={classes.useCalculationDivStyle}>
              <Typography className={classes.dlvFeeCalculationTextStyle}>Use this value in calculation</Typography>
            </Grid>
            <Grid item xs={3}>
              <SwitchButton
                className={stateObj.isEditMode ? classes.switchStyle : classes.disableSwitch}
                size="small"
                checked={stateObj.isEditMode}
                disabled={false}
                handleChange={(e: any) => _handle_calculation_toggle_change(e, multiShipmentLevel, name)}
                leftLabel={undefined}
                rightLabel={undefined}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {stateObj.isEditMode && (
        <>
          <Grid container className={classes.thresholdInputMainDiv}>
            <Grid item xs={3} className={classes.thresholdInputDiv}>
              <TextBoxWithLabel
                label={"Threshold Amount"}
                name="orderThreshold"
                value={stateObj.orderThreshold}
                placeholderText="Threshold Amount"
                handleChange={(e: any) => _handle_multi_shiment_input_change(e, multiShipmentLevel, name)}
                type="number"
                className={classes.rightPaddingStyle}
                disabled={stateObj.enabledEdit}
              ></TextBoxWithLabel>
            </Grid>

            <Grid item xs={3} className={classes.thresholdInputDiv}>
              <TextBoxWithLabel
                label={`${isPercentageBased === true ? "Delivery Fees Above Threshold (%)" : "Delivery Fees Above Threshold (Value)"}`}
                name="deliveryFee"
                value={stateObj.deliveryFee}
                placeholderText="Delivery Fees Above Threshold"
                handleChange={(e: any) => _handle_multi_shiment_input_change(e, multiShipmentLevel, name)}
                type="number"
                className={classes.rightPaddingStyle}
                disabled={stateObj.enabledEdit}
              ></TextBoxWithLabel>
            </Grid>

            <Grid item xs={1} className={classes.actionIconDivStyle}>
              {stateObj.enabledEdit === true ? (
                <img src={Pencil} alt="Edit" className={classes.actionIconStyle} onClick={(e: any) => _handle_edit_multi_shipment_enable_toggle_change(multiShipmentLevel, name, "enabledEdit")} />
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={5}>
              {""}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default React.memo(MultiShipmentLevelConfigurationCommonInputView);
