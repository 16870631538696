import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../../config/theme/theme";

const slotsInformationStyles = makeStyles(() => ({
  accordion: {
    backgroundColor: COLORS.WHITE,
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    marginTop: "16px",
  },
  accordionSummary: {
    color: COLORS.DARK_GRAY,
    fontSize: "18px",
    fontWeight: 600,
    borderBottom: `1px solid ${COLORS.SECONDARY_DARK}`,
  },

  tabPanelStyle: {
    padding: "24px",
    backgroundColor: "#F9F9F9",
    borderRadius: "8px",
    marginTop: "24px",
  },
  horizontalLine: {
    borderBottom: `1px solid ${COLORS.SECONDARY_DARK}`,
    padding: "0px 0px",
    margin: "24px -24px",
  },
  prodTypeFieldTitle: {
    marginBottom: "8px",
    fontWeight: 600,
    fontSize: "16px",
  },
  fieldTitle: {
    fontWeight: 500,
  },
  fieldData: {
    fontWeight: 600,
    marginTop: "8px",
  },
  slotsTableHeader: {
    fontWeight: 600,
  },
  disableAccordion: {
    backgroundColor: COLORS.WHITE,
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    marginTop: "12px",
    "& .MuiAccordionSummary": {
      padding: "8px",
    },
  },
  activeAccordion: {
    backgroundColor: COLORS.WHITE,
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    marginTop: "16px !important",
  },
  disabledServiceType: {
    color: "#B2B2B2",
  },
  activeServiceType: {
    color: "#2D2D2D",
  },
  slotsAccordion: {
    backgroundColor: COLORS.WHITE,
    borderRadius: "8px",
    marginTop: "12px",
    padding: "0px",
  },
  slotsAccordionSummary: {
    padding: "0px 24px",
    color: COLORS.DARK_GRAY,
    fontSize: "14px",
    fontWeight: 600,
    margin: "0px !important",
    "&.MuiAccordionSummary-content": {
      margin: "0px",
    },
  },
  assignedAreas: {
    fontWeight: 600,
  },
  regionName: {
    marginBottom: "8px",
  },
  fontWeight500: {
    fontWeight: 500,
  },
  fontWeight600: {
    fontWeight: 600,
  },
  dayLabel: {
    fontWeight: 600,
    textTransform: "capitalize",
  },
  dBlock: {
    display: "block",
  },
  slotsContainer: {
    margin: "1px 0px",
  },
  slotDivider: {
    borderTop: "1px solid #DCDCDC",
  },
  regionSeparator: {
    margin: "16px 0px",
  },
  showFirstSlotAtContainer: {
    margin: "16px 0px",
  },
  slotsAllDaysText: {
    fontWeight: 600,
    fontSize: "16px",
  },
  areasContainer: {
    fontWeight: 600,
    marginBottom: "16px",
    fontSize: "16px",
  },
  dFlex: {
    display: "flex",
  },
  emailIcon: {
    marginRight: "5px",
  },
  collectionPointAccSummary: {
    fontSize: "14px",
  },
  collectionPointAccDetails: {
    padding: "24px",
    display: "block",
    backgroundColor: "#FAFAFA",
    marginTop: "16px",
  },
  slotsInfoContainer: {
    position: "relative",
  },
  serviceAccSummary: {
    padding: "14px 24px",
  },
  serviceAccDetails: {
    margin: "8px",
    display: "block",
  },
  textCapitalize: {
    textTransform: "capitalize",
  },
  configureServiceButton: {
    fontSize: "18px",
    padding: "6px 0px",
    textTransform: "capitalize",
  },
  posRelative: {
    position: "relative",
  },
  serviceToggle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    fontSize: "14px",
    fontWeight: 600,
  },
  editServiceIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  zoneTabBorder: {
    borderBottom: "2px solid #E6E6E6",
    marginTop: "-2px",
  },
  dayWiseSlotsContainer: {
    marginTop: "16px",
  },
  zoneAccordionStyle: {
    backgroundColor: "rgb(249, 249, 249)",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    marginTop: "8px !important",
  },
  zoneNameTextStyle: {
    color: COLORS.DARK_GRAY,
    fontSize: "16px",
    fontWeight: 500,
  },
  linkstyle: {
    textDecoration: "none",
  },
  editServiceDivStyle: {
    display: "flex",
    justifyContent: "flex-end",
    margin: 0,
    padding: 0,
    paddingRight: "24px",
  },
  editServiceIconStyle: {
    cursor: "pointer",
  },
  availableZoneStateButtonStyle: {
    width: "254px",
    background: COLORS.SEA_GREEN,
    border: `1px solid ${COLORS.SEA_GREEN}`,
    borderRadius: "8px",
    color: COLORS.WHITE,
    textDecoration: "none",
    "&:hover": {
      background: COLORS.SEA_GREEN,
      border: `1px solid ${COLORS.SEA_GREEN}`,
    },
    "&.MuiButton-label": {
      background: COLORS.SEA_GREEN,
      border: `1px solid ${COLORS.SEA_GREEN}`,
      "&:hover": {
        background: COLORS.SEA_GREEN,
        border: `1px solid ${COLORS.SEA_GREEN}`,
      },
    },
  },
  availableZoneSpanStyle: {
    fontSize: "14px",
    fontWeight: 700,
    color: COLORS.SEA_GREEN,
    margin: "auto 16px",
    textTransform: "uppercase",
  },
  busyZoneStateButtonStyle: {
    width: "254px",
    background: COLORS.DARK_RED,
    border: `1px solid ${COLORS.DARK_RED}`,
    borderRadius: "8px",
    color: COLORS.WHITE,
    textDecoration: "none",
    "&:hover": {
      background: COLORS.DARK_RED,
      border: `1px solid ${COLORS.DARK_RED}`,
    },
    "&.MuiButton-label": {
      background: COLORS.DARK_RED,
      border: `1px solid ${COLORS.DARK_RED}`,
      "&:hover": {
        background: COLORS.DARK_RED,
        border: `1px solid ${COLORS.DARK_RED}`,
      },
    },
  },
  busyZoneSpanStyle: {
    fontSize: "14px",
    fontWeight: 700,
    color: COLORS.DARK_RED,
    margin: "auto 16px",
    textTransform: "uppercase",
  },
}));

export default slotsInformationStyles;
