import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { userStyles } from "./Styles/ApprovalRequestRecordStyle";
import {
  APPROVAL_STATUS,
  ApprovalRecordFilterPopUpState,
  ApprovalRecordFilterPopUpStateInterface,
  ApprovalRecordInitialState,
  ApprovalRecordInitialStateInterface,
  ERROR_OCCURRED_WHILE_SAVING,
  IMPLEMENTATION_DATE,
  REQUEST_DATE,
} from "./common/ApprovalRequestConstant";
import { selectUserDetails } from "../Login/redux/userSlice";
import {
  _handle_design_mapped_pos_no_name_record_util,
  _handle_design_record_based_on_search_util,
  clear_all_filter_utils,
  desgin_approval_flow_record_list,
  design_filter_object_from_header,
  handle_check_box_click_utils,
  handle_close_pop_and_fetch_data,
  handle_open_filter_pop_up_utils,
  handle_reset_pos_no_name_filter_pop_up_utils,
  update_filter_date_based_on_value,
} from "./utils/ApprovalRequestRecordUtils";

import Loader from "../common/Loader/Loader";
import ApprovalRequestRecordHeader from "./common/ApprovalRequestRecordHeader";
import ApprovalRequestRecordListCard from "./common/ApprovalRequestRecordListCard";
import CustomAlert from "components/common/CustomAlert/CustomAlert";

import { getApprovalRequestRecordBasedOnHeaderFilter, getPOSNoAndNameForFilterPopUp, storeRecordDataReducer } from "./Redux/ApprovalRequestSlice";
import { ALERT_TYPES, APPROVAL_FLOW_ACTIONS_LIST } from "../../config/constants";
import { getDateFromPicker } from "../../utils/DateUtils";

const ApprovalRequestRecordPage = () => {
  const classes = userStyles();

  const appDispatch = useDispatch();

  const userInfo: any = useSelector(selectUserDetails);
  const { currentCountry } = useSelector((state: any) => state.appConfig);

  const [approvalRecordState, setApprovalRecordState] = useState<ApprovalRecordInitialStateInterface>(ApprovalRecordInitialState);
  const [appFilterPopUpObj, setAppFilterPopUpObj] = useState<ApprovalRecordFilterPopUpStateInterface>(ApprovalRecordFilterPopUpState);

  const fetchApprovalRequestRecordBasedOnHeaderFilter = async (request_payload: any, popUpObj: ApprovalRecordFilterPopUpStateInterface) => {
    const { payload }: any = await appDispatch(getApprovalRequestRecordBasedOnHeaderFilter(request_payload));
    if (payload !== undefined) {
      let newApprovalRecordState = desgin_approval_flow_record_list(approvalRecordState, payload);
      setApprovalRecordState(newApprovalRecordState);
      appDispatch(storeRecordDataReducer(undefined));
    } else {
      if (payload !== undefined && typeof payload === "object" && payload.message !== undefined && typeof payload.message === "string" && payload.message !== "") {
        CustomAlert(ALERT_TYPES.ERROR, payload.message);
      } else {
        CustomAlert(ALERT_TYPES.ERROR, ERROR_OCCURRED_WHILE_SAVING);
      }
      setApprovalRecordState({ ...approvalRecordState, recordList: [], isLoading: false });
    }
    setAppFilterPopUpObj(popUpObj);
  };

  const fetchApprovalFlowDataList = (pageNumber: any, popUpObj: any) => {
    const request_payload = {
      countryId: currentCountry.countryId,
      pageSize: approvalRecordState.pageSize,
      pageNumber: pageNumber,
      serviceTypeFilterPayload: {},
    } as any;
    let serviceTypeFilterPayload: any = design_filter_object_from_header(popUpObj);
    if (serviceTypeFilterPayload !== undefined && typeof serviceTypeFilterPayload === "object" && Object.keys(serviceTypeFilterPayload).length > 0) {
      request_payload.serviceTypeFilterPayload = { ...serviceTypeFilterPayload };
    }
    fetchApprovalRequestRecordBasedOnHeaderFilter(request_payload, popUpObj);
  };

  const clearAllFilter = () => {
    let newAppFilterPopUpObj = clear_all_filter_utils(appFilterPopUpObj);
    setAppFilterPopUpObj(newAppFilterPopUpObj);
    setApprovalRecordState({ ...approvalRecordState, isLoading: true, pageNumber: 0, recordList: [] });
    fetchApprovalFlowDataList(0, newAppFilterPopUpObj);
  };

  const fetchNextRecord = (event: any, page: any) => {
    if (page !== undefined && page !== "" && Number(page) >= 0) {
      setApprovalRecordState({ ...approvalRecordState, isLoading: true, pageNumber: page, recordList: [] });
      fetchApprovalFlowDataList(page, appFilterPopUpObj);
    }
  };

  /**
   * https://mafretail.atlassian.net/browse/DOS-2070
   */

  const design_mapped_pos_no_name_record = (listData: any, appFilterPopUpObjCopy: any, anchorName: any) => {
    if (listData !== undefined && Array.isArray(listData)) {
      let newAppFilterPopUpObj = _handle_design_mapped_pos_no_name_record_util(appFilterPopUpObjCopy, listData, anchorName);
      setAppFilterPopUpObj(newAppFilterPopUpObj);
    }
  };

  const fetchPOSNoAndNameForFilterPopUp = async (countryId: any, newAppFilterPopUpObj: ApprovalRecordFilterPopUpStateInterface, anchorName: any) => {
    const { payload }: any = await appDispatch(getPOSNoAndNameForFilterPopUp(countryId));
    if (payload !== undefined) {
      design_mapped_pos_no_name_record(payload, newAppFilterPopUpObj, anchorName);
    } else {
      if (payload !== undefined && typeof payload === "object" && payload.message !== undefined && typeof payload.message === "string" && payload.message !== "") {
        CustomAlert(ALERT_TYPES.ERROR, payload.message);
      } else {
        CustomAlert(ALERT_TYPES.ERROR, ERROR_OCCURRED_WHILE_SAVING);
      }
      let newAppFilterPopUpObj = handle_open_filter_pop_up_utils(undefined, appFilterPopUpObj, false, "");
      setAppFilterPopUpObj(newAppFilterPopUpObj);
    }
  };

  const handleFilterPopupOpen = (event: any, anchorName: any) => {
    if (currentCountry !== undefined && currentCountry !== "" && currentCountry.countryId !== undefined && currentCountry.countryId !== "") {
      let newAppFilterPopUpObj = handle_open_filter_pop_up_utils(event, appFilterPopUpObj, true, anchorName);
      setAppFilterPopUpObj(newAppFilterPopUpObj);
      if (anchorName !== REQUEST_DATE && anchorName !== IMPLEMENTATION_DATE) {
        if (anchorName === APPROVAL_STATUS) {
          let appFilterPopUpObjCopy = _handle_design_mapped_pos_no_name_record_util(newAppFilterPopUpObj, APPROVAL_FLOW_ACTIONS_LIST, anchorName);
          setAppFilterPopUpObj(appFilterPopUpObjCopy);
        } else {
          fetchPOSNoAndNameForFilterPopUp(currentCountry.countryId, newAppFilterPopUpObj, anchorName);
        }
      }
    }
  };

  const handleFilterPopupClose = () => {
    let newAppFilterPopUpObj = handle_open_filter_pop_up_utils(undefined, appFilterPopUpObj, false, "");
    setAppFilterPopUpObj(newAppFilterPopUpObj);
  };

  const handlePosNoNameSearchTextChange = (event: any, anchorName: any) => {
    if (event !== undefined && event.target !== undefined) {
      const { value } = event.target;
      let { listData } = _handle_design_record_based_on_search_util(appFilterPopUpObj.posNoNameRecord, value, appFilterPopUpObj.posNoNameFilteredRecord);
      setAppFilterPopUpObj({ ...appFilterPopUpObj, posNoNameFilteredRecord: listData, posNoNameSearchText: value });
    }
  };

  const headerPosNoRowCheckboxOnChange = (event: any, listIndex: any, filterName: any) => {
    if (listIndex !== undefined && listIndex >= 0) {
      if (event !== undefined && event.target !== undefined && event.target.checked !== undefined) {
        const { checked } = event.target;
        let newAppFilterPopUpObj = handle_check_box_click_utils(appFilterPopUpObj, checked, listIndex, filterName);
        setAppFilterPopUpObj(newAppFilterPopUpObj);
      }
    }
  };

  const handleResetPopUpOnClick = (anchorName: any) => {
    if (anchorName !== undefined && anchorName !== "") {
      let newAppFilterPopUpObj = handle_reset_pos_no_name_filter_pop_up_utils(appFilterPopUpObj, anchorName);
      setAppFilterPopUpObj(newAppFilterPopUpObj);
    }
  };

  const handleApplyPopUpOnClick = (anchorName: any) => {
    if (anchorName !== undefined && anchorName !== "") {
      let newAppFilterPopUpObj = handle_close_pop_and_fetch_data(appFilterPopUpObj);
      setAppFilterPopUpObj(newAppFilterPopUpObj);
      setApprovalRecordState({ ...approvalRecordState, recordList: [], isLoading: true });
      fetchApprovalFlowDataList(0, newAppFilterPopUpObj);
    }
  };

  const handleDateChangeEvent = (event: any, picker: any, anchorName: any) => {
    if (event && event.target) {
      const dateStart = getDateFromPicker(picker.startDate);
      const dateEnd = getDateFromPicker(picker.endDate);
      let newAppFilterPopUpObj = update_filter_date_based_on_value(appFilterPopUpObj, anchorName, dateStart, dateEnd);
      setApprovalRecordState({ ...approvalRecordState, recordList: [], isLoading: true });
      fetchApprovalFlowDataList(0, newAppFilterPopUpObj);
    }
  };

  const handleCancel = (event: any, picker: any, anchorName: any) => {
    // event.preventDefault();
    // picker.element.val("");
    let newAppFilterPopUpObj = update_filter_date_based_on_value(appFilterPopUpObj, anchorName, "", "");
    setApprovalRecordState({ ...approvalRecordState, recordList: [], isLoading: true });
    fetchApprovalFlowDataList(0, newAppFilterPopUpObj);
  };

  useEffect(() => {
    if (currentCountry !== undefined && currentCountry !== "" && currentCountry.countryId !== undefined && currentCountry.countryId !== "") {
      setApprovalRecordState({ ...approvalRecordState, recordList: [], isLoading: true });
      fetchApprovalFlowDataList(0, appFilterPopUpObj);
    }
  }, [currentCountry]);

  return (
    <div className={classes.container}>
      <ApprovalRequestRecordHeader classes={classes} clearAllFilter={clearAllFilter} />

      {approvalRecordState.isLoading ? (
        <div className={classes.loaderDiv}>
          <Loader />
        </div>
      ) : (
        <ApprovalRequestRecordListCard
          classes={classes}
          approvalRecordState={approvalRecordState}
          userInfo={userInfo}
          fetchNextRecord={fetchNextRecord}
          //
          appFilterPopUpObj={appFilterPopUpObj}
          handleFilterPopupOpen={handleFilterPopupOpen}
          handleFilterPopupClose={handleFilterPopupClose}
          handlePosNoNameSearchTextChange={handlePosNoNameSearchTextChange}
          headerPosNoRowCheckboxOnChange={headerPosNoRowCheckboxOnChange}
          //
          handleResetPopUpOnClick={handleResetPopUpOnClick}
          handleApplyPopUpOnClick={handleApplyPopUpOnClick}
          handleDateChangeEvent={handleDateChangeEvent}
          handleCancel={handleCancel}
          currentCountry={currentCountry}
          //
          appDispatch={appDispatch}
        />
      )}
    </div>
  );
};

export default React.memo(ApprovalRequestRecordPage);
