import React from "react";

import moment from "moment";

import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";

import modalStyles from "../../Standard/Styles/BulkUpdateSummaryDetailsAndConfirmationPopUpStyles";

import ModalContainer from "../../../../base/ModalContainer";
import PrimaryButton from "../../../common/PrimaryButton/PrimaryButton";

import { ReactComponent as CloseButton } from "../../../assets/svg/closeButton.svg";
import { HH_MM, TEXT_CONSTANT } from "../../../../config/constants";

interface BulkUpdateExpressPopUpInterface {
  popUpOpenFlag: any;
  currentConfiguration: any;
  configuredDays: any;
  selctedDays: any;
  currentDay: any;
  handleClose: any;
  handleDaySelectedForBulkUpdate: any;
  handleBulkUpdateConfirmAction: any;
}

const BulkUpdateExpressPopUp = (props: BulkUpdateExpressPopUpInterface) => {
  const { popUpOpenFlag, currentConfiguration, configuredDays, selctedDays, currentDay, handleClose, handleDaySelectedForBulkUpdate, handleBulkUpdateConfirmAction } = props;

  const classes = modalStyles();

  const isAllSelected = () => {
    return configuredDays?.length === selctedDays?.length;
  };

  const isDaySelectedtoCopy = (currentDay: any) => {
    if (currentDay !== undefined && currentDay !== "" && selctedDays !== undefined && Array.isArray(selctedDays) && selctedDays.length > 0 && selctedDays.indexOf(currentDay) > -1) {
      return true;
    }
    return false;
  };

  const generate_slot_time = (slot: any) => {
    if (slot.startTime !== undefined && moment(slot.startTime).isValid() && slot.endTime !== undefined && moment(slot.endTime).isValid()) {
      const date_str = moment(slot.startTime).format(HH_MM) + " - " + moment(slot.endTime).format(HH_MM);
      return date_str;
    }
    return "";
  };

  return (
    <ModalContainer onClose={handleClose} open={popUpOpenFlag}>
      <Card className={classes.cardStyle}>
        <CardContent className={classes.cardContentStyle}>
          <Grid container spacing={0} justifyContent="flex-start">
            <Grid item xs={12} className={classes.headerGridItemStyle}>
              <Grid item xs={12} className={classes.headerTitleGridItemStyle}>
                <Typography className={classes.headerSpanStyle}>{`Changes Summary -`}</Typography>
                <Typography className={classes.headerDaySpanStyle}>{`${currentDay || "Day"}`}</Typography>
              </Grid>

              <CloseButton className={classes.closeButtonIconStyle} onClick={handleClose} />
            </Grid>
          </Grid>
          <Grid container spacing={0} justifyContent="flex-start">
            <Grid item xs={12} className={classes.gridItemFullWidthStyle}>
              <Grid container spacing={1} justifyContent="flex-start">
                <Grid item xs={12} className={classes.gridInnerItemFullWidthStyle}>
                  <Typography className={classes.selectDaysSpanStyle}>{`Select the days you want to add the above changes `}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={0} justifyContent="flex-start">
                    <Grid item xs={12} className={classes.gridItemFullWidthStyle}>
                      <Grid container spacing={0} justifyContent="space-between">
                        <Grid item xs={6} className={classes.detailsTableHeaderGridItem}>
                          <Typography className={classes.detailsTableHeaderSpanStyle}>Time</Typography>
                        </Grid>
                        <Grid item xs={6} className={classes.detailsTableHeaderGridItem}>
                          <Typography className={classes.detailsTableHeaderSpanStyle}>Capacity</Typography>
                        </Grid>
                      </Grid>
                      {currentConfiguration !== undefined &&
                        currentConfiguration.generatedSlots !== undefined &&
                        Array.isArray(currentConfiguration.generatedSlots) &&
                        currentConfiguration.generatedSlots.length > 0 &&
                        currentConfiguration.generatedSlots.map((slot: any, slotIndex: any) => (
                          <Grid container spacing={0} justifyContent="space-between" key={`${slotIndex}-slotIndex-generatedSlots-currentConfiguration-${slot.id || ""}`}>
                            <Grid item xs={6} className={classes.detailsTableBodyGridItem}>
                              <Typography className={classes.detailsTableValueSpanStyle}>{`${generate_slot_time(slot)}`}</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.detailsTableBodyGridItem}>
                              <Typography className={classes.detailsTableValueSpanStyle}>{`${slot.slotCapacity}`}</Typography>
                            </Grid>
                          </Grid>
                        ))}
                    </Grid>
                    <Grid item className={isAllSelected() ? classes.selectDayGridItemSelectedStyle : classes.selectDayGridItemStyle} onClick={() => handleDaySelectedForBulkUpdate(TEXT_CONSTANT.ALL)}>
                      {isAllSelected() ? <DoneIcon className={classes.selectDayIconStyle} /> : <></>}
                      <Button className={classes.selectAllDayMainButtonStyle}>
                        <Typography className={isAllSelected() ? classes.selectDayMainButtonSpanSelectedStyle : classes.selectDayMainButtonSpanStyle}>{`ALL DAYS`}</Typography>
                      </Button>
                    </Grid>
                    {configuredDays !== undefined &&
                      Array.isArray(configuredDays) &&
                      configuredDays.length > 0 &&
                      configuredDays.map((day: any, dayIndex: any) => (
                        <Grid
                          item
                          key={`${dayIndex}-dayIndex-configuredDays`}
                          className={isDaySelectedtoCopy(day) ? classes.selectDayGridItemSelectedStyle : classes.selectDayGridItemStyle}
                          onClick={() => handleDaySelectedForBulkUpdate(day)}
                        >
                          {isDaySelectedtoCopy(day) ? <DoneIcon className={classes.selectDayIconStyle} /> : <></>}
                          <Button className={classes.selectDayMainButtonStyle}>
                            <Typography className={isDaySelectedtoCopy(day) ? classes.selectDayMainButtonSpanSelectedStyle : classes.selectDayMainButtonSpanStyle}>{`${day || ""}`}</Typography>
                          </Button>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.gridItemFullWidthStyle}>
              <Typography className={classes.specialFooterNoteSpanStyle}>{`This action will replace the existing data with new information. Please confirm if you want to proceed.`}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridItemFullWidthStyle}>
              <Grid container spacing={1} justifyContent="flex-end">
                <PrimaryButton buttonLabel="Cancel" onClick={handleClose} buttonVariant={"outlined"} className={classes.buttonStyle} />
                <PrimaryButton buttonLabel="Confirm" onClick={handleBulkUpdateConfirmAction} className={classes.buttonStyle} />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </ModalContainer>
  );
};

export default React.memo(BulkUpdateExpressPopUp);
