import { Grid } from "@material-ui/core";

interface ViewBasicDetailNameAndValueInterface {
  xsValue: any;
  classes: any;
  fieldTitle: any;
  fieldData: any;
}

export const ViewBasicDetailNameAndValue = (props: ViewBasicDetailNameAndValueInterface) => {
  const { xsValue, classes, fieldTitle, fieldData } = props;

  return (
    <Grid item xs={xsValue}>
      <div className={classes.fieldTitle}>{fieldTitle || "-"}</div>
      <div className={classes.fieldData}>{fieldData || "-"}</div>
    </Grid>
  );
};
