import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";

import { DEFAULT_CONFIG_TAB_CONFIGURATIONS_INTERFACE, STANDARD_SERVICE_CONSTANT, SecondaryStoresInterface } from "../Standard/StandardServiceConstant";

import { ReactComponent as DeleteIcon } from "../../assets/svg/DeleteIcon.svg";

interface RenderConfigureSecondarySlotMappingInterface {
  classes: any;
  configTab: DEFAULT_CONFIG_TAB_CONFIGURATIONS_INTERFACE;
  configTabIndex: number;
  dragItem: null | SecondaryStoresInterface;
  //
  handleAddSecondaryPosZoneClick: (configTabIndex: number) => void;
  removeDynamicRoutingRow: (configTabIndex: number, elementIndex: number) => void;
  handleOnDragStart: (event: React.DragEvent<HTMLDivElement>, element: SecondaryStoresInterface) => void;
  handleOnDragEnd: (event: React.DragEvent<HTMLDivElement>, element: SecondaryStoresInterface) => void;
  handleOnDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  handleOnDragDrop: (event: React.DragEvent<HTMLDivElement>, element: SecondaryStoresInterface, configTabIndex: number) => void;
}

export const renderDynamicRoutingHeading = (classes: any, value: string) => {
  return (
    <Grid item xs={2} className={classes.dynamicRoutingHeaderGridStyle}>
      <Typography className={classes.dynamicRoutingHeaderSpanStyle}>{`${value || ""}`}</Typography>
    </Grid>
  );
};

export const renderDynamicRoutingValue = (classes: any, value: string | number) => {
  return (
    <Grid item xs={2} className={classes.dynamicRoutingHeaderGridStyle}>
      <Typography className={classes.dynamicRoutingValueSpanStyle}>{`${value}`}</Typography>
    </Grid>
  );
};

const RenderConfigureSecondarySlotMapping = (props: RenderConfigureSecondarySlotMappingInterface) => {
  const { classes, configTab, configTabIndex, dragItem, handleAddSecondaryPosZoneClick, removeDynamicRoutingRow, handleOnDragStart, handleOnDragEnd, handleOnDragOver, handleOnDragDrop } = props;

  return (
    <Accordion className={classes.accordionStyle}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryStyle}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography className={classes.accordionTitle}>{`${STANDARD_SERVICE_CONSTANT.DYNAMIC_ROUTING}`}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.dFlexStart}>
            <Typography className={classes.accordionHeaderSubTitleStyle}>{`${STANDARD_SERVICE_CONSTANT.DYNAMIC_ROUTING_SUB_HEADING}`}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetailsStyle}>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12} className={classes.dynamicRoutingDivStyle}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={8} className={classes.dFlexStart}>
                    {configTab?.dynamicRoutingConfig?.secondaryStores?.length > 0 && (
                      <>
                        <Grid item xs={2} className={classes.dynamicRoutingHeaderGridStyle}></Grid>
                        <Grid item xs={1} className={classes.dynamicRoutingHeaderGridStyle}>
                          <Typography className={classes.dynamicRoutingHeaderSpanStyle}>{`${STANDARD_SERVICE_CONSTANT.RANK || ""}`}</Typography>
                        </Grid>
                        {renderDynamicRoutingHeading(classes, STANDARD_SERVICE_CONSTANT.POS_NO)}
                        <Grid item xs={4} className={classes.dynamicRoutingHeaderGridStyle}>
                          <Typography className={classes.dynamicRoutingHeaderSpanStyle}>{`${STANDARD_SERVICE_CONSTANT.POS_NAME || ""}`}</Typography>
                        </Grid>
                        {renderDynamicRoutingHeading(classes, STANDARD_SERVICE_CONSTANT.ZONE)}
                      </>
                    )}
                  </Grid>
                  <Grid item xs={4} className={classes.dFlexEnd}>
                    <Button onClick={() => handleAddSecondaryPosZoneClick(configTabIndex)} className={classes.addSecondaryPosZoneButtonStyle}>
                      <Typography className={classes.addSecondaryPosZoneSpanStyle}>{`${STANDARD_SERVICE_CONSTANT.ADD_SECONDARY_POS_ZONE_RECORD}`}</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              {configTab?.dynamicRoutingConfig?.secondaryStores?.length > 0 && (
                <Grid item xs={12}>
                  {configTab?.dynamicRoutingConfig?.secondaryStores?.map((element: SecondaryStoresInterface, elementIndex: number) => (
                    <Grid
                      container
                      spacing={0}
                      key={`${elementIndex}-Zone-Service-Type-List-Interface`}
                      className={elementIndex % 2 === 0 ? classes.dynamicRoutingValueEvenGridStyle : classes.dynamicRoutingValueGridStyle}
                    >
                      <Grid
                        item
                        xs={8}
                        className={`${classes.dFlexStart}`}
                        draggable={true}
                        onDragStart={(event: React.DragEvent<HTMLDivElement>) => handleOnDragStart(event, element)}
                        onDragEnd={(event: React.DragEvent<HTMLDivElement>) => handleOnDragEnd(event, element)}
                        onDragOver={(event: React.DragEvent<HTMLDivElement>) => handleOnDragOver(event)}
                        onDrop={(event: React.DragEvent<HTMLDivElement>) => handleOnDragDrop(event, element, configTabIndex)}
                      >
                        <Grid item xs={2} className={classes.dynamicRoutingHeaderGridStyle}>
                          <MenuIcon fontSize="small" className={classes.dragDropIconStyle} />
                        </Grid>
                        <Grid item xs={1} className={classes.dynamicRoutingHeaderGridStyle}>
                          <Typography className={classes.dynamicRoutingValueSpanStyle}>{`${elementIndex + 1}`}</Typography>
                        </Grid>
                        {renderDynamicRoutingValue(classes, element.posNo)}
                        <Grid item xs={4} className={classes.dynamicRoutingHeaderGridStyle}>
                          <Typography className={classes.dynamicRoutingValueSpanStyle}>{`${element.posName}`}</Typography>
                        </Grid>
                        {renderDynamicRoutingValue(classes, element.zone)}
                        <Grid item xs={1} className={classes.dynamicRoutingHeaderGridStyle}>
                          <DeleteIcon onClick={() => removeDynamicRoutingRow(configTabIndex, elementIndex)} className={classes.dynamicRoutingDeleteIconStyle} />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(RenderConfigureSecondarySlotMapping);
