import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_RESPONSE_STATUS } from "../../../config/constants";
import { DashboardPageSliceInterface } from "../Utils/DashboardPageInterface";
import { isNonEmptyArray } from "../../../utils/DataUtils";

import * as DashboardPageService from "../../../api/DashboardPageService";

export const fetchPosZoneServiceTypeListByCountryIdAndServiceType = createAsyncThunk("dashboardPage/fetchPosZoneServiceTypeList", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await DashboardPageService.fetchPosZoneServiceTypeList(data);
    if (response && response.data && response.data.content && Array.isArray(response.data.content)) {
      return response.data.content;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchSlotUtilizationRecord = createAsyncThunk("dashboardPage/fetchSlotUtilizationRecord", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await DashboardPageService.fetchSlotUtilizationRecord(data);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAdvanceDayCalculate = createAsyncThunk("dashboardPage/getAdvanceDayCalculate", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await DashboardPageService.getAdvanceDayCalculate(data);
    if (response && response?.data && response?.data?.serviceTypes && isNonEmptyArray(response.data.serviceTypes)) {
      let stabdardIndex = response.data.serviceTypes.findIndex((item: any) => item.serviceTypeID === 1);
      if (stabdardIndex !== -1) {
        const standardData = response.data.serviceTypes[stabdardIndex];
        if (standardData && standardData.advanceSlotDays && Number(standardData.advanceSlotDays) > 0) {
          return Number(standardData.advanceSlotDays);
        }
      }
    }
    return 0;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const initialState: DashboardPageSliceInterface = {
  loading: false,
  fetchStatus: API_RESPONSE_STATUS.IDLE,
  error: "",
  posZoneRecord: [] as any,
  slotUtilization: {} as any,
  advanceDaySize: 0,
};

const DashboardPageSlice = createSlice({
  name: "dashboardPage",
  initialState: initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      /* fetchPosZoneServiceTypeListByCountryIdAndServiceType */
      .addCase(fetchPosZoneServiceTypeListByCountryIdAndServiceType.pending, (state: any) => {
        state.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.loading = true;
        state.posZoneRecord = [];
        state.error = "";
      })
      .addCase(fetchPosZoneServiceTypeListByCountryIdAndServiceType.fulfilled, (state: any, action: any) => {
        state.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.loading = false;
        state.posZoneRecord = action.payload;
      })
      .addCase(fetchPosZoneServiceTypeListByCountryIdAndServiceType.rejected, (state: any, action: any) => {
        state.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.loading = false;
        state.error = action.error.message;
      })
      /* fetchSlotUtilizationRecord */
      .addCase(fetchSlotUtilizationRecord.pending, (state: any) => {
        state.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.loading = true;
        state.slotUtilization = {};
        state.error = "";
      })
      .addCase(fetchSlotUtilizationRecord.fulfilled, (state: any, action: any) => {
        state.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.loading = false;
        state.slotUtilization = action.payload;
      })
      .addCase(fetchSlotUtilizationRecord.rejected, (state: any, action: any) => {
        state.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.loading = false;
        state.error = action.error.message;
      })
      /* getAdvanceDayCalculate */
      .addCase(getAdvanceDayCalculate.pending, (state: any) => {
        state.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.loading = true;
        state.advanceDaySize = 0;
        state.error = "";
      })
      .addCase(getAdvanceDayCalculate.fulfilled, (state: any, action: any) => {
        state.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.loading = false;
        state.advanceDaySize = action.payload;
      })
      .addCase(getAdvanceDayCalculate.rejected, (state: any, action: any) => {
        state.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default DashboardPageSlice.reducer;
