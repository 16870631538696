import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import LinkButton from "../../../common/LinkButton/LinkButton";
import ShowFirstSlotAndServiceCutOffTime from "../../common/ShowFirstSlotAndServiceCuttOff/ShowFirstSlotAndServiceCuttOff";

import { is_show_first_slot_and_service_cut_off_time_visible } from "../Utils/ExpressAccessUtils";

interface RenderConfigureSlotsAccordionInterface {
  classes: any;
  userInfo: any;
  configTab: any;
  configTabIndex: any;
  isAddOtherDaysButtonDisabled: any;
  addOtherDays: any;
  isExpressTypeOnDemand: any;
  renderOnDemandSlotsGeneratorForm: any;
}

const RenderConfigureSlotsAccordion = (props: RenderConfigureSlotsAccordionInterface) => {
  const { classes, userInfo, configTab, configTabIndex, isAddOtherDaysButtonDisabled, addOtherDays, isExpressTypeOnDemand, renderOnDemandSlotsGeneratorForm } = props;

  return (
    <Accordion className={classes.accordionStyle}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryStyle}>
        <Grid container alignItems="center">
          <Grid>
            <Typography className={classes.accordionTitle}>Slots</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetailsStyle}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={9}>
            {""}
          </Grid>
          {!configTab.zoneId && (
            <Grid item xs={3} className={classes.addOtherDays}>
              <LinkButton buttonLabel={"Add Other Days"} disabled={isAddOtherDaysButtonDisabled(configTabIndex)} onClick={(e: any) => addOtherDays(configTabIndex, e)} />
            </Grid>
          )}
        </Grid>
        <>
          {configTab.slotsGeneratorForms.map((slotGeneratorForm: any, generatorFormIndex: any) => (
            <div key={`${generatorFormIndex}-generatorFormIndex-slotsGeneratorForms-configTab`}>
              {isExpressTypeOnDemand ? renderOnDemandSlotsGeneratorForm(configTab, configTabIndex, generatorFormIndex) : <></>}
            </div>
          ))}
        </>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(RenderConfigureSlotsAccordion);
