import { createSlice } from "@reduxjs/toolkit";
import { CURRENT_COUNTRY } from "../constants";

export const appConfigSlice = createSlice({
  name: "appConfig",
  initialState: {
    currentCountry: localStorage.getItem(CURRENT_COUNTRY) ? JSON.parse(localStorage.getItem(CURRENT_COUNTRY)) : {},
    // : { countryId: 1, id: "UAE", name: "UAE", isoCode: "AE" },
  },
  reducers: {
    changeCountry: (state, action) => {
      state.currentCountry = action.payload;
    },
  },
});

export const { changeCountry } = appConfigSlice.actions;

export default appConfigSlice.reducer;

export const selectCurrentCountryDetails = (state) => state.appConfig.currentCountry;
