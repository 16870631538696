import Fetch from "./Fetch";

import { API_HOST_URL } from "../config/constants";
import { handleError, handleResponse } from "./ApiUtils";

export const fetchPosZoneServiceTypeList = (data) => {
  return Fetch.get(`${API_HOST_URL}/delivery-bag/pos-zone-service-mapping`, data).then(handleResponse).catch(handleError);
};

export const fetchSlotUtilizationRecord = (data) => {
  return Fetch.create(`${API_HOST_URL}/slots/utilization-dashboard/${data.currentCountryId}`, data.payload).then(handleResponse).catch(handleError);
};

export const getAdvanceDayCalculate = (countryId) => {
  return Fetch.get(`${API_HOST_URL}/country/${countryId}`).then(handleResponse).catch(handleError);
};
