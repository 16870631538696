import React from "react";

import { FormControl, InputLabel } from "@material-ui/core";
import { useStyles } from "./DateRangePickerComponentStyles";

import CustomDateRangePicker from "../CustomDateRangePicker/CustomDateRangePicker";

interface DateRangePickerComponentInterface {
  isTextField: boolean;
  datePickerName: any;
  placeHolderText?: any;
  datePickerValue: any;
  handleDateChangeEvent: any;
  handleCancel: any;
  initialSettings: any;
  childClasses: any;
  keyId?: any;
  label?: any;
  fullWidth: any;
}

const DateRangePickerComponent = (props: DateRangePickerComponentInterface) => {
  const { isTextField, datePickerName, placeHolderText, datePickerValue, handleDateChangeEvent, handleCancel, initialSettings, childClasses, keyId, label, fullWidth } = props;

  const classes = useStyles();

  const getCustomDateRangePicker = () => {
    return (
      <CustomDateRangePicker
        childClasses={childClasses}
        isTextField={isTextField}
        datePickerName={datePickerName}
        datePickerValue={datePickerValue}
        placeHolderText={placeHolderText}
        handleDateChangeEvent={handleDateChangeEvent}
        handleCancel={handleCancel}
        initialSettings={initialSettings}
      />
    );
  };
  return (
    <>
      {isTextField ? (
        <FormControl className={`${classes.formControl} ${label ? "hasLabel" : ""}`} variant="outlined" fullWidth={fullWidth}>
          {label && (
            <InputLabel htmlFor={keyId} shrink>
              {label}
            </InputLabel>
          )}
          {getCustomDateRangePicker()}
        </FormControl>
      ) : (
        getCustomDateRangePicker()
      )}
    </>
  );
};

export default React.memo(DateRangePickerComponent);
