import { makeStyles } from "@material-ui/core";

import { COLORS } from "../../../../config/theme/theme";

const modalStyles = makeStyles(() => ({
  cardStyle: {
    margin: "80px auto 0px auto",
    padding: "24px",
    height: "90vh",
    width: "70vw",
    border: "none",
    borderRadius: "12px",
    overflow: "auto",
  },
  cardContentStyle: {
    margin: 0,
    padding: 0,
    "&:last-child": {
      margin: 0,
      padding: 0,
    },
  },
  headerGridItemStyle: {
    margin: 0,
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
  },
  headerTitleGridItemStyle: {
    margin: 0,
    padding: 0,
    display: "flex",
    justifyContent: "flex-start",
  },
  headerSpanStyle: {
    fontSize: "24px",
    fontWeight: 600,
    color: COLORS.BLACK,
  },
  headerDaySpanStyle: {
    margin: "0pc 0px 0pc 8px",
    padding: 0,
    fontSize: "24px",
    fontWeight: 600,
    color: COLORS.DOS_BLUE_DARK,
  },
  closeButtonIconStyle: {
    margin: "auto 0px",
    padding: 0,
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
  gridItemFullWidthStyle: {
    margin: "24px 0px 0px 0px",
    padding: 0,
  },
  gridInnerItemFullWidthStyle: {
    margin: "16px 0px 0px 0px",
    padding: 0,
  },
  detailsTableHeaderGridItem: {
    margin: "auto 0px",
    padding: "8px",
    borderRadius: "0px",
    background: COLORS.DOS_BLUE_LIGHT,
    "&:first-child": {
      borderRadius: "8px 0px 0px 0px",
    },
    "&:last-child": {
      borderRadius: "0px 8px 0px 0px",
    },
  },
  detailsTableHeaderSpanStyle: {
    fontSize: "14px",
    fontWeight: 600,
    color: COLORS.BLACK,
  },
  detailsTableBodyGridItem: {
    margin: "auto 0px",
    padding: "8px",
    background: COLORS.DOS_BLUE_SUPER_LIGHT,
  },
  detailsTableValueSpanStyle: {
    fontSize: "14px",
    fontWeight: 500,
    color: COLORS.BOULDER,
  },
  selectDaysSpanStyle: {
    fontSize: "20px",
    fontWeight: 600,
    color: COLORS.BLACK,
  },
  selectDayGridItemSelectedStyle: {
    display: "flex",
    justifyContent: "flex-start",
    margin: "16px 8px 0px 0px",
    padding: 0,
    borderRadius: "12px",
    background: COLORS.DOS_BLUE_LIGHT,
    cursor: "pointer",
  },
  selectDayGridItemStyle: {
    display: "flex",
    justifyContent: "flex-start",
    margin: "16px 8px 0px 0px",
    padding: 0,
    borderRadius: "12px",
    background: COLORS.WILDSAND,
    cursor: "pointer",
  },
  selectDayIconStyle: {
    height: "20px",
    width: "20px",
    margin: "auto 8px",
    padding: 0,
    color: COLORS.DOS_BLUE_DARK,
  },
  selectDayMainButtonSelectedStyle: {
    margin: 0,
    padding: "8px 4px 8px 4px",
    borderRadius: "12px",
    background: COLORS.DOS_BLUE_LIGHT,
    border: "none",
    "&:hover": {
      background: COLORS.DOS_BLUE_LIGHT,
    },
  },
  selectAllDayMainButtonStyle: {
    margin: 0,
    padding: "16px",
    width: "100%",
    height: "45px",
    borderRadius: "12px",
    background: "none",
    border: "none",
    "&:hover": {
      background: "none",
    },
  },
  selectDayMainButtonStyle: {
    margin: 0,
    padding: "8px 16px 8px 8px",
    width: "100%",
    borderRadius: "12px",
    background: "none",
    border: "none",
    "&:hover": {
      background: "none",
    },
  },
  selectDayMainButtonSpanStyle: {
    fontSize: "14px",
    fontWeight: 700,
    color: COLORS.SILVERCHALICE,
  },
  selectDayMainButtonSpanSelectedStyle: {
    fontSize: "14px",
    fontWeight: 700,
    color: COLORS.DOS_BLUE_DARK,
  },
  specialFooterNoteSpanStyle: {
    fontSize: "14px",
    fontWeight: 400,
    color: COLORS.BLACK,
  },
  buttonStyle: {
    margin: 0,
    padding: 0,
    minWidth: "212px",
    "&:last-child": {
      margin: "0px 0px 0px 16px",
      padding: 0,
    },
  },
}));

export default modalStyles;
