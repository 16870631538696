import moment from "moment";
import { isEmptyOrNull } from "./DataUtils";
import { USER_TYPES } from "../config/constants";

export const check_if_slot_obj_contains_sub_set = (generatedSlots: any) => {
  let responseValue = {} as any;
  if (generatedSlots !== undefined && Array.isArray(generatedSlots) && generatedSlots.length > 0) {
    generatedSlots.forEach((slot: any, slot_index: any) => {
      if (slot !== undefined) {
        if (slot.slotAction !== undefined && slot.slotAction !== "" && slot.slotAction !== "DELETE") {
          const { startTime, endTime } = slot;
          //
          if (isEmptyOrNull(startTime)) {
            responseValue[`${slot_index}-slot_index-startTime`] = `Please provide slot start time ${slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."}`;
          } else if (!moment(startTime).isValid()) {
            responseValue[`${slot_index}-slot_index-startTime`] = `Please provide valid slot start time ${slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."}`;
          } else {
            if (isEmptyOrNull(endTime)) {
              responseValue[`${slot_index}-slot_index-endTime`] = `Please provide slot end time ${slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."}`;
            } else if (!moment(endTime).isValid()) {
              responseValue[`${slot_index}-slot_index-endTime`] = `Please provide valid slot end time ${slot.day !== undefined && slot.day !== "" ? `for - ${slot.day}.` : "."}`;
            } else {
              /** */
              generatedSlots.forEach((newSlot: any, newSlotIndex: any) => {
                if (newSlotIndex !== slot_index) {
                  if (newSlot.slotAction !== undefined && newSlot.slotAction !== "" && newSlot.slotAction !== "DELETE") {
                    //
                    let startTimeFlag = moment(newSlot.startTime).isBetween(moment(startTime), moment(endTime));
                    if (!startTimeFlag) {
                      startTimeFlag = moment(newSlot.startTime).isSame(moment(startTime));
                    }
                    //
                    let endTimeFlag = moment(newSlot.endTime).isBetween(moment(startTime), moment(endTime));
                    if (!endTimeFlag) {
                      endTimeFlag = moment(newSlot.endTime).isSame(moment(endTime));
                    }
                    if (startTimeFlag && endTimeFlag) {
                      let error_message = "One or more slots is a subset of another slot. Please change slot timings.";
                      responseValue[`${slot_index}-slot_index-sub-set`] = error_message;
                    }
                  }
                }
              });
            }
          }
        }
      }
    });
  }
  return responseValue;
};

export const is_user_maf_admin = (userInfo: any) => {
  if (userInfo !== undefined && userInfo.userProfile !== undefined && userInfo.userProfile.role !== undefined) {
    switch (userInfo.userProfile.role) {
      case USER_TYPES.MAF_ADMIN:
        return true;
    }
  }
  return false;
};

export const is_user_maf_admin_or_super_admin = (userInfo: any) => {
  if (userInfo !== undefined && userInfo.userProfile !== undefined && userInfo.userProfile.role !== undefined) {
    switch (userInfo.userProfile.role) {
      case USER_TYPES.MAF_ADMIN:
      case USER_TYPES.SUPER_ADMIN:
        return true;
    }
  }
  return false;
};

export const is_user_maf_admin_or_super_admin_or_admin = (userInfo: any) => {
  if (userInfo !== undefined && userInfo.userProfile !== undefined && userInfo.userProfile.role !== undefined) {
    switch (userInfo.userProfile.role) {
      case USER_TYPES.MAF_ADMIN:
      case USER_TYPES.SUPER_ADMIN:
      case USER_TYPES.ADMIN:
        return true;
    }
  }
  return false;
};

export const is_user_admin = (userInfo: any) => {
  if (userInfo !== undefined && userInfo.userProfile !== undefined && userInfo.userProfile.role !== undefined) {
    switch (userInfo.userProfile.role) {
      case USER_TYPES.ADMIN:
        return true;
    }
  }
  return false;
};

export const is_user_admin_read_only_approver = (userInfo: any) => {
  if (userInfo !== undefined && userInfo.userProfile !== undefined && userInfo.userProfile.role !== undefined) {
    switch (userInfo.userProfile.role) {
      case USER_TYPES.ADMIN:
      case USER_TYPES.READONLY_USER:
      case USER_TYPES.APPROVER:
        return true;
    }
  }
  return false;
};

export const findTerm = (href: any, term: any) => {
  if (href.includes(term)) {
    return href;
  }
};

export const getCurrentUrl = () => {
  const currentLocation = window.location.pathname;
  if (currentLocation !== undefined && currentLocation !== "") {
    return currentLocation;
  }
  return "";
};

export const getDateFromPicker = (dateData: any, format: any) => {
  return moment(dateData).format(format);
};
