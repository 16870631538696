import React from "react";

import { Grid } from "@material-ui/core";

import TimePickerWithLabel from "../../common/TimePickerWithLabel/TimePickerWithLabel";
import TextBoxWithLabel from "../../common/TextBoxWithLabel/TextBoxWithLabel";

import { ReactComponent as DeleteIcon } from "../../assets/svg/DeleteIcon.svg";

interface DlvFeeTimeConfigurationInterface {
  classes: any;
  configIndex: any;
  dayObjectIndex: any;
  itemconfig: any;
  itemconfigIndex: any;
  handleConfigTimeChange: any;
  handleThresholdChange: any;
  removeTimeSlot: any;
}

const DlvFeeTimeConfiguration = (props: DlvFeeTimeConfigurationInterface) => {
  const { classes, configIndex, dayObjectIndex, itemconfig, itemconfigIndex, handleConfigTimeChange, handleThresholdChange, removeTimeSlot } = props;
  return (
    <Grid container spacing={1} className={classes.timeConfigMainContainerDivStyle}>
      <Grid item xs={2} className={classes.timeConfigDivStyle}>
        <TimePickerWithLabel
          label={"Start time"}
          value={itemconfig.startTime}
          handleChange={(date: any) => handleConfigTimeChange(date, "startTime", itemconfigIndex, dayObjectIndex, configIndex)}
          className={""}
          disabled={false}
          timerProps={undefined}
        />
      </Grid>
      <Grid item xs={2} className={classes.timeConfigDivStyle}>
        <TimePickerWithLabel
          label={"End time"}
          className={""}
          value={itemconfig.endTime}
          handleChange={(date: any) => handleConfigTimeChange(date, "endTime", itemconfigIndex, dayObjectIndex, configIndex)}
          timerProps={undefined}
        />
      </Grid>
      <Grid item xs={2} className={classes.timeConfigDivStyle}>
        <TextBoxWithLabel
          label={"Threshold Amount"}
          name="thresholdAmount"
          value={itemconfig.thresholdAmount}
          placeholderText="Threshold Amount"
          handleChange={(e: any) => handleThresholdChange(e, itemconfigIndex, dayObjectIndex, configIndex)}
          type="number"
          className={classes.rightPaddingStyle}
          disabled={false}
        ></TextBoxWithLabel>
      </Grid>
      <Grid item xs={2} className={classes.timeConfigDivStyle}>
        <TextBoxWithLabel
          label={"Threshold Below Amount"}
          name="thresholdBelowAmount"
          value={itemconfig.thresholdBelowAmount}
          placeholderText="Threshold Below Amount"
          handleChange={(e: any) => handleThresholdChange(e, itemconfigIndex, dayObjectIndex, configIndex)}
          type="number"
          className={classes.rightPaddingStyle}
          disabled={false}
        ></TextBoxWithLabel>
      </Grid>
      <Grid item xs={2} className={classes.timeConfigDivStyle}>
        <TextBoxWithLabel
          label={"Threshold Above Amount"}
          name="thresholdAboveAmount"
          value={itemconfig.thresholdAboveAmount}
          placeholderText="Threshold Above Amount"
          handleChange={(e: any) => handleThresholdChange(e, itemconfigIndex, dayObjectIndex, configIndex)}
          type="number"
          className={classes.rightPaddingStyle}
          disabled={false}
        ></TextBoxWithLabel>
      </Grid>
      <Grid item xs={1} className={classes.iconDivStyle}>
        <DeleteIcon onClick={() => removeTimeSlot(itemconfigIndex, dayObjectIndex, configIndex)} className={classes.iconStyle} />
      </Grid>
      <small style={{ color: "red" }}>{itemconfig.errorMessage}</small>
    </Grid>
  );
};

export default React.memo(DlvFeeTimeConfiguration);
