import React, { useState } from "react";

import { userStyles } from "./Styles/AddUpdateUserStyle";

import { DIALOG_TITLE_NEW_USER, DIALOG_TITLE_UPDATE_USER } from "./Utils/UserManagementUtils";
import UserBasicDetails from "./Utils/UserBasicDetails";
import UserProfileDetails from "./Utils/UserProfileDetails";

import PrimaryButton from "../common/PrimaryButton/PrimaryButton";
import CustomDialog from "../common/Dialog/index";
import Loader from "../common/Loader/Loader";

import { AddUpdateUserErrorState, AddUpdateUserInitialState } from "./AddUpdateUserInterface";
import { AddUpdateSubmitUserAccess } from "./Utils/UserManagementAccessUtils";
interface AddUpdateObject {
  open: boolean;
  handleClose: () => void;
  userObj: typeof AddUpdateUserInitialState;
  errors: typeof AddUpdateUserErrorState;
  handleUserObjChange: any;
  handleCountryChange: any;
  handleServiceTypeChange: any;
  handleSubmit: any;
  handleFeatureChange: any;
  isUpdatingUser: boolean;
  rolesList: Array<any>;
  countriesList: Array<any>;
  serviceTypeList: Array<any>;
  statusList: Array<any>;
  additionalServicesList: Array<any>;
  userInfo: any;
}

const AddUpdateUserPage = (props: AddUpdateObject) => {
  const addUpdateUserStyles = userStyles();
  const {
    open,
    handleClose,
    userObj,
    errors,
    handleUserObjChange,
    handleCountryChange,
    handleServiceTypeChange,
    handleSubmit,
    handleFeatureChange,
    isUpdatingUser,
    rolesList,
    countriesList,
    serviceTypeList,
    statusList,
    additionalServicesList,
    userInfo,
  } = props;

  const [openState, setOpenState] = useState({
    isLoadingData: false,
    openBasicDetials: true,
    openProfileDetials: true,
  });

  const publishDialogPaperProps = {
    classes: {
      root: addUpdateUserStyles.dialogRoot,
    },
    square: true,
  };

  const toggleBasicDetials = () => {
    setOpenState({ ...openState, openBasicDetials: !openState.openBasicDetials });
  };

  const toggleProfileDetials = () => {
    setOpenState({ ...openState, openProfileDetials: !openState.openProfileDetials });
  };

  const getAddUpdateDialogTitleContent = () => {
    return (
      <>
        <span className={addUpdateUserStyles.dialogTitleStyle}>{isUpdatingUser ? DIALOG_TITLE_UPDATE_USER : DIALOG_TITLE_NEW_USER}</span>
      </>
    );
  };

  const getAddUpdateDialogDetailsContent = () => {
    return (
      <>
        <UserBasicDetails openDetails={openState.openBasicDetials} handleViewClose={toggleBasicDetials} userObj={userObj} handleChange={handleUserObjChange} errors={errors} />
        {rolesList.length > 0 && statusList.length > 0 && countriesList.length > 0 && serviceTypeList.length > 0 && additionalServicesList.length > 0 ? (
          <UserProfileDetails
            openDetails={openState.openProfileDetials}
            handleViewClose={toggleProfileDetials}
            handleChange={handleUserObjChange}
            userObj={userObj}
            rolesList={rolesList}
            statusList={statusList}
            countriesList={countriesList}
            serviceTypeList={serviceTypeList}
            handleCountryChange={handleCountryChange}
            handleServiceTypeChange={handleServiceTypeChange}
            additionalServicesList={additionalServicesList}
            errors={errors}
            handleFeatureChange={handleFeatureChange}
          />
        ) : (
          <Loader />
        )}
      </>
    );
  };

  const getAddUpdateDialogActions = () => {
    return (
      <>
        <PrimaryButton buttonLabel="CANCEL" buttonVariant="outlined" className={addUpdateUserStyles.buttonStyles} onClick={handleClose} />
        <AddUpdateSubmitUserAccess userInfo={userInfo} isUpdatingUser={isUpdatingUser} addUpdateUserStyles={addUpdateUserStyles} handleSubmit={handleSubmit} userObj={userObj} />
      </>
    );
  };

  return (
    <CustomDialog
      open={open}
      PaperProps={publishDialogPaperProps}
      isLoadingData={openState.isLoadingData}
      title={getAddUpdateDialogTitleContent()}
      content={getAddUpdateDialogDetailsContent()}
      actions={getAddUpdateDialogActions()}
      handleClose={handleClose}
    />
  );
};

export default React.memo(AddUpdateUserPage);
