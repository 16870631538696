export function isEmptyOrNull(value: any) {
  return value === undefined || value === null || (typeof value === "string" ? value.trim() : value) === "";
}

export function isEmptyArray(array: Array<any> | undefined | null) {
  return array === undefined || array === null || array.length === 0;
}

export function isObjectEmptyOrNull(obj: any) {
  return obj === undefined || obj === null || Object.keys(obj).length === 0;
}

export const capitalizeFirstLetter = (nameString: any) => {
  if (nameString !== undefined && nameString !== "") {
    return nameString.charAt(0).toUpperCase() + nameString.slice(1).toLowerCase();
  }
  return "";
};

export const isArrayValid = (arr: any) => arr !== undefined && Array.isArray(arr) && arr.length > 0;

export const objectContainsKey = (object: any, keyName: string) => object && object.hasOwnProperty(keyName);

export const isUndefined = (target: any): boolean => target === undefined;

export const isAString = (candidate: any) => typeof candidate === "string" || candidate instanceof String;

export const isNull = (target: any): boolean => target === null || target === "";

export const isNonEmptyArray = (target: any[]): boolean => !!target && target.length > 0;

export const countObjectProperties = (target: object) => Object.keys(target).length;

export const isNonEmptyObject = (target: any): boolean => target && countObjectProperties(target) > 0;

export function isEmptyOrZero(value: any) {
  return value === undefined || value === null || (typeof value === "string" ? value.trim() : value) === "" || value === 0 || value.toString() === "0";
}
