import { Button, Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import SwitchButton from "../../../components/common/SwitchButton/SwitchButton";
import { APP_ROUTES, USER_TYPES } from "../../../config/constants";
import EditIcon from "../../assets/svg/Pencil.svg";

interface CreatePosButtonProps {
  classes: any;
  userInfo: any;
}

export const CreatePosButton = (props: CreatePosButtonProps) => {
  const { classes, userInfo } = props;
  return userInfo?.userProfile?.role !== USER_TYPES.READONLY_USER && userInfo?.userProfile?.role !== USER_TYPES.ADMIN && userInfo?.userProfile?.role !== USER_TYPES.APPROVER ? (
    <Grid item xs={12}>
      <Link to={`${APP_ROUTES.CREATE_POS}${APP_ROUTES.BASIC_DETAILS}`} className={classes.linkstyle}>
        <Button variant="contained" color="primary" className={classes.buttonStyles}>
          CREATE NEW POS
        </Button>
      </Link>
    </Grid>
  ) : (
    <Button variant="contained" color="primary" className={classes.buttonStyles} disabled={true}>
      CREATE NEW POS
    </Button>
  );
};

interface BasicDetailsServiceTypeProps {
  classes: any;
  userInfo: any;
  serviceTypes: any;
  serviceType: any;
  handleServiceType: any;
  isServiceTypeDisabled: any;
  errors: any;
  posType: any;
  handleServiceTypeCheckBox: any;
}

export const BasicDetailsServiceType = (props: BasicDetailsServiceTypeProps) => {
  const { classes, userInfo, serviceTypes, serviceType, handleServiceType, isServiceTypeDisabled, errors, posType, handleServiceTypeCheckBox } = props;

  let enableServiceType = serviceTypes.filter((item: any) => userInfo?.userProfile.serviceTypeId.includes(item.id));
  if (posType === "FBC") {
    enableServiceType = enableServiceType.filter((item: any) => item.name === "FBC");
  } else {
    enableServiceType = enableServiceType.filter((item: any) => item.name !== "FBC");
  }
  return (
    userInfo?.userProfile?.role !== USER_TYPES.READONLY_USER && (
      <div className={classes.serviceTypeContainer}>
        <Typography className={classes.smallTitle}>Service Type</Typography>
        <div className={classes.serviceStyles}>
          {enableServiceType && enableServiceType.length > 0 ? (
            enableServiceType.map((type: any) => (
              <FormControlLabel
                className={classes.serviceTypeItem}
                key={type.id}
                control={
                  <Checkbox
                    name={type.name}
                    checked={handleServiceTypeCheckBox(serviceType, type.id)}
                    value={type.id}
                    onChange={handleServiceType}
                    disabled={isServiceTypeDisabled(type.id) || (posType === "FBC" && type.name !== "FBC")}
                  />
                }
                label={<Typography className={classes.checkboxLabel}>{type.label}</Typography>}
              />
            ))
          ) : (
            <div className={classes.error}>{`You don't have access for ${posType} pos.`}</div>
          )}
        </div>
        {errors && errors.serviceType ? <div className={classes.error}>{errors.serviceType}</div> : null}
      </div>
    )
  );
};

interface PosStatusToggleProps {
  classes: any;
  userInfo: any;
  checked: any;
  disabled: any;
  handleServiceActiveInactiveSwitch: any;
}

export const PosStatusToggle = (props: PosStatusToggleProps) => {
  const { classes, userInfo, checked, disabled, handleServiceActiveInactiveSwitch } = props;

  return userInfo?.userProfile?.role !== USER_TYPES.READONLY_USER ? (
    <Grid item xs={5} className={classes.serviceToggle}>
      <SwitchButton
        leftLabel="Inactive"
        rightLabel="Active"
        size="medium"
        checked={Boolean(checked)}
        handleChange={handleServiceActiveInactiveSwitch}
        onClick={(e: any) => e.stopPropagation()}
        disabled={disabled}
      />
    </Grid>
  ) : (
    <></>
  );
};

interface EditPosButtonProps {
  userInfo: any;
  classes: any;
  serviceTypeName: any;
  posNo: any;
}

export const EditPosButton = (props: EditPosButtonProps) => {
  const { userInfo, classes, serviceTypeName, posNo } = props;
  return (
    userInfo?.userProfile?.role !== USER_TYPES.READONLY_USER &&
    userInfo?.userProfile?.role !== USER_TYPES.ADMIN &&
    userInfo?.userProfile?.role !== USER_TYPES.APPROVER && (
      <Grid item xs={2} className={classes.editServiceIcon}>
        <Link className={classes.dFlex} to={`${APP_ROUTES.CREATE_POS}${APP_ROUTES.SERVICE_AND_SLOTS}/${serviceTypeName.toLowerCase()}/${posNo}`}>
          <img src={EditIcon} alt="Edit POS" />
        </Link>
      </Grid>
    )
  );
};

interface ClickAndCollectActiveSwitchProps {
  userInfo: any;
  checked: any;
  handleCollectionPointToggleSwitch: any;
  classes: any;
  disabled: any;
}

export const ClickAndCollectStatuSwitch = (props: ClickAndCollectActiveSwitchProps) => {
  const { userInfo, checked, handleCollectionPointToggleSwitch, classes, disabled } = props;
  return userInfo?.userProfile?.role !== USER_TYPES.READONLY_USER ? (
    <Grid item xs={5} className={classes.serviceToggle}>
      <SwitchButton
        leftLabel="Inactive"
        rightLabel="Active"
        size="medium"
        checked={checked}
        handleChange={handleCollectionPointToggleSwitch}
        onClick={(e: any) => e.stopPropagation()}
        disabled={disabled}
      />
    </Grid>
  ) : (
    <></>
  );
};
