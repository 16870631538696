import { Grid } from "@material-ui/core";
import PrimaryButton from "components/common/PrimaryButton/PrimaryButton";
import { Link } from "react-router-dom";
import { APP_ROUTES, USER_TYPES } from "../../../config/constants";

interface RuleEngineCreateConfigButtonAccessInterface {
  userInfo: any;
  classes: any;
}

export const RuleEngineCurrentConfigButtonAccess = (props: RuleEngineCreateConfigButtonAccessInterface) => {
  const { userInfo, classes } = props;
  return userInfo?.userProfile?.role !== USER_TYPES.READONLY_USER ? (
    <Link to={APP_ROUTES.RULE_ENGINE} style={{ textDecoration: "none" }}>
      <PrimaryButton className={classes.backButtonStyles} buttonLabel={`VIEW CURRENT CONFIGURATIONS`} buttonVariant="outlined" />
    </Link>
  ) : (
    <></>
  );
};

export const RuleEngineCreateConfigButtonAccess = (props: RuleEngineCreateConfigButtonAccessInterface) => {
  const { userInfo, classes } = props;
  return userInfo?.userProfile?.role !== USER_TYPES.READONLY_USER && userInfo?.userProfile?.role !== USER_TYPES.ADMIN && userInfo?.userProfile?.role !== USER_TYPES.APPROVER ? (
    <Link to={APP_ROUTES.RULE_ENGINE_CURRENT_CONFIGURATION} style={{ textDecoration: "none" }}>
      <PrimaryButton className={classes.buttonStyles} buttonLabel={`CREATE NEW CONFIGURATION`} buttonVariant="outlined" />
    </Link>
  ) : (
    <></>
  );
};
