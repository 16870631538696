import { DAYS_DROPDOWN_OPTIONS, FOOD_SUB_TYPES } from "../../../config/constants";

export const foodSplitRegex = ", ";
export const ALL_DAYS_VALUES = DAYS_DROPDOWN_OPTIONS.reduce((acc: any, day: any) => [...acc, day.id], []);
export const PRE_SELECTED_FOOD_SUB_TYPES = FOOD_SUB_TYPES.reduce((acc: any, subType: any) => [...acc, subType.value], []).join(foodSplitRegex);

export interface DEFAULT_TAB_BUFFER_CAPACITY_CONFIGURATIONS_INTERFACE {
  bufferCapacityConfig: BufferCapacityConfig;
}
export const DEFAULT_TAB_BUFFER_CAPACITY_CONFIGURATIONS = {
  bufferCapacityConfig: { active: false, id: "", capacityUtilized: "", reducedBufferTime: "", maxCapacity: "" },
};

export const STANDARD_SERVICE_CONSTANT = {
  DYNAMIC_ROUTING: "Dynamic Routing",
  DYNAMIC_ROUTING_SUB_HEADING: "Please note: Mapped POS/Zone will serve to route demand from current POS/Zone in order of priority.",
  CHANGE_EXISTING_CONFIGURATION: "Change existing configuration",
  USE_EXISTING_CONFIGURATION: "Use existing configuration",
  USE_THIS_VALUE_IN_CALCULATION: "Use this value in calculation",
  ADD_SECONDARY_POS_ZONE_RECORD: "Add Associated POS Zone",
  POS_ZONE_MAPPING: "POS Zone Mapping",
  CANCEL: "Cancel",
  ADD: "Add",
  ASSIGNED_POS: "Selected Associated POS",
  TOTAL_POS: "Total POS",
  pageSize: 1000,
  pageNumber: 0,
  STANDARD: "STANDARD",
  POS_ZONE_SEARCH_TEXT: "posZoneSearchText",
  ASSIGNED_POS_SEARCH_TEXT: "assignedPosSearchText",
  SECONDARY_POS_NOT_FOUND: "No Associated POS record found",
  RANK: "Rank",
  POS_NO: "POS No",
  POS_NAME: "POS Name",
  ZONE: "Zone",
  CHANGE_RANK: "Change Rank",
  DYNAMIC_ROUTING_SUCCESS: "Dynamic Routing updated successfully",
  DYNAMIC_ROUTING_ERROR: "Error while saving Dynamic Routing",
  NO_DYNAMIC_ROUTING_PRESENT: "No Mapped POS/Zone found",
  REMOVE_ASSOCIATE_POS_ZONE: "Remove POS Zone",
  CONFIRM: "Confirm",
  THIS_ACTION_WILL: "This action will",
  REMOVE: "remove",
  FROM_RECEIVING_ROUTED_DEMAND: "from receiving routed demand.",
  POP_UP_RETURN_STRING: "POS Zone",
  CHANGE_PRIORITY: "Change POS Zone Priority",
  DECREASE_PRIORITY: "Decrease POS Zone Priority",
  INCREASE_PRIORITY: "Increase POS Zone Priority",
  WHEN_RECEIVING_ROUTED_DEMAND: "when receiving routed demand",
  CHANGE_PRIORITY_OF: "decrease priority of {POS/Zone}",
  DECREASE_PRIORITY_OF: "decrease priority of",
  INCREASE_PRIORITY_OF: "increase priority of",
};

export interface GeneratedSlotsInterface {
  id?: number;
  posId: number;
  day: string;
  startTime: string;
  endTime: string;
  slotCapacity: number | string;
  bufferTime: number | string;
  zoneId?: number | string;
  status: string;
  serviceTypeId: number | string;
  createdAt: number | string;
  time: string;
  bufferTimeHours: number;
  bufferTimeMinutes: number;
  orderCutOff: string;
  slotAction: string;
  canBeUpdated: boolean;
}

export interface SlotsGeneratorFormsInterface {
  isEdit?: boolean;
  days?: string[];
  slotDurationHours: string | number;
  slotDurationMinutes: string | number;
  bufferTimeHours: string | number;
  bufferTimeMinutes: string | number;
  serviceStartTime: null | string | null;
  serviceEndTime: null | string | null;
  defaultSlotCapacity: string | number;
  generatedSlots: GeneratedSlotsInterface[];
  quotas?: string[] | number[];
}

export interface DEFAULT_CONFIG_TAB_CONFIGURATIONS_INTERFACE {
  zoneId?: number | string;
  zoneName: string;
  //
  foodTypes: string;
  foodSubType: string;
  //
  zoneShowFirstSlotAt: string | null;
  showFirstSlotAtOption: boolean;
  showFirstSlotAt: string | null;
  //
  zoneServiceCutOffTime: string | null;
  showServiceCutOffTimeOption: boolean;
  serviceCutOffTime: string | null;
  //
  slotOptimizationEnabled: boolean;
  zoneServiceTypeMappingId: null | number;
  zoneCapacityThreshold: number;
  zoneTimeThreshold: number;
  timeThreshold: string;
  //
  selectedAreas: any[];
  zoneStatus: number | string;
  capacityThreshold: number | string;
  status: number | string;
  //
  bufferCapacityConfig: BufferCapacityConfig;
  //
  slotsGeneratorForms: SlotsGeneratorFormsInterface[];
  //
  isDynamicRoutingConfigChanges: boolean;
  dynamicRoutingConfig: DYNAMIC_ROUTING_CONFIG_OBJ_INTERFACE;
}

export interface BufferCapacityConfig {
  active: boolean;
  id?: number | string;
  capacityUtilized: number | string;
  reducedBufferTime: number | string;
  maxCapacity: number | string;
}

export const DEFAULT_SLOTS_GENERATOR_FORMS_VALUES: SlotsGeneratorFormsInterface = {
  days: [],
  slotDurationHours: "",
  slotDurationMinutes: 0,
  bufferTimeHours: "",
  bufferTimeMinutes: 0,
  serviceStartTime: null,
  serviceEndTime: null,
  defaultSlotCapacity: "",
  generatedSlots: [],
  quotas: [],
};

export interface SecondaryStoresInterface {
  posNo: number | string;
  posName: string;
  zone: number | string;
  zoneId: number | string;
  rank: number | string;
  latitude: number | string;
  longitude: number | string;
  status: string;
}

export interface DYNAMIC_ROUTING_CONFIG_OBJ_INTERFACE {
  posNo: string;
  zone: string;
  serviceType: string;
  secondaryStores: SecondaryStoresInterface[];
}

export const DYNAMIC_ROUTING_CONFIG_OBJ: DYNAMIC_ROUTING_CONFIG_OBJ_INTERFACE = {
  posNo: "",
  zone: "",
  serviceType: STANDARD_SERVICE_CONSTANT.STANDARD,
  secondaryStores: [],
};
export const DEFAULT_CONFIG_TAB_CONFIGURATIONS: DEFAULT_CONFIG_TAB_CONFIGURATIONS_INTERFACE = {
  zoneId: "",
  zoneName: "",
  //
  foodTypes: "",
  foodSubType: PRE_SELECTED_FOOD_SUB_TYPES,
  //
  zoneShowFirstSlotAt: null,
  showFirstSlotAtOption: false,
  showFirstSlotAt: null,
  //
  zoneServiceCutOffTime: null,
  showServiceCutOffTimeOption: false,
  serviceCutOffTime: null,
  //
  slotOptimizationEnabled: false,
  zoneServiceTypeMappingId: null,
  zoneCapacityThreshold: 0,
  zoneTimeThreshold: 0,
  timeThreshold: "",
  //
  zoneStatus: "",
  selectedAreas: [],
  capacityThreshold: "",
  status: "",
  //
  bufferCapacityConfig: { active: false, capacityUtilized: "", reducedBufferTime: "", maxCapacity: "" },
  //
  slotsGeneratorForms: [{ ...DEFAULT_SLOTS_GENERATOR_FORMS_VALUES, days: ALL_DAYS_VALUES }],
  // Dynamic Routing
  isDynamicRoutingConfigChanges: false,
  dynamicRoutingConfig: { ...DYNAMIC_ROUTING_CONFIG_OBJ },
};

export interface PosZoneServiceTypeList {
  zoneId: string | number;
  zoneName: string;
  serviceTypeId: string | number;
  serviceType: string;
  status: string;
  cncReferenceId?: string | number;
}

export interface PosZoneMapListDataInterface {
  posId: string | number;
  posNo: string | number;
  storeName: string;
  zoneServiceTypeList: PosZoneServiceTypeList[];
}

export interface ZoneServiceTypeListInterface {
  posId: string | number;
  posNo: string | number;
  posName: string | number;
  zoneName: string | number;
  zoneId: string | number;
  serviceTypeId: string | number;
  serviceType: string | number;
  isChecked: boolean;
  cncReferenceId?: string | number | undefined;
  status: string | number;
}

export interface PosInterface {
  id?: number; // posNo
  posId: number;
  posNo: number;
  posName: string; // storeName
  zoneServicetypeList: ZoneServiceTypeListInterface[];
  serviceTypeRecord: number;
  zoneTypeListRecord: number;
  isOpened: boolean;
  isChecked: boolean;
  serviceTypeId: string;
}
