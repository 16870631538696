import { getDateFromPicker } from "../../../utils/DateUtils";

export interface SlotDataRecordStateInterface {
  totalRecord: Array<any>;
  posListRecord: Array<any>;
}

export const SlotDataRecordState: SlotDataRecordStateInterface = {
  totalRecord: [],
  posListRecord: [],
};
export interface DashboardPageInitialStateInterface {
  loading: boolean;
  posList: Array<any>;
  zoneList: Array<any>;
  slotDate: any;
  slotDateLabel: string;
  posNos: Array<any>;
  zoneNames: Array<any>;
  slotList: Array<any>;
  recordData: SlotDataRecordStateInterface;
  totalSlotCapacity: number;
  totalUsedCapacity: number;
  totoalAvailableCapacity: number;
  totalUsedCapacityPercentage: number;
}

export const DashboardPageInitialState: DashboardPageInitialStateInterface = {
  loading: false,
  posList: [] as any,
  zoneList: [] as any,
  slotDate: getDateFromPicker(new Date()),
  slotDateLabel: getDateFromPicker(new Date()),
  posNos: [] as any,
  zoneNames: [] as any,
  slotList: [] as any,
  recordData: SlotDataRecordState,
  totalSlotCapacity: 0,
  totalUsedCapacity: 0,
  totoalAvailableCapacity: 0,
  totalUsedCapacityPercentage: 0,
};

export interface DashboardPageSliceInterface {
  loading: boolean;
  fetchStatus: string;
  error: string | object;
  posZoneRecord: Array<any>;
  slotUtilization: object | any;
  advanceDaySize: number;
}
