import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { MINIMUM_ORDER_VALUE_THRESHOLD } from "../../../Utils/DeliveryFeeConstants";

import TextBoxWithLabel from "components/common/TextBoxWithLabel/TextBoxWithLabel";
import SwitchButton from "components/common/SwitchButton/SwitchButton";
import { ChangeConfigOtion } from "../../utils/DeliveryFeeConfigViewUtils";

interface OrderLevelMOVThresholdConfigurationInterface {
  classes: any;
  openOrderLevelMovThreshold: any;
  _handle_accordion_open_state: any;
  mainState: any;
  handleOrderLevelMovThresholdInputChange: any;
  _handle_order_level_mov_threshold_calculation_toggle_change: any;
  use_existing__or_config_change_handler: any;
}

const OrderLevelMOVThresholdConfiguration = (props: OrderLevelMOVThresholdConfigurationInterface) => {
  const {
    classes,
    openOrderLevelMovThreshold,
    _handle_accordion_open_state,
    mainState,
    handleOrderLevelMovThresholdInputChange,
    _handle_order_level_mov_threshold_calculation_toggle_change,
    use_existing__or_config_change_handler,
  } = props;

  return (
    <Accordion expanded={openOrderLevelMovThreshold} className={classes.accordionStyle} onChange={() => _handle_accordion_open_state("openOrderLevelMovThreshold")}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryStyle}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography className={classes.accordionTitle}>{MINIMUM_ORDER_VALUE_THRESHOLD}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetailsStyle}>
        <Grid container style={{ padding: "0px 8px" }}>
          <Grid item xs={4} className={classes.marginAuto}>
            {mainState !== undefined && mainState.orderLevelMovThreshold !== undefined && mainState.orderLevelMovThreshold.active !== undefined && mainState.orderLevelMovThreshold.active == true ? (
              <Grid container className={classes.thresholdInputMainDiv}>
                <Grid item xs={12} className={classes.thresholdInputDiv}>
                  <TextBoxWithLabel
                    label={"Minimum Order Value Threshold"}
                    name="movThreshold"
                    value={mainState.orderLevelMovThreshold.movThreshold}
                    placeholderText="Minimum Order Value Threshold"
                    handleChange={(e: any) => handleOrderLevelMovThresholdInputChange(e)}
                    type="number"
                    className={classes.rightPaddingStyle}
                    disabled={!mainState.orderLevelMovThreshold.enableConfigEdit}
                  ></TextBoxWithLabel>
                </Grid>
              </Grid>
            ) : (
              mainState.orderLevelMovThreshold.id !== undefined &&
              mainState.orderLevelMovThreshold.id !== "" &&
              mainState.orderLevelMovThreshold.id !== 0 && (
                <Grid container>
                  <Grid item xs={12} className={classes.dlvFeeValueDisplayDivStyle}>
                    <Typography className={classes.dlvFeeValueDisplayTitleStyle}>Minimum Order Value Threshold</Typography>
                    <Typography className={classes.dlvFeeValueDisplayDataStyle}>
                      {mainState.orderLevelMovThreshold.movThreshold !== undefined && mainState.orderLevelMovThreshold.movThreshold !== "" ? mainState.orderLevelMovThreshold.movThreshold : "-"}
                    </Typography>
                  </Grid>
                </Grid>
              )
            )}
          </Grid>
          <Grid item xs={4} className={classes.marginAuto}>
            {!mainState.orderLevelMovThreshold.sameAsEarlier && (
              <Grid container justifyContent="flex-end">
                <Grid item xs={10} className={classes.useCalculationDivStyle}>
                  <Typography className={classes.dlvFeeCalculationTextStyle}>Use this value in calculation</Typography>
                </Grid>
                <Grid item xs={2}>
                  <SwitchButton
                    className={classes.switchStyle}
                    size="small"
                    checked={mainState.orderLevelMovThreshold.active}
                    disabled={false}
                    handleChange={(e: any) => _handle_order_level_mov_threshold_calculation_toggle_change(e)}
                    leftLabel={undefined}
                    rightLabel={undefined}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={4} className={classes.marginAuto}>
            <Grid container justifyContent="flex-end">
              <ChangeConfigOtion
                is_edit_mode={false}
                classes={classes}
                use_existing_checked={mainState.orderLevelMovThreshold.sameAsEarlier}
                use_existing_config_change_handler={(event: any) => use_existing__or_config_change_handler("sameAsEarlier")}
                change_existing_checked={mainState.orderLevelMovThreshold.enableConfigEdit}
                use_change_existing_config_change_handler={(e: any) => use_existing__or_config_change_handler("enableConfigEdit")}
              />
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(OrderLevelMOVThresholdConfiguration);
