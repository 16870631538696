import { makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  title: { fontSize: "24px", fontWeight: 700 },
  closeButton: {
    position: "absolute",
    top: "24px",
    right: "24px",
  },
  closeButtonSVG: {
    cursor: "pointer",
    fill: theme.palette.primary.main,
  },
  slotDetails: {
    margin: "24px 0px",
  },

  quotaDetailsContainer: {
    padding: "24px",
    backgroundColor: "#F9F9F9",
    "& > div:not(:first-child)": {
      marginTop: "64px",
    },
  },
  quotaName: { fontSize: "18px", fontWeight: 600, marginBottom: "12px" },
  fieldLabel: { fontWeight: 500 },
  fieldData: { fontWeight: 600 },
}));

export default styles;
