import React from "react";

import { Popover, SvgIcon } from "@material-ui/core";

import { useStyles } from "./TableHeaderFilterStyles";

import { ReactComponent as TableHeaderFilterIcon } from "../../assets/svg/TableHeaderFilterIcon.svg";

interface TableHeaderFilterInterface {
  openPopUp: any;
  anchorEl: any;
  //
  handleFilterPopupOpen: any;
  handleFilterPopupClose: any;
  //
  popUpContent: any;
  anchorName: any;
  //
  filterType?: any;
}

const TableHeaderFilter = (props: TableHeaderFilterInterface) => {
  const classes = useStyles();
  const {
    openPopUp,
    anchorEl,
    //
    handleFilterPopupOpen,
    handleFilterPopupClose,
    //
    popUpContent,
    anchorName,
    filterType,
  } = props;

  return (
    <>
      {filterType !== undefined && filterType === "dateRange" ? (
        popUpContent
      ) : (
        <>
          <SvgIcon key={anchorName} className={classes.rootIcon} component={TableHeaderFilterIcon} onClick={handleFilterPopupOpen} />
          <Popover
            open={openPopUp}
            id="filter-popover-area"
            classes={{
              paper: classes.paperProps,
            }}
            anchorEl={anchorEl}
            transformOrigin={{ vertical: -20, horizontal: "left" }}
            onClose={handleFilterPopupClose}
          >
            {popUpContent}
          </Popover>
        </>
      )}
    </>
  );
};

export default React.memo(TableHeaderFilter);
