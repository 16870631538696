import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../../config/theme/theme";

const serviceInformationStyles = makeStyles({
  serviceAndSlotsAcc: {
    backgroundColor: COLORS.WHITE,
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    marginTop: "16px",
  },
  serviceAndSlotsAccSummary: {
    color: COLORS.DARK_GRAY,
    fontSize: "18px",
    fontWeight: 600,
    borderBottom: "1px solid #E6E6E6",
    padding: "14px 24px",
  },
  serviceAndSlotsAccDetails: {
    margin: "16px 8px",
    // minHeight: "500px",
  },
  /* sliderContainer: {
    maxWidth: "100%",
    overflowX: "auto",
  },
  sliderRoot: {
    width: "250%",
  }, */
  sliderTrack: {
    height: "5px",
  },
  sliderRail: {
    height: "5px",
    backgroundColor: "#B4B4B4",
  },
  sliderMark: {
    display: "none",
  },
  sliderThumb: {
    height: "16px",
    width: "16px",
    marginTop: "-6px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25);",
  },
});

export default serviceInformationStyles;
