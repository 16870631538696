import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@material-ui/core";

import { RenderDefaultServiceSlots } from "../CompleteSetup/SlotsInformation/DefaultServiceSlot";
import { capitalizeFirstLetter, isObjectEmptyOrNull, isUndefined } from "../../utils/DataUtils";
import { ExpandMoreAccordianIcon } from "../common/App/AppViewUtils";

interface RenderDefaultSupplierAllInformationInterface {
  classes: any;
  supplierTypes: any;
}

const RenderDefaultSupplierAllInformation = (props: RenderDefaultSupplierAllInformationInterface) => {
  const { classes, supplierTypes } = props;

  const isSupplierTypeDataPresent = () => {
    return !isUndefined(supplierTypes) && !isObjectEmptyOrNull(supplierTypes);
  };

  return (
    <>
      {isSupplierTypeDataPresent() ? (
        Object.keys(supplierTypes).map((supplier, supplierIndex) => (
          <Accordion key={supplierIndex} className={classes.accordion} onClick={(e) => e.stopPropagation()}>
            <AccordionSummary expandIcon={<ExpandMoreAccordianIcon />} className={`${classes.accordionSummary} ${classes.collectionPointAccSummary}`} onClick={(e: any) => e.stopPropagation()}>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  {`${capitalizeFirstLetter(supplier)}`}
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.collectionPointAccDetails}>
              <Grid container>
                <Grid item className={classes.slotsAllDaysText}>
                  Slots (All Days)
                </Grid>
                <Grid item xs={12} className={classes.dayWiseSlotsContainer}>
                  <RenderDefaultServiceSlots dayWiseSlots={supplierTypes[supplier].dayWiseSlots} classes={classes} />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <></>
      )}
    </>
  );
};

export default React.memo(RenderDefaultSupplierAllInformation);
