import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Grid, Tooltip, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { capitalizeFirstLetter } from "../../Utils/DeliveryFeeUtils";
import { DELIVERY_FEE_MULTI_SHIPMENT_LEVEL } from "../../Utils/DeliveryFeeConstants";

import MultiShipmentLevelConfigurationCommonInputView from "./MultiShipmentLevelConfigurationCommonInputView";

import InfoIcon from "../../../assets/svg/InfoIcon.svg";
import SwitchButton from "../../../common/SwitchButton/SwitchButton";
import OrderLevelMOVThresholdConfiguration from "./Configuration/OrderLevelMOVThresholdConfiguration";

interface MultiShipmentLevelConfigurationInterface {
  openMultiShipmentLevel: any;
  classes: any;
  _handle_accordion_open_state: any;
  mainState: any;
  state: any;
  _handle_toggle_change_is_multi_shipment_calculation: any;
  _handle_calculation_toggle_change: any;
  _handle_multi_shiment_input_change: any;
  _handle_edit_multi_shipment_enable_toggle_change: any;
  openOrderLevelMovThreshold: any;
  handleOrderLevelMovThresholdInputChange: any;
  _handle_order_level_mov_threshold_calculation_toggle_change: any;
  use_existing__or_config_change_handler: any;
}

const MultiShipmentLevelConfiguration = (props: MultiShipmentLevelConfigurationInterface) => {
  const {
    openMultiShipmentLevel,
    classes,
    _handle_accordion_open_state,
    mainState,
    state,
    _handle_toggle_change_is_multi_shipment_calculation,
    _handle_calculation_toggle_change,
    _handle_multi_shiment_input_change,
    _handle_edit_multi_shipment_enable_toggle_change,
    openOrderLevelMovThreshold,
    handleOrderLevelMovThresholdInputChange,
    _handle_order_level_mov_threshold_calculation_toggle_change,
    use_existing__or_config_change_handler,
  } = props;

  return (
    <>
      <OrderLevelMOVThresholdConfiguration
        classes={classes}
        openOrderLevelMovThreshold={openOrderLevelMovThreshold}
        _handle_accordion_open_state={_handle_accordion_open_state}
        mainState={mainState}
        handleOrderLevelMovThresholdInputChange={handleOrderLevelMovThresholdInputChange}
        _handle_order_level_mov_threshold_calculation_toggle_change={_handle_order_level_mov_threshold_calculation_toggle_change}
        use_existing__or_config_change_handler={use_existing__or_config_change_handler}
      />
      <Accordion expanded={openMultiShipmentLevel} className={classes.accordionStyle} onChange={() => _handle_accordion_open_state("openMultiShipmentLevel")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryStyle}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography className={classes.accordionTitle}>{capitalizeFirstLetter(DELIVERY_FEE_MULTI_SHIPMENT_LEVEL)}</Typography>
            </Grid>
            <Grid item xs={6}>
              {openMultiShipmentLevel === true && (
                <Grid container>
                  <Grid item xs={1}>
                    {""}
                  </Grid>
                  <Grid item xs={5} className={classes.percentageBasedDivStyle}>
                    <Tooltip
                      arrow={true}
                      placement={"bottom"}
                      title={
                        <>
                          <Typography className={classes.tooltipStyle}>Threshold Percentage = Efood + Express * Percentage. </Typography>
                          <Typography className={classes.tooltipStyle}>Percentage of Delivery fees = efood delivery fees + Express delivery fees * Percentage</Typography>
                        </>
                      }
                      classes={{ tooltip: classes.multi_shipment_tooltip_style, arrow: classes.multi_shipment_tooltip_arrow_style }}
                    >
                      <img src={InfoIcon} alt="InfoIcon" />
                    </Tooltip>
                    <Typography className={mainState.isMultiShipmentCalculationPercentageBased === false ? classes.dlvFeeCalculationTextBoldStyle : classes.dlvFeeCalculationTextStyle}>
                      Value Based
                    </Typography>
                  </Grid>
                  <Grid item xs={1} className={classes.marginAuto}>
                    <SwitchButton
                      leftLabel=""
                      rightLabel=""
                      size="small"
                      checked={mainState.isMultiShipmentCalculationPercentageBased}
                      disabled={false}
                      handleChange={(e: any) => _handle_toggle_change_is_multi_shipment_calculation()}
                    />
                  </Grid>
                  <Grid item xs={5} className={classes.textAlignLeft}>
                    <Typography
                      className={
                        mainState.isMultiShipmentCalculationPercentageBased === true ? classes.multiDlvFeePercentageCalculationTextBoldStyle : classes.multiDlvFeePercentageCalculationTextStyle
                      }
                    >
                      Percentage Based
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetailsStyle}>
          {state &&
            Object.keys(state).map((stateLevel: any, stateLevelIndex: any) => (
              <MultiShipmentLevelConfigurationCommonInputView
                key={`${stateLevelIndex}-multi-${stateLevelIndex}-multi-stateLevel-state`}
                classes={classes}
                stateLevelIndex={stateLevelIndex}
                name={stateLevel}
                stateObj={state[stateLevel]}
                multiShipmentLevel={true}
                _handle_calculation_toggle_change={_handle_calculation_toggle_change}
                _handle_multi_shiment_input_change={_handle_multi_shiment_input_change}
                _handle_edit_multi_shipment_enable_toggle_change={_handle_edit_multi_shipment_enable_toggle_change}
                isPercentageBased={mainState.isMultiShipmentCalculationPercentageBased}
              />
            ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default React.memo(MultiShipmentLevelConfiguration);
