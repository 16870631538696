import React from "react";

import { Grid, Typography } from "@material-ui/core";

import SelectBox from "../../common/SelectBox/SelectBox";
import LinkButton from "../../common/LinkButton/LinkButton";

interface RenderAdvanceSlotDaysInterface {
  classes: any;
  advanceSlotDays: any;
  showAdvanceSlotDaysInput: any;
  menuItems: any;
  name: any;
  value: any;
  handleChange: any;
  handleAdvanceDaysClick: any;
  serviceTypeName: any;
}

const RenderAdvanceSlotDays = (props: RenderAdvanceSlotDaysInterface) => {
  const { classes, advanceSlotDays, showAdvanceSlotDaysInput, menuItems, name, value, handleChange, handleAdvanceDaysClick, serviceTypeName } = props;

  return (
    <Grid container spacing={0} justifyContent="flex-start">
      <Grid item xs={12} className={classes.advanceSlotDaysContainer}>
        <Typography className={classes.serviceTypeContentTitle}>{`Advance Slot Days : ${advanceSlotDays || "0"}`}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.advanceSlotDaysInput}>
        {showAdvanceSlotDaysInput ? (
          <Grid container>
            <Grid item xs={2}>
              <SelectBox name={name} menuItems={menuItems} value={value} handleChange={handleChange} />
            </Grid>
          </Grid>
        ) : (
          <LinkButton buttonLabel="Edit Days" className={classes.editDaysButton} onClick={() => handleAdvanceDaysClick(serviceTypeName)} />
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(RenderAdvanceSlotDays);
