import React from "react";

import moment from "moment";

import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";

import TextBox from "../../../common/TextBox/index";
import SelectBox from "../../../common/SelectBox/SelectBox";
import TimePicker from "../../../common/TimePicker/TimePicker";
import LinkButton from "../../../common/LinkButton/LinkButton";
import CustomAlert from "../../../common/CustomAlert/CustomAlert";
import SwitchButton from "../../../common/SwitchButton/SwitchButton";

import { ReactComponent as DeleteSlotIcon } from "../../../assets/svg/DeleteIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/svg/Pencil.svg";
import { ReactComponent as CheckedIcon } from "../../../assets/svg/CheckedIcon.svg";

import { ALERT_TYPES, SLOT_STATUSES, QUOTA_VIEW_OPTIONS } from "../../../../config/constants";
import { generateDropdownItems } from "./../../../../utils/helperFunctions";

// Styles
import templateSlotInEditModeStyles from "./TemplateSlotInEditModeStyles";
import { check_if_slot_obj_contains_sub_set } from "../../../../utils/ApplicationUtils";
import { is_standard_slot_time_checkbox_visible, is_user_creating_updating_pos } from "../../../ServiceAndSlots/Standard/StandardAccessUtils";

const HOURS = generateDropdownItems(0, 95, "hours");
const MINUTES = generateDropdownItems(0, 59, "mins");

const TemplateSlotInEditMode = ({
  slot,
  reduxSlot = {},
  canSlotBeDeleted,
  showQuotaColumn,
  handleIndividualSlotCheckbox,
  handleIndividualSlotTimeChange,
  handleIndividualSlotFieldsChange,
  makeSlotEditable,
  cancelEditingASlot,
  handleUpdateTemplateSlot,
  handleDeleteTemplateSlot,
  handleSaveNewTemplateSlot,
  handleViewQuotaSettings,
  configurationBulkUpdatedObj,
  generatedSlots,
  userInfo,
}) => {
  const classes = templateSlotInEditModeStyles();

  const isNewSlot = !slot.id;
  const { id, startTime, endTime, status, time, slotCapacity, bufferTimeHours, bufferTimeMinutes, isCreating } = slot;
  const { isUpdating, isDeleting, isUpdated } = reduxSlot;

  const calculateOrderCutOff = (slot) => {
    let orderCutOff = "";
    const { bufferTimeHours, bufferTimeMinutes, startTime } = slot;
    if (startTime && moment(startTime).isValid()) {
      const totalBufferTimeInMinutes = bufferTimeHours * 60 + bufferTimeMinutes;
      orderCutOff = moment(startTime).subtract(totalBufferTimeInMinutes, "m").format("HH:mm");
    }

    return orderCutOff || "";
  };

  const validateSlotTime = (slot) => {
    const { startTime, endTime } = slot;

    if (startTime && endTime) {
      const serviceStartTimeInMinutes = new Date(startTime).getTime();
      const serviceEndTimeInMinutes = new Date(endTime).getTime();

      if (serviceStartTimeInMinutes >= serviceEndTimeInMinutes) {
        CustomAlert(ALERT_TYPES.ERROR, "Service start time should be lesser than the service end time");
        return false;
      }
      const is_slot_obj_valid = check_if_slot_obj_contains_sub_set(generatedSlots);
      if (is_slot_obj_valid !== undefined && typeof is_slot_obj_valid === "object" && Object.keys(is_slot_obj_valid).length > 0) {
        CustomAlert(ALERT_TYPES.ERROR, is_slot_obj_valid[Object.keys(is_slot_obj_valid)[0]]);
        return false;
      }
    }
    return true;
  };

  const handleUpdateSlot = () => {
    if (validateSlotTime(slot)) {
      handleUpdateTemplateSlot(slot);
    }
  };

  const handleNewSlotSubmit = () => {
    if (validateSlotTime(slot)) {
      handleSaveNewTemplateSlot(slot);
    }
  };

  return (
    <Grid container spacing={1} key={id} className={`${classes.templateSlot} ${slot.canBeUpdated ? classes.isInEditMode : ""}`}>
      {slot.canBeUpdated || isNewSlot ? (
        <Grid item xs={3}>
          <Grid container alignItems="center" spacing={1}>
            {is_standard_slot_time_checkbox_visible(userInfo) && (
              <Grid item xs={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.slotCheckboxStyle}
                      checked={!!(status === SLOT_STATUSES.ACTIVATED || status === SLOT_STATUSES.PENDING_ACTIVATION)}
                      onChange={handleIndividualSlotCheckbox}
                      value={time}
                      disabled={!slot.canBeUpdated}
                    />
                  }
                />
              </Grid>
            )}
            <Grid item xs={1}>
              {""}
            </Grid>
            <Grid item xs={10}>
              <Grid container spacing={1} className={classes.slotEditTimeContainer}>
                <Grid item xs={6}>
                  <TimePicker showTimeIcon={false} value={startTime} handleChange={(date) => handleIndividualSlotTimeChange(date, "startTime")} />
                </Grid>
                <Grid item xs={6}>
                  <TimePicker showTimeIcon={false} value={endTime} handleChange={(date) => handleIndividualSlotTimeChange(date, "endTime")} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                className={classes.slotCheckboxStyle}
                checked={!!(status === SLOT_STATUSES.ACTIVATED || status === SLOT_STATUSES.PENDING_ACTIVATION)}
                onChange={handleIndividualSlotCheckbox}
                value={slot.time}
                disabled={!slot.canBeUpdated || !isNewSlot}
              />
            }
            label={
              <Grid container>
                <Typography className={classes.checkboxLabel}>{slot.time}</Typography>
                {isUpdated && <CheckedIcon className={classes.isSlotUpdatedIconStyle} />}
              </Grid>
            }
          />
        </Grid>
      )}
      <Grid item xs={showQuotaColumn ? 3 : 2}>
        <FormControl className={classes.slotField}>
          <TextBox
            type="number"
            name="slotCapacity"
            value={slotCapacity}
            handleChange={handleIndividualSlotFieldsChange}
            inputProps={{ maxLength: 15 }}
            textBoxId="capacityGenerated"
            disabled={!slot.canBeUpdated && !isNewSlot}
            placeholderText="Enter Capacity"
            isNegativeAllowed={false}
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <SelectBox
              label="Hrs"
              name="bufferTimeHours"
              menuItems={HOURS}
              value={bufferTimeHours}
              handleChange={handleIndividualSlotFieldsChange}
              disabled={!slot.canBeUpdated && !isNewSlot}
              labelClass={classes.hideShrinkedLabel}
              selectClass={classes.selectClass}
              className={classes.slotField}
            />
          </Grid>
          <Grid item xs={5}>
            <SelectBox
              label="Mins"
              name="bufferTimeMinutes"
              menuItems={MINUTES}
              value={bufferTimeMinutes}
              handleChange={handleIndividualSlotFieldsChange}
              disabled={!slot.canBeUpdated && !isNewSlot}
              labelClass={classes.hideShrinkedLabel}
              selectClass={classes.selectClass}
              className={classes.slotField}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        {calculateOrderCutOff(slot)}
      </Grid>
      {showQuotaColumn ? (
        <Grid item xs={1}>
          <Grid container alignItems="center">
            <Grid item>
              <SwitchButton
                name="isQuotaEnabled"
                disabled={!slot.canBeUpdated && !isNewSlot}
                checked={!!slot.isQuotaEnabled}
                value={!!slot.isQuotaEnabled}
                handleChange={handleIndividualSlotFieldsChange}
              />
            </Grid>
            <Grid item>
              <LinkButton buttonLabel="View" onClick={() => handleViewQuotaSettings(QUOTA_VIEW_OPTIONS.VIEW, slot)} />
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      <Grid item xs={2}>
        <Grid container spacing={1} alignItems="center">
          {is_user_creating_updating_pos(userInfo) ? (
            isNewSlot ? (
              <>
                {configurationBulkUpdatedObj !== undefined &&
                  configurationBulkUpdatedObj.bulkUpdatedDays !== undefined &&
                  Array.isArray(configurationBulkUpdatedObj.bulkUpdatedDays) &&
                  configurationBulkUpdatedObj.bulkUpdatedDays.length == 0 && (
                    <Grid item xs={4}>
                      <LinkButton className={classes.updateSlotButton} buttonLabel={isCreating ? "saving..." : "Save"} onClick={handleNewSlotSubmit} disabled={isCreating || !startTime || !endTime} />
                    </Grid>
                  )}
                <Grid item xs={2}>
                  <LinkButton buttonLabel="Cancel" onClick={() => handleDeleteTemplateSlot(slot)} />
                </Grid>
              </>
            ) : (
              <>
                {configurationBulkUpdatedObj !== undefined &&
                configurationBulkUpdatedObj.bulkUpdatedDays !== undefined &&
                Array.isArray(configurationBulkUpdatedObj.bulkUpdatedDays) &&
                configurationBulkUpdatedObj.bulkUpdatedDays.length == 0 ? (
                  <>
                    {slot.canBeUpdated ? (
                      <Grid item xs={10}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item xs={5}>
                            <LinkButton
                              className={classes.updateSlotButton}
                              buttonLabel={isUpdating ? "saving..." : "Save"}
                              disabled={isUpdating || !startTime || !endTime}
                              onClick={handleUpdateSlot}
                            />
                          </Grid>
                          <Grid item xs={5}>
                            <LinkButton className={classes.updateSlotButton} buttonLabel="Cancel" onClick={() => cancelEditingASlot(slot)} />
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item xs={6}>
                        <EditIcon className={classes.iconButton} onClick={() => makeSlotEditable(slot)} />
                      </Grid>
                    )}
                    {canSlotBeDeleted && !slot.canBeUpdated && (
                      <Grid item xs={2}>
                        {isDeleting ? "deleting..." : <DeleteSlotIcon className={classes.iconButton} onClick={() => handleDeleteTemplateSlot(slot)} />}
                      </Grid>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            )
          ) : !slot.canBeUpdated && !isNewSlot ? (
            <Grid item xs={2}>
              <EditIcon className={classes.iconButton} onClick={() => makeSlotEditable(slot)} />
            </Grid>
          ) : (
            <DeleteSlotIcon className={classes.iconButton} onClick={() => handleDeleteTemplateSlot(slot)} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(TemplateSlotInEditMode);
