import React from "react";

import { Collapse } from "@material-ui/core";

import { userStyles } from "../Styles/CollapseBoxStyle";
import TextBox from "components/common/TextBox";
import IconArrowClose from "../../assets/svg/IconArrowClose.svg";
import { AddUpdateUserError, AddUpdateUserState } from "../AddUpdateUserInterface";

interface UserBasicDetailsObject {
  openDetails: boolean;
  handleViewClose: () => void;
  userObj: AddUpdateUserState;
  handleChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  errors: AddUpdateUserError;
}

const UserBasicDetails = (props: UserBasicDetailsObject) => {
  const classes = userStyles();
  const { openDetails, handleViewClose, userObj, handleChange, errors } = props;

  return (
    <div className={classes.mainDetailsDivStyle}>
      <div onClick={handleViewClose} className={`${classes.detailsDivStyle} ${!openDetails ? classes.detailsDivCloseStyle : ""}`}>
        <span className={classes.detailsBoxTitleSpanStyle}>Basic Details</span>
        <img src={IconArrowClose} alt={IconArrowClose} className={`${classes.openArrowStyle} ${!openDetails ? classes.rotateBy270 : ""}`} />
      </div>
      <Collapse in={openDetails} unmountOnExit className={classes.collapseMainDivStyle} timeout={2}>
        <div className={classes.fieldDivStyle}>
          <span className={classes.fieldTitleSpanStyle}>First Name</span>
          <TextBox
            className={classes.textBoxStyle}
            label=""
            name="firstName"
            type="text"
            textBoxId="firstName"
            placeholderText="First Name"
            required={true}
            value={userObj.firstName}
            handleChange={handleChange}
            error={errors.firstName !== ""}
            helperText={errors.firstName}
          />
        </div>
        <div className={classes.fieldDivStyle}>
          <span className={classes.fieldTitleSpanStyle}>Last Name</span>
          <TextBox
            className={classes.textBoxStyle}
            label=""
            name="lastName"
            type="text"
            textBoxId="lastName"
            placeholderText="Last Name"
            required={true}
            value={userObj.lastName}
            handleChange={handleChange}
            error={errors.lastName !== ""}
            helperText={errors.lastName}
          />
        </div>
        <div className={classes.fieldDivStyle}>
          <span className={classes.fieldTitleSpanStyle}>Email Id</span>
          <TextBox
            className={classes.textBoxStyle}
            label=""
            name="emailId"
            type="Email"
            textBoxId="emailId"
            placeholderText="example@mafcarrefour.com"
            required={true}
            value={userObj.emailId}
            handleChange={handleChange}
            error={errors.emailId !== ""}
            helperText={errors.emailId}
          />
        </div>
      </Collapse>
    </div>
  );
};

export default React.memo(UserBasicDetails);
