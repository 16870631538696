import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../config/theme/theme";

const tableCellWidth = { width: "212px", minWidth: "212px", maxWidth: "212px" };
const tableHeaderCellBorder = { borderTopWidth: 0, borderRightWidth: "1px", borderBottomWidth: 0, borderLeftWidth: 0, borderColor: COLORS.MERCURY, borderStyle: "dotted" };
const tableBodyCellBorder = { borderTopWidth: "1px", borderRightWidth: "1px", borderBottomWidth: "1px", borderLeftWidth: 0, borderColor: COLORS.MERCURY, borderStyle: "dotted" };

export const userStyles = makeStyles(() =>
  createStyles({
    container: {
      marginTop: "80px",
      padding: "24px 12px 24px 24px",
    },
    headerRoot: {
      padding: 0,
      margin: 0,
    },
    header: {
      padding: 0,
      margin: 0,
      display: "flex",
      justifyContent: "flex-start",
      "&.MuiGrid-item": {
        padding: 0,
        margin: 0,
      },
    },
    headerFilter: {
      padding: 0,
      margin: 0,
      display: "flex",
      justifyContent: "flex-end",
      "&.MuiGrid-item": {
        padding: 0,
        margin: 0,
      },
    },
    headerFilterContainer: {},
    clearFilterDiv: {
      display: "flex",
      justifyContent: "flex-end",
      margin: "auto 0px",
      "&.MuiGrid-item": {
        padding: "4px",
      },
    },
    heading: {
      fontWeight: 600,
      fontSize: "24px",
      color: COLORS.DARK_GRAY,
    },
    clearFilterSpanStyle: {
      fontWeight: 600,
      fontSize: "14px",
      color: COLORS.DOS_BLUE_DARK,
      cursor: "pointer",
    },
    loaderDiv: {
      height: "calc(75vh)",
    },
    headerGridMainContainerStyle: {
      margin: "24px 0px 16px 0px",
      padding: 0,
      width: "auto",
    },
    dateSearchBoxOpen: {
      margin: "16px 0px 16px 0px",
      padding: "12px",
      width: "212px",
      maxWidth: "212px",
      height: "40px",
      border: "1px solid #adb5bd",
      borderRadius: "8px",
      background: "#FFFFFF",
      cursor: "pointer",
    },
    // Table
    tableContainerStyle: {
      height: "calc(100vh - 235px)",
      margin: "24px 0px 0px 0px",
      padding: 0,
      borderRadius: "12px",
      boxShadow: `0px 4px 24px 0px ${COLORS.LIGHT_WHITE}`,
      background: COLORS.WHITE,
    },
    tableHeaderRowStyle: {},
    tableHeaderCellStyle: {
      ...tableCellWidth,
      ...tableHeaderCellBorder,
      padding: "20px 10px",
      textAlign: "center",
      margin: "auto",
      fontWeight: 600,
      fontSize: "14px",
      color: COLORS.TUATARA,
      background: COLORS.WHITE,
    },
    tableCellRowStyle: {},
    tableBodyCellStickyStyle: {
      position: "sticky",
      top: 0,
      zIndex: 0,
      height: "40px",
      verticalAlign: "middle",
      fontWeight: 400,
      fontSize: "14px",
      color: COLORS.TUATARA,
      padding: "12px 12px 0px 12px",
      backgroundColor: COLORS.SILVERCHALICE,
      "&.MuiTableCell-paddingNone:last-child": {
        padding: "12px 12px 0px 12px",
      },
    },
    tableBodyCellStickyRelativeStyle: {
      ...tableCellWidth,
      ...tableBodyCellBorder,
      padding: "20px 10px",
      textAlign: "center",
      margin: "auto",
      fontWeight: 400,
      fontSize: "14px",
      color: COLORS.TUATARA,
      background: COLORS.WHITE,
      left: 0,
      zIndex: 3,
    },
    tableBodySecondCellStickyRelativeStyle: {
      ...tableCellWidth,
      ...tableBodyCellBorder,
      padding: "20px 10px",
      textAlign: "center",
      margin: "auto",
      fontWeight: 400,
      fontSize: "14px",
      color: COLORS.TUATARA,
      background: COLORS.WHITE,
      left: tableCellWidth.width,
      zIndex: 3,
    },
    tableBodyCellStyle: {
      ...tableCellWidth,
      ...tableBodyCellBorder,
      padding: "20px 10px",
      textAlign: "center",
      margin: "auto",
      fontWeight: 400,
      fontSize: "14px",
      color: COLORS.TUATARA,
      background: COLORS.WHITE,
      "&.MuiTableCell-paddingNone:last-child": {
        padding: "20px 10px",
      },
    },
    tableBodyCellTotalSpanStyle: {
      fontWeight: 700,
      fontSize: "20px",
    },
    tableBodyCellPosSpanStyle: {
      fontWeight: 600,
      fontSize: "14px",
    },
    tableBodyCellPosNormalSpanStyle: {
      fontWeight: 500,
      fontSize: "14px",
      padding: "0px 0px 0px 12px",
      textAlign: "left",
    },
    progressBarCountBox: {
      position: "relative",
    },
    linearProgressRootStyle: {
      height: "20px",
      borderRadius: "12px",
      "&.MuiLinearProgress-root": {
        height: "20px",
      },
    },
    linearProgressPrimaryColor: {
      background: COLORS.WILDSAND,
      backgroundColor: COLORS.WILDSAND,
      "&.MuiLinearProgress-colorPrimary": {
        backgroundColor: COLORS.WILDSAND,
      },
    },
    linearProgressFillColorDefaultStyle: {
      background: COLORS.PRIMARY_DARK,
      backgroundColor: COLORS.PRIMARY_DARK,
      borderRadius: "12px",
      "&.MuiLinearProgress-barColorPrimary": {
        backgroundColor: COLORS.PRIMARY_DARK,
      },
    },
    linearProgressFillColorRedStyle: {
      background: COLORS.PERSIAN_RED,
      backgroundColor: COLORS.PERSIAN_RED,
      borderRadius: "12px",
      "&.MuiLinearProgress-barColorPrimary": {
        backgroundColor: COLORS.PERSIAN_RED,
      },
    },
    linearProgressFillColorGreenStyle: {
      background: COLORS.SEA_GREEN,
      backgroundColor: COLORS.SEA_GREEN,
      borderRadius: "12px",
      "&.MuiLinearProgress-barColorPrimary": {
        backgroundColor: COLORS.SEA_GREEN,
      },
    },
    linearProgressFillColorYellowStyle: {
      background: COLORS.OLD_GOLD,
      backgroundColor: COLORS.OLD_GOLD,
      borderRadius: "12px",
      "&.MuiLinearProgress-barColorPrimary": {
        backgroundColor: COLORS.OLD_GOLD,
      },
    },
    linearProgressFillColorBlueStyle: {
      background: COLORS.CASPER,
      backgroundColor: COLORS.CASPER,
      borderRadius: "12px",
      "&.MuiLinearProgress-barColorPrimary": {
        backgroundColor: COLORS.CASPER,
      },
    },
    progressBarCountGrid: {
      position: "absolute",
      top: 0,
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      height: "20px",
      padding: "4px 10px",
    },
    progressBarCountUsedSpanStyle: {
      margin: "auto 0px",
      lineHeight: "12px",
      fontWeight: 500,
      fontSize: "12px",
      color: COLORS.WHITE,
    },
    progressBarCountAvailableSpanStyle: {
      margin: "auto 0px",
      lineHeight: "12px",
      fontWeight: 500,
      fontSize: "12px",
      color: COLORS.DUSTY_GRAY,
    },
    linearProgressBarActualCapacityGridStyle: {
      position: "relative",
      width: "100%",
      height: "8px",
      textAlign: "center",
      borderTop: "0px",
      borderBottom: `1px solid ${COLORS.SILVERCHALICE}`,
      borderLeft: `1px solid ${COLORS.SILVERCHALICE}`,
      borderRight: `1px solid ${COLORS.SILVERCHALICE}`,
      margin: "4px 0 0px 0px",
    },
    linearProgressBarActualCapacitySpanStyle: {
      position: "absolute",
      top: 0,
      left: "85px",
      fontWeight: 400,
      fontSize: "12px",
      color: COLORS.COD_GRAY,
      background: COLORS.WHITE,
      padding: "0 10px",
    },
    displayFlex: {
      display: "flex",
    },
    noDataRowStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "auto",
      height: "calc(100vh - 220px)",
      margin: "24px 0px",
      border: "none",
      borderRadius: "12px",
      boxShadow: `0px 4px 24px 0px ${COLORS.LIGHT_WHITE}`,
      background: COLORS.WHITE,
      verticalAlign: "middle",
      textAlign: "center",
    },
    tableFooterGridContainer: {
      margin: "16px 0px 0px 0px",
      padding: 0,
    },
    tableFooterSpanStyle: {
      display: "flex",
      justifyContent: "flex-start",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      color: COLORS.TUATARA,
    },
    tableFooterBoxStyle: {
      width: "10px",
      height: "10px",
      border: "none",
      borderRadius: "50%",
      margin: "auto 8px auto 16px",
    },
    customZIndex: {
      zIndex: 4,
    },
  })
);
