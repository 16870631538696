import React from "react";

import { Grid } from "@material-ui/core";

import SelectBox from "../../../common/SelectBox/SelectBox";

import { DEFAULT_RULE_CONFIG, RULE_ENGINE_TEXT_CONSTANT, getDefaultOrFixedTimeMinutes } from "../../RuleEngineUtils";
import { ChangeConfigOtion } from "../ServiceRuleEngineUtils";
import { DEFAULT_TIME_HOURS } from "../ServiceRuleEngine";

interface RenderMinimumPromiseTypeConfigurationInterface {
  classes: any;
  config: DEFAULT_RULE_CONFIG;
  onMinTimeIsSameAsEarlierChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleMinTimeConfigEdit: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onMinTimeChange: (event: React.ChangeEvent<HTMLInputElement>, name: string) => void;
}

const RenderMinimumPromiseTypeConfiguration = (props: RenderMinimumPromiseTypeConfigurationInterface) => {
  const { classes, config, onMinTimeIsSameAsEarlierChange, handleMinTimeConfigEdit, onMinTimeChange } = props;

  return (
    <Grid container className={classes.ruleContainer} spacing={0}>
      <Grid item xs={9} className={classes.ruleLabel}>
        {`${RULE_ENGINE_TEXT_CONSTANT.MINIMUM_PROMISE_TIME}`}
      </Grid>
      <ChangeConfigOtion
        config={config}
        checked={config.promiseConfig.minTimeSameAsEarlier}
        classes={classes}
        xsValue={4}
        checkChangeHandler={onMinTimeIsSameAsEarlierChange}
        changeExisting={config.promiseConfig.enableMinTimeEdit}
        onConfigEdit={handleMinTimeConfigEdit}
      />

      {(!config.promiseConfig.minTimeSameAsEarlier || config.isEditMode) && (
        <>
          <Grid item xs={4} className={classes.ruleEditingDivStyle}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SelectBox
                  label="Hours"
                  menuItems={DEFAULT_TIME_HOURS}
                  value={config.promiseConfig.minTimeHour}
                  handleChange={(event: React.ChangeEvent<HTMLInputElement>) => onMinTimeChange(event, "minTimeHour")}
                  disabled={!config.promiseConfig.enableMinTimeEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectBox
                  label="Minutes"
                  menuItems={getDefaultOrFixedTimeMinutes(config.promiseConfig.minTimeMinutes)}
                  value={config.promiseConfig.minTimeMinutes}
                  handleChange={(event: React.ChangeEvent<HTMLInputElement>) => onMinTimeChange(event, "minTimeMinutes")}
                  disabled={!config.promiseConfig.enableMinTimeEdit}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default React.memo(RenderMinimumPromiseTypeConfiguration);
