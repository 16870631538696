import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../config/theme/theme";

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: "16px",
    },
    areasAccordionSummary: {
      padding: "8px 24px",
    },
    areasAccordionDetails: {
      display: "block",
      padding: "0px 24px 24px 24px",
    },
    cardContent: {
      padding: "16px",
    },
    regionsSelector: {
      borderRadius: "8px 0px 0px 8px",
    },
    searchSelect: {
      marginRight: 0,
      "& .MuiOutlinedInput-root": {
        minWidth: 130,
        backgroundColor: "#F9F9F9",
      },
      "& .MuiOutlinedInput-input": {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      "& .open .MuiOutlinedInput-notchedOutline": {
        borderBottom: `1px solid ${COLORS.BLACK_INPUT_BORDER}`,
      },
      "& .MuiFilledInput-root": {
        backgroundColor: "#F9F9F9 !important",
      },
    },
    searchTextbox: {
      marginLeft: 0,
      "& .MuiOutlinedInput-root": {
        minWidth: 240,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderLeft: "none",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        top: "0px",
      },
    },
    selectAreasContainer: {
      maxWidth: "47%",
      flexBasis: "47%",
    },
    moveToListButtons: {
      maxWidth: "6%",
      flexBasis: "6%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    moveToRightListButton: {
      transform: "rotate(270deg)",
    },
    moveToLeftListButton: {
      marginTop: "20px",
      transform: "rotate(90deg)",
    },
    accordionStyle: {
      marginTop: "12px",
    },
    accordionTitle: {
      fontSize: "18px",
      fontWeight: 600,
    },
    areaListTransferButton: {
      cursor: "pointer",
    },
    assignedAreasContainer: {
      maxWidth: "47%",
      flexBasis: "47%",
    },
    areaTitles: {
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "20px",
      color: "#2D2D2D",
    },
    areaListCards: {
      boxShadow: "none",
      borderRadius: "8px",
      height: "100%",
    },
    areaList: {
      width: "-webkit-fill-available",
      // height: "328px",
      overflow: "scroll",
      // marginTop: "8px",
      "&::-webkit-scrollbar": {
        width: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "rgba(77, 77, 77, 0.2)",
        borderRadius: "10px",
        height: "50px",
      },
      padding: "0px",
    },
    areasListNotEmpty: {
      height: "328px",
      marginTop: "8px",
    },
    areaListItems: {
      color: "#000000",
      "&.MuiListItem-button:hover": {
        backgroundColor: "#E9EFF2",
      },
    },

    areaSelectedStyles: {
      backgroundColor: "#E9EFF2",
      "& span": {
        fontWeight: 500,
      },
    },
    error: {
      marginTop: "10px",
      fontSize: "14px",
      color: "red",
    },
    assignedAreaSearchBar: {
      marginTop: "10px",
    },
  })
);
