import { PosInterface, SecondaryStoresInterface, ZoneServiceTypeListInterface } from "./StandardServiceConstant";

export interface ConfigurationBulkUpdatedInitialObjInterface {
  isConfigurationBulkUpdated: any;
  bulkUpdatedDays: any;
}

export const ConfigurationBulkUpdatedInitialObj: ConfigurationBulkUpdatedInitialObjInterface = {
  isConfigurationBulkUpdated: "",
  bulkUpdatedDays: [],
};

export interface StandardServiceInitialStateInterface {
  loading: boolean;
  selectedConfigTab: any;
  request_already_presnt_by_admin_error: any;
  openBulkUpdateConfirmationPopUp: any;
  generatorFormIndex: any;
  configuredDays: any;
  currentBulkUpdateConfigurationSummary: any;
  selctedDays: any;
  currentDay: any;
  configurationBulkUpdatedObj: ConfigurationBulkUpdatedInitialObjInterface;
  // Dialog
  showDeleteZCModal: any;
  showPreviewModal: any;
  // Implemantation date for admin
  activationDate: any;
  activationDatelabel: any;
  isDateValid: any;
  data_present_obj: any;
  // Original Data
  originalConfigTab: any;
  //
  showZoneChangeWarningPopUp: boolean;
  temporarySelectedZone: any;
  // approval request
  showApprovalRequestPopUpPreview: boolean;
  requesterComments: any;
  // Dynamic Routing
  showDynamicRoutingPopUp: boolean;
  configTabIndex: number | null | undefined;
  totalAreaCount: string | number;
  totalRowsCountData: number | string;
  totalAssignedAreaCount: number;
  posZoneServiceRecord: PosInterface[];
  posZoneServiceFilteredRecord: PosInterface[];
  selectedPosServiceTypeZone: ZoneServiceTypeListInterface[];
  posZoneSearchText: string | null;
  assignedPosSearchText: string | null;
  dragRemoveConfigTabIndex: number | null;
  dragTargetItem: SecondaryStoresInterface | null;
  dragItemIndex: number | null;
  removeTargetItemIndex: number | null;
  showRemovePopUp: false;
  showDragPopUp: false;
}

export const StandardServiceInitialState: StandardServiceInitialStateInterface = {
  loading: false,
  selectedConfigTab: 0,
  request_already_presnt_by_admin_error: "",
  // Bulk Update
  openBulkUpdateConfirmationPopUp: false,
  configTabIndex: null,
  generatorFormIndex: null,
  configuredDays: [],
  currentBulkUpdateConfigurationSummary: {},
  selctedDays: [],
  currentDay: "",
  configurationBulkUpdatedObj: ConfigurationBulkUpdatedInitialObj,
  // Dialog
  showDeleteZCModal: false,
  showPreviewModal: false,
  // Implemantation date for admin
  activationDate: "",
  activationDatelabel: "",
  isDateValid: false,
  data_present_obj: {},
  // Original Data
  originalConfigTab: {},
  //
  showZoneChangeWarningPopUp: false,
  temporarySelectedZone: "",
  // approval request
  showApprovalRequestPopUpPreview: false,
  requesterComments: "",
  // Dynamic Routing
  showDynamicRoutingPopUp: false,
  totalAreaCount: 0,
  totalRowsCountData: 0,
  totalAssignedAreaCount: 0,
  posZoneServiceRecord: [],
  posZoneServiceFilteredRecord: [],
  selectedPosServiceTypeZone: [],
  posZoneSearchText: "",
  assignedPosSearchText: "",
  dragRemoveConfigTabIndex: null,
  dragTargetItem: null,
  dragItemIndex: null,
  removeTargetItemIndex: null,
  showRemovePopUp: false,
  showDragPopUp: false,
};
