import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import ModalContainer from '../../../base/ModalContainer';
import { ReactComponent as CloseButton } from "../../assets/svg/closeButton.svg";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
// Styles
import confirmModalStyles from "./ConfirmModalStyles";





const ConfirmModal = ({ show, title, description, confirmButtonText, onClose, onProceed, isLoading }) => {
  const classes = confirmModalStyles();

  return (
    <div className={classes.modalContainer}>
      <ModalContainer
        className={classes.modal}
        open={show}
        onClose={onClose}
        disableEscapeKeyDown={isLoading}
        disableBackdropClick
      >
        <div className={classes.modalContent}>
          <div className={classes.closeButton}>
            <CloseButton className={classes.closeButtonSVG} onClick={onClose} />
          </div>

          <div className={classes.modalHeader}>
            <div className={classes.modalTitle}>{title}</div>
          </div>

          <div className={classes.modalBody}>{description}</div>

          <div className={classes.modalFooter}>
            <Grid container spacing={2}>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <PrimaryButton
                  className={classes.buttonStyles}
                  buttonLabel="CANCEL"
                  buttonVariant="outlined"
                  onClick={onClose}
                />
              </Grid>
              <Grid item xs={5}>
                <PrimaryButton
                  className={classes.buttonStyles}
                  buttonLabel={isLoading ? "loading..." : confirmButtonText}
                  onClick={onProceed}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

ConfirmModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  confirmButtonText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

ConfirmModal.defaultProps = {
  confirmButtonText: "Proceed",
  isLoading: false,
};

export default ConfirmModal;
