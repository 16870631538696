import { createStyles, makeStyles } from "@material-ui/core";

export const multiSelectBoxStyles = makeStyles((theme) =>
  createStyles({
    fieldLabel: {
      marginBottom: "8px",
    },
    formControl: {
      "& .open": {
        "& .MuiOutlinedInput-notchedOutline": {
          top: "0px",
          borderBottom: "none",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          border: "1px solid #0A437C",
        },
      },
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
      maxHeight: 19,
      overflow: "auto",
    },
    chip: {
      margin: 2,
      height: 17,
      fontSize: 12,
    },
    menuPropsList: {
      "& .MuiListItem-root": {
        fontSize: 12,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        whiteSpace: "normal",
      },
      "& .MuiCheckbox-root": {
        backgroundSize: "16px 16px",
        marginRight: theme.spacing(0.75),
      },
    },
    menuPropsPaper: {
      "& .open": {
        border: "1px solid #0A437C",
      },
      maxHeight: 200,
      border: "1px solid #0A437C",
      borderRadius: theme.spacing(1),
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      boxShadow: "none",
      overflowX: "hidden",
      maxWidth: "155px",
    },
  })
);

export const anchorOriginDefault = {
  vertical: "bottom",
  horizontal: "left",
};
