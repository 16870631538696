import React from "react";

import { Card, CardContent, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";

import { AREA_POS_MAPPING_TYPE } from "../../../config/constants";

interface CountryMappingTypeViewInterface {
  classes: any;
  areaPosMappingType: any;
}

const CountryMappingTypeView = (props: CountryMappingTypeViewInterface) => {
  const { classes, areaPosMappingType } = props;
  return (
    <Card className={classes.areaMappingContainer}>
      <CardContent>
        <FormControl component="fieldset">
          <FormLabel component="legend" className={classes.serviceTypeContentTitle}>
            Select the Mapping Type
          </FormLabel>
          <RadioGroup name="slotVisibility" value={areaPosMappingType}>
            <FormControlLabel
              value={AREA_POS_MAPPING_TYPE.AREA_BASED}
              control={<Radio color="primary" />}
              label="Area Based Assignment"
              classes={{ label: classes.formControlLabel }}
              disabled={true}
            />
            <FormControlLabel
              value={AREA_POS_MAPPING_TYPE.POLYGON_BASED}
              control={<Radio color="primary" />}
              label="Polygon Based Assignment"
              classes={{ label: classes.formControlLabel }}
              disabled={true}
            />
          </RadioGroup>
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default React.memo(CountryMappingTypeView);
