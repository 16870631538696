import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Card, Grid, Typography } from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AdditionalDeliveryFeeConfigList from "./AdditionalDeliveryFeeConfigList";
import AdditionalCapacityUtilisationConfigList from "./AdditionalCapacityUtilisationConfigList";

import { DELIVERY_FEE_TEXT_CONSTANT, M_O_V_THRESHOLD_BOX_TITLE, SHIPMENT_LEVEL_CONFIGURATION, STANDARD_SERVICE_TYPE } from "../../Utils/DeliveryFeeConstants";
import { isArrayValid, isEmptyOrNull, isNonEmptyObject, isUndefined } from "../../../../utils/DataUtils";
import { DynamicFeesInterface, deliveryFeeRangeConfigDTOListInterface } from "../../Utils/DeliveryFeeInterface";

interface ShipmentLevelPreviewConfigurationInterface {
  classes: any;
  state: any;
}

const ShipmentLevelPreviewConfiguration = (props: ShipmentLevelPreviewConfigurationInterface) => {
  const { classes, state } = props;

  const renderDeliveryFeeContent = (title: string, value: string | number | undefined) => {
    return (
      <Grid item xs={3} className={classes.dlvFeeValueDisplayDivStyle}>
        <Typography className={classes.dlvFeeValueDisplayTitleStyle}>{`${title || ""}`}</Typography>
        <Typography className={classes.dlvFeeValueDisplayDataStyle}>{`${value || ""}`}</Typography>
      </Grid>
    );
  };

  const renderTitle = (ele: string) => {
    return (
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryStyle}>
        <Grid container alignItems="center">
          <Grid>
            <Typography className={classes.accordionTitle}>{`${SHIPMENT_LEVEL_CONFIGURATION} - ${ele}`}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
    );
  };

  /** Capacity Utilisation Configuration */
  const isCapacityUtilisationConfigurationPresent = (ele: string) => {
    return (
      state.shipmentLevel[ele] &&
      state.shipmentLevel[ele].promiseSlotFee &&
      state.shipmentLevel[ele].promiseSlotFee.capacityUtilisationConfiguration &&
      isArrayValid(state.shipmentLevel[ele].promiseSlotFee.capacityUtilisationConfiguration)
    );
  };
  const renderCapacityUtilisationConfiguration = (ele: string) => {
    return (
      isCapacityUtilisationConfigurationPresent(ele) && (
        <AdditionalCapacityUtilisationConfigList feeConfigList={state.shipmentLevel[ele].promiseSlotFee.capacityUtilisationConfiguration} classes={classes} stateObj={state.shipmentLevel[ele]} />
      )
    );
  };

  /** Additional Delivery Fee Config DTO List Present */
  const isAdditionalDeliveryFeeConfigDTOListPresent = (ele: string) => {
    return (
      state.shipmentLevel[ele] &&
      state.shipmentLevel[ele].deliveryFee &&
      state.shipmentLevel[ele].deliveryFee.additionalDeliveryFeeConfigDTOList &&
      isArrayValid(state.shipmentLevel[ele].deliveryFee.additionalDeliveryFeeConfigDTOList)
    );
  };
  const renderAdditionalDeliveryFeeConfigDTOListPresent = (ele: string) => {
    return (
      isAdditionalDeliveryFeeConfigDTOListPresent(ele) && (
        <AdditionalDeliveryFeeConfigList feeConfigList={state.shipmentLevel[ele].deliveryFee.additionalDeliveryFeeConfigDTOList} classes={classes} stateObj={state.shipmentLevel[ele]} />
      )
    );
  };

  /** isMovThresholdFee */
  const isMovThresholdFeePresent = (ele: string) => {
    return state.shipmentLevel[ele] && state.shipmentLevel[ele].serviceFee && !isUndefined(state.shipmentLevel[ele].serviceFee.isDynamic);
  };
  const renderMovThresholdFee = (ele: string) => {
    return (
      <>
        {isMovThresholdFeePresent(ele) && (
          <Card className={classes.additionDlvFeeMainCard}>
            <Grid container className={classes.additionDlvFeeInnerRowHeaderStyle}>
              <Grid item xs={2} className={classes.additionDlvFeeInnerRowDivStyle}>
                <Typography className={classes.dlvFeeValueDisplayDataStyle}>
                  {!isEmptyOrNull(state.shipmentLevel[ele].minimumOrderValue.movThreshold) ? state.shipmentLevel[ele].minimumOrderValue.movThreshold : "-"}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        )}
      </>
    );
  };

  /** Delivery Fee Range */
  const isrenderDeliveryFeeRange = (ele: string) => {
    return (
      state.shipmentLevel[ele] &&
      state.shipmentLevel[ele].deliveryFee &&
      state.shipmentLevel[ele].deliveryFee.deliveryFeeRangeConfigDTOList &&
      isArrayValid(state.shipmentLevel[ele].deliveryFee.deliveryFeeRangeConfigDTOList)
    );
  };
  const renderDeliveryFeeRange = (ele: string) => {
    return (
      <>
        {isrenderDeliveryFeeRange(ele) &&
          state.shipmentLevel[ele].deliveryFee.deliveryFeeRangeConfigDTOList.map((rangeObject: deliveryFeeRangeConfigDTOListInterface, rangeObjectIndex: number) => (
            <Grid item xs={12} key={`${rangeObjectIndex}-deliveryFeeRangeConfigDTOList-multiShipmentLevel-${ele}`}>
              <Grid container>
                {renderDeliveryFeeContent(DELIVERY_FEE_TEXT_CONSTANT.GREATER_THAN_OR_EQUALS_TO_THRESHOLD_VALUE, rangeObject?.previousOrderThreshold)}
                {renderDeliveryFeeContent(DELIVERY_FEE_TEXT_CONSTANT.LESS_THAN_THRESHOLD_VALUE, rangeObject?.newOrderThreshold)}
                {renderDeliveryFeeContent(DELIVERY_FEE_TEXT_CONSTANT.DELIVERY_FEES, rangeObject?.deliveryFee)}
              </Grid>
            </Grid>
          ))}
      </>
    );
  };

  /** Service Fee */
  function isServiceFeePresent(ele: string) {
    return state.shipmentLevel[ele] && state.shipmentLevel[ele].serviceFee && !isUndefined(state.shipmentLevel[ele].serviceFee.isDynamic);
  }
  const renderServiceFee = (ele: string) => {
    return (
      <>
        {isServiceFeePresent(ele) && (
          <Card className={classes.additionDlvFeeMainCard}>
            {state.shipmentLevel[ele].serviceFee.isDynamic ? (
              state.shipmentLevel[ele].serviceFee.dynamicFees !== undefined &&
              Array.isArray(state.shipmentLevel[ele].serviceFee.dynamicFees) &&
              state.shipmentLevel[ele].serviceFee.dynamicFees.length > 0 && (
                <>
                  <Grid container className={classes.additionDlvFeeInnerRowHeaderStyle}>
                    <Grid item xs={3} className={classes.dlvFeeValueDisplayDivStyle}>
                      <Typography className={classes.dlvFeeValueDisplayTitleStyle}>{`${DELIVERY_FEE_TEXT_CONSTANT.FEE_PERCENTAGE}`}</Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.dlvFeeValueDisplayDivStyle}>
                      <Typography className={classes.dlvFeeValueDisplayTitleStyle}>{`${DELIVERY_FEE_TEXT_CONSTANT.MIN_VALUE}`}</Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.dlvFeeValueDisplayDivStyle}>
                      <Typography className={classes.dlvFeeValueDisplayTitleStyle}>{`${DELIVERY_FEE_TEXT_CONSTANT.MAX_VALUE}`}</Typography>
                    </Grid>
                  </Grid>

                  {state.shipmentLevel[ele].serviceFee.dynamicFees.map((fee: DynamicFeesInterface, feeIndex: number) => (
                    <Grid container className={classes.additionDlvFeeInnerRowHeaderStyle} key={`{fee-Index-${feeIndex}}`}>
                      <Grid item xs={3} className={classes.additionDlvFeeInnerRowDivStyle}>
                        <Typography className={classes.dlvFeeValueDisplayDataStyle}>{fee.feePercentage}</Typography>
                      </Grid>
                      <Grid item xs={3} className={classes.additionDlvFeeInnerRowDivStyle}>
                        <Typography className={classes.dlvFeeValueDisplayDataStyle}>{fee.minValue}</Typography>
                      </Grid>
                      <Grid item xs={3} className={classes.additionDlvFeeInnerRowDivStyle}>
                        <Typography className={classes.dlvFeeValueDisplayDataStyle}>{fee.maxValue}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </>
              )
            ) : (
              <>
                <Grid container className={classes.additionDlvFeeInnerRowHeaderStyle}>
                  <Grid item xs={3} className={classes.additionDlvFeeInnerRowDivStyle}>
                    <Typography className={classes.dlvFeeValueDisplayDataStyle}>{`${state?.shipmentLevel[ele]?.serviceFee?.staticFees || "-"}`}</Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Card>
        )}
      </>
    );
  };

  const showPreferredSlotFeesConfiguration = (stateLevelName: string) => {
    return !isEmptyOrNull(stateLevelName) && stateLevelName === STANDARD_SERVICE_TYPE;
  };

  return (
    <>
      {isNonEmptyObject(state.shipmentLevel) ? (
        Object.keys(state.shipmentLevel).map(
          (ele: string) =>
            state.shipmentLevel[ele].isEditMode && (
              <Accordion defaultExpanded={true} className={classes.accordionStyle} key={`{state-shipmentLevel-${ele}}`}>
                {renderTitle(ele)}
                <AccordionDetails className={classes.accordionDetailsStyle}>
                  <Grid container className={classes.previewServiceDivStyle}>
                    <Grid container className={classes.previewServiceDeliveryFeeDivStyle}>
                      <Grid item xs={12} className={classes.previewServiceTitleDivStyle}>
                        <Typography className={classes.previewServiceTitleDivSpanStyle}>{`${DELIVERY_FEE_TEXT_CONSTANT.DELIVERY_FEES}`}</Typography>
                      </Grid>
                      {renderDeliveryFeeContent(DELIVERY_FEE_TEXT_CONSTANT.LESS_THAN_THRESHOLD_VALUE, state.shipmentLevel[ele]?.deliveryFee?.orderThreshold)}
                      {renderDeliveryFeeContent(DELIVERY_FEE_TEXT_CONSTANT.DELIVERY_FEES_BELOW_THRESHOLD, state.shipmentLevel[ele]?.deliveryFee?.belowThreshold)}
                      {renderDeliveryFeeContent("", "")}
                      {renderDeliveryFeeContent(DELIVERY_FEE_TEXT_CONSTANT.STATUS, state.shipmentLevel[ele].status)}
                      {renderDeliveryFeeRange(ele)}
                      <Grid item xs={12}>
                        <Grid container>
                          {renderDeliveryFeeContent(DELIVERY_FEE_TEXT_CONSTANT.GREATER_THAN_OR_EQUALS_TO_THRESHOLD_VALUE, state.shipmentLevel[ele]?.deliveryFee?.greaterOrderThresold)}
                          {renderDeliveryFeeContent(DELIVERY_FEE_TEXT_CONSTANT.DELIVERY_FEES_ABOVE_THRESHOLD, state.shipmentLevel[ele]?.deliveryFee?.aboveThreshold)}
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        {renderAdditionalDeliveryFeeConfigDTOListPresent(ele)}
                      </Grid>
                    </Grid>

                    {showPreferredSlotFeesConfiguration(ele) && (
                      <Grid container className={classes.previewServiceDeliveryFeeDivStyle}>
                        <Grid item xs={12} className={classes.previewServiceTitleDivStyle}>
                          <Typography className={classes.previewServiceTitleDivSpanStyle}>{`${DELIVERY_FEE_TEXT_CONSTANT.PREFERRED_SLOT_FEES}`}</Typography>
                        </Grid>
                        {renderCapacityUtilisationConfiguration(ele)}
                      </Grid>
                    )}

                    <Grid container className={classes.previewServiceDeliveryFeeDivStyle}>
                      <Grid item xs={12} className={classes.previewServiceTitleDivStyle}>
                        <Typography className={classes.previewServiceTitleDivSpanStyle}>{`${DELIVERY_FEE_TEXT_CONSTANT.SERVICE_FEE_CONFIGURATION}`}</Typography>
                      </Grid>
                      {renderServiceFee(ele)}
                    </Grid>

                    <Grid container className={classes.previewServiceDeliveryFeeDivStyle}>
                      <Grid item xs={12} className={classes.previewServiceTitleDivStyle}>
                        <Typography className={classes.previewServiceTitleDivSpanStyle}>{`${DELIVERY_FEE_TEXT_CONSTANT.MINIMUM_ORDER_VALUE_THRESHOLD}`}</Typography>
                      </Grid>
                      {renderMovThresholdFee(ele)}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )
        )
      ) : (
        <div> No Data Available.</div>
      )}
    </>
  );
};
export default React.memo(ShipmentLevelPreviewConfiguration);
