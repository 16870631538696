import React, { useEffect, useRef } from "react";
import { Polygon } from "react-google-maps";

function PolygonMap({ name, paths, fillColor, strokeColor, ...props }) {
  const polygonRef = useRef();
  useEffect(() => {
    polygonRef && polygonRef.current && polygonRef.current.polygon && polygonRef.current.polygon.setOptions({ key: name, paths, strokeColor, fillColor });
  }, [fillColor, strokeColor, paths, name]);

  return <Polygon ref={polygonRef} key={name} paths={paths} {...props} />;
}

export default PolygonMap;
