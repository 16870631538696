import React from "react";

import { Box, Grid, LinearProgress, Typography } from "@material-ui/core";
import { getAvailableValueSpanColor, getBarColor, getUsedValueSpanColor, getValueBasedOnTotal } from "../Utils/DashboardPageUtils";

interface DashboardCustomLinearProgressInterface {
  classes: any;
  totalCapacity: number;
  usedCapacity: number;
  availableCapacity: number;
  usedCapacityPercentage: number;
}

const DashboardCustomLinearProgress = (props: DashboardCustomLinearProgressInterface) => {
  const { classes, totalCapacity, usedCapacity, availableCapacity, usedCapacityPercentage } = props;

  return (
    <Box className={classes.progressBarCountBox}>
      <LinearProgress
        classes={{ root: classes.linearProgressRootStyle, colorPrimary: classes.linearProgressPrimaryColor, barColorPrimary: getBarColor(classes, usedCapacityPercentage) }}
        variant="determinate"
        value={Number(usedCapacity) > 0 ? getValueBasedOnTotal(usedCapacity, totalCapacity) : 0}
      />
      <Grid className={classes.progressBarCountGrid}>
        <Typography className={getUsedValueSpanColor(classes, usedCapacityPercentage)}>{`${usedCapacity} (${usedCapacityPercentage.toFixed(2) || 0}%)`}</Typography>
        <Typography className={getAvailableValueSpanColor(classes, usedCapacityPercentage)}>{`${availableCapacity}`}</Typography>
      </Grid>
      <Grid className={classes.linearProgressBarActualCapacityGridStyle}>
        <Typography className={classes.linearProgressBarActualCapacitySpanStyle}>{`${totalCapacity}`}</Typography>
      </Grid>
    </Box>
  );
};

export default React.memo(DashboardCustomLinearProgress);
