import Loader from "../common/Loader/Loader";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { logOutUser, selectUserDetails } from "../../components/Login/redux/userSlice";
import { ALERT_TYPES, APP_ROUTES, USER_TYPES } from "../../config/constants";
import { selectAllCountries, selectAllRoles, selectServiceTypes } from "../../config/redux/configurationsSlice";
import CustomAlert from "../common/CustomAlert/CustomAlert";
import { AddUpdateUserErrorState, AddUpdateUserInitialState, statusListData } from "./AddUpdateUserInterface";
import AddUpdateUserPage from "./AddUpdateUserPage";
import { createUser, fetchUserList, getUserDetails, selectAllAdditionalServices, selectAllUserRecord, updateUser } from "./Redux/UserManagementSlice";
import { userStyles } from "./Styles/UserManagementStyle";
import UserDetailsDialog from "./UserDetailsDialog/UserDetailsDialog";
import { userManagementInitState, UserManagementState } from "./UserManagementInterface";
import { UserAddButtonAccess, UserManagementCardAccess } from "./Utils/UserManagementAccessUtils";
import {
  clearUserObj,
  createUserDetailsObject,
  createUserObject,
  createUserRequestObject,
  ERROR_CREATE,
  ERROR_UPDATE,
  getMaxRecordPresent,
  getPageNumber,
  NO_MESSAGE_AVAILABLE,
  updateUserRecordData,
  validateForm,
} from "./Utils/UserManagementUtils";
import { getUserListColumns } from "./Utils/UserManagementViewUtils";

const UserManagementPage = () => {
  const classes = userStyles();
  let location = useLocation<any>();

  const history = useHistory();
  const dispatch = useDispatch();

  const userInfo: any = useSelector(selectUserDetails);

  const tableHeaderColumns = getUserListColumns();
  const userListService: any = useSelector(selectAllUserRecord);

  const countriesDataList = useSelector(selectAllCountries);
  const rolesListData = useSelector(selectAllRoles);

  const serviceTypeList = useSelector(selectServiceTypes);
  const additionalServicesList = useSelector(selectAllAdditionalServices);
  const statusList = statusListData;

  const [roleList, setRoleList] = useState<any>([]);
  const [countriesList, setcountriesDataList] = useState<any>([]);

  const [userState, setUserState] = useState<UserManagementState>(userManagementInitState);
  const {
    currentCountry: { countryId },
  } = useSelector((state: any) => state.appConfig);

  // Add and Update User Dialog
  const [userDialogState, setUserDialogState] = useState({
    isLoading: false,
    openAddUpdateUserWindow: false,
    userObj: AddUpdateUserInitialState,
    errors: AddUpdateUserErrorState,
    isUpdatingUser: false,
    openUserDetailsWindow: false,
  });

  const updateCountryList = useCallback(async () => {
    let newResponse = countriesDataList.map((r: any) => ({ id: r.countryId, name: r.id }));
    setcountriesDataList(newResponse);
  }, [countriesDataList]);

  useEffect(() => {
    if (countriesDataList.length > 0) {
      updateCountryList();
    }
  }, [countriesDataList, updateCountryList]);

  const fetchUserDataList = useCallback(async () => {
    dispatch(
      fetchUserList({
        countryId: countryId,
        page: userState.page,
        size: userState.size,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const getCurrentConfig = async () => {
      let params = new URLSearchParams(location.search);
      if (location.search) {
        let toUpdateUserId = params.get("userId");
        let userDetailId = params.get("userDetailId");
        if (toUpdateUserId || userDetailId) {
          const userId = userDetailId ? userDetailId : toUpdateUserId; // Get user Id. Either Edit user or User Detail
          setUserDialogState({ ...userDialogState, isLoading: true }); // Add Loading Screen
          const { payload }: any = await dispatch(getUserDetails(userId)); // Fetch User Details
          if (payload && payload.id && payload.firstName) {
            if (toUpdateUserId) {
              // If Edit User is clicked
              setUserDialogState({
                ...userDialogState,
                isUpdatingUser: true,
                userObj: createUserObject(payload, userDialogState.userObj),
                openAddUpdateUserWindow: true,
                isLoading: false,
                openUserDetailsWindow: false,
              });
            } else if (userDetailId) {
              // If Action Icon is clicked
              setUserDialogState({
                ...userDialogState,
                isUpdatingUser: false,
                userObj: createUserDetailsObject(payload, userDialogState.userObj),
                openAddUpdateUserWindow: false,
                isLoading: false,
                openUserDetailsWindow: true,
              });
            }
          } else {
            CustomAlert(ALERT_TYPES.ERROR, "Error while fetching User Details.");
            setUserDialogState({ ...userDialogState, isLoading: false });
          }
        }
      }
    };
    getCurrentConfig();
    // eslint-disable-next-line
  }, [location, location.search, dispatch]);

  const handleExportCSVClick = async () => {
    // setIsExporting(true);
    // TODO
  };

  const handleSearch = (e: any) => {
    let { value } = e.target;
    setUserState({
      ...userState,
      searchText: value,
    });
  };

  const handleAddUpdateUserDialogClose = useCallback(() => {
    if (userDialogState.openAddUpdateUserWindow) {
      // If Dialog Open
      // Moving back to USER_MANAGEMENT URL if Update User Dialog
      history.push(APP_ROUTES.USER_MANAGEMENT);
    }
    setUserDialogState({
      ...userDialogState,
      openAddUpdateUserWindow: !userDialogState.openAddUpdateUserWindow,
      userObj: clearUserObj(userDialogState.userObj),
      errors: AddUpdateUserErrorState,
      isUpdatingUser: false,
    });
  }, [userDialogState, history]);

  const handleUserObjChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event && event.target) {
        const { name, value } = event.target;
        switch (name) {
          case "emailId":
            let text = "Only @mafcarrefour.com Allowed";
            // eslint-disable-next-line
            if (value.match(/^[\w.+\-]+@mafcarrefour\.com$/)) {
              text = "";
            }
            setUserDialogState({ ...userDialogState, userObj: { ...userDialogState.userObj, [name]: value }, errors: { ...userDialogState.errors, [name]: text } });
            break;
          case "role":
            setUserDialogState({ ...userDialogState, userObj: { ...userDialogState.userObj, [name]: value, country: "", countryId: [] }, errors: { ...userDialogState.errors, [name]: "" } });
            break;
          case "country":
            setUserDialogState({ ...userDialogState, userObj: { ...userDialogState.userObj, [name]: value, countryId: [] }, errors: { ...userDialogState.errors, [name]: "" } });
            break;
          default:
            setUserDialogState({ ...userDialogState, userObj: { ...userDialogState.userObj, [name]: value }, errors: { ...userDialogState.errors, [name]: "" } });
        }
      }
    },
    [userDialogState]
  );

  const handleCountryChange = useCallback(
    (value: Array<any>) => {
      setUserDialogState({ ...userDialogState, userObj: { ...userDialogState.userObj, countryId: value, country: "" }, errors: { ...userDialogState.errors, countryId: "" } });
    },
    [userDialogState]
  );

  const handleServiceTypeChange = useCallback(
    (value: Array<any>) => {
      setUserDialogState({ ...userDialogState, userObj: { ...userDialogState.userObj, serviceTypeId: value }, errors: { ...userDialogState.errors, serviceTypeId: "" } });
    },
    [userDialogState]
  );

  const handleFeatureChange = useCallback(
    (value: Array<any>) => {
      setUserDialogState({ ...userDialogState, userObj: { ...userDialogState.userObj, featureId: value }, errors: { ...userDialogState.errors, featureId: "" } });
    },
    [userDialogState]
  );

  const handleSubmit = async () => {
    const valiadte: any = validateForm(userDialogState.userObj);
    if (valiadte.isError) {
      setUserDialogState({ ...userDialogState, errors: { ...userDialogState.errors, ...valiadte.newErrors } });
    } else {
      const userRequest: any = createUserRequestObject(userDialogState.userObj);
      const { payload }: any = await dispatch(createUser(userRequest));
      if (payload && payload.id) {
        // Success
        CustomAlert(ALERT_TYPES.SUCCESS, `User ${payload.firstName} ${payload.lastName} added successfully`);
        handleAddUpdateUserDialogClose();
        fetchUserDataList();
      } else {
        let errorMessage = ERROR_CREATE;
        if (payload.message && payload.message !== NO_MESSAGE_AVAILABLE) {
          errorMessage = payload.message;
        }
        CustomAlert(ALERT_TYPES.ERROR, errorMessage);
      }
    }
  };

  useEffect(() => {
    fetchUserDataList();
  }, [fetchUserDataList]);

  const handleUpdateUser = async () => {
    const valiadte: any = validateForm(userDialogState.userObj);
    if (valiadte.isError) {
      setUserDialogState({ ...userDialogState, errors: { ...userDialogState.errors, ...valiadte.newErrors } });
    } else {
      const userRequest: any = createUserRequestObject(userDialogState.userObj);
      const { payload }: any = await dispatch(updateUser(userRequest));
      if (payload && payload.id) {
        CustomAlert(ALERT_TYPES.SUCCESS, `User ${payload.firstName} ${payload.lastName} updated successfully`);
        if (userInfo !== undefined && userInfo.id !== undefined && userInfo.id === payload.id) {
          CustomAlert(ALERT_TYPES.SUCCESS, `Refresh required. Logging out`);
          dispatch(logOutUser());
        } else {
          handleAddUpdateUserDialogClose();
          fetchUserDataList();
        }
      } else {
        let errorMessage = ERROR_UPDATE;
        if (payload.message && payload.message !== NO_MESSAGE_AVAILABLE) {
          errorMessage = payload.message;
        }
        CustomAlert(ALERT_TYPES.ERROR, errorMessage);
      }
    }
  };

  useEffect(() => {
    if (additionalServicesList.length > 0) {
      setUserDialogState({
        ...userDialogState,
        userObj: { ...userDialogState.userObj, userDataRecord: updateUserRecordData(userListService.data, countriesList, serviceTypeList, additionalServicesList) },
        isLoading: false,
      });
      setUserState({ ...userState, page: getPageNumber(userListService.data), maxRecordPresent: getMaxRecordPresent(userListService.data) });
    }
  }, [additionalServicesList, userListService, userListService.data, countriesList, serviceTypeList]);

  const handleUserDetailDialogClose = useCallback(() => {
    if (userDialogState.openUserDetailsWindow) {
      // If User Detail dialog Open, Moving back to USER_MANAGEMENT URL
      history.push(APP_ROUTES.USER_MANAGEMENT);
    }
    setUserDialogState({ ...userDialogState, openUserDetailsWindow: !userDialogState.openUserDetailsWindow });
  }, [userDialogState, history]);

  const updateRoleListBasedOnRole = useCallback(
    (rolesListData: any) => {
      if (rolesListData && Array.isArray(rolesListData) && rolesListData.length > 0) {
        if (userInfo?.userProfile?.role) {
          switch (userInfo.userProfile.role) {
            case USER_TYPES.MAF_ADMIN:
              setRoleList(rolesListData);
              break;
            case USER_TYPES.SUPER_ADMIN:
              let allowedRoles = rolesListData.filter((role: any) => role.id !== USER_TYPES.MAF_ADMIN && role.id !== USER_TYPES.SUPER_ADMIN);
              setRoleList(allowedRoles);
              break;
            default:
              setRoleList([]);
          }
        }
      }
    },
    [userInfo]
  );

  useEffect(() => {
    updateRoleListBasedOnRole(rolesListData);
  }, [rolesListData, updateRoleListBasedOnRole]);

  const fetchNextRecord = useCallback(
    (pageNumber: any) => {
      setUserDialogState({ ...userDialogState, userObj: { ...userDialogState.userObj, userDataRecord: [] } });
      dispatch(
        fetchUserList({
          countryId: countryId,
          page: pageNumber,
          size: userState.size,
        })
      );
    },
    [userState.page]
  );

  return (
    <div className={classes.container}>
      <div className={classes.headerRoot}>
        <div className={classes.header}>
          <span className={classes.heading}>{`Users`}</span>
        </div>
        <UserAddButtonAccess userInfo={userInfo} classes={classes} handleAddUpdateUserDialogClose={handleAddUpdateUserDialogClose} />
      </div>

      {userListService.loading ? (
        <Loader />
      ) : (
        <UserManagementCardAccess
          userInfo={userInfo}
          classes={classes}
          userState={userState}
          handleSearch={handleSearch}
          handleExportCSVClick={handleExportCSVClick}
          userDataLoading={userListService.loading}
          additionalServicesList={additionalServicesList}
          userDialogState={userDialogState}
          tableHeaderColumns={tableHeaderColumns}
          pageSize={userState.size}
          rowCount={userState.maxRecordPresent}
          pageNumber={userState.page}
          fetchNextRecord={fetchNextRecord}
        />
      )}
      {userDialogState.openAddUpdateUserWindow && (
        <AddUpdateUserPage
          open={userDialogState.openAddUpdateUserWindow}
          handleClose={handleAddUpdateUserDialogClose}
          userObj={userDialogState.userObj}
          errors={userDialogState.errors}
          handleUserObjChange={handleUserObjChange}
          handleCountryChange={handleCountryChange}
          handleServiceTypeChange={handleServiceTypeChange}
          handleSubmit={userDialogState.isUpdatingUser ? handleUpdateUser : handleSubmit}
          handleFeatureChange={handleFeatureChange}
          isUpdatingUser={userDialogState.isUpdatingUser}
          countriesList={countriesList}
          serviceTypeList={serviceTypeList}
          rolesList={roleList}
          additionalServicesList={additionalServicesList}
          statusList={statusList}
          userInfo={userInfo}
        />
      )}
      {userDialogState.openUserDetailsWindow && (
        <UserDetailsDialog
          open={userDialogState.openUserDetailsWindow}
          userObj={userDialogState.userObj}
          handleClose={handleUserDetailDialogClose}
          countriesList={countriesList}
          serviceTypeList={serviceTypeList}
          rolesList={roleList}
          additionalServicesList={additionalServicesList}
          statusList={statusList}
          userInfo={userInfo}
        />
      )}
    </div>
  );
};

export default React.memo(UserManagementPage);
