import React from "react";

import { DateRangePicker } from "react-bootstrap-daterangepicker";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

const CustomDateRangePicker = ({ isTextField, childClasses, datePickerName, datePickerValue, placeHolderText, handleDateChangeEvent, handleCancel, initialSettings }) => {
  return (
    <DateRangePicker onApply={handleDateChangeEvent} onCancel={handleCancel} initialSettings={initialSettings}>
      <input
        type="text"
        name={datePickerName}
        placeholder={`${placeHolderText || "Select Dates(s)"}`}
        className={isTextField ? "MuiOutlinedInput-root MuiOutlinedInput-input MuiOutlinedInput-notchedOutline" : childClasses.dateSearchBoxOpen}
        value={datePickerValue}
      />
    </DateRangePicker>

    // <DateRangePicker
    //   onApply={handleDateChangeEvent}
    //   onCancel={handleCancel}
    //   initialSettings={{
    //     singleDatePicker: isSingleDatePicker,
    //     autoUpdateInput: false,
    //     cancelButtonClasses: "btn-primary",
    //     locale: { format: "YYYY-MM-DD", cancelLabel: "Clear" },
    //     minDate: allowPrevious === true ? null : new Date(),
    //   }}
    // >
    //   <input type="text" name="date" placeholder={`${placeHolderText || "Select Dates(s)"}`} className={classes.dateSearchBoxOpen} value={value} />
    // </DateRangePicker>
  );
};

export default React.memo(CustomDateRangePicker);
