import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../../config/theme/theme";

export const tableStyles = makeStyles((theme) => ({
  root: {
    background: COLORS.WHITE,
    borderRadius: "3px 3px 0px 0px",
    borderColor: "transparent",
    "& .MuiDataGrid-footerContainer": {
      position: "fixed",
      bottom: 0,
      justifyContent: "flex-end",
      width: "calc(100vw - 110px)",
      marginBottom: "37px",
      marginLeft: "-2px",
      background: "#E6E6E6",
      borderRadius: "0px 0px 8px 8px",
      minHeight: "48px",
      maxHeight: "48px",
    },
    // "& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer": {
    //   padding: 0,
    // },
  },
  table: {
    margin: 0,
    padding: 0,
    minHeight: "500px",
    maxHeight: "calc(100vh - 340px)",
    width: "100%",
    overflow: "auto",
  },
  tableSection: {
    height: "100%",
    width: "100%",
    "& .MuiDataGrid-root": {
      borderTop: "none",
      fontSize: 12,
    },
    "& .MuiDataGrid-colCellTitle": {
      fontWeight: 600,
      whiteSpace: "normal",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiTablePagination-root": {
      marginLeft: "auto",
      borderBottom: "none",
    },
    "& .MuiDataGrid-root .MuiDataGrid-cell": {
      fontWeight: "500 !important",
    },
    "& .MuiDataGrid-colCellMoving": {
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
    "& .MuiDataGrid-columnsContainer": {
      background: "#F5F5F5",
      borderRadius: "0px",
      border: "1px solid #E6E6E6",
    },
    "& .MuiDataGrid-colCellTitleContainer": {
      whiteSpace: "normal",
      lineHeight: "16px",
    },
    // "& .MuiDataGrid-window": {
    //   maxHeight: "380px",
    //   overflowY: "scroll !important",
    // },
    "& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer": {
      padding: 0,
      margin: 0,
      fontWeight: 600,
    },
    "& .MuiDataGrid-root .MuiDataGrid-columnHeader--moving": {
      background: "none",
    },
  },
}));
