import { makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  quotaSettings: {
    position: "relative",
  },
  quotaSettingsContainer: {
    // position: "relative",
    minWidth: "580px",
    padding: "24px",
  },
  quotaSettingsTitle: {
    fontSize: "24px",
    fontWeight: 700,
  },
  closeButton: {
    position: "absolute",
    top: "24px",
    right: "24px",
  },
  closeButtonSVG: {
    cursor: "pointer",
    fill: theme.palette.primary.main,
  },
  creataQuotaForm: {
    margin: "24px 0px",
  },
  smallerTitle: {
    fontSize: "18px",
    fontWeight: 600,
  },
  fieldLabel: {
    margin: "12px 0px",
  },
  buttonStyles: { minWidth: "100%" },
  generatedQuotasContainer: {
    margin: "24px 0px",
    padding: "24px",
    backgroundColor: "#F9F9F9",
  },
  createButton: {
    position: "absolute",
    right: "24px",
    bottom: "24px",
    left: "24px",
  },
  /** Quota Details styles */
  quotaDetailContainer: {
    marginBottom: "24px",
  },
  quotaInputs: {
    marginTop: "8px",
  },
  error: {
    marginTop: "4px",
    color: "#f44336",
    fontSize: "12px",
  },
}));

export default styles;
