import React, { useState } from "react";

import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Grid, Typography } from "@material-ui/core";

import ModalContainer from "../../../base/ModalContainer";

import { ExpandMoreAccordianIcon } from "../../common/App/AppViewUtils";
import { ReactComponent as CloseButton } from "../../assets/svg/closeButton.svg";
import { userStyles } from "../Styles/ApprovalRequestRecordStyle";

import ApprovalRequestSlotDataDetailsPage from "./ApprovalRequestSlotDataDetailsPage";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import CustomTextArea from "../../common/TextBoxWithLabel/CustomTextArea";

interface PreviewApprovalRequestPopUpInterface {
  popUpOpenFlag: any;
  handleClose: any;
  originalConfigTab: any;
  selectedConfigTab: any;
  configTabs: any;
  send_request_for_approval: any;
  serviceTypeName: any;
}

const PreviewApprovalRequestPopUp = (props: PreviewApprovalRequestPopUpInterface) => {
  const { popUpOpenFlag, handleClose, originalConfigTab, selectedConfigTab, configTabs, send_request_for_approval, serviceTypeName } = props;
  const classes = userStyles();

  const [appState, setAppState] = useState({ requesterComments: "" });

  const handleRequesterCommentInputChange = (event: any) => {
    if (event !== undefined && event.target !== undefined) {
      const { name, value } = event.target;
      if (value !== undefined && value.length !== undefined && value.length > 200) {
        return;
      } else {
        setAppState({ ...appState, requesterComments: value });
      }
    }
  };

  const getOriginalSlotData = () => {
    if (
      originalConfigTab !== undefined &&
      Array.isArray(originalConfigTab) &&
      originalConfigTab.length > 0 &&
      selectedConfigTab !== undefined &&
      selectedConfigTab >= 0 &&
      originalConfigTab[selectedConfigTab] != undefined &&
      originalConfigTab[selectedConfigTab].slotsGeneratorForms !== undefined &&
      Array.isArray(originalConfigTab[selectedConfigTab].slotsGeneratorForms) &&
      originalConfigTab[selectedConfigTab].slotsGeneratorForms.length > 0
    ) {
      return originalConfigTab[selectedConfigTab].slotsGeneratorForms;
    }
  };

  const getRequestedSlotData = () => {
    if (
      selectedConfigTab !== undefined &&
      selectedConfigTab >= 0 &&
      configTabs !== undefined &&
      Array.isArray(configTabs) &&
      configTabs.length > 0 &&
      configTabs[selectedConfigTab] != undefined &&
      configTabs[selectedConfigTab].slotsGeneratorForms !== undefined &&
      Array.isArray(configTabs[selectedConfigTab].slotsGeneratorForms) &&
      configTabs[selectedConfigTab].slotsGeneratorForms.length > 0
    ) {
      return configTabs[selectedConfigTab].slotsGeneratorForms;
    }
  };

  return (
    <ModalContainer onClose={handleClose} open={popUpOpenFlag}>
      <Card className={classes.cardStyle}>
        <CardContent className={classes.cardContentStyle}>
          <Grid container spacing={0} justifyContent="flex-start">
            <Grid item xs={12} className={classes.headerGridItemStyle}>
              <Grid item xs={12} className={classes.headerTitleGridItemStyle}>
                <Typography className={classes.headerSpanStyle}>{`Request Changes Summary`}</Typography>
              </Grid>

              <CloseButton className={classes.closeButtonIconStyle} onClick={handleClose} />
            </Grid>
          </Grid>
          <Grid container spacing={1} className={classes.popupCardMessageGridSection}>
            <Grid item xs={12}>
              <Accordion defaultExpanded={true} className={classes.accordionStyle}>
                <AccordionSummary expandIcon={<ExpandMoreAccordianIcon />} className={classes.accordionSummaryStyle}>
                  <Grid container alignItems="center">
                    <Grid>
                      <Typography className={classes.accordionTitle}>
                        Requester Comment <span className={classes.accordionTitleNoteStyle}>(optional)</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetailsStyle}>
                  <CustomTextArea
                    label={""}
                    name={"requesterComments"}
                    value={appState.requesterComments}
                    placeholderText={"Message for Approver (Max 200 characters)"}
                    handleChange={handleRequesterCommentInputChange}
                    disabled={false}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          <Grid container spacing={1} className={classes.popupCardBodyGrid}>
            <Grid item xs={6}>
              <ApprovalRequestSlotDataDetailsPage
                classes={classes}
                slot_data_object_key={"slots"}
                slot_data_object={undefined}
                serviceTypeName={serviceTypeName}
                stateObj={getOriginalSlotData()}
                slotBoxClass={classes.slotsGridContainerDetailsStyleOnPopUp}
              />
            </Grid>
            <Grid item xs={6}>
              <ApprovalRequestSlotDataDetailsPage
                classes={classes}
                slot_data_object_key={"new_slots"}
                slot_data_object={undefined}
                serviceTypeName={serviceTypeName}
                stateObj={getRequestedSlotData()}
                slotBoxClass={classes.slotsGridContainerDetailsStyleOnPopUp}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0} className={classes.popupCardFooterGrid}>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item xs={3}>
                <PrimaryButton
                  className={classes.approveButtonConfigStyle}
                  buttonLabel="Send For Approval"
                  disableFocusRipple={true}
                  disableRipple={true}
                  onClick={() => send_request_for_approval(appState.requesterComments)}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </ModalContainer>
  );
};

export default React.memo(PreviewApprovalRequestPopUp);
