import { Card, CardActions, CardContent, CardHeader, Divider, Grid, Modal, Typography } from "@material-ui/core";
import { AREA_POS_MAPPING_TYPE, EXPRESS_TYPES, STATUS, SWITCH_STATUS } from "../../../config/constants";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import { ServiceTypeBasicDetails } from "../CountrySettingView";

interface CountryChangesConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  classes: any;
  standardDetails: any;
  expressDetails: any;
  clickCollectDetails: any;
  defaultDetails: any;
  areaPosMappingType: string;
  loading: boolean;
  handleSubmit: () => void;
  weeklyHolidays: any;
}

export const CountryChangesConfirmationModal = (props: CountryChangesConfirmationModalProps) => {
  const { open, onClose, classes, standardDetails = {}, expressDetails = {}, clickCollectDetails = {}, areaPosMappingType, loading, handleSubmit, defaultDetails = {}, weeklyHolidays } = props;
  return (
    <Modal open={open} onClose={onClose}>
      <Card className={classes.applyChangesModalBody}>
        <CardContent>
          <Grid item xs={12} className={classes.modalTitleGridItem}>
            <Typography className={classes.modalTitle}>Are you sure you want to apply the following changes? </Typography>
          </Grid>
          <Grid container spacing={0} className={classes.modalBody}>
            <Grid item xs={12} className={classes.serviceTypeGridItem}>
              <Typography className={classes.applyModalBodyTitle}>Area Assignment</Typography>
              <Typography className={classes.applyModalBodyContent}>{areaPosMappingType === AREA_POS_MAPPING_TYPE.AREA_BASED ? "Area Based Assignment" : "Polygon Based Assignment"}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.serviceTypeGridItem}>
              <Grid container spacing={0} justifyContent="flex-start">
                <ServiceTypeBasicDetails
                  classes={classes}
                  serviceTypeName={"Standard"}
                  serviceActiveStatus={standardDetails.serviceActive ? STATUS.ACTIVE : STATUS.IN_ACTIVE}
                  nextDaySlotVisibilityStatus={standardDetails.nextDaySlotVisibility ? SWITCH_STATUS.ON : SWITCH_STATUS.OFF}
                  editSlotDaysStatus={standardDetails.editSlotDays}
                  plasticBagChargeStatus={standardDetails.bagChargeEnabled ? STATUS.ENABLED : STATUS.DISABLED}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.serviceTypeGridItem}>
              <Grid container spacing={0} justifyContent="flex-start">
                <ServiceTypeBasicDetails
                  classes={classes}
                  serviceTypeName={"Express"}
                  serviceActiveStatus={expressDetails.serviceActive ? STATUS.ACTIVE : STATUS.IN_ACTIVE}
                  nextDaySlotVisibilityStatus={expressDetails.nextDaySlotVisibility ? SWITCH_STATUS.ON : SWITCH_STATUS.OFF}
                  editSlotDaysStatus={expressDetails.editSlotDays}
                  plasticBagChargeStatus={expressDetails.bagChargeEnabled ? STATUS.ENABLED : STATUS.DISABLED}
                  expressTypeStatus={expressDetails.expressType === EXPRESS_TYPES.SLOT_BASED.value ? EXPRESS_TYPES.SLOT_BASED.label : EXPRESS_TYPES.ON_DEMAND.label}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.serviceTypeGridItem}>
              <Grid container spacing={0} justifyContent="flex-start">
                <ServiceTypeBasicDetails
                  classes={classes}
                  serviceTypeName={"Click & Collect"}
                  serviceActiveStatus={clickCollectDetails.serviceActive ? STATUS.ACTIVE : STATUS.IN_ACTIVE}
                  nextDaySlotVisibilityStatus={clickCollectDetails.nextDaySlotVisibility ? SWITCH_STATUS.ON : SWITCH_STATUS.OFF}
                  editSlotDaysStatus={clickCollectDetails.editSlotDays}
                  plasticBagChargeStatus={clickCollectDetails.bagChargeEnabled ? STATUS.ENABLED : STATUS.DISABLED}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.serviceTypeGridItem}>
              <Grid container spacing={0} justifyContent="flex-start">
                <ServiceTypeBasicDetails
                  classes={classes}
                  serviceTypeName={"Default"}
                  serviceActiveStatus={defaultDetails.serviceActive ? STATUS.ACTIVE : STATUS.IN_ACTIVE}
                  plasticBagChargeStatus={defaultDetails.bagChargeEnabled ? STATUS.ENABLED : STATUS.DISABLED}
                />
                <Grid item xs={12} className={classes.serviceTypeDetailsGridItem}>
                  <Typography className={classes.applyModalBodyContent}>{`Calendar Holidays : `}</Typography>
                  <Typography className={classes.selectedValuesData}>{`${defaultDetails.holidays?.map((info: any) => info.objectDate).join(", ")}`}</Typography>
                </Grid>
                <Grid item xs={12} className={classes.serviceTypeDetailsGridItem}>
                  <Typography className={classes.applyModalBodyContent}>{`Weekly Holidays : `}</Typography>
                  <Typography className={classes.selectedValuesData}>{`${weeklyHolidays.holidays?.map((info: any) => info).join(", ")}`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.serviceTypeFooterGridItem}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <PrimaryButton className={classes.buttonStyles} buttonLabel="CANCEL" buttonVariant="outlined" onClick={onClose} />
            </Grid>
            <Grid item xs={4}>
              <PrimaryButton className={classes.buttonStyles} buttonLabel={loading ? "loading..." : "PROCEED"} onClick={handleSubmit} disabled={loading} />
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Modal>
  );
};
