import { makeStyles } from "@material-ui/core";

const confirmModalStyles = makeStyles((theme) => ({
  modal: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
  modalContent: {
    border: "none",
    width: "500px",
    backgroundColor: "white",
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "24px",
    textAlign: "center",
    borderRadius: "8px",
    "&:focus": {
      outline: "none",
    },
  },
  closeButton: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  closeButtonSVG: {
    cursor: "pointer",
    fill: theme.palette.primary.main,
  },
  modalTitle: {
    fontSize: "24px",
    fontWeight: 600,
    textAlign: "center",
  },
  modalBody: {
    padding: "24px",
  },
  modalFooter: {
    marginTop: "24px",
  },
  buttonStyles: {
    minWidth: "100%",
  },
}));

export default confirmModalStyles;
