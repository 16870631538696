import React from "react";

import { HEADER_TEXT } from "./ApprovalRequestConstant";
import { Typography } from "@material-ui/core";

interface ApprovalRequestRecordHeaderInterface {
  classes: any;
  clearAllFilter: any;
}
const ApprovalRequestRecordHeader = (props: ApprovalRequestRecordHeaderInterface) => {
  const { classes, clearAllFilter } = props;

  return (
    <div className={classes.headerRoot}>
      <div className={classes.header}>
        <span className={classes.heading}>{`${HEADER_TEXT}`}</span>
      </div>
      <div className={classes.clearFilterHeader}>
        <Typography className={classes.clearFilterSpanStyle} onClick={() => clearAllFilter()}>{`Clear All Filters`}</Typography>
      </div>
    </div>
  );
};

export default React.memo(ApprovalRequestRecordHeader);
