import React from "react";

import { Grid } from "@material-ui/core";

import { ChangeConfigOtion } from "../ServiceRuleEngineUtils";

import SwitchButton from "../../../common/SwitchButton/SwitchButton";
import PrimaryButton from "../../../common/PrimaryButton/PrimaryButton";
import RemoveButton from "../../../common/RemoveButton/RemoveButton";
import SelectBox from "components/common/SelectBox/SelectBox";

import { ITEMS_INCREMENTAL_TIME_RANGE } from "../ServiceRuleEngine";

interface RenderScalableItemsInterface {
  classes: any;
  config: any;
  serviceName: any;
  onUseForConfigurationSwitchChange: any;
  onIsSameAsEarlierCheckboxChange: any;
  onScaleRuleConfigEdit: any;
  onScalableItemsFieldChange: any;
  getUniqueList: any;
  onRemoveScalableItems: any;
  onAddScalableItems: any;
}

const RenderScalableItems = (props: RenderScalableItemsInterface) => {
  const {
    classes,
    config,
    serviceName,
    onUseForConfigurationSwitchChange,
    onIsSameAsEarlierCheckboxChange,
    onScaleRuleConfigEdit,
    onScalableItemsFieldChange,
    getUniqueList,
    onRemoveScalableItems,
    onAddScalableItems,
  } = props;
  return (
    <Grid container className={classes.ruleContainer} spacing={0}>
      <Grid item xs={9}>
        <Grid container spacing={1}>
          <Grid item xs={5} className={classes.ruleLabel}>
            Type of Items (Scalable/ non-Scalable)
          </Grid>
          {!config.scalableTypeRule.sameAsEarlier && (
            <>
              <Grid item xs={6} className={classes.dFlexEnd}>
                Use this value in calculation
              </Grid>
              <Grid item xs={1}>
                <SwitchButton
                  className={config.isEditMode && !config.enableScaleRuleEdit ? classes.disableSwitch : ""}
                  size="small"
                  checked={config.scalableTypeRule.active}
                  disabled={config.isEditMode && !config.enableScaleRuleEdit}
                  handleChange={(e: any) => onUseForConfigurationSwitchChange(serviceName, "scalableTypeRule", e)}
                  leftLabel={undefined}
                  rightLabel={undefined}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <ChangeConfigOtion
        config={config}
        checked={config.scalableTypeRule.sameAsEarlier}
        classes={classes}
        checkChangeHandler={(e: any) => onIsSameAsEarlierCheckboxChange(serviceName, "scalableTypeRule", e)}
        onConfigEdit={onScaleRuleConfigEdit}
        changeExisting={config.enableScaleRuleEdit}
        xsValue={4}
      />

      {/* Render dynamic type of items */}
      {config.scalableTypeRule.itemList !== undefined &&
        Array.isArray(config.scalableTypeRule.itemList) &&
        config.scalableTypeRule.itemList.length !== 0 &&
        config.scalableTypeRule.itemList.length > 0 && (
          <Grid item xs={8} className={classes.ruleEditingDivStyle}>
            {config.scalableTypeRule.itemList.map((item: any, index: any) => (
              <Grid key={index} container spacing={1} alignItems="center">
                <Grid item xs={7}>
                  <SelectBox
                    fieldLabel={index === 0 ? "Select a products from catalog" : ""}
                    name="section"
                    label="Product"
                    value={item.section}
                    handleChange={(e: any) => onScalableItemsFieldChange(serviceName, index, e)}
                    menuItems={getUniqueList(config.scalableTypeRule.itemList, item)}
                    disabled={config.isEditMode && !config.enableScaleRuleEdit}
                  />
                </Grid>
                <Grid item xs={3}>
                  <SelectBox
                    fieldLabel={index === 0 ? "Incremental time" : ""}
                    name="incrementalTime"
                    label="Minutes"
                    value={item.incrementalTime}
                    handleChange={(e: any) => onScalableItemsFieldChange(serviceName, index, e)}
                    menuItems={ITEMS_INCREMENTAL_TIME_RANGE}
                    disabled={config.isEditMode && !config.enableScaleRuleEdit}
                  />
                </Grid>
                <Grid item xs={2} style={index === 0 ? { marginTop: "30px" } : {}}>
                  <RemoveButton
                    className={config.isEditMode && !config.enableScaleRuleEdit ? classes.disableCloseBtn : ""}
                    onClick={() => onRemoveScalableItems(serviceName, index)}
                    disabled={config.isEditMode && !config.enableScaleRuleEdit}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
      {config.scalableTypeRule.active && (
        <Grid item xs={12} className={classes.ruleEditingDivStyle}>
          <PrimaryButton buttonVariant="outlined" buttonLabel="Add Another Product" disabled={config.isEditMode && !config.enableScaleRuleEdit} onClick={() => onAddScalableItems(serviceName)} />
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(RenderScalableItems);
