import React from "react";
import { Redirect, Route } from "react-router-dom";
import { canChangeCountrySetting } from "../../components/CountrySettings/CountryAccessControlUtils";
import { canChangeRuleEngineConfig } from "../../components/RuleEnginePage/RuleEngineAccessControl";
import { APP_ROUTES, USER_INFO, USER_TYPES } from "../../config/constants";
import { isEmptyOrNull } from "../../utils/DataUtils";

const getCurrentUser = () => {
  let currentUser: any = localStorage.getItem(USER_INFO);
  currentUser = !isEmptyOrNull(currentUser) ? JSON.parse(currentUser) : currentUser;
  return currentUser;
};

export const isRequiredLogin = () => {
  const currentUser: any = getCurrentUser();
  return !currentUser || !currentUser.token;
};

export const PrivateRoute = ({ component: Component, ...rest }: any) => (
  <Route
    {...rest}
    render={(props) => {
      if (isRequiredLogin()) {
        return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
      }
      return <Component {...props} />;
    }}
  />
);

export const RuleEngineRoute = ({ component: Component, userProfile, ...rest }: any) => (
  <Route
    {...rest}
    render={(props) => {
      if (isRequiredLogin()) {
        return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
      }
      // READONLY_USER and ADMIN not allowed to change Rule engine config
      // if (!canChangeRuleEngineConfig(userProfile) && rest.path === APP_ROUTES.RULE_ENGINE) {
      //   return null;
      // }
      return <Component {...props} />;
    }}
  />
);

export const PosRoute = ({ component: Component, userRole, ...rest }: any) => (
  <Route
    {...rest}
    render={(props) => {
      if (isRequiredLogin()) {
        return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
      }
      // READONLY_USER not allowed to create POS
      if (userRole === USER_TYPES.READONLY_USER && rest.path === APP_ROUTES.CREATE_POS) {
        return null;
      }
      return <Component {...props} />;
    }}
  />
);

export const CountryRoute = ({ component: Component, userProfile, ...rest }: any) => (
  <Route
    {...rest}
    render={(props) => {
      if (isRequiredLogin()) {
        return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
      }
      // READONLY_USER and ADMIN not allowed to change country setting
      if (canChangeCountrySetting(userProfile) && rest.path === APP_ROUTES.RULE_ENGINE) {
        return null;
      }
      return <Component {...props} />;
    }}
  />
);

export const DeliveryFeeRoute = ({ component: Component, userRole, ...rest }: any) => (
  <Route
    {...rest}
    render={(props) => {
      if (isRequiredLogin()) {
        return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
      }
      // READONLY_USER and ADMIN not allowed to access Delivery Fee Page
      // if (userRole === USER_TYPES.READONLY_USER || userRole === USER_TYPES.ADMIN) {
      //   return null;
      // }
      return <Component {...props} />;
    }}
  />
);
