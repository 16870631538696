import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import standardServiceStyles from "../../../ServiceAndSlots/Standard/StandardStyles";
import { STANDARD_SERVICE_CONSTANT, SecondaryStoresInterface } from "../../../ServiceAndSlots/Standard/StandardServiceConstant";
import { renderDynamicRoutingHeading, renderDynamicRoutingValue } from "../../../ServiceAndSlots/common/RenderConfigureSecondarySlotMapping";

interface RenderDynamicRoutingConfigurationInterface {
  tab: any;
}

const RenderDynamicRoutingConfiguration = (props: RenderDynamicRoutingConfigurationInterface) => {
  const classes: any = standardServiceStyles();
  const { tab } = props;

  return (
    <Accordion className={classes.accordionStyle}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryViewStyle}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography className={classes.accordionTitle}>{`${STANDARD_SERVICE_CONSTANT.DYNAMIC_ROUTING}`}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.dFlexStart}>
            <Typography className={classes.accordionHeaderSubTitleStyle}>{`${STANDARD_SERVICE_CONSTANT.DYNAMIC_ROUTING_SUB_HEADING}`}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.subAccordionDetailsStyle}>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12} className={classes.dynamicRoutingDivStyle}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={8} className={classes.dFlexStart}>
                    {tab?.associatedStores?.length > 0 && (
                      <>
                        <Grid item xs={1} className={classes.dynamicRoutingHeaderGridStyle}>
                          <Typography className={classes.dynamicRoutingHeaderSpanStyle}>{`${STANDARD_SERVICE_CONSTANT.RANK || ""}`}</Typography>
                        </Grid>
                        {renderDynamicRoutingHeading(classes, STANDARD_SERVICE_CONSTANT.POS_NO)}
                        <Grid item xs={4} className={classes.dynamicRoutingHeaderGridStyle}>
                          <Typography className={classes.dynamicRoutingHeaderSpanStyle}>{`${STANDARD_SERVICE_CONSTANT.POS_NAME || ""}`}</Typography>
                        </Grid>
                        {renderDynamicRoutingHeading(classes, STANDARD_SERVICE_CONSTANT.ZONE)}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {tab?.associatedStores?.length > 0 ? (
                <Grid item xs={12}>
                  {tab?.associatedStores?.map((element: SecondaryStoresInterface, elementIndex: number) => (
                    <Grid
                      container
                      spacing={0}
                      key={`${elementIndex}-Zone-Service-Type-List-Interface`}
                      className={elementIndex % 2 === 0 ? classes.dynamicRoutingValueEvenGridStyle : classes.dynamicRoutingValueGridStyle}
                    >
                      <Grid item xs={8} className={`${classes.dFlexStart}`}>
                        <Grid item xs={1} className={classes.dynamicRoutingHeaderGridStyle}>
                          <Typography className={classes.dynamicRoutingValueSpanStyle}>{`${elementIndex + 1}`}</Typography>
                        </Grid>
                        {renderDynamicRoutingValue(classes, element.posNo)}
                        <Grid item xs={4} className={classes.dynamicRoutingHeaderGridStyle}>
                          <Typography className={classes.dynamicRoutingValueSpanStyle}>{`${element.posName}`}</Typography>
                        </Grid>
                        {renderDynamicRoutingValue(classes, element.zone)}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography className={classes.dynamicRoutingValueSpanStyle}>{`${STANDARD_SERVICE_CONSTANT.NO_DYNAMIC_ROUTING_PRESENT}`}</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(RenderDynamicRoutingConfiguration);
