import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../../config/theme/theme";

const topBarHeight = "80";

export const topMenuStyles = makeStyles((theme) => ({
  logoName: {
    paddingTop: "8px",
  },
  accountCircle: {
    marginLeft: "8px",
  },
  userDetail: {
    padding: "0px",
    margin: "auto",
    fontWeight: "600",
    fontSize: "12px",
    color: COLORS.TEXT_PRIMARY,
  },
  linesvg: {
    color: "#E6E6E6",
    marginRight: "8px",
    marginLeft: "8px",
  },
  CountryLabel: {
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: "500",
    color: COLORS.TEXT_PRIMARY,
    marginRight: "5px",
    paddingTop: "5px",
  },
  inputRounded: {
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: 600,
    color: COLORS.TEXT_PRIMARY,
    height: "48px",
    paddingLeft: "3px",
    paddingTop: "3px",
    background: COLORS.WHITE,
    minWidth: "90px",
    maxWidth: "90px",
    "& .MuiSelect-icon": {
      top: "auto",
    },
    "&:hover": {
      border: "none",
      borderRadius: "0px",
      background: COLORS.WHITE,

      fontSize: "12px",
      lineHeight: "15px",
      fontWeight: "600",
    },
    "&:focus": {
      border: "none",
      borderRadius: "0px",
      background: COLORS.WHITE,

      fontSize: "12px",
      lineHeight: "15px",
      fontWeight: "600",
    },
    "&>div": {
      paddingRight: "8px !important",
    },
  },
  dropdownMenuStyle: {
    border: "1px solid #0A437C",
    borderRadius: "8px",
    fontSize: "12px",
    fontWeight: 500,
    minWidth: "145px !important",
    maxHeight: "165px",
    marginLeft: "-60px",
    padding: "0px 8px",
    boxShadow: "0px 0px 5px 2px rgba(14, 90, 167, 0.1)",
  },
  // "@global": {
  //   "*::-webkit-scrollbar": {
  //     display: "none",
  //   },
  // },
  countryDivStyle: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: "500",
    color: COLORS.TEXT_PRIMARY,
    margin: 0,
  },
  appBar: {
    width: "100%",
    height: `${topBarHeight}px`,
    backgroundColor: COLORS.WHITE,
    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.05)",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolBar: {
    height: "80px",
    justifyContent: "flex-start",
  },
  userMenu: {
    marginTop: "60px",
    marginLeft: "10px",
  },
  tabsSection: {
    marginRight: "auto",
    marginLeft: "auto",
  },
  tabLabel: {
    margin: "0 12px",
    padding: "0px",
    fontWeight: 500,
    fontSize: "14px",
    color: COLORS.TEXT_PRIMARY,
    lineHeight: "67px",
  },
  indicator: {
    height: "8px",
  },
  countryMenuItemContainer: {
    padding: "6px 12px",
    fontSize: "12px",
    borderBottom: "1px solid #F4F4F4",
  },
  fontSize500: {
    fontWeight: 500,
  },
  countryMenuItem: {
    display: "flex",
    alignItems: "center",
  },
  logoutButton: {
    color: "#0E5AA7",
    cursor: "pointer",
    margin: "auto",
  },
  accountCircleDiv: {
    cursor: "pointer",
  },
  topMenuRightDivStyle: {
    justifyContent: "flex-end",
  },
  inactiveServiceTypeSpanStyle: {
    margin: "auto 0px",
    padding: 0,
    color: COLORS.DARK_RED,
    fontWeight: 500,
    fontSize: "14px",
  },
  inactiveServiceTypeBoldSpanStyle: {
    margin: "auto 2px",
    padding: "0px 2px 0px 2px",
    color: COLORS.DARK_RED,
    fontWeight: 700,
    fontSize: "14px",
    background: COLORS.LIGHT_RED_BACKGROUND,
    borderRadius: "4px",
  },
}));
