import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as ApprovalFlowRequestService from "../../../api/ApprovalFlowRequestService";
import { API_RESPONSE_STATUS } from "../../../config/constants";

export const fetchAllApprovalRequest = createAsyncThunk("approvalRequestConfigurations/fetchAllApprovalRequest", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await ApprovalFlowRequestService.fetchApprovalRequestByStatus(data);
    if (response !== undefined && response.status !== undefined && response.status === "OK") {
      return response?.data || [];
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchOneApprovalRequestRecordBasedOnReuestId = createAsyncThunk("approvalRequestConfigurations/fetchOneApprovalRequestRecordBasedOnReuestId", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await ApprovalFlowRequestService.fetchOneApprovalRequestRecordBasedOnReuestId(data);
    if (response !== undefined && response.status !== undefined && response.status === "OK") {
      return response?.data || [];
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const generateApprovalRequestService = createAsyncThunk("approvalRequestConfigurations/generateApprovalRequestService", async (data: any, { rejectWithValue }) => {
  try {
    const payload: any = await ApprovalFlowRequestService.generateApprovalRequestAPI(data);
    return payload;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchPendingApprovalRequest = createAsyncThunk("approvalRequestConfigurations/fetchPendingApprovalRequest", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await ApprovalFlowRequestService.fetchApprovalRequestByStatus(data);
    if (response !== undefined && response.status !== undefined && response.status === "OK") {
      return response?.data || [];
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getPOSNoAndNameForFilterPopUp = createAsyncThunk("approvalRequestConfigurations/getPOSNoAndNameForFilterPopUp", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await ApprovalFlowRequestService.fetchPOSNoAndNameForFilterPopUp(data);
    if (response !== undefined && response.status !== undefined && response.status === "OK") {
      return response?.data || [];
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getApprovalRequestRecordBasedOnHeaderFilter = createAsyncThunk("approvalRequestConfigurations/getApprovalRequestRecordBasedOnHeaderFilter", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await ApprovalFlowRequestService.fetchApprovalRequestRecordBasedOnHeaderFilter(data);
    if (response !== undefined && response.status !== undefined && response.status === "OK") {
      return response?.data || [];
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

const commonFetchInitialState = {
  fetchStatus: API_RESPONSE_STATUS.IDLE,
  error: "",
  data: [] as any,
  loading: false,
};

const initialState = {
  approvalRequestRecordDetailsService: { ...commonFetchInitialState },
  approvalRecordDetailsService: { ...commonFetchInitialState },
  approvalRequestActionByApproval: { ...commonFetchInitialState },
  pendingApprovalRequest: { ...commonFetchInitialState },
  posNoAndNameRecordService: { ...commonFetchInitialState },
  allApprovalRequestRecordDetailsService: { ...commonFetchInitialState },
  selectedApprovalRequestRecordService: { ...commonFetchInitialState },
};

const approvalRequestSlice = createSlice({
  name: "approvalRequestConfigurations",
  initialState,
  reducers: {
    storeRecordDataReducer: (state: any, record: any): any => {
      if (record !== undefined && record.payload !== undefined && record.payload.requestId !== undefined && record.payload.requestId !== "") {
        state.selectedApprovalRequestRecordService = { ...commonFetchInitialState };
        state.selectedApprovalRequestRecordService.data = record.payload;
      } else {
        state.selectedApprovalRequestRecordService = { ...commonFetchInitialState };
      }
    },
  },
  extraReducers: (builders) => {
    builders
      /* Get all teh records */
      .addCase(fetchAllApprovalRequest.pending, (state: any) => {
        state.approvalRequestRecordDetailsService.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.approvalRequestRecordDetailsService.data = [];
        state.approvalRequestRecordDetailsService.error = "";
      })
      .addCase(fetchAllApprovalRequest.fulfilled, (state: any, action: any) => {
        state.approvalRequestRecordDetailsService.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.approvalRequestRecordDetailsService.data = action.payload;
      })
      .addCase(fetchAllApprovalRequest.rejected, (state: any, action: any) => {
        state.approvalRequestRecordDetailsService.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.approvalRequestRecordDetailsService.error = action.error.message;
      })
      /* Get all teh records */
      .addCase(fetchOneApprovalRequestRecordBasedOnReuestId.pending, (state: any) => {
        state.approvalRecordDetailsService.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.approvalRecordDetailsService.data = [];
        state.approvalRecordDetailsService.error = "";
      })
      .addCase(fetchOneApprovalRequestRecordBasedOnReuestId.fulfilled, (state: any, action: any) => {
        state.approvalRecordDetailsService.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.approvalRecordDetailsService.data = action.payload;
      })
      .addCase(fetchOneApprovalRequestRecordBasedOnReuestId.rejected, (state: any, action: any) => {
        state.approvalRecordDetailsService.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.approvalRecordDetailsService.error = action.error.message;
      })
      /* generateApprovalRequestService */
      .addCase(generateApprovalRequestService.pending, (state: any) => {
        state.approvalRequestActionByApproval.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.approvalRequestActionByApproval.data = [];
        state.approvalRequestActionByApproval.error = "";
      })
      .addCase(generateApprovalRequestService.fulfilled, (state: any, action: any) => {
        state.approvalRequestActionByApproval.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.approvalRequestActionByApproval.data = action.payload;
      })
      .addCase(generateApprovalRequestService.rejected, (state: any, action: any) => {
        state.approvalRequestActionByApproval.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.approvalRequestActionByApproval.error = action.error.message;
      })
      /* fetchPendingApprovalRequest */
      .addCase(fetchPendingApprovalRequest.pending, (state: any) => {
        state.pendingApprovalRequest.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.pendingApprovalRequest.data = [];
        state.pendingApprovalRequest.error = "";
      })
      .addCase(fetchPendingApprovalRequest.fulfilled, (state: any, action: any) => {
        state.pendingApprovalRequest.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.pendingApprovalRequest.data = action.payload;
      })
      .addCase(fetchPendingApprovalRequest.rejected, (state: any, action: any) => {
        state.pendingApprovalRequest.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.pendingApprovalRequest.error = action.error.message;
      })
      /*getPOSNoAndNameForFilterPopUp */
      .addCase(getPOSNoAndNameForFilterPopUp.pending, (state: any) => {
        state.posNoAndNameRecordService.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.posNoAndNameRecordService.data = [];
        state.posNoAndNameRecordService.error = "";
      })
      .addCase(getPOSNoAndNameForFilterPopUp.fulfilled, (state: any, action: any) => {
        state.posNoAndNameRecordService.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.posNoAndNameRecordService.data = action.payload;
      })
      .addCase(getPOSNoAndNameForFilterPopUp.rejected, (state: any, action: any) => {
        state.posNoAndNameRecordService.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.posNoAndNameRecordService.error = action.error.message;
      })
      /** getApprovalRequestRecordBasedOnHeaderFilter */
      .addCase(getApprovalRequestRecordBasedOnHeaderFilter.pending, (state: any) => {
        state.allApprovalRequestRecordDetailsService.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.allApprovalRequestRecordDetailsService.data = [];
        state.allApprovalRequestRecordDetailsService.error = "";
      })
      .addCase(getApprovalRequestRecordBasedOnHeaderFilter.fulfilled, (state: any, action: any) => {
        state.allApprovalRequestRecordDetailsService.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.allApprovalRequestRecordDetailsService.data = action.payload;
      })
      .addCase(getApprovalRequestRecordBasedOnHeaderFilter.rejected, (state: any, action: any) => {
        state.allApprovalRequestRecordDetailsService.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.allApprovalRequestRecordDetailsService.error = action.error.message;
      });
  },
});

export default approvalRequestSlice.reducer;

export const { storeRecordDataReducer } = approvalRequestSlice.actions;

export const selectAllApprovalRequestRecordListServices = (state: any) => state.approvalRequestConfigurations.approvalRequestRecordDetailsService;
export const selectApprovalRecordBasedOnRequestIdServices = (state: any) => state.approvalRequestConfigurations.approvalRecordDetailsService;
export const selectAllPendingApprovalRequestRecordListServices = (state: any) => state.approvalRequestConfigurations.pendingApprovalRequest;
export const selectAllApprovalRequestListServiceBasedOnFilter = (state: any) => state.approvalRequestConfigurations.allApprovalRequestRecordDetailsService;
export const fetchSelectedApprovalRequestRecord = (state: any) => state.approvalRequestConfigurations.selectedApprovalRequestRecordService;
