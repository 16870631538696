import React, { useState } from "react";

import moment from "moment";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { ReactComponent as EditIcon } from "../../../../assets/svg/Pencil.svg";
import { ReactComponent as DeleteSlotIcon } from "../../../../assets/svg/DeleteIcon.svg";

import TextBox from "../../../../common/TextBox";
import TimePicker from "../../../../common/TimePicker/TimePicker";
import LinkButton from "../../../../common/LinkButton/LinkButton";

// Styles
import styles from "./OnDemandSlotInEditModeStyles";
import CustomAlert from "../../../../common/CustomAlert/CustomAlert";
import { ALERT_TYPES } from "../../../../../config/constants";
import { check_if_slot_obj_contains_sub_set } from "../../../../../utils/ApplicationUtils";
import { is_user_creating_updating_pos } from "../../Utils/ExpressAccessUtils";

const OnDemandSlotInEditMode = ({
  userInfo,
  tabIndex,
  formIndex,
  slotIndex,
  slot,
  handleOnDemandServiceTimeChange,
  handleIndividualSlotFieldsChange,
  loading,
  canSlotBeDeleted,
  configurationBulkUpdatedObj,
  generatedSlots,
  makeSlotEditable,
  saveNewOnDemandSlot,
  updateOnDemandSlot,
  handleDeleteTemplateSlot,
  cancelEditingASlot,
}) => {
  const classes = styles();
  const isNewSlot = !slot.id;

  const handleSaveSlotSubmit = () => {
    if (slot.slotCapacity === "") {
      CustomAlert(ALERT_TYPES.ERROR, "Please enter capacity for the selected slot");
      return;
    } else if (slot.endTime === null || slot.endTime === "" || !moment(slot.endTime).isValid()) {
      CustomAlert(ALERT_TYPES.ERROR, "Please enter valid slot end time");
      return;
    } else if (slot.startTime === null || slot.startTime === "" || !moment(slot.startTime).isValid()) {
      CustomAlert(ALERT_TYPES.ERROR, "Please enter valid slot start time");
      return;
    } else if (moment(slot.startTime).isSame(moment(slot.endTime))) {
      CustomAlert(ALERT_TYPES.ERROR, "Start time cannot be same as End time");
      return;
    } else if (moment(slot.startTime).isAfter(moment(slot.endTime))) {
      CustomAlert(ALERT_TYPES.ERROR, "Start time cannot be before End time");
      return;
    }

    const is_slot_obj_valid = check_if_slot_obj_contains_sub_set(generatedSlots);
    if (is_slot_obj_valid !== undefined && typeof is_slot_obj_valid === "object" && Object.keys(is_slot_obj_valid).length > 0) {
      CustomAlert(ALERT_TYPES.ERROR, is_slot_obj_valid[Object.keys(is_slot_obj_valid)[0]]);
      return;
    }

    if (!slot.id) {
      saveNewOnDemandSlot(tabIndex, formIndex, slotIndex, slot);
    } else {
      updateOnDemandSlot(tabIndex, formIndex, slotIndex, slot);
    }
  };

  return (
    <Grid container spacing={1} alignItems="center" className={classes.slotsDetailsContainer} key={`${slotIndex}-${tabIndex}-OnDemandSlotInEditMode-${slot?.id || ""}`}>
      <Grid item xs={4}>
        <TimePicker value={slot.startTime} handleChange={(date) => handleOnDemandServiceTimeChange(tabIndex, formIndex, slotIndex, date, "startTime")} disabled={!slot.canBeUpdated && !isNewSlot} />
      </Grid>
      <Grid item xs={4}>
        <TimePicker value={slot.endTime} handleChange={(date) => handleOnDemandServiceTimeChange(tabIndex, formIndex, slotIndex, date, "endTime")} disabled={!slot.canBeUpdated && !isNewSlot} />
      </Grid>
      <Grid item xs={2}>
        <TextBox
          type="number"
          name="slotCapacity"
          value={slot.slotCapacity}
          handleChange={(event) => handleIndividualSlotFieldsChange(tabIndex, formIndex, slotIndex, event)}
          disabled={!slot.canBeUpdated && !isNewSlot}
          placeholderText="Enter Capacity"
          isNegativeAllowed={false}
        />
      </Grid>
      <Grid item xs={2}>
        <Grid container spacing={1} alignItems="center">
          {is_user_creating_updating_pos(userInfo) ? (
            isNewSlot ? (
              <>
                {configurationBulkUpdatedObj !== undefined &&
                  configurationBulkUpdatedObj.bulkUpdatedDays !== undefined &&
                  Array.isArray(configurationBulkUpdatedObj.bulkUpdatedDays) &&
                  configurationBulkUpdatedObj.bulkUpdatedDays.length == 0 && (
                    <Grid item xs={4}>
                      <LinkButton className={classes.updateSlotButton} buttonLabel={loading ? "Saving..." : "Save"} onClick={handleSaveSlotSubmit} disabled={loading} />
                    </Grid>
                  )}
                <Grid item xs={2}>
                  <LinkButton buttonLabel="Cancel" onClick={() => handleDeleteTemplateSlot(slot)} />
                </Grid>
              </>
            ) : (
              <>
                {configurationBulkUpdatedObj !== undefined &&
                configurationBulkUpdatedObj.bulkUpdatedDays !== undefined &&
                Array.isArray(configurationBulkUpdatedObj.bulkUpdatedDays) &&
                configurationBulkUpdatedObj.bulkUpdatedDays.length == 0 ? (
                  <>
                    {slot.canBeUpdated ? (
                      <Grid item xs={10}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item xs={5}>
                            <LinkButton
                              className={classes.updateSlotButton}
                              buttonLabel={loading ? "Saving..." : "Save"}
                              disabled={loading || !slot.startTime || !slot.endTime}
                              onClick={handleSaveSlotSubmit}
                            />
                          </Grid>
                          <Grid item xs={5}>
                            <LinkButton className={classes.updateSlotButton} buttonLabel="Cancel" onClick={() => cancelEditingASlot(slot)} />
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item xs={6}>
                        <EditIcon className={classes.iconButton} onClick={() => makeSlotEditable(slot)} />
                      </Grid>
                    )}
                    {canSlotBeDeleted && !slot.canBeUpdated && (
                      <Grid item xs={2}>
                        {loading ? <></> : <DeleteSlotIcon className={classes.iconButton} onClick={() => handleDeleteTemplateSlot(slot)} />}
                      </Grid>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            )
          ) : !slot.canBeUpdated && !isNewSlot ? (
            <Grid item xs={2}>
              <EditIcon className={classes.iconButton} onClick={() => makeSlotEditable(slot)} />
            </Grid>
          ) : (
            <DeleteSlotIcon className={classes.iconButton} onClick={() => handleDeleteTemplateSlot(slot)} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OnDemandSlotInEditMode;
