import React from "react";
import moment from "moment";

import ExpandMoreIcon from "../../assets/svg/ExpandAccordionIcon.svg";

import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@material-ui/core";
import { TEXT_CONSTANT } from "../../ServiceAndSlots/DefaultService/DefaultInterface";
import { isUndefined } from "../../../utils/DataUtils";

interface RenderDefaultServiceSlotsProps {
  dayWiseSlots: any;
  classes: any;
}

export const RenderDefaultServiceSlots = (props: RenderDefaultServiceSlotsProps) => {
  const { dayWiseSlots, classes } = props;
  return (
    <div>
      {dayWiseSlots &&
        Object.keys(dayWiseSlots).map((dayWiseSlot) => (
          <Accordion key={dayWiseSlot} className={classes.slotsAccordion} onClick={(e) => e.stopPropagation()}>
            <AccordionSummary expandIcon={<img src={ExpandMoreIcon} alt="Expand Accordion Icon" />} className={classes.slotsAccordionSummary}>
              <div className={classes.fieldTitle}>
                <span className={classes.fontWeight500}>Slots for : </span>
                <span className={classes.dayLabel}>{`${dayWiseSlot.toLowerCase()}`}</span>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.dBlock}>
              <Grid container>
                <Grid item xs={8}>
                  <Grid container spacing={2} className={classes.slotsContainer}>
                    <Grid item xs={3} className={classes.slotsTableHeader}>
                      {`${TEXT_CONSTANT.MINIMUM_DAYS}`}
                    </Grid>
                    <Grid item xs={3} className={classes.slotsTableHeader}>
                      {`${TEXT_CONSTANT.MAXIMUM_DAYS}`}
                    </Grid>
                    <Grid item xs={3} className={classes.slotsTableHeader}>
                      {`${TEXT_CONSTANT.CUT_OFF}`}
                    </Grid>
                  </Grid>

                  {dayWiseSlots[dayWiseSlot]?.map((slot: any, index: number) => (
                    <div key={slot.id} className={classes.slotDivider} style={{ fontSize: "12px" }}>
                      <Grid container spacing={2} className={classes.slotsContainer}>
                        <Grid item xs={3}>{`${!isUndefined(slot?.minDay) ? Number(slot?.minDay) : ""}`}</Grid>
                        <Grid item xs={3}>{`${!isUndefined(slot?.maxDay) ? Number(slot?.maxDay) : ""}`}</Grid>
                        <Grid item xs={3}>{`${slot?.cutOff ? moment(slot?.cutOff).format(TEXT_CONSTANT.GET_DATE_FORMAT) : ""}`}</Grid>
                      </Grid>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
};
