import lodashGet from "lodash.get";
import moment from "moment";
import { DAYS_DROPDOWN_OPTIONS, HH_MM_SS, PHARMACY } from "../../../config/constants";
import { convertHoursToMinutes, getMaxNumberFromAnArray } from "../../../utils/helperFunctions";
import { productTypesToBeSent } from "./SlotServicesUtils";
import { isNull, isUndefined } from "../../../utils/DataUtils";

/**
 *
 * Function to calculate available days for selection in the dropdown
 */
export const calculateAvailableDaysForSelection = (formIndex, tab, objectKey) => {
  let remainingDays = [...DAYS_DROPDOWN_OPTIONS];
  if (formIndex !== 0) {
    let daysSelectedSoFar = [];
    if (objectKey) {
      for (let i = 0; i < formIndex; i++) {
        daysSelectedSoFar = [...daysSelectedSoFar, ...tab.slotsGeneratorForms[objectKey][i].days];
      }
    } else {
      for (let i = 0; i < formIndex; i++) {
        daysSelectedSoFar = [...daysSelectedSoFar, ...tab.slotsGeneratorForms[i].days];
      }
    }
    remainingDays = DAYS_DROPDOWN_OPTIONS.filter((day) => !daysSelectedSoFar.includes(day.id));
  }
  return remainingDays;
};

/** Utility function to generate slots */
export const generateSlots = ({ slotDurationHours, slotDurationMinutes, bufferTimeHours, bufferTimeMinutes, serviceStartTime, serviceEndTime, defaultSlotCapacity }) => {
  const slotDurationInMinutes = slotDurationHours * 60 + slotDurationMinutes;
  const bufferTimeInMinutes = bufferTimeHours * 60 + bufferTimeMinutes;
  const totalServiceTimeInMinutes = (new Date(serviceEndTime) - new Date(serviceStartTime)) / 1000 / 60;
  if (slotDurationInMinutes === 0) return [];
  const numberOfPossibleSlots = Math.ceil(totalServiceTimeInMinutes / slotDurationInMinutes);
  let mostRecentSlotEndTime = "";

  const calculatedSlots = [];

  for (let i = 0; i < numberOfPossibleSlots; i++) {
    let isLastSlot = i + 1 === numberOfPossibleSlots;
    // let isLastSlotEligible =
    //   (new Date(serviceEndTime) - new Date(mostRecentSlotEndTime)) / 1000 / 60 >= (50 * slotDurationInMinutes) / 100;
    let isLastSlotEligible = new Date(serviceEndTime) - new Date(mostRecentSlotEndTime);
    let slotStartTime = moment(serviceStartTime).add(i * slotDurationInMinutes, "m");
    let slotEndTime = isLastSlot && isLastSlotEligible ? moment(serviceEndTime) : moment(serviceStartTime).add((i + 1) * slotDurationInMinutes, "m");

    // if (isLastSlot && !isLastSlotEligible) continue;

    let slot = {
      time: `${slotStartTime.format("HH:mm")} - ${slotEndTime.format("HH:mm")}`,
      startTime: slotStartTime,
      endTime: slotEndTime,
      slotCapacity: defaultSlotCapacity,
      bufferTimeHours: bufferTimeHours,
      bufferTimeMinutes: bufferTimeMinutes,
      bufferTime: bufferTimeInMinutes,
      orderCutOff: moment(slotStartTime).subtract(bufferTimeInMinutes, "m").format("HH:mm"),
      status: true,
      canBeUpdated: true,
    };
    mostRecentSlotEndTime = slotEndTime;

    calculatedSlots.push(slot);
  }

  return calculatedSlots;
};

/**
 *
 * @param {Object} zones
 * Utitlity to create an array of all zone numbers
 */
export const getAllZoneNumbers = (zones = []) => {
  let allZoneNumbers = [];

  for (let i = 0; i < zones.length; i++) {
    const element = parseInt(zones[i].zoneName.split("Zone")[1]);
    allZoneNumbers.push(Number(element));
  }

  return allZoneNumbers;
};

/**
 * Utility to create the next zone name while adding zone configuraitons
 */
export const createNextZoneName = (zones = []) => {
  const allZoneNumbers = getAllZoneNumbers(zones);
  const maxZoneNumber = getMaxNumberFromAnArray(allZoneNumbers);
  return `Zone${(maxZoneNumber + 1).toString().padStart(2, 0)}`;
};

/**
 *
 * @param {Object} tab Zone tab config object
 * Helper to create a request payload for zone basic configurations like zoneName, serviceCutOffTime, slotOptimizations...
 */
const createZoneConfigPayload = (tab, serviceMappingId) => {
  let zoneConfig = {
    id: tab.zoneId,
    zoneName: tab.zoneName,
    serviceCutOffTime: tab.serviceCutOffTime ? moment(tab.serviceCutOffTime).format(HH_MM_SS) : tab.serviceCutOffTime,
    showFirstSlotAt: tab.showFirstSlotAt ? moment(tab.showFirstSlotAt).format(HH_MM_SS) : tab.showFirstSlotAt,
    serviceTypeMappingId: serviceMappingId,
    capacityThreshold: tab.slotOptimizationEnabled ? tab.capacityThreshold : "",
    timeThreshold: tab.slotOptimizationEnabled ? convertHoursToMinutes(tab.timeThreshold) : "",
    status: tab.status || "",
  };

  if (tab && tab.state && tab.state !== "") {
    zoneConfig.state = tab.state;
  }
  return zoneConfig;
};

/**
 *
 * @param {Object} tab
 * Function to create template slots payload while saving/updating service configurations day wise
 */
const createTemplateSlotsPayload = (tab, posId) => {
  let templateSlotsPayload = [];
  tab.slotsGeneratorForms.forEach((form) => {
    let days = form.days;
    let generatedSlots = form.generatedSlots;
    days.forEach((day) => {
      let newSlots = generatedSlots.map((s) => ({
        id: s.id || "",
        posId: posId,
        day,
        startTime: moment(s.startTime).format(HH_MM_SS),
        endTime: moment(s.endTime).format(HH_MM_SS),
        slotCapacity: s.slotCapacity,
        bufferTime: s.bufferTimeHours * 60 + s.bufferTimeMinutes || 0,
        isQuotaEnabled: s.isQuotaEnabled,
        zoneId: tab.zoneId,
        status: s.status,
        createdAt: s.createdAt || "",
      }));
      templateSlotsPayload = [...templateSlotsPayload, ...newSlots];
    });
  });
  return templateSlotsPayload;
};

/**
 *
 * @param {Object} tab zone tab configuration
 * Helper to create quota settings payload for each day of the week
 */
const createQuotaSettingsPayload = (tab) => {
  let quotaSettingsPayload = [];

  tab.slotsGeneratorForms.forEach((form) => {
    let days = form.days;
    let isQuotaSettingsApplied = form.quotas ? !!form.quotas.length : false;
    if (isQuotaSettingsApplied) {
      let quotas = form.quotas.map((quota) => ({
        ...quota,
        quotaCutOffTime: convertHoursToMinutes(quota.quotaCutOffTime),
      }));

      days.forEach((day) => {
        let quotaSettingsByDay = { day, quotaDetails: quotas };

        quotaSettingsPayload = [...quotaSettingsPayload, quotaSettingsByDay];
      });
    }
  });

  return quotaSettingsPayload;
};

/**
 *
 * @param {Objce} zoneData
 * @param {number} serviceTypeId
 * Utility to create service config payload for creating zone based configurations and for creating template slots
 */
export const createServiceConfigPayload = (zoneData, serviceTypeId, posDetails) => {
  const serviceTypeMappingId = posDetails.serviceType?.filter((s) => s.serviceTypeId === serviceTypeId)[0].id;
  const isPosTypePharmacy = lodashGet(posDetails, "posType", "") === PHARMACY;

  let requestPayload = {
    foodTypeMapping: { serviceTypeId, ...productTypesToBeSent(zoneData.foodTypes, zoneData.foodSubType, isPosTypePharmacy) },
    zoneConfig: createZoneConfigPayload(zoneData, serviceTypeMappingId),
    templateSlots: createTemplateSlotsPayload(zoneData, posDetails.id),
    selectedAreas: zoneData.selectedAreas || [],
    quotaSetting: createQuotaSettingsPayload(zoneData),
  };
  return requestPayload;
};

export const calculateAvailableDaysForSelectionDefaultService = (slotDetailsData) => {
  let remainingDays = [...DAYS_DROPDOWN_OPTIONS];
  if (slotDetailsData) {
    let daysSelectedSoFar = [];
    Object.keys(slotDetailsData).forEach((day) => {
      daysSelectedSoFar = [...daysSelectedSoFar, day];
    });
    remainingDays = DAYS_DROPDOWN_OPTIONS.filter((day) => !daysSelectedSoFar.includes(day.id));
  }
  return remainingDays;
};

/**
 *
 * @param {Object} tab Zone tab config object
 * Helper to create a request payload for zone basic configurations like zoneName, serviceCutOffTime, slotOptimizations...
 */
const createZoneConfigPayloadForStandard = (tab, serviceMappingId) => {
  let zone = {
    zoneConfig: { id: tab.zoneId, zoneName: tab.zoneName, serviceTypeMappingId: serviceMappingId, status: tab.status || "" },
    bufferCapacityConfig: { active: false, capacityUtilized: "", reducedBufferTime: "", maxCapacity: "" },
  };
  zone.bufferCapacityConfig.active = tab.bufferCapacityConfig.active;
  if (!isUndefined(tab?.bufferCapacityConfig?.id) && tab?.bufferCapacityConfig?.id !== "") {
    zone.bufferCapacityConfig.id = tab.bufferCapacityConfig.id;
  }
  zone.bufferCapacityConfig.capacityUtilized =
    !isUndefined(tab.bufferCapacityConfig.capacityUtilized) && !isNull(tab.bufferCapacityConfig.capacityUtilized) ? Number(tab.bufferCapacityConfig.capacityUtilized) : "";
  zone.bufferCapacityConfig.reducedBufferTime =
    !isUndefined(tab.bufferCapacityConfig.reducedBufferTime) && !isNull(tab.bufferCapacityConfig.reducedBufferTime) ? Number(tab.bufferCapacityConfig.reducedBufferTime) : "";
  zone.bufferCapacityConfig.maxCapacity = !isUndefined(tab.bufferCapacityConfig.maxCapacity) && !isNull(tab.bufferCapacityConfig.maxCapacity) ? Number(tab.bufferCapacityConfig.maxCapacity) : "";
  return zone;
};

/**
 *
 * @param {Objce} zoneData
 * @param {number} serviceTypeId
 * Utility to create service config payload for creating zone based configurations and for creating template slots
 */
export const createServiceConfigPayloadForStandard = (zoneData, serviceTypeId, posDetails) => {
  const serviceTypeMappingId = posDetails.serviceType?.filter((s) => s.serviceTypeId === serviceTypeId)[0].id;
  const isPosTypePharmacy = lodashGet(posDetails, "posType", "") === PHARMACY;
  let requestPayload = {
    foodTypeMapping: { serviceTypeId, ...productTypesToBeSent(zoneData.foodTypes, zoneData.foodSubType, isPosTypePharmacy) },
    zone: createZoneConfigPayloadForStandard(zoneData, serviceTypeMappingId),
    templateSlots: createTemplateSlotsPayload(zoneData, posDetails.id),
    selectedAreas: zoneData.selectedAreas || [],
  };
  return requestPayload;
};
