import React from "react";

import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { ServiceActiveFlagViewUtils } from "../CountrySettingView";
import { SERVICE_TYPES } from "../../../config/constants";

interface RenderFBCTypeDetailsInterface {
  classes: any;
  fbcDetails: any;
  handleServiceTypeActiveChange: any;
}

const RenderFBCTypeDetails = (props: RenderFBCTypeDetailsInterface) => {
  const { classes, fbcDetails, handleServiceTypeActiveChange } = props;

  return (
    <Card className={classes.serviceTypeCardForEmptyFBCServiceTypeCard}>
      <CardContent>
        <Grid container justifyContent="flex-start" spacing={2}>
          <ServiceActiveFlagViewUtils
            classes={classes}
            serviceTypeName={SERVICE_TYPES.FBC}
            serviceActiveFlag={fbcDetails.serviceActive}
            isDisabled={true}
            handleServiceTypeActiveChange={handleServiceTypeActiveChange}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default React.memo(RenderFBCTypeDetails);
