import React from "react";

import { Table, TableContainer } from "@material-ui/core";

import CustomTableFooter from "../../common/CustomTableFooter/CustomTableFooter";
import PlolygonServicesCardHeader from "./PlolygonServicesCardHeader";
import PlolygonServicesCardBody from "./PlolygonServicesCardBody";

interface PlolygonServicesCardSectionInterface {
  classes: any;
  isCountHeaderVisible: boolean;
  rowsData: any;
  handle_make_row_editable: any;
  mapp_pos_zone_for_polygon: any;
  currentCountry: any;
  userInfo: any;
  appStateObj: any;
  fetchNextRecord: any;
  appCountStateObj: any;
  handleFilterPopupOpen: any;
  handleFilterPopupClose: any;
  appFilterPopUpObj: any;
  headerRowCheckboxOnChange: any;
  handlePosZoneSearch: any;
  handlePosZoneSearchTextChange: any;
  handleResetPopUpOnClick: any;
  handleApplyPopUpOnClick: any;
  //
  hanlePolygonDetailsDrawerOpen: any;
}

const PlolygonServicesCardSection = (props: PlolygonServicesCardSectionInterface) => {
  const {
    classes,
    isCountHeaderVisible,
    rowsData,
    handle_make_row_editable,
    mapp_pos_zone_for_polygon,
    currentCountry,
    userInfo,
    appStateObj,
    fetchNextRecord,
    appCountStateObj,
    handleFilterPopupOpen,
    handleFilterPopupClose,
    appFilterPopUpObj,
    headerRowCheckboxOnChange,
    handlePosZoneSearch,
    handlePosZoneSearchTextChange,
    handleResetPopUpOnClick,
    handleApplyPopUpOnClick,
    //
    hanlePolygonDetailsDrawerOpen,
  } = props;

  return (
    <div className={isCountHeaderVisible ? classes.cardRootWithCountHeaderSection : classes.cardRoot}>
      <TableContainer className={isCountHeaderVisible ? classes.tableContainerStyleWithCountHeaderSection : classes.tableContainerStyle}>
        <Table padding="none" size="small" stickyHeader={true}>
          <PlolygonServicesCardHeader
            classes={classes}
            rowsData={rowsData}
            userInfo={userInfo}
            handleFilterPopupOpen={handleFilterPopupOpen}
            handleFilterPopupClose={handleFilterPopupClose}
            appFilterPopUpObj={appFilterPopUpObj}
            headerRowCheckboxOnChange={headerRowCheckboxOnChange}
            handlePosZoneSearch={handlePosZoneSearch}
            handlePosZoneSearchTextChange={handlePosZoneSearchTextChange}
            currentCountry={currentCountry}
            handleResetPopUpOnClick={handleResetPopUpOnClick}
            handleApplyPopUpOnClick={handleApplyPopUpOnClick}
          />
          <PlolygonServicesCardBody
            classes={classes}
            rowsData={rowsData}
            handle_make_row_editable={handle_make_row_editable}
            mapp_pos_zone_for_polygon={mapp_pos_zone_for_polygon}
            currentCountry={currentCountry}
            userInfo={userInfo}
            appCountStateObj={appCountStateObj}
            appFilterPopUpObj={appFilterPopUpObj}
            //
            hanlePolygonDetailsDrawerOpen={hanlePolygonDetailsDrawerOpen}
            appStateObj={appStateObj}
          />
          {rowsData && Array.isArray(rowsData) && rowsData.length > 0 && (
            <CustomTableFooter
              rowsData={rowsData}
              classes={classes}
              colSpan={6}
              pageSize={appStateObj.size || 40}
              rowCount={appStateObj.maxRecordPresent || 0}
              page={Number(appStateObj.page || 1) - 1}
              fetchNextRecord={fetchNextRecord}
            />
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

export default React.memo(PlolygonServicesCardSection);
