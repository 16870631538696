import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchPOSDetails,
  fetchStandardTemplateSlots,
  fetchStandardTemplateSlotsVersionThree,
  fetchTemplateSlots,
  toggleCollectionPoint,
  toggleServiceOfPOS,
  toggleZoneOfServiceOfPOS,
  toggleZoneServiceableState,
} from "../../../api/POSService";
import { downloadServiceConfigurationsReport } from "../../../api/ReportService";
import { ALL_DAYS_VALUES, REPORT_TYPES, SERVICE_TYPES, SLOT_STATUSES, ZONE_CHANGE_STATUS } from "../../../config/constants";
import { groupClickAndCollectResponseByCollectionPoints, groupDefaultResponseBySupplierType } from "../../ServiceAndSlots/redux/templateSlotsSlice";
import { getKeyNameFromZoneTab } from "../../../utils/ServiceTypeUtils";

export const fetchPOSBasicInformation = createAsyncThunk("completeSetup/fetchPOSDetails", async (posNo, { getState, rejectWithValue }) => {
  try {
    const {
      configurations: {
        serviceTypes: { data: fetchedServiceTypes },
      },
    } = getState();
    const {
      response: { body },
    } = await fetchPOSDetails(posNo);
    let transformedResponse = {
      ...body,
      services: [...new Set(body.serviceType.reduce((acc, type) => [...acc, fetchedServiceTypes.filter((s) => s.id === type.serviceTypeId)[0]], []))].sort((a, b) => a.id - b.id),
    };
    return transformedResponse;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchTemplateSlotsByServiceType = createAsyncThunk("completeSetup/fetchTemplateSlots", async ({ posNo, serviceType, serviceTypeName }, { rejectWithValue }) => {
  try {
    const {
      response: { data },
    } = await fetchTemplateSlots(posNo, { serviceType });
    let transformedResponse = {};
    if (serviceTypeName === "FBC") {
      transformedResponse = {
        ...data,
        slots: [],
      };
    } else {
      if (data && data.slots) {
        if (serviceTypeName === SERVICE_TYPES.CLICK_N_COLLECT) {
          transformedResponse = {
            ...data,
            slots: data.slots.map((r) => {
              return groupClickAndCollectResponseByCollectionPoints(r);
            }),
          };
          return transformedResponse;
        } else if (serviceTypeName === SERVICE_TYPES.DEFAULT) {
          transformedResponse = {
            ...data,
            slots: data.slots.map((r) => {
              return groupDefaultResponseBySupplierType(r);
            }),
          };
          return transformedResponse;
        } else {
          transformedResponse = {
            ...data,
            slots: data.slots.map((r) => ({
              ...r,
              dayWiseSlots: ALL_DAYS_VALUES.reduce(
                (acc, day) => ({
                  ...acc,
                  [day]: r.templateSlots.filter((slot) => slot.day.toLowerCase() === day.toLowerCase()),
                }),
                {}
              ),
              capacityAmountAtZoneLevel: r.templateSlots.reduce((a, b) => a + b.slotCapacity, 0),
            })),
          };
          if (transformedResponse !== undefined && transformedResponse.slots !== undefined) {
            transformedResponse.slots.forEach((slotValue) => {
              if (slotValue !== undefined && slotValue.dayWiseSlots !== undefined) {
                Object.keys(slotValue.dayWiseSlots).forEach((dayValue) => {
                  if (slotValue.dayWiseSlots[dayValue] !== undefined && Array.isArray(slotValue.dayWiseSlots[dayValue]) && slotValue.dayWiseSlots[dayValue].length > 0) {
                    let maximum_slot_capacity = slotValue.dayWiseSlots[dayValue].reduce((a, b) => a + b.slotCapacity, 0);
                    slotValue.dayWiseSlots[dayValue].forEach((daydata) => {
                      daydata.maximum_slot_capacity = maximum_slot_capacity;
                    });
                  }
                });
              }
            });
          }
        }
      }
    }
    return transformedResponse;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const getReportType = (serviceTypeName) => {
  let reportType = REPORT_TYPES.STANDARD_CONFIG;
  switch (serviceTypeName) {
    case SERVICE_TYPES.STANDARD:
      reportType = REPORT_TYPES.STANDARD_CONFIG;
      break;

    case SERVICE_TYPES.EXPRESS:
      reportType = REPORT_TYPES.EXPRESS_CONFIG;
      break;

    case SERVICE_TYPES.CLICK_N_COLLECT:
      reportType = REPORT_TYPES.CNC_CONFIG;
      break;
    default:
      reportType = REPORT_TYPES.STANDARD_CONFIG;
      break;
  }

  return reportType;
};

export const activateOrDeactivateAServiceOfPOS = createAsyncThunk("service/toggleServiceStatus", async (payloadRequest, { rejectWithValue }) => {
  const { posNo, serviceTypeName, active } = payloadRequest;
  try {
    const { response } = await toggleServiceOfPOS(posNo, { active, serviceType: serviceTypeName });
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const activateDeactivateCollectionPoint = createAsyncThunk("cnc/toggleStatus", async ({ serviceTypeName, collectionPointId, zoneId, active }, { rejectWithValue }) => {
  try {
    const { response } = await toggleCollectionPoint(collectionPointId, { toggleFlag: active });
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const downloadServiceConfigCSV = createAsyncThunk("config/downloadServiceConfigCSV", async ({ serviceTypeName, posNo }, { _, rejectWithValue }) => {
  try {
    let reportType = getReportType(serviceTypeName);
    const body = { type: reportType, parameterMap: { posNo } };
    const { response, fileName } = await downloadServiceConfigurationsReport(body);
    return { csvData: response, fileName };
  } catch (error) {
    return rejectWithValue(error);
  }
});

// Utils
const getArgsFromActionAction = (action) => {
  const {
    meta: {
      arg: { serviceTypeName },
    },
    payload,
  } = action;

  return { serviceTypeName, payload };
};

const getAllArgsForZoneStatusChangeAction = (action) => {
  const {
    meta: {
      arg: { serviceTypeName, tabIndex, zoneId, activstatus },
    },
    payload,
  } = action;

  return { serviceTypeName, tabIndex, zoneId, activstatus, payload };
};

export const activateOrDeactivateAServiceZoneOfPOS = createAsyncThunk("service/activateOrDeactivateAServiceZoneOfPOS", async (payloadRequest, { rejectWithValue }) => {
  const { posNo, serviceType, serviceTypeName, activstatus, tabIndex, zoneId, checked } = payloadRequest;
  try {
    const { response } = await toggleZoneOfServiceOfPOS(posNo, { serviceType, serviceTypeName, activstatus, tabIndex, zoneId, checked });
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchTemplateSlotsForStandard = createAsyncThunk("completeSetup/fetchStandardTemplateSlots", async ({ posNo, serviceType, serviceTypeName }, { rejectWithValue }) => {
  try {
    const {
      response: { data },
    } = await fetchStandardTemplateSlotsVersionThree(posNo, { serviceType });
    let transformedResponse = {};
    transformedResponse = {
      ...data,
      slots: data.slots.map((r) => ({
        ...r,
        dayWiseSlots: ALL_DAYS_VALUES.reduce(
          (acc, day) => ({
            ...acc,
            [day]: r.templateSlots.filter((slot) => slot.day.toLowerCase() === day.toLowerCase()),
          }),
          {}
        ),
        capacityAmountAtZoneLevel: r.templateSlots.reduce((a, b) => a + b.slotCapacity, 0),
      })),
    };
    if (transformedResponse !== undefined && transformedResponse.slots !== undefined) {
      transformedResponse.slots.forEach((slotValue) => {
        if (slotValue !== undefined && slotValue.dayWiseSlots !== undefined) {
          Object.keys(slotValue.dayWiseSlots).forEach((dayValue) => {
            if (slotValue.dayWiseSlots[dayValue] !== undefined && Array.isArray(slotValue.dayWiseSlots[dayValue]) && slotValue.dayWiseSlots[dayValue].length > 0) {
              let maximum_slot_capacity = slotValue.dayWiseSlots[dayValue].reduce((a, b) => a + b.slotCapacity, 0);
              slotValue.dayWiseSlots[dayValue].forEach((daydata) => {
                daydata.maximum_slot_capacity = maximum_slot_capacity;
              });
            }
          });
        }
      });
    }
    return transformedResponse;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const getAllArgsForZoneOrderStateChangeAction = (action) => {
  const {
    meta: {
      arg: { serviceTypeName, tabIndex, zoneId, orderState },
    },
    payload,
  } = action;
  return { serviceTypeName, tabIndex, zoneId, orderState, payload };
};

export const updateZoneStateForOrders = createAsyncThunk("service/updateZoneStateForOrders", async (payloadRequest, { rejectWithValue }) => {
  const { posNo, serviceType, serviceTypeName, tabIndex, zoneId, orderState, payload } = payloadRequest;
  try {
    const { response } = await toggleZoneServiceableState({ zoneId, payload });
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const initialState = {
  fetchingBasicDetails: true,
  basicDetails: {},
  fetchingBasicDetailsError: "",
  fetchingSlots: false,
  slots: {},
};

const completeSetupSlice = createSlice({
  name: "completeSetup",
  initialState,
  reducers: {
    resetCompleteSetupStore: (state) => {
      return initialState;
    },
  },
  extraReducers: (builders) => {
    builders
      .addCase(fetchPOSBasicInformation.pending, (state) => {
        state.fetchingBasicDetails = true;
        state.fetchingBasicDetailsError = "";
      })
      .addCase(fetchPOSBasicInformation.fulfilled, (state, action) => {
        state.fetchingBasicDetails = false;
        state.basicDetails = action.payload;
      })
      .addCase(fetchPOSBasicInformation.rejected, (state, action) => {
        state.basicDetails = {};
        state.fetchingBasicDetails = false;
        state.fetchingBasicDetailsError = action.payload.error;
      })
      .addCase(fetchTemplateSlotsByServiceType.pending, (state, action) => {
        const { serviceTypeName } = getArgsFromActionAction(action);
        state.slots[serviceTypeName] = { loading: true };
      })
      .addCase(fetchTemplateSlotsByServiceType.fulfilled, (state, action) => {
        const { serviceTypeName, payload } = getArgsFromActionAction(action);
        state.slots[serviceTypeName] = payload;
      })
      .addCase(fetchTemplateSlotsByServiceType.rejected, (state, action) => {
        const { serviceTypeName, payload } = getArgsFromActionAction(action);
        state.slots[serviceTypeName] = { error: payload.message, errorCode: payload.error_code };
      })
      .addCase(activateOrDeactivateAServiceOfPOS.pending, (state, action) => {
        const { serviceTypeName } = getArgsFromActionAction(action);
        state.slots[serviceTypeName] = { ...state.slots[serviceTypeName], toggling: true };
      })
      .addCase(activateOrDeactivateAServiceOfPOS.fulfilled, (state, action) => {
        const {
          meta: {
            arg: { serviceTypeName, active },
          },
          payload,
        } = action;
        const {
          body: { status },
        } = payload;
        state.slots[serviceTypeName] = {
          ...state.slots[serviceTypeName],
          toggling: false,
          active: status[serviceTypeName] === SLOT_STATUSES.ACTIVATED,
        };
        if (active === false) {
          state.slots[serviceTypeName].slots.forEach((slot) => {
            if (slot !== undefined && slot.zoneConfig !== undefined && slot.zoneConfig.status !== undefined) {
              slot.zoneConfig.status = ZONE_CHANGE_STATUS.DEACTIVATED;
            } else if (slot !== undefined && slot.zone !== undefined && slot.zone.zoneConfig !== undefined && slot.zone.zoneConfig.status !== undefined) {
              slot.zone.zoneConfig.status = ZONE_CHANGE_STATUS.DEACTIVATED;
            }
          });
        }
        if (serviceTypeName === SERVICE_TYPES.CLICK_N_COLLECT && !active) {
          const collectionPoints = state.slots[serviceTypeName].slots[0].collectionPoints;
          Object.keys(collectionPoints).forEach((cp) => {
            collectionPoints[cp].collectionPointDetails.active = false;
          });
        }
      })
      .addCase(activateOrDeactivateAServiceOfPOS.rejected, (state, action) => {
        const { serviceTypeName } = getArgsFromActionAction(action);
        state.slots[serviceTypeName] = {
          ...state.slots[serviceTypeName],
          toggling: false,
          error: action.payload.message,
        };
      })
      .addCase(activateDeactivateCollectionPoint.fulfilled, (state, action) => {
        const {
          meta: {
            arg: { serviceTypeName, zoneId, collectionPointId, active },
          },
        } = action;
        const {
          payload: {
            data: { serviceMap },
          },
        } = action;
        const zoneIndex = state.slots[serviceTypeName].slots.findIndex((s) => getKeyNameFromZoneTab(s, "id") === zoneId);
        const cpName = state.slots[serviceTypeName].slots[zoneIndex].templateSlots.find((slot) => slot.clickAndCollect.collectionPointId === collectionPointId).clickAndCollect.displayName;
        state.slots[serviceTypeName].active = serviceMap[serviceTypeName];
        state.slots[serviceTypeName].slots[zoneIndex].collectionPoints[cpName].collectionPointDetails.active = active;
      })
      .addCase(activateOrDeactivateAServiceZoneOfPOS.pending, (state, action) => {
        const { serviceTypeName } = getArgsFromActionAction(action);
        state.slots[serviceTypeName] = { ...state.slots[serviceTypeName], toggling: true };
      })
      .addCase(activateOrDeactivateAServiceZoneOfPOS.fulfilled, (state, action) => {
        const { serviceTypeName, zoneId, activstatus } = getAllArgsForZoneStatusChangeAction(action);
        state.slots[serviceTypeName] = { ...state.slots[serviceTypeName], toggling: false };
        const zoneIndex = state.slots[serviceTypeName].slots.findIndex((s) => getKeyNameFromZoneTab(s, "id") === zoneId);
        if (action !== undefined && action.payload !== undefined && action.payload.data !== undefined && action.payload.data.success !== undefined) {
          if (action.payload.data.success === true) {
            if (state !== undefined && state.slots !== undefined && state.slots[serviceTypeName] !== undefined && state.slots[serviceTypeName].slots !== undefined) {
              if (
                state.slots[serviceTypeName].slots[zoneIndex] !== undefined &&
                state.slots[serviceTypeName].slots[zoneIndex].zoneConfig !== undefined &&
                state.slots[serviceTypeName].slots[zoneIndex].zoneConfig.status !== undefined
              ) {
                state.slots[serviceTypeName].slots[zoneIndex].zoneConfig.status = activstatus;
              } else if (
                state.slots[serviceTypeName].slots[zoneIndex] !== undefined &&
                state.slots[serviceTypeName].slots[zoneIndex].zone !== undefined &&
                state.slots[serviceTypeName].slots[zoneIndex].zone.zoneConfig !== undefined &&
                state.slots[serviceTypeName].slots[zoneIndex].zone.zoneConfig.status !== undefined
              ) {
                state.slots[serviceTypeName].slots[zoneIndex].zone.zoneConfig.status = activstatus;
              }
            }

            if (activstatus === ZONE_CHANGE_STATUS.ACTIVATED) {
              state.slots[serviceTypeName].active = true;
            } else {
              let inactive_zone = 0;
              state.slots[serviceTypeName].slots.forEach((slot) => {
                if (getKeyNameFromZoneTab(slot, "status") !== ZONE_CHANGE_STATUS.ACTIVATED) {
                  inactive_zone++;
                }
              });
              if (inactive_zone === state.slots[serviceTypeName].slots.length) {
                state.slots[serviceTypeName].active = false;
              }
            }
          }
        }
      })
      .addCase(activateOrDeactivateAServiceZoneOfPOS.rejected, (state, action) => {
        const { serviceTypeName } = getArgsFromActionAction(action);
        state.slots[serviceTypeName] = { ...state.slots[serviceTypeName], toggling: false, error: action.payload.message };
      })
      /** fetchTemplateSlotsForStandard */
      .addCase(fetchTemplateSlotsForStandard.pending, (state, action) => {
        const { serviceTypeName } = getArgsFromActionAction(action);
        state.slots[serviceTypeName] = { loading: true };
      })
      .addCase(fetchTemplateSlotsForStandard.fulfilled, (state, action) => {
        const { serviceTypeName, payload } = getArgsFromActionAction(action);
        state.slots[serviceTypeName] = payload;
      })
      .addCase(fetchTemplateSlotsForStandard.rejected, (state, action) => {
        const { serviceTypeName, payload } = getArgsFromActionAction(action);
        state.slots[serviceTypeName] = { error: payload.message, errorCode: payload.error_code };
      })
      /* updateZoneStateForOrders */
      .addCase(updateZoneStateForOrders.pending, (state, action) => {
        const { serviceTypeName } = getArgsFromActionAction(action);
        state.slots[serviceTypeName] = { ...state.slots[serviceTypeName], toggling: true };
      })
      .addCase(updateZoneStateForOrders.fulfilled, (state, action) => {
        const { serviceTypeName, zoneId, orderState } = getAllArgsForZoneOrderStateChangeAction(action);
        state.slots[serviceTypeName] = { ...state.slots[serviceTypeName], toggling: false };
        const zoneIndex = state.slots[serviceTypeName].slots.findIndex((s) => getKeyNameFromZoneTab(s, "id") === zoneId);
        if (action && action.payload && action.payload.data) {
          if (state && state.slots && state.slots[serviceTypeName] && state.slots[serviceTypeName].slots) {
            if (state.slots[serviceTypeName].slots[zoneIndex] && state.slots[serviceTypeName].slots[zoneIndex].zoneConfig && state.slots[serviceTypeName].slots[zoneIndex].zoneConfig.state) {
              state.slots[serviceTypeName].slots[zoneIndex].zoneConfig.state = orderState;
            } else if (
              state.slots[serviceTypeName].slots[zoneIndex] &&
              state.slots[serviceTypeName].slots[zoneIndex].zone &&
              state.slots[serviceTypeName].slots[zoneIndex].zone.zoneConfig &&
              state.slots[serviceTypeName].slots[zoneIndex].zone.zoneConfig.state
            ) {
              state.slots[serviceTypeName].slots[zoneIndex].zone.zoneConfig.state = orderState;
            }
          }
        }
      })
      .addCase(updateZoneStateForOrders.rejected, (state, action) => {
        const { serviceTypeName } = getArgsFromActionAction(action);
        state.slots[serviceTypeName] = { ...state.slots[serviceTypeName], toggling: false, error: action.payload.message };
      });
  },
});

export const { resetCompleteSetupStore } = completeSetupSlice.actions;

export default completeSetupSlice.reducer;
