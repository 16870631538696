import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../config/theme/theme";

export const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      border: "none",
      "& .MuiOutlinedInput-input": {
        fontFamily: "Montserrat",
        fontSize: "14px",
      },
      "& .MuiInputBase-formControl": {
        backgroundColor: COLORS.WHITE,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #DCDCDC",
        top: "0px",
      },
      "& .MuiOutlinedInput-root.Mui-disabled": {
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #E6E6E6",
          top: "0px",
          cursor: "default",
        },
      },
      "& .open": {
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #DCDCDC",
          borderRadius: "4px",
        },
      },
      "& .MuiFormHelperText-contained": {
        fontSize: "12px",
      },
    },
    inputStyles: {
      width: "100%",
      height: "40px",
      border: "none",
      background: "#FFFFFF",
    },
    inputlabelStyles: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "0px",
      color: COLORS.TEXT_PRIMARY,
      top: "auto",
      "&.Mui-focused": {
        color: COLORS.TEXT_PRIMARY,
        top: "0px",
        background: COLORS.WHITE,
        padding: "0px 4px",
        lineHeight: "16px",
      },
      "&.MuiInputLabel-shrink": {
        color: COLORS.TEXT_PRIMARY,
        top: "0px",
        background: COLORS.WHITE,
        padding: "0px 4px",
        lineHeight: "16px",
      },
    },
    textAreainputStyles: {
      width: "100%",
      height: "auto",
      border: "none",
      background: "#FFFFFF",
    },
  })
);
