import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../config/theme/theme";

export const loginStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    card: {
      fontSize: "15px",
      width: "736px",
      height: "364px",
      backgroundColor: COLORS.WHITE,
      borderRadius: "8px",
      border: "1px solid #0A437C",
      boxShadow: "0px 0px 10px 2px rgba(14, 90, 167, 0.1)",
    },
    mainContainer: {
      background: COLORS.PRIMARY_MAIN,
      minHeight: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    heading: {
      display: "flex",
      justifyContent: "center",
      marginTop: 58,
    },
    linesvg: {
      color: COLORS.SECONDARY_DARK,
      marginRight: "1em",
      marginLeft: "1em",
    },
    bottomLogoSVG: {
      height: "40px",
    },
    logoName: {
      paddingTop: "8px",
    },
    logoFooter: {
      width: "86%",
      height: "50px",
    },
    division: {
      margin: "66px",
    },
    content: {
      marginTop: "50px",
    },
    loginText: {
      color: "#2D2D2D",
      fontWeight: 600,
      fontSize: "24px",
      marginTop: "50px",
      marginBottom: "18px",
    },
    fields: {
      paddingTop: "24px",
    },
    credentials: {
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "17px",
      marginBottom: "12px",
    },
    text: {
      background: COLORS.WHITE,
      borderRadius: "8px",
      marginTop: "12px",
      width: "100%",
    },
    helperText: {
      marginLeft: "0px",
      marginRight: "0px",
    },
    buttonSection: {
      marginTop: "24px",
    },
    loginItem: {
      padding: theme.spacing(1.5, 0),
    },
    loginButton: {
      minWidth: "86%",
      marginBottom: "78px",
    },
    loginDivRoot: {
      margin: 0,
      padding: "0px 53px 0px 0px",
    },
  })
);
