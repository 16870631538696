import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../../../config/theme/theme";

const basicServiceDetailsStyles = makeStyles(() => ({
  cards: {
    minWidth: 275,
    marginTop: "16px",
    background: COLORS.WHITE,
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
  },
  detailsContainer: {
    marginTop: "10px",
  },
  cardTitle: {
    fontWeight: 600,
    fontSize: "20px",
    textTransform: "capitalize",
  },
  posDetailLabel: {
    fontSize: "14px",
    fontWeight: 500,
  },
  posDetailData: {
    marginTop: "8px",
    fontSize: "14px",
    fontWeight: 600,
  },
  accordionStyle: {
    marginTop: "12px",
    background: COLORS.WHITE,
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
  },
  accordionSummaryStyle: {
    padding: "8px 24px",
  },
  accordionDetailsStyle: {
    display: "block",
    padding: "0px 24px 16px 24px",
  },
}));

export default basicServiceDetailsStyles;
