import { makeStyles } from "@material-ui/core";

const completeSetupStyles = makeStyles(() => ({
  completeSetup: {
    marginTop: "80px",
    padding: "24px 12px 24px 24px",
  },
  title: {
    fontWeight: 600,
  },
  footerButton: {
    margin: "16px 0px",
  },
  linkstyle: {
    textDecoration: "none",
  },
  footerButtonsStyle: {
    margin: 0,
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default completeSetupStyles;
