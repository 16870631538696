import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import LinkButton from "components/common/LinkButton/LinkButton";
import ShowFirstSlotAndServiceCutOffTime from "components/ServiceAndSlots/common/ShowFirstSlotAndServiceCuttOff/ShowFirstSlotAndServiceCuttOff";

import { is_first_slot_service_cut_off_visible, is_slot_optimization_visible } from "../StandardAccessUtils";
import CapacityOptimization from "../CapacityOptimization/CapacityOptimization";

interface RenderConfigureSlotsAccordionInterface {
  classes: any;
  userInfo: any;
  configTab: any;
  configTabIndex: any;
  handleSwitchButton: any;
  handleServiceCutOffAndShowSlotAtTime: any;
  handleSlotOptimizationFields: any;
  isAddOtherDaysButtonDisabled: any;
  addOtherDays: any;
  renderSlotsGeneratorForm: any;
  handleCapacityOptimizationInputsChange: any;
}

const RenderConfigureSlotsAccordion = (props: RenderConfigureSlotsAccordionInterface) => {
  const {
    classes,
    userInfo,
    configTab,
    configTabIndex,
    handleSwitchButton,
    handleServiceCutOffAndShowSlotAtTime,
    handleSlotOptimizationFields,
    isAddOtherDaysButtonDisabled,
    addOtherDays,
    renderSlotsGeneratorForm,
    handleCapacityOptimizationInputsChange,
  } = props;
  const { showFirstSlotAtOption, showFirstSlotAt, showServiceCutOffTimeOption, serviceCutOffTime, capacityOptimizationFlag, bufferCapacityConfig } = configTab;

  return (
    <Accordion className={classes.accordionStyle}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryStyle}>
        <Grid container alignItems="center">
          <Grid>
            <Typography className={classes.accordionTitle}>Slots</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetailsStyle}>
        <Grid container spacing={1} alignItems="center">
          {!configTab.zoneId && (
            <Grid item xs={12} className={classes.addOtherDays}>
              <LinkButton buttonLabel={"Add Other Days"} disabled={isAddOtherDaysButtonDisabled(configTabIndex)} onClick={(e: any) => addOtherDays(configTabIndex, e)} />
            </Grid>
          )}
        </Grid>
        <div>
          {configTab.slotsGeneratorForms.map((slotGeneratorForm: any, generatorFormIndex: any) => (
            <div key={generatorFormIndex}>{renderSlotsGeneratorForm(configTab, configTabIndex, generatorFormIndex)}</div>
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(RenderConfigureSlotsAccordion);
