import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../config/theme/theme";

export const configurationStyle = makeStyles(() =>
  createStyles({
    container: {
      marginTop: "80px",
      padding: "24px",
      width: "auto",
      height: "auto",
      background: "rgb(249, 249, 249)",
    },
    heading: {
      fontWeight: 600,
      fontSize: "24px",
      margin: "auto 0px",
    },
    configButtonDiv: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "24px 0px 0px 0px",
    },
    cancelButtonStyle: {
      width: "212px",
      background: "#FFFFFF",
      border: "1px solid #0E5AA7",
      borderRadius: "4px",
      color: "#0E5AA7",
      textDecoration: "none",
      marginRight: "24px",
      "&:hover": {
        background: "#FFFFFF",
        border: "1px solid #0E5AA7",
      },
      "&.MuiButton-label": {
        background: "#FFFFFF",
        border: "1px solid #0E5AA7",
        "&:hover": {
          background: "#FFFFFF",
          border: "1px solid #0E5AA7",
        },
      },
    },
    editButtonStyle: {
      width: "300px",
      background: "#0E5AA7",
      border: "1px solid #0E5AA7",
      borderRadius: "4px",
      color: "#FFFFFF",
      textDecoration: "none",
      "&:hover": {
        background: "#0E5AA7",
        border: "1px solid #0E5AA7",
      },
      "&.MuiButton-label": {
        background: "#0E5AA7",
        border: "1px solid #0E5AA7",
        "&:hover": {
          background: "#0E5AA7",
          border: "1px solid #0E5AA7",
        },
      },
    },
    // mainContent Start
    previewServiceDivStyle: {
      margin: 0,
      padding: "12px 0px 12px 0px",
      border: "none",
      borderBottom: "1px solid #C3C3C3",
      "&:last-child": {
        border: "none",
      },
    },
    ServiceTypeMainDivStyle: {
      margin: 0,
      padding: 0,
    },
    accordionStyle: {
      margin: 0,
      padding: 0,
      background: COLORS.WHITE,
      border: "1px solid #C3C3C3",
      borderRadius: "4px",
      marginTop: "12px",
      "&:last-child": {
        padding: 0,
        margin: 0,
        marginTop: "12px",
      },
    },
    accordionSummaryStyle: {
      padding: "12px 24px",
    },
    accordionTitle: {
      fontSize: "18px",
      fontWeight: 600,
    },
    accordionDetailsStyle: {
      borderTop: "1px solid #C3C3C3",
      display: "block",
      padding: "0px",
    },
    previewAccordionDetailsStyle: {
      borderTop: "1px solid #C3C3C3",
      display: "block",
      padding: "12px",
    },
    dlvFeeServiceTypeTextStyle: {
      margin: "auto",
      padding: 0,
      color: COLORS.BLACK,
      fontSize: "18px",
      fontWeight: 600,
    },
    dlvFeeValueDisplayDivStyle: {
      margin: 0,
      padding: 0,
    },
    dlvFeeValueDisplayTitleStyle: {
      fontFamily: "Montserrat",
      fontWeight: 400,
      color: "#979797",
      fontSize: "12px",
      lineHeight: "20px",
    },
    dlvFeeValueDisplayDataStyle: {
      fontFamily: "Montserrat",
      fontWeight: 500,
      color: "#141414",
      fontSize: "14px",
      lineHeight: "20px",
    },
    // AdditionalDeliveryFeeConfigList start
    additionDlvFeeMainCard: {
      margin: 0,
      padding: 0,
      width: "100%",
      height: "auto",
      boxShadow: "none",
      background: COLORS.DOS_BLUE_SUPER_LIGHT,
      border: "none",
      borderRadius: "4px",
    },
    additionDlvFeeMainTitleDivStyle: {
      margin: 0,
      padding: 0,
    },
    additionDlvFeeMainTitleStyle: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#141414",
    },
    additionDlvFeeInnerCardStyle: {
      margin: 0,
      padding: 0,
      width: "100%",
      height: "auto",
      boxShadow: "none",
      background: "none",
    },
    additionDlvFeeInnerRowHeaderStyle: {
      margin: 0,
      padding: 0,
      width: "100%",
      height: "auto",
      boxShadow: "none",
    },
    additionDlvFeeInnerTitleDivStyle: {
      margin: 0,
      padding: "12px 0px 0px 0px",
      width: "100%",
      height: "auto",
    },
    additionDlvFeeInnerTitleTextStyle: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#2D2D2D",
    },
    additionDlvFeeInnerRowDivStyle: {
      margin: 0,
      marginTop: "auto",
      marginBottom: "auto",
      padding: 0,
      paddingRight: "12px",
    },
    tableDataRowHeaderCell: {
      margin: "auto 0",
      padding: 0,
      paddingLeft: "12px",
      height: "36px",
      border: "1px solid #E6E6E6",
      background: "#FFFFFF",
      "&:last-child": {
        padding: 0,
        paddingLeft: "12px",
      },
    },
    tableDataHeaderCell: {
      margin: "auto 0",
      fontFamily: "Montserrat",
      fontWeight: 400,
      color: "#979797",
      fontSize: "12px",
      lineHeight: "20px",
    },
    tableDataRowCell: {
      margin: "auto 0",
      padding: 0,
      paddingLeft: "12px",
      height: "40px",
      fontSize: "12px",
      fontWeight: 400,
      color: "#2D2D2D",
      border: "1px solid #E6E6E6",
      background: "#FFFFFF",
      "&:last-child": {
        padding: 0,
        paddingLeft: "12px",
      },
    },
    previewServiceDeliveryFeeDivStyle: {
      margin: "0px 0px 16px 0px",
      padding: "8px 12px",
      background: COLORS.DOS_BLUE_SUPER_LIGHT,
    },
    previewServiceTitleDivStyle: {
      margin: 0,
      padding: "12px 0px 12px 0px",
    },
    previewServiceTitleDivSpanStyle: {
      margin: "auto",
      padding: 0,
      fontSize: "18px",
      fontWeight: 600,
      color: COLORS.MINE_SHAFT_LIGHT,
    },
    additionConfigDlvFeeMainCard: {
      margin: "16px 0px 0px 0px",
      padding: 0,
      width: "100%",
      height: "auto",
      boxShadow: "none",
      background: COLORS.DOS_BLUE_SUPER_LIGHT,
      border: "none",
      borderRadius: "4px",
    },
    additionDlvFeeTitleItemDivStyle: {
      margin: 0,
      padding: "0px 0px 8px 0px",
    },
    additionDlvFeeInnerRowItemStyle: {
      margin: "0px 0px 8px 0px",
      padding: 0,
      width: "100%",
      height: "auto",
      boxShadow: "none",
    },
  })
);
