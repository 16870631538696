import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "config/theme/theme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootIcon: {
      width: 24,
      height: 24,
      verticalAlign: "middle",
      cursor: "pointer",
      marginLeft: theme.spacing(1),
      marginTop: "2px",
    },
    closeIcon: {
      width: 15,
      height: 15,
      verticalAlign: "middle",
      cursor: "pointer",
    },
    paperProps: {
      minWidth: 350,
      padding: "16px",
      marginTop: "8px",
      border: `1px solid`,
      borderColor: COLORS.WILDSAND,
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
      borderRadius: "12px",
      overflow: "hidden",
    },
    popoverFilter: {
      maxHeight: 500,
    },
    headerContainer: {
      margin: "0px 0px 8px 0px",
      padding: 0,
      flexWrap: "nowrap",
      justifyContent: "space-between",
      alignItems: "center",
    },
    buttonsContainer: {
      flexWrap: "nowrap",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    mainContainer: {
      margin: "0px 0px 16px 0px",
      padding: 0,
      minHeight: 300,
      maxHeight: 300,
      display: "block",
      overflow: "auto",
    },
    headerContainerSpanStyle: {
      fontWeight: 600,
      fontSize: "14px",
      color: COLORS.TEXT_PRIMARY,
    },
    button: {
      width: "100%",
      margin: 0,
      padding: 0,
      borderRadius: "12px",
      "&:last-child": {
        marginLeft: "16px",
      },
    },
    resetButtonStyle: {
      border: "1px solid",
      borderColor: COLORS.DOS_BLUE_DARK,
      color: COLORS.DOS_BLUE_DARK,
      width: "91px",
      height: "40px",
      "&:hover": {
        background: COLORS.WHITE,
      },
    },
    applyButtonStyle: {
      border: "1px solid",
      borderColor: COLORS.DOS_BLUE_DARK,
      background: COLORS.DOS_BLUE_DARK,
      color: COLORS.WHITE,
      width: "91px",
      height: "40px",
      "&:hover": {
        background: COLORS.DOS_BLUE_DARK,
      },
    },
    loaderDiv: {
      height: "auto",
    },
    /**
     * Main
     */
    searchBoxGridDiv: {
      margin: 0,
      padding: "0px 0px 12px 0px",
    },
    listMainGridStyle: {
      margin: "auto",
      padding: "0px",
      width: "auto",
      height: "36px",
    },
    listItemGridStyle: {
      margin: "auto 0px",
      padding: "0px",
      "&.MuiGrid-item": {
        padding: "0px 0px 0px 8px",
      },
    },
    listItemSpanStyle: {
      fontWeight: 500,
      fontSize: "14px",
      color: COLORS.MINE_SHAFT_LIGHT,
    },
    /**
     * No Data
     */
    noDataRowStyle: {
      margin: "45px 0px 0px 0px",
      width: "auto",
      height: "200px",
      verticalAlign: "middle",
      textAlign: "center",
      borderRadius: "8px",
      border: "none",
      boxShadow: "none",
    },
    datePickerIcon: {
      border: "none",
      background: "transparent",
      width: "24px",
      cursor: "pointer",
      height: "24px",
      marginTop: "2px",
      marginLeft: "8px",
      verticalAlign: "middle",
    },
  })
);
