import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { Card } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import completeSetupStyles from "./CompleteSetupStyles";

import { APP_ROUTES, BUTTON_TEXT_CONSTANT, CURRENT_COUNTRY, NEW_PATH_SELECTED, SERVICE_TYPES } from "../../config/constants";
import { selectServiceTypes } from "../../config/redux/configurationsSlice";
import { isObjectEmpty } from "../../utils/helperFunctions";
import { selectUserDetails } from "../Login/redux/userSlice";
import { fetchPOSBasicInformation, fetchTemplateSlotsByServiceType, fetchTemplateSlotsForStandard, resetCompleteSetupStore } from "./redux/completeSetupSlice";

import PageTitle from "../common/PageTitle/PageTitle";
import PrimaryButton from "../common/PrimaryButton/PrimaryButton";
import BasicInformation from "./BasicInformation/BasicInformation";
import FBCInformation from "./FBC/FBCInformation";
import SlotsInformation from "./SlotsInformation/SlotsInformation";
import { getCurrentUrl } from "../../utils/ApplicationUtils";

const CompleteSetup = () => {
  const { posNo } = useParams();

  const classes = completeSetupStyles();
  const dispatch = useDispatch();

  const { currentCountry } = useSelector((state) => state.appConfig);
  const { fetchingBasicDetails, basicDetails, slots } = useSelector((state) => state.completeSetup);
  const serviceTypes = useSelector(selectServiceTypes);
  const userInfo = useSelector(selectUserDetails);

  const [countryName, setCountryName] = useState();
  const [enableServiceType, setEnableServiceType] = useState([]);

  useEffect(() => {
    let storedCountry = localStorage.getItem(CURRENT_COUNTRY) ? JSON.parse(localStorage.getItem(CURRENT_COUNTRY)) : undefined;
    const currentLocation = getCurrentUrl();
    if (storedCountry) {
      setCountryName(storedCountry.name);
    }
    if (currentLocation !== undefined && currentLocation !== "") {
      localStorage.setItem(NEW_PATH_SELECTED, currentLocation);
    }
  }, []);

  useEffect(() => {
    if (basicDetails && basicDetails.services && basicDetails.services.length > 0) {
      let serviceType = basicDetails.services.filter((item) => userInfo?.userProfile?.serviceTypeId.includes(item.id));
      setEnableServiceType(serviceType);
    }
  }, [basicDetails]);

  useEffect(() => {
    return () => dispatch(resetCompleteSetupStore());
  }, [dispatch]);

  useEffect(() => {
    if (serviceTypes.length) {
      dispatch(fetchPOSBasicInformation(posNo));
    }
  }, [dispatch, posNo, serviceTypes]);

  useEffect(() => {
    if (!fetchingBasicDetails && enableServiceType && basicDetails.posNo) {
      enableServiceType.forEach((s) => {
        if (s !== undefined && s.name !== undefined && s.name !== "" && s.name === SERVICE_TYPES.STANDARD) {
          dispatch(fetchTemplateSlotsForStandard({ posNo, serviceType: s.id, serviceTypeName: s.name }));
        } else {
          dispatch(fetchTemplateSlotsByServiceType({ posNo, serviceType: s.id, serviceTypeName: s.name }));
        }
      });
    }
  }, [dispatch, enableServiceType, fetchingBasicDetails, posNo]);

  return (
    <div className={classes.completeSetup}>
      <PageTitle title={`POS(${posNo})`} />
      {(!basicDetails.posNo && fetchingBasicDetails) || basicDetails.country == countryName ? (
        <>
          <BasicInformation loading={fetchingBasicDetails} posDetails={basicDetails} userInfo={userInfo} />
          {!isObjectEmpty(slots) &&
            !isObjectEmpty(basicDetails) &&
            enableServiceType.map((s) =>
              s.name === "FBC" ? (
                <FBCInformation key={s.id} posNo={basicDetails.posNo} userInfo={userInfo} serviceTypeName={s.name} serviceTypeId={s.id} slotsInformation={slots[s.name]} slots={slots} />
              ) : (
                <SlotsInformation key={s.id} userInfo={userInfo} posNo={basicDetails.posNo} serviceTypeName={s.name} serviceTypeId={s.id} slotsInformation={slots[s.name]} />
              )
            )}
        </>
      ) : (
        <>
          <Card style={{ padding: "10px", margin: "10px" }}>
            <Grid container style={{ marginLeft: "20%", fontWeight: "600", color: "red" }}>
              Pos - {posNo}, does not exist in
              {basicDetails.posNo == posNo
                ? `selected country - ${currentCountry !== undefined && currentCountry !== "" && currentCountry.name !== undefined && currentCountry.name !== "" ? currentCountry.name : "Country"}.`
                : `DOS.`}
            </Grid>
          </Card>
        </>
      )}

      <Grid container direction="row" className={classes.footerButton}>
        <Grid item xs={6}>
          <Grid container spacing={1} justifyContent="flex-start">
            <Grid item className={classes.footerButtonsStyle}>
              <Link to={`${APP_ROUTES.POS_LIST}`} className={classes.linkstyle}>
                <PrimaryButton buttonLabel={`${BUTTON_TEXT_CONSTANT.BACK_TO_POS_LIST}`} buttonVariant="outlined" disabled={false} />
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1} justifyContent="flex-end"></Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CompleteSetup;
