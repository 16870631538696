import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      "& .MuiOutlinedInput-input": {
        padding: "8px 14px 5px 14px",
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: 500,
        borderWidth: 1,
        borderRadius: theme.spacing(1),
        cursor: "pointer",
        "&:focus": {
          outline: "none",
        },
      },
      "&.hasLabel": {
        "& .MuiOutlinedInput-input": {
          paddingTop: "12px",
        },
      },
    },
    datePickerIcon: {
      padding: 0,
      width: 24,
      height: 24,
      cursor: "pointer",
      border: "none",
      verticalAlign: "middle",
      backgroundColor: "transparent",
      marginLeft: theme.spacing(1),
      "&:focus": {
        outline: "none",
      },
      "&.addMargin": {
        marginLeft: theme.spacing(0.5),
      },
    },
  })
);
