import Fetch from "./Fetch";

import { API_HOST_DUMMY_URL } from "../config/constants";
import { handleError, handleResponse } from "./ApiUtils";

export const fetchApprovalRequestByStatus = (data) => {
  return Fetch.get(`${API_HOST_DUMMY_URL}/approval/requests/${data.countryId}?status=${data.status}`).then(handleResponse).catch(handleError);
};

export const fetchOneApprovalRequestRecordBasedOnReuestId = (data) => {
  return Fetch.get(`${API_HOST_DUMMY_URL}/approval/template-slots/${data.requestedId}`).then(handleResponse).catch(handleError);
};

export const generateApprovalRequestAPI = (data) => {
  return Fetch.create(`${API_HOST_DUMMY_URL}/approval/request/${data.requestedId}?action=${data.action}`, data.payload).then(handleResponse).catch(handleError);
};

export const fetchPOSNoAndNameForFilterPopUp = (data) => {
  return Fetch.get(`${API_HOST_DUMMY_URL}/approval/request/pos-list/${data}`).then(handleResponse).catch(handleError);
};

export const fetchApprovalRequestRecordBasedOnHeaderFilter = (data) => {
  return Fetch.create(`${API_HOST_DUMMY_URL}/approval/requests/${data.countryId}?pageSize=${data.pageSize}&pageNumber=${data.pageNumber}`, data.serviceTypeFilterPayload)
    .then(handleResponse)
    .catch(handleError);
};
