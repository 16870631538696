import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { Grid } from "@material-ui/core";

import { APP_ROUTES, NEW_PATH_SELECTED } from "../../config/constants";
import BasicDetails from "../BasicDetails/BasicDetails";
import { selectUserDetails } from "../Login/redux/userSlice";
import ServiceAndSlots from "../ServiceAndSlots/ServiceAndSlots";

import createPOSStyles from "./CreatePOSStyles";
import SideBar from "./SideBar/SideBar";
import { getCurrentUrl } from "../../utils/ApplicationUtils";

const CreatePOS = () => {
  const { path } = useRouteMatch();
  const classes = createPOSStyles();
  const userInfo = useSelector(selectUserDetails);

  useEffect(() => {
    const currentLocation = getCurrentUrl();
    if (currentLocation !== undefined && currentLocation !== "") {
      localStorage.setItem(NEW_PATH_SELECTED, currentLocation);
    }
  }, []);

  return (
    <Grid container spacing={0} className={classes.mainContentSection}>
      <Grid item xs={3} className={classes.sideBarContainer}>
        <SideBar userInfo={userInfo} />
      </Grid>
      <Grid item xs={9} className={classes.mainContent}>
        <Switch>
          <Route exact path={`${path}${APP_ROUTES.BASIC_DETAILS}/:posNo?`}>
            <BasicDetails />
          </Route>
          <Route path={`${path}${APP_ROUTES.SERVICE_AND_SLOTS}`}>
            <ServiceAndSlots />
          </Route>
        </Switch>
      </Grid>
    </Grid>
  );
};

export default CreatePOS;
