import React from "react";

import { Table, TableContainer } from "@material-ui/core";

import CustomTableFooter from "../../common/CustomTableFooter/CustomTableFooter";
import ApprovalRequestTableHeader from "./ApprovalRequestTableHeader";
import ApprovalRequestTableBody from "./ApprovalRequestTableBody";

import { ApprovalRecordFilterPopUpStateInterface, ApprovalRecordInitialStateInterface } from "./ApprovalRequestConstant";

interface ApprovalRequestRecordListCardInterface {
  classes: any;
  approvalRecordState: ApprovalRecordInitialStateInterface;
  userInfo: any;
  fetchNextRecord: any;
  //
  appFilterPopUpObj: ApprovalRecordFilterPopUpStateInterface;
  handleFilterPopupOpen: any;
  handleFilterPopupClose: any;
  handlePosNoNameSearchTextChange: any;
  headerPosNoRowCheckboxOnChange: any;
  //
  handleResetPopUpOnClick: any;
  handleApplyPopUpOnClick: any;
  handleDateChangeEvent: any;
  handleCancel: any;
  currentCountry: any;
  //
  appDispatch: any;
}

const ApprovalRequestRecordListCard = (props: ApprovalRequestRecordListCardInterface) => {
  const {
    classes,
    approvalRecordState,
    userInfo,
    fetchNextRecord,
    //
    appFilterPopUpObj,
    handleFilterPopupOpen,
    handleFilterPopupClose,
    handlePosNoNameSearchTextChange,
    headerPosNoRowCheckboxOnChange,
    //
    handleResetPopUpOnClick,
    handleApplyPopUpOnClick,
    handleDateChangeEvent,
    handleCancel,
    currentCountry,
    //
    appDispatch,
  } = props;

  return (
    <div className={classes.tableDivStyle}>
      <TableContainer className={classes.tableStyle}>
        <Table padding="none" size="small" stickyHeader={true} className={classes.root}>
          <ApprovalRequestTableHeader
            classes={classes}
            //
            appFilterPopUpObj={appFilterPopUpObj}
            handleFilterPopupOpen={handleFilterPopupOpen}
            handleFilterPopupClose={handleFilterPopupClose}
            handlePosNoNameSearchTextChange={handlePosNoNameSearchTextChange}
            headerPosNoRowCheckboxOnChange={headerPosNoRowCheckboxOnChange}
            //
            handleResetPopUpOnClick={handleResetPopUpOnClick}
            handleApplyPopUpOnClick={handleApplyPopUpOnClick}
            handleDateChangeEvent={handleDateChangeEvent}
            handleCancel={handleCancel}
          />
          <ApprovalRequestTableBody classes={classes} rowsData={approvalRecordState.recordList} currentCountry={currentCountry} appFilterPopUpObj={appFilterPopUpObj} appDispatch={appDispatch} />
        </Table>
        {approvalRecordState !== undefined && approvalRecordState.recordList !== undefined && Array.isArray(approvalRecordState.recordList) && approvalRecordState.recordList.length > 0 && (
          <CustomTableFooter
            rowsData={approvalRecordState.recordList}
            classes={classes}
            colSpan={10}
            pageSize={approvalRecordState.pageSize || 40}
            rowCount={approvalRecordState.maxRecordPresent || 0}
            page={approvalRecordState.pageNumber}
            fetchNextRecord={fetchNextRecord}
          />
        )}
      </TableContainer>
    </div>
  );
};

export default React.memo(ApprovalRequestRecordListCard);
