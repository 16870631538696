import React from "react";

import { Box, Grid, Step, StepConnector, StepContent, StepLabel, Stepper, SvgIcon, Typography, makeStyles, withStyles } from "@material-ui/core";

import { PolygonDetailsSideDrawerInterface } from "../../Utils/PlolygonServicesInterface";

import { COLORS } from "../../../../config/theme/theme";

interface PolygonDetailsHistoryRecordInterface {
  classes: any;
  polygonDetailsSideDrawerObj: PolygonDetailsSideDrawerInterface;
}

const PolygonDetailsHistoryRecord = (props: PolygonDetailsHistoryRecordInterface) => {
  const { classes, polygonDetailsSideDrawerObj } = props;

  const QontoConnector = withStyles({
    root: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
      borderTop: "0px",
      borderRight: "0px",
      borderBottom: "0px",
      borderLeft: "1px",
      borderColor: COLORS.SILVER,
      borderStyle: "dashed",
      "&.MuiStepConnector-vertical": {
        marginLeft: "8px",
        marginTop: "2px",
      },
    },
    line: {
      border: "none",
    },
  })(StepConnector);

  function QontoStepIcon() {
    return <Box className={classes.stepIconStyle} />;
  }

  return (
    <>
      {polygonDetailsSideDrawerObj !== undefined &&
      polygonDetailsSideDrawerObj.polygonDetailsHistory !== undefined &&
      Array.isArray(polygonDetailsSideDrawerObj.polygonDetailsHistory) &&
      polygonDetailsSideDrawerObj.polygonDetailsHistory.length > 0 ? (
        <Stepper activeStep={0} orientation="vertical" alternativeLabel={false} nonLinear={true} connector={<QontoConnector />} className={classes.stepperRootStyle}>
          {polygonDetailsSideDrawerObj.polygonDetailsHistory.map((history: any, historyIndex: any) => (
            <Step key={`${historyIndex}-historyIndex`} active={true}>
              <StepLabel StepIconComponent={QontoStepIcon}>
                <Typography className={classes.stepLabelSpanStyle}>{history.actionPerformed || "-"}</Typography>
              </StepLabel>
              <StepContent className={classes.stepContentStyle}>
                <Typography className={classes.stepContentSpanStyle}>{history.actionPerformedByAt || "-"}</Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      ) : (
        <Grid container className={classes.historyGridContainer}>
          <Typography className={classes.historyGridNoDataSpanstyle}>{`No history found`}</Typography>
        </Grid>
      )}
    </>
  );
};

export default React.memo(PolygonDetailsHistoryRecord);
