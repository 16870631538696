import React, { useCallback, useState } from 'react';

import { userStyles } from './UserDetailsDialogStyle';

import AccountCircle from '../../assets/svg/AccountCircle.svg';
import Email from '../../assets/svg/email.svg';

import UserDetailsInfoView from './Utils/UserDetailsInfoView';

interface UserDetailsInterface {
    userObj : any
}

const UserDetailsView = (props : UserDetailsInterface) => {
    const classes = userStyles();
    const {userObj} = props;

    const [userData, setUserData] = useState<any>({});

    const populateUserData = useCallback(async () => {
        // Fetching *** serviceTypeName, countryDisplayName, accessToDeliveryEngine and accessToCountrySetting
        // from userObj.userDataRecord which is populated once the additionalServicesList is fetched
        if(userObj && userObj.userDataRecord && Array.isArray(userObj.userDataRecord) && userObj.userDataRecord.length > 0){
            const userDetails = userObj.userDataRecord.filter((record : any) => record.id === userObj.id);
           if(Array.isArray(userDetails) && userDetails.length > 0){
            setUserData(userDetails[0]);
           }
        }
    }, [userObj]);

    React.useEffect(() => {
        populateUserData()
    }, [userObj, populateUserData])

    return (
        <div className={classes.userDetailsMainDiv}>
            <div className={classes.userDetailsDiv}>
                <div className={classes.userDetailsIconDiv}>
                    <img src={AccountCircle} alt="User Icon" />
                </div>

                <div className={classes.userDetailsHeaderDiv}>
                    <span className={classes.headerTextNameStyle}>{`${userObj.firstName || '-'} ${userObj.lastName || '-'}`}</span>
                    <div className={classes.userDetailsEmailDiv}>
                        <img src={Email} alt="Email" className={classes.emailIconstyle}/>
                        <span className={classes.headerTextStyle}>{`${userObj.emailId || '-'}`}</span>
                    </div>
                </div>
            </div>

            <div className={classes.userDetailsInfoDiv}>
                <UserDetailsInfoView nameA={'Role'} valueA={userObj.role || '-'} nameB={'Service Type'} valueB={userData.serviceTypeName || '-'} />

                <UserDetailsInfoView nameA={'Country'} valueA={userData.countryDisplayName || '-'} nameB={'Status'} valueB={userObj.active || '-'} />

                <UserDetailsInfoView nameA={'Access to Rule Engine'} valueA={userData.accessToDeliveryEngine ? "Yes" : "No" || '-'} nameB={'Access to Country Settings'} valueB={userData.accessToCountrySetting ? "Yes" : "No" || '-'} />

                <UserDetailsInfoView nameA={'User Created Date'} valueA={userObj.createdAt || '-'} nameB={'User Created By'} valueB={userObj.createdBy || '-'} />

                <UserDetailsInfoView nameA={'Last Updated Date'} valueA={userObj.lastModifiedAt || '-'} nameB={'Last Updated By'} valueB={userObj.lastModifiedBy || '-'} />
            </div>
        </div>
    )
}

export default React.memo(UserDetailsView);