import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "config/theme/theme";

export const userStyles = makeStyles(() =>
  createStyles({
    mainContainer: {
      top: "10%",
      left: "auto",
      right: "auto",
      width: "100%",
      height: "auto",
      margin: 0,
      padding: "24px",
      overflow: "auto",
    },
    cardContainer: {
      width: "100%",
      height: "calc(100vh - 48px)",
      margin: 0,
      padding: 0,
      overflow: "auto",
    },
    gridContainerMain: {
      margin: 0,
      padding: 0,
      paddingBottom: "8px",
    },
    gridContentContainerMain: {
      margin: 0,
      padding: "0px 24px 8px 24px",
    },
    gridContainerTitleDivStyle: {
      margin: 0,
      padding: "24px 0px 0px 0px",
      textAlign: "center",
      background: "rgba(244, 244, 244, 0.5)",
    },
    headerStyleNormalDiv: {
      margin: 0,
      padding: "0px 12px 8px 12px",
      border: "none",
      borderBottom: "1px solid rgba(151, 151, 151, 0.5)",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "18px",
      color: "#141414",
    },
    configurationTitleDivStyle: {
      margin: "auto",
      padding: "12px 24px 0px 24px",
    },
    capacityUtiDivStyle: {
      margin: "auto",
      padding: "12px 24px 0px 24px ",
      textAlign: "right",
    },
    headerStyle: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "20px",
      color: "#141414",
    },
    buttonStyle: {
      width: "auto",
      height: "30px",
      textAlign: "right",
      color: "#0E5AA7",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "17px",
    },
    gridContainerFooter: {
      margin: 0,
      padding: "24px 24px 24px 24px",
      display: "flex",
      justifyContent: "flex-end",
    },
    cancelButtonStyle: {
      width: "212px",
      background: "#FFFFFF",
      border: "1px solid #0E5AA7",
      borderRadius: "4px",
      color: "#0E5AA7",
      textDecoration: "none",
      marginRight: "24px",
      "&:hover": {
        background: "#FFFFFF",
        border: "1px solid #0E5AA7",
      },
      "&.MuiButton-label": {
        background: "#FFFFFF",
        border: "1px solid #0E5AA7",
        "&:hover": {
          background: "#FFFFFF",
          border: "1px solid #0E5AA7",
        },
      },
    },
    saveButtonStyle: {
      width: "300px",
      background: "#0E5AA7",
      border: "1px solid #0E5AA7",
      borderRadius: "4px",
      color: "#FFFFFF",
      textDecoration: "none",
      "&:hover": {
        background: "#0E5AA7",
        border: "1px solid #0E5AA7",
      },
      "&.MuiButton-label": {
        background: "#0E5AA7",
        border: "1px solid #0E5AA7",
        "&:hover": {
          background: "#0E5AA7",
          border: "1px solid #0E5AA7",
        },
      },
    },
    noDateSelectedDiv: {
      width: "100%",
      height: "100px",
      margin: 0,
      marginBottom: "24px",
      padding: "24px",
      background: "rgba(244, 244, 244, 0.4)",
      border: "1px solid rgba(151, 151, 151, 0.5)",
      borderRadius: "8px",
    },
    noDateSelectedTextStyle: {
      margin: "auto",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#141414",
    },

    // Date Container
    dateTimeStyle: {
      width: "auto",
      margin: 0,
      marginBottom: "12px",
      padding: "0px",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "17px",
      color: "#2D2D2D",
    },
    boxContainer: {
      width: "100%",
      height: "auto",
      margin: 0,
      marginBottom: "24px",
      padding: "24px",
      background: "rgba(244, 244, 244, 0.4)",
      border: "1px solid rgba(151, 151, 151, 0.5)",
      borderRadius: "8px",
    },
    configDateTextStyle: {
      margin: 0,
      marginBottom: "12px",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "17px",
      color: "#252525",
    },
    keyboardDatePickerStyle: {
      "& .MuiOutlinedInput-input": {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px !important",
        lineHeight: "20px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        top: "0px",
      },
    },
    iconDivStyle: {
      margin: "auto",
      padding: 0,
    },
    iconStyle: {
      cursor: "pointer",
    },

    // Day Container
    DayBoxContainer: {
      width: "100%",
      height: "auto",
      margin: 0,
      marginTop: "12px",
      padding: "24px",
      background: "rgba(244, 244, 244, 0.4)",
      border: "1px solid rgba(151, 151, 151, 0.5)",
      borderRadius: "8px",
    },
    container: {},
    rightPaddingStyle: {
      paddingRight: "12px",
    },
    timeConfigMainContainerDivStyle: {
      margin: 0,
      marginBottom: "12px",
      padding: 0,
    },
    timeConfigDivStyle: {
      margin: 0,
      padding: 0,
    },

    textAlignCenterStyle: {
      textAlign: "center",
    },
    serviceTypeItem: {},
    checkboxLabel: {
      fontWeight: 600,
      fontSize: "14px",
      color: "#0E5AA7",
      margin: "auto 0px auto 8px",
    },
    capacityUtilisationMainBxContainer: {
      width: "100%",
      height: "auto",
      margin: 0,
      marginBottom: "24px",
      padding: "24px",
      background: "rgba(244, 244, 244, 0.4)",
      border: "1px solid rgba(151, 151, 151, 0.5)",
      borderRadius: "8px",
      boxShadow: "none",
    },
    capacityUtilisationMainDiv: {
      margin: 0,
      padding: "0px 0px 12px 0px",
    },
    capacityUtilisationValueDiv: {
      margin: 0,
      padding: "0px 0px 8px 0px",
    },
    gridContainerContent: {
      margin: 0,
      padding: "0px 24px 0px 24px",
    },
    // mainContent Start
    accordionStyle: {
      background: COLORS.WHITE,
      border: "1px solid #C3C3C3",
      borderRadius: "4px",
      marginTop: "12px",
      "&.Mui-expanded:first-child": {
        padding: 0,
        margin: 0,
        marginTop: "12px",
      },
      "&:last-child": {
        padding: 0,
        margin: 0,
        marginTop: "12px",
      },
    },
    accordionSummaryStyle: {
      padding: "12px 24px 12px 12px",
    },
    accordionTitle: {
      fontSize: "18px",
      fontWeight: 600,
    },
    accordionDetailsStyle: {
      borderTop: "1px solid #C3C3C3",
      display: "block",
      padding: "12px 0px 12px 0px",
    },
    addConfigIconMainDivStyle: {
      width: "100%",
      height: "100%",
    },
    addConfigIconDivStyle: {
      margin: 0,
      padding: 0,
      display: "flex",
      justifyContent: "flex-start",
      cursor: "pointer",
    },
    addConfigIconDivStyleDisabled: {
      margin: 0,
      padding: 0,
      display: "flex",
      justifyContent: "flex-start",
      cursor: "default",
      opacity: "0.5",
    },
    addConfigIconStyle: {
      margin: "auto 0px",
      width: "20px",
      height: "20px",
      color: COLORS.PRIMARY_MAIN,
    },
    addConfigTextStyle: {
      color: COLORS.PRIMARY_MAIN,
      fontSize: "12px",
      fontWeight: 600,
      margin: "auto 0px auto 12px",
    },
    actionIconStyle: {
      cursor: "pointer",
    },
    configDateNoteTextStyle: {
      margin: 0,
      marginBottom: "12px",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "17px",
      color: "#0E5AA7",
      float: "right",
    },

    // Express Service Fees
    gridContainerServiceFeesTitleDivStyle: {
      margin: 0,
      padding: "24px 0px 0px 0px",
      textAlign: "center",
    },
  })
);
