import React from 'react';

import CustomDialog from '../../common/Dialog/index';
import PrimaryButton from '../../common/PrimaryButton/PrimaryButton';
import Loader from '../../common/Loader/Loader';
import UserDetailsView from './UserDetailsView';

import { userStyles } from './UserDetailsDialogStyle';
import { DIALOG_USER_DETAIL_HEADER } from '../Utils/UserManagementUtils';
import { EditUserAccess } from '../Utils/UserManagementAccessUtils';


interface UserDetailsObject {
    open: boolean;
    userObj: any,
    handleClose: () => void;
    countriesList : Array<any>;
    serviceTypeList : Array<any>;
    rolesList : Array<any>;
    additionalServicesList : Array<any>;
    statusList : Array<any>;
    userInfo : any;
}

const UserDetailsDialog = (props: UserDetailsObject) => {
    const userDetailStyles = userStyles();
    const { open, userObj, handleClose, countriesList, serviceTypeList, rolesList, additionalServicesList, statusList, userInfo } = props;

    const publishDialogPaperProps = {
     classes: {
        root: userDetailStyles.dialogRoot,
     },
     square: true,
    };

    const getAddUpdateDialogTitleContent = () => {
        return (
          <>
            <span className={userDetailStyles.dialogTitleStyle}> {`${DIALOG_USER_DETAIL_HEADER} : `}</span>
          </>
        );
      };
    
      const getAddUpdateDialogDetailsContent = () => {
        return (
          userObj && userObj.id && rolesList.length > 0 && statusList.length > 0 && countriesList.length > 0 && serviceTypeList.length > 0 && additionalServicesList.length > 0
            ? <UserDetailsView userObj={userObj} />
            : <Loader />
        );
      };
    
      const getAddUpdateDialogActions = () => {
        return (
          <>
            <PrimaryButton buttonLabel="CANCEL" buttonVariant="outlined" className={userDetailStyles.buttonStyles} onClick={handleClose} />
            <EditUserAccess
              userInfo={userInfo}
              userObj={userObj}
              userDetailStyles={userDetailStyles}
            />
          </>
        );
      };

    return (
        <CustomDialog
         open={open}
         PaperProps={publishDialogPaperProps}
         isLoadingData={false}
         title={getAddUpdateDialogTitleContent()}
         content={getAddUpdateDialogDetailsContent()}
         actions={getAddUpdateDialogActions()}
         handleClose={handleClose}
        />
    )
}

export default React.memo(UserDetailsDialog);