import React from "react";

import { Card, CardContent, Grid, Typography } from "@material-ui/core";

import DateRangePickerComponent from "../../../common/DateRangePickerComponent/DateRangePickerComponent";

interface RenderApprovalRequestCardInterface {
  classes: any;
  serviceState: any;
  handleDateChangeEvent: any;
  handleCancel: any;
  configTab: any;
  configTabIndex: any;
  renderConfigureSlotsAccordion: any;
}

const RenderApprovalRequestCard = (props: RenderApprovalRequestCardInterface) => {
  const { classes, serviceState, handleDateChangeEvent, handleCancel, configTab, configTabIndex, renderConfigureSlotsAccordion } = props;

  return (
    <Grid className={classes.implementationDateCardStyle}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.dateSearchTitleSpanStyle}>{`Implemantation time for changes`}</Typography>
          </Grid>
          <Grid item xs={4}>
            <DateRangePickerComponent
              isTextField={false}
              childClasses={classes}
              datePickerName={"date"}
              placeHolderText={"Select implementation date"}
              datePickerValue={serviceState && serviceState.activationDatelabel ? serviceState.activationDatelabel : ""}
              handleDateChangeEvent={handleDateChangeEvent}
              handleCancel={handleCancel}
              initialSettings={{
                singleDatePicker: true,
                autoUpdateInput: false,
                cancelButtonClasses: "btn-primary",
                locale: { format: "YYYY-MM-DD", cancelLabel: "Clear" },
                minDate: new Date(),
              }}
              fullWidth={true}
            />
          </Grid>
        </Grid>
      </Grid>

      {serviceState !== undefined && serviceState.activationDatelabel !== undefined && serviceState.activationDatelabel !== "" && serviceState.isDateValid && (
        <Grid item xs={12} className={classes.titleSectionDiv}>
          {renderConfigureSlotsAccordion(configTab, configTabIndex)}
        </Grid>
      )}

      {serviceState !== undefined && serviceState.data_present_obj !== undefined && serviceState.data_present_obj.requestId !== undefined && serviceState.data_present_obj.requestId !== "" && (
        <Card className={classes.errorCardStyle}>
          <CardContent>
            <Typography className={classes.errorTextSpanStyle}>{`A request ${serviceState.data_present_obj.requestId} is already raised for this implementation date by ${
              serviceState.data_present_obj.requester || "Admin"
            }. Please request action on the same.`}</Typography>
          </CardContent>
        </Card>
      )}
    </Grid>
  );
};

export default React.memo(RenderApprovalRequestCard);
