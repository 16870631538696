/* global google */
import React from "react";
import PolygonMap from "../../PlolygonServices/PolygonMapService/PolygonMap";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";

const CustomGoogleMap = withScriptjs(
  withGoogleMap((props) => {
    const {
      refMap,
      defaultCenter,
      defaultZoom,
      onIdle,
      markers,
      polygons,
      handlePolygonClick,
      polygonStateObj,
      viewPolygonInfoWindow,
      posZoneMarkerInfoWindow,
      openPolygonPosZoneInfoWindow,
      mapOptionStyles,
    } = props;
    return (
      <GoogleMap
        ref={refMap}
        defaultCenter={defaultCenter}
        center={defaultCenter}
        defaultZoom={defaultZoom}
        zoom={defaultZoom}
        onIdle={onIdle}
        options={{ styles: mapOptionStyles ? mapOptionStyles : [] }}
      >
        <>
          {markers &&
            markers.length &&
            markers.map(
              (marker, markerIndex) =>
                marker &&
                marker.visible && (
                  <Marker key={`${marker.key}`} title={marker.name} icon={marker.icon} label={marker.label} position={marker.location} onClick={() => openPolygonPosZoneInfoWindow(markerIndex)}>
                    {marker.openWindow && <InfoWindow key={`infowindow-${marker?.posName || "InfoWindow"}`}>{posZoneMarkerInfoWindow && posZoneMarkerInfoWindow(marker)}</InfoWindow>}
                  </Marker>
                )
            )}
          {polygons &&
            polygons.length &&
            polygons.map((polygon) => (
              <PolygonMap
                name={`${polygon.polygonKey}`}
                path={polygon.path}
                fillColor={polygon?.options?.fillColor}
                strokeColor={polygon?.options?.strokeColor}
                defaultOptions={polygon.options}
                onClick={(event) => handlePolygonClick(event, polygon)}
              />
            ))}

          {polygonStateObj && polygonStateObj.openMapInfoWindow && (
            <InfoWindow
              key={`infowindow-${polygonStateObj?.mapInfoWindowRecord?.polygonId || "InfoWindow"}`}
              position={polygonStateObj.event.latLng ? polygonStateObj.event.latLng : { lat: polygonStateObj.event.lat, lng: polygonStateObj.event.lng }}
            >
              <div>{polygonStateObj?.mapInfoWindowRecord?.polygonId && viewPolygonInfoWindow ? viewPolygonInfoWindow() : ""}</div>
            </InfoWindow>
          )}
        </>
      </GoogleMap>
    );
  })
);

export default CustomGoogleMap;
