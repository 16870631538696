import { PublicClientApplication } from "@azure/msal-browser";

const appId: any = `${process.env.REACT_APP_AZURE_APP_ID}`;

let path = "";
if (typeof window !== undefined) {
  path = window.location.protocol + "//" + window.location.host + "/login";
}

export const config = {
  appId: appId || '',
  redirectUri: path,
  scopes: ["user.read", "Mail.Read"],
};

const msalConfig = {
  auth: {
    clientId: config.appId,
    redirectUri: config.redirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    tokenRenewalOffsetSeconds: 300,
  },
};
export const publicClientApplication = new PublicClientApplication(msalConfig);

export const Role = {
  MAFADMIN: 'MAFADMIN',
  PICKER: 'PICKER',
  SUPERADMIN: 'SUPERADMIN',
  MASTER: 'MASTER'
}
