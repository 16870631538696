import moment from "moment";

export const HH_MM_SS = "HH:mm:ss";
export const HH_MM = "HH:mm";

export function getDateFromData(dateData: any) {
  if (dateData) {
    return moment(dateData).format("DD MMM YYYY");
  }
  return dateData;
}

export function getTimeFormatData(timeData: any) {
  if (timeData) {
    return moment(timeData, HH_MM_SS).format("HH:mm");
  }
  return timeData;
}

export function getDateFromPicker(dateData: any) {
  return moment(dateData).format("YYYY-MM-DD");
}

export function convertDateFromCurrentFormatToNewFormat(dateData: any, currentFormat: any, newFormat: any) {
  let returnDate = moment(dateData, currentFormat).format(newFormat);
  return returnDate;
}
