import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
//Material UI Components
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useRef, useState } from "react";
import NavigateItem from "../../assets/svg/ExpandAccordionIcon.svg";
// Svg
import searchIcon from "../../assets/svg/SearchIcon.svg";
//Common Components
import TextBox from "../../common/TextBox/index";
import Loader from "../Loader/Loader";
import transferListStyles from "./TransferListStyles";

const operation = (list1, list2, isUnion = false) =>
  list1.filter(
    (
      (set) => (a) =>
        isUnion === set.has(a.value)
    )(new Set(list2.map((b) => b.value)))
  );

const inBoth = (list1, list2) => operation(list1, list2, true);

const inFirstOnly = operation;

const TransferList = ({ title, fetchingList = true, leftSideItems, rightSideItems = [], onLeftItemsSearch = () => {}, hasMoreData, resetItem, onFetchMore, onItemsAssigned }) => {
  const classes = transferListStyles();

  const [searchedText, setSearchedText] = useState("");

  const [localLeftItems, setLocalLeftItems] = useState(leftSideItems);
  const [localRightItems, setLocalRightItems] = useState(rightSideItems);

  const [selectedItems, setSelectedItems] = useState([]);

  const observer = useRef(null);
  const [element, setElement] = useState(null);

  const hasMoreDataRef = useRef(hasMoreData);

  const [right_side_search, set_right_side_search] = useState("");

  const handleSearchInputChange = (e) => {
    setSearchedText(e.target.value);
  };

  const handleKeyUp = (e) => {
    // if (e.key === "Enter" || e.keyCode === 13) {
    //   onLeftItemsSearch(searchedText);
    // }
    console.log(":: handleKeyUp ::");
  };

  const handleSearch = () => {
    // onLeftItemsSearch(searchedText);
    console.log(":: handleSearch ::");
  };

  const handleToggle = (value) => () => {
    const currentIndex = selectedItems.indexOf(value);
    const newChecked = [...selectedItems];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedItems(newChecked);
  };
  const selectedAssignedListItems = inBoth(selectedItems, localRightItems);
  // Handle move to right funcationality
  const moveToRightList = () => {
    let newAssignedList = [...new Set([...selectedItems, ...localRightItems])];
    setLocalLeftItems(inFirstOnly(leftSideItems, newAssignedList));
    setLocalRightItems(newAssignedList);
    setSelectedItems([]);
    onItemsAssigned(newAssignedList);
  };

  // Handle move to left funcationality
  const moveToLeftList = () => {
    setLocalRightItems(inFirstOnly(localRightItems, selectedAssignedListItems));
    setSelectedItems(inFirstOnly(selectedItems, selectedAssignedListItems));
    onItemsAssigned(inFirstOnly(localRightItems, selectedAssignedListItems));
  };

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        const first = entries[0];
        if (first.isIntersecting && hasMoreDataRef.current) {
          onFetchMore();
        }
      },
      { threshold: 1 }
    );
  }, [hasMoreData, onFetchMore]);

  useEffect(() => {
    hasMoreDataRef.current = hasMoreData;
  }, [hasMoreData]);

  useEffect(() => {
    const currentElement = element;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [element]);

  useEffect(() => {
    setLocalLeftItems(inFirstOnly(leftSideItems, localRightItems));
  }, [leftSideItems, localRightItems]);

  // reset all left right item on page refresh
  useEffect(() => {
    if (resetItem) {
      setLocalLeftItems(leftSideItems);
      setLocalRightItems([]);
    }
    // eslint-disable-next-line
  }, [resetItem]);

  const get_left_side_list = () => {
    if (localLeftItems !== undefined && Array.isArray(localLeftItems) && localLeftItems.length > 0) {
      let filtered_list = [...localLeftItems];
      if (searchedText !== undefined && searchedText !== "") {
        filtered_list = filtered_list.filter((list) => list.posNo && list.posNo.toLowerCase().includes(searchedText.toLowerCase()));
      }
      return filtered_list;
    }
    return [];
  };

  const renderLeftSide = () => {
    return (
      <Grid item xs={5} className={classes.leftContainer}>
        <Card className={classes.itemsCards}>
          <CardContent>
            <Typography className={classes.areaTitles}>{`Select Items (${selectedItems.length})`}</Typography>

            <Grid className={classes.root} container>
              <Grid item xs={12}>
                <TextBox
                  label="Search Pos Code"
                  placeholderText="Search"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="end" size="small">
                        <img src={searchIcon} alt="search" onClick={handleSearch} />
                      </IconButton>
                    </InputAdornment>
                  }
                  value={searchedText}
                  handleChange={handleSearchInputChange}
                  onKeyUp={handleKeyUp}
                />
              </Grid>
              <Grid item xs={12}>
                {fetchingList ? <Loader /> : null}
                {renderList(get_left_side_list(), true)}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const renderMoveButtons = () => {
    return (
      <Grid item className={classes.moveToListButtons}>
        <img
          className={`${classes.areaListTransferButton} ${classes.moveToRightListButton}`}
          src={NavigateItem}
          alt="moveRight"
          onClick={moveToRightList}
          // disabled={selectedAreaListItem.length === 0}
        />

        <img className={`${classes.areaListTransferButton} ${classes.moveToLeftListButton}`} src={NavigateItem} alt="moveLeft" onClick={moveToLeftList} />
      </Grid>
    );
  };

  const getRightSideListBasedOnSearch = () => {
    if (localRightItems !== undefined && Array.isArray(localRightItems) && localRightItems.length > 0) {
      let filtered_list = [...localRightItems];
      if (right_side_search !== undefined && right_side_search !== "") {
        filtered_list = filtered_list.filter((list) => list.posNo && list.posNo.toLowerCase().includes(right_side_search.toLowerCase()));
      }
      return filtered_list;
    }
    return [];
  };

  const right_side_search_on_change = (event) => {
    if (event !== undefined && event.target !== undefined) {
      let { value } = event.target;
      set_right_side_search(value);
    }
  };

  const renderRightSide = () => {
    return (
      <Grid item className={classes.assignedItemsContainer}>
        <Card className={classes.itemsCards}>
          <CardContent>
            <Typography className={classes.areaTitles}>{`Assigned Items (${localRightItems.length})`}</Typography>
            <Grid className={classes.assignedItemsSearchBar}>
              <TextBox
                label="Search POS Code"
                type="text"
                placeholderText="Search POS Code"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end" size="small">
                      <img src={searchIcon} alt="search" />
                    </IconButton>
                  </InputAdornment>
                }
                required={false}
                value={right_side_search}
                handleChange={right_side_search_on_change}
              ></TextBox>
            </Grid>
            {/* {localRightItems.length ? renderList(localRightItems) : null} */}
            {renderList(getRightSideListBasedOnSearch())}
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const renderList = (items, isLeft) => {
    return (
      <List component="div" className={`${classes.list} ${classes.listNotEmpty}`}>
        {items.map((item) => (
          <div key={item.value}>
            <ListItem role="listitem" button onClick={handleToggle(item)} className={`${classes.listItems} ${selectedItems.indexOf(item) !== -1 ? classes.selectedStyles : ""}`}>
              <ListItemText primary={`${item.label}`} />
            </ListItem>
            <Divider component="li" />
          </div>
        ))}
        {isLeft && (
          <ListItem role="listitem" button ref={setElement}>
            <ListItemText />
          </ListItem>
        )}
      </List>
    );
  };

  return (
    <Accordion defaultExpanded className={classes.accordionStyle}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
        <Grid item>
          <Typography className={classes.accordionTitle}>{title}</Typography>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Grid container>
          {renderLeftSide()}
          {renderMoveButtons()}
          {renderRightSide()}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default TransferList;
