import React from "react";
import { Link } from "react-router-dom";

import { Grid } from "@material-ui/core";

import PrimaryButton from "../../../../common/PrimaryButton/PrimaryButton";
import { APP_ROUTES } from "config/constants";

interface RenderFooterButtonsInterface {
  classes: any;
  serviceData: any;
  history: any;
  posDetails: any;
  send_request_for_approval: any;
  dateObject: any;
}

const RenderFooterButtons = (props: RenderFooterButtonsInterface) => {
  const { classes, serviceData, history, posDetails, send_request_for_approval, dateObject } = props;

  return (
    <Grid container direction="row" className={classes.buttonsStyle}>
      <Grid item xs={6}>
        <Grid container spacing={1} justifyContent="flex-start">
          <Grid item xs={4}>
            <PrimaryButton buttonLabel="VIEW SETUP" buttonVariant="outlined" disabled={!serviceData.length} onClick={() => history.push(`${APP_ROUTES.COMPLETE_SETUP}/${posDetails.posNo}`)} />
          </Grid>
          <Grid item xs={4}>
            <Link to={`${APP_ROUTES.POS_LIST}`} className={classes.linkstyle}>
              <PrimaryButton buttonLabel="BACK TO POS LIST" buttonVariant="outlined" disabled={!serviceData.length} />
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={6}>
            {dateObject !== undefined && dateObject.activationDatelabel !== undefined && dateObject.activationDatelabel !== "" && dateObject.isDateValid ? (
              <PrimaryButton buttonLabel="SEND FOR APPROVAL" onClick={send_request_for_approval} className={classes.buttonStyle} />
            ) : (
              <></>
            )}{" "}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(RenderFooterButtons);
