import { makeStyles } from "@material-ui/core";

const searchableDropdownStyles = makeStyles((theme) => ({
  fieldLabel: { marginBottom: "8px" },
  searchableDropdown: {
    "& .MuiAutocomplete-clearIndicator span > svg": {
      marginRight: "5px",
      fontSize: "24px",
    },
    "& .MuiAutocomplete-popupIndicator": {
      width: "20px",
    },

    "& .MuiAutocomplete-inputRoot": {
      fontWeight: 500,
      paddingLeft: "7px",
      paddingTop: "5px",
    },
    "& .MuiOutlinedInput-root.Mui-disabled": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#E6E6E6",
        top: "0px",
      },
    },
  },
  isDropdownOpen: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderBottom: "none",
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderColor: "#0A437C !important",
    },
  },

  paperStyles: {
    margin: 0,
    maxHeight: 200,
    border: "1px solid #0A437C",
    borderRadius: theme.spacing(1),
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: "none",
    fontSize: "12px",
  },
}));

export default searchableDropdownStyles;
