import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Card, CardActionArea, CardContent, CardHeader, FormControl, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { ReactComponent as EditIcon } from "../../../../assets/svg/Pencil.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/svg/DeleteIcon.svg";
import { ReactComponent as BulkUpdateInfoIcon } from "../../../../assets/svg/BulkUpdateInfoIcon.svg";

import TextBox from "../../../../common/TextBox";
import TimePicker from "../../../../common/TimePicker/TimePicker";

import LinkButton from "../../../../common/LinkButton/LinkButton";

interface RenderExpressConfigurationSlotsAccordionInterface {
  classes: any;
  configTab: any;
  makeSlotEditable: any;
  deleteTemplateSlot: any;
  addNewTemplateSlot: any;
  handleOnDemandServiceTimeChange: any;
  handleIndividualSlotFieldsChange: any;
  // Bulk Update
  bulkUpdatedDays: any;
  handleBulkUpdateAction: any;
  resetCurrentConfigurationAfterBulkUpdateRevert: any;
}

const RenderExpressConfigurationSlotsAccordion = (props: RenderExpressConfigurationSlotsAccordionInterface) => {
  const {
    classes,
    configTab,
    makeSlotEditable,
    deleteTemplateSlot,
    addNewTemplateSlot,
    handleOnDemandServiceTimeChange,
    handleIndividualSlotFieldsChange,
    bulkUpdatedDays,
    handleBulkUpdateAction,
    resetCurrentConfigurationAfterBulkUpdateRevert,
  } = props;

  const show_bulk_update_view = (slotGeneratorForm: any, generatorFormIndex: any) => {
    if (bulkUpdatedDays !== undefined && Array.isArray(bulkUpdatedDays)) {
      if (bulkUpdatedDays.length == 0) {
        return (
          <Grid item xs={12} className={classes.bulkUpdateItemGridMain}>
            <BulkUpdateInfoIcon className={classes.bulkUpdateInfoIconStyle} />
            <Typography className={classes.bulkUpdateInfoSpanStyle}>{`You can now duplicate the above configuration for other days as well`}</Typography>
            <Typography className={classes.bulkUpdateLinkSpanStyle} onClick={() => handleBulkUpdateAction(generatorFormIndex)}>{`Click here to bulk update`}</Typography>
          </Grid>
        );
      } else if (
        slotGeneratorForm !== undefined &&
        slotGeneratorForm.days !== undefined &&
        Array.isArray(slotGeneratorForm.days) &&
        slotGeneratorForm.days.length !== undefined &&
        slotGeneratorForm.days[0] !== undefined &&
        slotGeneratorForm.days[0] !== "" &&
        bulkUpdatedDays.indexOf(slotGeneratorForm.days[0]) > -1
      ) {
        return (
          <Grid item xs={12} className={classes.bulkUpdateRevertItemGridMain}>
            <Typography className={classes.bulkUpdateRevertSpanStyle}>{`Configuration for this days is changed in bulk. Want to revert back ?`}</Typography>
            <Typography className={classes.bulkUpdateRevertLinkSpanStyle} onClick={() => resetCurrentConfigurationAfterBulkUpdateRevert()}>{`Click here to refresh page`}</Typography>
          </Grid>
        );
      }
    }
    return <></>;
  };

  return (
    <Card className={classes.slotCardStyle}>
      <CardContent className={classes.slotCardContentStyle}>
        {configTab.slotsGeneratorForms.map((slotGeneratorForm: any, generatorFormIndex: any) => (
          <div key={`${slotGeneratorForm?.days[0] || generatorFormIndex}`} className={classes.approvalSlotsGeneratorFormContainer}>
            <Accordion defaultExpanded={false} className={classes.greyBackground}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.slotsAccordion}>
                <Grid container>
                  <Grid item xs={6}>
                    <div>
                      {`Slots for : `}
                      <span className={classes.dayStyles}>{slotGeneratorForm.days[0].toLowerCase()}</span>
                    </div>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails className={classes.generatedSlotsAccordionDetails}>
                <Grid container spacing={0}>
                  {slotGeneratorForm !== undefined &&
                    slotGeneratorForm.generatedSlots !== undefined &&
                    Array.isArray(slotGeneratorForm.generatedSlots) &&
                    slotGeneratorForm.generatedSlots.length > 0 && (
                      <Grid container spacing={1}>
                        <Grid item xs={4} className={classes.newRequestGridHeader}>
                          First customer order time
                        </Grid>
                        <Grid item xs={4} className={classes.newRequestGridHeader}>
                          Last order cut-off time
                        </Grid>
                        <Grid item xs={3} className={classes.newRequestGridHeader}>
                          Capacity
                        </Grid>
                        <Grid item xs={1} className={classes.newRequestGridHeader}>
                          Actions
                        </Grid>
                        {slotGeneratorForm.generatedSlots.map(
                          (slot: any, slot_index: any) =>
                            slot !== undefined &&
                            slot.slotAction !== undefined &&
                            slot.slotAction !== "" &&
                            slot.slotAction !== "DELETE" && (
                              <Grid container spacing={1} key={`${slot_index}-slot-generatedSlots-slotGeneratorForm-${slotGeneratorForm.days[0].toLowerCase()}}`}>
                                <Grid item xs={4} className={classes.newRequestGridValues}>
                                  <TimePicker
                                    value={slot.startTime}
                                    handleChange={(date: any) => handleOnDemandServiceTimeChange(generatorFormIndex, slot_index, date, "startTime")}
                                    disabled={!slot.canBeUpdated}
                                    fieldLabel={undefined}
                                    className={undefined}
                                    timerProps={undefined}
                                  />
                                </Grid>
                                <Grid item xs={4} className={classes.newRequestGridValues}>
                                  <TimePicker
                                    value={slot.endTime}
                                    handleChange={(date: any) => handleOnDemandServiceTimeChange(generatorFormIndex, slot_index, date, "endTime")}
                                    disabled={!slot.canBeUpdated}
                                    fieldLabel={undefined}
                                    className={undefined}
                                    timerProps={undefined}
                                  />
                                </Grid>
                                <Grid item xs={3} className={classes.newRequestGridValues}>
                                  <TextBox
                                    type="number"
                                    name="slotCapacity"
                                    value={slot.slotCapacity}
                                    handleChange={(event: any) => handleIndividualSlotFieldsChange(generatorFormIndex, slot_index, event)}
                                    disabled={!slot.canBeUpdated}
                                    placeholderText="Enter Capacity"
                                    isNegativeAllowed={false}
                                  />
                                </Grid>
                                <Grid item xs={1} className={classes.newRequestGridValuesLabel}>
                                  {!slot.canBeUpdated ? (
                                    <EditIcon className={classes.iconButton} onClick={() => makeSlotEditable(generatorFormIndex, slot_index)} />
                                  ) : (
                                    <DeleteIcon className={classes.iconButton} onClick={() => deleteTemplateSlot(generatorFormIndex, slot_index)} />
                                  )}
                                </Grid>
                              </Grid>
                            )
                        )}
                      </Grid>
                    )}
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12} className={classes.newRequestGridValues}>
                    <LinkButton buttonLabel="Add New Slot" onClick={() => addNewTemplateSlot(generatorFormIndex, slotGeneratorForm.days[0])} />
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  {slotGeneratorForm !== undefined &&
                    slotGeneratorForm.generatedSlots !== undefined &&
                    Array.isArray(slotGeneratorForm.generatedSlots) &&
                    slotGeneratorForm.generatedSlots.length > 0 &&
                    show_bulk_update_view(slotGeneratorForm, generatorFormIndex)}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </CardContent>
      <CardActionArea></CardActionArea>
    </Card>
  );
};

export default React.memo(RenderExpressConfigurationSlotsAccordion);
