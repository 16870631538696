import React, { useState } from "react";

// Material UI Components
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Grid from "@material-ui/core/Grid";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LinkButton from "../../../common/LinkButton/LinkButton";
import { ReactComponent as CheckedIcon } from "../../../assets/svg/CheckedIcon.svg";
import { ReactComponent as BulkUpdateInfoIcon } from "../../../assets/svg/BulkUpdateInfoIcon.svg";

import TemplateSlotInEditMode from "../TemplateSlot/TemplateSlotInEditMode";
import QuotaSettings from "../../Standard/QuotaSettings/QuotaSettings";

// Styles
import editTemplateSlotsStyles from "./EditTemplateSlotsStyles";
import { QUOTA_VIEW_OPTIONS, SERVICE_TYPES } from "../../../../config/constants";
import { Typography } from "@material-ui/core";
import { is_quota_addition_allowed } from "../../../ServiceAndSlots/Standard/StandardAccessUtils";

const EditTemplateSlots = ({
  serviceTypeName,
  days,
  generatedSlots,
  dayWiseSlots,
  quotas,
  handleIndividualSlotFieldsChange,
  handleIndividualSlotCheckbox,
  handleIndividualSlotTimeChange,
  makeSlotEditable,
  cancelEditingASlot,
  updateTemplateSlot,
  deleteTemplateSlot,
  addNewTemplateSlot,
  saveNewTemplateSlot,
  handleModifyQuotaSettings,
  handleBulkUpdateAction,
  configurationBulkUpdatedObj,
  resetCurrentConfigurationAfterBulkUpdateRevert,
  userInfo,
}) => {
  let isQuotaConfigured = !!(quotas && quotas.length);
  const classes = editTemplateSlotsStyles();

  const [showQuotaDrawer, setShowQuotaDrawer] = useState(false);
  const [quotaSettingsAction, setQuotaSettingsViewAction] = useState(QUOTA_VIEW_OPTIONS.MODIFY);
  const [selectedSlot, setSelectedSlot] = useState(null);

  const handleQuotaSettingsViewOption = (action, slot = null) => {
    setQuotaSettingsViewAction(action);
    setSelectedSlot(slot);
    setShowQuotaDrawer(true);
  };

  const renderSlotsForDay = () => {
    return (
      <div>
        {`Slots for : `}
        <span className={classes.dayStyles}>{days[0].toLowerCase()}</span>
        {dayWiseSlots && dayWiseSlots.length && dayWiseSlots.some((gs) => gs.isUpdated || gs.isCreated) ? <CheckedIcon className={classes.isSlotUpdatedIconStyle} /> : null}
      </div>
    );
  };

  const renderTemplateSlots = () => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={3} className={classes.slotsTableLabel}>
            Slots
          </Grid>
          <Grid item xs={isQuotaConfigured ? 3 : 2} className={classes.slotsTableLabel}>
            Capacity
          </Grid>
          <Grid item xs={3} className={classes.slotsTableLabel}>
            Buffer Time (Hrs/Mins)
          </Grid>
          <Grid item xs={2} className={classes.slotsTableLabel}>
            Order Cut-off
          </Grid>
          {isQuotaConfigured ? (
            <Grid item xs={1} className={classes.slotsTableLabel}>
              Quota
            </Grid>
          ) : null}
          <Grid item xs={1} className={classes.slotsTableLabel}>
            Actions
          </Grid>
        </Grid>

        <div className={classes.slotsContainer}>
          {generatedSlots.map(
            (slot, index) =>
              slot !== undefined &&
              slot.slotAction !== undefined &&
              slot.slotAction !== "" &&
              slot.slotAction !== "DELETE" && (
                <TemplateSlotInEditMode
                  key={slot.time}
                  slot={slot}
                  showQuotaColumn={isQuotaConfigured}
                  reduxSlot={dayWiseSlots && dayWiseSlots.length ? dayWiseSlots.find((d) => d.id === slot.id) : {}}
                  canSlotBeDeleted={!!(dayWiseSlots && dayWiseSlots.length !== 1)}
                  handleIndividualSlotFieldsChange={(e) => handleIndividualSlotFieldsChange(index, e)}
                  handleIndividualSlotCheckbox={(e) => handleIndividualSlotCheckbox(index, e)}
                  handleIndividualSlotTimeChange={(date, elem) => handleIndividualSlotTimeChange(index, date, elem)}
                  makeSlotEditable={(slot) => makeSlotEditable(index, slot)}
                  cancelEditingASlot={(slot) => cancelEditingASlot(index, slot)}
                  handleUpdateTemplateSlot={(slot) => updateTemplateSlot(index, slot)}
                  handleDeleteTemplateSlot={(slot) => deleteTemplateSlot(index, slot)}
                  handleSaveNewTemplateSlot={(slot) => saveNewTemplateSlot(index, slot)}
                  handleViewQuotaSettings={handleQuotaSettingsViewOption}
                  configurationBulkUpdatedObj={configurationBulkUpdatedObj}
                  generatedSlots={generatedSlots}
                  userInfo={userInfo}
                />
              )
          )}
        </div>
      </>
    );
  };

  return (
    <Accordion defaultExpanded={false} className={serviceTypeName.toUpperCase() === SERVICE_TYPES.CLICK_N_COLLECT ? classes.greyBackground : classes.whiteBackground}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.slotsAccordion}>
        <Grid container>
          <Grid item xs={6}>
            {renderSlotsForDay()}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.generatedSlotsAccordionDetails}>
        <Grid item xs={12} className={classes.generatedSlotsInfoGridItem}>
          {generatedSlots !== undefined && generatedSlots.length > 0 && renderTemplateSlots()}
        </Grid>

        <Grid item xs={12} className={classes.generatedSlotsInfoGridItem}>
          <LinkButton buttonLabel="Add New Slot" onClick={() => addNewTemplateSlot(days)} />
        </Grid>

        {/* {is_quota_addition_allowed(userInfo) && serviceTypeName !== undefined && serviceTypeName !== "" && serviceTypeName.toUpperCase() === SERVICE_TYPES.STANDARD && (
          <Grid item xs={12} className={classes.generatedSlotsInfoGridItem}>
            <LinkButton buttonLabel={!isQuotaConfigured ? "Create Quota Settings" : "Edit Quota Settings"} onClick={() => handleQuotaSettingsViewOption(QUOTA_VIEW_OPTIONS.MODIFY)} />
          </Grid>
        )} */}
        {/* DOS 1680 March 2023  */}
        {generatedSlots?.length > 0 ? (
          configurationBulkUpdatedObj?.bulkUpdatedDays?.length == 0 ? (
            <Grid item xs={12} className={classes.bulkUpdateItemGridMain}>
              <BulkUpdateInfoIcon className={classes.bulkUpdateInfoIconStyle} />
              <Typography className={classes.bulkUpdateInfoSpanStyle}>{`You can now duplicate the above configuration for other days as well`}</Typography>
              <Typography className={classes.bulkUpdateLinkSpanStyle} onClick={handleBulkUpdateAction}>{`Click here to bulk update`}</Typography>
            </Grid>
          ) : configurationBulkUpdatedObj !== undefined &&
            configurationBulkUpdatedObj.bulkUpdatedDays !== undefined &&
            Array.isArray(configurationBulkUpdatedObj.bulkUpdatedDays) &&
            configurationBulkUpdatedObj.bulkUpdatedDays.length > 0 &&
            resetCurrentConfigurationAfterBulkUpdateRevert !== undefined &&
            days !== undefined &&
            Array.isArray(days) &&
            days.length !== undefined &&
            days[0] !== undefined &&
            days[0] !== "" &&
            configurationBulkUpdatedObj.bulkUpdatedDays.indexOf(days[0]) > -1 ? (
            <Grid item xs={12} className={classes.bulkUpdateRevertItemGridMain}>
              <Typography className={classes.bulkUpdateRevertSpanStyle}>{`Configuration for this days is changed in bulk. Want to revert back ?`}</Typography>
              <Typography className={classes.bulkUpdateRevertLinkSpanStyle} onClick={() => resetCurrentConfigurationAfterBulkUpdateRevert(days[0])}>{`Click here to revert back`}</Typography>
            </Grid>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
        {/* DOS 1680 March 2023 End */}
      </AccordionDetails>

      {showQuotaDrawer ? (
        <QuotaSettings
          action={quotaSettingsAction}
          open={showQuotaDrawer}
          handleClose={() => setShowQuotaDrawer(false)}
          selectedSlot={selectedSlot}
          quotas={quotas}
          handleModifyQuotaSettings={handleModifyQuotaSettings}
        />
      ) : null}
    </Accordion>
  );
};

export default EditTemplateSlots;

EditTemplateSlots.defaultProps = {
  serviceTypeName: "",
};
