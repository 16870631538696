import { is_user_admin, is_user_maf_admin_or_super_admin } from "../../../utils/ApplicationUtils";

export const is_user_creating_updating_pos = (userInfo: any) => {
  return is_user_maf_admin_or_super_admin(userInfo);
};

export const is_user_creating_approval_request = (userInfo: any) => {
  return is_user_admin(userInfo);
};

export const is_zone_delete_allowed = (userInfo: any) => {
  return is_user_maf_admin_or_super_admin(userInfo);
};

export const is_zone_add_allowed = (userInfo: any) => {
  return is_user_maf_admin_or_super_admin(userInfo);
};

export const is_product_type_visible = (userInfo: any) => {
  return is_user_maf_admin_or_super_admin(userInfo);
};

export const is_first_slot_service_cut_off_visible = (userInfo: any) => {
  return is_user_maf_admin_or_super_admin(userInfo);
};

export const is_slot_optimization_visible = (userInfo: any) => {
  return is_user_maf_admin_or_super_admin(userInfo);
};

export const is_creating_new_pos = (userInfo: any) => {
  return is_user_maf_admin_or_super_admin(userInfo);
};

export const is_quota_addition_allowed = (userInfo: any) => {
  return is_user_maf_admin_or_super_admin(userInfo);
};

export const is_standard_slot_time_checkbox_visible = (userInfo: any) => {
  return is_user_maf_admin_or_super_admin(userInfo);
};
