import React from "react";

import { Checkbox, Collapse, FormControlLabel, Grid, IconButton, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";

import { userStyles } from "./CustomPosZoneMappingStyle";
import { TOTAL_AREAS } from "../../SingleUsePlastic/CurrentConfiguration/Utils/PlasticBagCurrentCofigurationConstants";
import { getSingleUsePlasticTotalAreasListColumns, SERVICE_TYPE } from "./Utils/CustomPosZoneMappingUtils";
import DownArrowIcon from "../../assets/svg/DownArrow.svg";
import { capitalizeFirstLetter } from "../../../utils/DataUtils";

import TextBox from "../TextBox";
import searchIcon from "../../assets/svg/SearchIcon.svg";
import Loader from "../Loader/Loader";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";

interface CustomPosZoneMappingInterface {
  title: any;
  titleCount: any;
  searchTextValue: any;
  searhTextName: any;
  handlePosSearchTextChange: any;
  fetchingList: any;
  posZoneServiceDataList: any;
  selectedPosServices: any;
  updatePosNumberOpenIndex: any;
  headerRowCheckboxOnchange: any;
  headerMainRowCheckboxOnchange: any;
  headerInnerRowCheckboxOnchange: any;
  totalRowsCountData: any;
  handleSearch: any;
}

const CustomPosZoneMapping = (props: CustomPosZoneMappingInterface) => {
  const classes: any = userStyles();
  const {
    title,
    titleCount,
    searchTextValue,
    searhTextName,
    handlePosSearchTextChange,
    fetchingList,
    posZoneServiceDataList,
    selectedPosServices,
    updatePosNumberOpenIndex,
    headerRowCheckboxOnchange,
    headerMainRowCheckboxOnchange,
    headerInnerRowCheckboxOnchange,
    totalRowsCountData,
    handleSearch,
  } = props;
  const tableHeaderColumns = getSingleUsePlasticTotalAreasListColumns();

  const renderPosZoneRecordList = () => {
    return (
      <Table padding="none" size="small" stickyHeader={true}>
        <TableHead>
          <TableRow>
            {tableHeaderColumns && Array.isArray(tableHeaderColumns) && tableHeaderColumns.length > 0 ? (
              tableHeaderColumns.map((header: any, headerIndex: any) =>
                headerIndex === 0 ? (
                  <TableCell key={`${headerIndex}-tableHeaderColumns-checkbox`} align="left" padding="none" size="small" sortDirection={false} width="10%" className={classes.tableDataRowHeaderCell}>
                    <FormControlLabel
                      key={`headerColumns-${headerIndex}-checkbox`}
                      control={
                        <Checkbox
                          name={"tableHeaderColumns"}
                          checked={selectedPosServices && selectedPosServices.length && selectedPosServices.length > 0 && selectedPosServices.length === totalRowsCountData ? true : false}
                          onChange={headerRowCheckboxOnchange}
                        />
                      }
                      label={""}
                    />
                  </TableCell>
                ) : header.headerName === SERVICE_TYPE ? (
                  <TableCell
                    key={`${headerIndex}-tableHeaderColumns-service-type-${headerIndex}`}
                    align="left"
                    padding="none"
                    size="small"
                    width={header.width}
                    className={classes.tableDataRowHeaderCell}
                  >
                    <div className={classes.serviceTypeTopDiv}>
                      <Typography className={classes.tableDataHeaderCell}>{header.headerName}</Typography>
                      <IconButton disableRipple size="small" className={classes.iconButtonStyle}>
                        <img src={DownArrowIcon} alt="DropdownIcon" className={classes.downArrowIconStyle} />
                      </IconButton>
                    </div>
                  </TableCell>
                ) : (
                  <TableCell key={`${headerIndex}-tableHeaderColumns-row-${headerIndex}`} align="left" padding="none" size="small" width={header.width} className={classes.tableDataRowHeaderCell}>
                    <Typography className={classes.tableDataHeaderCell}>{header.headerName}</Typography>
                  </TableCell>
                )
              )
            ) : (
              <></>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {posZoneServiceDataList && Array.isArray(posZoneServiceDataList) && posZoneServiceDataList.length > 0 ? (
            posZoneServiceDataList.map((posList: any, posListIndex: any) => (
              <>
                <TableRow key={`inner-posZoneServiceDataList-${posListIndex}`}>
                  <TableCell align="left" padding="none" size="small" width="10%" className={classes.tableDataRowCell}>
                    <FormControlLabel
                      key={`check-inner-posZoneServiceDataList-${posListIndex}-checkbox`}
                      control={<Checkbox name={"posZoneServiceDataList"} checked={posList.isChecked} onChange={(e) => headerMainRowCheckboxOnchange(e, posList)} />}
                      label={""}
                    />
                  </TableCell>
                  <TableCell align="left" padding="none" size="small" width="15%" className={classes.tableDataRowCell}>
                    {posList.id}
                    <IconButton
                      disableRipple={true}
                      disableFocusRipple={true}
                      size="small"
                      className={classes.downArrowIconButtonStyle}
                      onClick={() => {
                        updatePosNumberOpenIndex(posList.id, posListIndex);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {posList.isOpened ? (
                        <img src={DownArrowIcon} alt="DropdownIcon" className={classes.downArrowIconStyleOpen} />
                      ) : (
                        <img src={DownArrowIcon} alt="DropdownIcon" className={classes.downArrowIconStyle} />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell align="left" padding="none" size="small" width="20%" className={classes.tableDataRowCell}>
                    {posList.posName}
                  </TableCell>
                  <TableCell align="left" padding="none" size="small" width="15%" className={classes.tableDataRowCell}>
                    {capitalizeFirstLetter(posList.status) || ""}
                  </TableCell>
                  <TableCell align="left" padding="none" size="small" width="20%" className={classes.tableDataRowCell}>
                    {posList.serviceTypeRecord}
                  </TableCell>
                  <TableCell align="left" padding="none" size="small" width="20%" className={classes.tableDataRowCell}>
                    {posList.zoneTypeListRecord}
                  </TableCell>
                </TableRow>
                {posList.isOpened && (
                  <TableRow>
                    <TableCell className={classes.collapseTableCell} colSpan={6}>
                      <Collapse in={posList.isOpened} timeout="auto" unmountOnExit className={classes.collapseTableCellDiv}>
                        <Table padding="none" size="small" stickyHeader={true}>
                          <TableBody>
                            {posList.zoneServicetypeList && Array.isArray(posList.zoneServicetypeList) && posList.zoneServicetypeList.length > 0 ? (
                              posList.zoneServicetypeList.map((posData: any, posDataIndex: any) => (
                                <TableRow key={`${posDataIndex}-posList.zoneServicetypeList-${posDataIndex}`}>
                                  <TableCell align="left" padding="none" size="small" width="10%" className={classes.tableDataRowCellCollapsed}>
                                    {" "}
                                  </TableCell>
                                  <TableCell align="left" padding="none" size="small" width="15%" className={classes.tableDataRowCellCollapsed}>
                                    <div className={classes.collapsedTopDivInner}>
                                      <FormControlLabel
                                        key={`check-${posDataIndex}-posList-zoneServicetypeList-${posDataIndex}-checkbox`}
                                        control={<Checkbox name={"zoneServicetypeList"} checked={posData.isChecked} onChange={(e) => headerInnerRowCheckboxOnchange(e, posListIndex, posDataIndex)} />}
                                        label={""}
                                      />
                                      <Typography className={classes.tableDataRowCellCollapsedTextStyle}>{posData.posNo}</Typography>
                                      {posData.cncReferenceId && posData.cncReferenceId !== "" && (
                                        <Tooltip title={`CNC Reference Id - ${posData.cncReferenceId}`}>
                                          <PriorityHighIcon style={{ color: "#2D2D2D", width: "20px", height: "20px", margin: "auto 8px", padding: 0, transform: "rotate(180deg)" }} />
                                        </Tooltip>
                                      )}
                                    </div>
                                  </TableCell>
                                  <TableCell align="left" padding="none" size="small" width="20%" className={classes.tableDataRowCellCollapsed}>
                                    {posData.posName}
                                  </TableCell>
                                  <TableCell align="left" padding="none" size="small" width="15%" className={classes.tableDataRowCellCollapsed}>
                                    {capitalizeFirstLetter(posData.status) || ""}
                                  </TableCell>
                                  <TableCell align="left" padding="none" size="small" width="20%" className={classes.tableDataRowCellCollapsed}>
                                    {capitalizeFirstLetter(posData?.serviceType)}
                                  </TableCell>
                                  <TableCell align="left" padding="none" size="small" width="20%" className={classes.tableDataRowCellCollapsed}>
                                    {posData.zoneName}
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow key={`posZoneServiceDataList-${posListIndex}-no-data`}>
                                <TableCell colSpan={5}>No data available</TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} padding="none" align="center" className={classes.noDataCellStyle}>
                No data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  };

  return (
    <Grid className={classes.rootDiv}>
      <Grid className={classes.containerDiv}>
        <Typography className={classes.containerTitle}>{`${title || TOTAL_AREAS} (${titleCount || "0"})`}</Typography>
        <TextBox
          textBoxId="searchTextBox"
          placeholderText="Quick search by Pos number"
          endAdornment={
            <InputAdornment position="end">
              <IconButton edge="end" size="small">
                <img src={searchIcon} alt="search" onClick={handleSearch} />
              </IconButton>
            </InputAdornment>
          }
          value={searchTextValue}
          name={searhTextName}
          handleChange={handlePosSearchTextChange}
          className={classes.searchBoxGridDiv}
        ></TextBox>

        <Grid item xs={12} className={classes.tableMainGridDiv}>
          <>
            {fetchingList ? <Loader /> : null}
            {renderPosZoneRecordList()}
          </>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(CustomPosZoneMapping);
