import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";

import { produce } from "immer";

import ruleEngineStyles from "./RuleEnginePageStyles.js";

import Grid from "@material-ui/core/Grid";

import CustomAlert from "../common/CustomAlert/CustomAlert";
import PrimaryButton from "../common/PrimaryButton/PrimaryButton";
import PreviewDeliveryPromise from "./PreviewDeliveryPromise/PreviewDeliveryPromise";
import ServiceRuleEngine from "./ServiceRuleEngine/ServiceRuleEngine";

import { selectUserDetails } from "../Login/redux/userSlice";
import { RuleEngineCurrentConfigButtonAccess } from "./Utils/RuleEngineAccessUtils";
import { ALERT_TYPES, APP_ROUTES, SERVICE_TYPES, STATUS_OK } from "../../config/constants";
import { getDeliveryRuleEngineConfig, saveDeliveryRuleEngineConfig } from "./redux/ruleEngineSlice";
import { isUndefined } from "../../utils/DataUtils";
import {
  DEFAULT_RULE_CONFIG,
  DEFAULT_RULE_CONFIG_STATE,
  getConfiguredData,
  valiadteRuleEngineData,
  ALL_DAYS_VALUES,
  getRequestPayloadObject,
  createSaveUpdateRequestPayload,
  AUTOMATED_PROMISE_CONFIG,
  maximumPromiseRuleList,
} from "./RuleEngineUtils";

const RuleEnginePage = () => {
  const classes = ruleEngineStyles();

  const location = useLocation<any>();
  const history = useHistory();
  const dispatch = useDispatch();

  const { currentCountry } = useSelector((state: any) => state.appConfig);
  const userInfo: any = useSelector(selectUserDetails);

  const [expressRuleConfig, setExpressRuleConfig] = useState<DEFAULT_RULE_CONFIG>({ ...DEFAULT_RULE_CONFIG_STATE, serviceType: SERVICE_TYPES.EXPRESS });
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [resetTransferList, setResetTransferList] = useState(false);
  const [currentState, setCurrentConfig] = useState<DEFAULT_RULE_CONFIG>({ ...DEFAULT_RULE_CONFIG_STATE, serviceType: SERVICE_TYPES.EXPRESS });

  const handlePromiseTypeCheckboxChange = (typeOfPromise: string) => {
    setExpressRuleConfig({ ...expressRuleConfig, type: typeOfPromise });
  };

  /**
   * Minimum Promise Time
   */
  const onMinTimeIsSameAsEarlierChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isUndefined(event?.target?.checked)) {
      const { checked } = event.target;
      setExpressRuleConfig({
        ...expressRuleConfig,
        promiseConfig: {
          ...expressRuleConfig.promiseConfig,
          minTimeSameAsEarlier: checked,
          minActive: !checked,
          minTimeHour: "",
          minTimeMinutes: "",
        },
      });
    }
  };

  const handleMinTimeConfigEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isUndefined(event?.target?.checked)) {
      const { checked } = event.target;
      let configChanges: any = checked
        ? { promiseConfig: { ...expressRuleConfig.promiseConfig, enableMinTimeEdit: true, minTimeSameAsEarlier: false, minActive: true } }
        : {
            promiseConfig: {
              ...expressRuleConfig.promiseConfig,
              enableMinTimeEdit: false,
              minTimeSameAsEarlier: true,
              minActive: false,
              minTimeHour: currentState.promiseConfig.minTimeHour,
              minTimeMinutes: currentState.promiseConfig.minTimeMinutes,
            },
          };
      setExpressRuleConfig({ ...expressRuleConfig, ...configChanges });
    }
  };

  const onMinTimeChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    if (!isUndefined(event?.target)) {
      const { value } = event.target;
      setExpressRuleConfig({ ...expressRuleConfig, promiseConfig: { ...expressRuleConfig.promiseConfig, [name]: value } });
    }
  };

  /*
   * Maximum Promise Time
   */
  const onMaxTimeIsSameAsEarlierChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isUndefined(event?.target?.checked)) {
      const { checked } = event.target;
      setExpressRuleConfig({
        ...expressRuleConfig,
        promiseConfig: { ...expressRuleConfig.promiseConfig, maxTimeSameAsEarlier: checked, maxActive: false, maximumPromiseRuleValues: [...maximumPromiseRuleList] },
      });
    }
  };

  const handleMaxTimeConfigEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    let configChanges: any = checked
      ? { promiseConfig: { ...expressRuleConfig.promiseConfig, enableMaxTimeEdit: true } }
      : {
          promiseConfig: {
            ...expressRuleConfig.promiseConfig,
            enableMaxTimeEdit: false,
            maximumPromiseRuleValues: currentState.promiseConfig.maximumPromiseRuleValues,
          },
        };
    setExpressRuleConfig({ ...expressRuleConfig, ...configChanges });
  };

  const onMaximumDeliveryPromiseFieldsChange = (name: string, promiseIndex: number, event: React.ChangeEvent<HTMLInputElement>) => {
    let updated_maximum_promise_rule_values = expressRuleConfig.promiseConfig.maximumPromiseRuleValues.map((a: any) => {
      return { ...a };
    });
    if (name === "configuredDays") {
      updated_maximum_promise_rule_values[promiseIndex][name] = event;
      setExpressRuleConfig({ ...expressRuleConfig, promiseConfig: { ...expressRuleConfig.promiseConfig, maximumPromiseRuleValues: [...updated_maximum_promise_rule_values] } });
    } else {
      if (!isUndefined(event?.target?.value)) {
        if (promiseIndex >= 0) {
          const { value } = event.target;
          updated_maximum_promise_rule_values[promiseIndex][name] = value;
          setExpressRuleConfig({ ...expressRuleConfig, promiseConfig: { ...expressRuleConfig.promiseConfig, maximumPromiseRuleValues: [...updated_maximum_promise_rule_values] } });
        }
      }
    }
  };

  const isAddMaximumDeliveryPromiseOtherDaysButtonDisabled = (): boolean => {
    let isButtonDisabled: boolean = false;
    let daysSelectedSoFar = expressRuleConfig.promiseConfig.maximumPromiseRuleValues.reduce((days: any, value: any) => [...days, ...value.configuredDays], []);
    let remainingDays = ALL_DAYS_VALUES.filter((day) => !daysSelectedSoFar.includes(day));
    if (remainingDays.length === 0) {
      isButtonDisabled = true;
    }
    return isButtonDisabled;
  };

  const onAddMaximumDeliveryPromiseOtherDays = () => {
    let updated_maximum_promise_rule_values = [...expressRuleConfig.promiseConfig.maximumPromiseRuleValues];
    updated_maximum_promise_rule_values.push({ configuredDays: [], maxTimeHour: "", maxTimeMinutes: "" });
    setExpressRuleConfig({ ...expressRuleConfig, promiseConfig: { ...expressRuleConfig.promiseConfig, maximumPromiseRuleValues: [...updated_maximum_promise_rule_values] } });
  };

  const onRemoveMaximumDeliveryPromiseOtherDays = (removalIndex: number) => {
    let updated_maximum_promise_rule_values = [...expressRuleConfig.promiseConfig.maximumPromiseRuleValues];
    updated_maximum_promise_rule_values.splice(removalIndex, 1);
    setExpressRuleConfig({ ...expressRuleConfig, promiseConfig: { ...expressRuleConfig.promiseConfig, maximumPromiseRuleValues: [...updated_maximum_promise_rule_values] } });
  };

  const handleMaxPromiseTimeConfigEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event && event?.target) {
      const { checked } = event.target;
      setExpressRuleConfig({
        ...expressRuleConfig,
        promiseConfig: { ...expressRuleConfig.promiseConfig, maxActive: checked, maxTimeSameAsEarlier: false, maximumPromiseRuleValues: [...maximumPromiseRuleList] },
      });
    }
  };

  /**
   * Automated Promise
   */
  const handleAutomatedRuleConfigEdit = (event: React.ChangeEvent<HTMLInputElement>, keyName: string) => {
    if (keyName !== undefined && keyName !== "" && event !== undefined && event.target !== undefined) {
      const { checked } = event.target;
      let configChanges: any = {};
      switch (keyName) {
        case AUTOMATED_PROMISE_CONFIG.LAST_MILE_TIME_AUTOMATED_CONFIG:
          configChanges = checked ? { enableLastMileTimeAutomatedConfigPromiseRuleEdit: true } : { enableLastMileTimeAutomatedConfigPromiseRuleEdit: false, lastMileTimeHour: "", lastMileTimeMin: "" };
          setExpressRuleConfig({ ...expressRuleConfig, ...configChanges });
          break;
      }
      setExpressRuleConfig({ ...expressRuleConfig, ...configChanges });
    }
  };

  const onAutomatedPromiseFieldsChange = (name: string, event: any, keyName: string) => {
    if (name !== undefined && event !== undefined && event.target !== undefined && event.target.value !== undefined) {
      if (keyName !== undefined && keyName !== "") {
        switch (keyName) {
          case AUTOMATED_PROMISE_CONFIG.LAST_MILE_TIME_AUTOMATED_CONFIG:
            setExpressRuleConfig({ ...expressRuleConfig, lastmileTimeAutomatedConfig: { ...expressRuleConfig.lastmileTimeAutomatedConfig, [name]: event.target.value } });
            break;
        }
      }
    }
  };

  /**
   *  Promise
   */
  const handleUseForConfigurationSwitchChange = (serviceName: string, ruleName: string, event: any) => {
    if (event !== undefined && event.target !== undefined) {
      const { checked } = event.target;
      let valuesSet: any = DEFAULT_RULE_CONFIG_STATE[ruleName as keyof DEFAULT_RULE_CONFIG];
      setExpressRuleConfig({ ...expressRuleConfig, [ruleName]: { ...valuesSet, active: checked, sameAsEarlier: false } });
    }
  };

  const handleIsSameAsEarlierCheckbox = (serviceName: string, ruleName: string, event: any) => {
    if (event !== undefined && event.target !== undefined) {
      const { checked } = event.target;
      let valuesSet: any = DEFAULT_RULE_CONFIG_STATE[ruleName as keyof DEFAULT_RULE_CONFIG];
      setExpressRuleConfig({ ...expressRuleConfig, [ruleName]: { ...valuesSet, sameAsEarlier: checked } });
    }
  };

  const handleWeightRuleFieldsChange = (serviceName: string, e: any) => {
    const { name, value } = e.target;
    setExpressRuleConfig({ ...expressRuleConfig, weightRule: { ...expressRuleConfig.weightRule, [name]: value } });
  };

  const handleCountRuleFieldsChange = (serviceName: string, e: any) => {
    const { name, value } = e.target;
    setExpressRuleConfig({ ...expressRuleConfig, countRule: { ...expressRuleConfig.countRule, [name]: value } });
  };

  const handleAddScalableItems = (serviceName: string) => {
    let newItem: any = { section: "", incrementalTime: "" };
    let newExpressConfig = produce(expressRuleConfig, (draft: any) => {
      draft.scalableTypeRule.itemList.push(newItem);
    });
    setExpressRuleConfig(newExpressConfig);
  };

  const handleRemoveScalableItems = (serviceName: string, itemIndex: number) => {
    let newExpressConfig = produce(expressRuleConfig, (draft: any) => {
      draft.scalableTypeRule.itemList.splice(itemIndex, 1);
    });
    setExpressRuleConfig(newExpressConfig);
  };

  const handleScalableItemsFieldChange = (serviceName: string, itemIndex: number, e: any) => {
    const { name, value } = e.target;
    let newExpressConfig = produce(expressRuleConfig, (draft: any) => {
      draft.scalableTypeRule.itemList[itemIndex][name] = value;
    });
    setExpressRuleConfig(newExpressConfig);
  };

  const handlePOSZonesSelectChange = (serviceName: string, selectedZonesList = []) => {
    let newExpressConfig = produce(expressRuleConfig, (draft: any) => {
      draft.posZoneIdentifierList = selectedZonesList;
    });
    setExpressRuleConfig(newExpressConfig);
  };

  const handleSaveDeliveryRuleEngineConfig = async () => {
    setIsSaving(true);
    let expressConfigPayload = createSaveUpdateRequestPayload(expressRuleConfig, currentCountry);
    const { payload }: any = await dispatch(saveDeliveryRuleEngineConfig(expressConfigPayload));
    setIsSaving(false);
    setShowPreviewModal(false);
    if (payload.message) {
      CustomAlert(ALERT_TYPES.ERROR, payload.message);
    }
    if (payload.status === STATUS_OK) {
      if (expressRuleConfig.isEditMode) {
        CustomAlert(ALERT_TYPES.SUCCESS, "Delivery Rule Engine configurations updated successfully");
      } else {
        CustomAlert(ALERT_TYPES.SUCCESS, "Delivery Rule Engine configurations saved successfully");
      }
      history.push(APP_ROUTES.RULE_ENGINE);
    }
  };

  const handleWeightRuleConfigEdit = (e: any) => {
    const { checked } = e.target;
    let configChanges: any = checked ? { enableWeightRuleEdit: true } : { enableWeightRuleEdit: false, weightRule: currentState.weightRule };
    setExpressRuleConfig({ ...expressRuleConfig, ...configChanges });
  };

  const handleCountRuleConfigEdit = (e: any) => {
    const { checked } = e.target;
    let configChanges: any = checked ? { enableCountRuleEdit: true } : { enableCountRuleEdit: false, countRule: currentState.countRule };
    setExpressRuleConfig({ ...expressRuleConfig, ...configChanges });
  };

  const handleScaleRuleConfigEdit = (e: any) => {
    const { checked } = e.target;
    let configChanges: any = checked ? { enableScaleRuleEdit: true } : { enableScaleRuleEdit: false, scalableTypeRule: currentState.scalableTypeRule };
    setExpressRuleConfig({ ...expressRuleConfig, ...configChanges });
  };

  //
  // Order Velocity
  const handleOrderVelocityIsSameAsEarlierChange = (event: any) => {
    if (event && event.target) {
      const { checked } = event.target;
      setExpressRuleConfig({ ...expressRuleConfig, orderVelocityRule: { sameAsEarlier: checked, active: false, orderVelocityRuleValues: [] } });
    }
  };

  const handleOrderVelocityConfigEdit = (event: any) => {
    if (event && event.target) {
      const { checked } = event.target;

      setExpressRuleConfig({
        ...expressRuleConfig,
        orderVelocityRule: { ...expressRuleConfig.orderVelocityRule, active: checked, orderVelocityRuleValues: [{ configuredDays: [], timeRange: "", orderThreshold: "", extraPromiseTime: "" }] },
      });
    }
  };

  const onOrderVelocityFieldsChange = (name: any, configured_obj_index: any, event: any) => {
    let updated_order_velocity_rule_values = expressRuleConfig.orderVelocityRule.orderVelocityRuleValues.map((a: any) => {
      return { ...a };
    });
    if (name === "configuredDays") {
      updated_order_velocity_rule_values[configured_obj_index][name] = event;
      setExpressRuleConfig({ ...expressRuleConfig, orderVelocityRule: { ...expressRuleConfig.orderVelocityRule, orderVelocityRuleValues: [...updated_order_velocity_rule_values] } });
    } else {
      if (event && event.target) {
        if (configured_obj_index !== undefined && configured_obj_index >= 0) {
          const { value } = event.target;
          updated_order_velocity_rule_values[configured_obj_index][name] = value;
          setExpressRuleConfig({ ...expressRuleConfig, orderVelocityRule: { ...expressRuleConfig.orderVelocityRule, orderVelocityRuleValues: [...updated_order_velocity_rule_values] } });
        }
      }
    }
  };

  const handleOrderVelocityRuleConfigEdit = (e: any) => {
    const { checked } = e.target;
    let configChanges: any = checked ? { enableOrderVelocityRuleEdit: true } : { enableOrderVelocityRuleEdit: false, orderVelocityRule: currentState.orderVelocityRule };
    setExpressRuleConfig({ ...expressRuleConfig, ...configChanges });
  };

  const isAddOtherDaysButtonDisabled = () => {
    let isButtonDisabled = false;
    let daysSelectedSoFar = expressRuleConfig.orderVelocityRule.orderVelocityRuleValues.reduce((days: any, value: any) => [...days, ...value.configuredDays], []);
    let remainingDays = ALL_DAYS_VALUES.filter((day) => !daysSelectedSoFar.includes(day));
    if (remainingDays.length === 0) {
      isButtonDisabled = true;
    }
    return isButtonDisabled;
  };

  const onAddOtherDays = () => {
    let updated_order_velocity_rule_values = [...expressRuleConfig.orderVelocityRule.orderVelocityRuleValues];
    updated_order_velocity_rule_values.push({ configuredDays: [], timeRange: "", orderThreshold: "", extraPromiseTime: "" });
    setExpressRuleConfig({ ...expressRuleConfig, orderVelocityRule: { ...expressRuleConfig.orderVelocityRule, orderVelocityRuleValues: [...updated_order_velocity_rule_values] } });
  };

  const onRemoveOtherDays = (removal_index: any) => {
    let updated_order_velocity_rule_values = [...expressRuleConfig.orderVelocityRule.orderVelocityRuleValues];
    updated_order_velocity_rule_values.splice(removal_index, 1);
    setExpressRuleConfig({ ...expressRuleConfig, orderVelocityRule: { ...expressRuleConfig.orderVelocityRule, orderVelocityRuleValues: [...updated_order_velocity_rule_values] } });
  };

  // Vehicle Type
  const handleTypeOfVehicleIsSameAsEarlierChange = (event: any) => {
    // Use existing configuration
    if (event && event.target) {
      const { checked } = event.target;
      setExpressRuleConfig({
        ...expressRuleConfig,
        vehicleTypeRule: {
          sameAsEarlier: checked,
          active: false,
          // Checkbox
          bikeThresholdActive: false,
          carThresholdActive: false,
          otherThresholdActive: false,
          // Values
          thresholdFrom: "",
          bikeThresholdTo: "",
          carThresholdTo: "",
          otherThresholdTo: "",
        },
      });
    }
  };

  const handleTypeOfVehicleConfigEdit = (event: any) => {
    if (event && event.target) {
      const { checked } = event.target;
      setExpressRuleConfig({ ...expressRuleConfig, vehicleTypeRule: { ...expressRuleConfig.vehicleTypeRule, active: checked } });
    }
  };

  const handleTypeOfVehicleRuleConfigEdit = (e: any) => {
    const { checked } = e.target;
    let configChanges: any = checked ? { enableVehicleTypeRuleEdit: true } : { enableVehicleTypeRuleEdit: false, vehicleTypeRule: currentState.vehicleTypeRule };
    setExpressRuleConfig({ ...expressRuleConfig, ...configChanges });
  };

  const handleVehicleTypeConfigActiveCheckbox = (event: any, thresholdName: any) => {
    if (event && event.target !== undefined && thresholdName !== undefined && thresholdName !== "") {
      const { checked } = event.target;
      if (thresholdName === "BIKE") {
        setExpressRuleConfig({
          ...expressRuleConfig,
          vehicleTypeRule: {
            ...expressRuleConfig.vehicleTypeRule,
            bikeThresholdActive: checked,
            thresholdFrom: 0,
            bikeThresholdTo: 0,
          },
        });
      } else if (thresholdName === "CAR") {
        setExpressRuleConfig({
          ...expressRuleConfig,
          vehicleTypeRule: {
            ...expressRuleConfig.vehicleTypeRule,
            carThresholdActive: checked,
            thresholdFrom: 0,
            bikeThresholdTo: expressRuleConfig.vehicleTypeRule.bikeThresholdActive === true ? expressRuleConfig.vehicleTypeRule.bikeThresholdTo : 0,
            carThresholdTo: 0,
          },
        });
      } else if (thresholdName === "OTHER") {
        setExpressRuleConfig({
          ...expressRuleConfig,
          vehicleTypeRule: {
            ...expressRuleConfig.vehicleTypeRule,
            otherThresholdActive: checked,
            thresholdFrom: 0,
            carThresholdTo:
              expressRuleConfig.vehicleTypeRule.carThresholdActive === true
                ? expressRuleConfig.vehicleTypeRule.carThresholdTo
                : expressRuleConfig.vehicleTypeRule.bikeThresholdActive === true
                ? expressRuleConfig.vehicleTypeRule.bikeThresholdTo
                : 0,
            otherThresholdTo: 0,
          },
        });
      }
    }
  };

  const onTypeOfVehicleFromToFieldsChange = (event: any, thresholdName: any) => {
    if (thresholdName !== undefined && thresholdName !== "") {
      if (event && event.target) {
        const { value } = event.target;
        setExpressRuleConfig({ ...expressRuleConfig, vehicleTypeRule: { ...expressRuleConfig.vehicleTypeRule, [thresholdName]: value } });
      }
    }
  };

  const renderButtons = () => {
    return (
      <Grid container className={classes.footerDivStyle} spacing={1}>
        <Grid item xs={2}>
          <Link to={{ pathname: `${APP_ROUTES.RULE_ENGINE}` }} style={{ textDecoration: "none" }}>
            <PrimaryButton className={classes.cancelConfigStyle} buttonLabel="Back To List" disableFocusRipple={true} disableRipple={true} />
          </Link>
        </Grid>
        <Grid item xs={5}>
          {""}
        </Grid>
        <Grid item xs={2}>
          <PrimaryButton
            className={classes.resetConfigStyle}
            buttonLabel="Reset"
            onClick={() => {
              if (!expressRuleConfig.isEditMode) {
                setResetTransferList((prev) => !prev);
              }
              setExpressRuleConfig({ ...currentState });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <PrimaryButton
            buttonLabel="PREVIEW AND UPDATE"
            onClick={() => {
              valiadteRuleEngineData(expressRuleConfig) && setShowPreviewModal(true);
            }}
            className={classes.buttonStyle}
          />
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    const getCurrentConfig = async () => {
      let params = new URLSearchParams(location.search);
      if (location.search) {
        setIsSaving(true);
        let requestPayload = getRequestPayloadObject(params);
        if (requestPayload.countryId && requestPayload.serviceType && requestPayload.posId && requestPayload.zoneId) {
          const { payload }: any = await dispatch(getDeliveryRuleEngineConfig({ requestPayload }));
          if (payload && payload.data) {
            setIsSaving(false);
            let Info = getConfiguredData(payload.data);
            setExpressRuleConfig({ ...expressRuleConfig, ...Info });
            setCurrentConfig({ ...currentState, ...Info });
          }
        } else {
          setExpressRuleConfig({ ...DEFAULT_RULE_CONFIG_STATE, serviceType: SERVICE_TYPES.EXPRESS });
          setCurrentConfig({ ...DEFAULT_RULE_CONFIG_STATE, serviceType: SERVICE_TYPES.EXPRESS });
        }
      }
    };
    getCurrentConfig();
  }, [location.search]);

  return (
    <div className={classes.root}>
      <div className={classes.headerRoot}>
        <div className={classes.header}>
          <span className={classes.heading}>{`Delivery Promise Rule Engine Set up`}</span>
        </div>
        <RuleEngineCurrentConfigButtonAccess userInfo={userInfo} classes={classes} />
      </div>
      <ServiceRuleEngine
        serviceName={expressRuleConfig.serviceType}
        config={expressRuleConfig}
        handlePromiseTypeCheckboxChange={handlePromiseTypeCheckboxChange} // Promise Type Change
        // Minimum Promise Time
        onMinTimeIsSameAsEarlierChange={onMinTimeIsSameAsEarlierChange}
        handleMinTimeConfigEdit={handleMinTimeConfigEdit}
        onMinTimeChange={onMinTimeChange}
        // Maximum Promise Time
        onMaxTimeIsSameAsEarlierChange={onMaxTimeIsSameAsEarlierChange}
        handleMaxTimeConfigEdit={handleMaxTimeConfigEdit}
        isAddMaximumDeliveryPromiseOtherDaysButtonDisabled={isAddMaximumDeliveryPromiseOtherDaysButtonDisabled}
        onAddMaximumDeliveryPromiseOtherDays={onAddMaximumDeliveryPromiseOtherDays}
        onMaximumDeliveryPromiseFieldsChange={onMaximumDeliveryPromiseFieldsChange}
        onRemoveMaximumDeliveryPromiseOtherDays={onRemoveMaximumDeliveryPromiseOtherDays}
        handleMaxPromiseTimeConfigEdit={handleMaxPromiseTimeConfigEdit}
        //
        onUseForConfigurationSwitchChange={handleUseForConfigurationSwitchChange}
        onIsSameAsEarlierCheckboxChange={handleIsSameAsEarlierCheckbox}
        onWeightRuleFieldsChange={handleWeightRuleFieldsChange}
        onCountRuleFieldsChange={handleCountRuleFieldsChange}
        onAddScalableItems={handleAddScalableItems}
        onRemoveScalableItems={handleRemoveScalableItems}
        onScalableItemsFieldChange={handleScalableItemsFieldChange}
        onPOSZonesSelected={handlePOSZonesSelectChange}
        onWeightRuleConfigEdit={handleWeightRuleConfigEdit}
        onCountRuleConfigEdit={handleCountRuleConfigEdit}
        onScaleRuleConfigEdit={handleScaleRuleConfigEdit}
        resetTransferList={resetTransferList}
        // Order Velocity
        handleOrderVelocityIsSameAsEarlierChange={handleOrderVelocityIsSameAsEarlierChange}
        handleOrderVelocityConfigEdit={handleOrderVelocityConfigEdit}
        onOrderVelocityFieldsChange={onOrderVelocityFieldsChange}
        handleOrderVelocityRuleConfigEdit={handleOrderVelocityRuleConfigEdit}
        isAddOtherDaysButtonDisabled={isAddOtherDaysButtonDisabled}
        onAddOtherDays={onAddOtherDays}
        onRemoveOtherDays={onRemoveOtherDays}
        // Type of Vehicle
        handleTypeOfVehicleConfigEdit={handleTypeOfVehicleConfigEdit}
        handleTypeOfVehicleIsSameAsEarlierChange={handleTypeOfVehicleIsSameAsEarlierChange}
        handleTypeOfVehicleRuleConfigEdit={handleTypeOfVehicleRuleConfigEdit}
        handleVehicleTypeConfigActiveCheckbox={handleVehicleTypeConfigActiveCheckbox}
        onTypeOfVehicleFromToFieldsChange={onTypeOfVehicleFromToFieldsChange}
        // Automated Promise
        handleAutomatedRuleConfigEdit={handleAutomatedRuleConfigEdit}
        onAutomatedPromiseFieldsChange={onAutomatedPromiseFieldsChange}
      />
      {renderButtons()}
      <PreviewDeliveryPromise
        show={showPreviewModal}
        onClose={() => setShowPreviewModal(false)}
        configDetails={expressRuleConfig}
        isLoading={isSaving}
        onProceed={handleSaveDeliveryRuleEngineConfig}
        updatedConfigInfo={currentState}
      />
    </div>
  );
};

export default RuleEnginePage;
