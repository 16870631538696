import { createStyles, makeStyles } from "@material-ui/core";

export const userStyles = makeStyles(() =>
  createStyles({
    rootDiv: {
      width: "50%",
      height: "100%",
      margin: 0,
      padding: 0,
      background: "#FFFFFF",
    },
    containerDiv: {
      padding: "12px",
      width: "98%",
      height: "100%",
      margin: 0,
      background: "#FFFFFF",
      boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.15)",
      borderRadius: "8px",
    },
    containerTitle: {
      margin: 0,
      padding: 0,
      paddingBottom: "12px",
      fontSize: "16px",
      fontWeight: 600,
      color: "#2D2D2D",
    },
    searchBoxGridDiv: {
      margin: 0,
      padding: "0px 0px 12px 0px",
    },
    tableMainGridDiv: {
      position: "relative",
      width: "100%",
      height: "360px",
      padding: "0px",
      overflow: "auto",
    },

    // Table
    serviceTypeTopDiv: {
      margin: 0,
      padding: 0,
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
      height: "36px",
      textAlign: "center",
    },
    collapsedTopDiv: {
      margin: "0 auto",
      padding: 0,
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "36px",
      textAlign: "center",
    },
    collapsedTopDivInner: {
      margin: "auto 0px",
      padding: 0,
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
      height: "36px",
      textAlign: "center",
    },
    iconButtonStyle: {
      margin: "auto 0",
      padding: 0,
      width: "25px",
      height: "25px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#FFFFFF",
      },
    },
    tableDataRowHeaderCell: {
      padding: 0,
      paddingLeft: "12px",
      height: "36px",
      fontSize: "12px",
      fontWeight: "600",
      lineHeight: "14px",
      color: "#4D4D4D",
      border: "1px solid #E6E6E6",
      background: "#FFFFFF",
      "&:last-child": {
        padding: 0,
        paddingLeft: "12px",
      },
    },
    tableDataRowCell: {
      padding: 0,
      paddingLeft: "8px",
      height: "36px",
      fontSize: "12px",
      fontWeight: 400,
      color: "#2D2D2D",
      border: "1px solid #FFFFFF",
      background: "#FFFFFF",
      "&:last-child": {
        padding: 0,
        paddingLeft: "8px",
      },
    },
    noDataCellStyle: {
      padding: 0,
      margin: 0,
      height: "100px",
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "14px",
      color: "#2D2D2D",
      border: "1px solid #FFFFFF",
      background: "#FFFFFF",
      "&:last-child": {
        padding: 0,
        paddingLeft: "12px",
      },
    },
    tableDataRowCellCollapsed: {
      padding: 0,
      paddingLeft: "8px",
      height: "36px",
      fontSize: "12px",
      fontWeight: 400,
      color: "#2D2D2D",
      border: "1px solid rgba(244, 244, 244, 0.5)",
      background: "rgba(244, 244, 244, 0.5)",
      "&:last-child": {
        padding: 0,
        paddingLeft: "8px",
      },
    },
    tableDataRowCellCollapsedTextStyle: {
      margin: "auto 0",
      padding: 0,
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "14px",
      color: "#2D2D2D",
    },
    tableDataHeaderCell: {
      margin: "auto 0",
      fontSize: "12px",
      fontWeight: "600",
      lineHeight: "14px",
      color: "#2D2D2D",
    },
    downArrowIconButtonStyle: {
      "&:hover": {
        backgroundColor: "#FFFFFF",
      },
    },
    downArrowIconStyleOpen: {
      marginLeft: "8px",
      width: "20px",
      height: "20px",
      cursor: "pointer",
      transform: "rotate(180deg)",
    },
    downArrowIconStyle: {
      marginLeft: "8px",
      width: "20px",
      height: "20px",
      cursor: "pointer",
    },
    collapseTableCell: {
      paddingBottom: 0,
      paddingTop: 0,
      borderBottom: "none",
    },
    collapseTableCellDiv: {
      minHeight: "0px",
      //maxHeight: "200px",
      overflow: "auto",
    },

    // Assigned Table
    assignedListItemStyle: {
      margin: 0,
      padding: 0,
    },
    assignedListStyle: {
      margin: 0,
      marginLeft: "8px",
      padding: 0,
      fontSize: "12px",
      fontWeight: 400,
      textAlign: "left",
    },
    assignedListItemTextStyle: {
      fontSize: "12px",
      fontWeight: 400,
    },
  })
);
