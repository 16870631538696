import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../config/theme/theme";

export const useStyles = makeStyles((theme) =>
  createStyles({
    fieldLabel: { marginBottom: "8px" },
    root: {
      "& .hasLabel": {
        "& .MuiOutlinedInput-input": {
          paddingTop: theme.spacing(2),
          paddingBottom: 5,
          fontWeight: 500,
        },
      },
      "& .MuiOutlinedInput-root.Mui-disabled": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#E6E6E6",
          top: "0px",
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#E6E6E6",
        top: "0px",
      },
    },
    inputStyles: {
      "& .MuiOutlinedInput-input": {
        backgroundColor: COLORS.WHITE,
        borderRadius: "8px",
        fontWeight: 500,
      },
    },
  })
);
