import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../../config/theme/theme";

const topBarHeight = "80";
const drawerFromTop = "106";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {},
  drawerPaper: {
    margin: 0,
    padding: 0,
    height: "calc(100vh - 158px)",
    width: "23vw",
    top: `${drawerFromTop}px`,
    background: "#F5F5F5",
    border: "none",
    overflow: "auto",
    zIndex: 0,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  listStyleMain: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listStyle: {
    color: COLORS.DARK_GRAY,
    height: "60px",
    borderBottom: "1px solid #fff",

    "&.Mui-selected": {
      borderLeft: `3px solid ${COLORS.PRIMARY_MAIN}`,
      backgroundColor: "#F3F6F8 !important",
    },
    "&.Mui-selected span": {
      fontWeight: 600,
      color: "#0E5AA7",
    },
  },
  listIconSVG: {
    marginLeft: "2%",
    fill: COLORS.DARK_GRAY,
  },
  listItemSpan: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    color: COLORS.DARK_GRAY,
  },
  listItemSelected: {
    fontWeight: "600",
  },
  activeStyle: {
    backgroundColor: "#E9EFF2",
    color: "#0E5AA7",
    fill: "#0E5AA7",
  },
  activeIconSVG: {
    marginLeft: "2%",
    fill: "#0E5AA7",
  },
  activeSpan: {
    color: "#0E5AA7",
  },
  nestedUl: {
    padding: 0,
  },
  nested: {
    backgroundColor: "#F5F5F5",
    borderBottom: "1px solid #fff",
    paddingLeft: "75px",
    height: "60px",
    // "&:last-child": {
    //   border: "none",
    // },
    "&.Mui-selected": {
      backgroundColor: "#E9EFF2 !important",
    },
    "&.Mui-selected span": {
      fontWeight: 600,
      color: "#0E5AA7",
    },
  },
  listIconArrow: {
    fill: COLORS.DARK_GRAY,
  },
  bottomDiv: {
    position: "absolute",
    top: "85%",
    left: "20%",
  },
  bottomLogoSVG: {},
  rotateBy270: {
    transform: "rotate(270deg)",
  },
  paperAnchorLeft: {
    left: "auto",
    right: "auto",
  },
}));

export default useStyles;
