import React from "react";

import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  loadingDivMain: {
    display: "table",
    width: "100%",
    height: "100%",
  },
  loadingDivInner: {
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
  },
}));

const DialogLoading = () => {
  const classes = useStyles();

  return (
    <div className={classes.loadingDivMain}>
      <div className={classes.loadingDivInner}>
        <CircularProgress thickness={2} size={25} />
      </div>
    </div>
  );
};

export default DialogLoading;
