import React from "react";

import { Grid } from "@material-ui/core";

import MultiSelectBoxWithLabel from "../../common/MultiSelectBoxWithLabel/MultiSelectBoxWithLabel";
import LinkButton from "../../common/LinkButton/LinkButton";

import { ReactComponent as DeleteIcon } from "../../assets/svg/DeleteIcon.svg";
import DlvFeeTimeConfiguration from "./DlvFeeTimeConfiguration";

interface DlvFeeDayConfigurationInterface {
  state: any;
  configIndex: any;
  dayObject: any;
  dayObjectIndex: any;
  classes: any;
  handleSelectedDaysChange: any;
  removeDayButton: any;
  handleAddTimeButton: any;
  handleConfigTimeChange: any;
  handleThresholdChange: any;
  removeTimeSlot: any;
}

const DlvFeeDayConfiguration = (props: DlvFeeDayConfigurationInterface) => {
  const { state, configIndex, dayObject, dayObjectIndex, classes, handleSelectedDaysChange, removeDayButton, handleAddTimeButton, handleConfigTimeChange, handleThresholdChange, removeTimeSlot } =
    props;

  return (
    <div className={classes.DayBoxContainer}>
      <div className={classes.dateTimeStyle}>
        {state && state.selectedDays && state.selectedDays.length > 0 && (
          <>
            <span>Generated for : </span>
            <span style={{ fontWeight: 600 }}>{` ${dayObject.days.join(", ")}`}</span>
          </>
        )}
      </div>
      <Grid container>
        <Grid item xs={3}>
          <MultiSelectBoxWithLabel
            label={"Days"}
            name="days"
            className={classes.fontStyle}
            menuitems={dayObject.availableDays}
            value={dayObject.days}
            onChange={(e: any) => handleSelectedDaysChange(configIndex, e, dayObjectIndex)}
          />
        </Grid>
        <Grid item xs={8}>
          {""}
        </Grid>
        <Grid item xs={1} className={classes.iconDivStyle}>
          <DeleteIcon onClick={(e: any) => removeDayButton(configIndex, e, dayObjectIndex)} className={classes.iconStyle} />
        </Grid>
      </Grid>

      {dayObject && dayObject.days && Array.isArray(dayObject.days) && dayObject.days.length > 0 && (
        <>
          <Grid container>
            <Grid item xs={8}>
              {""}
            </Grid>
            <Grid item xs={4}>
              <div className={classes.buttonStyle}>
                <LinkButton disabled={false} buttonLabel="Add Time Slots" name="timeButton" onClick={() => handleAddTimeButton(dayObjectIndex, configIndex)} />
              </div>
            </Grid>
          </Grid>

          {dayObject && dayObject.timeZoneConfiguration && Array.isArray(dayObject.timeZoneConfiguration) && dayObject.timeZoneConfiguration.length > 0 ? (
            dayObject.timeZoneConfiguration.map((itemconfig: any, itemconfigIndex: any) => (
              <DlvFeeTimeConfiguration
                key={`${itemconfigIndex}-timeZone-${dayObjectIndex}Configuration-daysConfiguration-configurationState-configuration`}
                classes={classes}
                configIndex={configIndex}
                dayObjectIndex={dayObjectIndex}
                itemconfig={itemconfig}
                itemconfigIndex={itemconfigIndex}
                handleConfigTimeChange={handleConfigTimeChange}
                handleThresholdChange={handleThresholdChange}
                removeTimeSlot={removeTimeSlot}
              />
            ))
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(DlvFeeDayConfiguration);
