import React from "react";
import { Link } from "react-router-dom";

import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Tooltip, Typography } from "@material-ui/core";

import { APP_ROUTES, SERVICE_TYPES, STORE_STATE, ZONE_CHANGE_STATUS } from "../../../../config/constants";
import { PosStatusToggle } from "../../../POSList/posUtils/PosAccessUtils";
import { is_user_can_create_approval_request, is_zone_service_toggle_visible } from "../../CompleteSetupUtils";
import { getKeyNameFromZoneTab } from "../../../../utils/ServiceTypeUtils";

import Loader from "../../../common/Loader/Loader";

import ExpandMoreIcon from "../../../assets/svg/ExpandAccordionIcon.svg";
import RenderDefaultSupplierAllInformation from "../../../DefaultServiceType/RenderDefaultSupplierAllInformation";
import RenderCollectionPoints from "../../../ClickAndCollectServiceType/RenderCollectionPoints";
import RenderServiceAllInformation from "./RenderServiceAllInformation";

import EditIcon from "../../../assets/svg/Pencil.svg";

interface DetailedSlotInformationOnCompleteSetupInterface {
  classes: any;
  userInfo: any;
  toggling: any;
  tab: any;
  tabIndex: any;
  serviceTypeName: any;
  handleActivateDeactivateZoneConfigClick: any;
  handleCollectionPointToggleSwitch: any;
  posNo: any;
  handleExpressStoreStateChange?: any;
}

export const DetailedSlotInformationOnCompleteSetup = (props: DetailedSlotInformationOnCompleteSetupInterface) => {
  const { classes, userInfo, toggling, tab, tabIndex, serviceTypeName, handleActivateDeactivateZoneConfigClick, handleCollectionPointToggleSwitch, posNo, handleExpressStoreStateChange } = props;

  const showCapacityFlag = () => {
    return serviceTypeName !== SERVICE_TYPES.DEFAULT;
  };

  return (
    <Accordion key={tab.zoneConfigId || Math.random()} className={classes.zoneAccordionStyle}>
      <AccordionSummary expandIcon={<img src={ExpandMoreIcon} alt="Expand Accordion Icon" />} className={`${classes.accordionSummary} ${classes.serviceAccSummary}`}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography className={classes.zoneNameTextStyle}>{`${getKeyNameFromZoneTab(tab, "zoneName")} ${
              showCapacityFlag() ? `(Capacity - ${tab.capacityAmountAtZoneLevel || "0"})` : ""
            }`}</Typography>
          </Grid>

          {is_zone_service_toggle_visible(userInfo) ? (
            <Grid item xs={6}>
              <Grid container justifyContent="flex-end">
                {toggling ? (
                  <Grid item xs={5} className={classes.posRelative}>
                    <Loader />
                  </Grid>
                ) : (
                  <>
                    {serviceTypeName === SERVICE_TYPES.EXPRESS && getKeyNameFromZoneTab(tab, "status") === ZONE_CHANGE_STATUS.ACTIVATED && (
                      <Button
                        onClick={(e: any) =>
                          handleExpressStoreStateChange(
                            e,
                            tabIndex,
                            getKeyNameFromZoneTab(tab, "id"),
                            getKeyNameFromZoneTab(tab, "state") === STORE_STATE.AVAILABLE ? STORE_STATE.BUSY : STORE_STATE.AVAILABLE
                          )
                        }
                        className={getKeyNameFromZoneTab(tab, "state") === STORE_STATE.AVAILABLE ? classes.busyZoneStateButtonStyle : classes.availableZoneStateButtonStyle}
                      >
                        {getKeyNameFromZoneTab(tab, "state") === STORE_STATE.AVAILABLE ? "Change to Not Accepting Orders" : "Change to Accepting Orders"}
                      </Button>
                    )}
                    <PosStatusToggle
                      userInfo={userInfo}
                      classes={classes}
                      checked={getKeyNameFromZoneTab(tab, "status") === ZONE_CHANGE_STATUS.ACTIVATED ? true : false}
                      handleServiceActiveInactiveSwitch={(e: any) => handleActivateDeactivateZoneConfigClick(e, tabIndex, getKeyNameFromZoneTab(tab, "id"))}
                      disabled={toggling}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={6}>
              <Grid container justifyContent="flex-end">
                {serviceTypeName === SERVICE_TYPES.EXPRESS && (
                  <Typography className={getKeyNameFromZoneTab(tab, "state") === STORE_STATE.AVAILABLE ? classes.availableZoneSpanStyle : classes.busyZoneSpanStyle}>
                    {getKeyNameFromZoneTab(tab, "state") === STORE_STATE.AVAILABLE ? "Accepting Orders" : "Not Accepting Orders"}
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </AccordionSummary>

      <AccordionDetails className={classes.serviceAccDetails} onClick={(e) => e.stopPropagation()}>
        {serviceTypeName === SERVICE_TYPES.DEFAULT ? (
          <RenderDefaultSupplierAllInformation classes={classes} supplierTypes={tab?.supplierTypes} />
        ) : serviceTypeName === SERVICE_TYPES.CLICK_N_COLLECT ? (
          <RenderCollectionPoints
            classes={classes}
            tab={tab}
            collectionPoints={tab?.collectionPoints}
            userInfo={userInfo}
            handleCollectionPointToggleSwitch={handleCollectionPointToggleSwitch}
            serviceTypeName={serviceTypeName}
          />
        ) : (
          <RenderServiceAllInformation classes={classes} tab={tab} serviceTypeName={serviceTypeName} />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(DetailedSlotInformationOnCompleteSetup);
