import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../config/theme/theme";

export const userStyles = makeStyles(() =>
  createStyles({
    container: {
      marginTop: "80px",
      padding: "24px 12px 24px 24px",
    },
    headerRoot: {
      padding: 0,
      margin: 0,
    },
    header: {
      padding: 0,
      margin: 0,
      display: "flex",
      justifyContent: "flex-start",
      "&.MuiGrid-item": {
        padding: 0,
        margin: 0,
      },
    },
    clearFilterDiv: {
      display: "flex",
      justifyContent: "flex-end",
      margin: "auto 0px",
      "&.MuiGrid-item": {
        padding: "0px 0px 0px 12px",
      },
    },
    heading: {
      fontWeight: 600,
      fontSize: "24px",
      color: COLORS.DARK_GRAY,
    },
    clearFilterSpanStyle: {
      fontWeight: 600,
      fontSize: "14px",
      color: COLORS.DOS_BLUE_DARK,
      cursor: "pointer",
      padding: "0px 0px 0px 8px",
    },
    loaderDiv: {
      height: "calc(75vh)",
    },
    headerGridMainContainerStyle: {
      margin: "24px 0px 16px 0px",
      padding: 0,
      width: "auto",
    },
    countGridMainContainerStyle: {},
    countGridItemStyle: {
      margin: 0,
      padding: "8px",
      width: "100%",
      height: "40px",
      borderRadius: "12px",
      border: "1px solid",
      borderColor: COLORS.MERCURY,
      background: COLORS.ATHENS_GRAY,
      color: COLORS.MINE_SHAFT,
      cursor: "pointer",
    },
    countGridItemSelectedStyle: {
      margin: 0,
      padding: "8px 16px",
      width: "100%",
      height: "40px",
      borderRadius: "12px",
      border: "1px solid",
      borderColor: COLORS.DOS_BLUE_DARK,
      background: COLORS.DOS_BLUE_DARK,
      color: COLORS.WHITE,
      cursor: "pointer",
    },
    countItemLabelStyle: {
      margin: "auto",
      padding: 0,
    },
    countItemLabelSpanStyle: {
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: 400,
    },
    countItemValueStyle: {
      margin: "auto",
      padding: 0,
      display: "flex",
      justifyContent: "center",
    },
    countItemValueSpanStyle: {
      fontSize: "18px",
      lineHeight: "21px",
      fontWeight: 600,
    },
    cardRootWithCountHeaderSection: {
      width: "100%",
      height: "calc(100vh - 310px)",
      padding: 0,
      margin: "16px 0px 0px 0px",
      background: "#FFFFFF",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      overflow: "auto",
    },
    cardRoot: {
      width: "100%",
      height: "calc(100vh - 190px)",
      background: "#FFFFFF",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      overflow: "auto",
    },
    tableContainerStyleWithCountHeaderSection: {
      height: "calc(100vh - 360px)",
    },
    tableContainerStyle: {
      height: "calc(100vh - 190px)",
    },
    cellHeaderStyle: {
      height: "65px",
      position: "sticky",
      top: 0,
      left: 0,
      backgroundColor: COLORS.ATHENS_GRAY,
      padding: "12px 0px 0px 12px",
      margin: 0,
      color: COLORS.EMPEROR,
      fontWeight: 700,
      fontSize: "14px",
      border: "none",
      zIndex: 0,
      "&.MuiTableCell-paddingNone:last-child": {
        padding: "12px 12px 0px 12px",
      },
    },
    tablePaginationStyle: {
      position: "fixed",
      bottom: 0,
      justifyContent: "flex-end",
      width: "calc(100vw - 110px)",
      marginBottom: "24px",
      marginLeft: "-2px",
      background: "#E6E6E6",
      borderRadius: "0px 0px 8px 8px",
      minHeight: "54px",
      maxHeight: "54px",
    },
    noDataRowStyle: {
      width: "auto",
      height: "calc(100vh - 425px)",
      verticalAlign: "middle",
      textAlign: "center",
      borderRadius: "8px",
      border: "none",
      boxShadow: "none",
    },
    cellDataStyle: {
      width: "auto",
      height: "40px",
      verticalAlign: "middle",
      color: COLORS.MINE_SHAFT,
      fontWeight: 400,
      fontSize: "12px",
      border: "none",
      borderBottom: `1px solid ${COLORS.MERCURY}`,
      padding: "8px 0px 0px 16px",
      "&.MuiTableCell-paddingNone:last-child": {
        padding: "8px 0px 0px 16px",
      },
    },
    polygonServiceDetailsSpanStyle: {
      color: COLORS.MINE_SHAFT,
      fontWeight: 400,
      fontSize: "12px",
    },
    actionIconStyle: {
      cursor: "pointer",
    },
    inlineActionIconStyle: {
      cursor: "pointer",
      margin: 0,
      padding: "0px 0px 0px 8px",
      width: "22px",
    },
    inlineActionIconHiddenStyle: {
      cursor: "default",
      margin: 0,
      padding: "0px 0px 0px 8px",
      width: "22px",
      opacity: "0",
    },
    polygonNonServiceableSpanStyle: {
      color: COLORS.FROLY,
      fontWeight: 400,
      fontSize: "12px",
    },
    //
    //
    cellPolygonIdStyle: {
      minWidth: "10%",
      width: "10%",
      maxWidth: "10%",
      wordBreak: "break-word",
    },
    cellPolygonNameStyle: {
      minWidth: "15%",
      width: "15%",
      maxWidth: "15%",
      wordBreak: "break-word",
    },
    cellPolygonServiceStyle: {
      minWidth: "20%",
      width: "20%",
      maxWidth: "20%",
    },
    cellPolygonLastUpdatedStyle: {
      minWidth: "15%",
      width: "15%",
      maxWidth: "15%",
    },
    cellPolygonActionStyle: {
      minWidth: "5%",
      width: "5%",
      maxWidth: "5%",
    },
    //
    //
    serviceTypeFilterStyle: {
      margin: "auto",
      padding: "0px 8px 0px 0px",
    },
    serviceTypeFilterInputStyle: {
      width: "100%",
      height: "auto",
    },
    filteredListSpanstyle: {
      margin: "auto 4px",
      padding: "4px",
      background: COLORS.DOS_BLUE_LIGHT,
      color: COLORS.DOS_BLUE_DARK,
      borderRadius: "4px",
      fontSize: "12px",
    },
    forwardIconStyle: {
      color: COLORS.WHITE,
      margin: "0px 0px 0px 8px",
      padding: "0px",
      width: "25px",
      height: "25px",
      cursor: "pointer",
    },
    fullWidthButton: {
      width: "100%",
      maxWidth: "212px",
      margin: "0px 0px 0px 12px",
    },
    mapContainerCardStyle: {
      width: "100%",
      height: "69vh",
      maxHeight: "74vh",
      borderRadius: "8px",
      boxShadow: "none",
    },
    colorSchemeGridContainerStyle: {
      margin: "auto",
      padding: "12px 0px 12px 0px",
    },
    colorSchemeGridItemStyle: {
      margin: "auto 0px",
      padding: "0px 12px 0px 0px",
      display: "flex",
    },
    colorSchemeBoxStyle: {
      margin: "auto 0px",
      width: "12px",
      height: "12px",
      borderRadius: "50%",
    },
    colorSchemeSpanStyle: {
      margin: "auto 0px",
      padding: "0px 0px 0px 8px",
      color: COLORS.EMPEROR,
      fontWeight: 400,
      fontSize: "12px",
    },
    polygonInfoWindowBoxStyle: {
      width: "320px",
      maxWidth: "320px",
      height: "auto",
    },
    polygonInfoWindowMainGridConatinerStyle: {},
    polygonInfoWindowMainGridItemStyle: {
      margin: 0,
      padding: "4px 4px 0px 4px",
    },
    polygonInfoWindowMainGridCloseIconStyle: {
      margin: 0,
      padding: 0,
      width: "20px",
      height: "20px",
      display: "flex",
      justifyContent: "flex-end",
      cursor: "pointer",
    },
    polygonInfoWindowGridContainerStyle: {
      margin: 0,
      padding: "4px",
    },
    polygonInfoWindowTitleTextStyle: {
      color: COLORS.BLACK,
      fontWeight: 700,
      fontSize: "12px",
    },
    polygonInfoWindowSpanStyle: {
      color: COLORS.BLACK,
      fontWeight: 400,
      fontSize: "12px",
    },
    polygonInfoWindowErrorSpanStyle: {
      color: COLORS.FROLY,
      fontWeight: 400,
      fontSize: "12px",
    },
    posZoneBoxStyle: {
      margin: "4px 4px 0px 0px",
      padding: "4px 8px",
      border: `1px solid ${COLORS.ATHENS_GRAY}`,
      boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.06)",
      background: COLORS.WHITE,
      borderRadius: "8px",
    },
    posZoneSelectedBoxStyle: {
      margin: "4px 4px 0px 0px",
      padding: "4px 8px",
      border: `1px solid ${COLORS.DOS_BLUE_DARK}`,
      boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.06)",
      background: COLORS.DOS_BLUE_DARK,
      borderRadius: "8px",
    },
    posZoneBoxSpanStyle: {
      color: COLORS.BLACK,
      fontWeight: 400,
      fontSize: "12px",
    },
    posZoneSelectedBoxSpanStyle: {
      color: COLORS.WHITE,
      fontWeight: 400,
      fontSize: "12px",
    },
    clearPOSFilterSpanStyle: {
      fontWeight: 600,
      fontSize: "14px",
      color: COLORS.DOS_BLUE_DARK,
      cursor: "pointer",
      border: "none",
      borderRight: `1px solid ${COLORS.MERCURY}`,
      padding: "0px 8px 0px 0px",
    },
    rightPaddingStyle: {
      width: "150px",
      margin: 0,
      padding: "0px 0px 0px 8px",
    },
    resetSpanStyle: {
      margin: "auto 0px",
      padding: "0px 0px 0px 16px",
      cursor: "pointer",
      color: COLORS.DOS_BLUE_DARK,
      fontWeight: 600,
      fontSize: "14px",
    },
  })
);
