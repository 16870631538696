// Material UI Components
import Grid from "@material-ui/core/Grid";
import React from "react";
import LinkButton from "../../../common/LinkButton/LinkButton";
// Common Components
import MultiSelectBox from "../../../common/MultiSelectBox/MultiSelectBox";
import OnDemandSlot from "./OnDemandSlot/OnDemandSlot";
// Styles
import onDemandStyles from "./OnDemandStyles";

const CreateOnDemandSlots = ({
  tabIndex,
  formIndex,
  days,
  remainingDays,
  generatedSlots,
  handleDaysChange,
  handleAddOnDemandSlot,
  handleRemoveOnDemandSlot,
  handleIndividualSlotFieldsChange,
  handleOnDemandServiceTimeChange,
}) => {
  const classes = onDemandStyles();

  const renderDaysInput = () => {
    return <MultiSelectBox fieldLabel="Days" menuitems={remainingDays} value={days} onChange={(e) => handleDaysChange(tabIndex, formIndex, e)} />;
  };

  return (
    <div className={classes.onDemandContainer}>
      <Grid container spacing={1} style={{ margin: 0, padding: 0, paddingBottom: "16px" }}>
        <Grid item xs={10}>
          {renderDaysInput()}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={10}>
          {""}
        </Grid>
        <Grid item xs={2} className={classes.addSlots}>
          <LinkButton buttonLabel="Add Slots" onClick={() => handleAddOnDemandSlot(tabIndex, formIndex)} />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {generatedSlots.map((slot, index) => (
            <OnDemandSlot
              tabIndex={tabIndex}
              formIndex={formIndex}
              slotIndex={index}
              key={index}
              slotCapacity={slot.slotCapacity}
              serviceStartTime={slot.startTime}
              serviceEndTime={slot.endTime}
              handleRemoveOnDemandSlot={handleRemoveOnDemandSlot}
              handleIndividualSlotFieldsChange={handleIndividualSlotFieldsChange}
              handleOnDemandServiceTimeChange={handleOnDemandServiceTimeChange}
            />
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateOnDemandSlots;
