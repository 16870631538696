import { Grid } from "@material-ui/core";
import { APP_ROUTES } from "config/constants";
import { Link } from "react-router-dom";
import settingsIcon from "../../assets/svg/Settings.svg";
import { userStyles } from "../Styles/UserManagementStyle";

export const getUserListColumns = () => {
  const classes = userStyles();

  return [
    { sortable: false, flex: 0.5, field: "id", headerName: "User ID", headerClassName: classes.headerStyle },
    { sortable: false, flex: 1, field: "firstName", headerName: "First Name", headerClassName: classes.headerStyle },
    { sortable: false, flex: 1, field: "lastName", headerName: "Last Name", headerClassName: classes.headerStyle },
    {
      sortable: false,
      flex: 1,
      field: "accessToDeliveryEngine",
      headerName: "Access to Delivery Engine",
      headerClassName: classes.headerStyle,
      renderCell: (params: any) => {
        return <span> {params.row.accessToDeliveryEngine ? "Yes" : "No"}</span>;
      },
    },
    {
      sortable: false,
      flex: 1,
      field: "userProfile.role",
      headerName: "Role",
      headerClassName: classes.headerStyle,
      renderCell: (params: any) => {
        return params?.row?.userProfile?.role || "-";
      },
    },
    { sortable: false, flex: 1.5, field: "serviceTypeName", headerName: "Service Type", headerClassName: classes.headerStyle },
    { sortable: false, flex: 1.5, field: "countryDisplayName", headerName: "Country", headerClassName: classes.headerStyle },
    {
      sortable: false,
      flex: 1,
      field: "active",
      headerName: "Status",
      headerClassName: classes.headerStyle,
      renderCell: (params: any) => {
        return (
          <Grid container spacing={2} className={classes.statusTypeContainer}>
            <div className={params.row.active ? `${classes.activeStatus} ` : `${classes.inactiveStatus}`}>
              <div className={`${classes.status}`}>{params.row.active ? "ACTIVE" : "INACTIVE"}</div>
            </div>
          </Grid>
        );
      },
    },
    {
      sortable: false,
      flex: 0.55,
      field: "actions",
      headerName: "Actions",
      headerClassName: classes.headerStyle,
      renderCell: (params: any) => {
        const {
          row: { id },
        } = params;
        return (
          <Link to={{ pathname: `${APP_ROUTES.USER_MANAGEMENT}`, search: `?userDetailId=${id}` }}>
            <div className={classes.editIconStyle}>
              <img src={settingsIcon} alt="Edit User" />
            </div>
          </Link>
        );
      },
    },
  ];
};
