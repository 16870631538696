import { Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import ModalContainer from "../../../base/ModalContainer";
import { ReactComponent as CloseButton } from "../../assets/svg/closeButton.svg";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import commonStyles from "../RuleEngineCommonStyles";
// Styles
import previewDeliveryPromiseStyles from "./PreviewDeliveryPromiseStyles";
import { DEFAULT_RULE_CONFIG, PROMISE_TYPE, PromiseConfigMaxTime, RULE_ENGINE_TEXT_CONSTANT } from "../RuleEngineUtils";
import { HEADER_NAME_CONSTANT } from "../CurrentConfiguration/CurrentConfigConstant";

interface PreviewDeliveryPromiseProps {
  show: boolean;
  onClose: any;
  configDetails: DEFAULT_RULE_CONFIG;
  isLoading: boolean;
  onProceed: any;
  updatedConfigInfo: any;
}

const PreviewDeliveryPromise = (props: PreviewDeliveryPromiseProps) => {
  const { show = true, onClose, configDetails, isLoading, onProceed, updatedConfigInfo } = props;
  const commonClasses = commonStyles();
  const classes = previewDeliveryPromiseStyles();

  const renderPOSZoneView = () => {
    return (
      <Accordion defaultExpanded className={commonClasses.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={commonClasses.accordionSummary}>
          {`${RULE_ENGINE_TEXT_CONSTANT.ASSIGNED_POS_ZONES}`}
        </AccordionSummary>
        <AccordionDetails className={commonClasses.accordionDetails}>
          <div className={classes.posZoneMappingDetails}>
            <Grid container>
              <Grid item xs={4}>
                {configDetails.posZoneIdentifierList.map((zone: any) => (
                  <div className={classes.posZoneName} key={zone.id}>
                    {zone.posNoPosNameZoneName || `${zone.posNo}-${zone.posName}-${zone.zoneName}-${zone.status}`}
                  </div>
                ))}
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderFieldLabel = (value: string) => <div className={classes.fieldLabel}>{`${value}`}</div>;
  const renderFieldValue = (value: string | number) => <div className={classes.fieldValue}>{`${value}`}</div>;

  const renderTypeOfPromise = () => {
    return (
      <Grid item xs={2}>
        {renderFieldLabel(RULE_ENGINE_TEXT_CONSTANT.TYPE_OF_PROMISE)}
        {renderFieldValue(configDetails?.type || "")}
      </Grid>
    );
  };

  const renderMinimumPromiseTime = () => {
    return (
      <Grid item xs={3}>
        {renderFieldLabel(RULE_ENGINE_TEXT_CONSTANT.MINIMUM_PROMISE_TIME)}
        {renderFieldValue(`${configDetails.promiseConfig.minTimeHour} Hours ${configDetails.promiseConfig.minTimeMinutes} Minutes`)}
      </Grid>
    );
  };

  const renderAutomatedPromise = () => {
    return (
      <Grid item xs={3}>
        {renderFieldLabel(RULE_ENGINE_TEXT_CONSTANT.LAST_MILE_TIME_AUTOMATED_PROMISE)}
        {renderFieldValue(`${configDetails.lastmileTimeAutomatedConfig.lastMileTimeHour} Hour ${configDetails.lastmileTimeAutomatedConfig.lastMileTimeMin} Minutes`)}
      </Grid>
    );
  };

  const renderVehicleTypeRule = () => {
    return (
      <Grid item xs={6}>
        <div className={classes.fieldLabel}>{`${RULE_ENGINE_TEXT_CONSTANT.VEHICLE_TYPE_RULE}`}</div>
        <div className={classes.fieldValue}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                <div className={classes.vehicleFieldLabel}>Vehicle Type</div>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.vehicleFieldLabel}>Threshold Weight(Kg)</div>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.vehicleFieldLabel}>To Threshold Weight(Kg)</div>
              </Grid>
            </Grid>
            {configDetails.vehicleTypeRule.bikeThresholdActive == true && (
              <Grid container>
                <Grid item xs={4}>
                  <div className={classes.vehicleFieldLabel}>Bike</div>
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.vehicleFieldLabel}>{`${configDetails.vehicleTypeRule.thresholdFrom}`}</div>
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.vehicleFieldLabel}>{`${configDetails.vehicleTypeRule.bikeThresholdTo}`}</div>
                </Grid>
              </Grid>
            )}
            {configDetails.vehicleTypeRule.carThresholdActive == true && (
              <Grid container>
                <Grid item xs={4}>
                  <div className={classes.vehicleFieldLabel}>Car</div>
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.vehicleFieldLabel}>{`${configDetails.vehicleTypeRule.bikeThresholdTo}`}</div>
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.vehicleFieldLabel}>{`${configDetails.vehicleTypeRule.carThresholdTo}`}</div>
                </Grid>
              </Grid>
            )}
            {configDetails.vehicleTypeRule.otherThresholdActive == true && (
              <Grid container>
                <Grid item xs={4}>
                  <div className={classes.vehicleFieldLabel}>Other</div>
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.vehicleFieldLabel}>{`${configDetails.vehicleTypeRule.carThresholdTo}`}</div>
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.vehicleFieldLabel}>{`${configDetails.vehicleTypeRule.otherThresholdTo}`}</div>
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
      </Grid>
    );
  };

  const renderModalBody = () => {
    return (
      <div className={classes.modalBody}>
        {configDetails?.posZoneIdentifierList?.length > 0 && renderPOSZoneView()}
        <Accordion defaultExpanded className={commonClasses.accordion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} className={commonClasses.accordionSummary}>
            {`${RULE_ENGINE_TEXT_CONSTANT.EXPRESS}`}
          </AccordionSummary>
          <AccordionDetails className={commonClasses.accordionDetails}>
            <div className={classes.deliveryPromiseDetails}>
              <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
                {renderTypeOfPromise()}
                {configDetails.promiseConfig.minActive && renderMinimumPromiseTime()}
                {configDetails.type === PROMISE_TYPE.AUTOMATED && renderAutomatedPromise()}
                {(configDetails.type === PROMISE_TYPE.STATIC || configDetails.type === PROMISE_TYPE.DYNAMIC) && configDetails.vehicleTypeRule.active && renderVehicleTypeRule()}
                {configDetails.type === PROMISE_TYPE.DYNAMIC && (
                  <>
                    {/* Order Velocity */}
                    {configDetails.orderVelocityRule !== undefined && configDetails.orderVelocityRule.active !== undefined && configDetails.orderVelocityRule.active === true ? (
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={3}>
                            <div className={classes.fieldLabel}>Order Velocity Rule</div>
                          </Grid>
                          <Grid item xs={9}>
                            {""}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={5}>
                            <div className={classes.fieldLabel}>Days</div>
                          </Grid>
                          <Grid item xs={2}>
                            <div className={classes.fieldLabel}>Time Range</div>
                          </Grid>
                          <Grid item xs={2}>
                            <div className={classes.fieldLabel}>Order Threshold</div>
                          </Grid>
                          <Grid item xs={3}>
                            <div className={classes.fieldLabel}>Extra Promise Time</div>
                          </Grid>
                        </Grid>
                        {configDetails.orderVelocityRule.orderVelocityRuleValues !== undefined &&
                          Array.isArray(configDetails.orderVelocityRule.orderVelocityRuleValues) &&
                          configDetails.orderVelocityRule.orderVelocityRuleValues.length > 0 &&
                          configDetails.orderVelocityRule.orderVelocityRuleValues.map((element: any, elemnet_index: any) => (
                            <Grid container spacing={1} key={`${elemnet_index}-orderVelocityRule-orderVelocityRuleValues`}>
                              <Grid item xs={5}>
                                <div className={classes.fieldValue} style={{ wordBreak: "break-all" }}>{`${element.configuredDays}`}</div>
                              </Grid>
                              <Grid item xs={2}>
                                <div className={classes.fieldValue}>{`${element.timeRange} Minutes`}</div>
                              </Grid>
                              <Grid item xs={2}>
                                <div className={classes.fieldValue}>{`${element.orderThreshold}`}</div>
                              </Grid>
                              <Grid item xs={3}>
                                <div className={classes.fieldValue}>{`${element.extraPromiseTime} Minutes`}</div>
                              </Grid>
                            </Grid>
                          ))}
                      </Grid>
                    ) : (
                      <></>
                    )}
                    {/* Weight of Consignment */}
                    {configDetails.weightRule.thresholdWeight !== updatedConfigInfo.weightRule.thresholdWeight && (
                      <Grid item xs={3}>
                        <div className={classes.fieldLabel}>Weight Of Consignment</div>
                        <div className={classes.fieldValue}>{`${configDetails.weightRule.thresholdWeight} Kg`}</div>
                      </Grid>
                    )}
                    {configDetails.weightRule.incrementalTime !== updatedConfigInfo.weightRule.incrementalTime && (
                      <Grid item xs={4}>
                        <div className={classes.fieldLabel}>Incremental Time Above Threshold</div>
                        <div className={classes.fieldValue}>{`${configDetails.weightRule.incrementalTime || 0} minutes`}</div>
                      </Grid>
                    )}
                    {/* Item Count */}
                    {configDetails.countRule.thresholdCount !== updatedConfigInfo.countRule.thresholdCount && (
                      <Grid item xs={2}>
                        <div className={classes.fieldLabel}>Item Count</div>
                        <div className={classes.fieldValue}>{configDetails.countRule.thresholdCount}</div>
                      </Grid>
                    )}
                    {configDetails.countRule.incrementalTime !== updatedConfigInfo.countRule.incrementalTime && (
                      <Grid item xs={4}>
                        <div className={classes.fieldLabel}>Incremental Time Above Threshold</div>
                        <div className={classes.fieldValue}>{`${configDetails.countRule.incrementalTime || 0} seconds`}</div>
                      </Grid>
                    )}
                    {configDetails.scalableTypeRule.itemList.length > 0 && (
                      <Grid item xs={6}>
                        <div className={classes.fieldLabel}>Type of Items (Incremental Time)</div>
                        <div className={classes.fieldValue}>
                          {configDetails.scalableTypeRule.itemList.map((item: any) => (
                            <div key={item.section}>{`${item.section} ( ${item.incrementalTime} minutes )`}</div>
                          ))}
                        </div>
                      </Grid>
                    )}
                  </>
                )}
                {/* Maximum Promise Rule */}
                {configDetails.promiseConfig.maxActive && (
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={3}>
                        <div className={classes.fieldLabel}>{`${HEADER_NAME_CONSTANT.MAXIMUM_PROMISE_TIME}`}</div>
                      </Grid>
                      <Grid item xs={9}>
                        {""}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={4}>
                        <div className={classes.fieldLabel}>{`${HEADER_NAME_CONSTANT.DAYS}`}</div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className={classes.fieldLabel}>{`${HEADER_NAME_CONSTANT.MAXIMUM_PROMISE_TIME_HOUR}`}</div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className={classes.fieldLabel}>{`${HEADER_NAME_CONSTANT.MAXIMUM_PROMISE_TIME_MINUTES}`}</div>
                      </Grid>
                    </Grid>
                    {configDetails?.promiseConfig?.maximumPromiseRuleValues?.length > 0 &&
                      configDetails?.promiseConfig?.maximumPromiseRuleValues?.map((element: PromiseConfigMaxTime, elemnet_index: number) => (
                        <Grid container spacing={1} key={`${elemnet_index}-maximumPromiseRule-maximumPromiseRuleValues`}>
                          <Grid item xs={4}>
                            <div className={classes.fieldValue} style={{ wordBreak: "break-all" }}>{`${element.configuredDays}`}</div>
                          </Grid>
                          <Grid item xs={4}>
                            <div className={classes.fieldValue}>{`${element.maxTimeHour}`}</div>
                          </Grid>
                          <Grid item xs={4}>
                            <div className={classes.fieldValue}>{`${element.maxTimeMinutes}`}</div>
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                )}
              </Grid>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className={classes.modalFooter}>
        <Grid container spacing={1}>
          <Grid item xs={8}></Grid>
          <Grid item xs={4}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <PrimaryButton className={classes.buttonStyles} buttonLabel="CANCEL" buttonVariant="outlined" onClick={onClose} />
              </Grid>
              <Grid item xs={6}>
                <PrimaryButton className={classes.buttonStyles} buttonLabel={isLoading ? "loading..." : "Proceed"} onClick={onProceed} disabled={isLoading} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };
  return (
    <div>
      <ModalContainer className={classes.modal} open={show} onClose={onClose} disableBackdropClick>
        <div className={classes.modalContent}>
          <div className={classes.mainDivStyle}>
            <Typography className={classes.modalTitle}>Rule Engine Set up Preview</Typography>
            <CloseButton className={classes.closeButtonSVG} onClick={onClose} />
          </div>
          {renderModalBody()}
          {renderFooter()}
        </div>
      </ModalContainer>
    </div>
  );
};

export default PreviewDeliveryPromise;
