import { createStyles, makeStyles, PopoverOrigin, Theme } from "@material-ui/core";

import { COLORS } from "../../../config/theme/theme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { flexGrow: 1 },
    rootNoInputSelect: { width: "auto" },
    searchSelect: {
      "& .MuiOutlinedInput-root": {
        minWidth: 130,
        backgroundColor: COLORS.MERCURY_LIGHT,
        borderRadius: "8px 0px 0px 8px",
        color: COLORS.MINE_SHAFT_LIGHT,
      },
      "& .MuiOutlinedInput-input": {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        border: "none",
      },
      "& .open .MuiOutlinedInput-notchedOutline": {
        borderBottom: `1px solid ${COLORS.BLACK_INPUT_BORDER}`,
      },
    },
    searchNoInputSelect: {
      "& .MuiOutlinedInput-root": {
        minWidth: 130,
        backgroundColor: COLORS.MERCURY_LIGHT,
        borderRadius: "8px",
        color: COLORS.MINE_SHAFT_LIGHT,
      },
      "& .MuiOutlinedInput-input": {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        border: "none",
      },
      "& .open .MuiOutlinedInput-notchedOutline": {
        borderBottom: `1px solid ${COLORS.BLACK_INPUT_BORDER}`,
      },
    },
    searchSelectPaperProps: {
      maxHeight: 200,
      border: `1px solid ${COLORS.BLACK_INPUT_BORDER}`,
      borderTop: "none",
      borderRadius: theme.spacing(1),
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: 0,
      boxShadow: "none",
    },
    listItemTextRootProps: {
      margin: 0,
      padding: 0,
    },
    listItemTextProps: {
      fontWeight: 400,
      fontSize: "12px",
      color: COLORS.MINE_SHAFT_LIGHT,
    },
    searchTextbox: {
      padding: "0px 16px 0px 0px",
      "& .MuiOutlinedInput-root": {
        minWidth: 300,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderLeft: "none",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
    // SearchHeaderCustomSelectBox
    //
    formControl: {
      "& .open": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderBottom: "none",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      },
    },
    searchBox: {
      background: "white",
      overflowY: "auto",
      fontSize: "12px",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
      maxHeight: 19,
      overflow: "auto",
    },
    chip: {
      margin: 2,
      height: 17,
      fontSize: 12,
    },
    menuPropsList: {
      "& .MuiListItem-root": {
        fontSize: 12,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        whiteSpace: "normal",
      },
      "& .MuiCheckbox-root": {
        backgroundSize: "16px 16px",
        marginRight: theme.spacing(0.75),
      },
    },
    menuPropsPaper: {
      maxHeight: 200,
      maxWidth: 200,
      border: `1px solid ${COLORS.BLACK_INPUT_BORDER}`,
      borderRadius: theme.spacing(1),
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      overflow: "auto",
      boxShadow: "none",
    },
    // TextBox
    textBoxRoot: {
      "& .hasLabel": {
        "& .MuiOutlinedInput-input": {
          paddingTop: theme.spacing(2),
          paddingBottom: 5,
        },
      },
    },
    resetSpanStyle: {
      margin: "auto 0px",
      cursor: "pointer",
      color: COLORS.DOS_BLUE_DARK,
      fontWeight: 600,
      fontSize: "14px",
    },
  })
);

export const anchorOriginDefault: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};
