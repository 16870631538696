import { Modal, ModalProps } from "@material-ui/core";

interface ModalContainerProps extends ModalProps {
    children: any
    disableBackdropClick?: boolean
    disableEscapeKeyDown?: boolean
}

const ModalContainer = (props: ModalContainerProps) => {

    const { disableBackdropClick, disableEscapeKeyDown, onClose, children, ...rest } = props;

    const handleClose = (event: any, reason: any) => {
        if (disableBackdropClick && reason === "backdropClick") {
            return false;
        }

        if (disableEscapeKeyDown && reason === "escapeKeyDown") {
            return false;
        }

        if (typeof onClose === "function") {
            onClose(event, reason);
        }
    };

    return (
        <Modal
            onClose={handleClose}
            {...rest}>
            {children}
        </Modal>
    );
}

export default ModalContainer;