import produce from "immer";
import moment, { Moment } from "moment";
import { APP_ROUTES, PRODUCT_TYPES, SERVICE_TYPES, SERVICE_TYPES_KEYS } from "../../../config/constants";
import { isEmptyOrNull } from "../../../utils/DataUtils";
import { foodSplitRegex, PRE_SELECTED_FOOD_SUB_TYPES } from "./constants";

interface BREADCRUMB_LINKS {
  name: string;
  to: string;
  isCurrentRoute?: boolean;
}

// handle food type selection on service Slot
export const handleProductType = (configTabs: any, tabIndex: number, selectedFoodTypes: any, checked: boolean) => {
  let newTabs = produce(configTabs, (draft: any, index: number) => {
    // If food type unchecked remove from the selected types else add in list
    if (checked) {
      // Add all sub food type on selcted food
      if (selectedFoodTypes === PRODUCT_TYPES.FOOD) {
        draft[tabIndex].foodSubType = PRE_SELECTED_FOOD_SUB_TYPES;
      }
      draft[tabIndex].foodTypes = isEmptyOrNull(draft[tabIndex].foodTypes) ? selectedFoodTypes : draft[tabIndex].foodTypes + foodSplitRegex + selectedFoodTypes;
    } else {
      let existingFoodTypes = draft[tabIndex].foodTypes.split(foodSplitRegex);
      // if user uncheck food type remove all sub food type
      if (selectedFoodTypes === PRODUCT_TYPES.FOOD) {
        draft[tabIndex].foodSubType = "";
      }
      draft[tabIndex].foodTypes = existingFoodTypes.filter((element: any) => element !== selectedFoodTypes)[0];
    }
    return draft;
  });
  return newTabs;
};

/**
 * Method to update selected food sub items
 * @param {object} event
 */
export const handleFoodSubType = (configTabs: any, tabIndex: number, selectedFoodSubType: any) => {
  let newTabs = produce(configTabs, (draft: any) => {
    draft[tabIndex].foodSubType = selectedFoodSubType;
  });
  return newTabs;
};

// Send all exclude food types to server
export const productTypesToBeSent = (foodType: any, foodSubType: any, isPosTypePharmacy: boolean) => {
  if (isPosTypePharmacy) {
    // we will nothing exclude in case of pharmacy
    return {
      foodTypes: ["FOOD_DRY", "FOOD_FRESH", "FOOD_FROZEN"],
      excluded: true,
    };
  } else {
    let productType: Array<any> = [];
    const selectedFoodType = isEmptyOrNull(foodType) ? [] : foodType.split(foodSplitRegex);

    if (selectedFoodType.includes(PRODUCT_TYPES.FOOD) && foodSubType.length) {
      productType = [...foodSubType.split(foodSplitRegex)];
    }

    if (selectedFoodType.includes(PRODUCT_TYPES.NON_FOOD)) {
      productType = [...productType, ...["NON_FOOD"]];
    }
    return productType.length > 0
      ? {
          foodTypes: productType,
          // added excluded:true as per new contract in backend
          excluded: true,
        }
      : {};
  }
};

export function getPOSBreadCum(service: SERVICE_TYPES_KEYS) {
  const BREADCRUMB_LINKS: Array<BREADCRUMB_LINKS> = [
    { name: "POS List", to: APP_ROUTES.POS_LIST },
    { name: "Basic Details", to: `${APP_ROUTES.CREATE_POS}${APP_ROUTES.BASIC_DETAILS}` },
    {
      name: "Service & Slots",
      to: "#",
    },
  ];
  switch (service) {
    case SERVICE_TYPES.STANDARD:
      BREADCRUMB_LINKS.push({ name: "Standard", to: `${APP_ROUTES.SERVICE_AND_SLOTS}${APP_ROUTES.STANDARD}`, isCurrentRoute: true });
      break;
    case SERVICE_TYPES.EXPRESS:
      BREADCRUMB_LINKS.push({ name: "Express", to: `${APP_ROUTES.SERVICE_AND_SLOTS}${APP_ROUTES.EXPRESS}`, isCurrentRoute: true });
      break;
    case SERVICE_TYPES.CLICK_N_COLLECT:
      BREADCRUMB_LINKS.push({ name: "Click & Collect", to: `${APP_ROUTES.SERVICE_AND_SLOTS}${APP_ROUTES.CLICK_N_COLLECT}`, isCurrentRoute: true });
      break;
    case SERVICE_TYPES.DEFAULT:
      BREADCRUMB_LINKS.push({ name: "Default", to: `${APP_ROUTES.SERVICE_AND_SLOTS}${APP_ROUTES.DEFAULT}`, isCurrentRoute: true });
      break;
  }
  return BREADCRUMB_LINKS;
}

export function isNonFoodType(configTab: any): boolean {
  let alreadySelectedFoodItems = configTab.foodTypes ? configTab.foodTypes.split(foodSplitRegex) : [];

  return !alreadySelectedFoodItems.includes(PRODUCT_TYPES.FOOD);
}

export const getFoodTypesFromFoodSubTypes = (selectedProductTypes: Array<string>) => {
  let foodTypesArr = [];

  if (selectedProductTypes?.some((item) => item === "FOOD_DRY" || item === "FOOD_FRESH" || item === "FOOD_FROZEN")) {
    foodTypesArr.push(PRODUCT_TYPES.FOOD);
  }

  if (selectedProductTypes?.includes("NON_FOOD")) {
    foodTypesArr.push(PRODUCT_TYPES.NON_FOOD);
  }

  return foodTypesArr.join(foodSplitRegex);
};

export type OrderCutoff = {
  bufferTimeHours: number;
  bufferTimeMinutes: number;
  startTime: Moment | string;
};

export const calculateOrderCutOff = (slot: OrderCutoff) => {
  let orderCutOff = "";
  const { bufferTimeHours, bufferTimeMinutes, startTime } = slot;
  if (startTime && moment(startTime).isValid()) {
    const totalBufferTimeInMinutes = bufferTimeHours * 60 + bufferTimeMinutes;
    orderCutOff = moment(startTime).subtract(totalBufferTimeInMinutes, "m").format("HH:mm");
  }

  return orderCutOff || "";
};
