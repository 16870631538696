import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const userStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainDetailsDivStyle: {
      width: "100%",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
      borderRadius: "8px",
      marginTop: "24px",
      marginBottom: "12px",
    },
    detailsDivStyle: {
      cursor: "pointer",
      padding: "18px",
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px solid #E6E6E6",
    },
    detailsDivCloseStyle: {
      width: "100%",
      height: "60px",
      border: "none",
    },
    openArrowStyle: {
      width: "20px",
      height: "20px",
      transform: "rotate(90deg)",
    },
    rotateBy270: {
      transform: "rotate(270deg)",
    },
    detailsBoxTitleSpanStyle: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "17px",
      color: "#252525",
    },
    profileCollapseMainDivStyle: {
      padding: "18px",
      // maxHeight: "calc(100vw - 813px)",
      overflow: "auto",
    },
    collapseMainDivStyle: {
      padding: "18px",
    },
    fieldDivStyle: {
      margin: "8px 0px 8px 0px",
    },
    fieldTitleSpanStyle: {
      fontSize: "14px",
      fontWeight: "normal",
      lineHeight: "17px",
      color: "#252525",
    },
    textBoxStyle: {
      marginTop: "8px",
      "& .MuiFormHelperText-contained": {
        marginLeft: "10px",
        color: "#F34041",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#E6E6E6",
        top: "0px",
      },
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#E6E6E6",
          top: "0px",
        },
      },
    },
    errorTextBoxStyle: {
      marginLeft: "10px",
      color: "#F34041",
      fontSize: "12px",
      fontWeight: 400,
      marginTop: "3px,",
    },
  })
);
