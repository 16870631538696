import { isNonEmptyArray, isNull, isUndefined, objectContainsKey } from "../../../utils/DataUtils";
import { DASHBOARD_CONSTANT } from "./DashboardPageConstants";
import { DashboardPageInitialStateInterface } from "./DashboardPageInterface";

export function isCountryIdPresent(currentCountry: any) {
  return !isUndefined(currentCountry) && objectContainsKey(currentCountry, "id");
}

export function isGetSlotUtilizationRecordParamsValid(currentCountry: any, newAppStateObj: DashboardPageInitialStateInterface): boolean {
  return isCountryIdPresent(currentCountry) && !isNull(newAppStateObj.slotDateLabel) && isNonEmptyArray(newAppStateObj.posNos) && isNonEmptyArray(newAppStateObj.zoneNames);
}

export function isDesignPosZoneListParamsValid(newAppStateObj: DashboardPageInitialStateInterface): boolean {
  return !isNull(newAppStateObj.slotDateLabel) && isNonEmptyArray(newAppStateObj.posNos) && isNonEmptyArray(newAppStateObj.zoneNames);
}

export function isTotalRecordPresent(appStateObj: DashboardPageInitialStateInterface): boolean {
  return (
    !isUndefined(appStateObj) && objectContainsKey(appStateObj, "recordData") && objectContainsKey(appStateObj?.recordData, "totalRecord") && isNonEmptyArray(appStateObj?.recordData?.totalRecord)
  );
}

export function isPosListRecordPresent(appStateObj: DashboardPageInitialStateInterface): boolean {
  return (
    !isUndefined(appStateObj) && objectContainsKey(appStateObj, "recordData") && objectContainsKey(appStateObj?.recordData, "posListRecord") && isNonEmptyArray(appStateObj?.recordData?.posListRecord)
  );
}

export function isZoneStatusActivePresent(zoneData: any) {
  return zoneData && objectContainsKey(zoneData, "status") && !isNull(zoneData.status) && zoneData?.status === DASHBOARD_CONSTANT.ZONE_STATUS_ACTIVATED;
}
