import ModalContainer from "../../../base/ModalContainer";
import { ReactComponent as CloseButton } from "../../assets/svg/closeButton.svg";
import BasicInformation from "../../CompleteSetup/BasicInformation/BasicInformation";
import previewModalStyles from "./PreviewModalStyles";
import ServiceInformation from "./ServiceInformation";

const PreviewModal = ({ show, onClose, posDetails }) => {
  const classes = previewModalStyles({});

  return (
    <div className={classes.modalContainer}>
      <ModalContainer className={classes.modal} open={show} onClose={onClose} disableBackdropClick>
        <div className={classes.modalContent}>
          <div className={classes.closeButton}>
            <CloseButton className={classes.closeButtonSVG} onClick={onClose} />
          </div>

          <div className={classes.modalHeader}>
            <div className={classes.modalTitle}>{posDetails.posNo}</div>
          </div>

          <div className={classes.modalBody}>
            <BasicInformation posDetails={posDetails} isEditable={false} />
            <ServiceInformation />
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default PreviewModal;
