import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchPOSList } from "../../../api/POSService";

export const fetchPOSListByCountryId = createAsyncThunk("posList/fetchPOSList", async (data, { rejectWithValue }) => {
  try {
    const { response: rawData } = await fetchPOSList(data);
    if (rawData) {
      let transformedData = rawData.body.storeList.map((data) => ({
        ...data,
        id: `${data.posNo} - ${data.storeName}`,
        key: data.posNo,
        serviceTypes: data.serviceTypeList.reduce((acc, val) => [...acc, val.type], []),
      }));
      return transformedData;
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

const initialState = {
  loading: false,
  data: [],
  error: "",
};

const posListSlice = createSlice({
  name: "posList",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchPOSListByCountryId.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchPOSListByCountryId.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchPOSListByCountryId.rejected, (state, action) => {
        state.loading = false;
        state.data = [];
        state.error = action.payload.message;
      });
  },
});

export default posListSlice.reducer;
