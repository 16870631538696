import React from "react";

import { Card, CardActionArea, CardContent, CardHeader, Grid, Typography } from "@material-ui/core";

import ModalContainer from "../../../../base/ModalContainer";
import PrimaryButton from "../../../common/PrimaryButton/PrimaryButton";
import CustomPosZoneMapping from "../../..//common/CustomPosZoneMapping/CustomPosZoneMapping";
import CustomPosZoneAssignedList from "../../..//common/CustomPosZoneMapping/CustomPosZoneAssignedList";

import { STANDARD_SERVICE_CONSTANT, ZoneServiceTypeListInterface } from "../StandardServiceConstant";
import { StandardServiceInitialStateInterface } from "../StandardInterface";

interface ShowDynamicRoutingMappingPopUpInterface {
  classes: any;
  popUpOpenFlag: boolean;
  handleClose: () => void;
  standardServiceState: StandardServiceInitialStateInterface;
  handlePosSearchTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  updatePosNumberOpenIndex: (posListIdName: string | number, posIndex: number) => void;
  headerRowCheckboxOnchange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  headerMainRowCheckboxOnchange: () => void;
  headerInnerRowCheckboxOnchange: () => void;
  searchCustomPosZoneMappingList: () => void;
  getAssignedPosZoneMappingList: () => void;
  handleDeleteSelectedRow: (event: React.ChangeEvent<HTMLInputElement>, item: ZoneServiceTypeListInterface, index: number) => void;
  handleAddSecondaryPos: () => void;
}

export const ShowDynamicRoutingMappingPopUp = (props: ShowDynamicRoutingMappingPopUpInterface) => {
  const {
    classes,
    popUpOpenFlag,
    handleClose,
    standardServiceState,
    handlePosSearchTextChange,
    updatePosNumberOpenIndex,
    headerRowCheckboxOnchange,
    headerMainRowCheckboxOnchange,
    headerInnerRowCheckboxOnchange,
    searchCustomPosZoneMappingList,
    getAssignedPosZoneMappingList,
    handleDeleteSelectedRow,
    handleAddSecondaryPos,
  } = props;

  return (
    <ModalContainer onClose={handleClose} open={popUpOpenFlag}>
      <Card className={classes.cardStyle}>
        <CardContent className={classes.cardContentStyle}>
          <Grid item xs={12} className={classes.gridItemFullWidthStyle}>
            <Typography className={classes.accordionTitle}>{`${STANDARD_SERVICE_CONSTANT.POS_ZONE_MAPPING}`}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.innerAccordionMaindiv}>
            <CustomPosZoneMapping
              title={`${STANDARD_SERVICE_CONSTANT.TOTAL_POS}`}
              titleCount={standardServiceState?.totalRowsCountData}
              fetchingList={standardServiceState.loading}
              searchTextValue={standardServiceState.posZoneSearchText}
              searhTextName={`${STANDARD_SERVICE_CONSTANT.POS_ZONE_SEARCH_TEXT}`}
              handlePosSearchTextChange={handlePosSearchTextChange}
              posZoneServiceDataList={standardServiceState.posZoneServiceFilteredRecord}
              selectedPosServices={standardServiceState.selectedPosServiceTypeZone}
              updatePosNumberOpenIndex={updatePosNumberOpenIndex}
              headerRowCheckboxOnchange={headerRowCheckboxOnchange}
              headerMainRowCheckboxOnchange={headerMainRowCheckboxOnchange}
              headerInnerRowCheckboxOnchange={headerInnerRowCheckboxOnchange}
              totalRowsCountData={standardServiceState?.totalRowsCountData}
              handleSearch={searchCustomPosZoneMappingList}
            />
            <CustomPosZoneAssignedList
              title={`${STANDARD_SERVICE_CONSTANT.ASSIGNED_POS}`}
              titleCount={standardServiceState.totalAssignedAreaCount}
              fetchingList={standardServiceState.loading}
              searchTextValue={standardServiceState.assignedPosSearchText}
              searhTextName={`${STANDARD_SERVICE_CONSTANT.ASSIGNED_POS_SEARCH_TEXT}`}
              handlePosSearchTextChange={handlePosSearchTextChange}
              selectedPosServices={getAssignedPosZoneMappingList()}
              deleteSelectedRow={handleDeleteSelectedRow}
            />
          </Grid>
        </CardContent>
        <CardActionArea>
          <Grid item xs={12} className={classes.gridItemFullWidthStyle}>
            <Grid container spacing={1} justifyContent="flex-end" alignItems="flex-end">
              <PrimaryButton buttonLabel={`${STANDARD_SERVICE_CONSTANT.CANCEL}`} onClick={handleClose} buttonVariant={"outlined"} className={classes.buttonStyle} />
              <PrimaryButton buttonLabel={`${STANDARD_SERVICE_CONSTANT.ADD}`} onClick={handleAddSecondaryPos} className={classes.buttonStyle} />
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    </ModalContainer>
  );
};

export default React.memo(ShowDynamicRoutingMappingPopUp);
