import { Link } from "react-router-dom";
import { APP_ROUTES, USER_TYPES } from "../../../config/constants";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import { UserManagementState } from "../UserManagementInterface";
import UserManagementCard from "./UserManagementCard";

interface UserManagementCardAccessInterface {
  userInfo: any;
  classes: any;
  userState: UserManagementState;
  handleSearch: any;
  handleExportCSVClick: any;
  userDataLoading: any;
  additionalServicesList: any;
  userDialogState: any;
  tableHeaderColumns: any;
  pageSize: any;
  rowCount: any;
  pageNumber: any;
  fetchNextRecord: any;
}

export const UserManagementCardAccess = (props: UserManagementCardAccessInterface) => {
  const {
    userInfo,
    classes,
    userState,
    handleSearch,
    handleExportCSVClick,
    userDataLoading,
    additionalServicesList,
    userDialogState,
    tableHeaderColumns,
    pageSize,
    rowCount,
    pageNumber,
    fetchNextRecord,
  } = props;

  return userInfo?.userProfile?.role !== USER_TYPES.ADMIN && userInfo?.userProfile?.role !== USER_TYPES.READONLY_USER ? (
    <UserManagementCard
      classes={classes}
      userState={userState}
      handleSearch={handleSearch}
      handleExportCSVClick={handleExportCSVClick}
      userDataLoading={userDataLoading}
      additionalServicesList={additionalServicesList}
      userDialogState={userDialogState}
      tableHeaderColumns={tableHeaderColumns}
      pageSize={pageSize}
      rowCount={rowCount}
      pageNumber={pageNumber}
      fetchNextRecord={fetchNextRecord}
    />
  ) : (
    <div className={classes.cardRoot}>
      <div className={classes.accessDiv}>
        <span className={classes.heading}>Access Denied</span>
      </div>
    </div>
  );
};

interface UserAddButtonAccessInterface {
  userInfo: any;
  classes: any;
  handleAddUpdateUserDialogClose: any;
}

export const UserAddButtonAccess = (props: UserAddButtonAccessInterface) => {
  const { userInfo, classes, handleAddUpdateUserDialogClose } = props;
  return userInfo?.userProfile?.role !== USER_TYPES.ADMIN && userInfo?.userProfile?.role !== USER_TYPES.READONLY_USER ? (
    <PrimaryButton buttonLabel="CREATE NEW USER" buttonVariant="contained" onClick={handleAddUpdateUserDialogClose} className={classes.buttonStyles} />
  ) : (
    <></>
  );
};

interface AddUpdateSubmitUserAccessInterface {
  userInfo: any;
  isUpdatingUser: boolean;
  addUpdateUserStyles: any;
  handleSubmit: any;
  userObj: any;
}

export const AddUpdateSubmitUserAccess = (props: AddUpdateSubmitUserAccessInterface) => {
  const { userInfo, isUpdatingUser, addUpdateUserStyles, handleSubmit, userObj } = props;

  return userInfo?.userProfile?.role !== USER_TYPES.ADMIN && userInfo?.userProfile?.role !== USER_TYPES.READONLY_USER ? (
    userInfo?.userProfile?.role === USER_TYPES.SUPER_ADMIN && (userObj.role === USER_TYPES.MAF_ADMIN || userObj.role === USER_TYPES.SUPER_ADMIN) ? (
      <></>
    ) : (
      <PrimaryButton buttonLabel={isUpdatingUser ? "UPDATE" : "CREATE"} buttonVariant="outlined" className={addUpdateUserStyles.buttonStyles} onClick={handleSubmit} />
    )
  ) : (
    <></>
  );
};

interface EditUserAccessInterface {
  userInfo: any;
  userObj: any;
  userDetailStyles: any;
}

export const EditUserAccess = (props: EditUserAccessInterface) => {
  const { userInfo, userObj, userDetailStyles } = props;

  return userInfo?.userProfile?.role !== USER_TYPES.ADMIN && userInfo?.userProfile?.role !== USER_TYPES.READONLY_USER ? (
    userInfo?.userProfile?.role === USER_TYPES.SUPER_ADMIN && (userObj.role === USER_TYPES.MAF_ADMIN || userObj.role === USER_TYPES.SUPER_ADMIN) ? (
      <></>
    ) : (
      <Link to={{ pathname: `${APP_ROUTES.USER_MANAGEMENT}`, search: `?userId=${userObj.id}` }}>
        <PrimaryButton buttonLabel="EDIT" buttonVariant="outlined" className={userDetailStyles.buttonStylesSubmit} />
      </Link>
    )
  ) : (
    <></>
  );
};
