import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { useSelector } from "react-redux";
import { API_RESPONSE_STATUS, SERVICE_TYPES, STATUS_OK } from "../../../../config/constants";
import { selectCountrySettings } from "../../../CountrySettings/redux/countrySettingsSlice";
import basicServiceDetailsStyles from "./BasicServiceDetailsStyles";

const BasicServiceDetails = ({ serviceTypeId, serviceTypeName, expressType = "", country, region }) => {
  const classes = basicServiceDetailsStyles();
  const { fetchStatus, countryDetails } = useSelector(selectCountrySettings);

  const getDefaultSlotVisibilityByServiceType = () => {
    let defaultNextDaySlotVisibility = "On";
    switch (serviceTypeId) {
      case 1:
      case 4:
        defaultNextDaySlotVisibility = "On";
        break;
      case 3:
        defaultNextDaySlotVisibility = "Off";
        break;
      default:
        defaultNextDaySlotVisibility = "On";
        break;
    }
    return defaultNextDaySlotVisibility;
  };

  const getNextDaySlotVisibility = () => {
    let nextDaySlotVisibility = getDefaultSlotVisibilityByServiceType();

    if (fetchStatus === API_RESPONSE_STATUS.SUCCEEDED) {
      if (countryDetails && countryDetails.status === STATUS_OK) {
        let {
          data: { serviceTypes },
        } = countryDetails;
        let selectedService = serviceTypes.find((s) => s.serviceTypeID === serviceTypeId);
        nextDaySlotVisibility = selectedService ? selectedService.nextDaySlotVisibility : "Off";
      }
    }
    return nextDaySlotVisibility;
  };

  return (
    <Accordion defaultExpanded={false} className={classes.accordionStyle}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryStyle}>
        <Typography align="left" className={classes.cardTitle}>
          {serviceTypeName}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetailsStyle}>
        <Grid container spacing={3} className={classes.detailsContainer}>
          <Grid item xs={2}>
            <div className={classes.posDetailLabel}>Country</div>
            <div className={classes.posDetailData}>{country}</div>
          </Grid>
          {serviceTypeName.toUpperCase() === SERVICE_TYPES.EXPRESS && (
            <Grid item xs={3}>
              <div className={classes.posDetailLabel}>Express Type</div>
              <div className={classes.posDetailData}>{expressType}</div>
            </Grid>
          )}

          {serviceTypeName.toUpperCase() !== SERVICE_TYPES.EXPRESS && serviceTypeName.toUpperCase() !== SERVICE_TYPES.DEFAULT && (
            <Grid item xs={3}>
              <div className={classes.posDetailLabel}>Next Day Slot Visibility</div>
              <div className={classes.posDetailData}>{getNextDaySlotVisibility()}</div>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(BasicServiceDetails);
