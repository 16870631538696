import React from 'react';

import { userStyles } from '../UserDetailsDialogStyle';

interface UserDetailsInfoInterface {
    nameA : string,
    valueA : any,
    nameB : string,
    valueB : any
}

const UserDetailsInfoView = (props : UserDetailsInfoInterface) => {
    const classes = userStyles();
    const {nameA, valueA, nameB, valueB} = props;

    return (
        <div className={classes.infoDivMain}>
            <div className={classes.infoDiv}>
                <span className={classes.infoTextHeaderStyle}>{nameA}</span>
                <span className={classes.infoTextValueStyle}>{valueA}</span>
            </div>

            <div className={classes.infoDiv}>
            <span className={classes.infoTextHeaderStyle}>{nameB}</span>
                <span className={classes.infoTextValueStyle}>{valueB}</span>
            </div>
        </div>
    )

}

export default React.memo(UserDetailsInfoView);