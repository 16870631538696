import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as POSService from "../../../api/POSService";
import { downloadPOSBasicReport } from "../../../api/ReportService";
import { API_RESPONSE_STATUS } from "../../../config/constants";

const transformPOSDetailsResponse = (body: any, serviceTypes: any) => {
  let transformedResponse = {
    ...body,
    services: [...new Set(body.serviceType.reduce((acc: any, type: any) => [...acc, serviceTypes.filter((s: any) => s.id === type.serviceTypeId)[0]], []))].sort((a: any, b: any) => a.id - b.id),
  };
  return transformedResponse;
};

export const createPOS = createAsyncThunk("pos/createPOS", async (posData, { getState, rejectWithValue }) => {
  try {
    const {
      configurations: {
        serviceTypes: { data: serviceTypes },
      },
    }: any = getState();
    const { response }: any = await POSService.createPos(posData);
    let transformedResponse = transformPOSDetailsResponse(response, serviceTypes);
    return transformedResponse;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updatePOS = createAsyncThunk("pos/updatePOS", async (posData, { getState, rejectWithValue }) => {
  try {
    const {
      configurations: {
        serviceTypes: { data: serviceTypes },
      },
    }: any = getState();
    const { response }: any = await POSService.updatePOSDetails(posData);
    let transformedResponse = transformPOSDetailsResponse(response, serviceTypes);
    return transformedResponse;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchPOSDetailsByPOSNo = createAsyncThunk("pos/fetchPOSDetailsByPOSNo", async (posNo: { posNo: any }, { getState, rejectWithValue }) => {
  try {
    const {
      configurations: {
        serviceTypes: { data: serviceTypes },
      },
    }: any = getState();
    const {
      response: { body },
    }: any = await POSService.fetchPOSDetails(posNo);
    let transformedResponse = transformPOSDetailsResponse(body, serviceTypes);
    return transformedResponse;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const downloadPOSDetailsReport = createAsyncThunk("pos/downloadPOSDetailsReport", async (countryIsoCode, { _, rejectWithValue }: any) => {
  try {
    const body = { type: "POS_BASIC", parameterMap: { isoCode: countryIsoCode } };
    const { response, fileName }: any = await downloadPOSBasicReport(body);
    return { csvData: response, fileName };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const resetPOSDetails = createAsyncThunk("pos/resetPOSDetails", () => {
  return false;
});

const initialState = {
  loading: false,
  posDetails: {},
  createPOSError: "",
  isCreated: false,
  isUpdated: false,
  isCreatedOrUpdated: false,
  fetching: false,
  fetchStatus: API_RESPONSE_STATUS.IDLE,
};

const posSlice = createSlice({
  name: "pos",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(createPOS.pending, (state) => {
        state.loading = true;
        state.createPOSError = "";
        state.isCreated = false;
        state.fetchStatus = API_RESPONSE_STATUS.LOADING;
      })
      .addCase(createPOS.fulfilled, (state, action) => {
        state.loading = false;
        state.posDetails = action.payload;
        state.isCreated = true;
        state.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(createPOS.rejected, (state, action: any) => {
        state.loading = false;
        // state.posDetails = {};
        state.isCreated = false;
        state.createPOSError = action.payload.message;
        state.fetchStatus = API_RESPONSE_STATUS.FAILED;
      })
      .addCase(fetchPOSDetailsByPOSNo.pending, (state) => {
        state.fetching = true;
        state.createPOSError = "";
        state.posDetails = {};
        state.isUpdated = false;
        state.fetchStatus = API_RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchPOSDetailsByPOSNo.fulfilled, (state, action) => {
        state.fetching = false;
        state.posDetails = action.payload;
        state.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(fetchPOSDetailsByPOSNo.rejected, (state, action: any) => {
        state.fetching = false;
        state.posDetails = {};
        state.createPOSError = action.payload.message;
        state.fetchStatus = API_RESPONSE_STATUS.FAILED;
      })
      .addCase(updatePOS.pending, (state) => {
        state.loading = true;
        state.createPOSError = "";
        state.isUpdated = false;
      })
      .addCase(updatePOS.fulfilled, (state, action) => {
        state.loading = false;
        state.posDetails = action.payload;
        state.isUpdated = true;
      })
      .addCase(updatePOS.rejected, (state, action: any) => {
        state.loading = false;
        // state.posDetails = {};
        state.createPOSError = action.payload.message;
        state.isUpdated = false;
      })
      .addCase(resetPOSDetails.fulfilled, () => {
        return initialState;
      });
  },
});

export default posSlice.reducer;
